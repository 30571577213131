import React from 'react'
import MyIcons from '../../../golbal/icons/myIcons'

export default function TutorGrades({data}) {
  return (
    <span>
    {Date.now() - new Date(data?.createdAt) <=
    20 * 24 * 60 * 60 * 1000 ? (
      <MyIcons icon="new" />
    ) : data?.moreInfo?.transactions?.commitmentScore <= 500 ? (
      " "
    ) : data?.moreInfo?.transactions?.commitmentScore <= 1000 ? (
      " استاد2"
    ) : data?.moreInfo?.transactions?.commitmentScore <= 2500 ? (
      " استاد 3"
    ) : (
      " استاد تمام"
    )}   
  </span>
  )
}
