// import "./saved.css"

import { Outlet } from "react-router-dom"

// import { useContext } from "react"
// import { CartContext } from "../../../../../contexts/cartContext"

// import UserDashTransNav from '../../../components/dashNavbar/userDashTransNav'
import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"

export default function UserProfOut() {
  
    
  return (
    <div className="savedItems">
        <UserDashNavbar/>          
        <Outlet/>
    </div>
  )
}
