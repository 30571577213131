import React from 'react'
import { Link } from 'react-router-dom'
import MyIcons from '../../icons/myIcons';
import demyData from '../../../../utils/demyData';
import tools from '../../../../utils/tools';
import Tooltip1 from '../../tooltip/tooltip';
// import { Rating } from '@mui/material';

export default function NewTutorSmallCard({data}) {  
  return (
    <div className="card bg-dark text-white card1 m-1 my-5">
      <Link
      onClick={e=>window.scroll(0,0)}
        className="image-container "
        to={`/${window.lcs}/demy/tutor/page/${data._id}`}
        state={data}
      >
        <img
          src={data?.moreInfo?.img}
          className="card-img card-img1 w-100 "
          alt={data?.name[window.lang]}
          loading="lazy"
        />
        <div className="card-img-overlay ">
          <div>
            <h5 className="card-title card-titel1 card-titel3 mt-auto">
              {data?.name[window.lang]} <MyIcons icon={data.status}/>
              
              {/* <Tooltip1
                      key={data?._id}
                      icon={
                        <img
                          // width="25px"
                          style={{ width: "25px" }}
                          className=""
                          src={`/img/flags/${data?.moreInfo?.address?.countryShort?.toLowerCase()}.svg`}
                          alt={data?.moreInfo?.address?.countryShort}
                          title={window.capitalize(
                            data?.moreInfo?.address?.country
                          )}
                        />
                      }
                      message={
                        <div>
                          {`${data?.moreInfo?.address?.country}, ${data?.moreInfo?.address?.state}, ${data?.moreInfo?.address?.city}`.toUpperCase()}
                        </div>
                      }
                    /> */}
            </h5>
                {/* {data?.moreInfo?.levelSub?.slice(0,1).map((sub, i) => (                  */}
                    {/* // <ul className="d-flex flex-wrap mb-1 ps-1"> */}
                      {/* <div className=" form-check-label small-gray-font bold-dark-font ">
                        <span>
                          {
                            demyData.tutorLevel.find(
                              (le) => le.name === sub.level
                            )?.[window.lang]
                          }
                        </span>
                        :
                      </div> */}
                     <span>
                      {data?.moreInfo?.levelSub[0].subjects?.slice(0, 3).map((sub, i, arr) => (
                        <span
                          key={i}
                          className="ms-1"                     
                        >
                          {sub}
                          {i < arr.length - 1 && "،"}
                        </span>
                      ))}
                   </span>
                    {/* </ul> */}
                 
                {/* ))} */}
           
             
            {/* <div
              className="group-list-item small-gray-font my-1"
              style={{ color: "beige" }}
            >
              <i className="bi bi bi-geo-alt mx-2"></i>
              {`${window.capitalize(data?.moreInfo.address?.city)},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
            </div> */}
          </div>
        </div>
      </Link>
    </div>
  )
}
