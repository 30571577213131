// import React from "react";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
// import { Link } from "react-router-dom";

export default function ClaimBen() {
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "درباره متامارس",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/ca/qc/prompt/claimben",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/prompt/claimben`,
              name: "متامارس | چرا باید کسب و کار خود را ادعا کنم ",
              description:
                "  بیزینس های دارای مالکیت از سایر کسب و کار ها متمایز می باشند و به شکل مناسب تری نمایش و نسبت به کسب و کارهای ادعا نشده در اولویت بالاتری در موتور جستجو دارند ",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "متامارس | چرا باید کسب و کار خود را ادعا کنم ",
    description:
      "  بیزینس های دارای مالکیت از سایر کسب و کار ها متمایز می باشند و به شکل مناسب تری نمایش و نسبت به کسب و کارهای ادعا نشده در اولویت بالاتری در موتور جستجو دارند ",
    section: "ادعای مالکیت",
    url: `https://www.metamarce.com/${window.lcs}/prompt/claimben`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/prompt/claimben`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="p-5">
        {/* <img className=" img-404 p-0 m-0" src="/img/AccessDenied.png" alt="AccessDenied" /> */}
        <h1 className="titr1 font-40 my-5">
          ادعای مالکیت صفحه کسب‌وکار چیست و چرا باید ادعا کنم؟
        </h1>
        <div>
          <p>ادعای مالکیت صفحه کسب و کار سریع، اتوماتیک، آسان و رایگان است!</p>
          <ul className="flex-column">
            <li>
              <h3 className="titr7 text-start">
                <span>
                  <i className="bi bi-check-lg "></i>
                </span>{" "}
                امکان ویرایش و کنترل صفحه کسب‌وکار
              </h3>
              <p>
                کنترل اطلاعاتی که در صفحه اختصاصی کسب و کار شما نمایش داده
                می‌شود را به دست میگیرید. جزئیات را کامل میکنید و آنها را به‌روز
                نگه میدارید، محصولات یا خدمات خود را معرفی میکنید و همچنین ساعات
                کاری دقیق خود را ارائه میدهید.
              </p>
              کسب و کارهایی که اطلاعات به‌روزتر و تعامل بیشتری با کاربران خود
              دارند محبوبیت بیشتری هم دارند.
            </li>
            <li>
              <h3 className="titr7 text-start">
                {" "}
                <span>
                  <i className="bi bi-check-lg"></i>
                </span>{" "}
                دیده شدن بیشتر در موتور های جستجوگر
              </h3>
              <p>
                متامارس خود را متعهد به ایجاد بستری ایمن و قابل اتکا جهت دسترسی
                سریع، آسان و بیشتر به کسب و کارها در سراسر دنیا می داند و برای
                این منظور حداکثر توان موتورهای جستجوگر گوگل، بینگ، یاهو و ... را
                به کار گرفته است، با ادعای کسب و کار خود به بعد بعدی در دیجیتال
                مارکتینگ وارد و کسب و کار شما در رتبه بالاتری در نتایج جستجو
                انتقال داده خواهد شد،.
              </p>
            </li>
            <li>
              <h3 className="titr7 text-start">
                <span>
                  <i className="bi bi-check-lg"></i>
                </span>{" "}
                اولویت نمایش در متامارس{" "}
              </h3>
              <p>
                هنگامی که کاربران در جستجوی کسب و کارها در زمینه کاری یا مکان
                شما هستند، صفحه کسب و کار ادعا شده شما بالاتر از رقبای ادعانشده
                شما و به شکل متفاوت نمایان و کاربران قبل از دیگران شما را پیدا و
                با اعتماد بیشتر انتخاب می کنند.
              </p>
            </li>
            <li>
              <h3 className="titr7 text-start">
                <span>
                  <i className="bi bi-check-lg"></i>
                </span>
                آماده هستید؟
              </h3>
              برای شروع بهره‌مندی و از حداکثر مزایای عضویت در متامارس بر روی
              دکمه زیر کلیک کنید.
            </li>
          </ul>
          <div>
            <div
              className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
              onClick={(e) => window.close()}
            >
              بستن و بازگشت به اعای مالکیت
            </div>
          </div>
        </div>
        {/* <br />
        Persian Translation above
        <div>
      <h2>Claim Your Business Page for Exclusive Advantages!</h2>
      <p>
        Welcome to Meta business, where businesses thrive! By claiming your business page, you unlock a world of benefits and take control of your online presence.
      </p>

      <div>
        <h3>1. Enhanced Visibility</h3>
        <p>
          Claiming your business page boosts its visibility in search engines, ensuring that potential customers can easily find and connect with you. Stand out from the competition and increase your online reach.
        </p>
      </div>

      <div>
        <h3>2. Accurate Information</h3>
        <p>
          Take control of the information displayed on your business page. Keep your details up-to-date, showcase your products or services, and provide accurate business hours, ensuring that customers have the latest information about your business.
        </p>
      </div>

      <div>
        <h3>3. Engage with Customers</h3>
        <p>
          Respond to customer reviews, answer inquiries, and engage with your audience directly through your claimed business page. Build trust, show your commitment to customer satisfaction, and turn visitors into loyal customers.
        </p>
      </div>

      <div>
        <h3>4. Priority in Search Results</h3>
        <p>
          Enjoy priority placement in search results. When customers search for businesses in your industry or location, your claimed business page will appear prominently, increasing the likelihood of attracting new customers.
        </p>
      </div>

      <div>
        <h3>5. Exclusive Promotions</h3>
        <p>
          Unlock the ability to run exclusive promotions or offers on your business page. Attract more customers by showcasing special deals, discounts, or events directly on your profile.
        </p>
      </div>

      <div>
        <h3>Ready to Take Control?</h3>
        <p>
          Claiming your business page is quick, easy, and free! Click the button below to get started and maximize the benefits of being part of [Your Business Directory Name].
        </p>
        <div
            className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
            onClick={e=> window.close()}
          >
          close
          </div>
      </div>
    </div> */}
      </div>
    </div>
  );
}
