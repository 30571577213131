import { useEffect, useRef, useState } from "react";
// import { CartContext } from "../../contexts/cartContext";

export default function SearchMultiSelectBusRegister(props) {
  // console.log(props,props);
  // const{serviceIncrement}=useContext(CartContext)
  const [orgOpt, setOrgOpt] = useState(props.data || []);
  const [options, setOptions] = useState(props.data || []);
  const ulRef = useRef();
  const inputRef = useRef();
  const onInputChange = (e) => {
    // console.log(e.target.value);
    // console.log(orgOpt);

    const newOptions = orgOpt.filter((option) =>
      window
        .convert(option.state)
        .toLowerCase()
        .includes(e?.target?.value.toLowerCase())
    );

    setOptions(newOptions);
  };
  // const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
  useEffect(() => {
    // ulRef.current.style.display = "flex";
    inputRef.current.addEventListener("click", (e) => {
      ulRef.current.style.display = "flex";
      setOptions(orgOpt);
      // console.log(orgOpt);
    });
    inputRef.current.addEventListener("keyup", (e) => {
      e.stopPropagation();
      ulRef.current.style.display = "flex";
      onInputChange(e);
    });
    document.addEventListener("click", (e) => {
      if (
        ulRef?.current?.style != null &&
        e.target.closest("ul") != ulRef?.current &&
        e.target != inputRef.current
      )
        ulRef.current.style.display = "none";
    });
  });

  useEffect(() => {
    setOrgOpt(props.data);
    setOptions(props.data);
  }, [props.data]);

  // console.log(options);
  return (
    <div>
      <div className="  btn-search d-flex justify-content-between align-items-center w-100 ">
        <span className="w-100 text-center ">
          <input
            ref={inputRef}
            onChange={onInputChange}
            className="form-input3 shadow-none pe-2 w-100"
            placeholder={
              !props.formik?.values?.targetAddress[props.index]?.states?.length
                ? "  Type here..."
                : props.formik.values.targetAddress[props.index].states
                    ?.length > 1
                ? `${
                    props.formik.values.targetAddress[props.index].states.length
                  }Items Selected`
                : props.formik.values.targetAddress[props.index].states[0]
            }
            name="stateFormInput"
            autoComplete="off"
          />
          <ul
            ref={ulRef}
            id="result"
            className="list-group list-group-multiselect location-search-form "
            // style={{ display: "flex"}}
          >
            {props.formik.values.targetAddress[props.index].states?.length !==
              props.formik.values.targetAddress[props.index].statesOptions
                ?.length && (
              <div
                className="reset-btn"
                onClick={(e) => {
                  const UpdatedTarget = [...props.formik.values.targetAddress];
                  const allStates = UpdatedTarget[
                    props.index
                  ].statesOptions.map((opt) => opt.state?.toLowerCase());
                  console.log("allStates", allStates);
                  UpdatedTarget[props.index].states = allStates;
                  props.formik.setValues((prev) => ({
                    ...prev,
                    targetAddress: UpdatedTarget,
                  }));
                }}
              >
                انتخاب همه (
                {
                  props.formik.values.targetAddress[props.index].statesOptions
                    ?.length
                }
                استان)
              </div>
            )}
            {props.formik.values.targetAddress[props.index].states?.length >
              0 && (
              <div
                className="reset-btn"
                onClick={(e) => {
                  const UpdatedTarget = [...props.formik.values.targetAddress];
                  UpdatedTarget[props.index].states = [];
                  props.formik.setValues((prev) => ({
                    ...prev,
                    targetAddress: UpdatedTarget,
                  }));
                }}
              >
                پاک کردن همه
              </div>
            )}
            {options?.map((option, i) => {
              return (
                <div
                  key={i}
                  className="form-check pe-3 ps-5 my-1 d-flex justify-content-between align-items-center"
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={option.state}
                    checked={props.formik.values.targetAddress[
                      props.index
                    ]?.states.some(
                      (state) =>
                        state.toLowerCase() === option.state.toLowerCase()
                    )}
                    id={`flexCheckOptions${i}`}
                    onChange={(e) => {
                      //console.log("false", e.target.value);
                      let existStates = [
                        ...props.formik.values.targetAddress[props.index]
                          .states,
                      ];
                      // console.log("existStates", existStates);

                      if (
                        existStates.find(
                          (state) => state === e.target.value.toLowerCase()
                        )
                      ) {
                        //console.log("true", existStates);
                        const newValues = existStates.filter(
                          (state) => state !== e.target.value.toLowerCase()
                        );
                        existStates = newValues;
                      } else {
                        //console.log("false", existStates);
                        existStates.push(e.target.value.toLowerCase());
                      }
                      const UpdatedTarget = [
                        ...props.formik.values.targetAddress,
                      ];
                      UpdatedTarget[props.index].states = existStates;

                      props.formik.setValues((prev) => ({
                        ...prev,
                        targetAddress: UpdatedTarget,
                      }));
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`flexCheckOptions${i}`}
                  >
                    {option.state}
                  </label>
                </div>
              );
            })}
          </ul>
        </span>
      </div>
    </div>
  );
}
