import React from 'react'

export default function SocialMedias(props) {
if(props?.extraClass){
  const elements =  document.querySelectorAll('#socialMediasModal .social-icon')  
  Array.from(elements).map(el=> el.classList.replace("social-icon",props.extraClass))
}   
  // console.log('props',props)
  return (
    <div id="socialMediasModal" className="listing-details-socialmedia">
        
                  <div className="d-flex  flex-column align-items-center">
                    {/* <ul className="d-flex flex-wrap px-0 mx-0">
                    {props?.socialMedias?.map((socialMedia,i) => 
                    <div key={i}>
                     {socialMedia?.platform?.toLowerCase()==="pinterest" ? 
                      <li key={i} title={socialMedia?.platform}>
                       <a href={socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                       <span className="social-icon social-pinterest ">
                          <i className="bi bi-pinterest "></i>
                        </span>
                       </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="twitter" ?
                      <li key={i} title={socialMedia?.platform}>
                       <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                       <span  className="social-icon social-twitter">
                          <i className="bi bi-twitter"></i>
                        </span>
                       </a>
                      </li>
                      :
                      socialMedia?.platform?.toLowerCase()==="youtube" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span  className="social-icon social-youtube">
                          <i className="bi bi-youtube"></i>
                        </span>
                        </a>
                      </li >:                    
                        socialMedia?.platform?.toLowerCase()==="facebook" ?
                      <li key={i} title={socialMedia?.platform}>
                       <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                       <span  className="social-icon social-facebook">
                          <i className="bi bi-facebook"></i>
                        </span>
                       </a>
                      </li>:
                      socialMedia?.platform?.toLowerCase()==="instagram" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span  className="social-icon social-instagram">
                          <i className="bi bi-instagram"></i>
                        </span>
                        </a>
                      </li >:
                        socialMedia?.platform?.toLowerCase()==="telegram" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-telegram">
                          <i className="bi bi-telegram"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="whatsapp" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-whatsapp">
                          <i className="bi bi-whatsapp"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="linkedin" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-linkedin">
                          <i className="bi bi-linkedin"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="messenger" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-messenger">
                          <i className="bi bi-messenger"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="tiktok" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-tiktok">
                          <i className="bi bi-tiktok"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="github" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-github">
                          <i className="bi bi-github"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="reddit" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-reddit">
                          <i className="bi bi-reddit"></i>
                        </span>
                        </a>
                      </li>
                      :
                        socialMedia?.platform?.toLowerCase()==="snapchat" ?
                      <li key={i} title={socialMedia?.platform}>
                        <a href={(socialMedia?.url.startsWith("www")
                            ? "https://"
                            : "")+socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                        <span className="social-icon social-snapchat">
                          <i className="bi bi-snapchat"></i>
                        </span>
                        </a>
                      </li>
                      : 
                      
                      ""}
                    </div>
                   )}
                    </ul> */}
                    <ul className="d-flex flex-wrap px-0 mx-0">
                    {props?.socialMedias?.map((socialMedia,i) => 
                    <div key={i}>
                        <li key={i} title={socialMedia?.platform}>
                       <a href={socialMedia?.url} alt={socialMedia?.platform} target="_blank">
                       <span className={`social-icon social-${socialMedia?.platform?.toLowerCase()} `}>
                          <i className={`bi bi-${socialMedia?.platform?.toLowerCase()} `}></i>
                        </span>
                       </a>
                      </li>
                    </div>
                   )}
                    </ul>
                  </div>
                </div>
  )
}
