import { Outlet } from "react-router-dom"



export default function StoreDashOut() {
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
   <h1>Dashboard Store Out</h1>
    <Outlet/>
        
    </div>
  )
}
