import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CountdownBar = ({ expirationTime }) => {
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  // const countryDetails = JSON.parse(localStorage.getItem("countryDetails"))
  // console.log('remainingTime',remainingTime);
const navigate = useNavigate()
  function calculateRemainingTime() {
    const now = new Date();
    const expiresAt = new Date(expirationTime);
    const difference = expiresAt - now;
    return Math.max(0, difference);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
      
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  if(remainingTime<=0)navigate(`/${window.lcs}`)
  const progress = (remainingTime / (60 * 1000)) * 100; // Assuming the expiration time is in minutes
  const minutes = Math.floor(remainingTime / (60 * 1000));
  const seconds = Math.ceil((remainingTime % (60 * 1000)) / 1000);
  return (
    <div>
       <p>
        <span ></span>
         {minutes} : {seconds} </p>      
      <div className='countdown_bar mb-3' style={{ width: `${(remainingTime / (15 * 60 * 1000)) * 100}%` }} />
    </div>
  );
};
// const CountdownBar = ({ expirationTime }) => {
//   const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
//   console.log('remainingTime',remainingTime);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setRemainingTime(calculateRemainingTime());
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   const minutes = Math.floor(remainingTime.difference / (60 * 1000));
//   const seconds = Math.ceil((remainingTime.difference % (60 * 1000)) / 1000);

//   return (
//     <div>
//       <p>Time remaining: {remainingTime.formattedTime}</p>
//       <div style={{ width: `${(remainingTime.difference / (15 * 60 * 1000)) * 100}%`, height: '20px', backgroundColor: 'green' }} />
//     </div>
//   );
//   function calculateRemainingTime() {
//     const now = new Date();
//     const expiresAt = new Date(expirationTime);
//     const difference = expiresAt - now;
  
//     // Format remaining time using Intl.DateTimeFormat
//     const formatter = new Intl.DateTimeFormat('en-US', {
//       minute: 'numeric',
//       second: 'numeric',
//     });
//     const formattedTime = formatter.format(new Date(difference));
  
//     return { difference, formattedTime };
//   }
// };



export default CountdownBar;



