import LeftSidePanel from "./leftSide/leftSidePanel";
import RightSidePanel from "./rightSide/rightSidePanel";

const DisplayContent = () => {
    return ( 
        <div style={{"display":"flex"}} >
            <div>
                {/* <LeftSidePanel/> */}
            </div>
            <div>
                {/* <RightSidePanel/> */}
            </div>
        </div>
     );
}
 
export default DisplayContent;