import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import CartContextProvider from "./components/contexts/cartContext";
import { HelmetProvider} from 'react-helmet-async';
// import ProductsContextProvider from "./components/contexts/productsContext";


 const APP = (<Router>
  <CartContextProvider>
    {/* <ProductsContextProvider> */}
      <React.StrictMode>
       <HelmetProvider> 
          <App />
        </HelmetProvider>
      </React.StrictMode>
    {/* </ProductsContextProvider> */}
  </CartContextProvider>
</Router>)
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(APP)


