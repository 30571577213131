import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { useContext } from "react";
import { CartContext } from "../../../contexts/cartContext";
import TutorsGuide from "./tutorsGuide";
import StudentsGuide from "./studentsGuide";
import TutoringRules from "./tutoringRules";

export default function TutorsStudentsGuide() {
  const{user}=useContext(CartContext)
  const structuredData = [...renderData.structuredData,
   
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "راهنمای متادمی",
      description:
        "یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

      url: "https://www.metamarce.com/fa/ca/qc/prompt/guides",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/prompt/tutor`,
              name: `شرایط تدریس در متامارس`,
              description: "چگونه در متامارس تدریس کنم",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "راهنما و قوانین متادمی",
    description: "قوانین و شرایط کاربری و استفاده از متادمی",
    section: "metademy rules",
    url: `https://www.metamarce.com/${window.lcs}/prompt/tutor`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/prompt/tutor`,
    img: "https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type: "webpage",
  };
  return (
    <div className="tutorsStudentsGuide  ">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0 bg-deepGreen">
        {/* <div className="bg-image fixed-bg-media bg-contact-us"></div> */}

        <div className=" p-3 pb-0 px-5 pt-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start text-pink-orange ">
            راهنمای متادمی
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start ms-md-5 ms-3 mb-md-3 mb-0 text-beigeYellow"
            
          > 
            آکادمی متامارس
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>
      <div className=" mw-sub-ad">  <nav>
  <div className="nav nav-tabs mx-1" id="nav-tab" role="tablist">
    <button className="nav-link active" id="nav-tutor-tab" data-bs-toggle="tab" data-bs-target="#nav-tutor" type="button" role="tab" aria-controls="nav-tutor" aria-selected="true">راهنمای اساتید</button>
    <button className="nav-link" id="nav-student-tab" data-bs-toggle="tab" data-bs-target="#nav-student" type="button" role="tab" aria-controls="nav-student" aria-selected="false">راهنمای دانشجویان</button>
    <button className="nav-link" id="nav-rules-tab" data-bs-toggle="tab" data-bs-target="#nav-rules" type="button" role="tab" aria-controls="nav-rules" aria-selected="false">قوانین</button>
  </div>
</nav>
<div className="tab-content mt-5" id="nav-tabContent">
  <div className="tab-pane fade show active" id="nav-tutor" role="tabpanel" aria-labelledby="nav-tutor-tab"><TutorsGuide/></div>
  <div className="tab-pane fade" id="nav-student" role="tabpanel" aria-labelledby="nav-student-tab">
    <StudentsGuide/>
    </div>
  <div className="tab-pane fade" id="nav-rules" role="tabpanel" aria-labelledby="nav-rules-tab"><TutoringRules/></div>
</div>
</div>
    


      
    </div>
  );
}
