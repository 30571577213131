// import "../../../userList/userList.css";
import {
  BookmarkAdd,
  BookmarkAdded,
  ContentCopy,
  DeleteOutline,
  DoNotTouch,
  DynamicFeed,
  FilterAltOff,
  Visibility,
} from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/apiMedia";
import ToastNote from "../../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
import MyIcons from "../../../../golbal/icons/myIcons";

export default function MediaList({ access }) {
  const { user } = useContext(CartContext);
  const [user2, setUser2] = useState({ _id: user._id });
  const [loading, setLoading] = useState(false);

  const effectRan = useRef(false);
  const [data, setData] = useState({});
  const messageRef = useRef(null);

  const deleteHandle = async (id) => {
    if (window.confirm("I am agree to delete this media") !== true) return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "delete",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Delete action is completed successfully.",
    });
    if (access === "admin") {
      console.log("admin is triggred");
      const newData = data.map((da) => (da._id === id ? response.data : da));
      setData(newData);
    } else {
      console.log("user is triggred");
      const newData = data.filter((da) => da._id !== id);
      setData(newData);
    }
  };
  const inactiveHandle = async (id) => {
    if (window.confirm("I am agree to inactive this media") !== true) return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "inactive",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };
  const activationHandle = async (id) => {
    if (window.confirm("I am agree to activate this media") !== true) return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "active",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });
    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };

  useEffect(() => {
    fetchListData();
  }, [user2._id, window.location.pathname]);

  async function fetchListData() {
    setLoading(true);
    const response = await api.getListData({
      token: user.token,
      access: access,
      id: user2._id,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "action",
      headerName: "عملیات",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <span className="d-flex">
              {params.row.moreInfo.status === "01-inactive" ? (
                <Tooltip1
                  message="فعالسازی"
                  icon={
                    <DynamicFeed
                      sx={{ color: "green" }}
                      className="userListDelete m-1"
                      onClick={() => activationHandle(params.row._id)}
                    />
                  }
                />
              ) : (
                <Tooltip1
                  message="غیرفعالسازی موقت."
                  icon={
                    <FilterAltOff
                      className="userListDelete m-1"
                      onClick={() => inactiveHandle(params.row._id)}
                    />
                  }
                />
              )}
              <div onClick={() => deleteHandle(params.row._id)}>
                <MyIcons icon="delete2" />
              </div>

              <Link
                to={`/${window.lcs}/dashboard/user/media/edit/${params.row._id}?action=edit`}
                state={params.row}
              >
                <MyIcons icon="edit" />
              </Link>
            </span>
          </div>
        );
      },
    },
    {
      field: "moreInfo.status",
      headerName: "وضعیت",
      width: 80,
      renderCell: (params) => <div>{params.row.moreInfo?.status}</div>,
    },
    {
      field: "moreInfo.photo",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/${window.lcs}/business/page/${params.row.business}`}
            state={params.row}
          >
            <img
              src={params.row.moreInfo.photo}
              alt={params.row.moreInfo.title}
              className="userListImg"
              loading="lazy"
            />
          </Link>
        );
      },
    },
    {
      field: "moreInfo.title",
      headerName: "نام صفحه",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/${window.lcs}/business/page/${params.row._id}`}
            state={params.row}
          >
            <div title={params.row?.title}> {params.row?.moreInfo.title}</div>
            <div title={params.row?.email}> {params.row?.email}</div>
          </Link>
        );
      },
    },
    //    {
    //     field: "moreInfo.city",
    //     headerName: "آدرس",
    //     width: 200,
    //  renderCell:params=> <div >
    //   <span title={params.row.moreInfo?.address?.countryShort}>{params.row.moreInfo?.address?.countryShort}, </span>
    //   <span title={params.row.moreInfo?.address?.state}>{params.row.moreInfo?.address?.state}, </span>
    //   <span title={params.row.moreInfo?.address?.city}>{params.row.moreInfo?.address?.city}, </span>
    //   </div>
    //   },
    {
      field: "moreInfo.address.country",
      headerName: "کشور",
      width: 70,
      renderCell: (params) => <div>{params.row.moreInfo.address.country}</div>,
    },
    {
      field: "moreInfo.address.state",
      headerName: "استان",
      width: 70,
      renderCell: (params) => <div>{params.row.moreInfo.address.state}</div>,
    },
    {
      field: "moreInfo.address.city",
      headerName: "شهر",
      width: 70,
      renderCell: (params) => <div>{params.row.moreInfo.address.city}</div>,
    },
  ];

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}

      {!data.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[12]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
      {access === "admin" && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );
}
