
import"./slideshowGalery.css"
export default function SlideshowGalery(props) {
    // console.log(props);
let slideIndex = 1;
showSlides(slideIndex);

// Next/previous controls
function plusSlides(n) {
  showSlides(slideIndex += n);
}

// Thumbnail image controls
function currentSlide(n) {
  showSlides(slideIndex = n);  
}

function showSlides(n) {
  let i;
 
  let slides =Array.from(document.getElementsByClassName("mySlides"));
  if(!slides.length) return;
  // console.log(slides.length);
  let dots = document.getElementsByClassName("demo");
  let captionText = document.getElementById("caption");
  if (n > slides.length) {slideIndex = 1}
  if (n < 1) {slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[slideIndex-1].style.display = "block";
  dots[slideIndex-1].className += " active";
  captionText.innerHTML = dots[slideIndex-1].alt;
}


  return (
    <div className="container-bus-slider">
{props?.data?.map((photo,i) => 
<div key={i} className="mySlides ">
    
<div dir="ltr" className="numbertext ">{i+1} / {props.data.length}</div>
  <div className="container-bus-big-img-slide">  
 
   <img src={photo} className=" w-100 " alt={props.alt+i+1} loading="lazy"/>
  </div>
  </div>)}

{/* <!-- Next and previous buttons --> */}
<div className="neext-prev-container"> </div>
  <div className="prev" onClick={e => plusSlides(-1)}><i className="bi bi-caret-right-fill pt-2 pe-1"></i></div>
  <div className="next" onClick={e => plusSlides(1)}><i className="bi bi-caret-left-fill pt-2 ps-1"></i></div>

  {/* <!-- Image text --> */}
  <div className="caption-container ">
    <p className="my-2" id="caption"></p>
  </div>

  {/* <!-- Thumbnail images --> */}
  <div className="row">
    {props?.data?.map((photo,i) => 
    <div key={i} className="col-2  text-center ">
      <img className="demo cursor w-100 mt-3" src={photo}  onClick={e =>currentSlide(i+1)} alt={`${props.alt}`} loading="lazy"/>
    </div> )}
   
  </div>
</div>
  )
}
