import React, { useContext, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CartContext } from '../../../../contexts/cartContext';
import ColoredSpinner from '../../../../alret/spiners/coloredSpiner/coloredSpinner';
import apiUsers from '../../../../../utils/apiUsers';
// import api from '../../../../../utils/api';
import './chart.css'

export default function UsersOverview() {
  const {user}=useContext(CartContext)
  const[loading,setLoading]=useState(false)
  useEffect(()=> {   
      fetchData()     
  },[])
  const [data, setData]=useState([]) 


  async function fetchData(){
    setLoading(true);
    const response =  await apiUsers.getChartData({token:user.token, type:'stackedBarChart'})
    setLoading(false);
    console.log(response.data);
    if(response.error)return;
    setData(response.data)

  }



 function countryCounts (data){
  const countryCounts = {};
// Process the raw data
data.forEach((item) => {
  const lcs = item._id.lcs; // Assuming 'lcs' contains the country code
  const count = item.count;

  // Extract the country code from 'lcs' (e.g., 'ca' from 'fa/ca/on')
  const country = lcs.split('/')[1];

  // Update or initialize the count for the country
  if (countryCounts[country]) {
    countryCounts[country] += count;
  } else {
    countryCounts[country] = count;
  }
});

// Convert the country counts object to an array of objects
const result = Object.keys(countryCounts).map((country) => ({
  country,
  count: countryCounts[country],
}));
return result
}


function languageCounts (data){
const languageCounts = {};

// Process the raw data
data.forEach((item) => {
  const lcs = item._id.lcs; // Assuming 'lcs' contains the language code
  const count = item.count;

  // Extract the language code from 'lcs' (e.g., 'fa' from 'fa/ca/on')
  const language = lcs.split('/')[0];

  // Update or initialize the count for the language
  if (languageCounts[language]) {
    languageCounts[language] += count;
  } else {
    languageCounts[language] = count;
  }
});

// Convert the language counts object to an array of objects
const result = Object.keys(languageCounts).map((language) => ({
  language,
  count: languageCounts[language],
}));
// console.log(result);
return result
}


function countryStateCounts (data){
  const countryStateCounts = [];

  // Process the raw data
  data.forEach((item) => {
    const lcs = item._id.lcs; // Assuming 'lcs' contains the country and state information
    const count = item.count;
  
    // Split 'lcs' into country and state
    const [language, country, state] = lcs.split('/');
  
    // Find the country object in the array or create a new one
    let countryObj = countryStateCounts.find((c) => c.country === country);
    if (!countryObj) {
      countryObj = { country };
      countryStateCounts.push(countryObj);
    }
  
    // Update or initialize the count for the state within the country
    countryObj[state] = (countryObj[state] || 0) + count;
  });
  
  console.log(countryStateCounts);

console.log(countryStateCounts);
return countryStateCounts
}
const regionNames = new Intl.DisplayNames(["en"], { type: "region" });


// // Calculate the totals
const totalUsers = calculateTotalUsers(data); 

// Function to render the table recursively
function renderTable(data, level = 0) {
  if (!data || typeof data !== "object") {
    return null;
  }

  const keys = Object.keys(data);

  return (
    <table className="user-table">
      <thead>
        <tr>
          <th>{level === 0 ? "Language" : level === 1 ? "Country" : "State"}</th>
          <th>Total Users</th>
        </tr>
      </thead>
      <tbody>
        {keys.map((key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>
              {typeof data[key] === "number" ? data[key] : renderTable(data[key], level + 1)}
            </td>
          </tr>
        ))}
      </tbody>
      {/* <tfoot>
        <tr>
          <td>Total</td>
          <td>{data['Total Users']}</td>
        </tr>
      </tfoot> */}
    </table>
  );
}

function calculateTotalUsers(data) {
  const totals = {};

  function processLcs(lcs, count) {
    const parts = lcs.split("/");
    let currentLevel = totals;

    parts.forEach((part, index) => {
      if (!currentLevel[part]) {
        currentLevel[part] = index === parts.length - 1 ? count : {};
      } else if (typeof currentLevel[part] === "number") {
        currentLevel[part] += count;
      }
      currentLevel = currentLevel[part];
    });
  }

  data.forEach((user) => {
    const { _id, count } = user;
    if (_id && typeof _id.lcs === "string") {
      processLcs(_id.lcs, count);
    }
  });

  // Add the "Total Users" key to the totals object
  totals['Total Users'] = data.reduce((total, user) => total + user.count, 0);

  return totals;
}






  
  return (
    <div>
      {loading&&<ColoredSpinner/>}      
      {data.length > 0 && (
        <div>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={countryCounts (data)}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="country" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" stackId="a" fill="#8884d8" /> 
    </BarChart>
  </ResponsiveContainer>
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={languageCounts (data)}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="language" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" stackId="a" fill="#8884d8" />
 
    </BarChart>
  </ResponsiveContainer>
  <div>
  {countryStateCounts(data)?.map((countryData,i) => (
  <div key={countryData.country}>   
    <h2>{regionNames.of(countryData.country.toUpperCase())} Chart</h2>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={Object.entries(countryData).slice(1)} // Exclude "country" property
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="0" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="1" stackId="a" fill={`#6554d6`} name='count' />
      </BarChart>
    </ResponsiveContainer>
  </div>
))}
  </div>

  <div>
    <h2>User Details Table</h2>
    {renderTable(totalUsers)}
  </div>



       </div>
)}


    </div> 
  );

  


  
}






