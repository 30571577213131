import React, { useRef, useState } from "react";
import { Rating } from "@mui/material";
import ToastNot from "../../../golbal/notifications/toastNote";
import ShowAddress from "../../../golbal/showAddress";

export default function MapTutorCard({data,url,key,section}) {
  const messageRef = useRef(null);
  return (
    <div key={data._id} className="map-bus-card">
      <ToastNot messageRef={messageRef} />
      <div className="card mx-2 border-0 shadow h-100">
        <div className="card-img-pack ">
          <div className="business-card-img-wrap">
            <a
              className="image-container image-container-bus"
              href={url}
              target="_blank"
            >
              <img
                src={data.moreInfo.img}
                className="card-img-top  image-card business-card-img"
                alt={data?.name||data?.name?.[window.lang]}
                loading="lazy"
              />
            </a>
          </div>
          {data?.category&&<a
            href={`/${window.lcs}/demy/${section}/filters??mainCat=${
              data.category?.mainCat?.[window.lang]
            }&mainCatId=${data.category?.mainCat?._id}`}
            title={data.category?.mainCat[window.lang]}
            target="_blank"
          >
            <img
              src={data.category?.categoryPhoto}
              className="card-logo "
              alt={data.category?.mainCat[window.lang]}
              loading="lazy"
            />
          </a>}      
        </div>
        <div className="card-body mt-2">
          <h5 className="card-title card-titel1 m-1">
            {data?.name||data?.center}
            
          </h5>

          {/* <!-- *******rating stars shold show up here --> */}

          {section!=="center"&&<p dir="ltr" className="text-start">
            <span className="map-card-rating">
              <Rating
                name="read-only"
                value={Number(data?.feedbackInfo?.rate)}
                readOnly
                precision={0.5}
              />
            </span>
          </p>}

          <div className="" dir="ltr">
          <ShowAddress address={data?.moreInfo?.address} />
            {/* <ul className="list-group">
              <li className="group-list-item2 my-1 text-center">
                <i className="bi bi bi-geo-alt mx-2"></i>
                {`${window.capitalize(data?.moreInfo?.address?.city)},
                  ${window.capitalize(data?.moreInfo?.address?.state)},
                  ${window.capitalize(data?.moreInfo?.address?.countryShort)}
              `}
              </li>
            </ul> */}
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
