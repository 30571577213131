import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Check,
  CheckCircle, 
  Error,
  Publish,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
// import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
// import SearchMultiSelectFilters from "../../../golbal/dropdown/searchMultiSelectFilters";
import countryStates from "../../../../utils/countryStates";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import apiMP from "../../../../utils/apiMP";
import FormikListErr from "../../../persian/common/alerts/formikListErr";
// import AddSocialMedia from "../../../persian/addSocialMedia";

export default function UsedRegister({ action }) {
  const { lang, state,country, countryShort, stateShort } = tools.lcsn(
    window.location.pathname
  );
  const [states, setStates] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  const [originalLogo, setOriginalLogo] = useState(null);
  const [photos, setPhotos] = useState({
      blob: [],
      resized: [],
      urls: [],    
  });
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: "از فایل با فرمت عکس استفاده کنید.",
      });
    // console.log("photoHandle auth", originalPhoto);
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  console.log('photos',photos);
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/mp/used",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };
  const sendBodyPhotosFirebase = async () => {
    if (photos.blob == []) return;
    console.log('sendBusinessCardToFirebase04');
    const uplaodedURLS2 = await Promise.all(
      photos.blob?.map(async (img) => {
        const formData = new FormData();
        formData.append("file", img);
        const response = await api.uploadSingleFile({
          token: user.token,
          formData,
          path: "images/mp/used",
        });
        if (response.error)
          return messageRef.current.showError({
            message: (
              <div>
                <div>مشکل در ذخیره فایل.</div>
                <div dir="ltr">{response.error}</div>
              </div>
            ),
          });
        return response.data;
      })
    );
    console.log('sendBusinessCardToFirebase04',uplaodedURLS2);
    return uplaodedURLS2;
  };
  function deleteHandle(e) {
    const newArray = photos.resized.filter(
      (x, i) => i != e.target.id
    );
    const newArrayBlob = photos.blob.filter(
      (x, i) => i != e.target.id
    );
    setPhotos((prevPhotos) => ({
      ...prevPhotos,    
        resized: newArray,
        blob: newArrayBlob,    
    }));
  }
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
 console.log('countryDetails',countryDetails);
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      action,
      // logo: "",      
      title: "",
      description: "",
      _id: "",
      lang,
      lcs: window.lcs,
      tags: "",
      email: user?.email,
      category: {
        mainCat: {},
        // subCat: {},
      },
      photos:[],
      contact: "",
      condition: "usedGood",
      delivery: "doorPick",
      isFree:false,
      isActive:true,
      price: {        
        rate: 0,
        currency:countryDetails?.currency,
      },  
      location: {
        displayAddress: false,
        preciseAddress:"",
        googleAddress: "",
        country,
        countryShort,
        state,
        stateShort,
        city: "",
        // route: "",
        // municipality: "",
        // streetNumber: "",
        // number: "",
        // postalCode: "",
        // lat: "",
        // lng: "",
      },
    },
    onSubmit: async (values) => {
      console.log("onSubmit");
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });
      const photos = await sendBodyPhotosFirebase()
      values.photos=photos
      console.log("passed Formik", values);
      
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiMP.registerUsed({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، نیازمندی با موفقیت ثبت شد.",
      });
      
      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });

      navigate(`/${window.lcs}/mp/used/filters`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      // name: Yup.string()
      //   .notRequired("لطفاً نام شرکت را وارد کنید.")
      //   .max(200, "نام شرکت حداکثر باید 200 حرف باشد .")
      //   .min(3, "نام شرکت حداقل باید 3 حرف باشد."),
      title: Yup.string()
        .required("لطفاً عنوان آگهی را وارد کنید.")
        .max(200, "عنوان آگهی حداکثر باید 200 حرف باشد .")
        .min(3, "عنوان آگهی حداقل باید 3 حرف باشد."),

      // education: Yup.string()
      //   .required("لطفاً اطلاعات تکمیلی را وارد کنید.")
      //   .max(4000, "اطلاعات تکمیلی حداکثر باید 4000 حرف باشد .")
      //   .min(10, "اطلاعات تکمیلی حداقل باید 10 حرف باشد."),
      // category: Yup.object().shape({
      //   mainCat: Yup.object().shape({
      //     _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
      //   }),
      //   subCat: Yup.object().shape({
      //     _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
      //   }),
      // }),
      // phone: Yup.string()
      //   .trim()
      //   .when("suggestMode", (suggestMode, schema, value) => {
      //     return (
      //       value.parent.phone?.length &&
      //       schema.test(
      //         "is-valid-phone",
      //         "شماره تلفن نامعتبر میباشد.",
      //         (value) => {
      //           return isValidPhoneNumber(value || "");
      //         }
      //       )
      //     );
      //   }),
      // ... your other validation rules for phone

      // email: Yup.string()
      //   .required("لطفا ایمیل خود را وارد نمائید.")
      //   .trim()
      //   .lowercase()
      //   .email("ایمیل را به درستی وارد کنید."),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      // website: Yup.string().matches(
      //   urlRegex,
      //   " آدرس وبسایت را به درستی وارد کنید."
      // ),
      // socialMedia: Yup.array().of(
      //   Yup.object().shape({
      //     platform: Yup.string(),

      //     url: Yup.string()
      //       .when("platform", (platform, schema) => {
      //         return platform
      //           ? schema.required("لطفاً آدرس URL را وارد نمایید.")
      //           : schema;
      //       })
      //       .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
      //         if (!value) return true; // Allow empty value
      //         // Regular expression to validate URL
      //         return urlRegex.test(value);
      //       }),
      //   })
      // ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      // distances: Yup.array().of(
      //   Yup.object().shape({
      //     distance: Yup.string().required("Please enter the distance."),
      //     cost: Yup.string().required("Please enter the cost."),
      //   })
      // ),
      // salary: Yup.object().shape({
      //   min: Yup.number().required(" حداقل حقوق را وارد کنید.").min(1),
      //   period: Yup.string().required(" دوره را انتخاب کنید."),
      // }),
      // languages: Yup.array().of(
      //   Yup.object().shape({
      //     level: Yup.string().notRequiredequired("لطفا مقطع آموزشی را انتخاب نمائید."),
      //     subjects: Yup.array()
      //       .of(Yup.string()) // Validate individual subject items if needed
      //       .min(1, "لطفا دست کم یک سرفصل آموزشی را انتخاب نمائید.") // Ensure at least one subject is entered
      //   })
      // )
    }),
  });

  useEffect(() => {
    fetchDetails();
    // fetchSubjects()
  }, [id]);

  async function fetchDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiMP.usedDetails({token:user.token , id});
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  const bodyPhotosHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });

    setLoading((pre) => ({ ...pre, loading14: true }));
    const resizedPhotos = await Promise.all(
      [...Array.from(e.target.files)]
        .slice(0, 6)
        .map(
          async (x) =>
            await tools.resizeFile({ file: x, width: 1000, height: 1000 })
        )
    );
    const resizedPhotosBlob = await Promise.all(
      [...Array.from(e.target.files)].slice(0, 6).map(
        async (x) =>
          await tools.resizeFile({
            file: x,
            width: 1000,
            height: 1000,
            blob: true,
          })
      )
    );
    setLoading((pre) => ({ ...pre, loading14: false }));
    if (resizedPhotos.length < 6) {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,        
          resized: photos.resized
            .slice(resizedPhotos.length - 6)
            .concat(resizedPhotos),
          blob: photos.blob
            .slice(resizedPhotos.length - 6)
            .concat(resizedPhotosBlob),
          urls: [],
        
      }));
    } else {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
          resized: resizedPhotos,
          blob: resizedPhotosBlob,
          urls: [],
    
      }));
    }
  };


  console.log("formik", formik.values);
  console.log("formik.errors", formik.errors);
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          }, 
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/dashboard/mp/registerused`,
              name: "ثبت رایگان نیازمندی",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی نیازمندیها",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "آگهی نیازمندی",
    description: "ثبت آگهی نیازمندی در شهر شما",
    section: "used register",
    url: `https://www.metamarce.com/${window.lang}/ca/qc/mp/registerused`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "register",
  };

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">ثبت نیازمندی</span>
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="row userShow"
        name="jobRegForm00"
        autoComplete="true"
      >
        {/* Right Section */}
        <div className="col-md-6 px-3">
          <div>
            <div className="row">
              <div className="col px-3">
                <div className="mb-5 ">
                  <CatsSelector formik={formik} section={"used"} />
                  <div>
                    <label
                      className="form-label mt-3 titr6 mb-0 required"
                      htmlFor="title"
                    >
                       عنوان آگهی
               
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      className={`form-control py-1 box2 ${addErrClass({
                        formik,
                        field: "title",
                      })} `}
                      id="title"
                      placeholder=""
                      name="title"
                      {...formik.getFieldProps("title")}
                    />
                    <FormikErrRender formik={formik} field="title" />
                  </div>
                  <div className="">
                    <p className="form-label mt-3 titr6 mb-0">Ads Status</p>
                  <select
                    className={`login-btn my-0 ${addErrClass({
                      formik,
                      field: "status",
                    })}`}
                    name="status"
                    value={formik.values.status}
                   onChange={e=> formik.setFieldValue("status",e.target.value)}
                  >                    
                    <option value="active"> Active</option>
                    <option value="pending">Pending </option>
                    <option value="sold">Sold</option>
                  
                  </select>
                  </div>
                  <div className="">
                    <p className="form-label mt-3 titr6 mb-0">وضعیت</p>
                    <div>
                    {renderData?.condition?.map((cond) => (
                      <div className="form-check form-check-px1 m-2">
                        <input
                          checked={formik.values.condition === cond.name}
                          className="form-check-input"
                          type="radio"
                          name="condition"
                          id={"condition" + cond.name}
                          value={cond.name}
                          onChange={(e) =>
                            formik.setValues((pre) => ({
                              ...pre,
                              condition: e.target.value,
                            }))
                          }
                        />
                        <label
                          className="form-check-label d-flex"
                          htmlFor={"condition" + cond.name}
                        >
                          {cond[lang]}
                        </label>
                      </div>
                    ))}
                    </div>
                  </div>
                  <div className="">
                    <p className="form-label mt-3 titr6 mb-0">Delivery</p>
                    <div>
                    {renderData?.delivery?.map((deliver) => (
                      <div className="form-check form-check-px1 m-2">
                        <input
                          checked={formik.values.delivery === deliver.name}
                          className="form-check-input"
                          type="radio"
                          name="delivery"
                          id={"delivery" + deliver.name}
                          value={deliver.name}
                          onChange={(e) =>
                            formik.setValues((pre) => ({
                              ...pre,
                              delivery: e.target.value,
                            }))
                          }
                        />
                        <label
                          className="form-check-label d-flex"
                          htmlFor={"delivery" + deliver.name}
                        >
                          {deliver[lang]}
                        </label>
                      </div>
                    ))}
                    </div>
                  </div>

                  <div className="mb-5">
                    <label htmlFor="description" className="form-label titr6">Description</label>
                    <textarea
                    id="description"
                      className={`form-control ${addErrClass({
                        formik,
                        field: "description",
                      })}`}
                      rows="3"
                      name="description"
                      defaultValue={formik.values.description}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          description: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="description" />
                  </div>
                  <div>
                <p className="form-label mt-5 titr6 required"> آدرس فروشنده</p>
                <CountryStateCitySelector formik={formik} />
              </div>
              <div className="mb-5">
                    <label className="form-label titr6">آدرس دقیق تر</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "preciseAddress",
                      })}`}
                      rows="3"
                      name="preciseAddress"
                      defaultValue={formik.values.location.preciseAddress}
                      onBlur={(e) =>formik.setFieldValue("location.preciseAddress",e.target.value)
                        // formik.setValues((pre) => ({
                        //   ...pre,
                        //   preciseAddress: e.target.value,
                        // }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="location.preciseAddress" />
                  </div>
                  <div className="mb-5">
                    <label className="form-label titr6">Contact Information</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "contact",
                      })}`}
                      rows="3"
                      name="contact"
                      defaultValue={formik.values.contact}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          contact: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="contact" />
                  </div>
                 
                  

                  <div className="my-5">
                    <div className="form-label titr6">مبلغ</div>
                    <div className="form-switch">
                      <label htmlFor="activeSwitchFree">Free Item</label>
              <input
                className="form-check-input form-check-input-filter1  m-0 p-0"
                type="checkbox"
                role="switch"
                id="activeSwitchFree"
                checked={formik.values?.isFree}
                onChange={(e) =>
                  formik.setValues((prev) => ({
                    ...prev,
                    isFree: !formik.values.isFree,
                  }))
                }
              />
                    
                  </div>

              {!formik.values.isFree&&<div className="d-flex">
            
                <div className=" mx-2">
                  <input
                    id="price-rate"
                    type="number"
                    autoComplete="off"
                    aria-label="Amount"
                    className={`form-control ${addErrClass({
                      formik,
                      field: "price.rate",
                    })}`}
                    placeholder="با حروف لاتین تایپ کنید"
                    // {...formik.getFieldProps("price.rate")}
                    value={formik.values.price.rate}
                    onChange={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        price: {
                          ...pre.price,
                          rate: e.target.value,
                          cadValue:
                            Number(e.target.value) / Number(pre.price?.exRate),
                        },
                      }))
                    }
                  />
                </div>
                ({formik.values.price.currency})
              </div>}
              <FormikErrRender formik={formik} field="price.rate" />
              <FormikErrRender formik={formik} field="price.currency" />
            </div>
               
                  {/* <div className="mb-5">
                    <label className="form-label titr6">Hobbies</label>
                    <textarea
                      className={`form-control ${addErrClass({
                        formik,
                        field: "hobbies",
                      })}`}
                      rows="3"
                      name="hobbies"
                      defaultValue={formik.values.hobbies}
                      onBlur={(e) =>
                        formik.setValues((pre) => ({
                          ...pre,
                          hobbies: e.target.value,
                        }))
                      }
                    ></textarea>
                    <FormikErrRender formik={formik} field="hobbies" />
                  </div> */}

                  {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="emailApply"
                    >
                      Email
                      <Tooltip1
                        message={
                          <div>
                            <div>این ایمیل محرمانه است و منتشر نخواهد شد.</div>
                            <div>
                              درخواست همکاری توسط متامارس به آن فرستاده می‌شود.
                            </div>
                          </div>
                        }
                      />
                    </label>
                    <input
                      dir="ltr"
                      id="emailApply"
                      {...formik.getFieldProps("email")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "email",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.email}
                    />
                    <FormikErrRender formik={formik} field="email" />
                  </div> */}
                  {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="PhoneNumber"
                    >
                      Phone Number
                    </label>
                    <input
                      dir="ltr"
                      id="PhoneNumber"
                      {...formik.getFieldProps("phone")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "phone",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.phone}
                    />
                    <FormikErrRender formik={formik} field="phone" />
                  </div> */}

                  {/* <div>
                    <label
                      className="form-label mt-5 titr6 mb-0 required"
                      htmlFor="website"
                    >
                      Website
                    </label>
                  </div> */}
                  {/* <div>
                    <input
                      dir="ltr"
                      id="website"
                      {...formik.getFieldProps("website")}
                      className={`userUpdateInput  form-control box2 ${addErrClass(
                        {
                          formik,
                          field: "website",
                        }
                      )}`}
                      type="text"
                      autoComplete="off"
                      placeholder={formik.values.website}
                    />
                    <FormikErrRender formik={formik} field="website" />
                  </div> */}
                  {/* <div className="form-label mt-5 titr6 mb-0  border-0 ">
                    شبکه‌های اجتماعی
                  </div> */}

                  {/* <div className=" border-0 mb-0 px-5">
                    <AddSocialMedia formik={formik} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Left Section */}
        <div className="userUpdateItem col-md-6 px-3">
          <div className="d-flex">
            <div className="userUpdateUpload d-flex flex-column justify-content-center">
              <img
                src={resisedPhotoAuth || formik.values?.img}
                alt={formik.values.name}
                className="userShowImg-profile"
                loading="lazy"
              />

              <label className="my-2" htmlFor="fileAuth">
                <Publish className="userUpdateIcon" />
                تغییر عکس
              </label>
              <input
                type="file"
                id="fileAuth"
                onChange={(e) =>
                  photoHandle(e, setResisedPhotoAuth, setOriginalPhoto)
                }
                style={{ display: "none" }}
              />
              <span
                className="userUpdateButton edit-btn"
                style={{ display: resisedPhotoAuth ? "block" : "none" }}
                onClick={(e) =>
                  uploadFileDatabase(
                    e,
                    originalPhoto,
                    "img",
                    setResisedPhotoAuth
                  )
                }
              >
                بارگذاری
              </span>
            </div>

         <div>
         <label
              htmlFor="formFileMultiple"
              className="form-label  form-titr mb-2"
            >
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              آلبوم عکس‌های کسب و کار
              <Tooltip1
                              message="این عکس ها در گالری اختصاصی صفحه کسب و کار شما نمایش داده خواهد شد. (حداکثر 6 عکس)"
                              position="top-end"
                            />
            </label>
            <div className="mb-0 form-group files">
              <div className="d-flex ">
                <input
                  disabled={loading.loading14 || loading.loading4}
                  className="form-control  p-3"
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  data-mdb-max-file-quantity="6"
                  id="formFileMultiple"
                  dir="ltr"
                  multiple
                  max={5}
                  onChange={bodyPhotosHandle}
                />
                {loading.loading14 && (
                  <CircularProgress color="inherit" size="20px" />
                )}
                {!formik?.values?._id
                  ? ""
                  : photos?.resized?.length > 0 &&
                    (photos?.urls?.length > 0 ? (
                      <Check />
                    ) : (
                      <span
                        className="btn btn-primary login-btn m-3"
                        onClick={async (e) => {
                          setLoading((pre) => ({
                            ...pre,
                            loading4: true,
                          }));
                          const urls = await sendBodyPhotosFirebase();
                          setLoading((pre) => ({
                            ...pre,
                            loading4: false,
                          }));

                          setPhotos((pre) => {
                            const photos = { ...pre };
                            photos.urls = urls;

                            const formikValues = { ...formik.values };
                            formikValues.photos = urls;
                            formik.setValues(formikValues);

                            return photos;
                          });
                        }}
                      >
                        بفرست
                        {loading.loading4 && (
                          <CircularProgress color="inherit" size="20px" />
                        )}
                      </span>
                    ))}
              </div>

              {photos?.resized?.length > 0
                ? photos?.resized?.map((img, i) => (
                    <>
                      <img
                        key={i}
                        className="userShowImg userShowImg-busphotos"
                        src={img}
                        alt="userShowImg"
                        loading="lazy"
                      />
                      <i
                        id={i}
                        className="bi bi-trash"
                        style={{ color: "red" }}
                        onClick={deleteHandle}
                      ></i>
                    </>
                  ))
                : formik?.values?.photos?.length > 0
                ? formik?.values?.photos.map((img, i) => (
                    <>
                      <img
                        key={i}
                        className="userShowImg userShowImg-busphotos"
                        src={img}
                        alt={formik?.values.busName?.[window.lang] + i}
                        loading="lazy"
                      />
                      {/* <i
                id={i}
                className="bi bi-trash"
                style={{ color: "red" }}
                onClick={deleteHandle}
              ></i> */}
                    </>
                  ))
                : ""}
            </div>
         </div>


          </div>
          {/* <div className=" mb-5 p-2">
                    <p className="form-label titr6">حقوق</p>
                    <div className="d-flex">
                      <div className=" mx-2">
                        <input
                          type="text"
                          autoComplete="off"
                          aria-label="Amount (to the nearest dollar)"
                          className={`form-control ${addErrClass({
                            formik,
                            field: "salary.min",
                          })}`}
                          placeholder="Min"
                          {...formik.getFieldProps("salary.min")}
                        />
                      </div>

                      <p className="mt-2 mx-2">تا</p>
                      <div className=" mx-2">
                        <input
                          type="text"
                          autoComplete="off"
                          aria-label="Amount"
                          className="form-control"
                          placeholder="Max"
                          {...formik.getFieldProps("salary.max")}
                        />
                      </div>
                      {loading.currencies ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        <select
                          className="login-btn my-2"
                          name="salaryCurrency"
                          value={formik.values.salary.currency}
                          // {...formik.getFieldProps("salary.currency")}
                          onChange={handleChange}
                        >
                          <option>انتخاب کنید :</option>
                          {currencies.map((curr) => (
                            <option value={curr.currency}>
                              {curr.currency}
                            </option>
                          ))}
                          {currencies.length < 10 && (
                            <option value="more">موارد بیشتر</option>
                          )}
                        </select>
                      )}

                    </div>
                    <FormikErrRender formik={formik} field="salary.min" />
                    <div
                      className={`px-3 d-flex ${addErrClass({
                        formik,
                        field: "salary.period",
                      })}`}
                    >
                      {renderData.jobPeriod.map((period) => (
                        <div className="form-check form-check-px1 m-2">
                          <input
                            defaultChecked={formik.values.salary.period?.includes(
                              period.name
                            )}
                            className="form-check-input"
                            type="radio"
                            name="jobPeriod"
                            id={"jobPeriod" + period.name}
                            value={period.name}
                            onChange={(e) =>
                              formik.setValues((pre) => ({
                                ...pre,
                                salary: {
                                  ...pre.salary,
                                  period: e.target.value,
                                },
                              }))
                            }
                          />
                          <label
                            className="form-check-label d-flex"
                            htmlFor={"jobPeriod" + period.name}
                          >
                            {period[lang]}
                          </label>
                        </div>
                      ))}
                      <FormikErrRender formik={formik} field="salary.period" />
                    </div>
                    <div className=" mx-2">
                        <textarea
                          type="text"
                          autoComplete="off"
                          aria-label="Amount (to the nearest dollar)"
                          className={`form-control ${addErrClass({
                            formik,
                            field: `salary.desc`,
                          })}`}
                          placeholder="توضیحات بیشتر در مورد حقوق درخواستی"
                          {...formik.getFieldProps(`salary.desc`)}
                        />
                      </div>
                  </div> */}
          
       <br /><br /><br />
          {/* <div>
            <p id="tagsInput" className="mt-5 form-titr ">
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              برچسب‌ها (با "+" جدا شوند )
              <Tooltip1
                message={
                  <div>
                    <div>
                      برچسب‌ها - تگ - را توسط '+' (بدون فاصله)از یکدیگر جداکنید.
                    </div>
                    <div>حداکثر ۱۰ برچسب.</div>
                  </div>
                }
              />
              <input
                type="text"
                autoComplete="off"
                name="other"
                placeholder={
                  formik?.values?.tags ||
                  `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
                }
                className="form-input1 mt-3"
                {...formik.getFieldProps("tags")}
              />
            </p>
          </div> */}

          {/* <div>
      <span className="p-0 m-z "> مهارتهای زبانی</span>
          <LangsLevel formik={formik}/> 
      </div> */}
        </div>
        <FormikListErr formik={formik}/>
        <SubmitBtn formik={formik} text="" />
      </form>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    console.log(states);
    // const state = countryStates.find(
    //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    // ).states[0];
    setStates(states);

    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.country = regionNames.of(countryShort).toLowerCase();
      location.countryShort = countryShort.toLowerCase();
      location.state = "";
      location.stateShort = "";
      location.cities = [];
      return { ...prev, location };
    });
  }
  function setCitiesHandle(value) {
    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.cities = value;
      return { ...prev, location };
    });
  }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lcs: data?.lcs ?? window.lcs,
    lang: data?.lang ?? window.lang,
    title: data.title,
    category: data.category,
    email: data.email,
    // logo: data.moreInfo.logo,
    description: data.moreInfo.description,
    status: data.moreInfo.status,
    tags: data.moreInfo.tags.join("+") || "",
    photos: data.moreInfo.photos,
    contact: data.moreInfo.contact,
    condition: data.moreInfo.condition,
    delivery: data.moreInfo.delivery,
    isFree: data.moreInfo.isFree,
    isActive: data.moreInfo.isActive,
    price: data.moreInfo.price,
    // salary: data.moreInfo.salary,
    description: data.moreInfo.description,   
    location: data.moreInfo.address,   
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiMP.editUsed({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " نیازمندی با موفقیت ویرایش شد.",
  });

  navigate(`/${window.lcs}/mp/used/filters`, {
    state: response.data,
  });
}



