import { NavLink } from "react-router-dom";

export default function BusDashListNav() {
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/business/list/all`}
        className={`sidebarListItem m-1 link ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        Business list
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/business/list/overview`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        Overview{" "}
      </NavLink>
    </div>
  );
}
