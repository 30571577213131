import React, { useEffect, useState } from "react";
import apiDemy from "../../../../utils/apiDemy";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import { Checkbox, CircularProgress } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";

export default function AddLevelSub({
  formik,
  loading,
  setLoading,
  user,
  messageRef,
}) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    fetchSubjects();
    //this is to avoid to remove all  levels in the first render on the edit mode
    if (!isFirstRender) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        levelSub: [{ level: "", subjects: [] }],
      }));
    } else {
      setIsFirstRender(false);
    }
  }, [formik.values.category.mainCat._id]);
  const [subjects, setSubjects] = useState({
    school: [],
    university: [],
  });
  // const [fsubjects, setFSubjects] = useState(subjects);
  // function searchFiltersHandle(e, level) {
  //   console.log("subjects[level]", subjects[level]);
  //   const newI = subjects[level]?.filter((option) =>
  //     window
  //       .convert(option)
  //       .toLowerCase()
  //       .includes(e?.target?.value.toLowerCase())
  //   );
  //   setFSubjects((pre) => ({ ...pre, [level]: newI }));
  // }
  async function addNewSubHandle(e, level) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;
    console.log(value, level);
    if (e.key === "Enter" || e.type === "click") {
      console.log("passed");
      if (!formik.values?.category?.mainCat?._id?.length)
        return messageRef.current.showError({
          message: "لطفا دسته بندی اصلی را انتخاب نمائید.",
        });

      if (
        window.confirm(
          `آیا با اضافه کردن سرفصل ${value}  در دسته بندی اصلی ${
            formik.values.category.mainCat[window.lang]
          } موافق هستید ؟ `
        ) !== true
      )
        return;
      setLoading((pre) => ({ ...pre, addSub: true }));
      const data = {
        lcs: window.lcs,
        lang: window.lang,
        level,
        subject: value,
        mainCat: formik.values.category.mainCat,
      };
      setLoading((pre) => ({ ...pre, addSub: false }));
      const response = await apiDemy.createSubject({ token: user.token, data });
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div>مشکل در عملیات.</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: ".درس با موفقیت اضافه شد.\n لطفا درس مورد نظر را انتخاب کنید",
      });
      e.target.parentElement.querySelector("input").value = "";
      document.getElementById(`${level}AddSubBox`).classList.toggle("d-none");
      setSubjects((pre) => {
        const newS = { ...pre };
        newS[level] ??= [];
        newS[level].push(response.data.subject);
        return newS;
      });
    }
  }
  async function fetchSubjects() {
    if (!formik.values?.category?.mainCat?._id?.length)
      return alert("please choose your category");
    setLoading((pre) => ({ ...pre, subject: true }));
    const response = await apiDemy.fetchSujectsList({
      token: user.token,
      id: formik.values.category.mainCat._id,
    });
    setLoading((pre) => ({ ...pre, subject: false }));
    if (response.error) return alert(response.error);
    const subjects = { ...response.data[0], ...response.data[1] };
    setSubjects(subjects);
    // setFSubjects(subjects);
  }

  return (
    <div className="" id="maintable3">
      {formik.values?.levelSub.map((ls, parentIndex) => (
        <div key={parentIndex} className="mb-2 tutor-color16 py-3">
          <div id={parentIndex} className="input-group mb-1 addSocialMedia">
            {/* Start choose study levels */}
            <div>
              <select
                className="login-btn selectpicker shadow-none m-2"
                aria-label="Default select example"
                name={"levelSector" + parentIndex}
                value={ls.level}
                onChange={(e) => {
                  const newLevel = e.target.value;
                  formik.setValues((prev) => ({
                    ...prev,
                    levelSub: prev.levelSub.map((sub, index) => {
                      if (index === parentIndex) {
                        // Reset subjects to [] whenever the level changes
                        return { level: newLevel, subjects: [] };
                      }
                      return sub;
                    }),
                  }));
                }}
              >
                <option value="">انتخاب کنید :</option>
                {formik.values.category.mainCat._id ===
                  "65cbc5e3e18353df0b52b6f5" &&
                  !formik.values.levelSub.some(
                    (sub, i) => i !== parentIndex && sub.level === "school"
                  ) && <option value="school">دبستان | دبیرستان</option>}
                {formik.values.category.mainCat._id ===
                  "65cbc5e3e18353df0b52b6f5" &&
                  !formik.values.levelSub.some(
                    (sub, i) => i !== parentIndex && sub.level === "university"
                  ) && <option value="university">کالج | دانشگاه</option>}
                {/* Add 'all' level option */}
                {formik.values.category.mainCat._id !==
                  "65cbc5e3e18353df0b52b6f5" &&
                  !formik.values.levelSub.some(
                    (sub, i) => i !== parentIndex && sub.level === "all"
                  ) && <option value="all">زمینه تدریس</option>}
              </select>
              {loading.addSub && <CircularProgress color="inherit" />}
            </div>
            {/* Start select Subjects */}
            {ls.level.length > 0 && (
              <div className="w-100 px-2">
                <Autocomplete
                  autoComplete="off"
                  multiple
                  id={`checkboxes-tags-demo${parentIndex}`}
                  options={subjects[ls.level] || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8, fontFamily: "vazir" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ fontFamily: "vazir" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="انتخاب درس"
                      placeholder="انتخاب کنید"
                    />
                  )}
                  onChange={(e, subjects) =>
                    formik.setValues((pre) => {
                      let levelSub = [...pre.levelSub];
                      levelSub[parentIndex].subjects = subjects;
                      return { ...pre, levelSub };
                    })
                  }
                  // Use formik values directly for the value prop
                  value={ls.subjects}
                />
              </div>
            )}
            {/* End select Subjects */}
          </div>
          <FormikErrRender
            formik={formik}
            field={`levelSub.${parentIndex}.level`}
          />
          <FormikErrRender
            formik={formik}
            field={`levelSub.${parentIndex}.subjects`}
          />

          {ls.level.length > 0 && (
            <div className="d-flex flex-wrap justify-content-center">
              <span
                className="btn primary-btn view-cart cart-footer py-1 mt-3"
                onClick={(e) =>
                  document
                    .getElementById(`${ls.level}AddSubBox`)
                    .classList.toggle("d-none")
                }
              >
                درخواست درس جدید{" "}
                {ls.level === "school"
                  ? "در دبستان | دبیرستان"
                  : ls.level === "university"
                  ? "در کالج | دانشگاه"
                  : ls.level === "all"
                  ? ""
                  : ""}
                {loading.addSub && (
                  <CircularProgress color="inherit" size="20px" />
                )}
              </span>

              <span
                id={`${ls.level}AddSubBox`}
                className="form-control d-flex justify-content-between align-items-center  mx-md-3 mx-0 py-2 d-none"
              >
                <span className="w-100">
                  <input
                    type="search"
                    autoComplete="off"
                    className="form-input3 shadow-none px-3 w-100"
                    placeholder="درس جدید را وارد نمائید..."
                    onKeyUp={(e) => addNewSubHandle(e, ls.level)}
                    style={{ color: "black" }}
                    name="searchForm"
                  />
                </span>
                <div
                  className="btn bg-beigeDark font-12"
                  onClick={(e) => addNewSubHandle(e, ls.level)}
                >
                  اضافه کردن به لیست{" "}
                </div>
              </span>
            </div>
          )}
          {/* {parentIndex > 0 && ( */}
          <button
            style={{ visibility: parentIndex > 0 ? "visible" : "hidden" }}
            type="button"
            className="deleteContact btn btn login-trigger3 btn-xs mx-2  d-flex"
            title="پاک کردن"
            onClick={() => removeHandle(parentIndex)}
          >
            <i
              className="bi bi-x-circle-fill p-0 "
              style={{ fontSize: "1.5em" }}
            ></i>
            <span className="small-gray-font font-10 my-2 px-2">
              پاک کردن مقطع
            </span>
          </button>
          {/* )} */}
        </div>
      ))}

      {!formik.errors.levelSub &&
        (formik.values.category.mainCat._id === "65cbc5e3e18353df0b52b6f5"
          ? formik.values.levelSub.length < 2
          : formik.values.levelSub.length < 1) && (
          <div
          // className='border-top border-secondary'
          >
            <div
              className="btn btn-xs shadow-none login-trigger2 d-flex  "
              title="افزودن مقطع"
              onClick={addSubjectSelectorHandle}
            >
              <i
                className="bi bi-plus-circle-fill "
                style={{ fontSize: "1.5em" }}
              ></i>
              <span className="small-gray-font font-10 my-2 px-2">
                افزودن مقطع
              </span>
            </div>
          </div>
        )}
    </div>
  );

  function addSubjectSelectorHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.levelSub.length;
      // newF.levelSub.push(newF.levelSub[index - 1]);
      newF.levelSub.push({ level: "", subjects: [] });
      return newF;
    });
  }
  function removeHandle(index) {
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.levelSub.splice(index, 1);
      return newFormik;
    });
  }

  function backUplevels() {
    {
      formik.values?.levelSub.map((ls, parentIndex) => (
        <div key={parentIndex} className="mb-2 tutor-color16 py-3">
          <div id={parentIndex} className="input-group mb-1 addSocialMedia">
            {/*Start choose study levels*/}
            <div>
              <select
                className=" login-btn  selectpicker shadow-none m-2 "
                aria-label="Default select example"
                name={"levelSector" + parentIndex}
                value={ls.level}
                onChange={(e) => {
                  const newLevel = e.target.value;
                  formik.setValues((prev) => ({
                    ...prev,
                    levelSub: prev.levelSub.map((sub, index) => {
                      if (index === parentIndex) {
                        // Reset subjects to [] whenever the level changes
                        return { level: newLevel, subjects: [] };
                      }
                      return sub;
                    }),
                  }));
                }}
              >
                <option value="">انتخاب کنید :</option>
                {!formik.values.levelSub.some(
                  (sub, i) => i !== parentIndex && sub.level === "school"
                ) && <option value="school">دبستان | دبیرستان</option>}
                {!formik.values.levelSub.some(
                  (sub, i) => i !== parentIndex && sub.level === "university"
                ) && <option value="university">کالج | دانشگاه</option>}
              </select>
              {loading.addSub && <CircularProgress color="inherit" />}
            </div>
            {/* Start select Subjects */}
            {ls.level.length > 0 && (
              <div className="w-100 px-2">
                <Autocomplete
                  autoComplete="off"
                  multiple
                  id={`checkboxes-tags-demo${parentIndex}`}
                  options={subjects[ls.level] || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8, fontFamily: "vazir" }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  style={{ fontFamily: "vazir" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="انتخاب درس"
                      placeholder="انتخاب کنید"
                    />
                  )}
                  onChange={(e, subjects) =>
                    formik.setValues((pre) => {
                      let levelSub = [...pre.levelSub];
                      levelSub[parentIndex].subjects = subjects;
                      return { ...pre, levelSub };
                    })
                  }
                  // Use formik values directly for the value prop
                  value={ls.subjects}
                />
              </div>
            )}
            {/* End select Subjects */}
          </div>
          <FormikErrRender
            formik={formik}
            field={`levelSub.${parentIndex}.level`}
          />
          <FormikErrRender
            formik={formik}
            field={`levelSub.${parentIndex}.subjects`}
          />

          {ls.level.length > 0 && (
            <div className="d-flex flex-wrap justify-content-center">
              <span
                className=" btn primary-btn view-cart cart-footer py-1 mt-3"
                onClick={(e) =>
                  document
                    .getElementById(`${ls.level}AddSubBox`)
                    .classList.toggle("d-none")
                }
              >
                درخواست درس جدید در{" "}
                {ls.level === "school"
                  ? "دبستان | دبیرستان"
                  : ls.level === "university"
                  ? "کالج | دانشگاه"
                  : ""}
              </span>

              <span
                id={`${ls.level}AddSubBox`}
                className="form-control d-flex justify-content-between align-items-center  mx-md-3 mx-0 mt-3  d-none"
              >
                <i
                  className="bi bi-plus-circle cursor px-2"
                  onClick={(e) => addNewSubHandle(e, ls.level)}
                ></i>
                <span className="w-100">
                  <input
                    type="search"
                    autoComplete="off"
                    className="form-input3 shadow-none ps-1 w-100"
                    placeholder="درس جدید را وارد نمائید..."
                    onKeyUp={(e) => addNewSubHandle(e, ls.level)}
                    style={{ color: "black" }}
                    name="searchForm"
                  />
                </span>
              </span>
            </div>
          )}
          {parentIndex > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(parentIndex)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          )}
        </div>
      ));
    }
  }
}
