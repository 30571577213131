import React, {useEffect} from "react";
import countryStates from "../../../../utils/countryStates";
import SearchMultiSelectBusRegister from "../../../golbal/dropdown/searchMultiSelectBusRegister";
import MyIcons from "../../../golbal/icons/myIcons";

export default function TargetAddress01({ formik }) {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  // console.log('formik.values',formik.values);
  function updatedTarget(targetAddress) {
    // console.log('updatedTarget',targetAddress);
    return (
      targetAddress[0]?.countryShort?.length > 0 &&
      targetAddress?.map((value, index) => {
        const countryInfo = countryStates.find(
          (count) =>
            count.countryShort.toLowerCase() ===
            value.countryShort.toLowerCase()
        );
        console.log('countryInfo',countryInfo);
        const updatedAddresses = [...targetAddress];
        updatedAddresses[index] = {
          ...updatedAddresses[index],
          country: value.country,
          countryShort: value.countryShort.toLowerCase(),
          states: value.states,
          statesOptions: countryInfo.states,
        };
        // console.log(' updatedAddresses[index', updatedAddresses[index]);
        return updatedAddresses[index];
      })
    );
  }

  useEffect(() => {
    const newTarget = updatedTarget(formik.values?.targetAddress);
console.log('newTarget',newTarget);
    if(newTarget){
      // console.log('newTarget passed');
      formik.setValues((pre) => ({ ...pre, targetAddress: newTarget }))
    
    };
  }, [formik.values._id]);

  const countrySelectHandle = (index, value) => {
    const countryInfo = countryStates.find(
      (count) => count.countryShort === value
    );

    const updatedAddresses = [...formik.values.targetAddress];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      country: regionNames.of(value),
      countryShort: value,
      states: [],
      statesOptions: countryInfo.states,
    };

    formik.setValues((prev) => ({
      ...prev,
      targetAddress: updatedAddresses,
    }));
  };

  const stateSelectHandle = (index, value) => {
    console.log("state seelct");
    const parts = value.split(",");
    const stateShort = parts[0];
    const state = parts[1];

    const updatedAddresses = [formik.values.targetAddresses];
    updatedAddresses[index].states.push({ state, stateShort });
    formik.setValues((prev) => ({
      ...prev,
      targetAddress: updatedAddresses,
    }));

    // setTargetAddresses(updatedAddresses);
  };

  const addAddressHandle = () => {
    const newTA = [...formik.values.targetAddress];
    const index = newTA.length;
    console.log("newTA", newTA, "index", index);
    newTA[index] = {
      country: "",
      countryShort: "",
      states: [],
      statesOptions: [],
    };

    formik.setValues((prev) => ({
      ...prev,
      targetAddress: newTA,
    }));
  };

  const removeAddressHandle = (index) => {
    formik.setValues((prev) => {
      const newTA = { ...prev };
      newTA.targetAddress.splice(index, 1);
      return newTA;
    });
  };
  // console.log('formik?.values?.targetAddress',formik?.values);
  return (
    <div>
      <div>
        <div id="parentAddressReapeater">
          {formik?.values?.targetAddress?.map((address, index) => (
            <div className="d-flex targetAddressReapeater" key={index}>
              <div className="mb-2 mx-3">
                <label className="form-label m-0">کشور</label>
                <br />
                <select
                  name={"targetAddressReapeater" + address + index}
                  className="login-btn mt-0 selectpicker shadow-none w-100"
                  aria-label="Default select example"
                  value={address.country}
                  onChange={(e) => countrySelectHandle(index, e.target.value)}
                >
                  <option value={address.country}>
                    {address?.country?.toUpperCase() ||
                      "از این لیست انتخاب کنید:"}
                  </option>
                  {countryStates.map((coun, i) => (
                    <option key={i} value={coun.countryShort}>
                      {coun.country?.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2">
                <label className="form-label m-0">استان/ ایالت</label>
                <SearchMultiSelectBusRegister
                  index={index}
                  data={formik.values.targetAddress[index].statesOptions}
                  formik={formik}
                  onChange={(value) => stateSelectHandle(index, value)}
                />
              </div>
              {/* {index > 0 && ( */}
              <button
                type="button"
                className="deleteContact btn btn login-trigger3 btn-xs"
                onClick={() => removeAddressHandle(index)}
              >
                <div onClick={addAddressHandle}>
                  <MyIcons icon="delete2" />
                </div>
              </button>
              {/* )} */}
            </div>
          ))}
        </div>
        <img
          // src="/img/premium/add.png"
          src="/img/premium/add-button-2.png"
          className="arrow3 ms-5 "
          alt="add"
          onClick={addAddressHandle}
          loading="lazy"
        />
        {/* <i
        className="bi bi-plus-circle-fill ms-5 lg"
        onClick={addAddressHandle}
      ></i> */}
      </div>
      {/* <!-- Modal Premium --> */}
      <div
        className="modal fade"
        id="premiumModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="premiumeModalLabel">
                <span className="mt-0 py-0 mb-3 ">
                  <img
                    // src="/img/premium/premium.png"
                    src="/img/premium/premium-quality.png"
                    className="arrow2"
                    alt="premium"
                    loading="lazy"
                  />
                </span>
                گزینه کسب و کارهای ویژه
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              شرایط استفاده از این گزینه ...
              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6 p-0 m-0">
                  <img
                    className="d-sm-none d-md-block img-modal-prem"
                    src="/img/Images/vip-luxury-lifestyle.svg"
                    alt="lmodal-premium"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn primary-btn view-cart cart-footer my-0"
                data-bs-dismiss="modal"
              >
                انتخاب و افزودن به سید خرید
              </button>
              <button
                type="button"
                className="btn primary-btn checkout-btn cart-footer my-0"
              >
                نه! الان لازم ندارم!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
