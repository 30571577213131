
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CartContext } from "../../../../../../contexts/cartContext";
import api from "../../../../../../../utils/apiUsers";
import renderData from "../../../../../../../utils/renderData";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import tools from "../../../../../../../utils/tools";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import MetaDecorator from "../../../../../../../utils/metaDecorator";


export default function WalletDetails() {
  const { user } = useContext(CartContext);
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log(data);
// const{countryShort}=tools.lcsn(window.location.pathname)
const countryDetails = JSON.parse(localStorage?.getItem("countryDetails"))??{};

  useEffect(() => {
      (async () => {
        setLoading(true);
      const response = await api.getWalletDetails({token:user.token,id:user._id});      
      setLoading(false);
      if (response.error) return alert(response.error);
      const subtotal= response.data.reduce((a,c)=>a+Number(c.amount),0)
const newData = [...response.data, { _id: 'subtotal', type: 'subtotal', amount: subtotal, createdAt: '', targetIdentifier: { description: 'جمع کل' } }];

      setData(newData);
  })()}, []);


function showPageHandle({props}){
  console.log(props);
  let text ="شما در حال انتقال به صفحه دیگر هستید."
  //  if(lang.toLocaleLowerCase()!==props.businessLng.toLocaleLowerCase()) text+= `شما در حال انتقال به زبان ${window.capitalize(props.businessLng)} میباشد . \n`
  //  if(countryShort.toLocaleLowerCase()!==props.countryShort.toLocaleLowerCase()) text+= `شما در حال انتقال به کشور ${window.capitalize(props.country)} میباشد . \n`
  //  if(stateShort.toLocaleLowerCase()!==props.stateShort.toLocaleLowerCase()) text+= `شما در حال انتقال به استان ${window.capitalize(props.state)} میباشد . \n`
  if(window.confirm(text)!==true)return;
  if(props.targetIdentifier.model === "Business") return navigate(`/${window.lcs}/business/page/${props.targetIdentifier.id}`)
  if(props.targetIdentifier.model === "Post") return navigate(`/${window.lcs}/blog/post/${props.targetIdentifier.id}`)
}
const subtotal= data.reduce((a,c)=>a+Number(c.amount),0)
const columns = [
  {
    field: "type",
    headerName: "گروه",
    width: 150,
    renderCell:(params)=>{
      const trans = tools.renderTrans({section:'walletDesc',key:params.row?.type})
      // const group = pointsGroups.find(group => group.name === params.row?.type)    
      return <div title={trans}> {trans}</div>
    }   
  }, 
  { field: "targetIdentifier.description", headerName: "توضیحات", width: 280,
     renderCell: (params) => {
      return (             
          <strong title={params.row?.targetIdentifier?.description}> {params.row?.targetIdentifier?.description}</strong>
               
      );
    },
   },
   {
    field: "amount",
    headerName: 'مبلغ',
    width: 120,
    renderCell: (params) => {
      return (<strong >{tools.showPrice(params.row?.amount*countryDetails.exRate , countryDetails.currency)}</strong>);
    },
},{
    field: "createdAt",
    headerName: "تاریخ تراکنش",
    width: 160,
 renderCell:params=>  <div>{tools.dynamicDate({date:params.row?.createdAt,lang:window.lang,countryShort:window.location.pathname.split('/')[2],showYear:true})}</div>  
  
  },
];
const metaTagsData={
  title:"  متامارس | داشبورد | جزئیات امتیاز ها ",
  description:"مشاهده جزئیات امتیاز کسب شده کاربری",
  section:"oreders list",
  url:`${window.location.origin}/${window.lcs}/dashboard/user/trans/points`,
  img:"https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
  type:'webpage',
  index:false
}

return (
  <div className="userList" style={{ height: '100vh', width: "100%" }}>
    <MetaDecorator data={{...metaTagsData}}/>

      {loading && <ColoredSpinner />}
    <DataGrid
      getRowId={(row) => row._id}
      rows={data}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      columns={columns}
      pageSize={[12]}
      rowsPerPageOptions={[1]}
      checkboxSelection
      
    />
   
    
  </div>
);

function CustomFooter({ totalRowCount, subtotal }){
  return (
    <div style={{ padding: '10px', borderTop: '1px solid #ccc' }}>
      <div>Total rows: {totalRowCount}</div>
      <div>Subtotal: {subtotal}</div>
    </div>
  );
};




  
}
