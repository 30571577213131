import React, { useContext, useEffect, useRef, useState } from "react";
import "./chatBox.css";
import { CartContext } from "../../contexts/cartContext";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import { CircularProgress } from "@mui/material";
import ToastNote from "../notifications/toastNote";

export default function ChatBox({ unreadCount, setUnreadCount }) {
  const [ws, setWs] = useState(null);
  const { user } = useContext(CartContext);
  const [selectedUser, setSelectedUser] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState({ messages: false });
  const messageRef = useRef(null);

  async function updateSubscription() {
    const sw = await navigator.serviceWorker.ready;
    const newSubscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_WEBPUSHPUBLICKEY,
      // Add other options if needed
    });
    const response = await api.updateSubscription({
      subscription: newSubscription,
      token: user?.token,
      userAgent: navigator.userAgent,
    });
    if (response.error) return console.log(response.error);
    setSubscription({
      subscription: newSubscription,
      subscribed: response.data.subscribed,
    });
  }
  const handleSubscribe = async () => {
    const userAgent = navigator.userAgent;
    let os = "";
    if (userAgent.match(/Android/i)) {
      os = "Android";
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      os = "iOS";
    } else if (userAgent.match(/Windows/i)) {
      os = "Windows";
    } else if (userAgent.match(/Macintosh|MacIntel|MacPPC|Mac68K/i)) {
      os = "Mac OS";
    } else if (userAgent.match(/Linux/i)) {
      os = "Linux";
    }
    console.log("os", os);

    const translate = {
      iOS: "اپل",
      Android: "اندروید",
      Windows: "ویندوز",
      Linux: "لینوکس",
      "Mac OS": "مکینتاش",
    };

    const message = (
      <div className="d-flex flex-column">
        <div className="">
          {" "}
          برای دریافت نوتیفیکشن در {translate[os]}، با استفاده از دکمه Add to
          Home Screen متامارس را به اپلیکیشن های خود اضافه و از آنجا فعال
          نمائید.
        </div>
        <div className="">
          <img
            src="/img/guide/add-to-home.jpg"
            alt="add to home"
            width="270px"
          />
        </div>
      </div>
    );
    if (!os.match(/Windows|Android/i))
      messageRef.current.showMessage({ message });
    const sw = await navigator.serviceWorker.ready;
    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: process.env.REACT_APP_WEBPUSHPUBLICKEY,
    });
    setLoading((pre) => ({ ...pre, subscribe: true }));
    const response = await api.pushSubscription({
      subscription,
      token: user?.token,
      userAgent,
    });
    setLoading((pre) => ({ ...pre, subscribe: false }));
    if (response.error) return console.log(response.error);
    setSubscription({ subscription, subscribed: response.data.subscribed });
  };
  const handleUnsubscribe = async () => {
    if (subscription) {
      await subscription.subscription.unsubscribe();
      // console.log('Unsubscribed successfully');
      setLoading((pre) => ({ ...pre, subscribe: true }));
      const response = await api.pushUnSubscribe({ token: user?.token });
      setLoading((pre) => ({ ...pre, subscribe: false }));
      if (response.error) return console.log(response.error);
      setSubscription(null);
    }
  };

  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState(null);
  // Example state variable
  const [onlinePeople, setOnlinePeople] = useState([]);
  const [contactedPeople, setContactedPeople] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const divUnderMessages = useRef();
  // console.log("messages", messages);
  useEffect(() => {
    if (!user?.token) return;
    connectToWs();
  }, [user.token, selectedUser]);

  useEffect(() => {
    fetchMessages();
  }, [user._id, selectedUser?.userId]);
  useEffect(() => {
    // const div = divUnderMessages.current;
    const div = document.getElementById("divUnderMessages");
    if (div) {
      // console.log("div", div);
      setTimeout(() => {
        div.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
  }, [messages.length]);
  async function fetchMessages() {
    if (!user._id || !selectedUser?.userId) return;
    setLoading((pre) => ({ ...pre, messages: true }));
    const response = await api.fetchMessages({
      token: user.token,
      sender: user._id,
      recipient: selectedUser?.userId,
    });
    setLoading((pre) => ({ ...pre, messages: false }));
    if (response.error) return window.alert(response.error);
    // console.log("success", response.data);
    setMessages(response.data);
    setContactedPeople((pre) => {
      const newContacted = pre.map((user) =>
        user.userId === selectedUser.userId
          ? (function () {
              user.unreadCount = 0;
              return user;
            })()
          : user
      );
      setUnreadCount(unreadCounter(newContacted));
      return newContacted;
    });
  }
  function unreadCounter(contacted) {
    return contacted.reduce(
      (a, c) => (user._id !== c.userId ? a + c.unreadCount : a),
      0
    );
  }

  // console.log("contactedPeople", contactedPeople);
  useEffect(() => {
    if (!user?._id) return;
    fetchContactList();
  }, [user?._id]);
  async function fetchContactList() {
    const response = await api.fetchContactedList({
      token: user.token,
      id: user._id,
    });
    if (response.error)
      return console.log("fetchContactList err", response.error);
    setContactedPeople(response.data.contacted);
    setUnreadCount(unreadCounter(response.data.contacted));
    if (response?.data?.subscription?.subscribed) {
      updateSubscription();
    }
  }
  function createArrayOnlinePeople(onlineUsers) {
    // console.log('createArrayOnlinePeople',onlineUsers);
    const uniqueUserIds = new Set();
    const uniqueUsers = onlineUsers.filter((user) => {
      if (!uniqueUserIds.has(user.userId)) {
        uniqueUserIds.add(user.userId);
        return true;
      }
      return false;
    });
    setOnlinePeople(uniqueUsers);
  }
  // function handleMessage(e) {
  //   const messageData = JSON.parse(e.data);
  //   // console.log("newMessage Arrived", messageData);
  //   // console.log("selectedUser", selectedUser);
  //   if ("online" in messageData) {
  //     createArrayOnlinePeople(messageData.online);
  //   } else if ("text" in messageData) {
  //     console.log("selectedUser Id", messageData.sender, selectedUser?.userId);
  //     if (messageData.sender === selectedUser?.userId) {
  //       setMessages((prevMessages) => [...prevMessages, {...messageData,isRead:true}]);
  //       // alert(messageData.text)
  //     }else{
  //       console.log("setContactedPeople", messageData);
  //       setContactedPeople(pre=>{
  //         let count=0
  //         const newContacted = pre.map((user,i)=> {
  //           console.log('count', count);
  //           return user.userId===messageData.sender? count<=0 && function(){ user.unreadCount+=1; count+=1; return user}(): user})
  //         setUnreadCount(unreadCounter(newContacted))
  //         return newContacted
  //        })

  //     }
  //   } else {
  //     console.log("something Else", messageData);
  //   }
  // }

  //keep this to avoid duplicate counter running based on client connections
  const messageId = useRef(null);

  function handleMessage(e) {
    const messageData = JSON.parse(e.data);

    if ("online" in messageData) {
      createArrayOnlinePeople(messageData.online);
    } else if ("text" in messageData) {
      // console.log("selectedUser Id", messageData.sender, selectedUser?.userId);
      if (messageData.sender === selectedUser?.userId) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { ...messageData, isRead: true },
        ]);
      } else {
        if (!messageId.current || messageId.current !== messageData._id) {
          // console.log("setContactedPeople", contactedPeople);
          setContactedPeople((pre) => {
            const newContacted = pre
              .map((user, i) => {
                return user.userId === messageData.sender
                  ? {
                      ...user,
                      unreadCount: user.unreadCount + 1,
                      latestCreatedAt: new Date(),
                      lastText: messageData.text,
                    }
                  : user;
              })
              .sort(
                (a, b) =>
                  new Date(b.latestCreatedAt) - new Date(a.latestCreatedAt)
              );
            console.log("newContacted", newContacted);
            setUnreadCount(unreadCounter(newContacted));
            return newContacted;
          });
          messageId.current = messageData._id;
        }
      }
    } else {
      console.log("something Else", messageData);
    }
  }

  function connectToWs() {
    // const ws = new WebSocket("ws://localhost:5000", ["token", user.token]);
    // const ws = new WebSocket("wss://www.metamarce.com/ws", ["token", user.token])
    const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";
    const port = window.location.hostname === "localhost" ? ":5000" : "";
    const address = `${protocol}//${window.location.hostname}${port}/ws`;
    // console.log("address", address);
    const ws = new WebSocket(address, ["token", user.token]);
    ws.onopen = function (event) {
      console.log("WebSocket connection established");
    };

    // ws.onerror = function(error) {
    //   if (error instanceof Event) {
    //     console.error("WebSocket connection failed:", error);
    //   } else {
    //     // If the error object is not an instance of Event,
    //     // it likely contains additional information about the error,
    //     // such as the HTTP status code returned by the server.
    //     console.error("WebSocket connection failed with status code:", error.message);
    //   }
    // };

    setWs(ws);
    ws.addEventListener("message", handleMessage);
    ws.addEventListener("close", () => setTimeout(() => connectToWs(), 5000));
    return () => {
      // Cleanup function to close WebSocket connection when component unmounts
      ws.close();
    };
  }
  function sendFile(e) {
    // console.log(e.target.files);
    if (files?.[0]?.size > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        sendMessageHandle(null, {
          data: reader.result,
          name: files[0]?.name,
        });
      };
      setFiles(null);
    } else {
      alert("لطفا فایل را دوباره انتخاب نمائید");
    }

    var myModal = document.getElementById("attachFileModal"); // Get the modal element
    var modal = new window.bootstrap.Modal(myModal, {
      // backdrop: "static",
      keyboard: true,
    });
    modal.hide();
  }
  function fileChangeHandle(e) {
    // console.log("fileChangeHandle", e.target.files);
    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "image/tiff",
      "image/webp",
      "image/tif",
      "image/bmp",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    const file = e.target.files[0];
    console.log("file.type", file.type, "file.size", file.size);

    if (!file) return; // No file selected
    if (!allowedFormats?.includes(file?.type)) {
      alert(
        " لطفااز فایل با فرمت عکس، پی دی اف و یا فایل‌های مایکروسافت آفیس استفاده نمائید."
      );
      // Clear the file input
      // e.target.files[0] =null;
      return;
    }

    if (file.size > 3 * 1024 * 1024)
      return alert(" لطفااز فایل باحجم کمتر از 3 مگابایت استفاده کنید.");
    setFiles(e.target.files);
    //
    var myModal = document.getElementById("attachFileModal"); // Get the modal element
    var modal = new window.bootstrap.Modal(myModal, {
      // backdrop: "static",
      keyboard: true,
    });
    modal.show();
  }
  const [uniMessages, setUniMessages] = useState([]);
  useEffect(() => {
    const uniqueMessages = removeDuplicateMessages([...messages]);
    // console.log('uniqueMessages',uniqueMessages);
    setUniMessages(uniqueMessages);
  }, [messages]);

  // const offlineUsers = contactedPeople.filter((u) => {
  //   return (
  //     u.userId !== user._id &&
  //     !onlinePeople.some((online) => online.userId === u.userId)
  //   );
  // });

  const offU = [];
  const onU = [];

  contactedPeople.forEach((u) =>
    u.userId !== user._id &&
    !onlinePeople.some((online) => online.userId === u.userId)
      ? offU.push(u)
      : onU.push({ ...u, isOnline: true })
  );
  // console.log("contactedPeople", contactedPeople, "offU", offU, "onU", onU);

  // const combinedUsers = [...onlinePeople, ...offlineUsers];

  const combinedUsers = [...onU, ...offU];

  const filteredUsers = combinedUsers.filter((user) =>
    !searchQuery.length
      ? true
      : user.userName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  // console.log('filteredUsers',filteredUsers);
  const removeDuplicateMessages = (messageArray) => {
    // console.log('removeDuplicateMessages',messageArray);
    const uniqueMessageIds = new Set();
    return messageArray.filter((message) => {
      if (!uniqueMessageIds.has(message._id)) {
        uniqueMessageIds.add(message._id);
        return true;
      }
      return false;
    });
  };

  return (
    <div>
      <ToastNote messageRef={messageRef} />
      <div className="metaChat">
        <div
          dir="ltr"
          className="offcanvas offcanvas-start chat  my-md-5 mx-md-2 m-0 "
          style={{ backgroundColor: "#f7f5f3" }}
          tabIndex="-1"
          id="offcanvasChatboxContainer"
          aria-labelledby="offcanvasChatboxContainerExampleLabel "
          // data-bs-scroll="true"
          // data-bs-backdrop="false"
        >
          <div className="card-header  d-flex">
            <div
              type="button btn-icon"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasChatboxContainer"
              aria-controls="offcanvasChatboxContainer"
              onClick={(e) => {
                setMessages([]);
                setSelectedUser({});
              }}
            >
              {/* <div>{unreadCount}</div> */}
              <div>
                <i
                  className="bi bi-x-circle ps-3  font-25"
                  id="offCanCloseBtn"
                ></i>
              </div>
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Search..."
                name=""
                className="form-control search"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="input-group-prepend">
                <span className="input-group-text search_btn h-100">
                  <i className="bi bi-search"></i>
                </span>
              </div>
            </div>
          </div>
          {user?.roles?.includes(9999) ? (
            <div className="card h-100">
             
              <div className="card-body  msg_card_body">
                <div className="d-flex flex-column justify-content-between align-items-between h-100">
                  <ul className="contacts py-3">
                    {filteredUsers?.map(
                      (people, i) =>
                        people.userId !== user._id && (
                          <li
                            key={i}
                            className={`cursor ${
                              selectedUser?.userId === people.userId && "active"
                            } `}
                            onClick={(e) => {
                              console.log("people", people);
                              setSelectedUser(people);
                            }}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasChatbox"
                            aria-controls="offcanvasChatbox"
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex  ">
                                <div className="img_cont">
                                  <img
                                    src={people?.thumbnail}
                                    className="rounded-circle user_img"
                                  />
                                  <span
                                    title={`${people?.userName} ${
                                      people?.showOnline && people?.isOnline
                                        ? "is online"
                                        : "is offline"
                                    }`}
                                    className={
                                      people?.showOnline && people?.isOnline
                                        ? `online_icon`
                                        : "online_icon offline"
                                    }
                                  ></span>
                                </div>
                                <div className="user_info">
                                  <span>{people.userName}</span>
                                  <p>
                                    {/* {people?.userName} */}
                                    {people?.lastText?.substring(0, 30)}
                                    {/* {people?.showOnline && people?.isOnline
                                  ? "is online"
                                  : "is offline"} */}
                                  </p>
                                </div>
                              </div>

                              {people.unreadCount > 0 && (
                                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                                  {people.unreadCount}
                                  {/* {tools.dynamicNumber({number: people.unreadCount, lang:window.lang})} */}
                                </div>
                              )}
                            </div>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
              <div className="card-footer mt-auto">
                <div
                  className="d-flex justify-content-between img-banner-text-23 m-2 px-3 py-2"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="d-flex   ">
                    <div className="img_cont  ">
                      <img
                        src={user.thumbnail}
                        className="rounded-circle user_img"
                      />
                      <span className="online_icon"></span>
                    </div>
                    <div className="user_info">
                      <span>{user.userName}</span>
                      {/* <p>Sami is online</p> */}
                      {/* <div onClick={handleSubscribe} className="">Subscribe</div> */}
                    </div>
                  </div>
                  <div>
                    {loading.subscribe ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : subscription?.subscribed ? (
                      <div
                        onClick={handleUnsubscribe}
                        className="cursor"
                        title="Notification On"
                      >
                        <i className="bi bi-bell-fill"></i>
                      </div>
                    ) : (
                      <div
                        onClick={handleSubscribe}
                        className="cursor"
                        title="Notification Off"
                      >
                        <i className="bi bi-bell"></i>
                      </div>
                    )}
                  </div>
                </div>{" "}
              </div>
            </div>
          ) : (
            <div
              dir="rtl"
              className="text-center pt-5 d-flex flex-column align-items-center justify-content-center "
            >
              <p>برای استفاده از «متاچت» ابتدا وارد حساب کاربری شوید.</p>
              <div
                className="mt-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalToggle"
                role="button"
              >
                <img className="w-100 rounded-2 " src="/img/Metademy/165.png" />
              </div>
            </div>
          )}
        </div>
        <div
          dir="ltr"
          className="offcanvas chat my-md-5 mx-md-2 m-0 offcanvas-start "
          tabIndex="-1"
          id="offcanvasChatbox"
          aria-labelledby="offcanvasChatboxExampleLabel"
          // data-bs-scroll="true"
          // data-bs-backdrop="false"
        >
          <div className="card-header msg_head d-flex">
            <div className="pt-2" id="action_menu_btn">
              <div
                type="button btn-icon"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasChatboxContainer"
                aria-controls="offcanvasChatboxContainer"
                onClick={(e) => {
                  setMessages([]);
                  setSelectedUser({});
                }}
              >
                <i className="bi bi-arrow-left-circle"></i>
              </div>
            </div>
            <div className="d-flex me-5">
              <div className="img_cont ">
                <img
                  src={selectedUser?.thumbnail}
                  className="rounded-circle user_img "
                />

                <span
                  className={
                    selectedUser.showOnline &&
                    onlinePeople.some(
                      (online) => online.userId === selectedUser.userId
                    )
                      ? "online_icon"
                      : "online_icon offline"
                  }
                ></span>
              </div>
              <div className="user_info">
                <span>{selectedUser?.userName}</span>
                {/* <p>
                      {messages?.length > 0 && `${messages?.length} Messages`}
                    </p> */}
              </div>

              {/* <div className="video_cam">
                    <span>
                      <i className="bi bi-camera-video-fill"></i>
                    </span>
                    <span>
                      <i className="bi bi-telephone-outbound-fill"></i>
                    </span>
                  </div> */}
            </div>

            {/* <span id="action_menu_btn">
                  <i className="bi bi-three-dots-vertical"></i>
                </span>
                <div className="action_menu">
                  <ul>
                    <li>
                      <i className="fas fa-user-circle"></i> View profile
                    </li>
                    <li>
                      <i className="fas fa-users"></i> Add to close friends
                    </li>
                    <li>
                      <i className="fas fa-plus"></i> Add to group
                    </li>
                    <li>
                      <i className="fas fa-ban"></i> Block
                    </li>
                  </ul>
                </div> */}
          </div>

          <div className="card-body  msg_card_body">
            {loading.messages ? (
              <CircularProgress />
            ) : (
              selectedUser?.userId &&
              uniMessages.map((message, i) => {
                return message.hideForSender && message.sender === user._id ? (
                  ""
                ) : message.sender === user._id ? (
                  <div
                    key={i}
                    className="d-flex me-5  justify-content-end
                       mb-4"
                  >
                    <div className="d-flex flex-column">
                      <div
                        className="msg_cotainer_send meta-break"
                        dir={window.lang === "fa" ? "rtl" : "ltr"}
                      >
                        {tools.linesSpliterWithAnchor(message.text)}
                        {message.file && (
                          <div>
                            <a href={message.file} target="_blank">
                              <i className="bi bi-paperclip font-18"></i>
                              {message.file.split("/").slice(-1)[0]}
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="d-flex align-items-center">
                        <ShowTime date={message?.createdAt} />
                        <div className="m-0">
                          {message?.isRead ? (
                            <i className="bi bi-check-all" />
                          ) : (
                            <i className="bi bi-check" />
                          )}
                        </div>
                        {/* <span className="msg_time"></span> */}
                      </div>
                    </div>
                    {/* <div className="img_cont_msg">
                          <img
                            src={user.thumbnail}
                            className="rounded-circle user_img_msg"
                          ></img>
                        </div> */}
                  </div>
                ) : (
                  <div
                    key={i}
                    className="d-flex me-s  justify-content-start
                       mb-4"
                  >
                    {/* <div className="img_cont_msg">
                          <img
                            src={selectedUser.thumbnail}
                            className="rounded-circle user_img_msg"
                          ></img>
                        </div> */}
                    <div className="d-flex flex-column meta-break">
                      <div
                        className={"msg_cotainer "}
                        dir={window.lang === "fa" ? "rtl" : "ltr"}
                      >
                        {tools.linesSpliterWithAnchor(message.text)}
                        {message.file && (
                          <div>
                            <a href={message.file} target="_blank">
                              <i className="bi bi-paperclip font-18"></i>
                              {message.file.split("/").slice(-1)[0]}
                            </a>
                          </div>
                        )}
                        {/* {message?.isRead&& <i className="bi bi-check-all"></i>}  */}
                        {/* <span className="msg_time"></span> */}
                      </div>
                      <div className="ms-auto">
                        <ShowTime date={message?.createdAt} />
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div id="divUnderMessages" ref={divUnderMessages}></div>
          </div>
          {selectedUser?.userId && (
            <div className="card-footer">
              <div className="input-group mb-2">
                <div className="input-group-append">
                  <input
                    type="file"
                    multiple={false}
                    accept="image/*, application/pdf"
                    className="d-none"
                    onChange={fileChangeHandle}
                    id="attachAFile"
                  />
                  <span className="input-group-text attach_btn h-100">
                    <label htmlFor="attachAFile">
                      <i className="bi bi-paperclip font-20"></i>
                    </label>
                  </span>
                </div>
                <textarea
                  value={newMessage}
                  name="type new message"
                  className="form-control type_msg pt-3"
                  placeholder="Type your message..."
                  onChange={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                    setNewMessage(e.target.value);
                  }}
                  // style={{ minHeight: '40px' }}
                ></textarea>
                <div className="input-group-append" onClick={sendMessageHandle}>
                  <span className="input-group-text send_btn h-100">
                    {
                      <button disabled={!newMessage} className="icon-btn">
                        <i className="bi bi-send font-18 px-2"></i>
                      </button>
                    }
                  </span>
                </div>
              </div>

              {/* <!-- Modal --> */}
              <div
                // dir="rtl"
                className="modal fade"
                id="attachFileModal"
                tabIndex="-1"
                aria-labelledby="sendAttachmentModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header d-flex justify-vontent-between ">
                      <h1
                        className="modal-title fs-5"
                        id="sendAttachmentModalLabel"
                      >
                        Send as a file
                      </h1>
                      <button
                        type="button"
                        className="btn-close mx-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col">
                          <div>
                            <div className="d-flex">
                              <div className="mx-2 font-30">
                                <i className="bi bi-files"></i>
                              </div>
                              <div className="">
                                {files?.[0]?.name}
                                <div className="review-text">
                                  {Math.ceil(files?.[0]?.size / 1024) + "KB"}
                                </div>
                              </div>
                            </div>

                            {/* <div><h3>Caption</h3></div> */}
                            <textarea
                              value={newMessage}
                              name=""
                              className="form-control type_msg"
                              placeholder="Type your message ..."
                              onChange={(e) => setNewMessage(e.target.value)}
                            ></textarea>
                            {/* <input
                                  className="w-100 mx-2"
                                  type="text"
                                  id="captin Input"
                                  value={newMessage}
                                  onChange={(e) =>
                                    setNewMessage(e.target.value)
                                  }
                                /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer ">
                      <button
                        type="button"
                        className="btn  primary-btn tutor-btn ms-3 py-1"
                        data-bs-dismiss="modal"
                        onClick={sendFile}
                      >
                        <i className="bi bi-check-circle-fill"></i>
                      </button>
                      <button
                        type="button"
                        className="btn primary-btn tutor-btn ms-3 py-1"
                        data-bs-dismiss="modal"
                        onChange={(e) => {
                          setNewMessage("");
                          setFiles(null);
                        }}
                      >
                        <i className="bi bi-x-circle-fill"></i>
                      </button>
                      <div>
                        <label
                          className="btn primary-btn tutor-btn ms-3 py-1"
                          htmlFor="newAttach"
                        >
                          <i className="bi bi-file-earmark-image"></i>
                        </label>
                        <input
                          accept="image/*, application/pdf"
                          multiple={false}
                          id="newAttach"
                          className="d-none"
                          type="file"
                          onChange={(e) =>
                            e.target.files.length > 0 &&
                            setFiles(e.target.files)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  function sendMessageHandle(e, file = null) {
    // console.log('file',file);
    if (e) e.preventDefault();
    ws.send(
      JSON.stringify({
        recipient: selectedUser?.userId,
        senderUserName: user.userName,
        text: newMessage,
        file,
      })
    );
    if (file) {
      fetchMessages();
    } else {
      setNewMessage("");
      setMessages((pre) => [
        ...pre,
        {
          sender: user._id,
          recipient: selectedUser?.userId,
          text: newMessage,
          createdAt: new Date(),
          _id: Date.now(),
        },
      ]);
    }
  }
}

function ShowTime({ date }) {
  const currentDate = new Date();
  const dateToShow = date ? new Date(date) : currentDate;

  let timeString;
  if (dateToShow.toDateString() === currentDate.toDateString()) {
    // If dateToShow is from today
    timeString =
      dateToShow.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      }) +
      " " +
      ", Today";
  } else {
    // If dateToShow is from a previous dateToShow
    timeString =
      dateToShow.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      }) +
      " " +
      dateToShow.toLocaleDateString();
  }
  return <div className="msg_time_send me-auto px-3">{timeString}</div>;
}
