import axios from "axios"

export default new (class{
filters = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/demy/filtersCenter?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
filtersTutor = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/demy/filtersTutor?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
getPopCat = async({filters})=>{
  console.log(filters);
  
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}


try {

const response = await axios.get(`/api/demy/popCat?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {

return  {error: error?.response?.data?.message || error.message}
}
}
tutorgetLocations = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/demy/locations/tutor?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
centergetLocations = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/demy/locations/center?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

fetchCenterList = async({token,access,id,key})=>{
  try {
  const response = await axios.get(`/api/demy/list/center/${id}?access=${access}&key=${key}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }  
  })
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchTutorList = async({token,access,id,key})=>{
  try {
  const response = await axios.get(`/api/demy/list/tutor/${id}?access=${access}&key=${key}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }  
  })
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
fetchProgramList = async({token,access,id,key})=>{
  try {
  const response = await axios.get(`/api/demy/list/program/${id}?access=${access}&key=${key}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }  
  })
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  changeSessionStatus = async({token,data})=>{
  try {
  const response = await axios.put(`/api/demy/tutor/book`,{data},{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  sendInviteLink = async({token,data})=>{    
  try {
  const response = await axios.post(`/api/demy/tutor/inviteLink`,{data},{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  userSavedList = async({token,savedItems,model})=>{
  try {  
  const response = await axios.post(`/api/demy/user/list/saved`,{savedItems,model} ,{
    headers :{
      Authorization : `Bearer ${token}`,
     }}
     )
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
registerCenter = async({token , data })=>{
  console.log("Demy register",data );  
  try {
    const response = await axios.post(`/api/demy/register/center` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
registerTutor = async({token , data })=>{
  
try {
  const response = await axios.post(`/api/demy/register/tutor` ,
  {data} ,{
    headers :{
      Authorization : `Bearer ${token}`,
      }

  })
    // console.log(response.data);
    if(response.status!==201)  throw new Error(response.data.message)
    return response.data

} catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
}
}
bookSession = async({token , data})=>{  
try {
  const response = await axios.post(`/api/demy/tutor/book` ,
  {data} ,{
    headers :{
      Authorization : `Bearer ${token}`,
      }

  })
    // console.log(response.data);
    if(response.status!==201)  throw new Error(response.data.message)
    return response.data

} catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
}
}
createSubject = async({token , data })=>{
  
try {
  const response = await axios.post(`/api/demy/createsubject/tutor` ,
  {data} ,{
    headers :{
      Authorization : `Bearer ${token}`,
      }

  })
    // console.log(response.data);
    if(response.status!==201)  throw new Error(response.data.message)
    return response.data

} catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
}
}
editCenter = async({token , data })=>{
  console.log("edit ",data );
  
  try {
    const response = await axios.put(`/api/demy/register/center` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
editTutor = async({token , data })=>{
  console.log("edit ",data );
  
  try {
    const response = await axios.put(`/api/demy/register/tutor` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
reqTutor = async({token , data })=>{
  console.log("reqTutor ",data );  
  try {
    const response = await axios.post(`/api/demy/req/tutor`,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
centerNeedUpdate = async({token , data })=>{
  console.log("centerNeedUpdate ",data );  
  try {
    const response = await axios.post(`/api/demy/needUpdate/center` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchTutorReqList = async({token , data })=>{
  console.log("fetchTutorReqList ",data );  
  try {
    const response = await axios.get(`/api/demy/req/tutor?id=${data.id}&access=${data.access}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchTutorTransList = async({token , data })=>{
    // console.log('fetchSessionReqList',data);
    let keys=''
    for(let key in data){      
      keys += `${key}=${data[key]}&`
    }
  try {
    const response = await axios.get(`/api/demy/tutor/trans/list?${keys}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchTutorProfileList = async({token , data })=>{
    // console.log('fetchSessionReqList',data);
    let keys=''
    for(let key in data){      
      keys += `${key}=${data[key]}&`
    }
  try {
    const response = await axios.get(`/api/demy/tutor/profile/list?${keys}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchSessionReqList = async({token , data })=>{
    // console.log('fetchSessionReqList',data);
    let keys=''
    for(let key in data){      
      keys += `${key}=${data[key]}&`
    }
  try {
    const response = await axios.get(`/api/demy/tutor/book?${keys}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
registerProgram = async({token , data })=>{
  console.log("register Program",data );  
  try {
    const response = await axios.post(`/api/demy/register/program` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
editProgram  = async({token , data })=>{ 
  try {
    const response = await axios.put(`/api/demy/register/program` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }


  fetchCenterDetails = async(id)=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/demy/details/center/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
  fetchTutorDetails = async(id)=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/demy/details/tutor/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
fetchSujectsList = async({token,id})=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/demy/subjectsList/tutor/${id}`,{
  headers :{
    Authorization : `Bearer ${token}`,
   }
}
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
  fetchProgramDetails = async(id)=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/demy/details/program/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

deleteTutor = async({token,id,action})=>{  
  try {
  const response = await axios.delete(`/api/demy/tutor/profile/list?id=${id}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }








})()