// import "../demy.css";
import React, { useContext, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import MyIcons from "../../../golbal/icons/myIcons";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
// import renderData from "../../../../utils/renderData";
import tools from "../../../../utils/tools";
import RatingManuel from "../../../comment/rating";
import demyData from "../../../../utils/demyData";
// import TutorGrades from "./tutorGrades";
// import ShowAddress from "../../../golbal/showAddress";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import ProfTutor from "./profTutor";

export default function ReadyCard({
  data,
  currencies,
  setVideo,
  setActiveTutor,
}) {
  // console.log("data", data);
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);

  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section: "ready",
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> لطفا دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.ready?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: " صفحه کارجو با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "صفحه کارجو از لیست ذخیره حذف شد.",
        });
  };

  const {
    currency,
    currency_symbol,
    exRate,
    convertRate,
    accepted,
    translations,
  } = JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  // const matchedCurr = currencies.find(
  //   (curr) => curr.currency === data?.moreInfo?.salary?.currency
  // );

  const isOwner = data?.moreInfo?.history?.createdBy?._id === user?._id;
  return (
    <div key={data._id} className="col px-1 tutorCard">
      <ToastNote messageRef={messageRef} />
      <div className="card entry p-md-4 p-3 pt-4">
        <div className=" row g-3">
          <div className="col-md-2 px-0">
            <div className="row display-tablet">
              <div className="col-4 p-md-0 ">
                <div>
                  <div
                    className="image-container image-container-media text-center cursor "
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lcs}/jobs/ready/page/${data._id}`,
                        { currencies, data }
                      )
                    }
                  >
                    <img
                      src={
                        data?.moreInfo?.img ||
                        "/img/Metademy/Green-Red-Stickers-5.png"
                      }
                      // className="picture-src"
                      id={"tutor" + data?._id}
                      title={data.name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className="titr5 text-start text-gray-green cursor"
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${window.lcs}/jobs/ready/page/${data?._id}`,
                          { currencies, data }
                        )
                      }
                    >
                      {data?.name}
                    </div>
             
                    <Tooltip1
                      key={data?._id}
                      icon={
                        <img
                          // width="25px"
                          style={{ width: "25px" }}
                          className=""
                          src={`/img/flags/${data?.moreInfo?.address?.countryShort?.toLowerCase()}.svg`}
                          alt={data?.moreInfo?.address?.countryShort}
                          title={window.capitalize(
                            data?.moreInfo?.address?.country
                          )}
                        />
                      }
                      message={
                        <div>
                          {`${data?.moreInfo?.address?.country}, ${data?.moreInfo?.address?.state}, ${data?.moreInfo?.address?.city}`.toUpperCase()}
                        </div>
                      }
                    />
                  </div>
                  <div>
                  <div className=" my-1 small-gray-font d-flex flex-wrap  justify-content-end " dir="ltr">
              <i className="bi bi bi-geo-alt mx-2"></i>
              {`${window.capitalize(data?.moreInfo.address?.city || "")},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
            </div>
            <div className="med-gray-font fw-bold">{data?.moreInfo?.title}</div>
  
                  </div>
                  <div>
                    {/* <ProfTutor data={data} />
                    <TutorGrades data={data} /> */}
                    {user?.roles?.includes(1000) && (
                      <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {data?.feedbackInfo?.views} )
                      </span>
                    )}
                  </div>
                </div>

                {/* <ShowAddress address={data?.moreInfo?.address}/> */}
              </div>
            </div>
            <div className="display-none-md">
              <div
                className="image-container image-container-media text-center cursor"
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lcs}/jobs/ready/page/${data._id}`,
                    { currencies, data }
                  )
                }
              >
                <img
                  src={
                    data?.moreInfo?.img ||
                    "/img/Metademy/Green-Red-Stickers-5.png"
                  }
                  // className="picture-src"
                  id={"tutor" + data?._id}
                  title={data.name}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 display-none-md d-flex flex-column ps-3">
            <div>
              <div className="d-flex align-items-center">
                <div
                  className="titr5 text-start text-gray-green cursor"
                  onClick={(e) =>
                    window.navigateHandle(
                      `/${window.lcs}/jobs/ready/page/${data?._id}`,
                      { currencies, data }
                    )
                  }
                >
                  {data.name}

                </div>
            

                <Tooltip1
                  icon={
                    <img
                      // width="25px"
                      style={{ width: "25px" }}
                      className=""
                      src={`/img/flags/${data?.moreInfo?.address?.countryShort?.toLowerCase()}.svg`}
                      alt={data?.moreInfo?.address?.countryShort}
                      title={window.capitalize(
                        data?.moreInfo?.address?.country
                      )}
                    />
                  }
                  message={
                    <div>
                      {`${data?.moreInfo?.address?.country}, ${data?.moreInfo?.address?.state}, ${data?.moreInfo?.address?.city}`.toUpperCase()}
                    </div>
                  }
                />
              </div>
              <div>
              <div className=" my-1 small-gray-font d-flex flex-wrap  justify-content-end " dir="ltr">
              <i className="bi bi bi-geo-alt mx-2"></i>
              {`${window.capitalize(data?.moreInfo.address?.city || "")},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
            </div>
            <div className="med-gray-font fw-bold">{data?.moreInfo?.title}</div>


                {/* <RatingManuel value={data?.feedbackInfo?.rate} /> */}

                {/* <span className="review-text font-10 mx-1">
                  (
                  {data?.feedbackInfo?.rates
                    ? tools.dynamicNumber({
                        number: data?.feedbackInfo?.rates,
                        lang: window.lang,
                      }) + " "
                    : "بدون "}
                  دیدگاه )
                </span> */}
              </div>
              <div>
                {/* <ProfTutor data={data} />

                <TutorGrades data={data} /> */}
                {user?.roles?.includes(1000) && (
                  <span className="review-text text-center">
                    (
                    <i
                      className="bi bi-eye Business-cover-icon px-1  "
                      title="بازدید"
                    ></i>
                    {data?.feedbackInfo?.views} )
                  </span>
                )}
              </div>
            </div>

            <div className="mt-auto">
              {/* {data?.moreInfo?.stList?.length > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-pencil me-2"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.stList?.length,
                    lang: window.lang,
                  })}{" "}
                  دانشجوی فعال
                </div>
              )}
              {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-book me-2"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.transactions?.sessionCounter,
                    lang: window.lang,
                  })}{" "}
                  کلاس برگزار شده
                </div>
              )} */}

              <div className="  divider-small w-100 border-0 mb-0 mt-3 d-flex">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <div className="p-0 mb-0 med-gray-font text-justify-metamarce ">

{data?.moreInfo?.bio}


    
                  {/* {data.moreInfo?.education?.map((edu,i)=><div key={i}>
                        {`${tools.renderTrans({key:edu.level,section:'education'})}, ${edu.field}, ${edu.institute}`}
                  </div>)} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 d-flex flex-column">
            <div className="card-footer  d-flex flex-wrap  justify-content-between ">
              <div className="">
                <strong>
                  {/* {tools.showPrice(
                    Math.ceil(
                      Math.ceil(
                        data?.moreInfo?.salary?.rate /
                          (matchedCurr?.exRate ??
                            data?.moreInfo?.salary?.exRate)
                      ) * exRate
                    ),
                    currency
                  )} */}
                </strong>
              </div>
              <div>
                <button
                  id={`saveBtn${data._id}`}
                  className="icon-btn icon-stack "
                  onClick={saveHandler}
                >
                  {spiner ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user.saved?.tutor?.includes(data._id)
                          ? "bi bi-bookmark-fill icon-stack-1x "
                          : "bi bi-bookmark icon-stack-1x "
                      }
                      title="ذخیره"
                    ></i>
                  )}
                </button>
                <button
                  className=" icon-stack mx-1 text-green-dark "
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  <i className="bi bi-share" title="همرسانی"></i>
                </button>

             
              </div>
            </div>
            <div>
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lcs}/jobs/ready/page/${data?._id}`}
                title={data?.name}
                img={data?.moreInfo.img}
                _id={data?._id}
              />
            </div>
            <div>
              
              <ul className="d-flex flex-wrap my-3 p-0">
                <i className="bi bi-translate font-20"></i>
                {data?.moreInfo?.languages.slice(0, 2)?.map((lang, i, arr) => (
                  <li key={i} className="small-gray-font pt-1">
                    <span
                      // htmlFor={lang + i}
                      className="form-check-label px-1 "
                    >
                      {tools.renderTrans({
                        section: "languages",
                        key: lang.name,
                      })}
                    </span>
                    (
                    {tools.renderTrans({
                      section: "langsLevel",
                      key: lang.level,
                    })}
                    ){i < arr.length - 1 && "،"}
                  </li>
                ))}
                <div style={{ position: "relative" }}>
                  {data?.moreInfo?.languages.length > 2 && (
                    <span
                      className="cursor cart-count bg-pinkOrangeDark text-beigeYellow"
                      onClick={(e) =>
                        window.navigateHandle(
                          `/${window.lcs}/jobs/ready/page/${data?._id}`,
                          { currencies, data }
                        )
                      }
                    >
                      +
                      {tools.dynamicNumber({
                        number: data?.moreInfo?.languages.length - 2,
                        lang: window.lang,
                      })}
                      {/* {`+${data?.moreInfo?.languages.length - 2}`} */}
                    </span>
                  )}
                </div>
              </ul>
             
              <div className="my-3 my-md-0 p-0">
              <i class="bi bi-card-checklist font-20 me-1 "></i> 
              <span className="small-gray-font ">{(data?.moreInfo?.skills?.substring(0,90)+(data?.moreInfo?.skills.length>90?"...":""))}</span>
              </div>
            </div>
            <div className="display-tablet">
              {/* {data?.moreInfo?.stList?.length > 0 && (
                <div className="small-gray-font">
                  {" "}
                  <i className="bi bi-pencil me-2"></i>
                  {data?.moreInfo?.stList?.length} دانشجوی فعال
                </div>
              )}
              {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-book me-2"></i>
                  {data?.moreInfo?.transactions?.sessionCounter} کلاس برگزار شده
                </div>
              )} */}

              <div className="  divider-small w-100 border-0 my-2 d-flex">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <div className="p-0 mb-0 med-gray-font text-justify-metamarce ">
                {data.moreInfo?.education?.map((edu,i)=><div key={i}>
                        {`${tools.renderTrans({key:edu.level,section:'education'})}, ${edu.field}, ${edu.institute}`}
                  </div>)}
                </div>
              </div>
            </div>
            {/* <div className="mt-auto">
              <button
                type="button"
                disabled={!data.moreInfo.freeSession}
                className={`btn primary-btn checkout-btn cart-footer bg-${
                  data.moreInfo.freeSession ? "pinkOrange" : "grayGreen"
                }  mt-3  border-0 shadow-none py-1  w-100`}
                onClick={(e) =>
                  window.navigateHandle(
                    `/${window.lcs}/jobs/ready/page/${data._id}#tutor-schedule`,
                    { currencies, data }
                  )
                }
              >
                <span>
                  <i className="bi bi-clipboard-check me-2 font-20"></i>
                </span>
                جلسه آزمایشی
              </button>
              <button
                type="button"
                // className="btn primary-btn checkout-btn cart-footer bg-pinkOrangeDark mt-3  border-0 shadow-none py-1 w-100 "
                disabled={isOwner}
                className={`btn primary-btn checkout-btn cart-footer bg-${
                  isOwner ? "grayGreen" : "pinkOrangeDark"
                } mt-3  border-0 shadow-none py-1 w-100  `}
                // data-bs-toggle="modal"
                // data-bs-target="messageModal"
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (isOwner) return;
                  setActiveTutor(data);
                  if (data.moreInfo.requests.some((t) => t._id === user?._id)) {
                    document.getElementById("chatBoxBtn").click();
                    messageRef.current.showWarning({
                      message:
                        "درگذشته پیام شما فرستاده شده است، \n لطفا در پیام رسان متامارس ادامه دهید.",
                    });
                  } else {
                    const myModal = document.getElementById("messageModal"); // Get the modal element
                    const modal = new window.bootstrap.Modal(myModal, {
                      // backdrop: "static",
                      keyboard: true,
                    });
                    modal.show();
                  }
                }}
              >
                <span>
                  <i
                    className="bi bi-send-plus mx-2"
                    style={{ fontSize: 20 }}
                  ></i>
                </span>
                فرستادن پیام
              </button>
            </div> */}
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
