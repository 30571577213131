import React from "react";
import renderData from "../../utils/renderData";
import FormikErrRender from "./common/alerts/formikErrRender";

export default function AddSocialMedia({ formik }) {
  return (
    <div className="" id="maintable2">
      {formik.values?.socialMedia.map((social, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="d-flex mb-1 ">
            <select
              className=" login-btn  selectpicker shadow-none w-50 font-12"
              aria-label="Default select example"
              name={social + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`socialMedia[${i}].platform`)}
            >
              <option defaultValue={social?.platform}>
                {renderData.socialMedias.find(
                  (so1) => so1.name === social?.platform
                )?.[window.lang] || "انتخاب کنید:"}
              </option>
              {renderData.socialMedias?.map((social, i) => (
                <option key={i} value={social.name}>
                  {social?.[window.lang]}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="form-control login-btn w-60"
              placeholder="  مثال:https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr . "
              aria-label="Text input with dropdown button"
              autoComplete="off"
              defaultValue={social?.url}
              style={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
              }}
              onChange={(e) =>
                formik.setValues((pre) => {
                  const newF = { ...pre };
                  newF.socialMedia[i].url = e.target.value;
                  return newF;
                })
              }
            />
            <button
              type="button"
              style={{ visibility: i > 0 ? "visible" : "hidden" }}
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          </div>
            <FormikErrRender formik={formik} field={`socialMedia.${i}.platform`} />
            <FormikErrRender formik={formik} field={`socialMedia.${i}.url`} />
        </div>
      ))}

      {!formik.errors.socialMedia && (
        <div
        // className='border-top border-secondary'
        >
          <div
            className="btn btn-xs shadow-none login-trigger2  "
            title="افزودن شبکه اجتماعی"
          >
            <i
              className="bi bi-plus-circle-fill "
              style={{ fontSize: "1.5em" }}
              onClick={addsocialHandle}
            ></i>
          </div>
        </div>
      )}
    </div>
  );

  function addsocialHandle() {
    console.log("addsocialHandle");

    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.socialMedia.length;
      // console.log("addsocialHandle", newF, "index", index);
      newF.socialMedia.push({ platform: "", url: "" });
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.socialMedia.splice(index, 1);
      return newFormik;
    });
  }
}
