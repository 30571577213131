import axios from "axios";
import React, { Component } from "react";
import { createContext } from "react";
export const ProductsContext = createContext({});

class ProductsContextProvider extends Component {

    state = {
    products: [],
    error: false,
    loading: false,
    errorMessage: "",
       };
 
  componentDidMount() {  
    // (async () => {
    //   try {
    //     this.setState({ loading: true });
    //     const { data } = await axios.get("/api/products");
    //     this.setState({ loading: false, products: data });
    //     } catch (error) {
    //     this.setState({
    //       loading: false,
    //       error: true,
    //       errorMessage: error.message,
    //     });
    //   }
    // })();
 

  } 

  render() {
    return (
      <ProductsContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ProductsContext.Provider>
    );
  }


  handle=(n)=>{return n}
  
}

export default ProductsContextProvider;
