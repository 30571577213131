import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import RatingManuel from "../../../comment/rating";
// import SocialMedias from "../components/socialMedias";
import apiMP from "../../../../utils/apiMP";
// import CommentsRating from "../../../comment/commentsRating";
// import SlideshowGalery from "../components/slideshowGalery/slideshowGalery";
import { CartContext } from "../../../contexts/cartContext";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import GoogleMap from "../../../googleMap/googleMap";
import renderData from "../../../../utils/renderData";
// import ShareSocialMedias from "../components/shareSocialMedias";
import ToastNote from "../../../golbal/notifications/toastNote";
import { CircularProgress, Rating } from "@mui/material";
// import Countdown from "../../../golbal/countDown/countDown";
import BusCardSlider from "../../../golbal/sliders/busCardSlider";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import QRCode from "qrcode.react";
import MetaDecorator from "../../../../utils/metaDecorator";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";
// import ActionBtnGenerator from "../../demy/components/actionBtnGenerator";
// import BusPageSch from "../components/busPageSch";
import { FiberNew } from "@mui/icons-material";
import SlideshowGalery from "../../business/components/slideshowGalery/slideshowGalery";
import ShareSocialMedias from "../../business/components/shareSocialMedias";

export default function UsedPage(props) {
  // const [passwordCounter, setPaswordCounter] = useState(1);
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);
  const [request, setRequest] = useState({ email: "", message: "" });
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const { id } = useParams();
  const [data, setData] = useState(useLocation().state);
  const [busSlider, setBusSlider] = useState([]);
  // console.log("data", data);
  const [loading, setLoading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    save: false,
  });

  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;  
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    // console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data._id,
      token: user.token,
      section: "used",
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 10);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // user.saved.used.find((x) => x._id == used?._id);
    !user?.saved?.used?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "آگهی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "آگهی از لیست ذخیره حذف شد.",
        });

    signedUser(response.data);
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      if (id?.length !== 24)
        return messageRef.current.showError({
          message: "شناسه صحیح نمی باشد، دوباره وارد این صفحه شوید.",
        });
        
      const response = await apiMP.usedDetails({token:user.token,id});
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      // fetchBusSlider(response.data);
      effectRan.current = true;
    })();
  }, [id]);

  // async function fetchBusSlider(data) {
  //   setTimeout(async () => {
  //     // console.log("fetchBusSlider", data);
  //     const filters = {
  //       lang: window.lang,
  //       country: data.moreInfo.address.country,
  //       state: data.moreInfo.address.state,
  //       stateShort: data.moreInfo.address.stateShort,
  //       mainCatId: data.category.mainCat._id,
  //       subCatId: data.category.subCat._id,
  //       pageNumber: 1,
  //       itemsToShow: 12,
  //       sort: "dateB",
  //     };
  //     console.log(filters);
  //     setLoading((pre) => ({ ...pre, busSlider: true }));
  //     const responseSlider = await apiMP.BusCardsSlider({ filters });
  //     setLoading((pre) => ({ ...pre, busSlider: false }));
  //     // console.log(responseSlider);
  //     if (responseSlider.error) return;
  //     setBusSlider(responseSlider.data);
  //   }, 200);
  // }

  // console.log(data);

  // function claimHandle(e) {
  //   e.preventDefault();
  //   console.log(e.target);
  //   if (user.userName)
  //     return navigate(
  //       `/${window.lcs}/dashboard/user/data/edit/${data?._id}`,
  //       { state: data },
  //       { replace: true }
  //     );
  // }
  // const openGoogleMaps = (destination) => {
  //   if (!destination || !data?.moreInfo?.address?.displayAddress)
  //     return messageRef.current.showError({
  //       message: "آدرس در دسترس نمی باشد",
  //     });
  //   const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
  //     destination
  //   )}`;
  //   window.open(googleMapsUrl, "_blank");
  // };

  // const GoogleMapsIframe = ({ destination }) => {
  //   const embedUrl = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
  //     destination
  //   )}`;

  //   return (
  //     <div>
  //       <iframe
  //         width="600"
  //         height="450"
  //         frameBorder="0"
  //         style={{ border: 0 }}
  //         src={embedUrl}
  //         allowFullScreen=""
  //       ></iframe>
  //     </div>
  //   );
  // };
  const title = `${data?.title} | ${
    data?.category?.subCat?.[window.lang]
  } در ${window.capitalize(data?.moreInfo?.address?.city)}`;
  const description = `${data?.moreInfo?.description?.substring(
    0,
    120
  )} | ${data?.moreInfo?.address.city}`;
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      name: data?.title,
      description: data?.moreInfo?.description,
      priceRange: "$$",
      // telephone: data?.moreInfo?.businessPhone,
      // email: data?.email,

      image: [   
        ...(data?.moreInfo?.photos ?? []),
      ],
      url: window.location.href,
      address: {
        "@type": "PostalAddress",
        addressLocality: data?.moreInfo?.address?.city?.toUpperCase() ?? "",
        addressRegion: data?.moreInfo?.address?.state?.toUpperCase(),
        addressCountry:
          data?.moreInfo?.address?.countryShort?.toUpperCase(),
        // postalCode: data?.moreInfo?.address?.postalCode ?? "",
        // streetAddress: `${data?.moreInfo?.address?.streetNumber} ${data?.moreInfo?.address?.route}`,
      },
      // geo: {
      //   "@type": "GeoCoordinates",
      //   latitude: data?.moreInfo?.address?.coordinates?.lat || "",
      //   longitude: data?.moreInfo?.address?.coordinates?.lng || "",
      // },

      // ...(data?.feedbackInfo?.rates > 0 && {
      //   aggregateRating: {
      //     "@type": "AggregateRating",
      //     ratingValue: data?.feedbackInfo?.rate ?? 0,
      //     bestRating: 5,
      //     ratingCount: data?.feedbackInfo?.rates ?? 0,
      //   },
      // }),
      // ...(data?.feedbackInfo?.comment?.comments?.length > 0 && {
      //   review: data?.feedbackInfo?.comment?.comments?.map((rev) => ({
      //     "@type": "Review",
      //     reviewRating: {
      //       "@type": "Rating",
      //       ratingValue: rev?.rate || 0,
      //     },
      //     author: {
      //       "@type": "Person",
      //       name: rev?.userName,
      //     },
      //   })),
      // }),
      ...(data?.moreInfo?.tags?.length > 0 && {
        keywords: data?.moreInfo?.tags?.map((tag) => tag),
      }),
      // openingHoursSpecification: data?.moreInfo?.workingDays?.map(
      //   (day) => ({
      //     "@type": "OpeningHoursSpecification",
      //     dayOfWeek: day.weekDay,
      //     opens: day.from,
      //     closes: day.to,
      //   })
      // ),
      additionalType: [
        data?.category?.subCat?.[window.lang],
        // Add more categories as needed
      ],
      // "acceptsReservations": "True"
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.title,
      description: data?.moreInfo?.description,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/mp/used/filters`,
              name: ` آگهی های نیازمندی ${translations?.[window.lang]}`,
              description: `نیازمندیها ، خرید و فروش  ${translations?.[window.lang]}`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: data?.title,
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title,
    description,
    section: "market place page",
    url: `https://www.metamarce.com/${window.lcs}/mp/used/page/${data?._id}`,
    img: data?.moreInfo?.photos?.businessCard,
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* <div className="bus-bio-bg"> */}
      <nav className="" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${window.lcs}`} className="breadcrumb-item">
              خانه
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${window.lcs}/mp`} className="breadcrumb-item">
              کسب و کارها
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/${window.lcs}/mp/used/filters`}
              className="breadcrumb-item"
            >
              آگهی‌های نیازمندی
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong> {data?.title.substring(0,20)}</strong>
            </span>
          </li>
        </ol>
      </nav>
      {/* </div>         */}

      {/* ****** cover ******** */}
      <div
        className="listing-hero pt-0"
        data-setbg="/img/img/listing/details/listing-hero.jpg"
        style={{
          objectFit: "cover",
          backgroundImage: `url(${data?.moreInfo?.photos?.[0]})`,
        }}
      >
        <div className=" container2 px-md-5 px-3 pt-0">
          <div className="row px-1 px-lg-5 ">
            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
              <div className="listing-cover-page">
                <div className="container">
                  <div className="picture-container">
                    <div className="picture">
                      <img
                        // src="/img/Images/icons8-add-image-64.png"
                        src={data?.moreInfo?.photos?.[1]}
                        className="picture-src"
                        id="wizardPicturePreview"
                        title={data?.title?.substring(
                          0,
                          30
                        )}
                        loading="lazy"
                      />
                      {/* <input type="file" id="wizard-picture" className="" /> */}
                    </div>
                  </div>
                  <div className="bus-bio-bg">
                    <h1 className="business-name-cover">
                      {tools.linesSpliter(data?.title)}
                    </h1>
                    {/* <!-- *******rating stars shold show up here -->*/}
                    {/* <p className="text-start">
                      <a href="#comments2">
                        <RatingManuel value={data?.feedbackInfo?.rate} />
                        <span>
                          {Math.floor(data?.feedbackInfo?.rate * 100) / 100}
                        </span>
                        <span className="review-text">
                          (
                          {data?.feedbackInfo?.rates
                            ? data?.feedbackInfo?.rates
                            : "بدون "}
                          رای)
                        </span>
                      </a>
                      <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {data?.feedbackInfo?.views} )
                      </span>
                    </p> */}
                    <div className="d-flex mt-5 mb-1">
                      <div title=" تاریخ ثبت " className="m-0">
                        <i className="bi bi-calendar-plus Business-cover-icon px-2 "></i>
                        <span className="small-gray-font">
                       
                          {tools.dynamicDate({
                            date: data?.createdAt,
                            countryShort:
                              window.location.pathname.split("/")[2],
                            lang: window.lang,
                            showYear: true,
                            short: true,
                          })}
                        </span>
                      </div>
                      <div title="آخرین به روز رسانی " className="m-0">
                        <i className="bi bi-calendar2-check Business-cover-icon px-2 "></i>
                        <span className="small-gray-font">
                        
                          {tools.dynamicDate({
                            date: data?.moreInfo?.lastUpdate,
                            countryShort:
                              window.location.pathname.split("/")[2],
                            lang: window.lang,
                            showYear: true,
                            short: true,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-5 px-0 d-flex flex-wrap align-items-end justify-content-lg-end justify-content-center ">
              {/* <!-- Button trigger modal --> */}

        

              {((
                (user?._id ?? false)) ||
                (
                  data?.moreInfo?.history?.createdBy?._id ===
                    (user?._id ?? false)) ||
                user?.roles?.includes(1000)) && (
                <>
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    // data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      // user.roles?.includes(9999)?
                      `/${window.lcs}/dashboard/mp/editused/${data?._id}`
                      // : "#exampleModalToggle"
                    }
                    state={data}
                    type="Link"
                  >
                    ویرایش ‌<i className="bi bi-arrow-repeat"></i>
                  </Link>
                 
                </>
              )}

              {/* {data?.moreInfo?.history?.claimedBy[0]?._id ===
                (user?._id ?? false) &&
                data.moreInfo.status !== "01-draft" && (
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      user.roles?.includes(9999) &&
                      `/${window.lcs}/dashboard/user/ads/plans?_id=${data?._id}&targetIdentifier=Business`
                    }
                    state={data}
                    type="Link"
                  >
                    بیشتر دیده شوید‌<i className="bi bi-magic"></i>
                  </Link>
                )} */}

              <button
                className="btn btn-primary share-cover-btn m-2 "
                data-bs-toggle="modal"
                // data-bs-target={`#myModel${data?._id}`}
                data-bs-target={`#myModel${data?._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                همرسانی
                <i className="bi bi-share-fill mx-2"></i>
              </button>

              {/* <!-- Modal --> */}
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lcs}/mp/used/page/${data?._id}`}
                title={data?.title}
                img={data?.moreInfo?.photos?.[0]}
                _id={data?._id}
              />

              <button
                className="btn btn-outline-secondary save-cover-btn m-2"
                onClick={saveHandler}
              >
                ذخیره
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.used?.includes(data?._id)
                        ? "bi bi-bookmark-fill ms-1"
                        : "bi bi-bookmark ms-1"
                    }
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-5">
        <div className="row p-0 m-0">
          <div className=" col-md-6 d-flex flex-wrap  justify-content-center mt-3 ">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/mp/used/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat._id}`}
              // to={'/${lcs}/data/filters?mainCat=${data?.category.mainCat?.[window.lang]}&mainCatId=${data?.category?mainCat?._id}'}
              className="btn btn-primary button-filter button-filter-tag"
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/mp/used/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link>
          </div>

          <div className=" col-md-6 d-flex flex-wrap  justify-content-center  mt-3">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              {repliesCounter()} دیدگاه‌
            </a>
            <Link
              className="btn btn-primary button-filter "
              to={`/${window.lcs}/mp/used/filters?usedId=${data?._id}`}
            >
              <span>
                <i className="bi bi-person-square me-1"></i>
              </span>
              همکاری
            </Link>

            {/* KEEEEEEEEEEP */}
            {/* <a href="#" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-shop me-1"></i>
              </span>
              فروشگاه
            </a> */}
           {data?.scheduleInfo?.length>0 &&<a href="#tutor-schedule" className="btn btn-primary button-filter ">
              <span>
                {/* <i className="bi bi-calendar-check me-1"></i> */}
                <FiberNew/>
              </span>
              قرار ملاقات
             
            </a>}
            <button
              onClick={(e) => {
                if (!window.logined(9999)) return;
                const myModal = document.getElementById("messageModal"); // Get the modal element
                const modal = new window.bootstrap.Modal(myModal, {
                  // backdrop: "static",
                  keyboard: true,
                });
                modal.show();
              }}
              className="btn btn-primary button-filter "
            >
              <span>
                <i className="bi bi-send-plus me-1"></i>
              </span>
              فرستادن پیام
            </button>
            {/* <!-- Modal --> */}
            <div
              className="modal fade"
              id="messageModal"
              tabIndex="-1"
              aria-labelledby="sendMessageModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title titr5  "
                      id="sendMessageModalLabel"
                    >
                      فرستادن پیام به {data?.moreInfo?.history?.createdBy?._id?.userName}
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-7">
                        <div>
                          {/* <label
                            className="med-gray-font m-3"
                            htmlFor="sessionRequest"
                          >
                             اگه پرسشی از {data?.busName[window.lang]} دارید میتونید مستقیما ازشون بیرسید.
                          </label> */}
                          <textarea
                            placeholder="پیامتون رو این‌جا بنویسید..."
                            className="w-100 px-3 border-0"
                            name=""
                            id="sessionRequest"
                            cols="50"
                            rows="10"
                            onChange={(e) =>
                              setRequest((pre) => ({
                                ...pre,
                                message: e.target.value,
                              }))
                            }
                          ></textarea>
                          <button
                            type="button"
                            className="btn btn-primary border-0  save-cover-btn w-100 px-3"
                            // data-bs-dismiss="modal"
                            onClick={sendTextHandle}
                          >
                            فرستادن پیام
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5 align-items-center  display-none-md">
                        <img
                          src="/img/Bussiness-page/Messaging-bus.svg"
                          alt="claim"
                          loading="lazy"
                          // style={{ verticalAlign: "center" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}

        {/* <!-- <div className="container"> -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-7 order-md-last">
            {data?.moreInfo?.photos?.length > 0 && (
              <div className="listing-details-information py-3 mb-5">
                <div className=" divider-small border-0 mt-4 px-5">
                  <span>
                    <img
                      src="/img/bussiness-page/gallery.png"
                      className="arrow2 pe-2"
                      alt="info"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">گالری </span>

                  {/* <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"/>  */}
                </div>
                <div className="px-3">
                  <SlideshowGalery
                    data={data?.moreInfo?.photos}
                    alt={data?.title}
                  />
                </div>
              </div>
            )}
            <div className="listing-details-information p-5  pt-3 my-5">
              <div className=" divider-small border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/information.png"
                    className="arrow2 pe-2"
                    alt="info"
                    loading="lazy"
                  />
                </span>

                <span className="p-0 mb-0 ">توضیحات </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div>
                {tools.linesSpliter(data?.moreInfo?.description)}
              </div>
            </div>
        
            {data?.moreInfo?.tags?.length > 0 && (
              <div className="listing-details-information p-5  pt-3 my-5 ">
                <div className=" divider-small border-0 mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="tags"
                      loading="lazy"
                    />
                  </span>

                  <span className="p-0 mb-0 ">برچسب‌ها </span>

                  {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
                </div>

                <ul className=" d-flex flex-wrap justify-content-between">
                  {data?.moreInfo?.tags?.map((tag, i) => (
                    <li>
                      <Link
                        onClick={(e) =>
                          window.scrollTo({ top: 0, smooth: true })
                        }
                        key={i}
                        to={`/${window.lcs}/mp/used/filters?tag=${tag}`}
                      >
                        <span key={i}>
                          {/* <img
                    src="/img/bussiness-page/tag.png"
                    className="arrow2 pe-2"
                    alt="tags"
                  /> */}
                          {/* <i className="bi bi-hash"></i> */}
                          <i className="bi bi-tag me-1"></i>
                        </span>
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

           
              <div className="listing-details-information p-3  my-5  ">
                <div className=" divider-small border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/notes.png"
                      className="arrow2 pe-2"
                      alt="facilities"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-3 ">مشخصات </span>
                </div>

                  <div>
                 { data?.moreInfo?.isFree?<div className="prof-tag my-3">Free</div>:<div>
                  <strong>
                  {tools.showPrice(
                  data?.moreInfo?.price?.rate,
                  data?.moreInfo?.price?.currency
                  )}
                </strong>
                  </div>}
                  </div>
                  <div className="">
                    وضعیت سلامت کالا : 
                   {tools.renderTrans({section:'condition',key:data?.moreInfo?.condition})}                   
                  </div>
                  <div className="">
                    Delivery Option :
                   {tools.renderTrans({section:'delivery',key:data?.moreInfo?.delivery})}                   
                  </div>
                  <div>

                  </div>
              
              </div>
          
     
          </div>
          <div className=" col-lg-5 ">
            {/* <!-- <div className=""> -->*/}

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">اطلاعات تماس</span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>

              {/* <div style={{direction:"ltr"}}> */}
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span>
                  
                      <span className="addressBusPage">
                        {`
                    ${
                      data?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(
                            data?.moreInfo?.address?.number
                          ) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.route?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.route
                          ) + ","
                        : ""
                    }
                    
                    ${
                      data?.moreInfo?.address?.postalCode
                        ? data?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                      </span>
                    
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      data?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.city?.length
                        ? window.capitalize(data?.moreInfo?.address?.city) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.state?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.state
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.country?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.country
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>

                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {data?.moreInfo?.contact}
                  {/* {tools.formatPhoneNumber(data?.moreInfo?.businessPhone)} */}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                {/* <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <div className="text-break">
                    {data?.moreInfo?.website?.length > 0 ? (
                      <a
                        href={
                          (!data?.moreInfo?.website.startsWith("http")
                            ? "http://"
                            : "") + data?.moreInfo?.website
                        }
                        target="_blank"
                      >
                        {data?.moreInfo?.website}
                      </a>
                    ) : (
                      <span title="وبسایت تاکنون ثبت نشده است">
                        بدون وبسایت
                      </span>
                    )}
                  </div>
                </li> */}
                {/* <li className="group-list-item ">
                  <i className="bi bi-envelope mx-3"></i>
                  <div className="text-break">{data?.email}</div>
                </li> */}
                {/* {data?.moreInfo?.address?.displayAddress && (
                  <li
                    className="group-list-item pe-auto cursor"
                    onClick={() =>
                      openGoogleMaps(data?.moreInfo?.address?.googleAddress)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="32"
                      fill="currentColor"
                      className="bi bi-sign-turn-right mx-3"
                      viewBox="0 0 16 32"
                    >
                      <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                      <path
                        fillRule="evenodd"
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                      />
                    </svg>
                    <div className="map-dir">مسیریابی با نقشه گوگل</div>
                  </li>
                )} */}
              </ul>
              {/* </div> */}
              {/* <SocialMedias socialMedias={data?.moreInfo?.socialMedia} /> */}

            </div>
          
              <div className="listing-sidebar-section mt-0 mb-5">
                <div className="divider2 divider-small">
                  <span>
                    <img
                      src="/img/bussiness-page/information-2.png"
                      className="arrow2 pe-2 py-1"
                      alt="more info"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-0 ">صاحب لیستینگ </span>
                </div>
              <div>
                <div className="d-flex">
                <img src={data?.moreInfo?.history?.createdBy._id?.thumbnail} alt={data?.moreInfo?.history?.createdBy._id?.name} width="60px"/>
                  <div>
                    <div>{data?.moreInfo?.history?.createdBy._id?.userName}</div>
                    {/* <div>{tools.dynamicDate({date:data?.moreInfo?.history?.createdBy._id?.createdAt,countryShort:
                            window.location.pathname.split("/")[2],
                            lang: window.lang,
                            showYearMonth : true, 
                           })}</div> */}
                           <div dir="ltr">{tools.showYMHistory(data?.moreInfo?.history?.createdBy._id?.createdAt)}</div>
                           <div> <Rating
                name="read-only"
                value={Number(4)}
                readOnly
                precision={0.5}
              /></div>
                
                  </div>
                </div>
              </div>
              </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/qr-code.png"
                    className="arrow2 pe-2 py-1"
                    alt="tags"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">                 
                  QR-code کسب‌و‌کار در متامارس
                </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div className="text-center">
                <QRCode
                  value={`https://www.metamarce.com/${window.lcs}/mp/used/page/${data?._id}`}
                  fgColor="#BAA78D"
                />
              </div>
            </div>
            {/* future section!!!!!!! */}
            {/* <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <svg
                  className="text-dark-550 dark:text-white ml-2"
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                  <circle
                    cx="35"
                    cy="16"
                    r="8"
                    fill="currentColor"
                    fillOpacity="0.4"
                  ></circle>
                  <circle
                    cx="20"
                    cy="8"
                    r="5"
                    fill="currentColor"
                    fillOpacity="0.7"
                  ></circle>
           
                </svg>
                <span className="p-0 mb-0 ">تنظیم قرار ملاقات</span>

                
              </div>

              <div className="calendar">
                <iframe src="https://calendar.google.com/calendar/embed?src=danagirloftheisland%40gmail.com&ctz=America%2FToronto"></iframe>
              </div>
            </div> */}
          </div>

          {/* <!-- </div> -->*/}
        </div>

        {/* <!-- ممکنه براتون جالب باشه... -->*/}
        {busSlider?.length >= 4 && (
          <div>
            <div className="divider2 ">
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  -->*/}
              </svg>

              <span className="p-0 mb-0 ">ممکنه براتون جالب باشه...</span>
              {/* <img
                src="/img/Images/arrow-09-svgrepo-com.svg"
                className="arrow"
                alt="arrow"
              /> */}
            </div>
            <div className="mx-4">
              <BusCardSlider data={busSlider} />
            </div>

            {/* <BusCardSlider2 
            businessess={data?.businessess}
            subCat={data?.category?.subCategory}
            /> */}
          </div>
        )}
      </div>

      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        {/* <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={id}
            value={data}
            setData={setData}
            collection="Business"
          />
        </div> */}
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  async function sendEmailCode(e) {
    e.preventDefault();
    if (!id)
      return messageRef.current.showWarning({ message: "کسب و کار پیدا نشد!" });
    // if(data?.moreInfo?.status === "02-claimed" || data?.moreInfo?.status === "03-verified")return messageRef.current.showWarning({message:"این کسب و کار فعال می باشد."})
    document.querySelector("#getPhoneCodeInput").style.display = "none";
    e.target.setAttribute("disabled", true);
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 2 * 2);

    setLoading((pre) => ({ ...pre, loading1: true }));
    const response = await apiMP.sendEmailCode({ token: user.token, id });
    setLoading((pre) => ({ ...pre, loading1: false }));
    console.log(response);
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    messageRef.current.showSuccess({
      message: "ایمیل فعالسازی فرستاده شد.",
    });
    document.querySelector("#getEmailCodeInput").style.display = "block";
    document.querySelector(".hideShowControl").style.display = "none";
  }
  // async function checkEmailCodeAndShowPhoneInput(e) {
  //   e.preventDefault();
  //   console.log(id);
  //   console.log(emailCode);
  //   if (emailCode.length < 6)
  //     return messageRef.current.showError({ message: "کد نامعتبر می باشد." });
  //   setLoading((pre) => ({ ...pre, loading2: true }));
  //   const response = await apiMP.checkEmailCodeAndShowPhoneInput({
  //     value: emailCode.trim(),
  //     token: user.token,
  //     id,
  //   });
  //   setLoading((pre) => ({ ...pre, loading2: false }));
  //   console.log(response);
  //   if (response.error)
  //     return messageRef.current.showError({ message: "کد نامعتبر می باشد." });
  //   messageRef.current.showSuccess({
  //     message:
  //       "ایمیل تائید شد،  تلفن خود را در کادر زیر جهت دریافت کد وارد کنید .",
  //   });
  //   e.target.setAttribute("disabled", true);
  //   // document.querySelector("#getEmailCodeInput").style.display = "none";
  //   document.querySelector("#getPhoneNumberInput").style.display = "block";

  //   //  var myModal = document.getElementById('claimModal'); // Get the modal element
  //   // var modal = window.bootstrap.Modal.getInstance(myModal); // Get the Bootstrap modal instance
  //   // modal.hide(); // Close the modal
  // }

  // async function sendCodeToContactPhone(e) {
  //   e.preventDefault();
  //   const phone = data.moreInfo?.suggestMode
  //     ? (contactPhone?.length >= 8 &&
  //         isValidPhoneNumber(contactPhone) &&
  //         contactPhone) ||
  //       data.moreInfo?.businessPhone
  //     : (contactPhone?.length >= 8 &&
  //         isValidPhoneNumber(contactPhone) &&
  //         contactPhone) ||
  //       (data.moreInfo?.contactPhone?.length &&
  //         data.moreInfo?.contactPhone);

  //   e.target.setAttribute("disabled", true);
  //   setTimeout(() => {
  //     e.target.removeAttribute("disabled");
  //   }, 1000 * 60 * 2);
  //   setLoading((pre) => ({ ...pre, loading4: true }));
  //   const response = await apiMP.sendCodeToContactPhone({
  //     token: user.token,
  //     id,
  //     contactPhone: tools.prepareForSMS(phone),
  //   });
  //   setLoading((pre) => ({ ...pre, loading4: false }));
  //   console.log(response);
  //   if (response.error)
  //     return messageRef.current.showError({
  //       message: (
  //         <div>
  //           <div> خطا در انجام عملیات</div>
  //           <div>{response.error}</div>
  //         </div>
  //       ),
  //     });
  //   messageRef.current.showSuccess({
  //     message: "کد فعالسال سازی با پیامک فرستاده شد.",
  //   });
  //   document.querySelector("#getEmailCodeInput").style.display = "none";
  //   document.querySelector("#getPhoneNumberInput").style.display = "none";
  //   document.querySelector("#getPhoneCodeInput").style.display = "block";
  // }

  // async function preActivationHandle(e) {
  //   e.preventDefault();
  //   console.log(id);
  //   console.log(codeA);
  //   setPaswordCounter(passwordCounter + 1);
  //   console.log(passwordCounter);
  //   setTimeout(() => setPaswordCounter(1), 1000 * 60 * 15);
  //   if (passwordCounter > 5)
  //     return messageRef.current.showError({
  //       message:
  //         "تلاش های نادرست شما بیش از 5 می باشد،  پس از 15 دقیقه برای دریافت کد جدید اقدام کنید",
  //     });

  //   if (!codeA || codeA.length < 6)
  //     return messageRef.current.showError({
  //       message: <div> کد را به شکل صحیح وارد کنید ! </div>,
  //     });
  //   setLoading((pre) => ({ ...pre, loading2: true }));
  //   const response = await apiMP.preActivation({
  //     value: codeA.trim(),
  //     token: user.token,
  //     id,
  //   });
  //   setLoading((pre) => ({ ...pre, loading2: false }));
  //   console.log(response);
  //   if (response.error)
  //     return messageRef.current.showError({
  //       message: (
  //         <div>
  //           <div> خطا در انجام عملیات!</div>
  //           <div>{response.error}</div>
  //         </div>
  //       ),
  //     });
  //   messageRef.current.showSuccess({
  //     message:
  //       " شماره تلفن با موفقیت تائید شد،  اطلاعات مورد نیاز کسب و کار را تکمیل کنید.",
  //   });
  //   e.target.setAttribute("disabled", true);
  //   var myModal = document.getElementById("claimModal"); // Get the modal element
  //   var modal = window.bootstrap.Modal.getInstance(myModal); // Get the Bootstrap modal instance

  //   setTimeout(() => {
  //     modal.hide(); // Close the modal
  //     navigate(
  //       `/${window.lcs}/dashboard/user/data/edit/${data?._id}`,
  //       {
  //         state: response.data,
  //       },
  //       { replace: true }
  //     );
  //   }, 4000);
  // }


  async function sendTextHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    const hostUser =data?.moreInfo?.history?.createdBy?._id?._id;

    setLoading((pre) => ({ ...pre, spinner: true }));
    // e.target.setAttribute("disabled", true);
    const email = request.email.length ? request.email : user.email;
    if (!hostUser)
      return messageRef.current.showError({
        message: "شناسه کاربری مالک آگهی فراهم نشده است.",
      });
    const response = await api.sendText({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        hostEmail: data.email,
        hostUser,
        hostId: data._id,
        hostName:data?.moreInfo?.history?.createdBy?._id?.userName,
        lang: window.lang,
        lcs: window.lcs,
        guestId: user._id,
        url: `https://metamarce.com/${window.lcs}/mp/used/page/${data._id}`,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>
            درخواست شما با موفقیت برای {data.title} فرستاده
            شد و در اولین فرصت در متاچت پاسخ خواهند داد.
          </div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}

