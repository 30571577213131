import React from "react";
import renderData from "../../utils/renderData";
import FormikErrRender from "./common/alerts/formikErrRender";

export default function AddEducation({ formik }) {
  return (
    <div className="" id="maintable2">
      {formik.values?.education.map((ed, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="d-flex flex-column mb-1 ">
            <div className="d-flex flex-column my-3">
              <label htmlFor={`ed${i}level`}>مقطع تحصیلی</label>
              
            <select
            id={`ed${i}level`}
              className=" login-btn  selectpicker shadow-none font-12 "
              aria-label="Default select example"
              name={ed + i}
          
              {...formik.getFieldProps(`education[${i}].level`)}
            >
              <option defaultValue={ed?.level}>
                {renderData.education.find(
                  (so1) => so1.name === ed?.level
                )?.[window.lang] || "انتخاب کنید:"}
              </option>
              {renderData.education?.map((educ, i) => (
                <option key={i} value={educ.name}>
                  {educ?.[window.lang]}
                </option>
              ))}
            </select>

            </div>
            <div>
            <label htmlFor={`ed${i}field`}>رشته تحصیلی</label>
            <textarea
              type="textarea"
              id={`ed${i}field`}
              className="form-control login-btn w-60 "
              placeholder="Example: Master of Business Administration "
              aria-label="Text input with dropdown button"
              autoComplete="off"
              defaultValue={ed?.field}
              style={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                border: "1px solid"
              }}
              onChange={(e) =>
                formik.setValues((pre) => {
                  const newF = { ...pre };
                  newF.education[i].field = e.target.value;
                  return newF;
                })
              }
            />
            </div>
            <div>
            <label htmlFor={`ed${i}institute`}>نام موسسه</label>
            <textarea
              type="textarea"
              id={`ed${i}institute`}
              className="form-control login-btn w-60"
              placeholder="Example: Concordia University of Montreal"
              aria-label="Text input with dropdown button"
              autoComplete="off"
              defaultValue={ed?.institute}
              style={{
                borderTopLeftRadius: "20px",
                borderBottomLeftRadius: "20px",
                 border: "1px solid"
              }}
              onChange={(e) =>
                formik.setValues((pre) => {
                  const newF = { ...pre };
                  newF.education[i].institute = e.target.value;
                  return newF;
                })
              }
            />
            </div>
        <div className="d-flex justify-content-between my-2 ">
            <button
              type="button"
              style={{ visibility: i > 0 ? "visible" : "hidden" }}
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
              <span className="font-12 ms-1 ">پاک کردن </span>
              

            </button>
            {!formik.errors.education && (
        <div
        // className='border-top border-secondary'
        >
          <div
            className="btn btn-xs shadow-none login-trigger2  "
            title="افزودن تحصیلات"
          >
            <i
              className="bi bi-plus-circle-fill "
              style={{ fontSize: "1.5em" }}
              onClick={addsocialHandle}
            ></i>
            <span className="font-12"> افزودن تحصیلات</span>
           
          </div>
        </div>
      )}
</div>
          </div>
            <FormikErrRender formik={formik} field={`education.${i}.level`} />
            <FormikErrRender formik={formik} field={`education.${i}.institute`} />
            <FormikErrRender formik={formik} field={`education.${i}.field`} />
        </div>
      ))}

    
    </div>
  );

  function addsocialHandle() {
    console.log("addsocialHandle");

    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.education.length;
      // console.log("addsocialHandle", newF, "index", index);
      newF.education.push({level:"",field:"",institute:"",description:"",});
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.education.splice(index, 1);
      return newFormik;
    });
  }
}
