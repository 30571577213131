import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import apiDemy from "../../../utils/apiDemy";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
import SocialMedias from "../business/components/socialMedias";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";

export default function CenterPage(props) {
  const messageRef = useRef(null);
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({ save: false });
  const [request, setRequest] = useState({
    email: "",
    message: "",
    links: [""],
  });
  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data?._id,
      section: "center",
      token: user.token,
      title: data.moreInfo?.title,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    console.log("data", user?.saved?.data);
    signedUser(response.data);

    !user?.saved?.center?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "مرکز آموزشی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "مرکز آموزشی از لیست ذخیره حذف شد.",
        });
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      if (id?.length !== 24)
        return messageRef.current.showError({
          message: "شناسه صحیح نمی باشد، لطفا دوباره وارد این صفحه شوید.",
        });
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiDemy.fetchCenterDetails(id);
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  // const { translations } =
  //   JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` ${data?.center} | ${(
    data?.moreInfo?.address?.country +
    ", " +
    data?.moreInfo?.address?.state +
    ", " +
    data?.moreInfo?.address?.city
  )?.toUpperCase()}`;
  const description =
    data?.moreInfo?.description?.substring(0, 100) +
    " | " +
    data?.moreInfo?.address?.municipality +
    " | " +
    data?.moreInfo?.address?.city;

  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "EducationalOrganization",
      name: data?.center,
      url: `https://www.metamarce.com/${window.lcs}/demy/center/page/${data?._id}`,
      logo: data?.moreInfo?.logo,
      image: data?.moreInfo?.img,
      address: {
        "@type": "PostalAddress",
        streetAddress: `${data?.moreInfo?.address?.streetNumber} ${data?.moreInfo?.address?.route}`,
        addressLocality: data?.moreInfo?.address?.city?.toUpperCase() ?? "",
        addressRegion: data?.moreInfo?.address?.state?.toUpperCase(),
        postalCode: data?.moreInfo?.address?.postalCode ?? "",
        addressCountry: data?.moreInfo?.address?.countryShort?.toUpperCase(),
      },
      contactPoint: {
        "@type": "ContactPoint",
        telephone: data?.moreInfo?.phone,
        contactType: "ADMISSIONS",
      },
      sameAs: data?.moreInfo?.socialMedia?.map((so) => so.url),
      email: data?.email,
      website: data?.moreInfo?.website,

      // "duns": "DUNS_number",
      // "paymentAccepted": "Cash, Credit Card",
      image: data?.moreInfo?.img,
      description: data?.moreInfo?.description,
      geo: {
        "@type": "GeoCoordinates",
        latitude: data?.moreInfo?.address?.coordinates?.lat || "",
        longitude: data?.moreInfo?.address?.coordinates?.lng || "",
      },
      feesAndCommissionsSpecification: {
        "@type": "TuitionSpecification",
        tuitions: data?.moreInfo?.tuitions?.map((tu) => {
          return {
            "@type": "Tuition",
            resideStatus: tu.resideStatus,
            tuitionLevel: tu.details.map((stl) => {
              return {
                "@type": "StudyLevel",
                name: stl.studyLevel,
                price: `${stl.currency} ${stl.max}`,
                priceCurrency: stl.currency,
                min: stl.min,
                max: stl.max,
                validFrom: "2024-01-01",
                validThrough: "2025-12-31",
                valueAddedTaxIncluded: false,
              };
            }),
          };
        }),
      },
      applicationFees: {
        type: "applicationFees",
        amount: data?.moreInfo?.appFee?.fee,
        currency: data?.moreInfo?.appFee?.currency,
      },
      studentsEnrollment: data?.moreInfo?.enrolment.map((enrol) => {
        return {
          studyLevel: enrol?.studyLevel,
          type: enrol?.type,
          quantity: enrol?.qty,
        };
      }),

      // "founder": "Founder Name",
      // "alumni": "Alumni Name",
      areaServed: [
        {
          "@type": "City",
          name: data?.moreInfo?.address?.city?.toUpperCase() ?? "",
        },
      ],
      dliNumber: data?.moreInfo?.dli,
      pgwpEligibility: data?.moreInfo?.pgwp,
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.center,
      description: data?.description,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: "home",
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/demy`,
              name: "metademy",
              description: "MetaMarce Academy",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: data?.center,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "metademy",
    url: `https://www.metamarce.com/${window.lcs}/demy/center/page/${data?._id}`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/demy/center/page/${data?._id}`,
    img: data?.moreInfo?.img,
    type: "webpage",
  };
  function openGoogleMaps(destination) {
    if (!destination)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  }

  return (
    <div className="page-en">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}
      {/* ****** cover ******** */}
      <nav className="p-md-3 p-2" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${window.lcs}`} className="breadcrumb-item">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/${window.lcs}/demy`} className="breadcrumb-item">
              Metademy
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/${window.lcs}/demy/center/filters`}
              className="breadcrumb-item"
            >
              Centers
            </Link>
          </li>
          <li className="breadcrumb-item">
            <span className="breadcrumb-item active">
              <strong>{data?.center}</strong>
            </span>
          </li>
        </ol>
      </nav>

      <div className="bg-deepGreen py-0" dir="ltr">
        <div className=" container2 p-0 ">
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-2 d-flex justify-content-lg-start justify-content-center">
              <div className="picture-container">
                <div className=" picture picture2">
                  <img
                    // src="/img/Images/icons8-add-image-64.png"
                    src={data?.moreInfo?.logo}
                    className="picture-src"
                    id="wizardPicturePreview"
                    title=""
                  />
                  {/* <input type="file" id="wizard-picture" className="" /> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <h1 className="uni-name-cover text-beigeYellow m-2 my-3">
                {/* className="p-0 mb-0   "> */}
                {data?.center}
              </h1>

              <div className=" d-flex ">
                {user?.roles?.includes(1000) && (
                  <Link
                    className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1 "
                    to={`/${window.lcs}/dashboard/demy/editcenter/${data?._id}`}
                  >
                    edit
                  </Link>
                )}

                <button
                  className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  share
                  <i className="bi bi-share-fill mx-2"></i>
                </button>

                {/* <!-- Modal --> */}
                <div dir="rtl">
                  {" "}
                  <ShareSocialMedias
                    url={`${window.location.origin}/${window.lcs}/demy/center/page/${data?._id}`}
                    title={data?.center}
                    img={data?.moreInfo?.img}
                    _id={data?._id}
                  />
                </div>

                <button
                  className="btn btn-outline-secondary save-cover-btn img-banner-text-23 border-0 mx-2 font-12 py-1"
                  onClick={saveHandler}
                >
                  save
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.center?.includes(data?._id)
                          ? "bi bi-bookmark-fill mx-1"
                          : "bi bi-bookmark mx-1"
                      }
                    ></i>
                  )}
                </button>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary share-cover-btn mx-2 bg-redDarker border-0 font-12"
                    data-bs-toggle="modal"
                    data-bs-target={
                      user.roles?.includes(9999)
                        ? "#messageModal"
                        : "#exampleModalToggle"
                    }
                  >
                    update
                    <i className="bi bi-arrow-repeat me-1"></i>
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    dir="rtl"
                    className="modal fade"
                    id="messageModal"
                    tabIndex="-1"
                    aria-labelledby="sendMessageModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="sendMessageModalLabel"
                          >
                            پیشنهاد به‌روزرسانی
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-7">
                              {user?.email?.length <= 0 && (
                                <div dir="ltr">
                                  <label htmlFor="emailCheck" className=" mx-2">
                                    E-mail:
                                  </label>
                                  <input
                                    type="email"
                                    placeholder="ایمیلتون رو وارد کنید"
                                    id="emailCheck"
                                    onChange={(e) =>
                                      setRequest((pre) => ({
                                        ...pre,
                                        email: e.target.value,
                                      }))
                                    }
                                  />
                                </div>
                              )}

                              <div>
                                <label
                                  htmlFor="sessionRequest"
                                  className="med-gray-font my-3"
                                >
                                  در صورتی که اطلاعات مربوط به این دانشگاه نیاز
                                  به تغییر داره، از شما درخواست می‌کنیم تا موارد
                                  مورد نظرتون رو با جزییات و همراه با لینک (های)
                                  تایید برامون بفرستید. با سپاس بیشمار.{" "}
                                </label>
                                <textarea
                                  name=""
                                  id="sessionRequest"
                                  cols="50"
                                  rows="7"
                                  onChange={(e) =>
                                    setRequest((pre) => ({
                                      ...pre,
                                      message: e.target.value,
                                    }))
                                  }
                                ></textarea>
                              </div>
                              {request.links.map((link, i) => (
                                <div className="my-2">
                                  <label htmlFor={"link" + i} className="mx-2">
                                    لینک : {i + 1}
                                  </label>
                                  <input
                                    type="text"
                                    id={"link" + i}
                                    defaultValue={link[i]}
                                    onChange={(e) =>
                                      setRequest((pre) => ({
                                        ...pre,
                                        links: [
                                          ...pre.links.slice(0, i),
                                          e.target.value,
                                          ...pre.links.slice(i + 1),
                                        ],
                                      }))
                                    }
                                  />
                                  {i !== 0 && (
                                    <button
                                      className="btn btn-primary share-cover-btn bg-redDarker mx-2 font-12 py-1 border-0"
                                      onClick={() =>
                                        setRequest((pre) => ({
                                          ...pre,
                                          links: pre.links.filter(
                                            (_, index) => index !== i
                                          ),
                                        }))
                                      }
                                    >
                                      پاک کردن
                                    </button>
                                  )}
                                </div>
                              ))}
                              <button
                                className="btn btn-primary share-cover-btn img-banner-text-23 mx-2 font-12 py-1 border-0"
                                onClick={(e) =>
                                  setRequest((pre) => ({
                                    ...pre,
                                    links: [...pre.links, ""],
                                  }))
                                }
                              >
                                افزودن لینک
                              </button>
                            </div>
                            <div className="col-md-5 align-items-center my-5 display-none-md">
                              <img
                                src="/img/illustration/task-animate.svg"
                                alt="claim"
                                loading="lazy"
                                // style={{ verticalAlign: "center" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary share-cover-btn bg-pinkOrange  mx-2 font-12 py-1 border-0"
                            // data-bs-dismiss="modal"
                            onClick={sendRequestHandle}
                          >
                            فرستادن
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary share-cover-btn bg-redDarker mx-2 font-12 py-1 border-0"
                            data-bs-dismiss="modal"
                          >
                            بستن
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-3">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            {/* <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/mdcenter/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/mdcenter/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link> */}
          </div>
          <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              ( {repliesCounter()} ) Comments
            </a>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5" dir="ltr">
          <div className="col-lg-8  order-2 order-lg-1">
            {data?.moreInfo?.description?.length > 0 && (
              <div>
                <div className="w-100 image-container image-container-gd rounded-top">
                  <img src={data?.moreInfo?.img} alt={data?.center} />
                </div>
                <div className="listing-details-information px-md-5  p-3 mb-5">
                  <div className=" divider-small divider-small-en border-0 mt-4"></div>

                  <p className="text-justify mt-3">
                    {tools.linesSpliter(data?.moreInfo?.description)}
                  </p>
                </div>
              </div>
            )}
            {data?.moreInfo?.tuitions[0]?.resideStatus?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 my-2">
                  <span>
                    <img
                      src="/img/bussiness-page/pay.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 "> TUITION FEES </span>
                </div>
                <div className="row row-cols-md-2 row-cols-1 g-3 text-justify mb-5  ">
                  {data.moreInfo.tuitions?.map((tuition, i) => (
                    <div className="col">
                      <div className="">
                        <div className="btn text-center primary-btn edit-btn justify w-100 my-3 bg-redDarker">
                          {tuition?.resideStatus}{" "}
                        </div>
                        <div className="fw-bold"></div>
                        {tuition.details.map((detail, i) => (
                          <div className="ps-3 pb-3">
                            <div className="fw-bold ">{detail?.studyLevel}</div>
                            <div>
                              {tools.showPrice(
                                detail?.min,
                                detail.currency,
                                "en"
                              )}{" "}
                              -{" "}
                              {tools.showPrice(
                                detail?.max,
                                detail.currency,
                                "en"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div></div>
                    </div>
                  ))}
                </div>
                <div className="med-gray-font mt-3">
                  {" "}
                  {tools.linesSpliter(data?.moreInfo?.tuitionDesc)}
                </div>
              </div>
            )}
            {data?.moreInfo?.enrolment[0]?.studyLevel?.length > 0 && (
              <div className="listing-details-information px-md-5 p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/graduated.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="px-1 mb-0 ">STUDENT ENROLMENT</span>
                </div>
                <div className="row row-cols-md-2 row-cols-1 g-3">
                  {data?.moreInfo?.enrolment?.map((enrol, i) => (
                    <div className="col ">
                      <div className="fw-bold fs-5">
                        {enrol?.type + " - " + enrol?.studyLevel}
                      </div>
                      <div>{enrol?.qty}</div>
                    </div>
                  ))}
                </div>

                <div className="med-gray-font mt-3">
                  {data?.moreInfo.tuitionDesc}
                </div>
              </div>
            )}

            {/* {data?.moreInfo?.resideDesc?.length > 0 && ( */}
            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className=" divider-small divider-small-en border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/home.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>

                <span className="px-1 mb-0 ">RESIDENCE</span>
              </div>
              <p className=".text-justify mt-3">{data?.moreInfo?.resideDesc}</p>
              <ul className="list-group px-1">
                {data?.moreInfo?.resideLinks?.map((link, i) => (
                  <li key={i}>
                    <a href={link.url} target="_blank">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* )} */}
          </div>
          <div className=" col-lg-4 order-1 order-lg-2">
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/home-address.png"
                    className="arrow2 ms-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="mb-0 ">LOCATION</span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    {/* <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span> */}

                    <span className="addressBusPage">
                      {`
                    ${
                      data?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(data?.moreInfo?.address?.number) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.route?.length
                        ? window.capitalize(data?.moreInfo?.address?.route) +
                          ","
                        : ""
                    }
                    
                    ${
                      data?.moreInfo?.address?.postalCode
                        ? data?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                    </span>
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      data?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.city?.length
                        ? window.capitalize(data?.moreInfo?.address?.city) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.state?.length
                        ? window.capitalize(data?.moreInfo?.address?.state) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>
                <li
                  className="group-list-item pe-auto cursor"
                  onClick={() =>
                    openGoogleMaps(data?.moreInfo?.address?.googleAddress)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="32"
                    fill="currentColor"
                    className="bi bi-sign-turn-right mx-2 my-1"
                    viewBox="0 0 16 32"
                  >
                    <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                    <path
                      fillRule="evenodd"
                      d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                    />
                  </svg>
                  <div className="map-dir">Get direction</div>
                </li>
              </ul>

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={data?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en border-0">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 "> UNIVERSITY RESOURCES </span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {tools.formatPhoneNumber(data?.moreInfo?.phone ?? "")}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <a href={data?.moreInfo?.website} target="_blank">
                    {data?.moreInfo?.website}
                  </a>
                </li>
                <li className="group-list-item">
                  <i className="bi bi-envelope mx-3"></i>
                  {data?.email}
                </li>
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={data?.moreInfo?.socialMedia} />

              {data?.moreInfo?.links?.map((link, i) => (
                <div className="mt-3" key={i}>
                  <strong>{link.section}</strong>
                  <ul className="d-flex flex-column">
                    {link.urls.map((url, i) => (
                      <li>
                        <a href={url.url} target="_blank">
                          {url.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={data?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/maple-leaf.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span>Institution Details </span>
              </div>

              <p>
                {" "}
                <strong>DLI# </strong>
                <span>{data?.moreInfo?.dli}</span>
              </p>

              <p className="d-flex justify-content">
                {" "}
                <strong>PGWP-eligible </strong>
                <span className="mx-2">
                  {data?.moreInfo?.pgwp ? "Yes" : "No"}
                </span>
              </p>
              <p>
                {" "}
                <strong>Application Fee </strong>
                {data?.moreInfo?.appFee?.waived ? (
                  <span>Waived</span>
                ) : (
                  <span>
                    <span className="mx-2">
                      {tools.showPrice(
                        data?.moreInfo?.appFee?.fee,
                        data?.moreInfo?.appFee?.currency,
                        "en"
                      )}
                    </span>
                    <span>
                      {tools.linesSpliter(data?.moreInfo?.appFee.desc)}
                    </span>
                  </span>
                )}
              </p>

              <p>
                {" "}
                <strong> Year established</strong>
                <span className="med-gray-font text-end mx-2">
                  {" "}
                  {data?.moreInfo?.yearBuilt}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={id}
            value={data}
            setData={setData}
            collection="Center"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    e.target.setAttribute("disabled", true);

    setLoading((pre) => ({ ...pre, spinner: true }));
    const email = request.email.length ? request.email : user.email;
    const response = await apiDemy.centerNeedUpdate({
      token: user.token,
      data: {
        message: request.message,
        email,
        links: request.links,
        _id: data._id,
        lang: window.lang,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>Thanks for your update.</div>
          <div>Your informations has sent to support team.</div>
        </div>
      ),
    });
    setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "", links: [""] });
  }
}
