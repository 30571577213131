import { NavLink } from "react-router-dom";

export default function UserDashAdsNav() {
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/user/ads/plans`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        پلن‌ها
      </NavLink>
      {/* <NavLink to={`/${window.lcs}/dashboard/user/ads/myplans`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>پلن‌های من</NavLink> */}
      {/* <NavLink to={`/${window.lcs}/dashboard/user/ads/reports`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>گزارش</NavLink>
        <NavLink to={`/${window.lcs}/dashboard/user/ads/billboard`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>بیلبورد من</NavLink> */}
    </div>
  );
}
