import { useContext, useRef } from "react";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function TutoringRules() {
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  return (
    <div className="mx-md-5">
      <ToastNote messageRef={messageRef} />
      <h1 className="titr7 text-red-dark mt-5 mb-3 text-start my-5">
        قوانین و مقررات
      </h1>
      <div id="introduction">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          مقدمه
        </h5>
        <div className="top-margin justify terms__content">
          متامارس به عنوان یک پلتفرم که صرفا فراهم‌کننده امکان آموزش و رزرو کلاس
          به صورت آنلاین و حضوری با مجموعه ای از اساتید می‌باشد. کاربران در
          متامارس می‌توانند با رزرو زمان مطلوب خود با اساتید فعال کلاس خود را
          برگزار نمایند، قوانین این بخش(متادمی) بین متامارس به عنوان ارائه دهنده
          سرویس و کاربران متامارس ( دارندگان حساب کاربری، متشکل از دانشجویان و
          اساتید) می باشد. متامارس در هر زمان ممکن است تغییراتی در قوانین اعمال
          کند که مراتب آن را به کاربران خود اطلاع خواهد داد و مفاد آن همواره در
          این بخش قابل مشاهده خواهد بود.
        </div>
      </div>
      <div id="onus">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          تعهدات کاربر
        </h5>
        <div className="top-margin justify terms__content">
          کاربر موظف است در زمان ثبت‌نام اطلاعات شخصی خود از جمله نام و نام
          خانوادگی، آدرس ایمیل و یا شماره موبایل را به درستی وارد نماید، در صورت
          وجود هرگونه مغایرت مسئولیت آن بر عهده کاربر خواهد بود. چنانچه هر
          کاربری به هر شکلی قصد ایجاد هرگونه اختلال و یا مزاحمت را داشته باشد
          متامارس می‌تواند بدون اطلاع قبلی حساب وی را حذف نمائید.
        </div>
      </div>
      <div id="trialclassName">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          کلاس های آزمایشی
        </h5>
        <div className="top-margin justify terms__content">
          کلاس‌های آزمایشی غالبا برای شناخت بیشتر با استاد بوده و بررسی تناسب
          نیازهای دانشجو با توانمندی‌های مدرس، تشخیص روند این کلاس برعهده استاد
          بوده و در توافق با دانشجو روند این کلاس پیش گرفته می‌شود.
        </div>
      </div>
      <div id="nonTrialclassName">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          انتخاب مدرس و برگزاری کلاس های آموزشی (غیرآزمایشی)
        </h5>
        <div className="top-margin justify terms__content">
          کاربر پس از رزرو کلاس با استاد مورد نظر خود موظف است در کلاس حاضر شده
          یا در صورت تمایل به تغییر طبق مقررات پلتفرم اقدام به جابجایی یا کنسلی
          کلاس خود نماید در غیر این صورت اگر وی در کلاس حاضر نشود کلاس موردنظر
          برگزار شده، لحاظ می‌گردد. متامارس نیز از طرفی و به طورمرتب و منظم طبق
          الگوریتم های توسعه‌یافته خود کیفیت آموزشی و تکمیل بودن پروفایل اساتید
          را بررسی می‌نماید و در تلاش است تا انتخاب اساتید برتر را برای کاربران
          ساده‌تر کند، اما این بدان معنی نیست که تضمین کیفیت برگزاری کلاس‌های
          اساتید با متامارس است. لذا اکیدا توصیه می‌گردد دانشجو، پروفایل استاد
          را به طوردقیق بررسی کرده ( مشاهده ویدئو معرفی استاد، خواندن نظرات
          دانشجویان قبلی و ...) و سپس با استاد مورد نظر خود کلاس آزمایشی رزرو
          کند. تشخیص انتخاب درست استاد بر عهده دانشجو می‌باشد.
        </div>
      </div>
      <div id="financial">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          مقررات مالی
        </h5>
        <div className="top-margin justify terms__content"></div>
        <div className="terms__child-title-box">
          <div id="share">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              سهم متامارس
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned"></div>
              متامارس به عنوان کمیسیون و پرداخت هزینه های نقل و انتقالات مالی
              بین المللی، پیشتیبانی و همچنین به منظور فراهم نمودن امکان توسعه
              زیرساخت‌ها برای امکانات بیشتر و تجربه‌کاربری بهتر، ۲۰ درصد از مبلغ
              کلاس را برداشت می‌نماید. کنسلی ها مستقیما و بدون هرگونه تغییری به
              حساب استاد یا دانشجویی که متحمل لغو کلاس شده، واریز می‌گردد.
            </div>
          </div>
          <div id="time">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              زمان تسویه حساب
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned"></div>
              کارکرد خالص اساتید منطبق بر ماه های میلادی محاسبه و در هفته اول
              ماه بعد به اساتید محترم پرداخت می‌گردد.
            </div>
          </div>

          <div id="cancel">
            <div className="text-pink-orange-dark my-3 ms-md-5 ms-2">
              <i className="bi bi-circle-fill font-10 me-1"></i>
              قوانین کنسل کردن کلاس
            </div>
            <div className="top-margin justify terms__content">
              <div className="flex full-width center-aligned">
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-8">
                    <div className="row">
                      <div className="col-5"></div>
                      <div className="col-7"></div>
                    </div>
                  </div>
                </div>
                <table className="mb-3">
                  <thead>
                    <tr className="bold">
                      <th>زمان باقی مانده تا آغاز کلاس</th>
                      <th className="center-aligned"> کنسلی توسط استاد</th>
                      <th className="center-aligned"> کنسلی توسط دانشجو</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>بیش از ۲۴ ساعت</td>
                      <td className="center-aligned">بدون جریمه</td>
                      <td className="center-aligned">بازگشت کامل</td>
                    </tr>
                    <tr>
                      <td>کمتر از ۲۴ ساعت</td>
                      <td className="center-aligned"> * </td>
                      <td className="center-aligned">
                        باتصمیم استاد ٪۱۰۰ قابل برگشت
                      </td>
                    </tr>
                    <tr>
                      <td>عدم حضور در کلاس</td>
                      <td className="center-aligned"> * *</td>
                      <td className="center-aligned">
                        باتصمیم استاد ٪۱۰۰ قابل برگشت
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>
                - درصورتی که بیشتر از ۲۴ ساعت مانده به آغاز کلاس دانشجو آن را
                کنسل کند، متامارس تمامی وجه را به حساب دانشجو برمی‌گرداند. اما
                اگر کنسلی در بازه کمتر از ۲۴ ساعت به آغاز کلاس انجام شود، یا
                دانشجو در کلاس حضور پیدا نکند، تصمیم‌‌گیری برعهده استاد خواهد
                بود. به این صورت که پس از پایان زمان کلاس گزینه بازپرداخت یا عدم
                بازپرداخت برای استاد فعال می‌شود و تصمیم استاد به صورت اتوماتیک
                اعمال خواهد شد. در این حالت سهمی به عنوان جریمه یا هزینه کنسلی
                برای متامارس در نظر گرفته نخواهد شد. با این حال ممکن است با توجه
                به نوع تراکنش و هزینه‌های انتقال وجه که توسط بانک، پی پال یا
                سایر اشخاص ثالث برای برگرداندن وجه تحمیل خواهد شد از حساب مسبب
                کنسلی کم شود.
              </p>
              <div>
                💡 در متامارس اساتید در زمان ثبت نام مبلغ سی دلار اعتبار بعنوان
                هدیه خوش آمد گویی دریافت می نمایند. شما میتوانید موجودی اعتبار
                هدیه خود را
                <span
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    if (!user?.roles?.includes(1505))
                      return messageRef.current.showError({
                        message:
                          "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                      });
                    window.navigateHandle(
                      `/${window.lcs}/dashboard/user/demy/myTutorlist`
                    );
                  }}
                  // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                  className="content__link bold-700 cursor"
                >
                  <strong> (اینجا) </strong>
                </span>
                ببینید.
              </div>
              <p>
                * اساتید محترم هم باید توجه داشته باشند که در صورت عدم حضور در
                کلاس ۱۵ دلار از اعتبار شما کسر می‌شود.
              </p>
              <p>
                ** هر کنسلی کلاس با وضعیت رزرو باعث کسر ۷ دلار و هر کنسلی کلاس
                با وضعیت تائید شده منجر به کسر ۱۰ دلار از اعتبار شما خواهد شد.
              </p>

              <p>
                - برای نمایش و دسترسی به پروفایل‌ استاد در لیست اساتید، میزان
                این اعتبار باید مثبت باشد و در صورت صفر یا منفی شدن آن، نام
                استاد به صورت خودکار از فهرست نمایش اساتید خارج خواهد شد.
              </p>

              <p>
                - مانده اعتبار هدیه متامارس پس از تدریس ۵۰۰ ساعت جلسه آموزشی
                قابل برداشت می‌باشد.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="condition">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          شرایط برگزاری مناسب کلاس های آموزشی
        </h5>
        <div className="text-red-dark">
          در کلاس‌‌های آن‌لاین، استاد و دانشجو باید از اینترنت مناسب و پایدار برای کلاس
          های خود استفاده کنند و توصیه داریم همواره از لپ‌تاپ و با هدفون در
          کلاس‌ها شرکت نمایید، قطعا روشن بودن وبکم نیز باعث می‌شود کلاس
          سرزنده‌تری را تجربه نمایید. در صورتیکه قطع و وصلی های حین کلاس به دلیل
          نوسانات اینترنت دانشجو و یا استاد باشد، متامارس هیچگونه مسئولیتی در
          عودت مبلغ آن کلاس به دانشجو یا استاد ندارد. متامارس تنها ارائه
          کننده زیرساخت برگزاری کلاس های آموزشی بوده و مسئولیتی در قبال محتوا و
          مطالبی که توسط اساتید در کلاس ها ارائه می‌شود ندارد.
        </div>
      </div>
      <div id="record">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          ادامه روند کلاس ها و ضبط جلسات
        </h5>
        <div className="text-red-dark">
          در صورت انتشار آگاهانه یا غیرآگاهانه محتوای رویدادها توسط استاد و یا
          دانشجو و یا هرشخص ثالثی، متامارس هیچ‌گونه مسئولیتی را بر عهده
          نداشته و عواقب ناشی از آن تماما متوجه استاد و دانشجو خواهد بود. از
          آنجا که اساتید همانند دانشجویان برای حفظ یا حذف پروفایل خود در این
          بستر کاملا مختار می‌باشند و بدیهی است که متامارس نمی‌تواند هیچگونه
          تعهدی در قبال فعال بودن درازمدت پروفایل استاد در سایت داشته باشد و لذا
          در صورت غیرفعال شدن پروفایل استاد، دانشجویان، می توانند روند آموزش
          خود را با سایر اساتید فعال ادامه دهند.
        </div>
      </div>
      <div id="update">
        <h5 className="text-red-dark mt-5 mb-3">
          {" "}
          <i className="bi bi-circle-fill font-14 me-1"></i>
          بروزرسانی و تغییرات در قوانین
        </h5>
        <div className="text-red-dark">
          پیرو تغییرات سریع به روزرسانی های متامارس میتواند بصورت مستمر نسبت به
          اصلاح و تعدیل مفاد این توافق نامه اقدام نموده و با ارائه نسخه‌های جدید
          و اصلاح شده آن در سایت، این مفاد را بروزرسانی نماید هر ماده ای از این
          توافق نامه که بنا به دلایلی به طور کلی یا جزیی بی اعتبار یا غیر قابل
          اجرا گردد تاثیری بر مفاد دیگر نخواهد داشت.
        </div>
      </div> */}
    </div>
  );
}
