import React, { useContext, useRef, useState } from "react";
import { CircularProgress, Rating } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import tools from "../../../../utils/tools";
// import CommentsRating from "../../../comment/commentsRating";
// import Tooltip1 from "../../../golbal/tooltip/tooltip";

export default function UsedCard(props) {
  const section = "used";
  console.log('props',props);
  const [data , setData] = useState(props.data)
  const repliesCounter = (() => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  })();
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data._id,
      section,
      token: user.token,
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    signedUser(response.data);

    !user?.saved?.[section]?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "کار در گوشه و کنار با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "کار در گوشه و کنار از لیست ذخیره حذف شد.",
        });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, smooth: true });
  };
  return (
    <div key={data._id} className="col px-3 ">
      <ToastNote messageRef={messageRef} />
      <div className="card entry p-md-2 p-1 mb-0 ">
        
        <div className=" d-flex justify-content-between ">
          
          <div className="picture-container3" onClick={e=> window.navigateHandle(`/${window.lcs}/mp/used/page/${data._id}`,data)}>
            <div className=" picture picture3">
              <img
                src={data?.moreInfo?.photos?.[1]}
                className="picture-src"
                // id="wizardPicturePreview"
                title=""
              />
            </div>
           
          </div>
          <div className="d-flex flex-column justify-content-end flex-grow-1 px-1">
            <div className="fw-bold font-16 text-end " onClick={e=> window.navigateHandle(`/${window.lcs}/mp/used/page/${data._id}`,data)}>
              <div
              // to={`/${window.lcs}/jobs/hide/page/${data._id}`}
              >
                {data?.title}
              </div>
              <div className="prof-tag my-3">
           {window.capitalize(data?.moreInfo?.status)}
        </div>
        <div dir="ltr">
        { data?.moreInfo?.isFree?<div className="prof-tag my-3">Free</div>:<div>
                  <strong>
                  {tools.showPrice(
                  data?.moreInfo?.price?.rate,
                  data?.moreInfo?.price?.currency
                  )}
                </strong>
                  </div>}
                  <div >
                    {tools.timeAgo(data?.createdAt)}
                  </div>
        </div>
            </div>
            <div>{tools.linesSpliter(data?.moreInfo?.description?.length< 60 ? data?.moreInfo?.description : (data?.moreInfo?.description?.substring(0,60)+" ..."))}</div>
            {/* <div>{data?.email}</div>
            <div>{tools.formatPhoneNumber(data?.moreInfo?.phone)}</div> */}
            <div className="row">
              <div className="col-8 med-gray-font">
                <div>
                  {`${window.capitalize(data?.moreInfo.address?.city||"")},
                    ${window.capitalize(data?.moreInfo.address?.state)},
                    ${window.capitalize(data?.moreInfo.address?.countryShort)}
                `}
                </div>
              </div>              
              <div className="col-4 card-footer  d-flex flex-wrap  justify-content-center">
                <div className="d-flex  justify-content-between">
                <div>
                    <button
                    title="دیدگاه ها"
                      id={`commentBtn${data._id}`}
                       className="icon-btn icon-stack"                                           
                    onClick={e=> {
                    const el = document.querySelector(`#commentContainer${data._id}`)                  
                   el.style.display= el.style.display==="none"?"block":"none"
                    }}
                   >
                    <i className="bi bi-chat-text icon-btn"/>
                    {repliesCounter}
                    </button>
                  </div>
                  <div>
                    <button
                      id={`saveBtn${data._id}`}
                      className="icon-btn icon-stack"
                      onClick={saveHandler}
                    >
                      {spiner ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : (
                        <i
                          className={
                            user.saved?.[section]?.includes(data._id)
                              ? "bi bi-bookmark-fill icon-stack-1x"
                              : "bi bi-bookmark icon-stack-1x"
                          }
                          title="ذخیره"
                          style={{ color: "rgba(123, 99, 90, 0.894)" }}
                        ></i>
                      )}
                    </button>
                  </div>
                

                  <div>
                    <button
                      className=" icon-stack mx-1 "
                      data-bs-toggle="modal"
                      data-bs-target={`#myModel${data._id}`}
                      id="shareBtn"
                      data-bs-placement="top"
                      title="Share!"
                    >
                      <i className="bi bi-share" title="همرسانی"></i>
                    </button>
                  </div>
                  <ShareSocialMedias
                    url={`${window.location.origin}/${window.lcs}/mp/used/${data?._id}`}
                    title={data?.title}
                    img={data?.moreInfo?.photos?.[0]}
                    _id={data?._id}
                  />

                  {user?.roles?.includes(1000) && (
                    <button
                    id={`editBtn${data._id}`}
                    className="icon-btn icon-stack"
                    onClick={e=> window.navigateHandle(`/${window.lcs}/dashboard/mp/editused/${data._id}`)}                   
                  >
                      <i
                        className={"bi bi-pencil icon-stack-1x"}
                        title="ویرایش"
                        style={{ color: "rgba(123, 99, 90, 0.894)" }}
                      ></i>
                 
                  </button>
                  
                  )}
                </div>
     
              </div>
              {/* <div id={"commentContainer"+data._id} style={{display:"none"}}> 
          <CommentsRating
          id={data._id}
          value={data}
          setData={setData}
          collection="Hide"
        />
         <div className='w-100'>
                    <button                  
                      id={`closeBtn${data._id}`}
                                                                
                    onClick={e=> {
                    const el = document.querySelector(`#commentContainer${data._id}`)                  
                   el.style.display= el.style.display==="none"?"block":"none"
                    }}
                   >
                    Close                    
                    </button>
                  </div>
        </div> */}

            </div>
          </div>
        </div>

        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
