import React, { Component } from "react";
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css'; 
import "slick-carousel/slick/slick-theme.css";

import BusSmallCard from "./components/BusSmallCard";

export default class NewBusSlider extends Component {
  
  constructor(props) {
    super(props);   
  }
   
  render() {
    const PrevButton = ({ onClick }) => (
      <button className="slick-prev" onClick={onClick}>Previous</button>
    );
    
    const NextButton = ({ onClick }) => (
      <button className="slick-next" onClick={onClick}>Next</button>
    );

    var settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      // fade:true,
      rtl:true,
      swipe:true,
      speed: 600,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      vertical: false,
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    
    return (
      <div className=" m-md-5 mx-4 my-5"  >       
        <Slider {...settings}>
        {this.props.data.map((bus, i) => (
       <div key={i} className="px-md-3 px-0"><BusSmallCard data={bus} section='business'/></div> 
      ))}
        </Slider>
      </div>
    );
  }
}