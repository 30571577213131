import React, { Component } from "react";
import Slider from "react-slick";
// https://react-slick.neostack.com/docs/api/#draggable
import 'slick-carousel/slick/slick.css';
// import "~slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import BusSmallCard from "./components/BusSmallCard";

export default class PopCatSlider extends Component {
  
  constructor(props) {
    super(props);   
  }
   
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay:true,
      // cssEase: 'linear',
      // fade:true,
      rtl:true,
      swipe:true,
      speed: 1500,
      autoplaySpeed:1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      vertical: true,
      // centerMode:true,
      // centerPadding: '25px',
      responsive: [
        // {
        //   breakpoint: 1224,
        //   settings: {
        //     slidesToShow: 3,
        //     slidesToScroll: 1,
        //     infinite: true,
        //     dots: false
        //   }
        // },
        // {
        //   breakpoint: 1000,
        //   settings: {
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //     initialSlide: 2
        //   }
        // },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    



    // console.log('popCatSlider',this.props.data);
    return (
      <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6 gx-4 gy-5 m-md-5 m-2" >

        {this.props.data.map((cat,i)=>       
       <div key={`mainSlide${i}`} className="col px-2 h-100" >
            <div className=" mx-auto ">            
              <div className="p-0 text-center titr5">{cat.mainCat}</div>
            </div>


       <Slider {...settings}>
        
        {cat?.data?.map((bus, i) => (
        <div  key={`subCatSlide${i}`}>
          <BusSmallCard section={this.props.section} data={bus}/>
          </div>
       
      ))}
        </Slider>
        </div>
        )}
      </div>
    );
  }
}