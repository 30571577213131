// import "./saved.css"
import UserDashSavedNav from '../../../components/dashNavbar/userDashSavedNav'
import { Outlet } from "react-router-dom"
import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"

import UserDashJobsNav from '../../../components/dashNavbar/userDashJobsNav'

export default function UserJobsOut() {
    
  return (
    <div className="savedItems">        
        <UserDashJobsNav/> 
        <Outlet/>
    </div>
  )
}
