import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CheckCircle,
  Clear,
  Error,
  Publish,
  WarningAmber,
} from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import AddEducation from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
import countryStates from "../../../../utils/countryStates";
import apiJobs from "../../../../utils/apiJobs";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import LangsLevel from "../../../persian/common/LangsLevel";
import AddEducation from "../../../persian/addEducation";
import FormikListErr from "../../../persian/common/alerts/formikListErr";

export default function ReadyRegister({ action }) {
  const { lang, state, countryShort, stateShort } = tools.lcsn(
    window.location.pathname
  );
  const [states, setStates] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  // const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  // const [originalLogo, setOriginalLogo] = useState(null);
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: "از فایل با فرمت عکس استفاده کنید.",
      });
    // console.log("photoHandle auth", originalPhoto);
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/jobs/ready",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  // const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      action,
      _id: "",
      lang,
      name: "",
      lcs: window.lcs,
      email: user.email,
      socialMedia: [],
      category: {
        mainCat: {},
        subCat: {},
      },
      img: "",
      title: "",
      bio: "",
      experience: "",
      skills: "",
      hobbies: "",
      educationDesc: "",
      education: [{ level: "", field: "", institute: "", description: "" }],
      emType: ["fullTime"],
      certificates: "",
      commute: "",
      phone: "",
      linkedin: "",
      showContact: false,
      languages: [{ name: "", level: "" }],
      salary: {
        period: "hourly",
        rate: 0,
        currency: "CAD",
        min: "20",
        max: "30",
        desc: "",
      },
      tags: "",
      location: {
        displayAddress: false,
        googleAddress: "",
        preciseAddress: "",
        country: "",
        countryShort: "",
        state: "",
        stateShort: "",
        city: "",
        // route: "",
        // municipality: "",
        // streetNumber: "",
        // number: "",
        // postalCode: "",
        // lat: "",
        // lng: "",
      },
      website: "",
    },
    onSubmit: async (values) => {
      console.log("onSubmit");
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiJobs.registerReady({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، اطلاعات مدرس با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });

      navigate(`/${window.lcs}/jobs/ready/filters`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("لطفاً نام را وارد کنید.")
        .max(120, "نام حداکثر باید 120 حرف باشد .")
        .min(3, "نام حداقل باید 3 حرف باشد."),
      title: Yup.string()
        .required("لطفاً عنوان شغلی را وارد کنید.")
        .max(120, "عنوان شغلی حداکثر باید 120 حرف باشد .")
        .min(3, "عنوان شغلی حداقل باید 3 حرف باشد."),
      bio: Yup.string()
        .required("لطفا درباره من را وارد نمائید")
        .max(200, "درباره من حداکثر باید 200 حرف باشد .")
        .min(20, "درباره من حداقل باید 20 حرف باشد."),
      experience: Yup.string()
        .required("لطفاً تجربه کاری خود را وارد کنید.")
        .max(2000, "تجربه کاری خود حداکثر باید 2000 حرف باشد .")
        .min(3, "تجربه کاری خود حداقل باید 3 حرف باشد."),
      commute: Yup.string()
        // .required("لطفاً شهر یا محل کار را وارد کنید.")
        .max(250, "شهر یا محل کار حداکثر باید 250 حرف باشد .")
        .min(3, "شهر یا محل کار حداقل باید 3 حرف باشد."),
      certificates: Yup.string().max(
        2000,
        "گواهینامه ها حداکثر باید 2000 حرف باشد ."
      ),

      // education: Yup.string()
      //   .required("لطفاً اطلاعات تکمیلی را وارد کنید.")
      //   .max(4000, "اطلاعات تکمیلی حداکثر باید 4000 حرف باشد .")
      //   .min(10, "اطلاعات تکمیلی حداقل باید 10 حرف باشد."),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),

      email: Yup.string()
        .required("لطفا ایمیل خود را وارد نمائید.")
        .trim()
        .lowercase()
        .email("ایمیل را به درستی وارد کنید."),
      // emType: Yup.array()
      // .required(" دست کم یک نوع از همکاری را انتخاب کنید.")
      // .test(
      //   "array-length",
      //   " دست کم یک نوع از همکاری را انتخاب کنید.",
      //   (value) => value.length > 0
      // ),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),
      languages: Yup.array().of(
        Yup.object().shape({
          level: Yup.string().required("لطفا سطح زبانی خود را انتخاب نمائید."),
          name: Yup.string().required("لطفا زبان را انتخاب نمائید."),
        })
      ),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      linkedin: Yup.string().matches(
        /^https?:\/\/(?:www\.)?linkedin\.com(?:\/.*)?$/,
        " آدرس را در دامنه www.linkedin.com انتخاب کنید."
      ),
      // website: Yup.string().matches(
      //   urlRegex,
      //   " آدرس وبسایت را به درستی وارد کنید."
      // ),
      // socialMedia: Yup.array().of(
      //   Yup.object().shape({
      //     platform: Yup.string(),

      //     url: Yup.string()
      //       .when("platform", (platform, schema) => {
      //         return platform
      //           ? schema.required("لطفاً آدرس URL را وارد نمایید.")
      //           : schema;
      //       })
      //       .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
      //         if (!value) return true; // Allow empty value
      //         // Regular expression to validate URL
      //         return urlRegex.test(value);
      //       }),
      //   })
      // ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      // distances: Yup.array().of(
      //   Yup.object().shape({
      //     distance: Yup.string().required("Please enter the distance."),
      //     cost: Yup.string().required("Please enter the cost."),
      //   })
      // ),
      // salary: Yup.object().shape({
      //   min: Yup.number().required(" حداقل حقوق را وارد کنید.").min(1),
      //   desc: Yup.string().required("لطفا حقوق درخواستی خود را وارد نمائید.").min(5,"لطفا اطلاعات بیشتری در مورد حقوق بنویسید"),
      // }),
      education: Yup.array().of(
        Yup.object().shape({
          field: Yup.string()
            .required("لطفا رشته تحصیلی را انتخاب نمائید.")
            .min(3, "رشته تحصیلی حداقل باید 3 حرف باشد."),
          institute: Yup.string()
            .required("لطفا نام مرکز آموزشی را انتخاب نمائید.")
            .min(3, "نام مرکز آموزشی حداقل باید 3 حرف باشد."),
          level: Yup.string()
            .required("لطفا مقطع آموزشی را انتخاب نمائید.")
            .min(3, "مقطع آموزشی حداقل باید 3 حرف باشد."),
        })
      ),
    }),
  });

  useEffect(() => {
    fetchDetails();
    // fetchSubjects()
  }, [id]);

  async function fetchDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiJobs.readyDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  console.log("formik", formik.values);
  console.log("formik.errors", formik.errors);
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs`,
              name: `کاریابی و نیروی کار`,
              description:
                "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/dashboard/jobs/registerready`,
              name: "ثبت رایگان آگهی آماده به کار",
              description: "ثبت آسان، اعلام آماده به کار",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "اعلام آمادگی به کار",
    description: "ثبت اطلاعات مربوط به اعلام آمادگی به کار",
    section: "Tutor register",
    url: `https://www.metamarce.com/${window.lang}/ca/qc/jobs/registerready`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "",
  };

  return (
    <div className="user  mt-3  px-3">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
        <span className="p-0 m-z ">آماده همکاری</span>
      </p>
      <form
        onSubmit={formik.handleSubmit}
        className="row userShow max-w-1000 mx-auto"
        name="jobRegForm00"
        autoComplete="true"
      >
        <div className="mb-5">
          <div className="userUpdateUpload d-flex flex-column justify-content-center">
            <img
              src={resisedPhotoAuth || formik.values?.img}
              alt={formik.values.name}
              className="userShowImg-profile"
              loading="lazy"
            />

            <label className="my-2" htmlFor="fileAuth">
              <Publish className="userUpdateIcon" />
              افزودن/تغییر عکس
            </label>
            <input
              type="file"
              id="fileAuth"
              onChange={(e) =>
                photoHandle(e, setResisedPhotoAuth, setOriginalPhoto)
              }
              style={{ display: "none" }}
            />
            <span
              className="userUpdateButton edit-btn"
              style={{ display: resisedPhotoAuth ? "block" : "none" }}
              onClick={(e) =>
                uploadFileDatabase(e, originalPhoto, "img", setResisedPhotoAuth)
              }
            >
              بارگذاری
            </span>
          </div>
        </div>

        <div className="mb-5">
          <label className="form-label titr6 mb-0 required" htmlFor="name">
            
            نام
            <Tooltip1
              message={
                <div>
                  <div> بهتر است از نام و نام خانوادگی واقعی استقاده شود.</div>
                </div>
              }
            />
          </label>
          <input
            type="text"
            autoComplete="off"
            className={`form-control py-1 box2 ${addErrClass({
              formik,
              field: "name",
            })} `}
            id="name"
            placeholder=""
            name="name"
            {...formik.getFieldProps("name")}
          />
          <FormikErrRender formik={formik} field="name" />
        </div>

        <div className="mb-3">
          <label className="form-label titr6 required">
            عنوان شغلی/زمینه کاری
          </label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "title",
            })}`}
            rows="3"
            name="title"
            // defaultValue={formik.values.title}
            {...formik.getFieldProps("title")}
            // onBlur={(e) =>
            //   formik.setValues((pre) => ({
            //     ...pre,
            //     title: e.target.value,
            //   }))
            // }
          ></textarea>
          <FormikErrRender formik={formik} field="title" />
        </div>
        <CatsSelector formik={formik} section={"jobs"} />
        <div className="  ‌mb-5">
          <p className="form-label titr6 mt-5"> نوع همکاری</p>
          <div
            className={`d-flex flex-wrap ${addErrClass({
              formik,
              field: "emType",
            })} `}
          >
            {renderData.emType.map((type, i) => (
              <div key={i} className="form-check mx-3 my-2">
                <input
                  className="form-check-input px-1"
                  type="checkbox"
                  id={"emType" + type.name}
                  checked={formik.values?.emType?.includes(type.name)}
                  value={type.name}
                  onChange={(e) =>
                    formik.setValues((pre) => {
                      const emType = [...pre.emType];
                      const valueIndex = emType.indexOf(type.name);
                      if (valueIndex !== -1) {
                        emType.splice(valueIndex, 1);
                      } else {
                        emType.push(type.name);
                      }
                      return { ...pre, emType };
                    })
                  }
                />
                <label
                  className="form-check-label "
                  htmlFor={"emType" + type.name}
                >
                  {type[lang]}
                </label>
              </div>
            ))}
          </div>
          <FormikErrRender formik={formik} field="emType" />
        </div>
        <div className="my-5">
          <label className="form-label titr6 required">درباره من</label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "bio",
            })}`}
            rows="3"
            name="bio"
            defaultValue={formik.values.bio}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                bio: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="bio" />
        </div>
        <div className="mb-5">
          <div className="form-label titr6 required ">تحصیلات</div>
          <div className=" border-0 mb-0 ">
            <AddEducation formik={formik} />

            <label className="form-label med-gray-font">
              توضیح بیشتر درباره تحصیلات
            </label>
            <textarea
              className={`form-control ${addErrClass({
                formik,
                field: "educationDesc",
              })}`}
              rows="3"
              name="educationDesc"
              defaultValue={formik.values.educationDesc}
              onBlur={(e) =>
                formik.setValues((pre) => ({
                  ...pre,
                  educationDesc: e.target.value,
                }))
              }
            ></textarea>
            <FormikErrRender formik={formik} field="educationDesc" />
          </div>
        </div>
        
        <div className="mb-5">
          <label className="form-label titr6">تجربه کاری</label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "experience",
            })}`}
            rows="3"
            name="experience"
            defaultValue={formik.values.experience}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                experience: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="experience" />
        </div>
        <div className="mb-5">
          <div className="form-label titr6 "> مهارت‌های زبانی </div>
          <LangsLevel formik={formik} />
        </div>
        <div className="mb-5">
          <label className="form-label titr6">مهارت‌های دیگر</label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "skills",
            })}`}
            rows="3"
            name="skills"
            defaultValue={formik.values.skills}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                skills: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="skilles" />
        </div>
        <div className="mb-5">
          <label className="form-label titr6">گواهینامه‌های حرفه‌ای</label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "certificates",
            })}`}
            rows="3"
            name="certificates"
            defaultValue={formik.values.certificates}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                certificates: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="certificates" />
        </div>

        {/* <div className="mb-5">
          <label className="form-label titr6">سرگرمی‌ها</label>
          <textarea
            className={`form-control ${addErrClass({
              formik,
              field: "hobbies",
            })}`}
            rows="3"
            name="hobbies"
            defaultValue={formik.values.hobbies}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                hobbies: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="hobbies" />
        </div> */}

        <div className=" mb-5 ">
          <lable className="form-label titr6">حقوق درخواستی</lable>

          <textarea
            type="text"
            autoComplete="off"
            aria-label="Amount (to the nearest dollar)"
            className={`form-control ${addErrClass({
              formik,
              field: `salary.desc`,
            })}`}
            placeholder="توضیحات بیشتر در مورد حقوق درخواستی"
            {...formik.getFieldProps(`salary.desc`)}
          />
          <FormikErrRender formik={formik} field="salary.desc" />
        </div>
        <div className=" mb-5 p-2">
          <p className="form-label titr6 mb-3 ">
             <span className="p-0 m-0 required"> محل زندگی</span>
          </p>
          <CountryStateCitySelector formik={formik} />
          <div className="mt-3">
            <label className="form-label med-gray-font">توضیح بیشتر درباره محل زندگی</label>
            <textarea
              className={`form-control ${addErrClass({
                formik,
                field: "preciseAddress",
              })}`}
              rows="3"
              name="preciseAddress"
              defaultValue={formik.values.location.preciseAddress}
              onBlur={
                (e) =>
                  formik.setFieldValue(
                    "location.preciseAddress",
                    e.target.value
                  )
                // formik.setValues((pre) => ({
                //   ...pre,
                //   preciseAddress: e.target.value,
                // }))
              }
            ></textarea>
            <FormikErrRender formik={formik} field="location.preciseAddress" />
          </div>
        </div>
        <div className="mb-5">
          <label className="form-label titr6">
          <span>
            
            </span>محل کار  </label>
          <textarea
            placeholder="اگر آمادگی کار در شهری به جز محل زندگی خود را دارید، این‌جا توضیح دهید."
            className={`form-control ${addErrClass({
              formik,
              field: "commute",
            })}`}
            rows="3"
            name="commute"
            defaultValue={formik.values.commute}
            onBlur={(e) =>
              formik.setValues((pre) => ({
                ...pre,
                commute: e.target.value,
              }))
            }
          ></textarea>
          <FormikErrRender formik={formik} field="commute" />
        </div>
       

              <label
                className="form-label mt-5 titr6 mb-0 required"
                htmlFor="emailApply"
              >
                ایمیل
                <Tooltip1
              message="از این ایمیل آدرس برای هماهنگی شغلی استفاده خواهد شد و در صورت فعالسازی اطلاعات به صورت عمومی نمایش داده خواهد شد."             
              
            />
              </label>
              <input
                dir="ltr"
                id="emailApply"
                {...formik.getFieldProps("email")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "email",
                })}`}
                type="text"
                autoComplete="off"
                placeholder={formik.values.email}
              />
              <FormikErrRender formik={formik} field="email" />
              <label
                className="form-label mt-5 titr6 mb-0 "
                htmlFor="phoneApply"
              >
                شماره تماس
                <Tooltip1
                              message="شماره تلفن جهت تماس و هماهنگی. (مثال: 6543 987 514 1+) این شماره تنها در صورت انتخاب گزینه نمایش اطلاعات تماس، در وبسایت بصورت وبسایت نمایش داده نخواهد شد."
                              position="top-end"
                            />
              </label>
              <input
                dir="ltr"
                id="phoneApply"
                {...formik.getFieldProps("phone")}
                className={`userUpdateInput  form-control box2 ${addErrClass({
                  formik,
                  field: "phone",
                })} `}
                type="text"
                autoComplete="off"
                placeholder={formik.values.phone}
              />
              <FormikErrRender formik={formik} field="phone" />
       
              <label
                className="form-label my-0 titr6  "
                htmlFor="linkedinInp"
              >
                لینکدین
                <Tooltip1
              message=" آدرس حساب لینکدین شما به صورت عمومی نمایش داده خواهد شد."             
              
            />
              </label>
              <input
                id="linkedinInp"
                {...formik.getFieldProps("linkedin")}
                className="userUpdateInput  form-control box2"
                type="text"
                autoComplete="off"
                placeholder={formik.values.linkedin}
              />
           <FormikErrRender formik={formik} field="linkedin" />
           <div className="userShow form-check form-switch mt-5 py-3 px-1 mx-0 mx-md-5 bg-beigeGray">
              <input
                className="form-check-input form-check-input-filter1  m-0 p-0 cursor"
                type="checkbox"
                role="switch"
                id="showContactSwitch"
                checked={formik.values?.showContact}
                onChange={(e) =>
                  formik.setValues((prev) => ({
                    ...prev,
                    showContact: !formik.values.showContact,
                  }))
                }
              />
              <label
                className="form-check-label px-2 mt-0"
                htmlFor="showContactSwitch"
              >
                <div>  نمایش اطلاعات تماس به صورت عمومی.</div>
                <div className="small-gray-font font-10 my-2">
                 (اطلاعات تماس به صورت پیش فرض بصورت عمومی نمایش داده نخواهد شد.)
                </div>
              </label>
            </div>

        <div className="mb-5">
        
          <p id="tagsInput" className="form-titr ">
           
            برچسب‌ها (با "+" جدا شوند )
            <Tooltip1
              message={
                <div>
                  <div>
                    برچسب‌ها - تگ - را با '+' از یکدیگر جداکنید.
                  </div>
                  <div>حداکثر ۱۰ برچسب.</div>
                </div>
              }
            />
            <input
              type="text"
              autoComplete="off"
              name="other"
              placeholder={
                formik?.values?.tags ||
                `برای مثال : بازسازی+طراحی داخلی+ طراحی فضای سبز`
              }
              className="form-input1 mt-3"
              {...formik.getFieldProps("tags")}
            />
          </p>
        </div>
        <FormikListErr formik={formik}/>

        <SubmitBtn formik={formik} text="" />
      </form>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    const errorsKeys = Object.keys(formik.errors)
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: errorsKeys?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          errorsKeys?.length === 0 && <CheckCircle />
        )}
        <span>
          {errorsKeys?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    console.log(states);
    // const state = countryStates.find(
    //   (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    // ).states[0];
    setStates(states);

    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.country = regionNames.of(countryShort).toLowerCase();
      location.countryShort = countryShort.toLowerCase();
      location.state = "";
      location.stateShort = "";
      location.cities = [];
      return { ...prev, location };
    });
  }
  function setCitiesHandle(value) {
    formik.setValues((prev) => {
      const location = { ...prev.location };
      location.cities = value;
      return { ...prev, location };
    });
  }
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    email: data.email,
    name: data.name,
    category: data.category,
    img: data.moreInfo.img,
    title: data.moreInfo.title,
    bio: data.moreInfo.bio,
    experience: data.moreInfo.experience,
    skills: data.moreInfo.skills,
    hobbies: data.moreInfo.hobbies,
    educationDesc: data.moreInfo.educationDesc,
    education: data.moreInfo.education,
    emType: data.moreInfo.emType,
    certificates: data.moreInfo.certificates,
    commute: data.moreInfo.commute,
    phone: data.moreInfo.phone,
    linkedin: data.moreInfo.linkedin,
    showContact: data.moreInfo.showContact,
    languages: data.moreInfo.languages,
    salary: data.moreInfo.salary,
    tags: data.moreInfo.tags.join("+"),

    status: data.moreInfo.status,
    // title: data.moreInfo.title,
    website: data.moreInfo.website,

    location: {
      ...data.moreInfo.address,
      lat: data.moreInfo.address?.coordinates?.lat,
      lng: data.moreInfo.address?.coordinates?.lng,
    },
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiJobs.editReady({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " اطلاعات با موفقیت ویرایش شد.",
  });

  navigate(`/${window.lcs}/jobs/ready/page/${response.data._id}`, {
    state: response.data,
  });
}

function AddAvailability({ formik }) {
  return (
    <div className="table" id="maintable3">
      {formik.values?.availability.map((availabel, i) => (
        <div key={i} className="mb-2">
          <div id={i} className="input-group mb-1 addSocialMedia">
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={availabel + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`availability[${i}].availableDay`)}
            >
              <option>Select :</option>
              {demyData.availableDay?.map((stl, i) => (
                <option key={i} value={stl.name}>
                  {stl[window.lang]}
                </option>
              ))}
            </select>
            <select
              className=" login-btn  selectpicker shadow-none w-30"
              aria-label="Default select example"
              name={availabel + i}
              style={{
                borderTopRightRadius: "20px",
                bordeBbottomRightRadius: "20px",
                fontSize: "small",
              }}
              {...formik.getFieldProps(`availability[${i}].availableTime`)}
            >
              <option>Select :</option>
              {demyData.availableTime?.map((enrolrT, i) => (
                <option key={i} value={enrolrT.name}>
                  {enrolrT[window.lang]}
                </option>
              ))}
            </select>
          </div>

          {i > 0 && (
            <button
              type="button"
              className="deleteContact btn btn login-trigger3 btn-xs mx-2"
              title="پاک کردن"
              onClick={() => removeHandle(i)}
            >
              <i
                className="bi bi-x-circle-fill p-0 "
                style={{ fontSize: "1.5em" }}
              ></i>
            </button>
          )}
          <FormikErrRender
            formik={formik}
            field={`availability.${i}.availableDay`}
          />
          <FormikErrRender
            formik={formik}
            field={`availability.${i}.availableTime`}
          />
        </div>
      ))}

      {!formik.errors.enrolment && (
        <div
        // className='border-top border-secondary'
        >
          <div
            className="btn btn-xs shadow-none login-trigger2  "
            title="افزودن شبکه اجتماعی"
          >
            <i
              className="bi bi-plus-circle-fill "
              style={{ fontSize: "1.5em" }}
              onClick={addTuitionHandle}
            ></i>
          </div>
        </div>
      )}
    </div>
  );

  function addTuitionHandle() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.availability.length;
      newF.availability.push(newF.availability[index - 1]);
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.availability.splice(index, 1);
      return newFormik;
    });
  }
}

