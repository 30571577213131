import React from "react";
import { Link } from "react-router-dom";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function NotFound() {
  const structuredData=[{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "404",
    "description":"page not found !",

    "url":"https://www.metamarce.com/404",    

  }]

  const metaTagsData={
    title:'صفحه مورد نظر یافت نشد ',
    description:"صفحه ای که دنبالش بودی پیدا نشد .",
    section:"Thank you",
    url:`https://www.metamarce.com/404`,
    img:"https://www.metamarce.com/img/404-error-with-a-cute-animal-animate-2.svg",
    type:'webpage',
  }
  return (    
    <div>
      <MetaDecorator data={{...metaTagsData,structuredData}}/>

      <div className="container-404 text-center d-flex flex-column align-items-center ">
        <img
          className=" img-404 p-0 m-0"
          src="/img/404-error-with-a-cute-animal-animate-2.svg"
          alt="404"
        />
        <div className="mb-3 ">
          <span> صفحه‌ای که دنبالش بودی پیدا نشد</span>
          <span>
            <i className="bi bi-emoji-frown"></i>
          </span>
          <Link
            className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
            to={`/`}
          >
            بازگشت به خانه
          </Link>
        </div>
      </div>
    </div>
  );
}
