// import "./saved.css"
import UserDashSavedNav from '../../../components/dashNavbar/userDashSavedNav'
import { Outlet } from "react-router-dom"
import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"
import { useContext } from "react"
import { CartContext } from "../../../../../contexts/cartContext"
import UserDashBusNav from '../../../components/dashNavbar/userDashBusNav'

export default function UserBusOut() {
    
  return (
    <div className="savedItems">        
        <UserDashBusNav/>         
        <Outlet/>
    </div>
  )
}
