import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import api from "../../../../utils/apiMP";
// import Cluster from "../../../googleMap/googleCluster";
import renderData from "../../../../utils/renderData";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import PlansX4 from "../../../golbal/sliders/plansX4";
import BusSmallCardSlider from "../../../golbal/sliders/busCardSliderS1";
import MainSidebar from "../../media/components/mainSidebar";
import tools from "../../../../utils/tools";
import MetaDecorator from "../../../../utils/metaDecorator";
// import CenterCard from "../components/hideCard";
import { CircularProgress } from "@mui/material";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import countryStates from "../../../../utils/countryStates";
import NewPosts2x2 from "../../../golbal/sliders/components/newPosts2x2";
// import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import UsedCard from "../components/usedCard";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
// import demyData from "../../../../utils/demyData";

export default function UsedFilters() {
  const navigate = useNavigate();
  const section = "used";
  const page = "usedFilters";
  const {
    lang,
    country,
    countryShort,
    state,
    stateShort,
    stateInfo,
    countryInfo,
  } = tools.lcsn(window.location.pathname);
  // console.log("countryInfo", countryInfo);
  const { user } = useContext(CartContext);
  const [pubData] = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState({
    thumbnail: true,
    cluster: false,
  });
  const [states, setStates] = useState([]);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [loading, setLoading] = useState({ spinner: false });
  const [data, setData] = useState([]);
  console.log('data',data);
  const initialFilters = {
    lang,
    country,
    countryShort,
    states: [],
    state: "",
    stateShort: "",
    cities: [],   
    mainCatId: "",
    subCat: [],
    subCatId: [], 
    searchKey: "",
    isFree: "",
    status: [],
    img: "",
    price:{},
    condition: [],
    delivery: [],
    pageNumber: 1,
    itemsToShow: 12,
    sort: "default",
  };
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(storedFilters || initialFilters);
  const [backFilters, setBackFilters] = useState({ ...filters });
  
  const effectRan = useRef(true);
  useEffect(() => {
    if (effectRan.current) return;
    fetchData();
    setBackFilters({ ...filters });
    storeFilters({ section, filters });
  }, [filters, window.lcs]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    // const keys = Array.from(searchParams.keys())
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }
    countrySet(countryShort)
    setFilters((pre) => ({
      ...pre,
      country,      
      mainCat: params?.mainCat ?? pre.mainCat,
      mainCatId: params?.mainCatId ?? pre.mainCatId,
      subCat: params?.subCat ? [params?.subCat] : pre.subCat,
      subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
      cities: params?.cities ? [params?.cities] : pre.cities,
      searchKey: params?.searchKey ?? pre.searchKey,     
    }));
    effectRan.current = false;
  }, [searchParams]);

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    const searchString = searchParams?.toString();

    navigate(`?${searchString}`);
  }

  async function fetchData() {
    setLoading((pre) => ({
      ...pre,
      spinner: true,
    }));
    const response = await api.filtersUsed({ filters });
    setLoading((pre) => ({
      ...pre,
      spinner: false,
    }));
    if (response?.error) return alert(response?.error);
    setResultsTotal(response.data.resultsTotal);
    setData(response.data.data);
  }

  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;

    filters.searchKey = value;
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: value,
        pageNumber: 1,
      }));
    }
  }
  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
                queryRemover("mainCatId");
              }
              // if (key === "country") {
              //   newFilters.countryShort = "";
                
              // }
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }
    setFilters(update());
  }

  const selectedFiltersRender = (() => {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (  key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId") {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            // key === "languages"
            //   ? renderData[key]?.find((option1) => option1.name === option)
            //   : renderData[key]?.find((option1) => option1.name === option);
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                {/* <span>{option} </span>        */}
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&       
        key !== "country" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "state" &&
        key !== "section" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&  
        key !== "mainCatId" &&      
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  })();

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
    // effectRan.current = false;
  }
  async function viewMorePosts(e) {
    e.preventDefault();
    setFilters((pre) => ({
      ...pre,
      pageNumber: pre.pageNumber + 1,
    }));
  }

  const { translations,states:states0 } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` آکهی نیازمندی‌های ${
    translations?.[window.lang] }`;
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:
        "آگهی نیازمندی " +
        translations?.[window.lang] ,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
      
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/mp/used/filters`,
              name: `نیازمندیهای ${translations?.[window.lang]}`,
              description: `فیلترهای پیشرفته و پر سرعت جهت نمایش دقیق سریع آگهی های نیازمندی ${
                translations?.[window.lang]} `,
            },
          },
        ],
      },
      about: {
        "@type": "Place",
        name: state?.toUpperCase(),
        address: {
          "@type": "PostalAddress",
          // "addressLocality": "City",
          addressRegion: state?.toUpperCase(),
          // "postalCode": "Postal Code",
          addressCountry: country?.toUpperCase(),
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: stateInfo?.lat, // Replace with the actual latitude
          longitude: stateInfo?.lng, // Replace with the actual longitude
        },
      },
    },
  ];
  const metaTagsData = {
    title,
    description: `نیازمندیها و خرید و فروش در ${translations?.[window.lang] } `,
    section: "نیازمندیها",
    url: `https://www.metamarce.com/${window.lcs}/mp/used/filters`,
    canonical: `https://www.metamarce.com/${window.lang}/${countryShort}/${states0?.[0]?.state_code?.toLowerCase()}/mp/used/filters`,
    img: "https://www.metamarce.com/img/illustration/man-with-join-us-sign-for-open-recruitment.svg",
    type: "website",
  };
  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      {/* <!-- *********************************** main ******************************************************************** --> */}

      <section className="mx-auto ads-all py-0">
        <div className="m-3 ms-5">
          <nav style={{ bsBreadcrumbDivider: ">", ariaLabel: "breadcrumb" }}>
            <ol className="breadcrumb">
              <li  className="breadcrumb-item">
              <Link to={`/${window.lcs}`} >
                خانه
              </Link></li>
              <li  className="breadcrumb-item">
              <Link to={`/${window.lcs}/mp`} >
                نیازمندیها
              </Link></li>
              <li className="breadcrumb-item">
              <strong className="breadcrumb-item active">آگهی‌های نیازمندی</strong>
              </li>
            </ol>
          </nav>
        </div>


        <div className="row blog px-md-4 p-0 py-1">
          {/* <!-- **************Right side ************** --> */}
          <div className="col-lg-9 entries  ">
            {/* <!-- ************** MAP ************** --> */}
            <a id="top"> </a>

            {/* <!-- Pagination *******************************--> */}

            <div className="mx-1 p-0 ">
              
                <div>

 {/* <!-- **************Filters  ************** --> */}

            <div className=" d-flex flex-wrap x-3 justify-content-around my-2 border-0 ">
      <span title="نمایش ذخیره ها">
                <i className={`bi ${filters.saved?'bi-bookmark-fill':"bi-bookmark"} icon-stack-1x cursor`} onClick={e=>{
                  if (!window.logined(9999)) return;
                  if(user?.saved?.[section]?.length<= 0)return alert("شما تاکنون آیتم ذخیره شده ای ندارین.")
                  setFilters(pre=>({...initialFilters,saved:!pre.saved,section}))}}></i>
                </span>
        {resultsTotal !== 0 && (
          <span className="box4 py-1 px-2 mx-md-2 mx-0">
            {resultsTotal} یافته
          </span>
        )}
        <div className="blog-search ">
          <BlogSearch searchHandle={searchHandle} />
        </div>
        <button
          className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-filter me-1"> فیلترها</i>
        </button>
        
        <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
          <div
            className="d-flex flex-wrap mt-3"
          >
            {/* <!-- Button filter 1 *******************************--> */}
            <div className="dropdown ">
              <button
                type="button"
                className="btn btn-primary button-filter dropdown-toggle smaller"
                data-bs-toggle="dropdown"
                // id="#filter5"
                aria-expanded="false"
                // aria-controls="filter5"
              >
                <span>
                  <i className="bi bi-arrow-down-up me-1"></i>
                </span>
                مرتب کن
                <span className="ps-3">
                  <i className="bi bi-chevron-down"></i>
                </span>
              </button>

              {/* <!-- Modal --> */}

              <div className="dropdown-menu checkbox-form">
                <div className=" ">
                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={filters.sort === "likeB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "likeB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexRadioDefault1"
                    >
                      پسندیده
                    </label>
                  </div>
                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={filters.sort === "dateB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "dateB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      تازه‌ترین
                    </label>
                  </div>

                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault4"
                      checked={filters.sort === "viewsB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "viewsB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault4"
                    >
                      بازدید
                    </label>
                  </div>
                </div>
              </div>
            </div>

                 {/* <!-- Button country 2 *******************************--> */}
                 {/* <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    کشور
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

           

                  <div className="dropdown-menu checkbox-form">
                    <DropdownSearch updateParent={countrySet} />
                  </div>
                </div> */}

                {/* <!-- Button State 2.5 *******************************--> */}
                {filters.country?.length > 0 && (
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    استان
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu px-0 checkbox-form">
                    <div
                      className="reset-btn w-100"
                      onClick={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          states: [],
                          state: "",
                          stateShort: "",
                          pageNumber: 1,
                        }));
                      }}
                    >
                      پاک کردن
                    </div>
                    {states?.map((state, i) => (
                      <div
                        key={"states" + i}
                        className="form-check d-flex justify-content-between flex-row-reverse px-3"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          // value={state.name?.toLowerCase()}
                          onChange={(e) => {
                            const states = filters?.states
                              ? [...filters.states]
                              : [];
                            const state1 = { stateShort: "", state: "" };
                            const value = state.state;
                            console.log("value01", value);
                            console.log("states02", states);
                            const index = states.indexOf(value);
                            if (index !== -1) {
                              states.splice(index, 1);
                              const contry = countryStates.find(
                                (count) =>
                                  count.countryShort ===
                                  filters.countryShort.toUpperCase()
                              );
                              console.log("contry03", contry);
                              // const stateShort = contry.states.find(
                              //   (state) => {
                              //     console.log('state.state === states[0]',state.state , states[0]);
                              //     return state.state === states[0]}
                              // ).stateShort;                             
                              const stateShort= countryStates.find(count=>count.countryShort === filters.countryShort)
                              if (states.length === 1) {
                                state1.stateShort = stateShort;
                                state1.state = states[0];
                              } else {
                                state1.stateShort = "";
                                state1.state = "";
                              }
                            } else {
                              states.push(value);
                              if (states.length === 1) {
                                state1.stateShort = state.stateShort;
                                state1.state = value;
                              } else {
                                state1.stateShort = "";
                                state1.state = "";
                              }
                            }
                            setFilters((pre) => ({
                              ...pre,
                              states,
                              state: state1.state,
                              stateShort: state1.stateShort,
                            }));
                          }}
                          checked={filters.states?.includes(state.state)}
                          id={`statesCheckBox${i}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`statesCheckBox${i}`}
                        >
                          {state?.state}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* <!-- Button City 3 *******************************--> */}
              {filters.states.length === 1 && filters?.state?.length > 0 && (
                <div className="dropdown">
                  <button
                    type="button"
                    className="btn button-filter dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="bi bi-geo-alt me-1"></i>
                    </span>
                    شهر/ محله
                    <span className="ps-3">
                      <i className="bi bi-chevron-down"></i>
                    </span>
                  </button>

                  {/* <!-- Modal --> */}

                  <div className="dropdown-menu checkbox-form">
                    <SearchMultiSelect
                      updateParent={setCitiesHandle}
                      data={{
                        countryShort: filters.countryShort,
                        stateShort: filters.stateShort,
                        cities: filters.cities,
                      }}
                    />
                  </div>
                </div>
              )}

    
            {/* <!-- Button MainCat 3 *******************************--> */}
            <FiltersCatsSelector
            section={'used'}
            filters={filters}
            setFilters={setFilters}
          />

            {/* <!-- Button filter 5 *******************************--> */}
<div className="dropdown">
            <button
                className="btn button-filter"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBothOptionsFilters"
                aria-controls="offcanvasWithBothOptionsFilters"
              >
                <span>
                  <i className="bi bi-filter me-1"></i>
                </span>
                فیلتر بیشتر
                <span className="ps-3">
                  <i className="bi bi-chevron-down"></i>
                </span>
              </button>

              <div
                className="offcanvas offcanvas-end offcanvas-countryLanguage "
                data-bs-scroll="true"
                tabIndex="-1"
                id="offcanvasWithBothOptionsFilters"
                aria-labelledby="offcanvasWithBothOptionsLabelFilters"
              >
                <div className="offcanvas-header offcanvas-countryLanguageTitle ">
                  <h5
                    className="offcanvas-title "
                    id="offcanvasWithBothOptionsLabelFilters"
                  >
                    فیلترهای بیشتر
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="offcanvas-footer  text-center px-5 py-4">
                  <button
                    className="btn  checkout-btn  text-center w-100 mb-2 py-2"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={(e) =>{ 
                      console.log('initialFilters',initialFilters);
                      console.log('backFilters.img.length',backFilters.img.length);
                      
                      setFilters(initialFilters)}}>
                    پاک کردن فیلترها
                  </button>
                  <button
                    className="btn  view-cart text-center w-100 py-2"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={(e) => {
                      // effectRan.current = false;
                      backFilters.pageNumber = 1;
                      setFilters(backFilters);
                    }}
                  >
                    نمایش موارد
                  </button>
                </div>

                <div className="offcanvas-body px-0 pb-3 pt-0">
                  <div
                    className="accordion w-100 p-0 m-0"
                    id="accordionPanelsStayOpenBusinessFilters"
                  >
                    {/* <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseZero"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseZero"
                        >
                          <p className="form-titr2">شیف های کاری </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseZero"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingZero"
                      >
                        <div className="accordion-body form-font px-5  row row-cols-2">
                          {renderData.workShifts.map((ws, i) => (
                            <div key={i} className="form-check m-0 py-0 px-1">
                              <input
                                className="form-check-input form-check-input-filter1 m-0 p-0"
                                type="checkbox"
                                // role="switch"
                                name="workShifts"
                                value={ws.name}
                                id={`workShifts,${i}`}
                                checked={backFilters?.workShifts?.includes(
                                  ws.name
                                )}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const workShifts = backFilters.workShifts
                                    ? [...backFilters.workShifts]
                                    : [];
                                  const index = workShifts.indexOf(value);
                                  if (index !== -1) {
                                    workShifts.splice(index, 1);
                                  } else {
                                    workShifts.push(ws.name);
                                  }
                                  setBackFilters((prev) => ({
                                    ...prev,
                                    workShifts,
                                  }));
                                }}
                              />
                              <label
                                className="form-check-label px-2"
                                htmlFor={`workShifts,${i}`}
                              >
                                {ws?.[window.lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div> */}

                  
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-headingTwo"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseTwo"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseTwo"
                        >
                          <p className="form-titr2">
                            {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                            وضعیت سلامت
                          </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-headingTwo"
                      >
                        <div className="accordion-body form-font px-5">
                          {renderData.condition.map((em, i) => (
                            <div key={i} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={em.name}
                                checked={backFilters.condition?.includes(em.name)}
                                id={`checkBoxEmType${i}`}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const condition = backFilters.condition
                                    ? [...backFilters.condition]
                                    : []; // Create a shallow copy of the array
                                  console.log("condition", condition);
                                  const index =
                                    backFilters.condition?.indexOf(value);
                                  if (index !== -1) {
                                    condition.splice(index, 1);
                                  } else {
                                    // Add the value if it's not selected
                                    condition.push(value);
                                  }
                                  // backFilters.condition=condition
                                  setBackFilters((pre) => ({ ...pre, condition }));
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`checkBoxEmType${i}`}
                              >
                                {em[lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-delivery"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapseDelivery"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapseDelivery"
                        >
                          <p className="form-titr2">
                            {/* <span>
                              <i className="bi bi-circle-fill "></i>
                            </span> */}
                            نحوه تحویل
                          </p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapseDelivery"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-delivery"
                      >
                        <div className="accordion-body form-font px-5">
                          {renderData.delivery.map((em, i) => (
                            <div key={i} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={em.name}
                                checked={backFilters.delivery?.includes(em.name)}
                                id={`checkBoxDelivery${i}`}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const delivery = backFilters.delivery
                                    ? [...backFilters.delivery]
                                    : []; // Create a shallow copy of the array
                                  console.log("delivery", delivery);
                                  const index =
                                    backFilters.delivery?.indexOf(value);
                                  if (index !== -1) {
                                    delivery.splice(index, 1);
                                  } else {
                                    // Add the value if it's not selected
                                    delivery.push(value);
                                  }
                                  // backFilters.delivery=delivery
                                  setBackFilters((pre) => ({ ...pre, delivery }));
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`checkBoxDelivery${i}`}
                              >
                                {em[lang]}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  

                   <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-heading-six"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapse-six"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapse-six"
                        >
                          <p className="form-titr2"> امکانات دیگر</p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapse-six"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-heading-six"
                      >
                        <div className="accordion-body form-font px-5">
                          <div className="form-check form-switch m-0 py-0 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="img"
                              // value={'tr.name'}
                              id={`imgBackF`}
                              checked={backFilters?.img?.length>0}
                              // checked={false}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  img: prev?.img?.length>0 ? "" : "دارای عکس",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`imgBackF`}
                            >
                              دارای عکس
                            </label>
                          </div>
                          <div className="form-check form-switch m-0 py-2 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="isFree"
                              // value={'tr.name'}
                              id={`isfreebackF`}
                              checked={backFilters.isFree.length>0}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  isFree: prev?.isFree?.length
                                    ? ""
                                    : "رایگان",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`isfreebackF`}
                            >
                              رایگان
                            </label>
                          </div>
                          {/* <div className="form-check form-switch m-0 py-2 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="freeSession"
                              // value={'tr.name'}
                              id={`freeSessionBackF`}
                              checked={backFilters?.freeSession}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  freeSession: prev.freeSession?.length
                                    ? ""
                                    : "جلسه آزمایشی",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`freeSessionBackF`}
                            >
                              جلسه آزمایشی
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </div>
                   {/* <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpenBusinessFilters-heading-status"
                      >
                        <button
                          className="accordion-button collapsed accordion-button-business-filter"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpenBusinessFilters-collapse-status"
                          aria-expanded="false"
                          aria-controls="panelsStayOpenBusinessFilters-collapse-status"
                        >
                          <p className="form-titr2"> وضعیت آگهی</p>
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpenBusinessFilters-collapse-status"
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpenBusinessFilters-heading-status"
                      >
                        <div className="accordion-body form-font px-5">
                          <div className="form-check form-switch m-0 py-0 px-1">
                            <input
                              className="form-check-input form-check-input-filter1 m-0 p-0"
                              type="checkbox"
                              role="switch"
                              name="img"
                              // value={'tr.name'}
                              id={`imgBackF`}
                              checked={backFilters?.img?.length>0}
                              // checked={false}
                              onChange={(e) => {
                                setBackFilters((prev) => ({
                                  ...prev,
                                  img: prev?.img?.length>0 ? "" : "دارای عکس",
                                }));
                              }}
                            />
                            <label
                              className="form-check-label px-2"
                              htmlFor={`imgBackF`}
                            >
                              دارای عکس
                            </label>
                          </div>
                        
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                {/* ***************** */}
              </div>
              </div>
       
          </div>
        </div>

        {/* <!-More filters- Button filter 5 here*******************************--> */}
      </div>

            <div className=" d-flex flex-wrap my-2 show-filters ">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7"
              >
                <span>{window.capitalize(filters?.country)}</span>
                <span className="ms-3 me-2">
                  <i className="bi bi-arrow-repeat"></i>
                </span>
              </div>
              {selectedFiltersRender}
              {selectedFiltersRender.length > 0 && (
                <div
                  className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
                  onClick={(e) => setFilters(initialFilters)}
                >
                  <span>پاک کردن فیلترها</span>
                </div>
              )}
            </div>





                  {loading.spinner ? (
                    <img src="/img/searching-error.svg" alt="Searching..." />
                  ) : (
                    data?.length <= 0 && (
                      <div className="row m-5 px-md-5 px-0">
                        <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
                          داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
                          <div
                            className="btn-search shadow-none m-2 d-flex  align-items-center filter-del-btn img-banner-text-7"
                            onClick={(e) =>
                              document.querySelector("#lcsChangeBtn").click()
                            }
                          >
                            دیگر استانها یا کشورها
                          </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                          <img
                            src="/img/cart-is-empty.svg"
                            alt="cart-is-empty"
                            loading="lazy"
                          />
                        </div>
                        <div className="mt-5 " dir="rtl">
                    </div>
                      </div>
                    )
                  )}
                  {/* <!-- ************** Cards************** --> */}
                  <div
                    className="row row-cols-1 
                     g-4"
                  >
                    {data?.map((job, i) => (
                      <UsedCard data={job} />
                    ))}
                  </div>
                  <div className="mt-2 d-flex justify-content-center pe-5">
                    <button
                      className="btn px-5 btn-sm shadow-none login-btn p-1 "
                      onClick={viewMorePosts}
                      disabled={
                        resultsTotal <= data?.length || loading.spinner
                      }
                    >
                      {loading.spinner ? (
                        <CircularProgress color="inherit" size="20px" />
                      ) : resultsTotal <= data?.length ? (
                        "پایان یافته ها"
                      ) : (
                        "موارد بیشتر"
                      )}
                    </button>
                  </div>
                </div>
              
            </div>
          </div>
          <div
            className="col-lg-3  sidebar-all align-items-center text-center  p-md-4 p-1"
            dir="rtl"
          >
            {/* <!-- ********left side *************************************************************** --> */}

            <MainSidebar section={section} />
          </div>
        </div>
        {/* <!-- *********** end of listing ******* --> */}
      </section>

      <section className="mx-md-5 pt-0 mx-0 p-0">
        <div className="divider2 mb-0">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">معرفی ویژه</span>
        </div>
        <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>
        <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page={page} />
      </section>

      {/* <!-- تازه‌های بلاگ  --> */}

      <div className=" mx-5 ">
        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
          </svg>
          <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
        </div>
        <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ads-all mx-auto ">
          {pubData?.newPosts?.length > 0 && (
            <NewPosts2x2 newPosts={pubData.newPosts} user={user} />
          )}
        </div>
      </div>

      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>

      {/* <!-- **********end of back to top************************* --> */}
    </div>
  );
  function countrySet(countryShort) {
    const coun = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    )
    setStates(coun.states);
    setFilters((prev) => ({
      ...prev,      
      // country: coun.country,
      countryShort: countryShort.toLowerCase(),
      state: "",
      stateShort: "",
      states: [],
      cities: [],
    }));
  }

}
