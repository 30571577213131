import React from 'react'
import { Link } from 'react-router-dom'
import MyIcons from '../../icons/myIcons';
// import { Rating } from '@mui/material';

export default function NewBusSmallCard({data}) {  
  return (
    <div className="card bg-dark text-white card1 m-1 my-5">
      <Link
      onClick={e=>window.scroll(0,0)}
        className="image-container "
        to={`/${window.lcs}/business/page/${data._id}`}
        state={data}
      >
        <img
          src={data.img}
          className="card-img card-img1 w-100 "
          alt={data.title?.[window.lang]}
          loading="lazy"
        />
        <div className="card-img-overlay ">
          <div>
            <h5 className="card-title card-titel1 card-titel3 mt-auto">
              {data.title?.[window.lang]} <MyIcons icon={data.status}/>
            </h5>
            
            <div
              className="group-list-item small-gray-font my-1"
              style={{ color: "beige" }}
            >
              <i className="bi bi bi-geo-alt mx-2"></i>
              {`${window.capitalize(data.address?.city)},
                    ${window.capitalize(data.address?.state)},
                    ${window.capitalize(data.address?.countryShort)}
                `}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
