import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import apiDemy from "../../../utils/apiDemy";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
import SocialMedias from "../business/components/socialMedias";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
import GoogleMap from "../../googleMap/googleMap";
import demyData from "../../../utils/demyData";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import apiJobs from "../../../utils/apiJobs";

export default function ReadyPage(props) {
  // console.log("props", props);
  const messageRef = useRef(null);
  // const navigate = useNavigate();
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({ spinner: false, save: false });
  const [request, setRequest] = useState({ email: "", message: "" });
  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data?._id,
      section: "ready",
      token: user.token,
      title: data.name,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    signedUser(response.data);

    !user?.saved?.ready?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "  با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "  از لیست ذخیره حذف شد.",
        });
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      if (id?.length !== 24)
        return messageRef.current.showError({
          message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
        });
      const response = await apiJobs.readyDetails(id);
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` ${data?.moreInfo?.title} کارجو ${
    translations?.[window.lang] + " شهر " + data?.moreInfo?.address?.city
  }`;
  const description =
    data?.moreInfo?.description?.substring(0, 100) +
    " | " +
    data?.moreInfo?.address?.municipality +
    " | " +
    data?.moreInfo?.address?.city;

  const structuredData = [
    ...renderData.structuredData,

    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.name,
      description: data?.moreInfo?.title,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs/ready/filters`,
              name: `لسیت کارجویان`,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: data?.name,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "آماده به کار",
    url: `https://www.metamarce.com/${window.lcs}/jobs/ready/page/${data?._id}`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "webpage",
  };
  function openGoogleMaps(destination) {
    if (!destination)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  }

  return (
    <div className="max-w-m mx-auto tutorPage text-gray-green">
      {loading.spinner && <ColoredSpinner />}
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* ****** cover ******** */}

      <div className=" py-0">
        <div className=" container2 p-0 ">
          <nav className="p-md-3 p-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/${window.lcs}`} className="breadcrumb-item">
                  خانه
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/${window.lcs}/jobs`} className="breadcrumb-item">
                  کاریابی
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`/${window.lcs}/jobs/ready/filters`}
                  className="breadcrumb-item"
                >
                 آماده به‌کار
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="breadcrumb-item active">
                  <strong> {data?.name}</strong>
                </span>
              </li>
            </ol>
          </nav>
          <div className="ms-5"></div>
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-2 d-flex justify-content-lg-start justify-content-center">
              <div className="picture-container">
                <div className=" picture  image-container image-container-media text-center m-0 ">
                  <img
                    // src="/img/Images/icons8-add-image-64.png"
                    src={
                      data?.moreInfo?.img ||
                      "/img/Metademy/Green-Red-Stickers-5.png"
                    }
                    className="picture-src"
                    id={"tutor" + data?._id}
                    title={data?.name}
                  />
                  {/* <input type="file" id="wizard-picture" className="" /> */}
                </div>
              </div>
            </div>

            <div className="col-lg-6 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <h1 className="uni-name-cover  m-2 ">
                {data?.name}
                {/* {(user?.roles?.includes(1000) || isTutor) &&
                !data?.moreInfo?.isActive && ( */}
                {/* <span
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lcs}/dashboard/demy/edittutor/${data?._id}`
                      )
                    }
                    className="btn btn-primary save-cover-btn mx-2 font-16  border-0 cursor"
                  >
                    (غیرفعال)
                  </span> */}

                {/* <TutorGrades data={data} /> */}
              </h1>
              {/* <ProfTutor data={data} /> */}

              <div className="fw-bold">{data?.moreInfo?.title}</div>
              {/* <ShowAddress address={data?.moreInfo?.address} /> */}
              {/* <div className="d-flex ">
              {data?.moreInfo?.stList?.length > 0 && (
                <div className="small-gray-font me-3">
                  <i className="bi bi-pencil me-1"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.stList?.length,
                    lang: window.lang,
                  })}{" "}
                  دانشجوی فعال
                </div>
              )}
              {data?.moreInfo?.transactions?.sessionCounter > 0 && (
                <div className="small-gray-font">
                  <i className="bi bi-book me-1"></i>
                  {tools.dynamicNumber({
                    number: data?.moreInfo?.transactions?.sessionCounter,
                    lang: window.lang,
                  })}{" "}
                  کلاس برگزار شده
                </div>
              )}
            </div> */}
            </div>
            <div className="col-lg-4 order-1 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <div className=" d-flex ">
                <Link
                  className="btn btn-primary save-cover-btn mx-2 font-12  border-0"
                  to={`/${window.lcs}/dashboard/jobs/editReady/${data?._id}`}
                >
                  ویرایش
                </Link>

                {/* <div
                  className="btn btn-primary save-cover-btn mx-2 font-12 py-1  border-0"
                  data-bs-target="#offcanvasChatboxContainer"
                  data-bs-toggle="offcanvas"
                  data-bs-backdrop="false"
                >
                  پیامهای من
                  <span
                    style={{ background: "black", borderRadius: "50%" }}
                  
                  >
                {data?.moreInfo?.requests.reduce((a, c) => 1 + a, 0)} 
                  </span>
                </div> */}

                <button
                  className="btn btn-primary save-cover-btn mx-2 font-12 py-1  border-0"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  همرسانی
                  <i className="bi bi-share-fill mx-2"></i>
                </button>

                {/* <!-- Modal --> */}
                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lcs}/jobs/ready/page/${data?._id}`}
                  title={data?.name}
                  img={data?.moreInfo?.img}
                  _id={data?._id}
                />

                <button
                  className="btn btn-outline-secondary save-cover-btn mx-2 font-12 py-1  border-0"
                  onClick={saveHandler}
                >
                  ذخیره
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.ready?.includes(data?._id)
                          ? "bi bi-bookmark-fill mx-1"
                          : "bi bi-bookmark mx-1"
                      }
                    ></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-3">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/jobs/ready/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter   "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            {/* <Link
            onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
            to={`/${window.lcs}/jobs/ready/filters?subCat=${
              data?.category?.subCat?.[window.lang]
            }&subCatId=${data?.category?.subCat?._id}`}
            className="btn btn-primary button-filter button-filter-tag  "
          >
            <span>
              <i className="bi bi-list-nested me-1 "></i>
            </span>
            {data?.category?.subCat?.[window.lang]}
          </Link> */}
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              ({" "}
              {tools.dynamicNumber({
                number: repliesCounter(),
                lang: window.lang,
              })}{" "}
              ) دیدگاه
            </a>
          </div>
          {/* <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5"> */}
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-8  ">
          <div className="px-3">
              {/* <button
              type="button"
              className="btn primary-btn checkout-btn cart-footer shadow-none py-2 w-100 bg-pinkOrangeDark  "
            >
              <a href="#tutor-schedule">
                <i className="bi bi-calendar4-week me-2 font-20"></i>
                برنامه کلاسی و رزرو
              </a>
            </button> */}

              <button
                type="button"
                // disabled={data?.moreInfo?.requests?.some(t=>t._id===user?._id)}
                className="btn primary-btn checkout-btn cart-footer my-3 shadow-none py-2 w-100 "
                // className={`btn primary-btn checkout-btn cart-footer bg-${isOwner?"grayGreen":"pinkOrangeDark"} mt-3  border-0 shadow-none py-1 w-100  `}
                // disabled={isOwner}
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  // if (
                  //   data?.moreInfo?.requests?.some((t) => t._id === user?._id)
                  // ) {
                  //   document.getElementById("chatBoxBtn").click();
                  //   messageRef.current.showWarning({
                  //     message:
                  //       "درگذشته پیام شما فرستاده شده است، \n لطفا در پیام رسان متامارس ادامه دهید.",
                  //   });
                  // } else {
                  const myModal = document.getElementById("messageModal"); // Get the modal element
                  const modal = new window.bootstrap.Modal(myModal, {
                    // backdrop: "static",
                    keyboard: true,
                  });
                  modal.show();
                  // }
                }}
              >
                <span>
                  <i
                    className="bi bi-send-plus mx-2"
                    style={{ fontSize: 20 }}
                  ></i>

                  {/* <i
                  className="bi bi-envelope-plus mx-2"
                  style={{ fontSize: 20 }}
                ></i> */}
                </span>
                 درخواست همکاری
              </button>

              {/* <!-- Modal --> */}
              {/* <!-- Modal --> */}
              <div
              className="modal fade"
              id="messageModal"
              tabIndex="-1"
              aria-labelledby="sendMessageModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1
                      className="modal-title titr5  "
                      id="sendMessageModalLabel"
                    >
                      فرستادن پیام به {data?.name}
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-7">
                        <div>
                          {/* <label
                            className="med-gray-font m-3"
                            htmlFor="sessionRequest"
                          >
                             اگه پرسشی از {business?.busName[window.lang]} دارید میتونید مستقیما ازشون بیرسید.
                          </label> */}
                          <textarea
                            placeholder="پیامتون رو این‌جا بنویسید..."
                            className="w-100 px-3 border-0"
                            name=""
                            id="sessionRequest"
                            cols="50"
                            rows="10"
                            onChange={(e) =>
                              setRequest((pre) => ({
                                ...pre,
                                message: e.target.value,
                              }))
                            }
                          ></textarea>
                          <button
                            type="button"
                            className="btn btn-primary border-0  save-cover-btn w-100 px-3"
                            // data-bs-dismiss="modal"
                            onClick={sendTextHandle}
                          >
                            فرستادن درخواست همکاری
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5 align-items-center  display-none-md">
                        <img
                          src="/img/Bussiness-page/Messaging-bus.svg"
                          alt="claim"
                          loading="lazy"
                          // style={{ verticalAlign: "center" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="modal-footer">
                   
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-cover-btn ms-3"
                        data-bs-dismiss="modal"
                      >
                        بستن
                      </button>
                    </div> */}
                </div>
              </div>
            </div>
            </div>
            {/* <div className="w-100 image-container image-container-gd rounded-top">
            <img src={data?.moreInfo?.img} alt={data?.name} />
          </div> */}

            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className="  divider-small w-100  border-0 mt-4">
                <span>
                  <img
                    src="/img/icons/about.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>

                <span className="p-0 mb-0 ">درباره من</span>
              </div>

              <div className="text-justify-metamarce mt-3">
                {tools.linesSpliter(data?.moreInfo?.bio)}
              </div>
            </div>

            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className="  divider-small w-100  border-0 mt-4">
                <span>
                  <img
                    src="/img/icons/portfolio.png"
                    className="arrow4 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">تجربه کاری</span>
              </div>

              <div className="text-justify-metamarce mt-3">
                {tools.linesSpliter(data?.moreInfo?.experience)}
              </div>
            </div>

            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className="  divider-small w-100  border-0 mt-4">
                <span>
                  <img
                    src="/img/icons/mortarboard.png"
                    className="arrow4 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">تحصیلات</span>
              </div>

              <div className="text-justify-metamarce mt-3">
                <ul>
                  {data?.moreInfo?.education.map((edu, i) => (
                    <li className="d-flex">
                      <div>
                        {/* مقطع تحصیلی :  */}
                        {tools.renderTrans({
                          key: edu.level,
                          section: "education",
                        })}
                      </div>
                      <div>
                        {/* رشته تحصیلی */}, {edu.field}
                      </div>
                      <div>
                        {/* دانشگاه :  */}, {edu.institute}
                      </div>
                    </li>
                  ))}
                </ul>
                {tools.linesSpliter(data?.moreInfo?.educationDesc)}
              </div>

              {data?.moreInfo?.certificates && (
              <div>
                <div className="  divider-small w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/badge.png"
                      className="arrow4 pe-1"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 "> گواهینامه‌های حرفه‌ای</span>
                </div>

                <div className="text-justify-metamarce mt-3">
                  {tools.linesSpliter(data?.moreInfo?.certificates)}
                </div>
              </div>
            )}
            
            </div>

            
            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className="  divider-small w-100  border-0 mt-4">
                <span>
                  <img
                    src="/img/icons/notebook.png"
                    className="arrow4 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">مهارت‌ها</span>
              </div>

              <div className="text-justify-metamarce mt-3">
                <div className="med-gray-font mb-3">
                  <i className="bi bi-circle-fill me-2 "></i> 
                  زبان‌
                </div>

                <ul className="d-flex flex-wrap font-14">
                  {data?.moreInfo?.languages?.map((lang, i, arr) => (
                    <li key={i} className=" d-flex ">
                      <div
                        htmlFor={lang + i}
                        className="form-check-label px-1 "
                      >
                        {tools.renderTrans({
                          section: "languages",
                          key: lang.name,
                        })}
                      </div>
                      <div className="mid-gray-font">
                        (
                        {tools.renderTrans({
                          section: "langsLevel",
                          key: lang.level,
                        })}
                        ){i < arr.length - 1 && "،"}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="med-gray-font mb-3">
                <i className="bi bi-circle-fill me-2 "></i> مهارت‌های دیگر 
                <div>{tools.linesSpliter(data?.moreInfo?.skills)}</div>
              </div>
            
              {/* {data?.moreInfo?.hobbies && (
              <div>
                <div className="  divider-small w-100  border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/Bowling.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">سرگرمی‌ها</span>
                </div>

                <div className="text-justify-metamarce mt-3">
                  {tools.linesSpliter(data?.moreInfo?.hobbies)}
                </div>
              </div>
            )} */}
           </div>
          
          </div>
          <div className=" col-lg-4 ">
           

            <div className="listing-sidebar-section mt-0 mb-5">
              {/* <div className="divider2  divider-small w-100   border-0 mt-5 mb-3">
              <span>
                <img
                  src="/img/bussiness-page/information.png"
                  className="arrow2 pe-2"
                  alt="info"
                />
              </span>
              <span>جزییات </span>
            </div> */}
              {/* <div className="divider2  divider-small w-100 mt-5 mb-3  border-0">
              <span>
                <img
                  src="/img/icons/reminder.png"
                  className="arrow2 pe-2"
                  alt="info"
                />
              </span>
              <span>چگونگی برگزاری کلاس </span>
            </div>
            <ul className="d-flex ps-1">
              {renderData?.serviceTypes?.map((ST, i) =>
                !data?.moreInfo?.serviceTypes?.includes(ST.name) ? (
                  ""
                ) : (
                  <li key={i} className="me-3">
                    <img src={ST.img} className="arrow2 ps-2" alt="info" />
                    <span
                      className="form-check-label px-1 "
                      htmlFor={ST.name + i}
                    >
                      {ST?.[window.lang]}
                    </span>
                  </li>
                )
              )}
            </ul> */}

              {/* <div className="divider2  divider-small w-100 mt-5 mb-3  border-0">
              <span>
                <img
                  src="/img/icons/reminder.png"
                  className="arrow2 pe-2"
                  alt="info"
                />
              </span>
              <span>زمان دسترسی </span>
            </div>
            <ul className="d-flex flex-column">
              {data?.moreInfo?.availability?.map((ST, i) => (
                <li key={i} className="d-flex justify-content-between">
                  <div>
                    <span>
                      {
                        demyData?.availableDay?.find(
                          (RST) => RST.name === ST.availableDay
                        )?.[window.lang]
                      }
                      {ST?.[window.lang]}
                    </span>
                    <span> : </span>
                  </div>
                  <span>
                    {
                      demyData?.availableTime?.find(
                        (RST) => RST.name === ST.availableTime
                      )?.[window.lang]
                    }
                    {ST?.[window.lang]}
                  </span>
                </li>
              ))}
            </ul> */}
              <div className="divider-small w-100  mb-3  border-0">
               
              <span>
                  <img
                    src="/img/icons/portfolio.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>نوع همکاری </span>
               
                <div className="d-flex justify-content-center flex-wrap">
                  {data?.moreInfo?.emType.map((em) => (
                    <Link
                      title="نوع همکاری"
                      className="btn btn-primary button-filter button-filter-tag button-filter-tag4  mx-2"
                      to={`/${window.lcs}/jobs/filters?emType=${em}`}
                    >
                      {
                        renderData.emType.find((emp) => emp.name === em)?.[
                          window.lang
                        ]
                      }
                    </Link>
                  ))}
                </div>
              </div>
              <div className="divider-small w-100 mt-5 border-0">
                <span>
                  <img
                    src="/img/icons/income.png"
                    className="arrow2 pe-2"
                    alt="info"
                  />
                </span>
                <span>حقوق درخواستی </span>
              </div>
              <div>{data?.moreInfo?.salary?.desc}</div>
              {/* <div className="d-flex justify-content-between">
                <p className="mid-gray-font">
                  {tools.renderTrans({
                    section: "jobPeriod",
                    key: data?.moreInfo?.salary?.period,
                  })}
                </p>
                <div>
                  <strong>
                    {tools.showPrice(
                      Math.ceil(data?.moreInfo?.salary?.min),
                      data?.moreInfo?.salary?.currency
                    )}
                  </strong>
                  - تا -
                  <strong>
                    {tools.showPrice(
                      Math.ceil(data?.moreInfo?.salary?.max),
                      data?.moreInfo?.salary?.currency
                    )}
                  </strong>
                </div>
              </div> */}
              
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">

                <div className="divider-small w-100 mb-3 border-0">
                  <span>
                    <img
                      src="/img/bussiness-page/home-address.png"
                      className="arrow2 pe-2 py-1"
                      alt="more info"
                    />
                  </span>
                  <span className="mb-0 ">محل زندگی </span>
                </div>
                <ul className="list-group px-1" dir="ltr">
                  <li className="group-list-item flex-column">
                    <div>
                      {/* <span>
                    <i className="bi bi bi-geo-alt mx-3"></i>
                  </span> */}

                      <span className="addressBusPage">
                        {`
                  ${
                    data?.moreInfo?.address?.number?.length
                      ? "#" +
                        window.capitalize(data?.moreInfo?.address?.number) +
                        ","
                      : ""
                  }
                  ${
                    data?.moreInfo?.address?.streetNumber?.length
                      ? window.capitalize(
                          data?.moreInfo?.address?.streetNumber
                        ) + ","
                      : ""
                  }
                  ${
                    data?.moreInfo?.address?.route?.length
                      ? window.capitalize(data?.moreInfo?.address?.route) + ","
                      : ""
                  }
                  
                  ${
                    data?.moreInfo?.address?.postalCode
                      ? data?.moreInfo?.address?.postalCode
                      : ""
                  }
                  
                 
                 `}
                      </span>
                    </div>
                    <div>
                      {`
                  ${
                    data?.moreInfo?.address?.municipality?.length
                      ? window.capitalize(
                          data?.moreInfo?.address?.municipality
                        ) + ","
                      : ""
                  }
                  ${
                    data?.moreInfo?.address?.city?.length
                      ? window.capitalize(data?.moreInfo?.address?.city) + ","
                      : ""
                  }
                  ${
                    data?.moreInfo?.address?.state?.length
                      ? window.capitalize(data?.moreInfo?.address?.state) + ","
                      : ""
                  }
                  ${
                    data?.moreInfo?.address?.countryShort?.length
                      ? window.capitalize(data?.moreInfo?.address?.countryShort)
                      : ""
                  }
                  `}
                    </div>
                  </li>
                  <li>{data?.moreInfo?.address?.preciseAddress}</li>
                
                </ul>
                {data?.moreInfo?.commute&&<div>
                  <div className="divider-small w-100 mb-3 border-0">
                  <span>
                    <img
                      src="/img/icons/office.png"
                      className="arrow2 pe-1 "
                      alt="more info"
                    />
                  </span>
                  <span className="mb-0 ">محل کار </span>
                </div>
                <div className="text-justify-metamarce mt-3">
                {tools.linesSpliter(data?.moreInfo?.commute)}
              </div>
              </div>}
              </div>

            {data?.moreInfo?.showContact && (
              <div className="listing-sidebar-section mt-0 mb-5">
                <div className="divider-small border-0">
                  <span>
                    <img
                      src="/img/bussiness-page/email.png"
                      className="arrow2 pe-2 py-1"
                      alt="more info"
                      loading="lazy"
                    />
                  </span>
                  <span className="p-0 mb-0 ">راه‌های تماس</span>
                </div>
                <ul className="list-group px-1" dir="ltr">
                  {data?.moreInfo?.phone&&<li className="group-list-item">
                    <i className="bi bi-telephone mx-3"></i>
                    {tools.formatPhoneNumber(data?.moreInfo?.phone)}
                  </li>}
                  <li className="group-list-item ">
                    <i className="bi bi-envelope mx-3"></i>
                    <div className="text-break">{data?.email}</div>
                  </li>
                  {data?.moreInfo?.linkedin && (
                    <li className="group-list-item ">
                      <i className="bi bi-linkedin mx-3"></i>
                      <a href={data?.moreInfo.linkedin} target="_blank">
                         پروفایل لینکدین
                      </a>
                    </li>
                  )}
                </ul>
                {/* </div> */}
              </div>
            )}
          
         {data?.moreInfo?.tags?.length > 0 && (
                <div className="listing-details-information px-md-5  p-3 mb-5">
                  <div className=" divider-small w-100 border-0  mb-3">
                    <span>
                      <img
                        src="/img/bussiness-page/tag.png"
                        className="arrow2 pe-2"
                        alt="tags"
                        loading="lazy"
                      />
                    </span>

                    <span className="p-0 mb-0 ">برچسب‌ها </span>

                    {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
                  </div>

                  <ul className=" d-flex flex-wrap font-12">
                    {data?.moreInfo?.tags?.map((tag, i) => (
                      <li key={i} className="mx-2 my-1">
                        <Link
                          onClick={(e) =>
                            window.scrollTo({ top: 0, smooth: true })
                          }
                          key={i}
                          to={`/${window.lcs}/jobs/ready/filters?tag=${tag}`}
                        >
                          <span key={i}>
                            {/* <img
                  src="/img/bussiness-page/tag.png"
                  className="arrow2 pe-2"
                  alt="tags"
                /> */}
                            {/* <i className="bi bi-hash"></i> */}
                            <i className="bi bi-tag font-10"></i>
                          </span>
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

          </div>
        </div>
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}
      {/* <div className="row m-0 p-0 gx-5 justify-content-center ">
      <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
        <CommentsRating
          id={id}
          value={data}
          setData={setData}
          collection="Tutor"
        />
      </div>
    </div> */}
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendTextHandle(e) {
    if (!window.logined(9999)) return;
    // console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    const hostUser = data?.moreInfo?.history?.createdBy?._id;

    setLoading((pre) => ({ ...pre, spinner: true }));
    // e.target.setAttribute("disabled", true);
    const email = request.email.length ? request.email : user.email;
    if (!hostUser)
      return messageRef.current.showError({
        message: "شناسه کاربری فراهم نشده است.",
      });
    const response = await api.sendText({
      token: user.token,
      data: {
        message: request.message,
        guestEmail: email,
        hostEmail: data.email,
        hostUser,
        hostId: data._id,
        hostName: data?.moreInfo?.history?.createdBy?.name,
        lang: window.lang,
        lcs: window.lcs,
        guestId: user._id,
        url: `https://metamarce.com/${window.lcs}/jobs/ready/page/${data._id}`,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    // setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>
            درخواست شما با موفقیت برای {data.name} فرستاده شد و در اولین فرصت در
            متاچت پاسخ خواهند داد.
          </div>
        </div>
      ),
    });
    // setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}
