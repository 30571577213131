import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../contexts/cartContext";
// import "./cart.css";

const CartItems = ({ data }) => {
  // console.log(data);
  const { decrementItem, increment } = useContext(CartContext);
  const [qty, setQty] = useState(data.orderCount);
  const totalRowPrice = Math.floor(qty * data.price * 100) / 100;
  const addToCart = (Quantity) => {
    const addedProduct = { ...data };
    addedProduct.orderCount = parseInt(Quantity);
    increment(addedProduct);
  };

  const deleteBtn = () => {
    decrementItem(data._id);
  };

  return (
    <div
      dir="ltr"
      className="d-flex"
      style={{
        border: "1px solid #e7e7e7",
        // width: "95%",
        // height: "220px",
        // margin: "25px",
        // display: "flex",
      }}
    >
      {/* *******image****** */}

      <div className="si-pic">
        <Link to={`/product/${data._id}`}>
          <img
            className="checkout-cart-img"
            src={data.img}
            alt="checkout-cart"
            loading="lazy"
          />
        </Link>
      </div>

      <div className="w-100 d-flex flex-column">
        <div className="row w-100 px-3 pt-2">
          <div className=" col-6">
            <div className=" d-flex flex-column h-100">
              
              <h6 className="textgap ">{data.name}</h6>
              <p className="small-gray-font m-0 mt-2 ">فروشگاه ...</p>
              <p className="small-gray-font m-0">توضیحات</p>
            </div>
          </div>
          <div className="col-3">
            <p className="text-end pe-3 my-2" dir="ltr">
              <select
                className="store-select-btn"
                value={qty}
                onChange={(e) => {
                  setQty(e.target.value);
                  addToCart(e.target.value);
                }}
                name={`select${data._id}`}
              >
                {[...Array(data.qty).keys()].map((x) => (
                  <option key={x + 1} value={x + 1}>
                    {x + 1}
                  </option>
                ))}
              </select>
              <span className="small-gray-font">X ${data.price} </span>
            </p>
          </div>
          <div className="col-3">
            <div style={{ fontWeight: "bold" }} className="textgap">
              Total: ${totalRowPrice}
            </div>
            <div className="px-3">
              <button
                className=" btn btn-primary button-filter mt-auto "
                onClick={deleteBtn}
              >
                Delete
              </button>

              <img
                // src="/img/premium/remove.png"
                src="/img/premium/delete-2.png"
                className="arrow3 ms-3 mt-3"
                alt="delete"
                loading="lazy"
                // onClick={this.deleteBtn}
              />
            </div>
          </div>
        </div>
        <div className="d-flex w-100 p-3 mt-auto ms-auto">
          <span className="mx-3">
            <i className="bi bi-heart"></i>
          </span>
          <span>
            <i className="bi bi-share"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
