
import "./main.css";
import "./styleListfinal.css";
import LandingPage from "./components/screens/landingPage/landingPage";
import { Routes, Route, useNavigate } from "react-router-dom";
import data from "./utils/LanguagesCountries";
import StateOutlet from "./router/outlets/stateOut";
import iso2 from "./utils/iso2.json"
import NotFound from "./components/persian/common/alerts/404";
import StatesRoutes from "./router/statesRoutes";
import ComingSoon from "./components/persian/common/alerts/comingSoon";
import { useEffect } from "react";

function App() {
  const navigate = useNavigate();  
  const pathname = window.location.pathname.toLowerCase().split("/").slice(1, 4);
  const languagesSet = new Set(data.languages);  
  const matchedLang = languagesSet.has(pathname[0]) ? pathname[0] : null;
  const countIndex = iso2.findIndex(count=> count.iso2.toLowerCase() === pathname[1])
  const matchedCountry =countIndex!==-1? iso2[countIndex] : null;
  const matchedState=matchedCountry?.states.find(state=> state.toLowerCase()===pathname[2]) 
// useEffect(()=>{
//   function affiliateSet(){
//     const urlParams  = new URLSearchParams(window.location.search)
//     const affiliateCode = urlParams.get("aff")
//     const ttl = 7*24*60*60*1000
//     console.log('affiliateCode',affiliateCode)
//     if(affiliateCode?.length>=10) {
//       const now = new Date();

//       const item = {
//         affCode: affiliateCode,
//         expiry: now.getTime() + ttl,
//     };
//     localStorage.setItem('affiliate', JSON.stringify(item));
//       // localStorage.setItem("affiliate",affiliateCode)
//     }
//   }
//   affiliateSet()},[])
  

return (
    <>
      <Routes>          
      <Route path={"/"+matchedLang+"/"+matchedCountry?.iso2+"/"+matchedState} element={<StateOutlet/>} >
        {StatesRoutes()}       
      </Route>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/denied" element={<AccessDenied />} />
        <Route path="/privacy" element={<Privacy />} /> */}
        <Route path="/coming" element={<ComingSoon />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>

    </>
  );

}

export default App;
