import axios from "axios"

export default new (class{
filters = async({filters})=>{ 
let filters1=""  
for (let key in filters) { 
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/event?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
filtersArt = async({filters})=>{ 
let filters1=""  
for (let key in filters) { 
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/event/filtersart?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}


// getPopCat = async({filters})=>{
//   console.log(filters);
  
// let filters1=""  
// for (let key in filters) {
//   // console.log(`${key}: ${filters[key]}`);
//   filters1 += `${key}=${filters[key]}&`
// }


// try {

// const response = await axios.get(`/api/event/popCat?${filters1}`)


// // console.log(response.data);
// if(response.status!==200)  throw new Error(response.data.message)
// return response.data

// } catch (error) {

// return  {error: error?.response?.data?.message || error.message}
// }
// }

getLocations = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/event/locations?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

fetchArtList = async({token,access,id})=>{
  try {
  const response = await axios.get(`/api/event/list/art/${id}?access=${access}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }  
  })
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
fetchEventList = async({token,access,id})=>{
  try {
  const response = await axios.get(`/api/event/list/event/${id}?access=${access}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }  
  })
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }

//   userBusList = async({token,filters})=>{
//     let filters1=""  
//   for (let key in filters) {
//     // console.log(`${key}: ${filters[key]}`);
//     filters1 += `${key}=${filters[key]}&`
//   }
//   try {
//   const response = await axios.get(`/api/business/user/list?${filters1}`,{ headers :{
//     Authorization : `Bearer ${token}`,
//    }
  
//   })
  
  
//   // console.log(response.data);
//   if(response.status!==200)  throw new Error(response.data.message)
//   return response.data
  
//   } catch (error) {
//   console.log(error);
//   return  {error: error?.response?.data?.message || error.message}
//   }
//   }
//   changeStatus = async({token,id,action})=>{
//   try {
//   const response = await axios.get(`/api/event/changeStatus/${id}?action=${action}`,{ headers :{
//     Authorization : `Bearer ${token}`,
//    }
  
//   })
  
  
//   // console.log(response.data);
//   if(response.status!==200)  throw new Error(response.data.message)
//   return response.data
  
//   } catch (error) {
//   console.log(error);
//   return  {error: error?.response?.data?.message || error.message}
//   }
//   }
  
  userSavedList = async({token,savedItems})=>{
  try {  
  const response = await axios.post(`/api/event/user/list/saved`,{savedItems} ,{
    headers :{
      Authorization : `Bearer ${token}`,
     }}
     )
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }

registerArtist = async({token , data })=>{
  console.log("registerArtist",data );
  try {
    const response = await axios.post(`/api/event/register/artist` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editArtist = async({token , data })=>{
    console.log("editArtist ",data );    
    try {
      const response = await axios.put(`/api/event/register/artist` ,
      {data} ,{
        headers :{
          Authorization : `Bearer ${token}`,
         }
      })
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
    
    } catch (error) {
       console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }

registerEvent = async({token , data })=>{
  console.log("Event register",data );  
  try {
    const response = await axios.post(`/api/event/register/event` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }

edit = async({token , data })=>{
  console.log("edit ",data );
  
  try {
    const response = await axios.put(`/api/event/register/event` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }


fetchArtDetails = async({id,token})=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/event/artdetails/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
fetchEventDetails = async({id,token})=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/event/eventdetails/${id}`,
{headers:{Authorization:`Bearer ${token}`}} 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
fetchSeatsDetails = async({id,token})=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/event/seats/details/${id}`,
{headers:{Authorization:`Bearer ${token}`}} 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

getTags = async({ filters})=>{    
  let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }

  try {
    const response = await axios.get(`/api/event/tags?${filters1}`)
      
      if(response.status!==200)  throw new Error(response.data.message)      
      return response.data
  
  } catch (error) {             
      return  {error: error?.response?.data?.message || error.message}
  }
  }
})()