import { Link } from "react-router-dom";
import tools from "../../../../utils/tools";

function SmallPostSummaryCard(props) {
  return (
    <div className="card entry entry2 px-0 w-100 ">
      <Link
        onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
        className="link"
        to={`/${window.lcs}/blog/post/${props?.post?._id}`}
        state={{ data: props?.post, posts: props?.posts }}
      >
        <div className="row g-0 ">
          <div className=" col-4  col-lg-12">
            <div className="image-container ">
              {" "}
              <img
                src={props.post && props.post.img}
                alt={props?.post?.title}
                // className=" small-img"
                loading="lazy"
              />{" "}
            </div>
          </div>
          <div className=" col-8 col-lg-12 ">
            <div className="card-body px-3 py-0  d-flex flex-column h-100 px-2">
              <h5 className="card-title entry-title">{props?.post?.title}</h5>

              <div className="entry-meta mt-auto">
                <ul>
                  {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li> --> */}
                  <li className="d-flex align-items-center text-black-50">
                    <i className="bi bi-calendar4"></i>
                    <time
                      dateTime="2020-01-01"                     
                    >
                   
                    {tools.dynamicDate({date:props?.post?.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}
                      
                    </time>
                  </li>
                  {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li> --> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default SmallPostSummaryCard;
