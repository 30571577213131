import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { CartContext } from "../../../contexts/cartContext";
import { useContext } from "react";

export default function Affiliation() {
const{user}=useContext( CartContext)
  const structuredData=[...renderData.structuredData,{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "لینک معرفی اختصاصی | affiliation",
    "description":"یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده ...",

    "url":"https://www.metamarce.com/fa/ca/qc/prompt/affiliation",    
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,          
          "item": {
            "@id": "https://www.metamarce.com/",
            "name": "شروع",
            "description": "متامارس، پلی بین پارسی‌زبانان سراسر دنیا"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,          
          "item": {
            "@id": `https://www.metamarce.com/${window.lcs}/prompt/affiliation`,
            "name": `همکاری با متامارس از طریق لینک معرفی` ,
            "description":"کسب و درآمد در متامارس از طریق لینک معرفی اختصاصی "

          }
        },

   
      ]       
    }
  }]
  const metaTagsData={
    title:'لینک معرفی اختصاصی | affiliation ',
    description:"کسب و درآمد در متامارس از طریق لینک معرفی اختصاصی ",
    section:"affiliation",
    url:`https://www.metamarce.com/${window.lcs}/prompt/affiliation`,
    canonical:`https://www.metamarce.com/${window.lang}/ca/qc/prompt/affiliation`,
    img:"https://www.metamarce.com/img/illustration/pair-programming-animate.svg",
    type:'webpage',
  }
  return (
    <div>
    <MetaDecorator data={{ ...metaTagsData, structuredData }} />
    <div className="my-header m-0">
      <div className="bg-image fixed-bg-media bg-contact-us"></div>
      <div className="p-3 pb-0 px-5">
        <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
          کسب در آمد لینک معرفی اختصاصی(Affiliation)
        </h1>
        <h3 className="mx-auto header-firstpage titr3 text-start" style={{ color: "#60412b" }}>
          پلی بین پارسی‌زبانان سراسر دنیا
        </h3>
      </div>
      <img src="/img/homePage/wave-haikei-3.svg" className="p-0 pt-2 w-100 overlay-b" alt="homePage01" />
    </div>
  
    <div className="px-md-5 px-3">
      <div className="row">
        <div className="col-md-8 px-4 d-flex flex-column align-items-center justify-content-center">
          <p className="titr7">به متامارس خوش آمدید!</p>
          <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
            یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی شده است.
          </p>
          <div className="container">
            <h1 className="text-center my-4">راهنمای کسب درآمد از طریق لینک معرفی متامارس</h1>
  
            <section>
              <h2>مقدمه</h2>
              <p>به برنامه پورسانت و کسب و در آمد مشارکتی متامارس خوش آمدید! با معرفی و هر خرید، ۵٪ کمیسیون کسب کنید. از همین امروز شروع به کسب درآمد کنید.</p>
            </section>
  
            <section>
              <h2>فرآیند ثبت‌نام</h2>
              <ol>
                <li>
                  در متامارس ثبت‌نام کنید.
                  {!user?.roles?.includes(9999) && (
                    <strong data-bs-toggle="modal" data-bs-target="#exampleModalToggle" role="button">
                      (اینجا)
                    </strong>
                  )}
                </li>
                <li>
                  پس از ثبت نام یک لینک اختصاصی برای شما صادر می گردد.
                  <span className="fw-bold fst-italic cursor" onClick={(e) => {
                    if (!window.logined(9999)) return;
                    window.navigateHandle(`/${window.lcs}/dashboard/user/profile/me`);
                  }}>
                    (اینجا)
                  </span>
                </li>
                <li>شما میتوانید لینک اختصاصی خود را کپی و آنرا منتشر کنید و سپس کمیسون شما از کاربرانی که توسط لینک شما وارد وبسایت شده باشند شروع و تا دوسال آینده از تمام خریدهای کاربران مشمول دریافت پورسانت میباشید.</li>
              </ol>
            </section>
  
            <section>
              <h2>تهیه لینک معرفی</h2>
              <ol>
                <li>وارد حساب کاربری خود شوید.</li>
                <li>
                  با کلیک بر روی نام کاربری خود از منوی بازشده گزینه  
                  <span className="fw-bold fst-italic cursor" onClick={(e) => {
                    if (!window.logined(9999)) return;
                    document.querySelector("#offcanvasLoginTrigger").click();
                  }}>
                    "لینک اختصاصی شما به این صفحه"
                  </span>
                  انتخاب و لینک صفحه کنونی را کپی کنید.
                </li>
                <li>پس از کپی کردن میتوانید از آن برای معرفی متامارس استفاده نمائید.</li>
                <li>
                  عملکرد خود را در بخش 
                  <span className="fw-bold fst-italic cursor" onClick={(e) => {
                    if (!window.logined(9999)) return;
                    window.navigateHandle(`/${window.lcs}/dashboard/user/trans/affilatelist`);
                  }}>
                    "تراکنش ها"
                  </span>
                  پیگیری کنید.
                </li>
              </ol>
              <div>
                <img src="/img/guide/affiliationLink.jpg" alt="Affiliation Link" />
              </div>
            </section>
  
            <section>
              <h2>کسب کمیسیون</h2>
              <ul>
                <li>شما برای هر خرید کامل شده برای کاربرانی از طریق لینک معرفی شما ثبت نام نموده اند، ۵٪ کمیسیون کسب می‌کنید.</li>
                <li>کمیسیون‌ها ماهانه از طریق PayPal پرداخت می‌شوند.</li>
                <li>حداقل آستانه پرداخت ۵۰ دلار است.</li>
              </ul>
            </section>
  
            <section>
              <h2>قوانین و راهنماها</h2>
              <ul>
                <li><strong>روش‌های تبلیغاتی مجاز:</strong> پست‌های شبکه‌های اجتماعی، مقالات بلاگ، خبرنامه‌های ایمیلی.</li>
                <li><strong>فعالیت‌های ممنوع:</strong> اسپم، ادعاهای گمراه‌کننده، یا استفاده از لینک همکاری در فروش خود در سایت‌های فروش کوپن.</li>
                <li><strong>رعایت قوانین:</strong> اطمینان حاصل کنید که تمامی فعالیت‌های تبلیغاتی با قوانین محلی سازگار هستند.</li>
              </ul>
            </section>
  
            <section>
              <h2>نظارت و گزارش‌دهی</h2>
              <p>ما از ابزارهای پیشرفته ردیابی برای نظارت بر ارجاع‌های شما و تشخیص هرگونه فعالیت تقلبی استفاده می‌کنیم. داشبورد شما داده‌های لحظه‌ای درباره کلیک‌ها و فروش‌ها را نشان می‌دهد.</p>
            </section>
  
            <section>
              <h2>پشتیبانی و منابع</h2>
              <p>
                نیاز به کمک دارید؟ با تیم پشتیبانی ما به آدرس metamarce.com@gmail.com ایمیل بفرستید و یا در 
                <span className="fw-bold fst-italic cursor" onClick={(e) => {
                  if (!window.logined(9999)) return;
                  document.querySelector("#chatBoxBtn").click();
                }}>
                  "متاچت"
                </span>
                با ما در ارتباط باشید.
              </p>
            </section>
          </div>
        </div>
  
        <div className="col-md-4 text-center">
          <div style={{ position: "relative" }}>
            <img src="/img/illustration/pair-programming-animate.svg" className="mb-3 px-0 w-100" alt="homePage" />
          </div>
        </div>
      </div>
    </div>
  
    <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
  </div>
  
  );
  return (
    <div>
       <MetaDecorator data={{...metaTagsData,structuredData}}/>
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>

        <div className=" p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
          کسب در آمد لینک معرفی اختصاصی(Affiliation)
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start"
            style={{ color: "#60412b" }}
          >
            {/* راهنمای سوشیال مدیای متامارس */}
            {/* منتظر شنیدن دیدگاه‌های شما هستیم! */}
            پلی بین پارسی‌زبانان سراسر دنیا
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-md-5 px-3">
        <div className="row">
          <div className="col-md-8 px-4 d-flex flex-column align-items-center justify-content-center">
            <p className="titr7">به متامارس خوش آمدید!</p>
            <p className="titr7 titr7-5" style={{ color: "#c3b091" }}>
              {" "}
              یک پلتفرم آنلاین پویا که برای جامعه پارسی‌زبان در سراسر جهان طراحی
              شده است.{" "}
            </p>
            <p className="pt-5 text-justify-metamarce">
            
  <div class="container">
    <h1 class="text-center my-4">راهنمای لینک معرفی متامارس</h1>
    
    <section>
      <h2>مقدمه</h2>
      <p>به برنامه پورسانت  و کسب و در آمد مشارکتی متامارس خوش آمدید! با معرفی و هر خرید، ۵٪ کمیسیون کسب کنید. از همین امروز شروع به کسب درآمد کنید.</p>
    </section>
    
    <section>
      <h2>فرآیند ثبت‌نام</h2>
      <ol>
        <li>در متامارس ثبت‌نام کنید.
        {!user?.roles?.includes(9999) && (
                      <strong
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalToggle"
                        role="button"
                      >
                        (اینجا)
                      </strong>
                    )}
        </li>
        <li>پس از ثبت نام یک لینک اختصاصی برای شما صادر می گردد.
        <span className="fw-bold fst-italic cursor"   onClick={(e) => {
                      if (!window.logined(9999)) return;
                      window.navigateHandle(
                        `/${window.lcs}/dashboard/user/profile/me`
                      );
                    }}>
           (اینجا)
          </span>  
        </li>
        <li>شما میتوانید لینک اختصاصی خود را کپی و آنرا منتشر کنید و سپس کمیسون شما از کاربرانی که توسط لینک شما وارد وبسایت شده باشند شروع و تا دوسال آینده از تمام خریدهای کاربران مشمول دریافت پورسانت میباشید. </li>
      </ol>
      
    </section>
    
    <section>
      <h2>تهیه لینک معرفی</h2>
      <ol>
        <li>وارد حساب کاربری خود شوید.</li>
        <li>با کلیک بر روی نام کاربری خود از منوی بازشده گزینه  
        <span className="fw-bold fst-italic cursor"   onClick={(e) => {
                      if (!window.logined(9999)) return;
                      document.querySelector("#offcanvasLoginTrigger").click()
                    }}>
           "لینک اختصاصی شما به این صفحه"
          </span>  
          

          انتخاب و لینک صفحه کنونی را کپی کنید.</li>
        <li>پس از کپی کردن میتوانید از آن برای معرفی متامارس استفاده نمائید.</li>
        <li>عملکرد خود را در بخش 
         <span className="fw-bold fst-italic cursor"   onClick={(e) => {
                      if (!window.logined(9999)) return;
                      window.navigateHandle(
                        `/${window.lcs}/dashboard/user/trans/affilatelist`
                      );
                    }}>
          "تراکنش ها"
          </span>  
          
          پیگیری کنید.</li>
      </ol>
      <div > <img src="/img/guide/affiliationLink.jpg" alt="Affiliation Link" /> </div>
    </section>
    
    <section>
      <h2>کسب کمیسیون</h2>
      <ul>
        <li>شما برای هر خرید کامل شده برای کاربرانی از طریق لینک معرفی شما ثبت نام نموده اند، ۵٪ کمیسیون کسب می‌کنید.</li>
        <li>کمیسیون‌ها ماهانه از طریق PayPal پرداخت می‌شوند.</li>
        <li>حداقل آستانه پرداخت ۵۰ دلار است.</li>
      </ul>
    </section>
    
    <section>
      <h2>قوانین و راهنماها</h2>
      <ul>
        <li><strong>روش‌های تبلیغاتی مجاز:</strong> پست‌های شبکه‌های اجتماعی، مقالات بلاگ، خبرنامه‌های ایمیلی.</li>
        <li><strong>فعالیت‌های ممنوع:</strong>  اسپم، ادعاهای گمراه‌کننده، یا استفاده از لینک همکاری در فروش خود در سایت‌های فروش کوپن.</li>
        <li><strong>رعایت قوانین:</strong> اطمینان حاصل کنید که تمامی فعالیت‌های تبلیغاتی با قوانین محلی سازگار هستند.</li>
      </ul>
    </section>
    
    <section>
      <h2>نظارت و گزارش‌دهی</h2>
      <p>ما از ابزارهای پیشرفته ردیابی برای نظارت بر ارجاع‌های شما و تشخیص هرگونه فعالیت تقلبی استفاده می‌کنیم. داشبورد شما داده‌های لحظه‌ای درباره کلیک‌ها و فروش‌ها را نشان می‌دهد.</p>
    </section>
    
    <section>
      <h2>پشتیبانی و منابع</h2>
      <p>نیاز به کمک دارید؟ با تیم پشتیبانی ما به آدرس metamarce.com@gmail.com 
        ایمیل بفرستید و یا در 
        <span className="fw-bold fst-italic cursor"   onClick={(e) => {
                      if (!window.logined(9999)) return;
                      document.querySelector("#chatBoxBtn").click()
                    }}>
           "متاچت"
          </span>  
         با ما در اتباط باشید.</p>
    </section>
  </div>
  
  <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>

            </p>
          </div>
          <div className="col-md-4 text-center ">
            <div style={{ position: "relative" }}>
              {/* <div className="titr9 text-center dir="ltr"">Welcome to Metamarce!</div> */}
              <img
                src="/img/illustration/pair-programming-animate.svg"
                className=" mb-3 px-0 w-100"
                alt="homePage"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div>
       <div>
        --English translation in following--
        </div>
        <h1>به متامارس خوش آمدید </h1>
    <section>
        <h2>چشم‌انداز ما</h2>
        <p>متامارس فقط یک وبسایت نیست؛ این یک جنبش به سوی ایجاد یک دهکده جهانی است. ما به قدرت جامعه اعتقاد داریم و تلاش می‌کنیم که از مرزهای زبانی فراتر رویم تا اتصالاتی را برقرار کنیم که مهم هستند. سفر ما با تمرکز بر محتوای فارسی شروع می‌شود و به اتحاد ذهن‌ها در زبان‌ها و فرهنگ‌های مختلف می‌پیوندد.</p>
    </section>

    <section>
        <h2>محتوا به آزادی</h2>
        <p>در وبلاگ ما غوطه ور شوید، جایی که دانش با خلاقیت هم‌راه می‌شود. برای دریافت دانش‌های خاص صنعت یا راهنمای مسایل عمومی ، متامارس منبع شماست. ما بر سیاست آزادی در اشتراک‌گذاری عمل می‌کنیم و هرکسی می‌تواند مشارکت کند،  بستری از ایده‌ها و تجربیات گوناگون  .</p>
    </section>

    <section>
        <h2>ساخت پل‌ها</h2>
        <p>در متامارس، ما فقط خالقان محتوا نیستیم. ما داشتنیم، کسب‌وکارها و افراد را با یکدیگر به ارتباط می‌اندازیم تا اتصالات قدرتمندی را برای نیازهای مشترک بسازیم. هدف ما این است که تا حد ممکن فاصله ها رو کاهش دهیم.</p>
    </section>
    <section>
        <h2>پیوستن به جنبش</h2>
        <p>متامارس فقط یک پلتفرم نیست؛ این یک دعوت به پیوستن به یک جامعه پویااست. آیا یک کسب و کار موفق هستید، یا یک بازاریاب با تجربه، یا یک اینفلوئنسر ، یا یک خالق محتوا در حال رشد، در دهکده جهانی ما جایی برای شما وجود دارد. با هم، بیایید آینده بازاریابی دیجیتال را شکل دهیم و از مرزها گذر کرده و جهانی ایجاد کنیم که هر کسی صدا داشته باشد.</p>
    </section>

    <section>
        <h2>پیشنهاد ما</h2>
        <p>متامارس بر پایه شفافیت، همکاری و یک دیدگاه مشترک برای یک دنیای بهتر و متصل ساخته شده است. با ما در این سفر هیجان‌انگیز به عنوان یک نیروی نوآورانه در منظر دیجیتال، یک ایده نوین در هر زمان را تعریف کنید.</p>
    <h4> <p>خوش آمدید به متامارس – جایی که ایده‌ها مرز نمی‌شناسند!</p></h4>
    </section>

<div>Persian translation in the above</div>
    <header>
        <h1>Welcome to Metamarce</h1>
    </header>

    <section>
        <h2>Our Vision</h2>
        <p>Metamarce is not just a company; it's a movement towards creating a global village. We believe in the strength of community, transcending language barriers to foster connections that matter. Our journey begins with a focus on Persian content, branching out to unite minds across various languages and cultures.</p>
    </section>

    <section>
        <h2>Content Unleashed</h2>
        <p>Dive into our blog, where knowledge meets creativity. Whether you're seeking industry-specific insights or a general guide to spark your interest, Metamarce is your go-to source. We operate on an open sharing policy, inviting anyone and everyone to contribute, creating a diverse tapestry of ideas and experiences.</p>
    </section>

    <section>
        <h2>Building Bridges</h2>
        <p>At Metamarce, we're more than just content creators. We're matchmakers, bringing businesses and individuals together to forge powerful connections. Our goal is to serve as the catalyst for collaborative marketing solutions. From crafting compelling advertising videos to managing social media platforms, we're here to elevate your brand presence.</p>
    </section>

    <section>
        <h2>Join the Movement</h2>
        <p>Metamarce isn't just a platform; it's an invitation to join a thriving community. Whether you're a seasoned marketer or a budding content creator, there's a place for you in our global village. Together, let's shape the future of digital marketing, transcending boundaries and creating a world where everyone has a voice.</p>
    </section>

    <section>
        <h2>Our Promise</h2>
        <p>Metamarce is built on transparency, collaboration, and a shared vision for a better-connected world. Join us on this exciting journey as we redefine the landscape of digital marketing, one innovative idea at a time.</p>
    </section>

    <footer>
        <p>Welcome to Metamarce – Where Ideas Know No Borders!</p>
    </footer>
    </div> */}
    </div>
  );
}
