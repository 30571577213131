export default function FormikListErr({formik}) {
  let rows = [];
  function extractErrors(errors, touched, path = '') {
    for (const key in errors) {
      const currentPath = path ? `${path}.${key}` : key;
      if (typeof errors[key] === "string") {
        if (touched?.[key]) {          
           if(key!=="terms")
          rows.push(
            <div className="error-message" key={currentPath}>
              <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
              {errors[key]}
            </div>
          );
        }
      } else {
        extractErrors(errors[key], touched?.[key], currentPath);
      }
    }
  }
  extractErrors(formik.errors, formik.touched);

  return rows;
}
