// import "./saved.css"
import UserDashSavedNav from '../../../components/dashNavbar/userDashSavedNav'
import { Outlet } from "react-router-dom"
import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"
import { useContext } from "react"
import { CartContext } from "../../../../../contexts/cartContext"
import UserDashBusNav from '../../../components/dashNavbar/userDashBusNav'
import UserDashBlogNav from '../../../components/dashNavbar/userDashBlogNav'

export default function UserBlogOut() {
    
  return (
    <div className="savedItems">
        {/* <UserDashNavbar/>*/}
        <UserDashBlogNav/> 
        {/* userBlogOut123 */}
        <Outlet/>
    </div>
  )
}
