import './saved.css'
import {DeleteOutline} from '@mui/icons-material';
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { userRows } from '../../../dummyData';
import UserDashNavbar from '../../../components/dashNavbar/userDashNavbar';
import UserDashSavedNav from '../../../components/dashNavbar/userDashSavedNav';


export default function SavedProducts() {
  const [data , setData] = useState(userRows)
  const deleteHandle = (id)=>{
    setData(data.filter(item => item.id !== id))
  }

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'userName', headerName: 'User Name', width: 160 , renderCell:(params)=>{ return (
    <div className='userListUser'>
        <img className='userListImg' src={params.row.avatar} alt="userName" loading="lazy"/>
        {params.row.userName} 
    </div>
   )} },
  { field: 'email', headerName: 'Email', width: 230 },  
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'transaction',  headerName: 'Transaction', width: 90,}, 
  { field: 'action',  headerName: 'Action', width: 150, renderCell:(params)=>{
    return( 
        <div>
          <Link to={`/dashboard/users/${params.row.id}`}><button className='userListEdit'>Edit</button></Link>
         <DeleteOutline className='userListDelete' onClick={()=>deleteHandle(params.row.id)}/>
        </div>
    )
  }}, 
  { field: 'transaction',  headerName: 'Transaction', width: 90,}, 
];


  return (
    <div className='userList' style={{ height: 400, width: '100%' }}>
      Saved Products
      <DataGrid
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[5]}
        checkboxSelection       
        
      />
      
    </div>
  );
}

