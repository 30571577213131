import { NavLink } from "react-router-dom";

export default function UserDashSavedNav() {
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/user/saved/posts`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        پست{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/saved/business`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        کسب و کار{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/saved/jobs`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        کاریابی
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/saved/media`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        شبکه اجتماعی
      </NavLink>
      {/* <       NavLink to={`/${window.lcs}/dashboard/user/saved/products`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>محصول </NavLink> */}
    </div>
  );
}
