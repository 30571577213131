import axios from "axios"

export default new (class{
filterJobs = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/job?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data
} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
filtersReady = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/job/filtersReady?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data
} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
filtersHide = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/job/filtersHide?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data
} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}


getPopCat = async({filters})=>{
  console.log(filters);
  
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}


try {

const response = await axios.get(`/api/job/popCat?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {

return  {error: error?.response?.data?.message || error.message}
}
}

getLocations = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/job/locations?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

getListData = async({token,access,id})=>{
  try {
  const response = await axios.get(`/api/job/list/${id}?access=${access}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200) throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }

  userBusList = async({token,filters})=>{
    let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }
  try {
  const response = await axios.get(`/api/business/user/list?${filters1}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  changeStatus = async({token,id,action})=>{
  try {
  const response = await axios.get(`/api/job/changeStatus/${id}?action=${action}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  hideChangeStatus = async({token,id,action})=>{
  try {
  const response = await axios.get(`/api/job/changeStatus/${id}?action=${action}`,{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  
  userSavedList = async({token,savedItems})=>{
  try {  
  const response = await axios.post(`/api/job/user/hide/list/saved`,{savedItems} ,{
    headers :{
      Authorization : `Bearer ${token}`,
     }}
     )
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }

register = async({token , values })=>{
  console.log("Jobs register",values );
  
  try {
    const response = await axios.post(`/api/job/register` ,
    {values} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
registerReady = async({token , data })=>{
  console.log("Ready register",data );
  
  try {
    const response = await axios.post(`/api/job/register/ready` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
registerHide = async({token , data })=>{
  // console.log("Hide register",data );
  
  try {
    const response = await axios.post(`/api/job/register/hide` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
edit = async({token , values })=>{
  console.log("edit ",values );
  
  try {
    const response = await axios.put(`/api/job/register` ,
    {values} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editReady = async({token , data })=>{
  
  
  try {
    const response = await axios.put(`/api/job/register/ready` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editHide = async({token , data })=>{
  try {
    const response = await axios.put(`/api/job/register/hide` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }


jobDetails = async(id)=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/job/details/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
readyDetails = async(id)=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/job/details/ready/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
hideDetails = async({token,id})=>{
// console.log("revLikeCounter");
try {
const response = await axios.get(`/api/job/details/hide/${id}`,{
  headers :{
    Authorization : `Bearer ${token}`,          
   }

} 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

getTags = async({ filters})=>{    
  let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }

  try {
    const response = await axios.get(`/api/job/tags?${filters1}`)
      
      if(response.status!==200)  throw new Error(response.data.message)      
      return response.data
  
  } catch (error) {             
      return  {error: error?.response?.data?.message || error.message}
  }
  }

  jobApply = async({token ,_id,formData })=>{    
    try {
      const response = await axios.post(`/api/job/apply/${_id}` ,
      formData ,{
        headers :{
          Authorization : `Bearer ${token}`,          
         }
    
      })
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
    
    } catch (error) {
       console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }

    fetchReadyList = async({token,access,id,key})=>{
      try {
      const response = await axios.get(`/api/job/list/ready/${id}?access=${access}&key=${key}`,{ headers :{
        Authorization : `Bearer ${token}`,
       }  
      })
      // console.log(response.data);
      if(response.status!==200) throw new Error(response.data.message)
      return response.data
      
      } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
      }
      }
    fetchHideList = async({token,access,id,key})=>{
      try {
      const response = await axios.get(`/api/job/list/hide/${id}?access=${access}&key=${key}`,{ headers :{
        Authorization : `Bearer ${token}`,
       }  
      })
      // console.log(response.data);
      if(response.status!==200) throw new Error(response.data.message)
      return response.data
      
      } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
      }
      }






})()