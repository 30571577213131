import { Outlet} from "react-router-dom"
import BusDashListNav from "../../components/dashNavbar/busDashListNav"



export default function BusListDashOut() {
 
  return (
    <div className="blogDashboard" style={{width:"100%"}}>   
   <BusDashListNav/>
    <Outlet/>
        
    </div>
  )
}
