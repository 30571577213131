import { Outlet } from "react-router-dom"
import UserDashDemyNav from '../../../components/dashNavbar/userDashDemyNav'

export default function UserDemyOut() {
    
  return (
    <div className="savedItems">        
        <UserDashDemyNav/> 
        <Outlet/>
    </div>
  )
}
