import { useEffect, useState, useContext } from "react";
import api from "../../../../../../utils/api";
import { Add, Delete, Send } from "@mui/icons-material";
import "./blogSetting.css";
import { CartContext } from "../../../../../contexts/cartContext";
import { useRef } from "react";

export default function BlogSetting() {
  const { user } = useContext(CartContext);
  const effectRan = useRef(false);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({
    mainCategory: "",
    subCategory: [],
    newSubcat: "",
  });

  console.log(activeCategory);
  useEffect(() => {
    if (effectRan.current === false) {
      (async () => {
        const response = await api.getCategory();
        if (response.error) return alert(response.error);
        setCategories(response.data);
      })();
      effectRan.current = true;
    }
  }, []);
  const categoryHandle = (e) => {
    e.preventDefault();
    const category = e.target.value.split(",");
    console.log(category);
    setActiveCategory({
      ...activeCategory,
      mainCategory: category[0],
      subCategory: category.slice(1),
    });
    // setCategory2(category.slice(1))
  };
  const addSubcatHandle = async () => {
    if (
      activeCategory.newSubcat.length < 3 ||
      !activeCategory.mainCategory.length
    )
      return alert(" شاخه اصلی و زیر شاخه جدید را به درستی انتخاب کنید.");
    const newActiveCat = activeCategory;
    newActiveCat.subCategory.push(activeCategory.newSubcat);

    console.log(newActiveCat);
    // setActiveCategory({...activeCategory,})

    const response = await api.createSubcategory(user.token, newActiveCat);
    console.log(response);
    if (response?.error) return alert(response.error);
    console.log(response.data);
    alert(response.message);
    setActiveCategory(response.data);
    // setActiveCategory({...activeCategory,newSubcat:""})
  };

  const deleteHandle = async (e) => {
    console.log(e.target.id);
    if (e.target.id == undefined)
      return alert(" زیر شاخه را به درستی انتخاب کنید.");
    const newActiveSubcat = activeCategory.subCategory.filter(
      (x, i) => i != e.target.id
    );
    const newActiveCat = activeCategory;
    newActiveCat.subCategory = newActiveSubcat;

    const response = await api.createSubcategory(user.token, newActiveCat);
    console.log(response);
    if (response?.error) return alert(response.error);
    console.log(response.data);
    alert("عملیات حذف یک زیرشاخه باموفقیت انجام شد.");
    setActiveCategory(response.data);
  };
  console.log(activeCategory.mainCategory);
  return (
    <div className="blogSettingContainer">
      <div className="leftContainer">
        <h3>categories</h3>
        <select onChange={categoryHandle} name="categoryHandleSel">
          <option value="">انتخاب کنید</option>
          {categories?.map((category, i) => (
            <option
              key={i}
              value={[category.mainCategory, category.subCategory]}
            >
              {category.mainCategory}
            </option>
          ))}
        </select>

        <div
          className="input-group"
          style={{
            display: activeCategory?.mainCategory?.length == 0 && "none",
          }}
        >
          <input
            type="text"
            className="form-control"
            aria-label="Text input with segmented dropdown button"
            onChange={(e) =>
              setActiveCategory({
                ...activeCategory,
                newSubcat: e.target.value,
              })
            }
            autoComplete="off"
          />
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={addSubcatHandle}
          >
            زیر شاخه جدید
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            {activeCategory.subCategory.map((subCat, index) => (
              <li key={index}>
                <a
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="dropdown-item"
                  href="#"
                >
                  <span
                    style={{ color: "red", fontWeight: "bold" }}
                    id={index}
                    value={subCat}
                    onClick={deleteHandle}
                  >
                    X
                  </span>
                  {subCat}.{index + 1}
                </a>
              </li>
            ))}
            <li style={{ display: "flex", alignItems: "center" }}>
              <Send onClick={addSubcatHandle} />
              <input
                type="text"
                className="dropdown-item"
                placeholder=" اضافه نمودن زیر شاخه جدید"
                onChange={(e) =>
                  setActiveCategory({
                    ...activeCategory,
                    newSubcat: e.target.value,
                  })
                }
                autoComplete="off"
              />
              <Add />
            </li>
          </ul>
        </div>
      </div>
      <div className="middleContainer">Mid</div>
      <div className="rightContainer">right</div>
    </div>
  );
}
