import { NavLink } from "react-router-dom";

export default function UserDashJobsNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/user/jobs/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        افزودن فرصت شغلی{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/jobs/list`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        فرصت های شغلی{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/jobs/registerhide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        افزودن گوشه و کنار{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/jobs/listHide`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        گزارش گوشه و کنار{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/jobs/registerReady`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        افزودن آماده بکار{" "}
      </NavLink>
      {/* <NavLink
        to={`/coming`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        لیست درخواست‌دهندگان
      </NavLink> */}
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lcs}/dashboard/user/jobs/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          ویرایش
        </NavLink>
      )}
    </div>
  );
}
