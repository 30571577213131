import { Navigate, Route } from "react-router-dom";
import Home from "../components/persian/home/home";
import BlogOut from "../components/screens/blog/blogOut";
import BusinessOut from "./outlets/businessOut";
import blogRoutes from "./blogRoutes";
import businessRoutes from "./businessRoutes";
import StoreOut from "./outlets/storeOut";
import storeRoutes from "./storeRoutes";
import DashboardOut from "../components/screens/dashboard/dashboardOut";
import DashRoutes from "./dashRoutes";
import { useContext } from "react";
import { CartContext } from "../components/contexts/cartContext";
import AccountActivation from "../components/emailVerify/accountActivation";
import PassRecover2 from "../components/screens/login/passRecover2";
import jobsRoutes from "./jobsRoutes";
import JobsOut from "./outlets/jobsOut";
import mediaRoutes from "./mediaRoutes";
import MediaOut from "./outlets/MediaOut";
import PromptOut from "./outlets/promptOut";
import promptRoutes from "./promptRoutes";
import eventRoutes from "./eventRoutes";
import EventOut from "./outlets/eventOut";
import demyRoutes from "./demyRoutes";
import DemyOut from "./outlets/demyOut";
import MarketPlaceOut from "./outlets/MarketPlaceOut";
import MarketPlaceRoutes from "./MarketPlaceRoutes";
// import MarketPlaceRoutes from "./MarketPlaceRoutes";


export default function StatesRoutes() {
  const lcs = window.location.pathname.split("/").slice(1, 4).join("/");
  const { user } = useContext(CartContext); 
  return (
    <>
      <Route path="" element={<Home />} />
      <Route
        path="register/activate/:token/:id"
        element={<AccountActivation />}
      />
      <Route path="passRecover/:token/:id" element={<PassRecover2 />} />
      <Route path="prompt" element={<PromptOut />}>
      {promptRoutes()}
      </Route>
      <Route path="blog" element={<BlogOut />}>
        {blogRoutes()}
      </Route>
      <Route path="business" element={<BusinessOut />}>
        {businessRoutes()}
      </Route>
      <Route path="mp" element={<MarketPlaceOut />}>
        {MarketPlaceRoutes()}
      </Route>
      <Route path="event"element={user?.roles?.includes(1000) ? <EventOut />: <Navigate replace to={`/${lcs}`} />}>
        {eventRoutes()}
      </Route>
      
      <Route path="demy"element={<DemyOut />}>
        {demyRoutes()}
      </Route>      
      <Route path="jobs" element={<JobsOut />}>
        {jobsRoutes()}
      </Route>
      <Route path="media" element={<MediaOut />}>
        {mediaRoutes()}
      </Route>
      <Route path="store" element={<StoreOut />}>
        {storeRoutes()}
      </Route>
      <Route
        path="dashboard"
        element={user?.roles?.includes(9999) ? <DashboardOut />: <Navigate replace to={`/${lcs}`} />}>        
        {DashRoutes()}
      </Route>
    </>
  );
}
