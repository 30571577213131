import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

export default function BusinessOut() {
  const [pubData] = useOutletContext();
  return (
    <div>
      <Outlet context={[pubData]}/>
    </div>
  )
}
