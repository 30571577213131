// import "../../../userList/userList.css";
import { DeleteOutline, Visibility } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/api";
import ColoredSpiner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";

export default function CurrenciesList() {
  const { user } = useContext(CartContext);
  const effectRan = useRef(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState({ all: true, accepted: false });
  const [updatedVal, setUpdatedVal] = useState({});
  console.log("data", data);
  const deleteHandle = async (id) => {
    // const response = await api.deletePost(user.token, id);
    // if (response.error) return alert(response.message);
    // alert(response.message);
    // setData(response.data);
  };
  function updatingData(originalData, UpdatedData) {
    const originalMap = new Map(originalData.map((item) => [item._id, item]));

    for (const updatedDocument of UpdatedData) {
      if (originalMap.has(updatedDocument._id)) {
        originalMap.set(updatedDocument._id, updatedDocument);
      }
    }
    // Convert the map values back to an array
    const updatedArrayResult = Array.from(originalMap.values());
    return updatedArrayResult;
  }
  const editHandle = async (e) => {
    e.preventDefault();
    const id = e.target.id;

    console.log("id", id);
    const currency = e.currentTarget.getAttribute("data-currency");
    console.log(updatedVal[currency]);
    const value = {
      convertRate: updatedVal[currency]?.convertRate,
      exRate: updatedVal[currency]?.exRate,
    };
    console.log("currency", value);
    setLoading(true);
    const response = await api.updateCur({
      token: user.token,
      currency,
      value,
    });
    setLoading(false);
    if (response.error) return alert(response.message);
    console.log("response 00", response);
    // const newData = data.map(da=> da._id===response.data._id?response.data:da)

    setData(updatingData(data, response.data));
  };

  async function acceptHandle(e) {
    const id = e.target.id;
    const currency = e.currentTarget.getAttribute("data-currency");
    setUpdatedVal((prevState) => {
      const pre = { ...prevState };
      if (!pre[currency]?.accepted) {
        pre[currency] = { ...pre[currency], accepted: false }; // Update the accepted property
      }
      pre[currency].accepted = !pre[currency]?.accepted;
      return pre;
    });
    const accepted = data.find((da) => da._id === id).accepted;
    // setLoading(true)
    const response = await api.updateCur({
      token: user.token,
      currency,
      acceptedView: view.accepted,
      value: { accepted: !accepted },
    });
    setLoading(false);
    if (response.error) return alert(response.message);
    setData(updatingData(data, response.data));
  }
  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await api.fetchCurrencies({
        token: user.token,
        acceptedView: view.accepted,
      });
      setLoading(false);
      console.log("response 00", response);
      if (response.error) {
        alert(response.error);
        setData([]);
      } else setData(response.data);
    })();
  }, [window.location.pathname, view]);

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <button
              id={params.row._id}
              data-currency={params.row.currency}
              className="userListEdit"
              onClick={editHandle}
            >
              Update
            </button>
            <DeleteOutline
              className="userListDelete"
              onClick={() => deleteHandle(params.row._id)}
            />
          </div>
        );
      },
    },
    { field: "iso2", headerName: "Abbreviation", width: 50 },
    {
      field: "name",
      headerName: "Country name",
      width: 170,
      // renderCell: params => <div>{params.row.name}</div>
    },
    {
      field: "exRate",
      headerName: "Exchange Rate",
      width: 175,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div
              className="btn-click mx-2 pt-2"
              style={{ width: 60, height: 29 }}
            >
              {params.row?.exRate}
            </div>

            <input
              style={{ width: 75 }}
              id={params.row?._id}
              data-currency={params.row.currency}
              type="number"
              onChange={(e) => {
                const currency = e.currentTarget.getAttribute("data-currency");
                setUpdatedVal((pre) => ({
                  ...pre,
                  [currency]: { ...pre[currency], exRate: e.target.value },
                }));
              }}
            />
          </div>
        );
      },
    },

    {
      field: "convertRate",
      headerName: "ConvertRate",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div
              className="btn-click mx-2 pt-2"
              style={{ width: 30, height: 29 }}
            >
              {params.row?.convertRate}
            </div>

            <input
              style={{ width: 45 }}
              id={params.row?._id}
              data-currency={params.row.currency}
              type="number"
              onChange={(e) => {
                const currency = e.currentTarget.getAttribute("data-currency");
                setUpdatedVal((pre) => ({
                  ...pre,
                  [currency]: { ...pre[currency], convertRate: e.target.value },
                }));
              }}
            />
          </div>
        );
      },
    },

    {
      field: "accepted",
      headerName: "Accepted Currency",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <div className="form-check form-switch m-0 py-0 px-1">
              <input
                className="form-check-input form-check-input-filter1 m-0 p-0"
                type="checkbox"
                role="switch"
                checked={params.row.accepted}
                id={params.row._id}
                data-currency={params.row.currency}
                onChange={acceptHandle}
              />
            </div>

            {/* <div title={params.row.moreInfo.history?.claimedBy[0]?.name}>{params.row.moreInfo.history?.claimedBy[0]?.name}</div> 
            <div title={params.row.moreInfo.history?.claimedBy[0]?.email}>{params.row.moreInfo.history?.claimedBy[0]?.email}</div>  */}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "currency",
      width: 70,
      //  renderCell:params=> <Link to={`/${window.location.pathname.split('/').slice(1,4).join('/')}/dashboard/business/createdBy/${params.row.moreInfo?.history?.createdBy?._id}`} state={params.row}>
      //   <div title={params.row.moreInfo?.history?.createdBy?.name}>{params.row.moreInfo?.history?.createdBy?.name}</div>
      //   <div title={params.row.moreInfo?.history?.createdBy?.email}>{params.row.moreInfo?.history?.createdBy?.email}</div>
      //   </Link>
    },
    {
      field: "currency_name",
      headerName: "currency_name",
      width: 200,
      // renderCell: (params) => {
      //   return (
      //    <div>
      //     <div></div>
      //     <div></div>
      //    </div>
      //   );
      // },
    },

    // { field: "email", headerName: "Business Email", width: 250 },

    //   { field: 'transaction',  headerName: 'Transaction', width: 90,},

    //   { field: 'transaction',  headerName: 'Transaction', width: 90,},
  ];
  // console.log('view',view);
  return (
    <div>
      {loading && <ColoredSpiner />}
      <div className="col-6 d-flex justify-content-center ">
        <span
          className={`me-2 map-card ${view.accepted && "map-card-active"}`}
          onClick={(e) => setView({ all: false, accepted: true })}
        >
          ارز پذیرفته شده <i className="bi bi-map mx-1 "></i>
        </span>
        <span
          className={`me-2 map-card ${view.all && "map-card-active"}`}
          onClick={(e) => setView({ all: true, accepted: false })}
        >
          همه<i className="bi bi-image mx-1"></i>
        </span>
      </div>

      <div
        className="userList px-5"
        style={{ height: "100vh", width: "100%" }}
        dir="ltr"
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[20]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      </div>
    </div>
  );
}
