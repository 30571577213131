import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import { CartContext } from "../../../contexts/cartContext";
import { useParams } from "react-router-dom";
import apiBusiness from "../../../../utils/apiBusiness";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";

export default function EmployeeSch({ action }) {
  const [loading, setLoading] = useState({
    register: false,
    spinner: false,
  });

  const { id } = useParams();
  const messageRef = useRef(null);
  const { user } = useContext(CartContext);

  useEffect(() => {    
    (async () => {    
      if (action === "edit") return fetchDetails();      
    })();
  }, []);

  async function fetchDetails() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBusiness.emSchedule({id:user?._id ,token: user.token} );
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    primaryValues(formik, response.data);
  }
  const englishRegex = /^[a-zA-Z0-9 ]+$/;

  const formik = useFormik({
    initialValues: {
      terms: false,
      lcs: "",
      listingLangs: "",
      busName: {},
      businessLng: "",
      _id: id,
      email: "",
      buses:[{
        _id:"",
        busName:{},
        moreInfo:{photos:{businessCard:''}},
        scheduleInfo:[{
          name: "",
          img: "",
          email: "",
          phone: "",
          _id: "",
          desc:{fa:"",en:"",fr:""},
          isActive: true,
          schedule: [
            {
              weekDay: "Monday",
              slots: [
                {
                  start: "09:00",
                  duration: 1,
                },
              ],
            },
          ],
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },],
      }],      
    },
    onSubmit: async (values) => {
      console.log("onSubmit", formik.values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiBusiness.setEmSchedule({
        data: values,
        token: user.token,
        id
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({ message: response.error });
      messageRef.current.showSuccess({
        message: "برنامه کاری با موفقیت به روز رسانی شد.",
      });
      primaryValues(formik, response.data);
    },
    validationSchema: Yup.object({
      buses: Yup.array().of(
        Yup.object().shape({
          busName: Yup.object().shape({
            fa: Yup.string()
              .required(" نام کسب و کار را انتخاب کنید.")
              .min(3, "نام کسب و کار حداقل باید ۳ حرف باشد.")
              .max(50, "نام کسب و کار حداکثر باید 50 حرف باشد.")
              .trim(),
            en: Yup.string()
              .matches(englishRegex, "please use english letters.")
              .min(3, "Business name must be 3 letters and more.")
              .max(50, "Business name must be 50 letters and less.")
              .trim(),
            fr: Yup.string()
              .matches(englishRegex, "please use english letters.")
              .min(3, "Business name must be 3 letters and more.")
              .max(50, "Business name must be 50 letters and less.")
              .trim(),
          }),
          _id: Yup.string()
            .required("شناسه کاربری فراهم نشده است.")
            .min(24,"شناسه کاربری درست نمی‌باشد.")
            ,        
           
            scheduleInfo: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required(
                  "لطفا دست کم یک روز هفته را انتخاب نمائید."
                ),
                img: Yup.string().required(
                  "لطفا دست کم یک روز هفته را انتخاب نمائید."
                ),
                email: Yup.string().required(
                  "لطفا دست کم یک روز هفته را انتخاب نمائید."
                ),
                _id: Yup.string().required(
                  "لطفا دست کم یک روز هفته را انتخاب نمائید."
                ),
                schedule: Yup.array().of(
                  Yup.object().shape({
                    weekDay: Yup.string().required(
                      "لطفا دست کم یک روز هفته را انتخاب نمائید."
                    ),
                    slots: Yup.array().of(
                      Yup.object().shape({
                        start: Yup.string().required(
                          "لطفا ساعت شروع را انتخاب نمائید."
                        ),
                        duration: Yup.number().required(
                          "لطفا مدت کلاس را انتخاب نمائید."
                        ),
                      })
                    ),
                  })
                ),
                desc: Yup.object().shape({
                  fa: Yup.string()
                    .max(80, "خدمات حداکثر باید 80 حرف .")
                    // .min(10, "خدمات حداقل باید 10 حرف باشد.")
                    .required("لطفاً خدمات را وارد کنید.")
                    .trim(),
                  en: Yup.string()
                    .matches(englishRegex, "please use english letters.")
                    .max(150, "intro must be 150 letters and less."),
                  fr: Yup.string()
                    .matches(englishRegex, "please use english letters.")
                    .max(150, "intro must be 150 letters and less."),
                }),
              })
            ),
         
        })
      ),
    }),
  });
  // console.log("formik.values", formik.values);
  // console.log('Object.keys(formik.errors)',(formik.errors?.['employes']?.[1]));
  // console.log("formik.errors", formik.errors);

  return (
    <div className="max-w-m mx-auto">
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}

      <div className="titr7 my-3">برنامه های کاری من</div>
      <hr />
      {/* <div className="d-flex m-3">
        <div className="media-img me-2">
        
        <img src={formik.values?.busCard} className="picture-src jobCrad-img me-2"  alt="businessCard" />
       
       </div>
        <h4 className="pt-3">{formik.values?.busName?.[window.lang]}</h4>
      </div>
      */}
    
      <form className="mx-md-3 mx-1" onSubmit={formik.handleSubmit}>
        <div className="table" id="maintable2">
          <div className="accordion">
            {formik.values?.buses?.map((bus, parentIndex) =>bus?.scheduleInfo.map((em,i)=>
            (
              <div className="accordion-item" key={parentIndex+"-"+i}>
                <div className="accordion-header d-flex" id={"heading" + parentIndex+"-"+i}>
                  <button
                    className="accordion-button accordion-button2b"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapse" + parentIndex+"-"+i}
                    aria-expanded="false"
                    aria-controls={"collapse" + parentIndex+"-"+i}
                  >
                    <img
                      src={bus.moreInfo.photos.businessCard}
                     className="icture-src jobCrad-img2 me-2"
                      alt={bus.busName[window.lang]}
                    />
                    <h5 className="m-2">
                      {bus.busName[window.lang]}{" "}
                      {formik.errors?.["employes"]?.[i] && (
                        <Error sx={{ fontSize: 20, color: "red" }} />
                      )}
                    </h5>
                    <div>
                      {em.isActive ? (
                        "(فعال)"
                      ) : (
                        <span className="text-danger">(غیرفعال)</span>
                      )}
                    </div>
                  </button>
                  {/* <button
                    type="button"
                    // style={{ visibility: i > 0 ? "visible" : "hidden" }}
                    className="deleteContact btn btn login-trigger3 btn-xs mx-2"
                    title="پاک کردن"
                    onClick={() => removeHandle(i)}
                  >
                    <i
                      className="bi bi-x-circle-fill p-0 "
                      style={{ fontSize: "1.5em" }}
                    ></i>
                  </button>111111 */}
                </div>
                <div
                  id={"collapse" + parentIndex+"-"+i}
                  className="accordion-collapse collapse"
                  aria-labelledby={"heading" + parentIndex+"-"+i}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="form-check form-switch m-0 py-0 px-1">
                      <input
                        className="form-check-input form-check-input-filter1 m-0 p-0"
                        type="checkbox"
                        role="switch"
                        name="img"
                        // value={'tr.name'}
                        id={`${parentIndex}em.isAct${i}`}
                        checked={em.isActive}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `buses.${parentIndex}.scheduleInfo.${i}.isActive`,
                            !em.isActive
                          )}
                      />
                      <label
                        className="form-check-label px-2"
                        htmlFor={`${parentIndex}em.isAct${i}`}
                      >
                        فعال
                      </label>
                    </div>
                    <div className="d-flex mb-1 ">
                      <div id={"desc" + parentIndex+"-"+i} className="">
                        <label htmlFor={"desc" + parentIndex+"-"+i}>خدمات</label>
                        <textarea
                          type="textarea"
                          rows="2"
                          dir="ltr"
                          className="form-control"
                          placeholder="کوتاهی مو ، ناخن و ... "
                          aria-label="Text input with dropdown button"
                          autoComplete="off"
                          defaultValue={em?.desc?.[window.lang]}
                          // style={{
                          //   borderTopLeftRadius: "20px",
                          //   borderBottomLeftRadius: "20px",
                          // }}
                          onBlur={(e) =>
                            formik.setValues((pre) => {
                              const newF = { ...pre };
                              newF.buses[parentIndex].scheduleInfo[i].desc = {
                                ...em.desc,
                                [window.lang]: e.target.value};
                              return newF;
                            })
                          }
                        />
                        <FormikErrRender
                          formik={formik}
                          field={`buses.${parentIndex}.scheduleInfo.${i}.desc.${window.lang}`}
                        />
                      </div>
                    </div>
                    <ScheduleComponent employee={em} pI0={parentIndex} pI2={i} />
                  </div>
                </div>
              </div>
            ))
          
          )
            
            }

            {/* {!formik?.errors?.employes && (
              <div
              // className='border-top border-secondary'
              >
                <div
                  className="btn btn-xs shadow-none login-trigger2  "
                  title="افزودن همکار"
                >
                  <i
                    className="bi bi-plus-circle-fill "
                    style={{ fontSize: "1.5em" }}
                    onClick={addEmployeeHandle}
                  ></i>
                </div>
              </div>
            )} */}
          </div>

          {/* {formik.values?.employes?.length > 0 && ( */}
            <SubmitBtn formik={formik} loading={loading} act={"edit"} />
          {/* )} */}
        </div>
      </form>
    </div>
  );

  function addEmployeeHandle() {
    console.log("addEmployeeHandle");

    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.employes.length;
      console.log("addsocialHandle", newF, "index", index);
      newF.employes.push({
        name: "",
        img: "",
        email: "",
        phone: "",
        // _id: "",
        schedule: [
          {
            weekDay: "Monday",
            slots: [
              {
                start: "09:00",
                duration: 1,
              },
            ],
          },
        ],
        // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return newF;
    });
  }

  function removeHandle(index) {
    console.log("removeHandle");
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.employes.splice(index, 1);
      return newFormik;
    });
  }

  function ScheduleComponent({ employee,pI0 ,pI2 }) {
    // console.log("employee", employee);
    return (
      <div className=" my-2 text-center">
        {employee?.schedule?.map((sche, parentIndex) => (
          <div className=" my-3" key={pI2 + "-" + parentIndex}>
            <div>
              <div className="day-time  py-3">
                <div
                  className=" mx-auto"
                  style={{ maxWidth: "500px" }}
                >
                  <div className="row">                  
                    <div>
                      <select
                        className="pb-2 my-2 login-btn "
                        dir="rtl"
                        name="days"
                        id="days"
                        value={
                          formik?.values?.buses[pI0]?.scheduleInfo?.[pI2]?.schedule[parentIndex]
                            .weekDay
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            `buses[${pI0}].scheduleInfo.[${pI2}].schedule[${parentIndex}].weekDay`,
                            e.target.value
                          );
                        }}
                      >
                        <option value="">روز کاری را انتخاب کنید</option>
                        {renderData.weekDays
                          ?.filter((day) => {
                            // Filter out the weekdays that are already selected for other schedules
                            return !formik?.values?.buses[pI0]?.scheduleInfo?.[pI2]?.schedule?.some(
                              (schedule, index) => {
                                return (
                                  index !== parentIndex &&
                                  schedule.weekDay === day.name
                                );
                              }
                            );
                          })
                          .map((day, i) => (
                            <option key={i} value={day.name}>
                              {day?.[window.lang]}
                            </option>
                          ))}
                      </select>{" "}
                    </div>

                    <div>
                      {sche.slots?.map((slot, i) => (
                        <div className="d-flex justify-content-around" key={pI0+"-"+pI2+""+i}>
                          <div className="d-flex my-1 ">
                            <label
                              className="pt-2 pe-2 font-12"
                              htmlFor={"slotStart" + pI0+"-"+pI2 + parentIndex + i}
                            >
                              زمان آغاز
                            </label>
                            <span>
                              <input
                                dir="ltr"
                                id={"slotStart" + pI0+"-"+pI2+ parentIndex + i}
                                type="time"
                                className="form-control pt-2 px-1 font-12 lh-1"
                                // value={selectedTime}
                                // onChange={(e) => setSelectedTime(e.target.value)}
                                {...formik.getFieldProps(
                                  `buses[${pI0}].scheduleInfo.[${pI2}].schedule.[${parentIndex}].slots[${i}].start`
                                )}
                              />
                            </span>
                          </div>
                          <div className="my-1 d-flex">
                            <label
                              className="pe-2 pt-2 font-12"
                              htmlFor={parentIndex + "sessionDuration" + i}
                            >
                              مدت{" "}
                            </label>
                            <select
                              className="form-control font-12 px-1"
                              name=""
                              id={parentIndex + "sessionDuration" + i}
                              {...formik.getFieldProps(
                                `buses[${pI0}].scheduleInfo.[${pI2}].schedule.[${parentIndex}].slots[${i}].duration`
                              )}
                            >
                              <option className=" py-5" value=".5">
                                نیم ساعت
                              </option>
                              <option value="1"> یکساعت</option>
                              <option value="2"> دو ساعت</option>
                            </select>
                          </div>

                          <button
                            type="button"
                            style={{ visibility: i > 0 ? "visible" : "hidden" }}
                            className="deleteContact btn pt-2 login-trigger3 btn-xs mx-0"
                            title="پاک کردن"
                            onClick={() =>
                              formik.setValues((prev) => {
                                const newFormik = { ...prev };
                                newFormik.buses[pI0].scheduleInfo[pI2].schedule[parentIndex].slots.splice(i, 1);
                                return newFormik;
                              })
                            }
                          >
                            <i
                              className="bi bi-x-circle-fill p-0 "
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          </button>
                          <FormikErrRender
                            formik={formik}
                            field={`buses.${pI0}.scheduleInfo.${pI2}.schedule.${parentIndex}.slots.${i}.start`}
                          />
                        </div>
                      ))}

                      <FormikErrRender
                        formik={formik}
                        field={`buses.${pI0}.scheduleInfo.${pI2}.schedule.${parentIndex}.weekDay`}
                      />
                      <div
                      // className='border-top border-secondary'
                      >
                        {!("schedule" in formik?.errors) && (
                          <div
                            className="btn btn-xs shadow-none login-trigger2  "
                            title="افزودن ساعت "
                          >
                            <i
                              id={"addSlotbtn" + parentIndex}
                              className="bi bi-plus-circle-fill "
                              style={{ fontSize: "1.5em" }}
                              onClick={(e) => {
                                formik.setValues((prevValues) => {
                                  const newSchedule = [
                                    ...prevValues.buses[pI0].scheduleInfo[pI2].schedule,
                                  ];
                                
                                  const lastSlot =
                                    newSchedule[parentIndex]?.slots[
                                      newSchedule[parentIndex]?.slots?.length - 1
                                    ] || { start: "00:00", duration: 1 };
                                
                                  // Fetch the duration of the last slot
                                  const duration = lastSlot.duration; // Duration in hours
                                
                                  // Split last slot start time into hours and minutes
                                  const [hours, minutes] = lastSlot.start.split(":").map(Number);
                                  const minutesSinceMidnight = hours * 60 + minutes;
                                
                                  // Calculate new start time
                                  const newMinutesSinceMidnight = minutesSinceMidnight + duration * 60; // Add duration in minutes
                                  const newHours = Math.floor(newMinutesSinceMidnight / 60);
                                  const newMinutes = newMinutesSinceMidnight % 60;
                                  const newStartTime = `${newHours.toString().padStart(2, "0")}:${newMinutes.toString().padStart(2, "0")}`;
                                
                                  // Add a new slot with the calculated start time
                                  newSchedule[parentIndex].slots=[...newSchedule[parentIndex].slots,{
                                    start: newStartTime,
                                    duration, // Use the duration of the last slot
                                    booked: false,
                                    bookInfo: {
                                      userName: "",
                                      _id: "",
                                      bookDate: "",
                                      confirmed: false,
                                    }}];

                                  // Create a new values object with the updated schedule
                                  const newValues = {
                                    ...prevValues,
                                    buses: prevValues.buses.map((bus, busIndex) => {
                                      if (busIndex === pI0) {
                                        return {
                                          ...bus,
                                          scheduleInfo: bus.scheduleInfo.map((scheduleInfo, scheduleIndex) => {
                                            if (scheduleIndex === pI2) {
                                              return {
                                                ...scheduleInfo,
                                                schedule: newSchedule,
                                              };
                                            }
                                            return scheduleInfo;
                                          }),
                                        };
                                      }
                                      return bus;
                                    }),
                                  };

                                  return newValues;
                                });
                              }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* left section */}
                  </div>
                </div>
              </div>
            </div>
            {parentIndex > 0 && (
              <button
                type="button"
                className="deleteContact btn btn login-trigger3 btn-xs mx-2"
                title="پاک کردن روز کاری"
                onClick={() =>
                  formik.setValues((prev) => {
                    const newFormik = { ...prev };
                    newFormik.buses[pI0].scheduleInfo?.[pI2].schedule.splice(parentIndex, 1);
                    return newFormik;
                  })
                }
              >
                <i
                  className="bi bi-x-circle-fill p-0 "
                  style={{ fontSize: "1.5em" }}
                ></i>
                <span className="font-12 ps-2">پاک کردن روز کاری</span>
              </button>
            )}
          </div>
        ))}
        <div
        // className='border-top border-secondary'
        >
          {!("schedule" in formik?.errors) &&
            formik.values?.buses[pI0].scheduleInfo?.[pI2].schedule?.length <= 6 && (
              <div
                className="btn btn-xs shadow-none login-trigger2 px-2 py-1"
                style={{ backgroundColor: "#9c988554" }}
                title="افزودن روز کاری"
              >
                <div onClick={addLevelHandle}>
                  <i
                    className="bi bi-plus-circle-fill "
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <span className="font-12 ps-2">افزودن روز کاری</span>
                </div>
              </div>
            )}
        </div>
      </div>
    );

    function addLevelHandle() {
      formik.setValues((prev) => {
        const newF = { ...prev };
        const index = newF.buses[pI0].scheduleInfo?.[pI2].schedule.length;
        const newSh = { ...newF.buses[pI0].scheduleInfo?.[pI2].schedule[index - 1] };
        console.log("newSh", newSh);
        newSh.weekDay = "";
        newF.buses[pI0].scheduleInfo?.[pI2].schedule.push(newSh);
        return newF;
      });
    }
  }
}
function SubmitBtn({ formik, loading, act }) {
  return (

    <div className="px-3"> <button
    
      disabled={formik.isSubmitting}
      className="my-1 mb-3 login-btn login-btn2 w-100"
      style={{
        backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
      }}
      type="submit"
      id="createBusBtn"
    >
      ثبت
      {loading.btn ? (
        <CircularProgress color="inherit" size="20px" />
      ) : (
        Object.keys(formik.errors)?.length === 0 && <i class="bi bi-check-circle-fill ms-1"></i>
      )}
      <span>
        {Object.keys(formik.errors)?.length > 0 && (
          <Tooltip1
            message=" موارد ستاره دار را کامل کنید."
            icon={<Error sx={{ fontSize: 18, color: "red" }} />}
            position="left"
          />
        )}
      </span>
    </button></div>
   
  );
}
function primaryValues(formik, existingBusiness) {
  console.log("existingBusiness", existingBusiness);
  formik.setValues({
    lcs: window.lcs,
    buses:existingBusiness
    // terms: existingBusiness?.moreInfo?.terms || false,
    // listingLangs: existingBusiness?.listingLangs || [],
    // verifiedPhone: existingBusiness?.moreInfo?.verifiedPhone || [],
    // busName: existingBusiness?.busName || {},
    // busCard: existingBusiness?.moreInfo.photos.businessCard || {},
    // businessLng: existingBusiness?.moreInfo?.businessLng || window.lang,
    // status: existingBusiness?.moreInfo?.status || "01-draft",
    // isPaid: existingBusiness?.moreInfo?.isPaid || {},
    // employes: existingBusiness?.scheduleInfo || [],
    // _id: existingBusiness?._id,
    // email: existingBusiness?.email || "",
  });
}
