import React from "react";

export default function RatingManuel({value}) {
    // console.log(value);
  return (
    <span >
      <i  className={value >=1 ? "bi bi-star-fill overal-stars" : value >=.5?"bi bi-star-half overal-stars" : "bi bi-star overal-stars"}></i>
      <i  className={value >=2 ? "bi bi-star-fill overal-stars" : value >=1.5?"bi bi-star-half overal-stars" : "bi bi-star overal-stars"}></i>
      <i  className={value >=3 ? "bi bi-star-fill overal-stars" : value >=2.5? "bi bi-star-half overal-stars" : "bi bi-star overal-stars"}></i>
      <i  className={value >=4 ? "bi bi-star-fill overal-stars" : value >=3.5?"bi bi-star-half overal-stars" : "bi bi-star overal-stars"}></i>
      <i  className={value >=5 ? "bi bi-star-fill overal-stars" : value >=4.5?"bi bi-star-half overal-stars" : "bi bi-star overal-stars"}></i>  
    </span>
  );
}
