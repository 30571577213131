import { Outlet } from "react-router-dom"
import UserDashMediaNav from '../../../components/dashNavbar/userDashMediaNav'

export default function UserMediaOut() {
    
  return (
    <div className="savedItems">        
        <UserDashMediaNav/> 
        <Outlet/>
    </div>
  )
}
