import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import api from "../../../../utils/apiBlog";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import PostSummaryCard from "./postSummaryCard";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import { CircularProgress } from "@mui/material";
import SearchMultiSelect from "../../../golbal/dropdown/searchMultiSelectFilters";
import renderData from "../../../../utils/renderData";
import countryStates from "../../../../utils/countryStates";
import FiltersCatsSelector from "../../../golbal/filtersCatsSelector";
import DropdownSearch from "../../../golbal/dropdown/DropdownSearch";
import MetaDecorator from "../../../../utils/metaDecorator";
import { CartContext } from "../../../contexts/cartContext";

export default function BlogFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {user}=useContext( CartContext)
  const [loading, setLoading] = useState({ fetchPosts: false });
  const [states, setStates] = useState([]);
  const [resultsTotal, setResultsTotal] = useState(0);
  const section = "blog";

  const effectRan = useRef(true);
  const initialFilters = {
    lang: window.lang,
    country: "",
    states: [],
    state: "",
    cities: [],
    mainCat: "",
    mainCatId: "",
    subCat: [],
    subCatId: [],
    author: "",
    authorId: "",
    title: "",
    tag: "",
    searchKey: "",
    pageNumber: 1,
    itemsToShow: 12,
    sort: "dateB",
  };
  const storedFilters = JSON.parse(localStorage.getItem("storedFilters"))?.[
    section
  ];
  const [filters, setFilters] = useState(storedFilters || initialFilters);
  const [posts, setPosts] = useState([]);

  const searchHandle = async (e) => {
    e.preventDefault();
    if (e.key === "Enter" || e.type === "click") {
      setFilters((prev) => ({
        ...prev,
        searchKey: e.target.value,
        pageNumber: 1,
      }));
    }
  };
  const viewMorePosts = async (e) => {
    e.preventDefault();
    setFilters((pre) => ({
      ...pre,
      pageNumber: pre.pageNumber + 1,
    }));
  };

  useEffect(() => {
    if (effectRan.current) return;
    fetchPosts();
    storeFilters({ section, filters });
  }, [filters]);
  function storeFilters({ section, filters }) {
    const storedFilters =
      JSON.parse(localStorage.getItem("storedFilters")) ?? {};
    storedFilters[section] = filters;
    localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    return;
  }

  useEffect(() => {
    const params = {};
    for (let [key, value] of searchParams) {
      params[key] = value;
    }

    setFilters((pre) => ({
      ...pre,
      lang: window.lang,
      country: pre.country ?? "",
      countryShort: pre.country ?? "",
      states: pre.states ?? [],
      state: pre.state ?? "",
      stateShort: pre.stateShort ?? "",
      mainCat: params?.mainCat ?? pre.mainCat,
      mainCatId: params?.mainCatId ?? pre.mainCatId,
      subCat: params?.subCat ? [params?.subCat] : pre.subCat,
      subCatId: params?.subCatId ? [params?.subCatId] : pre.subCatId,
      cities: params?.cities ? [params?.cities] : pre.cities,
      searchKey: params?.searchKey ?? pre.searchKey,
      tag: params?.tag ?? pre.tag,
      author: params.author ?? pre.author,
      authorId: params.authorId ?? pre.authorId,
      title: params.title ?? pre.title,
    }));
    effectRan.current = false;
  }, [searchParams]);

  // console.log(filters);

  function queryRemover(field) {
    console.log(field);
    searchParams?.delete(field);
    const searchString = searchParams?.toString();
    navigate(`?${searchString}`);
  }

  async function fetchPosts() {
    setLoading((pre) => ({
      ...pre,
      fetchPosts: true,
    }));

    const response = await api.getPost({ filters });
    setLoading((pre) => ({
      ...pre,
      fetchPosts: false,
    }));
    if (response.error) return alert(response.error);
    setPosts(response.data.postsForDisplay);
    setResultsTotal(response.data.resultsTotal);
  }
  const structuredData=[...renderData.structuredData,{
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "مجله",
    "url":window.location.href,
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,          
          "item": {
            "@id": "https://www.metamarce.com/",
            "name": "شروع",
            "description": "متامارس، پلی بین پارسی‌زبانان سراسر دنیا"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,          
          "item": {
            "@id": `https://www.metamarce.com/${window.lcs}`,
            "name": `خانه` ,
            "description": "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !"
          }
        },
        {
          "@type": "ListItem",
          "position": 3,          
          "item": {
            "@id": `https://www.metamarce.com/${window.lcs}/blog/filters`,
            "name":` مجله`,
            "description":" و نکات کاربردی مهاجرت و زندگی، جاهای دیدنی و خوراکی خوشمزه و خوردنی، تجربیات باحال و آموزنده ..."
          }
        }
      ]       
    }
  }]
  const metaTagsData={
    title:"مجله متامارس | کلی تجربیات باحال و آموزنده",
    description:"دسترسی به تمام مقلات مربوط به متامارس و سایر نوینسده ها از طریق فیلترهای پشرفته و سریع..",
    section:"مجله",
    url:`https://www.metamarce.com/${window.lcs}/blog/filters`,
    canonical :`https://www.metamarce.com/${window.lang}/ca/qc/blog/filters`,
    img:"https://www.metamarce.com/img/illustration/blogs-and-article.svg",
    type:'weblog',
  }
 

  return (
    <div className="col-lg-8 entries">
       <MetaDecorator data={{...metaTagsData,structuredData}}/>      
      {loading.fetchPosts && <ColoredSpiner />}

      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/${window.lcs}`}>خانه</Link>
          </li>
          <li className="breadcrumb-item">
            <span>مجله</span>
          </li>
        </ol>
      </nav>
      <div className=" d-flex flex-wrap x-3 justify-content-around my-2 border-0 ">
      <span title="نمایش ذخیره ها">
                <i className={`bi ${filters.saved?'bi-bookmark-fill':"bi-bookmark"} icon-stack-1x cursor`} onClick={e=>{
                  if (!window.logined(9999)) return;
                  if(user?.saved?.[section]?.length<= 0)return alert("شما تاکنون آیتم ذخیره شده ای ندارین.")
                  setFilters(pre=>({...initialFilters,saved:!pre.saved,section}))}}></i>
                </span>
        {resultsTotal !== 0 && (
          <span className="box4 py-1 px-2 mx-md-2 mx-0">
            {resultsTotal} یافته
          </span>
        )}
        <div className="blog-search ">
          <BlogSearch searchHandle={searchHandle} />
        </div>
        <button
          className="btn btn-primary button-filter me-md-3 me-0 ms-auto my-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-filter me-1"> فیلترها</i>
        </button>
        
        <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
          <div
            className="d-flex flex-wrap mt-3  
    "
          >
            {/* <!-- Button filter 1 *******************************--> */}
            <div className="dropdown ">
              <button
                type="button"
                className="btn btn-primary button-filter dropdown-toggle smaller"
                data-bs-toggle="dropdown"
                // id="#filter5"
                aria-expanded="false"
                // aria-controls="filter5"
              >
                <span>
                  <i className="bi bi-arrow-down-up me-1"></i>
                </span>
                مرتب کن
                <span className="ps-3">
                  <i className="bi bi-chevron-down"></i>
                </span>
              </button>

              {/* <!-- Modal --> */}

              <div className="dropdown-menu checkbox-form">
                <div className=" ">
                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={filters.sort === "likeB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "likeB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label "
                      htmlFor="flexRadioDefault1"
                    >
                      پسندیده
                    </label>
                  </div>
                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      checked={filters.sort === "dateB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "dateB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      تازه‌ترین
                    </label>
                  </div>

                  <div className="form-check d-flex justify-content-between flex-row-reverse">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault4"
                      checked={filters.sort === "viewsB" && true}
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: "viewsB",
                          pageNumber: 1,
                        }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault4"
                    >
                      بازدید
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Button country 2 *******************************--> */}
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary button-filter dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <i className="bi bi-geo-alt me-1"></i>
                </span>
                کشور
                <span className="ps-3">
                  <i className="bi bi-chevron-down"></i>
                </span>
              </button>

              {/* <!-- Modal --> */}

              <div className="dropdown-menu checkbox-form">
                <DropdownSearch updateParent={countrySet} />
              </div>
            </div>

            {/* <!-- Button State 2 *******************************--> */}

            {filters.country?.length > 0 && (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-primary button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="bi bi-geo-alt me-1"></i>
                  </span>
                  استان
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu px-0 checkbox-form">
                  <div
                    className="reset-btn w-100"
                    onClick={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        states: [],
                        state: "",
                        stateShort: "",
                        pageNumber: 1,
                      }));
                    }}
                  >
                    پاک کردن
                  </div>
                  {states.map((state, i) => (
                    <div
                      key={"states" + i}
                      className="form-check d-flex justify-content-between flex-row-reverse px-3"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // value={state.name?.toLowerCase()}
                        onChange={(e) => {
                          const states = filters?.states
                            ? [...filters.states]
                            : [];
                          const state1 = { stateShort: "", state: "" };
                          const value = state.state;
                          console.log("value", value);
                          const index = states.indexOf(value);
                          if (index !== -1) {
                            states.splice(index, 1);
                            const contry = countryStates.find(
                              (count) =>
                                count.countryShort ===
                                filters.countryShort.toUpperCase()
                            );
                            const stateShort = contry?.states?.find(
                              (state) => state.state === states[0]
                            )?.stateShort;
                            console.log("stateShort", stateShort);
                            // const stateShort= countryStates.find(count=>count.countryShort === filters.countryShort)
                            if (states.length === 1) {
                              state1.stateShort = stateShort;
                              state1.state = states[0];
                            } else {
                              state1.stateShort = "";
                              state1.state = "";
                            }
                          } else {
                            states.push(value);
                            if (states.length === 1) {
                              state1.stateShort = state.stateShort;
                              state1.state = value;
                            } else {
                              state1.stateShort = "";
                              state1.state = "";
                            }
                          }
                          setFilters((pre) => ({
                            ...pre,
                            states,
                            state: state1.state,
                            stateShort: state1.stateShort,
                          }));
                        }}
                        checked={filters.states?.includes(state.state)}
                        id={`statesCheckBox${i}`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`statesCheckBox${i}`}
                      >
                        {state?.state}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* <!-- Button City 3 *******************************--> */}
            {filters.states.length === 1 && filters?.state?.length > 0 && (
              <div className="dropdown">
                <button
                  type="button"
                  className="btn btn-primary button-filter dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <i className="bi bi-geo-alt me-1"></i>
                  </span>
                  شهر/ محله
                  <span className="ps-3">
                    <i className="bi bi-chevron-down"></i>
                  </span>
                </button>

                {/* <!-- Modal --> */}

                <div className="dropdown-menu checkbox-form">
                  <SearchMultiSelect
                    updateParent={setCitiesHandle}
                    data={{
                      countryShort: filters.countryShort,
                      stateShort: filters.stateShort,
                      cities: filters.cities,
                    }}
                  />
                </div>
              </div>
            )}
            {/* <!-- Button MainCat 3 *******************************--> */}
            <FiltersCatsSelector
              section={section}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        </div>

        {/* <!-More filters- Button filter 5 here*******************************--> */}
      </div>

      <div className=" d-flex flex-wrap mt-1 mb-3  show-filters ">
        {selectedFiltersRender()}
        {selectedFiltersRender().length > 0 && (
          <div
            className="shadow-none m-2 d-flex justify-content-between align-items-center img-banner-text-7 px-3 filter-del-btn"
            onClick={(e) => setFilters(initialFilters)}
          >
            <span>پاک کردن فیلترها</span>
          </div>
        )}
      </div>

      {/* {filters.author?.length > 0 && (
        <div>
          نوشته های {searchParams.get("authorName")}
          <i
            className="bi bi-x-lg"
            onClick={(e) => {
              queryRemover("authorName");
              queryRemover("author");
            }}
          ></i>
        </div>
      )} */}

      {loading.fetchPosts ? (
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <img src="/img/searching-error.svg" alt="Searching..." loading="lazy"/>
        </div>
      ) : (
        resultsTotal <= 0 && (
          <div className="row m-5 px-md-5 px-0">
            <div className="col-md-6 d-flex align-items-center justify-content-center px-0">
              داده ای پیدا نشد، لطفا فیلتر های دیگر را امتحان کنید !
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <img src="/img/cart-is-empty.svg" alt="Data not found" loading="lazy"/>
            </div>
          </div>
        )
      )}

      {posts?.map((post, i) => {
        return <PostSummaryCard key={i} data={post} posts={posts} />;
      })}
      <div className="mt-2 d-flex justify-content-center pe-5">
        <button
          className="btn px-5 btn-sm shadow-none login-btn p-1 "
          onClick={viewMorePosts}
          disabled={resultsTotal <= posts.length || loading.fetchPosts}
        >
          {loading.fetchPosts ? (
            <CircularProgress color="inherit" size="20px" />
          ) : resultsTotal <= posts.length ? (
            "پایان یافته ها"
          ) : (
            "نوشته های بیشتر"
          )}
        </button>
      </div>
    </div>
  );
  //   function deleteArrayFilter(e) {
  //     const field = e.currentTarget.id.split(",")[0];
  //     const index = e.currentTarget.id.split(",")[1];
  //  console.log('filed',field)
  //  queryRemover(field);
  //     function update() {
  //       let newFilters = { ...filters };
  //       for (let key in newFilters) {
  //         key == field
  //           ? (function () {
  //               newFilters[key].splice(index, 1);
  //               newFilters.pageNumber = 1;
  //               if(key==='subCat'){
  //                 newFilters.subCatId.splice(index, 1)
  //               }
  //             })()
  //           : (newFilters[key] = newFilters[key]);
  //       }
  //       return newFilters;
  //     }

  //     setFilters(update());
  //   }
  function deleteArrayFilter(e) {
    const field = e.currentTarget.id.split(",")[0];
    const index = e.currentTarget.id.split(",")[1];
    console.log("field", field);
    queryRemover(field);
    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key].splice(index, 1);
              newFilters.pageNumber = 1;
              if (key === "subCat") {
                newFilters.subCatId.splice(index, 1);
                queryRemover("subCatId");
              }
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    // effectRan.current = false;
    setFilters(update());
  }

  function selectedFiltersRender() {
    let rows = [];

    for (let key in filters) {
      if (Array.isArray(filters[key]))
        filters[key].map((option, i) => {
          // console.log(key, renderData[key], option);
          if (
            key !== "states" &&
            key !== "cities" &&
            key !== "subCat" &&
            key !== "subCatId"
          ) {
            const translate = renderData[key]?.find(
              (option1) => option1.name === option
            );
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{translate?.[window.lang]} </span>
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }

          if (key === "states" || key === "cities" || key === "subCat") {
            rows.push(
              <div
                key={[option, i]}
                className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center"
              >
                <span>{option} </span>
                {/* <span>{translate.nameFa} </span> */}
                <span
                  className="ms-3 me-2"
                  id={[key, i]}
                  onClick={deleteArrayFilter}
                >
                  <i className="bi bi-x-lg"></i>
                </span>
              </div>
            );
          }
        });

      !Array.isArray(filters[key]) &&
        key !== "lang" &&
        key !== "authorId" &&
        // key !== "country" &&
        key !== "countryShort" &&
        key !== "stateShort" &&
        key !== "section" &&
        // key !== "state" &&
        key !== "sort" &&
        key !== "pageNumber:" &&
        key !== "itemsToShow" &&
        key !== "mainCatId" &&
        filters[key]?.length &&
        rows.push(
          <div className=" btn-search shadow-none m-2 d-flex justify-content-between align-items-center">
            <span>
              {key === "tag" && "# "}
              {filters[key]}
            </span>
            <span className="ms-3 me-2" id={key} onClick={deleteFilter}>
              <i className="bi bi-x-lg"></i>
            </span>
          </div>
        );
    }
    return rows;
  }

  function setCitiesHandle(value) {
    setFilters((prev) => ({ ...prev, cities: value, pageNumber: 1 }));
  }

  function deleteFilter(e) {
    const field = e.currentTarget.id;
    queryRemover(field);
    if (field === "mainCat") queryRemover("mainCatId");
    if (field === "subCat") queryRemover("subCatId");

    function update() {
      let newFilters = { ...filters };
      for (let key in newFilters) {
        key == field
          ? (function () {
              newFilters[key] = "";
              if (key === "mainCat" || key === "mainCatId") {
                newFilters.mainCatId = "";
                newFilters.subCat = [];
                newFilters.subCatId = [];
              }
              if (key === "country") newFilters.states = [];
              newFilters.cities = [];
              if (key === "title") {
                newFilters.authorId = "";
                queryRemover("authorId");
              }
              newFilters.pageNumber = 1;
              newFilters.pageNumber = 1;
            })()
          : (newFilters[key] = newFilters[key]);
      }
      return newFilters;
    }

    const updatedFilters = update();

    setFilters(updatedFilters);
  }
  function countrySet(countryShort) {
    const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    console.log(countryShort);
    const states = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states;
    const state = countryStates.find(
      (count) => count.countryShort.toLowerCase() === countryShort.toLowerCase()
    ).states[0];
    setStates(states);

    setFilters((prev) => ({
      ...prev,
      country: regionNames.of(countryShort).toLowerCase(),
      countryShort: countryShort.toLowerCase(),
      //   state:{
      state: "",
      stateShort: "",
      cities: [],
      // lat:state.lat,
      // lng:state.lng,

      // }
    }));
  }
}
