
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CartContext } from "../../../../../../contexts/cartContext";
import api from "../../../../../../../utils/apiBusiness";
import MyIcons from "../../../../../../golbal/icons/myIcons";
import renderData from "../../../../../../../utils/renderData";
import ColoredSpinner from "../../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../../../../../utils/tools";


export default function MyBusList({suggestMode}) {
  const { user } = useContext(CartContext);
  const [countryShort,stateShort] = window.location.pathname.toLowerCase().split("/").slice(2, 4)
  const navigate = useNavigate()
  const [ loading , setLoading]=useState({spinner: false,})
  const messageRef = useRef(null)

 
  const [data, setData] = useState([]);

  const deleteHandle = async (bus) => {
    console.log('deleteHandle',bus._id);  
    if(window.confirm(`شما در حال حذف دائمی این کسب و کار هستین : "${bus.busName[window.lang]}"`)!==true)   return;
    setLoading(pre=>({...pre,spinner:true}))
    const response = await api.deleteBus({token:user.token , id: bus._id})
    setLoading(pre=>({...pre,spinner:false}))
    if (response.error) return messageRef.current.showError({message:response.error})
    messageRef.current.showSuccess({message:response.message})
    const newData = data.filter(da=> da._id!==bus._id)
    setData(newData);    
  };
  function editBusHandle({props}){
    let text =""
     if(window.lang!==props.businessLng.toLocaleLowerCase()) text+= `شما در حال انتقال به متامارس با زبان ${window.capitalize(props.businessLng)} میباشد . \n`
     if(countryShort.toLocaleLowerCase()!==props.countryShort.toLocaleLowerCase()) text+= `شما در حال انتقال به کشور ${window.capitalize(props.country)} میباشد . \n`
     if(stateShort.toLocaleLowerCase()!==props.stateShort.toLocaleLowerCase()) text+= `شما در حال انتقال به استان ${window.capitalize(props.state)} میباشد . \n`
      const url = `/${props.businessLng + "/" + props.countryShort + "/" + props.stateShort}/dashboard/user/business/edit/${props._id}`
    console.log(url);
    
      if(!text.length) return navigate(url , {state:props} ) 
     
    if(window.confirm(text)!=true)return;
    console.log('PAssed');
  
    navigate(url, {state:props} ) 
    
        
  
  }

  useEffect(() => {
    (async () => {
      const filters={ 
        sort: "dateB",
        userId:user._id,
        suggestMode
      }
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await api.userBusList({token:user.token, filters});
      setLoading((pre) => ({ ...pre, spinner: false }));  
      if (response.error )return alert(response.error);
      setData(response.data);
    })();
   
  }, [window.location.pathname]);


function showPageHandle(props){
  let text =""
   if(window.lang.toLocaleLowerCase()!==props.businessLng.toLocaleLowerCase()) text+= `شما در حال انتقال به زبان ${window.capitalize(props.businessLng)} میباشد . \n`
   if(countryShort.toLocaleLowerCase()!==props.countryShort.toLocaleLowerCase()) text+= `شما در حال انتقال به کشور ${window.capitalize(props.country)} میباشد . \n`
   if(stateShort.toLocaleLowerCase()!==props.stateShort.toLocaleLowerCase()) text+= `شما در حال انتقال به استان ${window.capitalize(props.state)} میباشد . \n`

  if(!text.length) return navigate(`/${window.lcs}/business/page/${props._id}`)
   
  if(window.confirm(text)!=true)return;
 
  navigate(`/${props.businessLng + "/" + props.countryShort + "/" + props.stateShort}/business/page/${props._id}`)
}
const columns = [
  //   { field: '_id', headerName: 'ID', width: 70 },
  {
    field: "action",
    headerName: "عملیات",
    width: 170,
    renderCell: (params) => {
      return (
        <div className="d-flex"> 
        <div  onClick={(e) => showPageHandle(params.row )}><MyIcons icon='display'/> </div>
          {!suggestMode&& <div className="d-flex"><div className="" onClick={e => editBusHandle({props:params.row})} ><MyIcons icon='edit'/></div>         
          {params.row.status!== '00-deleted'&&<div  onClick={() => deleteHandle(params.row)}><MyIcons icon='delete2'/></div>}
          </div>
          
          }
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "وضعیت",
    width: 200,
    renderCell: params => {
    const status = renderData.validation.find(va=> va.name ===params.row.status.substring(3))?.[window.lang]
   return <div title={params.row.status?.substring(3)}>
     {status}
    </div>}
  },
  {
    field: "businessCard",
    headerName: "عکس",
    width: 100,
    renderCell: (params) => {
      return (
        <div >
          <img src={params.row.businessCard} alt="businessCard" className="userListImg" loading="lazy"/>
        </div>
      );
    },
  },  
  { field: "busName", headerName: "نام کسب و کار", width: 200,
     renderCell: (params) => {
      return (
        <strong>       
          <div title={params.row?.busName?.[window.lang]}> {params.row?.busName?.[window.lang]}
          <MyIcons icon={params.row.status}/> 
          </div>
          <div title={params.row?.email}> {params.row?.email}</div>       
        </strong>
      );
    },
   },
   {
    field: "updatedAt",
    headerName: "به روز رسانی",
    width: 80,
 renderCell:params=>  <div title={tools.toSolar(params.row?.updatedAt)}>{tools.toGreg(params.row?.updatedAt)}</div>  
  },
  {
    field: "country",
    headerName: "کشور",
    width: 100,
    renderCell: (params) => (
      <div dir="ltr" title={params.row?.country}>
        {window.capitalize(params.row?.country)}
      </div>
    ),
  },
  {
    field: "state",
    headerName: "استان",
    width: 100,
    renderCell: (params) => (
      <div dir="ltr" title={params.row?.state}>
        {window.capitalize(params.row?.state)}
      </div>
    ),
  },
  


];

return (
  <div className="userList" style={{ height: '100vh', width: "100%" }}>
    {loading.spinner && <ColoredSpinner />}
    <DataGrid
      getRowId={(row) => row._id}
      rows={data}
      components={{
        Toolbar: GridToolbar,
      }}
      disableSelectionOnClick
      columns={columns}
      pageSize={[15]}
      rowsPerPageOptions={[1]}
      checkboxSelection
    />
  </div>
);
}

