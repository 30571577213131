import { Link } from "react-router-dom";
import tools from "../../../../utils/tools";

function NewMediasSmallCard({ data }) {
  return (
    <div className="card entry entry2 px-0 w-100 ">
      <div className="row g-0 ">
        <div className="col-4 col-lg-12">
          <Link
            onClick={(e) => window.scroll(0, 0)}
            className="link"
            to={`/${window.lcs}/media/filters?mediaId=${data?._id}&title=${data.title}`}
            state={{ data }}
          >
            <div className="image-container ">
              <img
                src={data.img}
                alt={data.title}
                // className=" small-img"
                loading="lazy"
              />
            </div>
          </Link>
        </div>

        <div className="col-8 col-lg-12">
          <div className="card-body p-0  d-flex flex-column h-100 px-2">
            <h5 className="card-title entry-title">{data?.title}</h5>

            <div className="entry-meta mt-auto">
              <ul>
                {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li> --> */}
                <li className="d-flex">
                  <Link
                    title="دسته‌بندی اصلی"
                    to={`/${window.lcs}/media/filters?mainCat=${
                      data?.category?.mainCat?.[window.lang]
                    }&mainCatId=${data?.category?.mainCat?._id}`}
                    className="btn btn-primary button-filter button-filter-tag button-filter-tag6 mx-2 py-1 "
                  >
                    {/* <span>
                      <i className="bi bi-list-ul "></i>
                    </span> */}

                    {data?.category?.mainCat?.[window.lang]}
                  </Link>
                  {/* <Link
                      title="دسته‌بندی کوچکتر"
                      to={`/${window.lcs}/media/filters?subCat=${data?.category?.subCat?.[window.lang]}&subCatId=${data?.category?.subCat?._id}`}
                      className="btn btn-primary button-filter button-filter-tag  button-filter-tag4 mx-2 me-auto"
                    >
                      {data?.category?.subCat?.[window.lang]}
                    </Link> */}
                </li>
                <li className="d-flex align-items-center text-black-50">
                  <i className="bi bi-calendar4"></i>
                  <time
                    dateTime="2020-01-01"
                  
                  >
                  {tools.dynamicDate({date:data?.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                  </time>
                </li>
                {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li> --> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMediasSmallCard;
