import axios from "axios";
export default new ( class {  
  // login Routes
login = async ({email , phoneNumber,password,type})=> {
  // console.log("login",email , password);
  try {       
      const response = await axios.post('/api/auth/login',{email, phoneNumber, password,type})
      if(response.status!==200){throw new Error(response.data.message)}
      
      
    return response.data;
      
  } catch (error) {
    return  {error:  error?.response?.data?.message || error.message}          
  }
}


logout =async ()=> {
  try {       
      const response = await axios.get('/api/auth/logout',)
      if(response.status!==200){throw new Error(response.data.message)}
      // console.log(response);
      console.log(response);
      
    return response.data;
      
  } catch (error) {
    return  {error:  error?.response?.data?.message || error.message}          
  }
}

googleLogin = async()=>{
  try {
    console.log('google login');
    const response = await axios.get('/api/auth/google')    
  if(response.status!==200) throw new Error(response.data.message)
  console.log(response);  
  return response.data.data
  } catch (error) {
    // return  {error: error?.response?.data?.message || error.message} 
  } 
 }

  getUser =  async()=>{
    try {
      const response = await axios.get('/api/auth/login/success',{ withCredentials: true })    
    if(response.status!==200) throw new Error(response.data.message)
    // console.log(response.data);
    return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.message} 
    } 
   }
   
  // getUserInfo =  async(id , token)=>{
  //   try {
  //     const response = await axios.get(`/api/users/${id}`, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   // if(response.status!==200) throw new Error(response.data.message)
  //   console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }
  //  getAuthorInfo =  async({id , token})=>{
  //   try {
  //     const response = await axios.get(`/api/users/author/${id}`, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  //   // console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }


  // getRolesInfo =  async(token)=>{
  //   try {
  //     const response = await axios.get(`/api/users/roles`, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error(response.data.message)
  //   // console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }

  //  setUserRoles =  async(id , token , roles)=>{
  //   console.log(id , token , roles);
  //   try {
  //     const response = await axios.put(`/api/users/roles`,{id , roles}, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error(response.data.message)
  //   // console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }
  

  
  //  getAuthorList =async({token})=>{    try {
  //     const response = await axios.get(`/api/users/authorList`, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error(response.data.message)
   
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }
  // setUserInfo =  async({token , userInfo})=>{
  //   try {
  //     const response = await axios.put(`/api/users`,{userInfo}, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  //   console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }
 
   
  //  setAuthorInfo =  async({id , token , authorInfo})=>{
  //   try {
  //     const response = await axios.post(`/api/users/author/${id}`,{authorInfo}, {headers:{
  //       Authorization:`Bearer ${token}`,
  //     }})    
  //   if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  //   console.log(response.data);
  //   return response.data
  //   } catch (error) {
  //     return  {error: error?.response?.data?.message || error.message} 
  //   } 
  //  }
  

  uploadSingleFile =  async({token , formData,path})=>{
    try {
      const response = await axios.post(`/api/general/singleFile?path=${path}`,formData, {headers:{
        Authorization:`Bearer ${token}`,
        
      }})    
    if(response.status!==201) throw new Error(response.data.message)
    console.log(response.data);
    return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.message} 
    } 
   }

 localRegister = async ({userName , email , password,phoneNumber,lcs,affiliate})=>{ 
  try {
    const response  = await axios.post('/api/auth/register',{userName, email ,password,phoneNumber,lcs,affiliate})
    if(response.status!==200) throw new Error(response.data.message)      
     return response.data
  } catch (error) {
    console.log(error);
     return  {error: error?.response?.data?.message || error.message} 
  }
 }
 activate = async (token,id,password)=>{ 
  try {
    const response  = await axios.put(`/api/auth/register/activate`,{token,id,password})
    if(response.status!==200) throw new Error(response.data.message)      
     return response.data
  } catch (error) {
    console.log(error); 
     return  {error: error?.response?.data?.message || error.message} 
  }
 }
 sendNewEmailCode = async ({id})=>{ 
  console.log("sendNewEmailCode",id);
  try {
    const response  = await axios.get(`/api/auth/register/activate?id=${id}`)
    if(response.status!==200) throw new Error(response.data.message)      
     return response.data
  } catch (error) {
    console.log(error); 
     return  {error: error?.response?.data?.message || error.message} 
  }
 }

 passChange =  async(password , newPassword , token)=>{ 
  try {
    const response  = await axios.post('/api/auth/passchange',{password, newPassword},{
      headers:{
        Authorization:`Bearer ${token}`,
      }
    })
    if(response.status!==200)throw new Error(response.data.message)      
     return response.data
  } catch (error) {
     return  {error: error?.response?.data?.message || error.data.message} 
  }
 }
 passRecover =  async({values,lcs})=>{ 
  try {
    const response  = await axios.post('/api/auth/passrecover',{values,lcs}
    )
    if(response.status!==200)throw new Error(response.data.message)      
     return response.data
  } catch (error) {
     return  {error: error?.response?.data?.message || error.data.message} 
  }
 }

 passRecover2 =  async({id , token, newPassword})=>{ 
  console.log("api.passRecover2" , newPassword , token );
  try {
    const response  = await axios.put(`/api/auth/passrecover`,{id , token , newPassword}
    )
    if(response.status!==200)throw new Error(response.data.message)      
     return response.data
  } catch (error) {
     return  {error: error?.response?.data?.message || error.data.message} 
  }
 }

getProductDetails = async(productId)=>{
  console.log("revLikeCounter",productId);
  try {
    const response = await axios.get(`/api/products/${productId}`)
      
      if(response.status!==200)  throw new Error(response.data.message)      
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message.message || error.message}
  }
  }

 


  saveHandler = async({id , token,section})=>{  
      try {
    const response = await axios.post(`/api/general/saved/${id}` ,{section},
    {
    headers :{
      Authorization : `Bearer ${token}`,
      }      
    })
    // console.log(response.data);
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }
 


     //Categories Api'S
     
     getCategories = async({section})=>{
      // console.log("revLikeCounter");
      try {
        const response = await axios.get(`/api/general/categories?section=${section}`)
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
    
      updateSubCat = async(newValues,token , section)=>{
      // console.log("revLikeCounter");
      try {
        const response = await axios.put(`/api/general/categories`,{newValues,section} ,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      createCat = async(data,token , section)=>{
      // console.log("revLikeCounter");
      try {
        const response = await axios.post(`/api/general/categories`,{data,section} ,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      updateMainCat = async(data,token , section)=>{
      // console.log("revLikeCounter");
      try {
        const response = await axios.put(`/api/general/categories/maincat`,{data,section} ,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      changeRowOrder = async({data,token , section})=>{
      // console.log("revLikeCounter");
      try {
        const response = await axios.post(`/api/general/categories/maincat`,{data,section} ,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
    
    
      deleteSubCat = async(mainCatId,index,subCatId,token,section)=>{
      console.log(mainCatId,index);
      try {    
        const response = await axios.delete(`/api/general/categories?mainCatId=${mainCatId}&index=${index}&subCatId=${subCatId}&section=${section}`,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      deleteMainCat = async(mainCatId,lang,token,section)=>{
      console.log(mainCatId);
      try {
        // const response = await axios.delete(`/api/general/categories/delete` ,{mainCatId,index,subCatName},{
        const response = await axios.delete(`/api/general/categories/mainCat?mainCatId=${mainCatId}&lang=${lang}&section=${section}`,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      mainCatPhoto = async({id, url,lang,token,section})=>{
      console.log(id, url);
      try {
        const response = await axios.put(`/api/general/categories/mainphoto` ,{id, url,lang,section},{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      mainCatLogo = async({id, url,lang,token,section})=>{
      console.log(id, url);
      try {
        const response = await axios.put(`/api/general/categories/mainlogo` ,{id, url,lang,section},{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }
      subCatPhoto = async({id, url,index,lang,token,section})=>{
      console.log(id, url,index);
      try {
        const response = await axios.post(`/api/general/categories/subphoto` ,{id, url,lang,index,section},{
          headers:{
            Authorization:`Bearer ${token}`
          }
        }
        )
          // console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }     

      //comments Section
      createComment= async({id  , comment , token , rate,thumbnail,collection})=>{
        try {
        const response = await axios.post(`/api/general/${id}/comment` ,
        {comment,rate,thumbnail,collection} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        console.log(response.data.data.reviews);

        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }

      getComments= async({id})=>{
        try {
        const response = await axios.get(`/api/general/${id}/comment`,
        )
        
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        createReply = async({id  , token , commentId , replyText,thumbnail})=>{
        try {
        const response = await axios.post(`/api/general/${id}/comment/reply` ,
        {token ,commentId , replyText,thumbnail} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        console.log(response);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        createReplyToReply =async({id  , token , commentDocId ,commentId, replyId, replyToReplyText,thumbnail})=>{
        
        try {
        const response = await axios.post(`/api/general/${id}/reply/reply` ,
        {token ,commentDocId ,commentId, replyId, replyToReplyText,thumbnail} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        console.log(response);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        commentLikeCounter = async({id,commentDocId , token , likeId,thumbnail })=>{
        // console.log("commentLikeCounter");
        try {
        const response = await axios.post(`/api/general/${id}/comment/like` ,
        {commentDocId , token ,likeId ,thumbnail} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        // console.log(response.data);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        createCommentReport = async({id,commentDocId , token , commentId , reportText,thumbnail })=>{
        // console.log("revLikeCounter");
        try {
        const response = await axios.post(`/api/general/${id}/comment/report` ,
        {commentDocId , token , commentId ,reportText,thumbnail} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        // console.log(response.data);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        replyLikeCounter = async( {id , commentDocId , token , commentId , replyId,thumbnail })=>{
        
        try {
        const response = await axios.post(`/api/general/${id}/reply/like` ,
        {commentDocId,token , commentId , replyId,thumbnail} ,{
        headers :{
        Authorization : `Bearer ${token}`,
        }
        
        })
        // console.log(response.data);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        createReplyReport = async({id, commentDocId  , token , commentId , replyId, replyReportText,thumbnail})=>{              
        try {
        const response = await axios.post(`/api/general/${id}/reply/report` ,
        {token ,commentDocId,commentId , replyId, replyReportText,thumbnail} ,{
          headers :{
            Authorization : `Bearer ${token}`,
            }
        
        })
          console.log(response);
          if(response.status!==201)  throw new Error(response.data.message)
          return response.data
        
        } catch (error) {
          console.log(error);
          return  {error: error?.response?.data?.message || error.message}
        }
        }
        createReply2Report = async({id, commentDocId  , token , commentId , replyId,reply2Id, reply2ReportText,thumbnail})=>{              
        try {
            const response = await axios.post(`/api/general/${id}/reply2/report` ,
            {token ,commentDocId,commentId , replyId, reply2Id,reply2ReportText,thumbnail} ,{
            headers :{
                Authorization : `Bearer ${token}`,
                }
        
            })
            console.log(response);
            if(response.status!==201)  throw new Error(response.data.message)
            return response.data
        
        } catch (error) {
            console.log(error);
            return  {error: error?.response?.data?.message || error.message}
        }
        }
        
        reply2LikeCounter = async({ id , commentDocId , token , commentId , replyId ,reply2Id,thumbnail})=>{
        
          try {
            const response = await axios.post(`/api/general/${id}/reply2/like` ,
            {commentDocId,token , commentId , replyId,reply2Id,thumbnail} ,{
              headers :{
                Authorization : `Bearer ${token}`,
                }
          
            })
              // console.log(response.data);
              if(response.status!==201)  throw new Error(response.data.message)
              return response.data
          
          } catch (error) {
              console.log(error);
              return  {error: error?.response?.data?.message || error.message}
          }
        }

        fetchCountryDetails = async({filters})=>{ 
          let filters1=""  
          for (let key in filters) {
            // console.log(`${key}: ${filters[key]}`);
            filters1 += `${key}=${filters[key]}&`
          }

        try {
          const response = await axios.get(`/api/general/countryDetails?${filters1}`)
            
            if(response.status!==200)  throw new Error(response.data.message)      
            return response.data
        
        } catch (error) {             
            return  {error: error?.response?.data?.message || error.message}
        }
        }
        fetchCurrencies = async({token,acceptedView})=>{
        try {
          const response = await axios.get(`/api/general/currencies?acceptedView=${acceptedView}`,{
            headers :{
              Authorization : `Bearer ${token}`,
              }
        
          })
            
            if(response.status!==200)  throw new Error(response.data.message)      
            return response.data
        
        } catch (error) {             
            return  {error: error?.response?.data?.message || error.message}
        }
        }
        updateCur = async({token,id,currency,value,acceptedView})=>{
          console.log("value",value);
        try {
          const response = await axios.put(`/api/general/currencies?acceptedView=${acceptedView}`,{currency,id,value},{
            headers :{
              Authorization : `Bearer ${token}`,
              }
        
          })
            
            if(response.status!==200)  throw new Error(response.data.message)      
            return response.data
        
        } catch (error) {             
            return  {error: error?.response?.data?.message || error.message}
        }
        }

        getAcceptedCur = async({token ,accepted})=>{
          try {
            const response = await axios.get(`/api/general/currencies/accepted?accepted=${accepted}`,{
              headers :{
                Authorization : `Bearer ${token}`,
                }          
            })
              
              if(response.status!==200)  throw new Error(response.data.message)      
              return response.data
          
          } catch (error) {             
              return  {error: error?.response?.data?.message || error.message}
          }
          }
          
          getPupData = async({filters})=>{  
            let filters1=""  
            for (let key in filters) {
              // console.log(`${key}: ${filters[key]}`);
              filters1 += `${key}=${filters[key]}&`
            }
  
            try {
              const response = await axios.get(`/api/general/pupData?${filters1}`)
                
                if(response.status!==200)  throw new Error(response.data.message)      
                return response.data
            
            } catch (error) {
               console.log(error);
                return  {error: error?.response?.data?.message || error.message}
            }
            }
     
            getCities =  async(country , state)=>{
              try {
                const response = await axios.get(`https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,{headers: {
                  'X-CSCAPI-KEY': 'eTJJekprM0k1Q1AyYzlQODBrZzkwWGRPR3J3ODZMUXRmVWJ2VnRxOA=='
                }})    
              if(response.status!==200) throw new Error(response.error)
              // console.log(response);
              return response
              } catch (error) {
                return  {error: error.message} 
              } 
             }
             fetchSitemap =  async({token})=>{
              console.log('fetchSitemap');
              try {
                const response = await axios.get(`/api/general/sitemap`,{
                  // headers :{
                  //   Authorization : `Bearer ${token}`,
                  //   }
              
                })
                  
                  if(response.status!==200)  throw new Error(response.data.message)      
                  return response.data
              
              } catch (error) {             
                  return  {error: error?.response?.data?.message || error.message}
              }
             }
             fetchMessages =  async({token,sender,recipient})=>{
              // console.log('fetchMessages');
              try {
                const response = await axios.post(`/api/general/messages`,{sender,recipient},{
                  headers :{
                    Authorization : `Bearer ${token}`,
                    }
              
                })
                  
                  if(response.status!==200)  throw new Error(response.data.message)      
                  return response.data
              
              } catch (error) {             
                  return  {error: error?.response?.data?.message || error.message}
              }
             }
             fetchContactedList =  async({token,id})=>{
              // console.log('fetchContactedList');
              try {
                const response = await axios.get(`/api/general/contacted/${id}?ua=${navigator.userAgent}`,{
                  headers :{
                    Authorization : `Bearer ${token}`,
                    }              
                })
                  
                  if(response.status!==200)  throw new Error(response.data.message)      
                  return response.data
              
              } catch (error) {             
                  return  {error: error?.response?.data?.message || error.message}
              }
             }
             

             askq = async({token,data,lang})=>{
              try {
                const response = await axios.post(`/api/general/askq`,{data,lang},{
                  headers :{
                    Authorization : `Bearer ${token}`,
                    }              
                })                  
                  if(response.status!==200)  throw new Error(response.data.message)      
                  return response.data              
              } catch (error) {             
                  return  {error: error?.response?.data?.message || error.message}
              }
              }
              sendText = async({token , data })=>{
                console.log("sendText ",data );  
                
                try {
                  const response = await axios.post(`/api/general/sendText`,
                  {data} ,{
                    headers :{
                      Authorization : `Bearer ${token}`,
                     }
                  })
                    // console.log(response.data);
                    if(response.status!==200)  throw new Error(response.data.message)
                    return response.data
                
                } catch (error) {
                   console.log(error);
                    return  {error: error?.response?.data?.message || error.message}
                }
                }
              pushSubscription = async({subscription , token,userAgent})=>{
                // console.log("pushSubscription ",userAgent,subscription );  
                
                try {
                  const response = await axios.post(`/api/general/subscribe`,
                  {subscription,userAgent},{
                    headers :{
                      Authorization : `Bearer ${token}`,
                     }
                  })
                    // console.log(response.data);
                    if(response.status!==201)  throw new Error(response.data.message)
                    return response.data
                
                } catch (error) {
                  //  console.log(error);
                    return  {error: error?.response?.data?.message || error.message}
                }
                }
                updateSubscription = async({subscription , token,userAgent})=>{
                // console.log("updateSubscription",userAgent,subscription );  
                
                try {
                  const response = await axios.put(`/api/general/subscribe`,
                  {subscription,userAgent},{
                    headers :{
                      Authorization : `Bearer ${token}`,
                     }
                  })
                    // console.log(response.data);
                    if(response.status!==200)  throw new Error(response.data.message)
                    return response.data
                
                } catch (error) {
                  //  console.log(error);
                    return  {error: error?.response?.data?.message || error.message}
                }
                }
                pushUnSubscribe = async({subscription , token,userAgent})=>{
                // console.log("pushSubscription ",userAgent,subscription );  
                
                try {
                  const response = await axios.delete(`/api/general/subscribe`,
                  {
                    headers :{
                      Authorization : `Bearer ${token}`,
                     }
                  })
                    // console.log(response.data);
                    if(response.status!==200)  throw new Error(response.data.message)
                    return response.data
                
                } catch (error) {
                  //  console.log(error);
                    return  {error: error?.response?.data?.message || error.message}
                }
                }


    

})()