import { Tooltip,IconButton, Fade, Typography, Zoom } from "@mui/material"
import { useState } from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener'

export default function Tooltip1(props) {
    // console.log(props);
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
    <Tooltip title={<Typography fontSize={12} fontFamily={"vazir"}>{props.message}</Typography>}
    placement={props.position||"bottom"} 
    
    onClose={handleTooltipClose}
    open={open}
    //  enterDelay={50} 
    //  leaveDelay={100}
    TransitionComponent={Zoom}
    PopperProps={{
      disablePortal: true,
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  TransitionProps={{ timeout: 200 }}
    >
          <span onClick={handleTooltipOpen}>
        <IconButton sx={{ fontSize: 20, pt: 0, pb: 0}}>
          {props.icon?[props.icon]:
        <i className="bi bi-question-circle" aria-label="more info" ></i>   
      }     
        </IconButton>
      </span>
    </Tooltip>
    </ClickAwayListener>
  )
}
