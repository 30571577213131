import axios from "axios"

export default new (class{
filtersUsed = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/mp/filtersUsed?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data
} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
registerUsed = async({token , data })=>{
  // console.log("Used register",data );
  
  try {
    const response = await axios.post(`/api/mp/register/used` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editUsed = async({token , data })=>{
    try {
      const response = await axios.put(`/api/mp/register/used` ,
      {data} ,{
        headers :{
          Authorization : `Bearer ${token}`,
         }
      })
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
    
    } catch (error) {
       console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }
  usedDetails = async({token,id})=>{
  // console.log("revLikeCounter");
  try {
  const response = await axios.get(`/api/mp/details/used/${id}`,{
    headers :{
      Authorization : `Bearer ${token}`,          
     }
  
  } 
  )
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  fetchUsedList = async({token,access,id,key})=>{
    try {
    const response = await axios.get(`/api/job/list/used/${id}?access=${access}&key=${key}`,{ headers :{
      Authorization : `Bearer ${token}`,
     }  
    })
    // console.log(response.data);
    if(response.status!==200) throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }

// getPopCat = async({filters})=>{
//   console.log(filters);
  
// let filters1=""  
// for (let key in filters) {
//   // console.log(`${key}: ${filters[key]}`);
//   filters1 += `${key}=${filters[key]}&`
// }


// try {

// const response = await axios.get(`/api/mp/popCat?${filters1}`)


// // console.log(response.data);
// if(response.status!==200)  throw new Error(response.data.message)
// return response.data

// } catch (error) {

// return  {error: error?.response?.data?.message || error.message}
// }
// }

// getLocations = async({filters})=>{
//   // console.log(filters);
// let filters1=""  
// for (let key in filters) {
//   // console.log(`${key}: ${filters[key]}`);
//   filters1 += `${key}=${filters[key]}&`
// }

// try {

// const response = await axios.get(`/api/mp/locations?${filters1}`)


// // console.log(response.data);
// if(response.status!==200)  throw new Error(response.data.message)
// return response.data

// } catch (error) {
// console.log(error);
// return  {error: error?.response?.data?.message || error.message}
// }
// }

// getListData = async({token,access,id})=>{
//   try {
//   const response = await axios.get(`/api/mp/list/${id}?access=${access}`,{ headers :{
//     Authorization : `Bearer ${token}`,
//    }
  
//   })
  
  
//   // console.log(response.data);
//   if(response.status!==200) throw new Error(response.data.message)
//   return response.data
  
//   } catch (error) {
//   console.log(error);
//   return  {error: error?.response?.data?.message || error.message}
//   }
//   }


// edit = async({token , values })=>{
//   console.log("edit ",values );
  
//   try {
//     const response = await axios.put(`/api/mp/register` ,
//     {values} ,{
//       headers :{
//         Authorization : `Bearer ${token}`,
//        }
  
//     })
//       // console.log(response.data);
//       if(response.status!==200)  throw new Error(response.data.message)
//       return response.data
  
//   } catch (error) {
//      console.log(error);
//       return  {error: error?.response?.data?.message || error.message}
//   }
//   }
//   editReady = async({token , data })=>{
  
  
//   try {
//     const response = await axios.put(`/api/mp/register/ready` ,
//     {data} ,{
//       headers :{
//         Authorization : `Bearer ${token}`,
//        }
//     })
//       // console.log(response.data);
//       if(response.status!==200)  throw new Error(response.data.message)
//       return response.data
  
//   } catch (error) {
//      console.log(error);
//       return  {error: error?.response?.data?.message || error.message}
//   }
//   }


// getTags = async({ filters})=>{    
//   let filters1=""  
//   for (let key in filters) {
//     // console.log(`${key}: ${filters[key]}`);
//     filters1 += `${key}=${filters[key]}&`
//   }

//   try {
//     const response = await axios.get(`/api/mp/tags?${filters1}`)
      
//       if(response.status!==200)  throw new Error(response.data.message)      
//       return response.data
  
//   } catch (error) {             
//       return  {error: error?.response?.data?.message || error.message}
//   }
//   }

  // jobApply = async({token ,_id,formData })=>{    
  //   try {
  //     const response = await axios.post(`/api/mp/apply/${_id}` ,
  //     formData ,{
  //       headers :{
  //         Authorization : `Bearer ${token}`,          
  //        }
    
  //     })
  //       // console.log(response.data);
  //       if(response.status!==200)  throw new Error(response.data.message)
  //       return response.data
    
  //   } catch (error) {
  //      console.log(error);
  //       return  {error: error?.response?.data?.message || error.message}
  //   }
  //   }

  //   fetchReadyList = async({token,access,id,key})=>{
  //     try {
  //     const response = await axios.get(`/api/mp/list/ready/${id}?access=${access}&key=${key}`,{ headers :{
  //       Authorization : `Bearer ${token}`,
  //      }  
  //     })
  //     // console.log(response.data);
  //     if(response.status!==200) throw new Error(response.data.message)
  //     return response.data
      
  //     } catch (error) {
  //     console.log(error);
  //     return  {error: error?.response?.data?.message || error.message}
  //     }
  //     }






})()