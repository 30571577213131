import React, { useEffect, useState } from "react";
import countryStates from "../../../../utils/countryStates";
import api from "../../../../utils/api";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import { CircularProgress } from "@mui/material";

export default function RegisterAddressManuel(props) {
  const [loading, setLoading] = useState(false);
  async function getCitiesHandle() {
    // console.log(props.formik.values?.cities?.length);
    if (props.formik.values?.cities?.length) return;
    const [countryShort, stateShort] = window.location.pathname
      .toLowerCase()
      .split("/")
      .slice(2, 4);

    setLoading(true);
    const response = await api.getCities(countryShort, stateShort);
    setLoading(false);
    console.log(response);
    if (response.error) return console.log(response.error);
    props.formik.setValues((pre) => ({ ...pre, cities: response.data }));
  }
  useEffect(() => {
    getCitiesHandle();
  }, []);

  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  return (
    <div className="mb-5 ">
      {
      (props.formik.values.location.city?.length||props.formik.values.location.municipality?.length > 0) && 
      (
        <div className="mb-5 mx-3">
          <p className=" small-gray-font pb-3 pt-0">
            اگر آدرستون در این لیست دیده نمیشه می تونید به صورت دستی پر کنید.
            دراین حالت برای نمایش بر روی نقشه داشتن مختصات جغرافیایی ضروریست.
          </p>
          <div className="row d-flex">
            <div className="col mb-2 mx-3 w-100">
              <label className="form-label required m-0" htmlFor="countrySel">
                کشور
              </label>
              <br />

              <select
                id="countrySel"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className="login-btn  selectpicker shadow-none w-100 "
                aria-label="Default select example"
                onChange={mainCountrySelectHandle}
                name="mainCountrySelect"
              >
                <option value="">
                  {window.capitalize(props.formik.values.location?.country) ||
                    " انتخاب کنید"}
                </option>
                {/* {countryStates.map((coun, i) => (
                <option key={i} value={coun.countryShort}>
                  {coun.country}
                </option>
              ))} */}
              </select>

              <FormikErrRender formik={props.formik} field="location.country" />
            </div>
            <div className="col mb-2 mx-3 w-100">
              <label className="form-label required m-0" htmlFor="stateSel">
                استان/ایالت
              </label>
              <br />
              <select
                id="stateSel"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling2"
                aria-controls="offcanvasScrolling2"
                className="login-btn  selectpicker shadow-none w-100 "
                aria-label="Default select example"
                // {...props.formik.getFieldProps("props.formik.values.location?.state")}
                onChange={mainStateSelectedHandle}
                name="mainStateSelected"
              >
                <option value="">
                  {window.capitalize(props.formik.values.location?.state) ||
                    " انتخاب کنید"}
                </option>
                {/* {props.formik.values.states?.map((state, i) => (
                <option key={i} value={[state.stateShort, state.state]}>
                  {state.state}
                </option>
              ))} */}
              </select>

              <FormikErrRender formik={props.formik} field="location.state" />
            </div>
          </div>

          <div className="row d-flex">
            <div className="col mb-2 mx-3 w-100">
              <label className="form-label required m-0">
                {loading && <CircularProgress color="inherit" size="20px" />}
                شهر
              </label>
              <br />
              <select
                className="login-btn mt-0 selectpicker shadow-none w-100"
                aria-label="Default select example"
                // onClick={getCitiesHandle}
                name="citySelectHandle"
                onChange={(e) =>
                  props.formik.setValues((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      city: e.target.value,
                    },
                  }))
                }
              >
                <option value="">
                  {props.formik.values.location?.city || " انتخاب کنید"}
                </option>
                {props.formik.values?.cities?.map((city, i) => (
                  <option key={i} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <FormikErrRender formik={props.formik} field="location.city" />

            {props.formik.values.location?.municipality?.length > 0 && (
              <div className="col mb-2 mx-3 w-100">
                <label className="form-label required m-0">محله</label>
                <br />
                <select
                  className="login-btn  mt-0 selectpicker shadow-none w-100"
                  aria-label="Default select example"
                  name="municipalitySelect"
                >
                  <option value="">
                    {props.formik.values.location?.municipality ||
                      " انتخاب کنید"}
                  </option>
                </select>
              </div>
            )}
          </div>
          <div className="">
            <div className="mb-3">
              <label
                className={`form-label mt-3 mb-2 ${
                  !props.formik.values.suggestMode &&
                  props?.section === "business" &&
                  "required"
                } `}
                htmlFor="f10"
              >
                شماره (پلاک )
              </label>

              <input
                type="text"
                autoComplete="off"
                className="form-control py-1  box3 w-50"
                id="f10"
                // onBlur={e=> console.log(e.target.value)}
                // {...props.formik.getFieldProps("location.streetNumber")}
                defaultValue={props.formik.values.location?.streetNumber}
                onBlur={(e) =>
                  props.formik.setValues((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      streetNumber: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <FormikErrRender
              formik={props.formik}
              field="location.streetNumber"
            />

            <div className="mb-3">
              <label
                className={`form-label mt-3 mb-2 ${
                  !props.formik.values.suggestMode &&
                  props?.section === "business" &&
                  "required"
                } `}
                htmlFor="f11"
              >
                خیابان
              </label>

              <input
                type="input"
                className="form-control py-1  box3 w-50"
                id="f11"
                defaultValue={props.formik.values?.location?.route}
                onBlur={(e) =>
                  props.formik.setValues((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      route: e.target.value,
                    },
                  }))
                }
              />
              <FormikErrRender formik={props.formik} field="location.route" />
            </div>

            <div className="mb-3">
              <label className="form-label mt-2 mb-1" htmlFor="f12">
                شماره واحد یا آپارتمان
              </label>

              <input
                type="text"
                autoComplete="off"
                className="form-control py-1  box3 w-50"
                id="f12"
                defaultValue={props.formik.values.location?.number}
                onBlur={(e) =>
                  props.formik.setValues((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      number: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <FormikErrRender formik={props.formik} field="location.number" />
          </div>

          <div className="mb-3">
            <label
              className={`form-label mt-3 mb-2 ${
                !props.formik.values.suggestMode &&
                props?.section === "business" &&
                "required"
              } `}
              htmlFor="f13"
            >
              کدپستی
            </label>

            <input
              type="text"
              autoComplete="off"
              className="form-control py-1  box3 w-50"
              id="f13"
              defaultValue={props.formik.values.location?.postalCode}
              onBlur={(e) =>
                props.formik.setValues((prev) => ({
                  ...prev,
                  location: {
                    ...prev.location,
                    postalCode: e.target.value,
                  },
                }))
              }
            />
            <FormikErrRender
              formik={props.formik}
              field="location.postalCode"
            />
          </div>
          {/* <div className="mb-3">
          <label className="form-label mt-3 mb-2 required" htmlFor="#">
            
            مختصات جغرافیایی
          </label>
          <br />
          <label className="form-label mt-3 mb-2 required" htmlFor="f14">
            طول
          </label>
          <input
            type="text"
            className="form-control py-1  box3 w-50"
            id="f14"
            defaultValue={props.formik.values.location?.lat}
            onBlur={(e) =>
              props.formik.setValues((prev) => ({
                ...prev,
                location: {
                  ...prev.location,
                  lat: e.target.value,
                },
              }))
            }
          />

          <label className="form-label mt-3 mb-2 required" htmlFor="f15">
            
            عرض
          </label>
          <input
            type="text"
            className="form-control py-1  box3 w-50"
            id="f15"
            defaultValue={props.formik.values.location?.lng}
            onBlur={(e) =>
              props.formik.setValues((prev) => ({
                ...prev,
                location: {
                  ...prev.location,
                  lng: e.target.value,
                },
              }))
            }
          />
        </div> */}

          {props?.section === "business" && (
            <div className="form-check form-switch mt-3 py-0 px-1">
              <input
                className="form-check-input form-check-input-filter1  m-0 p-0"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                defaultChecked={props.formik.values.location?.displayAddress}
                onChange={(e) =>
                  props.formik.setValues((prev) => ({
                    ...prev,
                    location: {
                      ...prev.location,
                      displayAddress:
                      !prev.location?.displayAddress 
                    },
                  }))
                }
              />
              <label
                className="form-check-label px-2 mt-0"
                htmlFor="flexSwitchCheckChecked"
              >
                نشانی در وبسایت به صورت عمومی نمایش داده شود!
              </label>
            </div>
          )}
        </div>
      )}
      <hr />
    </div>
  );

  async function mainCountrySelectHandle(e) {
    e.preventDefault();
    const countryInfo = countryStates.find(
      (count) => count.countryShort == e.target.value
    );

    props.formik.setValues((prev) => ({
      ...prev,
      location: {
        ...prev.location,
        country: regionNames.of(e.target.value),
        countryShort: e.target.value,
        state: "",
        stateShort: "",
        city: "",
      },
      states: countryInfo.states,
      cities: [],
    }));
  }

  async function mainStateSelectedHandle(e) {
    e.preventDefault();
    // console.log(e.target.value);
    const parts = e.target.value.split(",");
    const stateIso = parts[0];
    const state = parts[1];

    const response = await api.getCities(
      props.formik.values.location?.countryShort,
      stateIso
    );
    if (response.error) return console.log(response.error);

    props.formik.setValues((prev) => ({
      ...prev,
      cities: response.data,
      location: {
        ...prev.location,
        state: state,
        stateShort: stateIso,
        city: "",
      },
    }));

    // setOptions((prev) => ({
    //   ...prev,
    //   // stateSel:stateIso,
    //   cities: response.data,
    // }));
  }
}
