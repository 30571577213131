import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { useContext, useRef } from "react";
import { CartContext } from "../../../contexts/cartContext";
import tools from "../../../../utils/tools";
import ToastNote from "../../../golbal/notifications/toastNote";

export default function TutorsGuide() {
  const { user } = useContext(CartContext);
  const messageRef = useRef(null);
  return (
    <div className="d-flex align-items-start ">
      <ToastNote messageRef={messageRef} />
      <div className="accordion w-100" id="accordionPanelsStayOpenExample4">
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-register`}
            aria-controls={`open-col-register`}
          >
            <div> + چگونه پروفایل تدریس خودم رو کامل کنم؟ </div>
          </div>
          <div
            className="accordion-collapse collapse "
            id={`open-col-register`}
          >
            <div>
              <div>
                <div className="fm1">
                  <div>
                    ابتدا باید در متامارس بعنوان کاربر ثبت نام کنید.
                    {!user?.roles?.includes(9999) && (
                      <strong
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalToggle"
                        role="button"
                      >
                        (اینجا)
                      </strong>
                    )}
                  </div>

                  <span>
                    پس از ثبت نام به عنوان کاربر، پروفایل تدریس خودتون رو از
                    قسمت
                  </span>
                  <span
                    onClick={(e) => {
                      if (!window.logined(9999,"tutor")) return;
                      window.navigateHandle(
                        `/${window.lcs}/dashboard/user/demy/registertutor`
                      );
                    }}
                    // href={`/${window.lcs}/dashboard/user/demy/registertutor`}
                    className="content__link bold-700 cursor"
                  >
                    <strong>(ثبت‌نام استاد)</strong>
                  </span>
                  <span> کامل کنید .</span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                مراحل تکمیل پروفایل استاد:
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    فرایند کلی ثبت‌نام در سایت به صورت مراحل زیر است:
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱
                </div>
                <span>واردکردن نامتون جهت نمایش به کاربران فارسی زبان</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۲
                </div>
                <span>
                  {" "}
                  بارگذاری تصویر پروفایل اجباری نمی‌باشد ولی در صورت بارگذاری
                  بایستی تک نفره و مربوط به خود استاد باشد و غیر واقعی نباشد.
                </span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۳
                </div>
                <span>انتخاب دسته بندی اصلی</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۴
                </div>
                <span>
                  انتخاب مقطع و نام دروسی که تدریس می کنین متناسب با دسته بندی
                  اصلی (اگه درس مورد نظرتون رو پیدا نکردید می‌تونید با کلیک روی
                  <strong> درخواست درس جدید </strong>، درس مورد نظر را وارد و
                  درنهایت دکمه <strong> اضافه کردن به لیست </strong> را بزنید.)
                </span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۵
                </div>
                <span>
                  در صورتی که به گروه‌های سنی خاصی (خردسالان، کودکان، نوجوانان
                  یا بزرگسالان) تدریس می‌کنید، از بین گز‌ینه‌های مختلف انتخاب
                  کنید.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۶
                </div>
                <span>انتخاب نوع کلاس: حضوری یا آنلاین</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۷
                </div>
                <span>
                  تعین نرخ تدریس و ارز مورد درخواست براساس کشور خودتون
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۸
                </div>
                <span>
                  زبانی که می‌تونید به اون زبان تدریس کنید رو با میزان مهارت و
                  سطح زبانتون وارد کنید. ‌با فشردن دکمه + می‌تونید زبان‌های
                  بیشتری وارد کنید.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۹
                </div>
                <span>
                  انتخاب کلید واژه های مناسب جهت پیدا کردن راحت تر شما و جداکردن
                  آنها از یکدیگر با علامت +
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۰
                </div>
                <span>پرکردن قسمتهای «تحصیلات» «تجربه» «درباره من»</span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۱
                </div>
                <span>
                  «معرفی مختصر» : این متن در کارت معرفی شما در قسمت لیست اساتید
                  نمایش داده می‌شود.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۲
                </div>
                <span>
                  انتخاب برنامه کلاسی
                  <div>
                    برنامه کلاسی شما تنها برای یک هفته تنظیم و سپس به صورت هفتگی
                    به دانشجویان نمایش داده خواهد شد و تا زمانیکه که نیاز به
                    تغییر یا توقف آن نداشته باشید به صورت اتوماتیک تمدید خواهد
                    شد.
                  </div>
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۳
                </div>
                <span>
                  اگر تمایل به برگزاری جلسه‌ی اول به صورت آزمایشی و رایگان دارید
                  این گزینه رو انتخاب کنید. هدف از جلسه آزمایشی بیشتر آشنایی
                  استاد و دانشجوست و مدت اون نیم ساعت خواهد بود.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۴
                </div>
                <span>
                  آدرس ایمیل خودتون رو وارد کنید. از این آدرس برای مکاتبه با شما
                  و اطلاع رسانی در مورد کلاس‌های رزرو شده و ایمیل های یاددآوری
                  شروع کلاس استفاده می گردد و در اختیار دانشجویان قرار نخواهد
                  گرفت.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱۵
                </div>
                <span>
                  در قسمت ویدئو لینک ویدئوی معرفی خود در یوتیوب را بارگذاری کنید
                  تا دانشجویان بتوانند با شما و روش تدریس تون بیشتر آشنا بشوند.
                  <div>
                    توجه داشته باشید که بارگذاری ویدیو اجباری نیست ولی داشتن
                    ویدیو به دو دلیل باعث تقویت پروفایل شما میشه: ۱- آشنایی و
                    شناخت بیشتر شما پیش از رزرو جلسه آزمایشی و آموزشی توسط
                    دانشجو، ۲- قرار گرفتن در اولویت بالاتر در هنگام جستجو در
                    لیست اساتید.
                  </div>
                </span>
              </div>
            </div>
            <div>
              <div>
                <div className="">
                  <p className="fm1 bg-beigeYellow">
                    <strong>
                      💡 برای شروع به تدریس آنلاین در متامارس داشتن اجازه کار و
                      حداقل سن قانونی برای تدریس طبق قوانین کشور محل سکونت و
                      همچنین حداقل امکانات و ابزارها مثل اینترنت پرسرعت، لپ‌تاپ
                      و هدفون مناسب لازم و ضروری است و رعایت الزامات آن بر عهده
                      اساتید می‌باشد. هرگونه عواقب ناشی از عدم رعایت این موارد
                      به عهده استاد می‌باشد. متامارس نیز ممکن است به صورت
                      دوره‌ای مقررات مورد اشاره را کنترل و ارزیابی نماید.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-metaCredit`}
            aria-controls={`open-col-metaCredit`}
            aria-labelledby="open-col-metaCredit-tab"
          >
            <div>+ هدیه خوش آمدگویی متامارس چیست ؟</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-metaCredit">
            <div>
              در متامارس اساتید در زمان ثبت نام مبلغ سی دلار اعتبار بعنوان هدیه
              خوش آمد گویی دریافت می نمایند. شما میتوانید موجودی اعتبار هدیه خود
              را
              <span
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (!user?.roles?.includes(1505))
                    return messageRef.current.showError({
                      message:
                        "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                    });
                  window.navigateHandle(
                    `/${window.lcs}/dashboard/user/demy/myTutorlist`
                  );
                }}
                // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                className="content__link bold-700 cursor"
              >
                <strong> (اینجا) </strong>
              </span>
              ببینید.
            </div>
            <div>
              {" "}
              مانده اعتبار هدیه متامارس پس از تدریس ۵۰۰ ساعت جلسه آموزشی قابل
              برداشت می‌باشد.
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-afterRegister`}
            aria-controls={`open-col-afterRegister`}
            aria-labelledby="open-col-afterRegister-tab"
          >
            <div> + بعد از ثبت‌نام استاد چیکار کنم ؟ </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-afterRegister"
          >
            <div>
              <p className="content__title fm1">
                متامارس از یک سیستم هوشمند برای ثبت نام و فعالسازی استفاده می
                کند و اساتید جدید بعد از گذراندن مراحل ثبت‌نام به صورت اتوماتیک
                ارزیابی و وارد لیست نمایش اساتید میشوند و به تدریج و با میزان
                تعامل با دانشجوها جایگاه بهتری را بدست می آورند و کاربران میتونن
                باتوجه به برنامه کلاسی شما، کلاس‌های مورد نیازشون رو ثبت نام
                کنند.
              </p>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    💡 توجه داشته باشید هرچقدر پروفایل تدریس شما کامل تر و حرفه
                    ای باشه سیستم اون رو بالاتر نمایش و همچنین به دانشجوهای
                    بیشتری کمک میکنه که از پروفایل شما کلاس‌های خودشون رو انتخاب
                    کنند.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            // aria-expanded="true"
            data-bs-target={`#open-col-interview`}
            aria-controls={`open-col-interview`}
            aria-labelledby="open-col-interview-tab"
          >
            <div> + آیا قبل از فعالسازی نیاز هست که مصاحبه‌ ای کنم ؟</div>
          </div>

          <div className="accordion-collapse collapse" id="open-col-interview">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در متامارس بنا بر اظهار صادقانه و واقع بینانه‌ی اساتید از
                    توانایی های خودشون از یکسو و از سوی دیگر توانایی دانشجوها در
                    انتخاب استاد باتوجه به نیازهای خودشون و با استفاده از
                    اطلاعات ارائه شده توسط خود استاد، دیدگاه‌های سایر دانشجویان
                    و ویدئوی معرفی و جلسه آموزشی رایگان می باشد، با این حال در
                    متامارس پروفایل اساتید همواره توسط هوش مصنوعی پایش و گزارش
                    آن به صورت روزانه برای تیم پشتیبانی فرستاده می‌شود. و در
                    صورت مشاهده هرگونه مغایرت با اهداف آموزشی و قوانین متامارس،
                    پروفایل های مشکوک به صورت موقت غیرفعال یا حذف می گردند.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-conditions`}
            aria-controls={`open-col-conditions`}
            aria-labelledby="open-col-conditions-tab"
          >
            <div> + لازمه همکاری اساتید با متامارس چیست؟</div>
          </div>

          <div className="accordion-collapse collapse" id="open-col-conditions">
            <div>
              <div>
                <div className="">
                  <p className="fm1">
                    تعهد، دانش، تخصص کافی، توانایی انتقال مفاهیم و تجربه لازم در
                    زمینه مورد نظر تدریس
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    داشتن اجازه کار و حداقل سن قانونی برای کار بر اساس قوانین
                    کشوری و استانی محل سکونت.
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    داشتن بستر سخت افزاری و نرم افزاری مناسب و اینترنت پایدار و
                    باکیفیت برای برگزاری جلسات آنلاین
                  </p>
                </div>
                <div className="">
                  <p className="fm1">
                    برای کلاس‌های آنلاین، دسترسی به پلتفرم های برگزاری
                    نشست(میتینگ) و ترجیحات بدون فیلتر.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-completedProfile`}
            aria-controls={`open-col-completedProfile`}
            aria-labelledby="open-col-completedProfile-tab"
          >
            <div> + چرا پروفایل(های) خودم رو باید به صورت کامل به روز نگه دارم ؟ </div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-completedProfile"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1"></p>
                </div>
                <div className="fm1">
                  <span>
                    تهیه یک پروفایل به روز و کامل همیشه دارای 
                    داشتن یک پروفایل کامل بسیار حائز اهمیت است، زیرا مخاطبین بعد
                    از ورود به سایت،
                  </span>
                  <a
                    href={`/${window.lcs}/demy/tutor/filters`}
                    className="content__link bold-700"
                  >
                    لیست اساتید
                  </a>
                  <span>
                    را مشاهده و پروفایل آنها را با هم مقایسه می‌کنند، پس هرچقدر
                    پروفایل شما با کیفیت بهتری ساخته شده باشد، برای مخاطبین
                    قابل‌اعتمادتر خواهد بود.
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    منظور از پروفایل کامل یعنی علاوه بر تکمیل بودن موارد اجباری،
                    موارد اختیاری را هم به دقت تکمیل نمائید از جمله موارد زیر :
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">1. عکس پروفایل مناسب</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">2. برنامه کلاسی به روز</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">3. ویدئو معرفی با کیفیت و مناسب</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">4.انتخاب کلمات کلیدی مناسب</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-uploadPhoto`}
            aria-controls={`open-col-uploadPhoto`}
            aria-labelledby="open-col-uploadPhoto-tab"
          >
            <div>
              
              + برای آپلود عکس پروفایل مناسب چه نکاتی رو باید رعایت کنیم؟
            </div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-uploadPhoto"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    عکس پروفایل شما یکی از مهم‌ترین بخش‌های جذب دانشجو است. پس
                    سعی کنید بهترین عکس را برای پروفایل‌ خود انتخاب کنید. عکسی
                    کاملاً واضح که ترجیحاً فقط صورتتان را نشان دهد.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    از عکس‌های تار و بی کیفیت استفاده نکنید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر بک‌گراند تصویرتان شلوغ نباشد حس خیلی بهتری به کاربر
                    منتقل می‌شود و تمرکز فقط روی شماست.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    عکس فقط از خود شما باشد و شخص دیگری در عکس حضور نداشته باشد.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="">
                  <p className="fm1">
                    بهتر است در نور شب عکس نیندازید و یا عکس‌هایی که فیلتر تاریک
                    و یا تار دارند را انتخاب نکنید.
                  </p>
                </div>
                <div className="flex column-flex"></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-VideoContent`}
            aria-controls={`open-col-VideoContent`}
            aria-labelledby="open-col-VideoContent-tab"
          >
            <div> + چرا داشتن ویدئوی معرفی مهم است ؟</div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-VideoContent"
          >
            <div>
              <p className="content__title fm1">
                ویدئو معرفی مهمترین ابزار شما برای انتقال سطح دانش خودتون و فضای
                کلی کلاس‌های شماست و به دانشجویان کمک می‌کند که قبل از رزو کلاس
                آزمایشی یا آموزشی، چند دقیقه دانشجوی شما بودن رو تجربه کنن و
                شناخت بهتری از شما بدست بیاورند. پس مهم است که ویدئو معرفی خوبی
                از خودتون تهیه و در اختیار دانشجویان قرار بدین تا به صورت
                هوشمندانه هم نظر دانشجویان بیشتری رو به سمت خودتون جلب و هم در
                زمان خود و دانشجویانتون صرفه جویی کنید.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed"
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-technicalVideo`}
            aria-controls={`open-col-technicalVideo`}
            aria-labelledby="open-col-technicalVideo-tab"
          >
            <div> + چه نکاتی رو در تهیه ویدئو رعایت کنم ؟</div>
          </div>

          <div
            className="accordion-collapse collapse"
            id="open-col-technicalVideo"
          >
            <div>
              <div className="boolet-point">
                <p className="fm1">خودتان در ویدئو حضور داشته باشید.</p>
              </div>
            </div>
            <div>
              <div className="boolet-point">
                <p className="fm1">مدت‌زمان ویدئو بین ۱ تا ۳ دقیقه باشد.</p>
              </div>
            </div>

            <div>
              <div className="boolet-point">
                <p className="fm1">
                  از بیان راه‌های ارتباط مستقیم خودداری کنید.
                </p>
              </div>
            </div>
            <div>
              <div className="boolet-point">
                <p className="fm1">ویدئو را حتما به صورت افقی تهیه کنید . </p>
              </div>
              <div className="flex column-flex"></div>
            </div>

            <div>
              <div>
                <p className="fm1">
                  قبل از شروع به ضبط ویدئو بهتر است که از یک چک لیست یا متن
                  استفاده نمائید و سعی کنید که نکات آنرا به خاطر بسپارید و در
                  زمان ضبط ویدئو مطابق آن پیش برید، بدون اینکه نیازی به مراجعه
                  دوباره به آن در طول تهیه ویدئو داشته باشید و سعی کنید در صورت
                  امکان موارد زیر را در آن لحاظ کنید :
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۱
                </div>
                <span>نام و نام‌خانوادگی، سوابق تحصیلی و تجربیات .</span>
              </div>

              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  2
                </div>
                <span>
                  شیوه تدریس و احتمالا دلایلی که شما را به تدریس علاقه مند کرده
                  است.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۳
                </div>
                <span>
                  سعی کنید که خودتان باشید و طبیعی رفتار کنید و لبخند بر لب
                  داشته باشید و خیلی خشک نباشید (دانشجویان با استاد خیلی خشک و
                  جدی راحت نیستن) .
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۴
                </div>
                <span>
                  سعی کنید به دانشجویان نشان دهید که آنها را درک میکنید و متوجه
                  نیازهایشان هستین.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۵
                </div>
                <span>
                  به کلاس آزمایشی رایگان خود اشاره کنید و آنها را راهنمایی کنید
                  که چگونه با شما کلاس آزمایشی رزرو کنند.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۶
                </div>
                <span>
                  اگر توانایی و تجربه آموزش به گروهای خاصی را دارید به آن اشاره
                  کنید، مثلا کودکان یا نوجوانان وبخشی از ویدئو را هم به صحبت با
                  آن‌ها اختصاص دهید.
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۷
                </div>
                <span>
                  اگر تخصص در یک زمینه خاص دارید میتوانید به آن هم اشاره
                  کنید،مثلا آزمون های ورودی موسسات یا دانشگاه هاو...
                </span>
              </div>
              <div className="d-flex my-md-4  my-2">
                <div className="items-count bg-greenGray text-beigeYellow mx-2 mt-1">
                  ۲
                </div>
                <span>
                  اگر فکر میکنید که به روش خاصی تدریس میکنید و ممکن است برای
                  دانشجویان جذاب باشد، میتواند آنرا در چند دقیقه تدریس بیان
                  کنید.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-introduction`}
            aria-controls={`open-col-introduction`}
            aria-labelledby="open-col-introduction-tab"
          >
            <div>
              + چرا باید بخش «درباره‌ی من» را بنویسم؟ نکات مهم «درباره من»
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-introduction"
          >
            <div>
              <p className="content__title fm1">
                توجه داشته باشید که این بخش هم توجه بسیاری از کاربران را جلب
                می‌کند و ممکن است کاربران بدون دیدن ویدئو معرفی شما و با خواندن
                این متن، تصمیم بگیرند که با شما کلاس داشته باشند.
              </p>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                در متن بیو چه اطلاعاتی رو باید بنویسم؟
              </h2>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    درباره تجربیات و مهارت‌های قابل تدریس و مدارکی که دارید
                    توضیح بدید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    تکنیک‌ها و روش‌های منحصربه‌فردی که در تدریس استفاده می‌کنید
                    را توضیح بدید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    توضیح بدید که دنبال چه دانشجویانی هستید از نظر رنج سنی و سطح
                    و به چه افرادی تدریس کرده‌اید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر جلسه آزمایشی‌ شما رایگان می‌باشد، سعی کنید توضیح مختصری
                    داشته باشید و در اون توضیح اعلام کنید که در این جلسه قصد
                    دارید به چه موضوعاتی بپردازید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگر لازمه دانشجویان قبل از رزرو کلاس با شما، پیش‌نیازی را
                    بدانند، حتماً از این پیش‌نیاز مطلع‌شان کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                در متن بیو چه اطلاعاتی رو نباید بنویسید:
              </h2>
              <div>
                <div className="boolet-point">
                  <p className="fm1">اطلاعات تماس شخصی</p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    معرفی پیج و یا سروریس‌هایی به جز متامارس
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">تبلیغ سرویس‌ها و پلتفرم‌های دیگه</p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    توجه داشته باشید اگر فقط به گروه خاصی از دانشجویان تدریس
                    می‌کنید، حتما این نکته رو هم در قسمت درباره‌من بنویسید تا
                    دانشجویانی که در نظر دارید راحت‌تر بتوانند پروفایل شما را
                    پیدا کنند. و هر چقدر این متن دقیق نوشته شود، کاربران بیشتر
                    ترغیب خواهند شد که ویدئوی معرفی شما را ببینند و یا یک جلسه‌ی
                    رایگان با شما رزرو کنند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ویرایش پروفایل
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    برای به روز رسانی و ویرایش پروفایل خود کافیست تنها به صفحه
                    خودتون برین و دکمه ویرایش رو بزنید و در این صورت میتونین
                    تمام اطلاعات خودتون رو به صورت آنلاین به روز کنین.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorGrading`}
            aria-controls={`open-col-tutorGrading`}
            aria-labelledby="open-col-tutorGrading-tab"
          >
            <div>
              + اساتید چگونه ارزیابی و در لیست اساتید نمایش داده می شوند ؟
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorGrading"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    <div>
                      اساتید در متامارس بر اساس پارامترهای گوناگون ارزیابی و
                      امتیازاتی بدست می آورند که جایگاه نمایش آنها رو در لیست
                      اساتید مشخص می نماید. این ارزیابی به صورت روزانه انجام و
                      جایگاه نمایشی هر استاد را مشخص می نماید.
                    </div>
                    <div>
                      پارامترهای رتبه‌بندی را در زیر می‌توانید مشاهده کنید:
                    </div>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                پارامترهای فعالیت
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    <div>میزان تعهد :</div>
                    به معنای میزان تعهد شما به برگزاری کلاس ها می باشد و عدد آن
                    بر اساس :
                    <div>
                      تعداد کلاس‌های رزرو(آزمایشی و آموزشی)، تائید، برگزار یا
                      کنسل شده .
                    </div>
                    <div
                      className="cursor"
                      onClick={(e) => {
                        const elementToOpen = document.getElementById(
                          "open-col-tutorWallet"
                        )?.previousElementSibling;
                        if (elementToOpen.classList.contains("collapsed")) {
                          elementToOpen.click();
                        }
                        setTimeout(() => {
                          tools.scrollToAnchor("open-col-tutorWallet");
                        }, 100);
                      }}
                    >
                      (اطلاعات بیشتر)
                    </div>
                  </p>
                </div>
              </div>
              <div className="">
                <p className="fm1">
                  تعداد دانشجویانی که پس از کلاس آزمایشی با شما کلاس آموزشی
                  برگزار می نمایند.
                </p>
              </div>
              <div className="">
                <p className="fm1">
                  تعداد دانشجویانی که کلاس‌های خود را با شما تکرار می کنند.
                </p>
              </div>
              <div className="">
                <p className="fm1">تعداد دیدگاه ها دریافتی از دانشجویان</p>
              </div>
              <div className="">
                <p className="fm1">امتیاز دریافتی از دانشجویان</p>
              </div>
              <div className="">
                <p className="fm1">
                  به روز رسانی تقویم آموزشی و داشتن زمان های آموزشی بیشتر در
                  برنامه زمانبندی کلاس ها.
                </p>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                جلسه آزمایشی رایگان
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    فعال بودن جلسه آزمایشی موجب دریافت امتیاز این بخش و همچنین
                    ترغیب بیشتر دانشجویان جهت رزو جلسات با شما خواهد شد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                اساتید جدید
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    اساتیدی که تازه ثبت نام نموده اند تا ۴ ماه مورد حمایت
                    متامارس می باشند و به ترتیب در ۲۰ روز ابتدایی بیشترین امتیاز
                    حمایتی و پس از هر ماه و تا چهار ماه مقداری از میزان حمایت
                    آنها کمتر می گردد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ویدئوی یوتویوب
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    اساتیدی که ویدئوی معرفی یوتویوب خودرا بارگذاری نمایند امتیاز
                    بالای این بخش را دریافت و پروفایل خود را بالاتر از سایر
                    اساتید فاقد ویدئو، در لیست اساتید نمایش می دهند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                عکس پروفایل تدریس
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    بارگذاری عکس پروفایل دارای امتیاز می باشد و پیشنهاد می‌گردد
                    که اساتید آنرا بارگذاری نمایند تا پروفایل خود را نسبت به
                    سایر استادها برجسته و امتیاز این بخش را نیز دریافت نمایند.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-notReturnedStudents`}
            aria-controls={`open-col-notReturnedStudents`}
            aria-labelledby="open-col-notReturnedStudents-tab"
          >
            <div>چرا دانشجوهای من دوباره کلاس را رزرو نمی‌کنند؟ </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-notReturnedStudents"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    برای برگزاری جلسات باکیفیت‌تر، بهتره با نیاز کاربران آشنایی
                    بیشتری داشته باشید. بعد از جلسات آزمایشی و آموزشی، برخی از
                    مشکلات کاربران عبارتند از.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۱. استاد نیاز من را درک نمی‌کند و به اصرار با برنامه‌ خودش جلو
                میره.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در کلاس‌های خصوصی، بیشتر دانشجوها تمایل دارند که استاد
                    برنامه کلاس را با توجه به نیازهای شخصی آن‌ها تنظیم کند. با
                    این رویکرد شخصی، انگیزه و علاقه دانشجو بیشتر خواهد شد.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    💡 بعد از اینکه کلاس آزمایشی با شما رزرو شد، در ابتدای جلسه
                    سعی کنید سؤالاتی از دانشجو در مورد سطح زبان، دلیل یادگیری و
                    سوابق قبلی دانشجویی او بپرسید و با توجه به جواب‌هایی که از
                    دانشجو می‌گیرید سعی کنید ضمن اعتماد به جواب‌ها، تصمیمات بعدی
                    را درمورد کلاس بگیرید. اگر در این جلسه متوجه شدید به هر
                    دلیلی قادر به کمک به دانشجو نیستید، از دانشجو خواهش کنید با
                    دیگر اساتید یادگیری خود را ادامه دهد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۲. مشکل اینترنت وجود داره و استاد تلاشی برای حل آن نمی‌کند.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    همیشه قبل از شروع کلاس، اطمینان حاصل کنید که اتصال اینترنت
                    شما پایدار است و سعی کنید تمام مشکلات ممکن و راه‌حل‌های
                    احتمالی را پیش‌بینی کنید. اما اگر به هر دلیلی در طول کلاس با
                    مشکل روبرو شدید و امکان ادامه کلاس وجود نداشت، حتماً در
                    اولین فرصت، جلسه جبرانی را برگزار کنید.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۵. استاد قیمت کلاس هاشو خیلی افزایش داده.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    توجه داشته باشید دانشجویان فعلی شما نباید به صورت ناگهانی با
                    افزایش قیمت از سمت شما روبه‌رو شوند. در این صورت ممکن است
                    تمایل‌ آن‌ها به ادامه یادگیری از بین برود. افزایش قیمت باید
                    به صورت پلکانی و کم‌کم باشد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    یکی از روشهای قیمت گذاری این است که ابتدا با قیمت پایین‌تر
                    شروع کنید و به مرور با افزایش تعداد دانشجویان و شناخته تر
                    شدن شما و ایجاد اعتماد و پر شدن زمان تدریس شما قیمت را با
                    اطلاع رسانی قبلی به دانشجویان فعلی خود و به مرور افزایش
                    دهید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۶. استاد به وقت من احترام نمیذاره و دیر میاد و عذرخواهی نمیکنه و
                گاها فراموش می‌کنه کلاس داریم.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در صورت دیر رسیدن به کلاس حتماً از دانشجو عذرخواهی کنید و
                    سعی کنید زمان تلف شده را در انتهای کلاس جبران کنید.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    یکساعت قبل از شروع کلاس، ایمیل یادآوری برای شما و دانشجو
                    فرستاده می‌شود؛ اما توصیه می‌کنیم که حتماً برای شروع کلاس‌ها
                    بر روی تلفن همراه خود هم یادآور تنظیم کنید. فراموشی کلاس
                    توسط استاد باعث بی‌اعتمادی کاربران خواهد شد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۷. سروصدای اتاق استاد بسیار زیاد است و باعث پرت شدن تمرکز من
                می‌شود.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    دانشجوها نیازمند تمرکز هستند و معمولاً فضای اطراف استاد برای
                    آن‌ها توجه زیادی به خود جلب می‌کند. بنابراین، سعی کنید کلاس
                    خود را در یک محیط کاملاً آرام و ساکت برگزار کنید، و اگر
                    دوربین شما در طول کلاس روشن است، مکانی را برای برگزاری کلاس
                    انتخاب کنید که شلوغی در آن محدود شده باشد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                ۸. استاد به‌طور کامل برای تدریس آماده نمی‌شود.
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    همواره مناسب است که برای هر کلاس، جزوه و نکات مورد نیاز را
                    از قبل آماده کنید. اشتراک‌گذاری صفحه در طول تدریس نیز
                    می‌تواند به افزایش تمرکز دانشجویان کمک کند.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-loyalStudents`}
            aria-controls={`open-col-loyalStudents`}
          >
            <div>چطوری دانشجو هام رو نگه دارم؟ </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-loyalStudents"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در اولین جلسه، باید به دانشجو احساسی داده شود که موفق شده
                    است و هزینه‌ای که پرداخت می‌کند، مفید و ضروری است.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۲. دانشجو باید اطمینان حاصل کند که در پایان هر جلسه، مطالب
                    مفیدی دریافت می‌کند و اطلاعاتی درباره مطالبی که در جلسات
                    آینده قرار است یاد بگیرد دریافت می‌کند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۳. قبل از شروع جلسات به دانشجویان توضیح دهید که اهمیت بسیار
                    زیادی برای فعالیت آنها در کلاس قائل هستید .
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۴. همیشه قبل از شروع کلاس به تمامی پیام‌هایی که دانشجویان
                    برای شما فرستاده‌اند، پاسخ دهید. و خود را متعهد به دانشجویان
                    بدانید
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-qualitySessions`}
            aria-controls={`open-col-qualitySessions`}
            aria-labelledby="open-col-qualitySessions-tab"
          >
            <div>چطور میتونم جلسات آزمایشی مفیدتری برگزار کنم؟</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-qualitySessions"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    جلسات آزمایشی، برای آشنایی استاد با دانشجویان است و این جلسه
                    برای کاربران اهمیت بسیار بالایی دارد، چون کاربران پس از این
                    جلسه نسبت به انتخاب استاد خود اقدام می‌کنند. رعایت برخی
                    نکات، در برگزاری بهتر این جلسه مؤثر خواهد بود.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۱. دانشجو از اولین برخورد و اولین جلسه باید حس کنند شما
                    مناسب ترین فردی هستید که می‌تواند به او آموزش دهد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۲. کیفیت تدریس و آموزش استاد به‌تنهایی نمی‌تواند دانشجو را
                    قانع کند که شما بهترین گزینه هستید. شما باید بهترین راه را
                    برای انتقال اطلاعات به هر دانشجو انتخاب و توجه لازم برای
                    کلاس را در دانشجو به وجود بیاورید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۳. جلسات آزمایشی بسیار با اهمیت هستند و باید با برنامه وارد
                    کلاس شوید. باید آمادگی داشته باشید که اگر با یک دانشجو ضعیف
                    یا قوی مواجه شدید چه رویکردی را در پیش بگیرید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۴. در اولین جلسه چیزی برای تدریس داشته باشید. جوری که دانشجو
                    برای یادگرفتن و ادامه مسیر با شما مشتاق شود و تصمیم بگیرد
                    جلساتش را ادامه دهد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۵. در ذهن خود چندین جلسه آزمایشی رو تصور کنید و با توجه به
                    نیاز هر دانشجو و طبق موضوعی که از قبل در ذهن‌ خودتان آماده
                    کردید، عمل کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۶. هیچ دانشجویی از جلسات خشک و خیلی جدی خوشش نمیاد به همین
                    منظور سعی کنید جلسات‌ رو طوری برگزار کنید که دانشجو بدونه با
                    استاد خیلی جدی و جلسات خیلی خشک مواجه نیست و میتواند به
                    راحتی با استاد خود ارتباط برقرار کند و مشکلاتش را مطرح کند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    ۷. سعی داشته باشید در جلسه آزمایشی چند نکته جدید و جالب برای
                    تدریس داشته باشید. مخصوصاً نکاتی که می‌دونید اساتید زیادی به
                    آموزش اون نکات نمی‌پردازند. حالا این نکات میتونه چند لغت
                    جدید یا چند نکته گرامری باشه و یا هر نکته جذابی که خودتون
                    صلاح می‌دونید.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-dicipline`}
            aria-controls={`open-col-dicipline`}
            aria-labelledby="open-col-dicipline-tab"
          >
            <div>چطور کلاس را مدیریت کنم ؟</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-dicipline">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    به صورت کلی قوانینی که باید در کلاس رعایت شود رو استاد و
                    دانشجو با هم و به صورت توافقی تعیین می‌کنند؛ اما رعایت بعضی
                    نکات خاص می‌تواند به بهتر شدن روند برگزاری کلاس کمک کند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    اگه قانونی رو برای کلاس‌تون تعیین کردید حتماً دانشجو رو در
                    جریان بگذارید و پایبند به قوانین باشید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    بدون اعمال سختگیری بیش از حد، برای فرستادن تکالیف زمان‌بندی
                    مناسبی تعریف کنید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    سعی کنید در کلاس به صورت آراسته و با لباس مناسب ظاهر شوید و
                    محیط اطراف خود را مرتب نگه دارید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    در مورد روشن یا خاموش بودن وبکم هم میتوانید با دانشجو صحبت و
                    با توجه به نیاز کلاس، ترجیح خود و دانشجو تصمیم مشترک بگیرید.
                  </p>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-freeSessions`}
            aria-controls={`open-col-freeSessions`}
            aria-labelledby="open-col-freeSessions-tab"
            id="first-session-rules"
          >
            <div>+ یک کلاس باهام رزرو شده، چه کاری باید انجام بدم؟</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-freeSessions"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    دانشجویان انتظار دارند که استادها متعهد باشند و به موقع در
                    کلاس ها حضور داشته باشند.
                    <div></div>
                    برای دانشجویان، این بسیار نا امید‌کننده است که استاد در
                    اولین جلسه حاضر نشود. عدم حضور در اولین جلسه نه تنها برای
                    خود استاد بد است، بلکه به جایگاه سایر استادهای متامارس آسیب
                    می رساند.
                  </p>
                  این امر به خصوص برای دروس آزمایشی با دانشجوهای جدید بسیار مهم
                  است.
                </div>
              </div>
              <div></div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    پس از دریافت ایمیل رزرو کلاس شما میتوانید آنرا تائید یا کنسل
                    کنید، با این اعلام دانشجو از برگزاری یا عدم برگزاری کلاس در
                    زمان تعیین شده اطمینان حاصل می نماید و هر کدام از آنها دارای
                    امتیاز مثبت و منفی به شرح زیر است :
                  </p>
                  <div>
                    <h2 className="titr7 text-gray-green text-start my-3">
                      تائید یا لغو کلاسهای رزو شده :
                    </h2>
                    <div></div>
                    <div>
                      با تائید رزرواسیون کلاس آموزشی ۱ نمره و کلاس آزمایشی ۲
                      نمره مثبت امتیاز تعهد و اعتبار هدیه شما به میزان ۰.۵ درصد
                      مبلغ کلاس افزایش خواهد یافت.
                    </div>
                    <div>
                      لغو کلاس آموزشی و آزمایشی منجر به کاهش ۱ نمره از امتیاز
                      تعهد شما به دانشجویان خودتون و ۷ دلار از اعتبار هدیه
                      متامارس شما خواهد شد.
                    </div>
                    <div>
                      لغو کلاس‌های تائید شده منجر به کاهش ۲ نمره از امتیاز تعهد
                      شما به دانشجویان خودتون و ۱۰ دلار از اعتبار هدیه متامارس
                      شما خواهد شد.
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <h2 className="titr7 text-gray-green text-start my-3">
                    چطوری برای شروع کلاس آماده بشم؟
                  </h2>
                  <div className="fm1">
                    در کلاس‌های آنلاین حداقل نیم ساعت مانده به آغاز کلاس، لینک
                    ورود به کلاس را در از طریق صفحه خود استاد یا از پنل کاربری
                    برای دانشجو بارگذاری نمائید و پس از آن لینک به صورت خودکار
                    برای دانشجو توسط چت متامارس و ایمیل فرستاده می‌شود و استاد
                    نیز در لیست دریافت کنندگان ایمیل خواهد بود،همچنین شما
                    میتوانید لینک ورود به کلاس را مستقیما به ایمیل یا چت متامارس
                    دانشجو بفرستید.
                    <div>
                      برای تولید لینک کلاس شما می‌توانید از هر کدام از
                      پلتفرم‌هایی که با آن‌ها کار کرده اید و آشنایی دارید، مانند
                      زوم (Zoom)،تیمز (Teams)، گوکل میت(Google Meet) و ...
                      استفاده کنید، تنها در مورد فیلتر نبودن سرویس در کشور ایران
                      یا سایر کشور های تحت فیلترینگ مطمین شوید.
                      <div>
                        * سرویس گوگل میت برای جلساتی که قرار است یک طرف آن در
                        ایران باشد توصیه میگردد.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="titr7 text-gray-green text-start my-3">
                  پس از برگزاری کلاس بایستی کاری انجام دهم؟
                </h2>
                <div>
                  پس از تاریخ کلاس دو گزینه برای کلاس فعال خواهد شد که در آن شما
                  میتوانید کلید تائید برگزاری و یا غیبت دانشجو را انتخاب نمائید.
                </div>
                <div>
                  <span className="text-pink-orange-dark ">
                    {" "}
                    تائید برگزاری :{" "}
                  </span>
                  مبلغ کلاس را به حساب شما منتقل و در پایان ماه به حساب شما
                  واریز خواهد شد.
                </div>
                <div>
                  <span className="text-pink-orange-dark ">غیبت دانشجو : </span>
                  در صورت انتخاب دکمه غیبت دانشجو، دو گزینه دیگر به شما نمایش
                  داده خواهد شد که در آن میتوانید وجه کلاس را به دانشجو استرداد
                  و یا اینکه دکمه واریز به حساب من را انتخاب تا در پایان ماه وجه
                  آن به حساب شما منتقل گردد.
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    توجه داشته باشید که شما موظف هستید ۱۰ دقیقه از زمان شروع
                    کلاس منتظر دانشجو باشید و درصورت عدم حضور دانشجو دو مرتبه
                    لینک کلاس را برای دانشجو بفرستید و سپس در صورت عدم حضور دکمه
                    غیبت دانشجو را انتخاب نمائید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                اگر یک کلاس را غیبت کنم چه اتفاقی می افتد؟
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    اگر یک استاد کلاسی را از دست بدهد(غیبت استاد)، دانشجو
                    می‌تواند اعلام کند که استاد غایب بوده است. پس از این اعلام،
                    یک ایمیل به صورت خودکار برای استاد فرستاده می‌شود، همچنین
                    اساتید همواره در پنل کاربری خود به سابقه کلاس های خود دسترسی
                    خواهد داشت.
                  </p>
                  <div>
                    * اگر به هر دلیلی قبل و یا در طول کلاس با مشکل روبرو شدید و
                    امکان برگزاری کلاس را نداشتید، برای اجتناب دانشجو در استفاده
                    از این گزینه حتماً جلسه جبرانی هماهنگ و برگزار کنید.
                  </div>
                  <div>
                    عدم حضور استاد در کلاس، به‌منزله‌ی لغو کلاس بوده و هزینه
                    کلاس به حساب دانشجو عودت و ۴ نمره از امتیاز تعهد و ۱۵ دلار
                    از محل هدیه ۳۰ دلاری متامارس در زمان ثبت نام کم خواهد شد.
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorPayments`}
            aria-controls={`open-col-tutorPayments`}
            aria-labelledby="open-col-tutorPayments-tab"
          >
            <div> + پرداختی کلاس ها به چه صورت انجام میشه؟</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorPayments"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    پس از تاریخ کلاس برای اساتید در پنل کاربری متادمی و منوی
                    اساتید و زیر منوی (گذشته، نیاز به اقدام )دو گزینه تائید
                    برگزاری کلاس و عدم حضور دانشجو فعال می شود
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                          });
                        window.navigateHandle(
                          `/${window.lcs}/dashboard/user/demy/listsessions`
                        );
                      }}
                      // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (اینجا) </strong>
                    </span>
                    که پس از انتخاب گزینه مناسب مبلغ کلاس به گزارش کارکرد مالی
                    شما
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                          });
                        window.navigateHandle(
                          `/${window.lcs}/dashboard/user/demy/tutortranslist`
                        );
                      }}
                      // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (اینجا) </strong>
                    </span>
                    اضافه و در انتهای هر ماه گزارشات جمع آوری و در اولین هفته
                    واریز به حساب پی پال و به ایمیل آدرس ارائه در قسمت اطلاعات
                    مالی
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                          });
                        window.navigateHandle(
                          `/${window.lcs}/dashboard/user/user/profile/me`
                        );
                      }}
                      // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (اینجا) </strong>
                    </span>
                    فرستاده خواهد شد.
                  </p>
                  <div>
                    نکته : اساتیدی که از ایران ثبت نام و حساب شبا خود را وارد
                    نموده اند واریزی ها به حساب شبای آنها خواهد بود و اگر ارز
                    دیگری به غیر از ریال را انتخاب نموده باشند ملزم به ارائه
                    اطلاعات حساب پی پال خود جهت واریز وجه کلاس به آن حساب
                    می‌باشند.
                  </div>
                  {/* <div>نکته2: متامارس ممکن است بنا به درخواست اساتیدی که فاقد حساب پی پال میباشند و علاقه مند به برگزاری کلاس با نرخهایی غیر از ریال ایران باشند،از خدمات صرافی های مجاز استفاده نماید و مبلغ کارکرد اساتید را در روز واریز به نرخ روز تبدیل و به ریال ایران و به حساب آنها واریز نماید و متعاقب آن هزینه های صرافی و پردازش از حق الزحمه آنها کسر خواهد شد.  </div> */}
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    <i className="bi bi-circle-fill font-14 me-1"></i>
                    برای چک کردن ایمیل یا بروزرسانی نام و نام خانوادکی خودتون
                    میتونین از پنل کاربری و پروفایل من استفاده کنین.
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                          });
                        window.navigateHandle(
                          `/${window.lcs}/dashboard/user/demy/registertutor`
                        );
                      }}
                      // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (اینجا) </strong>
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <i className="bi bi-circle-fill font-14 me-1"></i>
                  توجه داشته باشید که نام و نام خانوادگی گیرنده وجه باید با نام
                  و نام خانودگی ثبت نام شده هماهنگی داشته باشد.
                </div>
                <div className="boolet-point">
                  <p className="fm1">
                    <i className="bi bi-circle-fill font-14 me-1"></i>
                    باتوجه به نیاز های آینده ناشی از گستردگی جغرافیای اساتید در
                    سرتاسر کشور های دنیا از جمله ایران، ممکن است از سایر راه ها
                    و موسسات مالی برای انتقال وجه با توجه به نیاز اساتید استفاده
                    گردد که متناسب با هزینه های مالی و پردازش آنها مبلغی بعنوان
                    هزینه های پردازش (Proccessing fee) از آن کسر خواهد شد.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-tutorWallet`}
            aria-controls={`open-col-tutorWallet`}
            aria-labelledby="open-col-tutorWallet-tab"
          >
            <div>+ توضیحات گزارش مالی کارکرد اساتید</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-tutorWallet"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در
                    <span
                      onClick={(e) => {
                        if (!window.logined(9999)) return;
                        if (!user?.roles?.includes(1505))
                          return messageRef.current.showError({
                            message:
                              "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                          });
                        window.navigateHandle(
                          `/${window.lcs}/dashboard/user/demy/tutortranslist`
                        );
                      }}
                      // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                      className="content__link bold-700 cursor"
                    >
                      <strong> (اینجا) </strong>
                    </span>
                    شما میتوانید لیست کلاس ها به همراه وضعیت برگزاری و مبلغ خالص
                    اونها رو ببینن، که در اولین هفته هر ماه به حساب پی پال تون
                    فرستاده خواهد شد.
                  </p>
                </div>
                <div className="flex column-flex"></div>
              </div>

              <div>
                <div className="">
                  <p className="fm1">
                    توجه داشته باشین که در متادمی برای برقراری نظم و انظباط در
                    زمان کنسلی کلاس توسط استاد نمره تعهد منفی و اعتبار منفی از
                    مبلغ اعتبار هدیه متامارس درنظر گرفته خواهد شد، نمره منفی در
                    جایگاه نمایش شما تاثیر خواهد گذاشت.
                  </p>
                </div>
              </div>

              <div>
                <div className="">
                  <p className="fm1">
                    - درصورتی که بیشتر از ۲۴ ساعت مانده به آغاز کلاس، دانشجو آن
                    را کنسل کند، متامارس تمامی وجه را به حساب دانشجو
                    برمی‌گرداند. اما اگر کنسلی در بازه کمتر از ۲۴ ساعت به آغاز
                    کلاس انجام شود، یا دانشجو در کلاس حضور پیدا نکند،
                    تصمیم‌‌گیری برعهده استاد خواهد بود. به این صورت که پس از
                    پایان زمان کلاس گزینه بازپرداخت یا عدم بازپرداخت برای استاد
                    فعال می‌شود و تصمیم استاد به صورت اتوماتیک اعمال خواهد شد.
                    در این حالت سهمی به عنوان جریمه یا هزینه کنسلی برای متامارس
                    در نظر گرفته نخواهد شد. با این حال ممکن است با توجه به نوع
                    تراکنش و هزینه‌های انتقال وجه که توسط بانک، پی پال یا سایر
                    اشخاص ثالث برای برگرداندن وجه تحمیل خواهد شد از حساب مسبب
                    کنسلی کم شود.
                    <p>
                      * اساتید محترم هم باید توجه داشته باشند که پس از ثبت نام و
                      پذیرش یک اعتبار ۳۰ دلاری بعنوان هدیه توسط متامارس برای شما
                      در نظر گرفته خواهد شد. و در صورت عدم حضور در کلاس ۱۵ دلار
                      آن کسر می‌شود.
                    </p>
                    <p>
                      ** هر کنسلی کلاس با وضعیت رزرو باعث کسر ۷ دلار و هر کنسلی
                      کلاس با وضعیت تائید شده منجر به کسر ۱۰ دلار از اعتبار شما
                      خواهد شد.
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-canceledSessions`}
            aria-controls={`open-col-canceledSessions`}
            aria-labelledby="open-col-canceledSessions-tab"
          >
            <div>
              + سهم متامارس از جلسات آموزشی و جلسات کنسل شده چقدر خواهد بود؟
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-canceledSessions"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    سهم متامارس از جلسات آموزشی، ۲۰ درصد از مبلغی می‌باشد که
                    اساتید هنگام ثبت‌نام و قیمت‌گذاری تعیین کرده‌اند.
                    {/* <div>
                      نکته : اساتیدی که بالای 1000 ساعت جلسه آموزشی برگزار کنند
                      کمیسیون آن به ۱۵ درصد کاهش می‌یابد.
                    </div> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-cancelationFees`}
            aria-controls={`open-col-cancelationFees`}
          >
            <div>
              + آیا با لغو جلسات، جریمه‌ای برای استاد و کاربر در نظر گرفته خواهد
              شد؟
            </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-cancelationFees"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                قوانین لغو
              </h2>
              <div>
                <h2 className="titr7 text-gray-green text-start my-3"></h2>
                <div>
                  <div className="">
                    <p className="fm1">
                      توجه داشته باشید که در متامارس استادها در مورد کلاس‌های
                      کنسل شده دیر هنگام (کمتر از 24 ساعت مانده به برگزاری) توسط
                      کاربرها اختیار کامل دارند و میتوانند وجه کلاس را به صورت
                      کامل دریافت یا اینکه آنرا به دانشجو برگردانند.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="titr7 text-gray-green text-start my-3"></h2>
                <div>
                  <div className="">
                    <p className="fm1">
                      لغو کلاس توسط استادها مشمول موارد زیر می باشد.
                    </p>
                    <div>
                      کنسلی کلاس‌های با وضعیت رزرو دارای ۷ نمره اعتبار منفی و
                      کنسلی کلاس‌های تائید شده ۱۰ نمره اعتبار منفی از اعتبار
                      هدیه شما در بر خواهد داشت و اعتبار هدیه شما پس از رسیدن به
                      0 به صورت اتوماتیک باعث عدم نمایش پروفایل استاد در لیست
                      اساتید خواهد شد. فعالسازی مجدد و نمایش تنها با شارژ مبلغ
                      اولیه اعتبار امکان پذیر می باشد.
                    </div>
                    همچنین کنسلی کلاس باعث کاهش نرخ تعهد استاد به دانشجو ها
                    خواهد شد که برای دانشجویان قابل مشاهده و در جایگاه نمایش
                    پروفایل استاد نیز تاثیر گذار است
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-commitmentScore`}
            aria-controls={`open-col-commitmentScore`}
          >
            <div>+ نشان حرفه‌ای متامارس چیه و چطوری اون رو دریافت کنم ؟ </div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-commitmentScore"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    شما ممکن است نشان حرفه‌ای رو در زیر نام برخی از استادها دیده
                    باشید. ما نشان حرفه‌ای را به استادانی که عملکرد خوبی داشته
                    اند، اعطا می‌کنیم.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                مزایای نشان استاد حرفه‌ای
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    نشان حرفه‌ای: داشتن این نشان در پروفایل شما تعداد دانشجویانی
                    که جلسات آزمایشی و آموزشی با شما رزرو می‌کنند را افزایش
                    می‌دهد و به شما کمک می‌کند کسب و کار خود را گسترش و درآمد
                    خود را افزایش دهید.
                  </p>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    نشان حرفه‌ای این اطمینان را حتی قبل از مطالعه پروفایل به
                    دانشجویان میدهد که استادی یافته‌اند که در کارش حرفه‌ای است و
                    میتوانند به او در مسیر یادگیری اعتماد نماید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                چگونه می‌توانید استاد با نشان حرفه‌ای شوید؟
              </h2>
              <div>
                <div className="">
                  <div className="d-flex my-md-4  my-2">
                    <span>
                      اساتید در متامارس به صورت پویا ارزیابی میشوند و هر فعالیت
                      یا عدم فعالیت میتواند در شرایط دریافت نشان حرفه‌ای اثر
                      بگذارد.
                    </span>
                  </div>
                  <div className="my-md-4  my-2">
                    <div className="d-flex ">
                      <span>معیارهای ارزیابی استاد حرفه‌ای:</span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۱
                      </div>
                      <span>میزان تبدیل دانشجویان آزمایشی به آموزشی</span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۲
                      </div>
                      <span>
                        غیبت درس: شما برای هیچ کدام از دروس (درس‌های آزمایشی یا
                        دروس غیر آزمایشی) غیبت نداشته‌اید.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۳
                      </div>
                      <span>
                        کلاسهای آموزشی برگزار شده: شما ۱۰ یا بیشتر باشد.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۴
                      </div>
                      <span>
                        امتیاز متوسط بررسی: شما امتیاز استادی ۴.۵ یا بالاتر
                        دارید.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۵
                      </div>
                      <span>
                        نرخ پاسخ: شما حداقل به ۹۰٪ از پیام‌های اولیه از
                        دانشجویان بالقوه خود در کمتر از ۲۴ ساعت پاسخ داده‌اید.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۶
                      </div>
                      <span> تعداد لغو جلسات آزمایشی و آموزشی. </span>
                    </div>

                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۷
                      </div>
                      <span>
                        نرخ تائید استاد برای حضور در کلاس‌های آموزشی و آزمایشی
                        رزو شده.
                      </span>
                    </div>
                    <div className="d-flex ms-3">
                      <div className="items-count bg-pinkOrange text-beigeYellow bg-pinkOrange text-beigeYellow mx-2 mt-1">
                        ۸
                      </div>
                      <span> بارگذاری ویدئو معرفی. </span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <span>
                      نشان حرفه‌ای براساس معیارهای بالا محاسبه و به محض احراز
                      تمام شرایط این نشان به صورت اتوماتیک برای استاد فعال و در
                      صورت از دست دادن شرایط آنرا از دست خواهید داد.
                    </span>
                  </div>

                  <div>
                    <div>
                      در این میان، در ادامه برخی از نکات مهم برای کمک به شما در
                      انطباق با شرایط و تبدیل شدن به استاد حرفه‌ای را مطالعه
                      کنید:
                    </div>
                    <div>
                      ★ بیشترین زمان‌های ممکن را داشته باشید این کار تعداد
                      دانشجویانی را که جلسه با شما رزرو می‌کنند افزایش می‌دهد.
                      همچنین مطمئن شوید که در زمان های تعیین شده آزاد و در دسترس
                      هستید.
                    </div>
                    <div>
                      ★ اگر به دانشجویان خاصی تدریس نمی کنید آنها را مشخص کنید
                      به برخی از دانشجویان، مانند دانشجویان جوان یا سطوح پایین،
                      تدریس نمی‌کنید، این را در توضیحات خود ذکر کنید. این باعث
                      کاهش شانس‌های شما برای رزرو جلسات آزمایشی با دانشجویان
                      نامناسب می‌شود.
                    </div>
                    <div>
                      ★ از دانشجویان خود برای ارائه دیدگاه و امتیاز بخواهید -
                      شرمنده نباشید! اگر یک دانشجو از کلاس‌های شما لذت می‌برد،
                      از او برای امتیاز دهی به شما بخواهید. دانشجویان معمولاً
                      خوشحال هستند که چیزهای مثبتی درباره استاد خود بنویسند.
                    </div>
                    <div>
                      <div>
                        ★ زمان های غیر خالی خود را در برنامه کلاسی خود ببندید
                      </div>
                      <div>
                        برای جلوگیری از لغو یا تغییر زمان دروس، اگر در زمانی که
                        به طور معمول آزاد برای تدریس هستید، مشغول بودید، از
                        ویرایش پروفایل خود استفاده و آن روز یا ساعت را حذف کنید.
                      </div>
                    </div>
                    <div>
                      <div>
                        ★ از یک الگوی قابل تنظیم برای پاسخ‌ها به دانشجویان
                        استفاده کنید
                      </div>
                      <div>
                        برای پاسخ سریع به دانشجویان جدید خود، یک الگو ایجاد کنید
                        که به راحتی می‌توانید آن را بر اساس نیاز تغییر دهید.
                      </div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-inviteLink`}
            aria-controls={`open-col-inviteLink`}
          >
            <div>دعوت از دانشجوها با استفاده از لینک دعوت و درصد کمیسیون صفر</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-inviteLink">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    شما می‌توانید دانشجویان خود را به متامارس دعوت کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">قوانین استفاده:</p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    باید دانشجویانی را دعوت کنید که قبلاً در متامارس ثبت‌نام
                    نکرده باشند.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    این دانشجویان می‌توانند هر تعداد کلاسی که می‌خواهند با شما
                    رزرو کنند و این جلسات برای شما با کمیسیون صفر محاسبه خواهد
                    شد.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    شما می‌توانید این لینک را برای هر تعداد دانشجویی که در نظر
                    دارید بفرستید.
                  </p>
                </div>
              </div>
              <div>
                <div className="boolet-point">
                  <p className="fm1">
                    شما نمی‌توانید این لینک را در بخش درباره‌ من بگذارید و یا در
                    ویدئو معرفی خود آن را برای تمامی دانشجویان به اشتراک
                    بگذارید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                اهمیت دعوت از دانشجویان
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    یکی از مهم‌ترین مواردی که شما باید به آن توجه داشته باشید،
                    داشتن پروفایل قابل‌اعتماد برای کاربر است. اگر تازه به جمع
                    اساتید متامارس اضافه‌شده‌اید و تعداد کلاس برگزار شده‌ی
                    بالایی ندارید، می‌توانید از دانشجویان خودتان دعوت کنید که در
                    متامارس با شما کلاس برگزار کنند و بعد از برگزاری کلاس برای
                    شما نظر ثبت کنند یا به کلاس شما امتیاز بدهند. همین‌طور
                    می‌توانید بدون پرداخت کمیسیون از امکانات متامارس استفاده
                    کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                چگونه دانشجویان خود را دعوت کنیم؟
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    برای دسترسی به این لینک، از پنل کاربری خود قسمت «کمیسیون
                    صفر» را انتخاب کنید.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    لینک دعوت این صفحه را کپی کنید و آن را برای دانشجویان خود
                    بفرستید.
                  </p>
                </div>
                <div className="flex column-flex">
                  <div>
                  </div>
                </div>
              </div>
              <div>
                <div className="">
                  <p className="fm1">
                    با کلیک بر روی این لینک، دانشجویان شما به صفحه ثبت‌نام هدایت
                    خواهد شد و می‌توانند پس از طی کردن مراحل ثبت‌نام در سایت، با
                    شما کلاس رزرو کنند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    توجه داشته باشید دانشجویان فعلی شما و افرادی که قبلاً در
                    متامارس ثبت‌نام کرده باشند نمی‌توانند از این لینک استفاده
                    کنند و این لینک فقط برای دانشجویانی است که تا به حال در
                    متامارس ثبت‌نام نکرده باشند.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-pricing`}
            aria-controls={`open-col-pricing`}
            aria-labelledby="open-col-pricing-tab"
          >
            <div>+ قیمت و ارز کلاس هام رو چطور تعیین کنم؟</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-pricing">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    برای تعیین قیمت هر ساعت تدریس باید به چندین عامل توجه داشته
                    باشید که به صورت خلاصه به شرح زیر می‌باشد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                چه ارزی رو انتخاب کنم ؟
              </h2>
              <div>
                <div className="">
                  <div className="fm1">
                    به صورت پیش فرض بهتر هستش که ارز کشور محل اقامت خودتون رو
                    انتخاب کنید ولی همچنان میتونین سایر ارزها رو هم انتخاب کنید.
                  </div>

                  <p>
                    دانشجویان هم قیمت های شما رو بر اساس ارز رسمی کشور محل
                    اقامتشون میبینن و تمام نرخ های اساتید در کشورهای مختلف تبدیل
                    به یک نرخ و نمایش داده میشه، پس برای دانشجویان تفاوتی بین
                    دلار و ریال نیست و همه یکسان هستند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                چه قیمتی برای کلاسهام بذارم؟
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    قیمت کلاس ها کاملا با توجه به ارزیابی شما از خودتون و همچنین
                    روش بازاریابی‌ که برای تدریستون تعیین می‌کنید بستگی داره و
                    برای همه اساتید به یک شکل نمی باشد و میتونه از خیلی ارزان،
                    گران یا متوسط شروع کنید، ولی پیشنهاد میشه ابتدا قیمت اساتید
                    دیگر را با درنظر گرفتن جدید بودن، تعداد دانشجویان، کلاس‌های
                    برگزار شده، امتیاز و دیدگاه‌های دریافتی مقایسه و اگر تقریباً
                    شبیه به پروفایل شما بود، می‌توانید از آنها برای تعیین قیمت
                    کلاس خود استفاده نمائید.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                جلسات آزمایشی
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    جلسات آزمایشی شاه کلید جذب دانشجویان است زیرا آنها میتوانند
                    به راحتی با استاد آشنا و تصمیم خود را قطعی کنند. البته تمام
                    جلسات آزمایشی نیم ساعته می باشد.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                چه زمانی و چطور نرخ کلاس هام رو افزایش بدهم ؟
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    بهترین زمان برای افزایش نرخ کلاس زمانی است که شما توانسته
                    اید برند خود را ساخته و دانشجویان خود را جذب کرده اید و
                    توانسته باشید حداقل 50% برنامه کلاسی خود را رزرو داشته
                    باشید، اما با این حال بهتر است پیش از افزایش قیمت دانشجویان
                    فعلی خود را درجریان این افزایش بگذارید و نظرات آنها را جویا
                    شوید و مطمین شوید که پس از این افزایش همچنان مشتاقانه با شما
                    ادامه خواهند داد.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-freeNoShow`}
            aria-controls={`open-col-freeNoShow`}
            aria-labelledby="open-col-freeNoShow-tab"
          >
            <div> در صورت عدم حضور استاد در جلسات آموزشی چه اتفاقی میفته؟</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-freeNoShow">
            <div>
              <h2 className="titr7 text-gray-green text-start my-3"></h2>
              <div>
                <div className="">
                  <p className="fm1">
                    به منظور برقراری نظم و همچنین تجربه‌ ای حرفه‌ای برای
                    دانشجویان، اساتید موظف به حضور در کلاس‌های خود در رأس ساعت
                    تنظیم شده هستند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                نیاز به لغو یا جابجایی
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در صورت نیاز به لغو دانشجویان و اساتید می توانند از پنل
                    کاربری اقدام به لغو کلاس نمایند و جهت جابجایی زمان کلاس هم
                    میتوانند حداقل 12 ساعت قبل از زمان شروع کلاس از طریق پیام
                    رسان متامارس و ایمیل درخواست خود را برای یکدیگر بفرستند
                    و در صورت توافق هردو طرف میتوانند کلاس را در زمان توافقی
                    دیگری برگزار نمایند.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                اختلاف در منطقه جغرافیایی اساتید و دانشجویان (Time Zone)
              </h2>
              باتوجه با اینکه دانشجویان و اساتید ممکن است از سرتاسر دنیا اقدام
              به رزرو کلاس نمایند همواره باید درخاطر داشته باشید که ممکنه است با
              یکدیگر اختلاف ساعت داشته باشید و در مکاتبات خود آنرا در نظر داشته
              باشید. در ایمیل های فرستاده شده از طرف متامارس در کنار زمان برگزاری
              کلاس به تایم زون هم اشاره شده است.
              <div></div>
            </div>

            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                عدم حضور استاد در کلاس
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    عدم حضور استاد در کلاس، به‌منزله‌ی لغو کلاس بوده و هزینه
                    کلاس به حساب دانشجو عودت و استاد هم علاوه بر نمرات منفی درج
                    شده در شرایط لغو مشمول جریمه ۱۵ دلار کانادا جریمه از محل
                    هدیه ۳۰ دلاری متامارس در زمان ثبت نام خواهد شد.
                  </p>
                  <p>
                    شایان ذکر است مانده اعتبار هدیه اساتید نمیتواند صفر یاکمتر
                    از آن باشد و به صورت خودکار منجر به عدم نمایش پروفایل استاد
                    مد نظر در لیست اساتید خواهد شد.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-discontinuing`}
            aria-controls={`open-col-discontinuing`}
            aria-labelledby="open-col-discontinuing-tab"
          >
            <div>+ تعهدات استاد در زمان قطع همکاری به چه صورت خواهد بود؟</div>
          </div>
          <div
            className="accordion-collapse collapse"
            id="open-col-discontinuing"
          >
            <div>
              <h2 className="titr7 text-gray-green text-start my-3">
                تصمیم به قطع همکاری
              </h2>
              <div>
                <div className="">
                  <p className="fm1">
                    در صورت تصمیم به قطع همکاری، اساتید محترم موظف هستند ابتدا
                    باید حساب آموزشی خود را غیر فعال نمائید (دکمه فعال/غیرفعال
                    سازی در بالای صفحه ویرایش اطلاعات استاد قرار دارد.) تا
                    دانشجویان جدیدی با ایشان کلاس رزرو نکنند و پشتیبانی متامارس
                    رو با فرستادن ایمیل به (metamarce.com@gmail.com)در جریان
                    بگذارند. و کلاس‌های کنونی خود را به پایان برسانند.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item2 m-3">
          <div
            className="accordion-button accordion-button3 collapsed "
            type="button"
            data-bs-toggle="collapse"
            aria-expanded="true"
            data-bs-target={`#open-col-multiTutor`}
            aria-controls={`open-col-multiTutor`}
            aria-labelledby="open-col-multiTutor-tab"
          >
            <div>+ آیا میتونم در دو زمینه مجزا تدریس داشته باشم ؟</div>
          </div>
          <div className="accordion-collapse collapse" id="open-col-multiTutor">
            <div>
              در متامارس این امکان فراهم شده که اگر یک استاد در دو زمینه متفاوت
              توانایی تدریس داشته باشد، برای هر کدام به شکل جداگانه پروفایل
              استاد تشکیل بدهند
              <span
                onClick={(e) => {
                  if (!window.logined(9999)) return;
                  if (!user?.roles?.includes(1505))
                    return messageRef.current.showError({
                      message:
                        "شما تاکنون پروفایل تدریس خود را کامل نکرده‌اید.",
                    });
                  window.navigateHandle(
                    `/${window.lcs}/dashboard/user/demy/registertutor`
                  );
                }}
                // href={`/${window.lcs}/dashboard/user/demy/myTutorlist`}

                className="content__link bold-700 cursor"
              >
                <strong> (اینجا) </strong>
              </span>
              مثلا آموزش گیتار و ریاضی .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
