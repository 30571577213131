import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import apiPromo from "../../../../../utils/apiStore";


export default function PromoList() {
  const { user } = useContext(CartContext);
  const [data, setData] = useState({});

 

  useEffect(() =>   {
    fetchPromoList()
     }, []);
   async function fetchPromoList(){
    const response = await apiPromo.promoList({token:user.token});
    console.log(response);
    if (response.error) return alert(response.error);
    setData(response.data);
   }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "cycle",
      headerName: "cycle",
      width: 100, 
    },
   
    { field: 'discountType',  headerName: 'discountType', width: 90,},

    { field: "discPercent", headerName: "discPercent", width: 250 },

      { field: 'discAmount',  headerName: 'discAmount', width: 90,},    
      { field: 'endDate',  headerName: 'endDate', width: 90,},
      { field: 'promCode',  headerName: 'promCode', width: 90,},
      { field: 'startDate',  headerName: 'startDate', width: 90,},
  ];

  return (
    <div className="userList" style={{ height: '100vh', width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={[12]}
        rowsPerPageOptions={[1]}
        checkboxSelection
      />
    </div>
  );
}
