import { NavLink } from "react-router-dom";

export default function UserDashNavbar() {
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/user/profile/me`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        پروفایل من
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/profile/author`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        {" "}
        پروفایل نویسندگی
      </NavLink>
      {/* <NavLink to={`/${window.lcs}/dashboard/user/profile/marketer`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> پروفایل بازاریابی</NavLink> */}
      {/* <NavLink to={`/${window.lcs}/dashboard/user/profile/affiliate`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}> ریفرال کد</NavLink> */}
    </div>
  );
}
