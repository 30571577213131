 const countryStates= [
    {
    country: "Afghanistan",
    countryShort: "AF",
    currency_symbol: "؋",
    states: [
    {
    state: "Badakhshan",
    stateShort: "BDS",
    lat: "36.73477250",
    lng: "70.81199530"
    },
    {
    state: "Badghis",
    stateShort: "BDG",
    lat: "35.16713390",
    lng: "63.76953840"
    },
    {
    state: "Baghlan",
    stateShort: "BGL",
    lat: "36.17890260",
    lng: "68.74530640"
    },
    {
    state: "Balkh",
    stateShort: "BAL",
    lat: "36.75506030",
    lng: "66.89753720"
    },
    {
    state: "Bamyan",
    stateShort: "BAM",
    lat: "34.81000670",
    lng: "67.82121040"
    },
    {
    state: "Daykundi",
    stateShort: "DAY",
    lat: "33.66949500",
    lng: "66.04635340"
    },
    {
    state: "Farah",
    stateShort: "FRA",
    lat: "32.49532800",
    lng: "62.26266270"
    },
    {
    state: "Faryab",
    stateShort: "FYB",
    lat: "36.07956130",
    lng: "64.90595500"
    },
    {
    state: "Ghazni",
    stateShort: "GHA",
    lat: "33.54505870",
    lng: "68.41739720"
    },
    {
    state: "Ghōr",
    stateShort: "GHO",
    lat: "34.09957760",
    lng: "64.90595500"
    },
    {
    state: "Helmand",
    stateShort: "HEL",
    lat: "39.29893610",
    lng: "-76.61604720"
    },
    {
    state: "Herat",
    stateShort: "HER",
    lat: "34.35286500",
    lng: "62.20402870"
    },
    {
    state: "Jowzjan",
    stateShort: "JOW",
    lat: "36.89696920",
    lng: "65.66585680"
    },
    {
    state: "Kabul",
    stateShort: "KAB",
    lat: "34.55534940",
    lng: "69.20748600"
    },
    {
    state: "Kandahar",
    stateShort: "KAN",
    lat: "31.62887100",
    lng: "65.73717490"
    },
    {
    state: "Kapisa",
    stateShort: "KAP",
    lat: "34.98105720",
    lng: "69.62145620"
    },
    {
    state: "Khost",
    stateShort: "KHO",
    lat: "33.33384720",
    lng: "69.93716730"
    },
    {
    state: "Kunar",
    stateShort: "KNR",
    lat: "34.84658930",
    lng: "71.09731700"
    },
    {
    state: "Kunduz Province",
    stateShort: "KDZ",
    lat: "36.72855110",
    lng: "68.86789820"
    },
    {
    state: "Laghman",
    stateShort: "LAG",
    lat: "34.68976870",
    lng: "70.14558050"
    },
    {
    state: "Logar",
    stateShort: "LOG",
    lat: "34.01455180",
    lng: "69.19239160"
    },
    {
    state: "Nangarhar",
    stateShort: "NAN",
    lat: "34.17183130",
    lng: "70.62167940"
    },
    {
    state: "Nimruz",
    stateShort: "NIM",
    lat: "31.02614880",
    lng: "62.45041540"
    },
    {
    state: "Nuristan",
    stateShort: "NUR",
    lat: "35.32502230",
    lng: "70.90712360"
    },
    {
    state: "Paktia",
    stateShort: "PIA",
    lat: "33.70619900",
    lng: "69.38310790"
    },
    {
    state: "Paktika",
    stateShort: "PKA",
    lat: "32.26453860",
    lng: "68.52471490"
    },
    {
    state: "Panjshir",
    stateShort: "PAN",
    lat: "38.88023910",
    lng: "-77.17172380"
    },
    {
    state: "Parwan",
    stateShort: "PAR",
    lat: "34.96309770",
    lng: "68.81088490"
    },
    {
    state: "Samangan",
    stateShort: "SAM",
    lat: "36.31555060",
    lng: "67.96428630"
    },
    {
    state: "Sar-e Pol",
    stateShort: "SAR",
    lat: "36.21662800",
    lng: "65.93336000"
    },
    {
    state: "Takhar",
    stateShort: "TAK",
    lat: "36.66980130",
    lng: "69.47845410"
    },
    {
    state: "Urozgan",
    stateShort: "URU",
    lat: "32.92712870",
    lng: "66.14152630"
    },
    {
    state: "Zabul",
    stateShort: "ZAB",
    lat: "32.19187820",
    lng: "67.18944880"
    }
    ]
    },
    {
    country: "Albania",
    countryShort: "AL",
    currency_symbol: "Lek",
    states: [
    {
    state: "Berat County",
    stateShort: "01",
    lat: "40.69530120",
    lng: "20.04496620"
    },
    {
    state: "Berat District",
    stateShort: "BR",
    lat: "40.70863770",
    lng: "19.94373140"
    },
    {
    state: "Bulqizë District",
    stateShort: "BU",
    lat: "41.49425870",
    lng: "20.21471570"
    },
    {
    state: "Delvinë District",
    stateShort: "DL",
    lat: "39.94813640",
    lng: "20.09558910"
    },
    {
    state: "Devoll District",
    stateShort: "DV",
    lat: "40.64473470",
    lng: "20.95066360"
    },
    {
    state: "Dibër County",
    stateShort: "09",
    lat: "41.58881630",
    lng: "20.23556470"
    },
    {
    state: "Dibër District",
    stateShort: "DI",
    lat: "41.58881630",
    lng: "20.23556470"
    },
    {
    state: "Durrës County",
    stateShort: "02",
    lat: "41.50809720",
    lng: "19.61631850"
    },
    {
    state: "Durrës District",
    stateShort: "DR",
    lat: "41.37065170",
    lng: "19.52110630"
    },
    {
    state: "Elbasan County",
    stateShort: "03",
    lat: "41.12666720",
    lng: "20.23556470"
    },
    {
    state: "Fier County",
    stateShort: "04",
    lat: "40.91913920",
    lng: "19.66393090"
    },
    {
    state: "Fier District",
    stateShort: "FR",
    lat: "40.72750400",
    lng: "19.56275960"
    },
    {
    state: "Gjirokastër County",
    stateShort: "05",
    lat: "40.06728740",
    lng: "20.10452290"
    },
    {
    state: "Gjirokastër District",
    stateShort: "GJ",
    lat: "40.06728740",
    lng: "20.10452290"
    },
    {
    state: "Gramsh District",
    stateShort: "GR",
    lat: "40.86698730",
    lng: "20.18493230"
    },
    {
    state: "Has District",
    stateShort: "HA",
    lat: "42.79013360",
    lng: "-83.61220120"
    },
    {
    state: "Kavajë District",
    stateShort: "KA",
    lat: "41.18445290",
    lng: "19.56275960"
    },
    {
    state: "Kolonjë District",
    stateShort: "ER",
    lat: "40.33732620",
    lng: "20.67946760"
    },
    {
    state: "Korçë County",
    stateShort: "06",
    lat: "40.59056700",
    lng: "20.61689210"
    },
    {
    state: "Korçë District",
    stateShort: "KO",
    lat: "40.59056700",
    lng: "20.61689210"
    },
    {
    state: "Krujë District",
    stateShort: "KR",
    lat: "41.50947650",
    lng: "19.77107320"
    },
    {
    state: "Kuçovë District",
    stateShort: "KC",
    lat: "40.78370630",
    lng: "19.87823480"
    },
    {
    state: "Kukës County",
    stateShort: "07",
    lat: "42.08074640",
    lng: "20.41429230"
    },
    {
    state: "Kukës District",
    stateShort: "KU",
    lat: "42.08074640",
    lng: "20.41429230"
    },
    {
    state: "Kurbin District",
    stateShort: "KB",
    lat: "41.64126440",
    lng: "19.70559500"
    },
    {
    state: "Lezhë County",
    stateShort: "08",
    lat: "41.78137590",
    lng: "19.80679160"
    },
    {
    state: "Lezhë District",
    stateShort: "LE",
    lat: "41.78607300",
    lng: "19.64607580"
    },
    {
    state: "Librazhd District",
    stateShort: "LB",
    lat: "41.18292320",
    lng: "20.31747690"
    },
    {
    state: "Lushnjë District",
    stateShort: "LU",
    lat: "40.94198300",
    lng: "19.69964280"
    },
    {
    state: "Malësi e Madhe District",
    stateShort: "MM",
    lat: "42.42451730",
    lng: "19.61631850"
    },
    {
    state: "Mallakastër District",
    stateShort: "MK",
    lat: "40.52733760",
    lng: "19.78297910"
    },
    {
    state: "Mat District",
    stateShort: "MT",
    lat: "41.59376750",
    lng: "19.99732440"
    },
    {
    state: "Mirditë District",
    stateShort: "MR",
    lat: "41.76428600",
    lng: "19.90205090"
    },
    {
    state: "Peqin District",
    stateShort: "PQ",
    lat: "41.04709020",
    lng: "19.75023840"
    },
    {
    state: "Përmet District",
    stateShort: "PR",
    lat: "40.23618370",
    lng: "20.35173340"
    },
    {
    state: "Pogradec District",
    stateShort: "PG",
    lat: "40.90153140",
    lng: "20.65562890"
    },
    {
    state: "Pukë District",
    stateShort: "PU",
    lat: "42.04697720",
    lng: "19.89609680"
    },
    {
    state: "Sarandë District",
    stateShort: "SR",
    lat: "39.85921190",
    lng: "20.02710010"
    },
    {
    state: "Shkodër County",
    stateShort: "10",
    lat: "42.15037100",
    lng: "19.66393090"
    },
    {
    state: "Shkodër District",
    stateShort: "SH",
    lat: "42.06929850",
    lng: "19.50325590"
    },
    {
    state: "Skrapar District",
    stateShort: "SK",
    lat: "40.53499460",
    lng: "20.28322170"
    },
    {
    state: "Tepelenë District",
    stateShort: "TE",
    lat: "40.29666320",
    lng: "20.01816730"
    },
    {
    state: "Tirana County",
    stateShort: "11",
    lat: "41.24275980",
    lng: "19.80679160"
    },
    {
    state: "Tirana District",
    stateShort: "TR",
    lat: "41.32754590",
    lng: "19.81869820"
    },
    {
    state: "Tropojë District",
    stateShort: "TP",
    lat: "42.39821510",
    lng: "20.16259550"
    },
    {
    state: "Vlorë County",
    stateShort: "12",
    lat: "40.15009600",
    lng: "19.80679160"
    },
    {
    state: "Vlorë District",
    stateShort: "VL",
    lat: "40.46606680",
    lng: "19.49135600"
    }
    ]
    },
    {
    country: "Algeria",
    countryShort: "DZ",
    currency_symbol: "دج",
    states: [
    {
    state: "Adrar",
    stateShort: "01",
    lat: "26.41813100",
    lng: "-0.60147170"
    },
    {
    state: "Aïn Defla",
    stateShort: "44",
    lat: "36.25094290",
    lng: "1.93938150"
    },
    {
    state: "Aïn Témouchent",
    stateShort: "46",
    lat: "35.29926980",
    lng: "-1.13927920"
    },
    {
    state: "Algiers",
    stateShort: "16",
    lat: "36.69972940",
    lng: "3.05761990"
    },
    {
    state: "Annaba",
    stateShort: "23",
    lat: "36.80205080",
    lng: "7.52472430"
    },
    {
    state: "Batna",
    stateShort: "05",
    lat: "35.59659540",
    lng: "5.89871390"
    },
    {
    state: "Béchar",
    stateShort: "08",
    lat: "31.62380980",
    lng: "-2.21624430"
    },
    {
    state: "Béjaïa",
    stateShort: "06",
    lat: "36.75152580",
    lng: "5.05568370"
    },
    {
    state: "Béni Abbès",
    stateShort: "53",
    lat: "30.08310420",
    lng: "-2.83450520"
    },
    {
    state: "Biskra",
    stateShort: "07",
    lat: "34.84494370",
    lng: "5.72485670"
    },
    {
    state: "Blida",
    stateShort: "09",
    lat: "36.53112300",
    lng: "2.89762540"
    },
    {
    state: "Bordj Baji Mokhtar",
    stateShort: "52",
    lat: "22.96633500",
    lng: "-3.94727320"
    },
    {
    state: "Bordj Bou Arréridj",
    stateShort: "34",
    lat: "36.07399250",
    lng: "4.76302710"
    },
    {
    state: "Bouïra",
    stateShort: "10",
    lat: "36.36918460",
    lng: "3.90061940"
    },
    {
    state: "Boumerdès",
    stateShort: "35",
    lat: "36.68395590",
    lng: "3.62178020"
    },
    {
    state: "Chlef",
    stateShort: "02",
    lat: "36.16935150",
    lng: "1.28910360"
    },
    {
    state: "Constantine",
    stateShort: "25",
    lat: "36.33739110",
    lng: "6.66381200"
    },
    {
    state: "Djanet",
    stateShort: "56",
    lat: "23.83108720",
    lng: "8.70046720"
    },
    {
    state: "Djelfa",
    stateShort: "17",
    lat: "34.67039560",
    lng: "3.25037610"
    },
    {
    state: "El Bayadh",
    stateShort: "32",
    lat: "32.71488240",
    lng: "0.90566230"
    },
    {
    state: "El M'ghair",
    stateShort: "49",
    lat: "33.95405610",
    lng: "5.13464180"
    },
    {
    state: "El Menia",
    stateShort: "50",
    lat: "31.36422500",
    lng: "2.57844950"
    },
    {
    state: "El Oued",
    stateShort: "39",
    lat: "33.36781100",
    lng: "6.85165110"
    },
    {
    state: "El Tarf",
    stateShort: "36",
    lat: "36.75766780",
    lng: "8.30763430"
    },
    {
    state: "Ghardaïa",
    stateShort: "47",
    lat: "32.49437410",
    lng: "3.64446000"
    },
    {
    state: "Guelma",
    stateShort: "24",
    lat: "36.46274440",
    lng: "7.43308330"
    },
    {
    state: "Illizi",
    stateShort: "33",
    lat: "26.16900050",
    lng: "8.48424650"
    },
    {
    state: "In Guezzam",
    stateShort: "58",
    lat: "20.38643230",
    lng: "4.77893940"
    },
    {
    state: "In Salah",
    stateShort: "57",
    lat: "27.21492290",
    lng: "1.84843960"
    },
    {
    state: "Jijel",
    stateShort: "18",
    lat: "36.71796810",
    lng: "5.98325770"
    },
    {
    state: "Khenchela",
    stateShort: "40",
    lat: "35.42694040",
    lng: "7.14601550"
    },
    {
    state: "Laghouat",
    stateShort: "03",
    lat: "33.80783410",
    lng: "2.86282940"
    },
    {
    state: "M'Sila",
    stateShort: "28",
    lat: "35.71866460",
    lng: "4.52334230"
    },
    {
    state: "Mascara",
    stateShort: "29",
    lat: "35.39041250",
    lng: "0.14949880"
    },
    {
    state: "Médéa",
    stateShort: "26",
    lat: "36.26370780",
    lng: "2.75878570"
    },
    {
    state: "Mila",
    stateShort: "43",
    lat: "36.36479570",
    lng: "6.15269850"
    },
    {
    state: "Mostaganem",
    stateShort: "27",
    lat: "35.95830540",
    lng: "0.33718890"
    },
    {
    state: "Naama",
    stateShort: "45",
    lat: "33.26673170",
    lng: "-0.31286590"
    },
    {
    state: "Oran",
    stateShort: "31",
    lat: "35.60823510",
    lng: "-0.56360900"
    },
    {
    state: "Ouargla",
    stateShort: "30",
    lat: "32.22648630",
    lng: "5.72998210"
    },
    {
    state: "Ouled Djellal",
    stateShort: "51",
    lat: "34.41782210",
    lng: "4.96858430"
    },
    {
    state: "Oum El Bouaghi",
    stateShort: "04",
    lat: "35.86887890",
    lng: "7.11082660"
    },
    {
    state: "Relizane",
    stateShort: "48",
    lat: "35.73834050",
    lng: "0.75328090"
    },
    {
    state: "Saïda",
    stateShort: "20",
    lat: "34.84152070",
    lng: "0.14560550"
    },
    {
    state: "Sétif",
    stateShort: "19",
    lat: "36.30733890",
    lng: "5.56172790"
    },
    {
    state: "Sidi Bel Abbès",
    stateShort: "22",
    lat: "34.68060240",
    lng: "-1.09994950"
    },
    {
    state: "Skikda",
    stateShort: "21",
    lat: "36.67211980",
    lng: "6.83509990"
    },
    {
    state: "Souk Ahras",
    stateShort: "41",
    lat: "36.28010620",
    lng: "7.93840330"
    },
    {
    state: "Tamanghasset",
    stateShort: "11",
    lat: "22.79029720",
    lng: "5.51932680"
    },
    {
    state: "Tébessa",
    stateShort: "12",
    lat: "35.12906910",
    lng: "7.95928630"
    },
    {
    state: "Tiaret",
    stateShort: "14",
    lat: "35.37086890",
    lng: "1.32178520"
    },
    {
    state: "Timimoun",
    stateShort: "54",
    lat: "29.67890600",
    lng: "0.50046080"
    },
    {
    state: "Tindouf",
    stateShort: "37",
    lat: "27.80631190",
    lng: "-5.72998210"
    },
    {
    state: "Tipasa",
    stateShort: "42",
    lat: "36.54626500",
    lng: "2.18432850"
    },
    {
    state: "Tissemsilt",
    stateShort: "38",
    lat: "35.60537810",
    lng: "1.81309800"
    },
    {
    state: "Tizi Ouzou",
    stateShort: "15",
    lat: "36.70691100",
    lng: "4.23333550"
    },
    {
    state: "Tlemcen",
    stateShort: "13",
    lat: "34.67802840",
    lng: "-1.36621600"
    },
    {
    state: "Touggourt",
    stateShort: "55",
    lat: "33.12484760",
    lng: "5.78327150"
    }
    ]
    },   
    {
    country: "Andorra",
    countryShort: "AD",
    currency_symbol: "€",
    states: [
    {
    state: "Andorra la Vella",
    stateShort: "07",
    lat: "42.50631740",
    lng: "1.52183550"
    },
    {
    state: "Canillo",
    stateShort: "02",
    lat: "42.59782490",
    lng: "1.65663770"
    },
    {
    state: "Encamp",
    stateShort: "03",
    lat: "42.53597640",
    lng: "1.58367730"
    },
    {
    state: "Escaldes-Engordany",
    stateShort: "08",
    lat: "42.49093790",
    lng: "1.58869660"
    },
    {
    state: "La Massana",
    stateShort: "04",
    lat: "42.54562500",
    lng: "1.51473920"
    },
    {
    state: "Ordino",
    stateShort: "05",
    lat: "42.59944330",
    lng: "1.54023270"
    },
    {
    state: "Sant Julià de Lòria",
    stateShort: "06",
    lat: "42.45296310",
    lng: "1.49182350"
    }
    ]
    },
    {
    country: "Angola",
    countryShort: "AO",
    currency_symbol: "Kz",
    states: [
    {
    state: "Bengo Province",
    stateShort: "BGO",
    lat: "-9.10422570",
    lng: "13.72891670"
    },
    {
    state: "Benguela Province",
    stateShort: "BGU",
    lat: "-12.80037440",
    lng: "13.91439900"
    },
    {
    state: "Bié Province",
    stateShort: "BIE",
    lat: "-12.57279070",
    lng: "17.66888700"
    },
    {
    state: "Cabinda Province",
    stateShort: "CAB",
    lat: "-5.02487490",
    lng: "12.34638750"
    },
    {
    state: "Cuando Cubango Province",
    stateShort: "CCU",
    lat: "-16.41808240",
    lng: "18.80761950"
    },
    {
    state: "Cuanza Norte Province",
    stateShort: "CNO",
    lat: "-9.23985130",
    lng: "14.65878210"
    },
    {
    state: "Cuanza Sul",
    stateShort: "CUS",
    lat: "-10.59519100",
    lng: "15.40680790"
    },
    {
    state: "Cunene Province",
    stateShort: "CNN",
    lat: "-16.28022210",
    lng: "16.15809370"
    },
    {
    state: "Huambo Province",
    stateShort: "HUA",
    lat: "-12.52682210",
    lng: "15.59433880"
    },
    {
    state: "Huíla Province",
    stateShort: "HUI",
    lat: "-14.92805530",
    lng: "14.65878210"
    },
    {
    state: "Luanda Province",
    stateShort: "LUA",
    lat: "-9.03508800",
    lng: "13.26634790"
    },
    {
    state: "Lunda Norte Province",
    stateShort: "LNO",
    lat: "-8.35250220",
    lng: "19.18800470"
    },
    {
    state: "Lunda Sul Province",
    stateShort: "LSU",
    lat: "-10.28665780",
    lng: "20.71224650"
    },
    {
    state: "Malanje Province",
    stateShort: "MAL",
    lat: "-9.82511830",
    lng: "16.91225100"
    },
    {
    state: "Moxico Province",
    stateShort: "MOX",
    lat: "-13.42935790",
    lng: "20.33088140"
    },
    {
    state: "Uíge Province",
    stateShort: "UIG",
    lat: "-7.17367320",
    lng: "15.40680790"
    },
    {
    state: "Zaire Province",
    stateShort: "ZAI",
    lat: "-6.57334580",
    lng: "13.17403480"
    }
    ]
    },
    

    {
    country: "Antigua And Barbuda",
    countryShort: "AG",
    currency_symbol: "$",
    states: [
    {
    state: "Barbuda",
    stateShort: "10",
    lat: "17.62662420",
    lng: "-61.77130280"
    },
    {
    state: "Redonda",
    stateShort: "11",
    lat: "16.93841600",
    lng: "-62.34551480"
    },
    {
    state: "Saint George Parish",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Saint John Parish",
    stateShort: "04",
    lat: null,
    lng: null
    },
    {
    state: "Saint Mary Parish",
    stateShort: "05",
    lat: null,
    lng: null
    },
    {
    state: "Saint Paul Parish",
    stateShort: "06",
    lat: null,
    lng: null
    },
    {
    state: "Saint Peter Parish",
    stateShort: "07",
    lat: null,
    lng: null
    },
    {
    state: "Saint Philip Parish",
    stateShort: "08",
    lat: "40.43682580",
    lng: "-80.06855320"
    }
    ]
    },
    {
    country: "Argentina",
    countryShort: "AR",
    currency_symbol: "$",
    states: [
    {
    state: "Buenos Aires",
    stateShort: "B",
    lat: "-37.20172850",
    lng: "-59.84106970"
    },
    {
    state: "Catamarca",
    stateShort: "K",
    lat: "-28.47158770",
    lng: "-65.78772090"
    },
    {
    state: "Chaco",
    stateShort: "H",
    lat: "-27.42571750",
    lng: "-59.02437840"
    },
    {
    state: "Chubut",
    stateShort: "U",
    lat: "-43.29342460",
    lng: "-65.11148180"
    },
    {
    state: "Ciudad Autónoma de Buenos Aires",
    stateShort: "C",
    lat: "-34.60368440",
    lng: "-58.38155910"
    },
    {
    state: "Córdoba",
    stateShort: "X",
    lat: "-31.39928760",
    lng: "-64.26438420"
    },
    {
    state: "Corrientes",
    stateShort: "W",
    lat: "-27.46921310",
    lng: "-58.83063490"
    },
    {
    state: "Entre Ríos",
    stateShort: "E",
    lat: "-31.77466540",
    lng: "-60.49564610"
    },
    {
    state: "Formosa",
    stateShort: "P",
    lat: "-26.18948040",
    lng: "-58.22428060"
    },
    {
    state: "Jujuy",
    stateShort: "Y",
    lat: "-24.18433970",
    lng: "-65.30217700"
    },
    {
    state: "La Pampa",
    stateShort: "L",
    lat: "-36.61475730",
    lng: "-64.28392090"
    },
    {
    state: "La Rioja",
    stateShort: "F",
    lat: "-29.41937930",
    lng: "-66.85599320"
    },
    {
    state: "Mendoza",
    stateShort: "M",
    lat: "-32.88945870",
    lng: "-68.84583860"
    },
    {
    state: "Misiones",
    stateShort: "N",
    lat: "-27.42692550",
    lng: "-55.94670760"
    },
    {
    state: "Neuquén",
    stateShort: "Q",
    lat: "-38.94587000",
    lng: "-68.07309250"
    },
    {
    state: "Río Negro",
    stateShort: "R",
    lat: "-40.82614340",
    lng: "-63.02663390"
    },
    {
    state: "Salta",
    stateShort: "A",
    lat: "-24.79976880",
    lng: "-65.41503670"
    },
    {
    state: "San Juan",
    stateShort: "J",
    lat: "-31.53169760",
    lng: "-68.56769620"
    },
    {
    state: "San Luis",
    stateShort: "D",
    lat: "-33.29620420",
    lng: "-66.32949480"
    },
    {
    state: "Santa Cruz",
    stateShort: "Z",
    lat: "-51.63528210",
    lng: "-69.24743530"
    },
    {
    state: "Santa Fe",
    stateShort: "S",
    lat: "-31.58551090",
    lng: "-60.72380160"
    },
    {
    state: "Santiago del Estero",
    stateShort: "G",
    lat: "-27.78335740",
    lng: "-64.26416700"
    },
    {
    state: "Tierra del Fuego",
    stateShort: "V",
    lat: "-54.80539980",
    lng: "-68.32420610"
    },
    {
    state: "Tucumán",
    stateShort: "T",
    lat: "-26.82211270",
    lng: "-65.21929030"
    }
    ]
    },
    {
    country: "Armenia",
    countryShort: "AM",
    currency_symbol: "֏",
    states: [
    {
    state: "Aragatsotn Region",
    stateShort: "AG",
    lat: "40.33473010",
    lng: "44.37482960"
    },
    {
    state: "Ararat Province",
    stateShort: "AR",
    lat: "39.91394150",
    lng: "44.72000040"
    },
    {
    state: "Armavir Region",
    stateShort: "AV",
    lat: "40.15546310",
    lng: "44.03724460"
    },
    {
    state: "Gegharkunik Province",
    stateShort: "GR",
    lat: "40.35264260",
    lng: "45.12604140"
    },
    {
    state: "Kotayk Region",
    stateShort: "KT",
    lat: "40.54102140",
    lng: "44.76901480"
    },
    {
    state: "Lori Region",
    stateShort: "LO",
    lat: "40.96984520",
    lng: "44.49001380"
    },
    {
    state: "Shirak Region",
    stateShort: "SH",
    lat: "40.96308140",
    lng: "43.81024610"
    },
    {
    state: "Syunik Province",
    stateShort: "SU",
    lat: "39.51331120",
    lng: "46.33932340"
    },
    {
    state: "Tavush Region",
    stateShort: "TV",
    lat: "40.88662960",
    lng: "45.33934900"
    },
    {
    state: "Vayots Dzor Region",
    stateShort: "VD",
    lat: "39.76419960",
    lng: "45.33375280"
    },
    {
    state: "Yerevan",
    stateShort: "ER",
    lat: "40.18720230",
    lng: "44.51520900"
    }
    ]
    },

    {
    country: "Australia",
    countryShort: "AU",
    currency_symbol: "$",
    states: [
    {
    state: "Australian Capital Territory",
    stateShort: "ACT",
    lat: "-35.47346790",
    lng: "149.01236790"
    },
    {
    state: "New South Wales",
    stateShort: "NSW",
    lat: "-31.25321830",
    lng: "146.92109900"
    },
    {
    state: "Northern Territory",
    stateShort: "NT",
    lat: "-19.49141080",
    lng: "132.55096030"
    },
    {
    state: "Queensland",
    stateShort: "QLD",
    lat: "-20.91757380",
    lng: "142.70279560"
    },
    {
    state: "South Australia",
    stateShort: "SA",
    lat: "-30.00023150",
    lng: "136.20915470"
    },
    {
    state: "Tasmania",
    stateShort: "TAS",
    lat: "-41.45451960",
    lng: "145.97066470"
    },
    {
    state: "Victoria",
    stateShort: "VIC",
    lat: "-36.48564230",
    lng: "140.97794250"
    },
    {
    state: "Western Australia",
    stateShort: "WA",
    lat: "-27.67281680",
    lng: "121.62830980"
    }
    ]
    },
    {
    country: "Austria",
    countryShort: "AT",
    currency_symbol: "€",
    states: [
    {
    state: "Burgenland",
    stateShort: "1",
    lat: "47.15371650",
    lng: "16.26887970"
    },
    {
    state: "Carinthia",
    stateShort: "2",
    lat: "46.72220300",
    lng: "14.18058820"
    },
    {
    state: "Niederösterreich",
    stateShort: "3",
    lat: "48.10807700",
    lng: "15.80495580"
    },
    {
    state: "Salzburg",
    stateShort: "5",
    lat: "47.80949000",
    lng: "13.05501000"
    },
    {
    state: "Styria",
    stateShort: "6",
    lat: "47.35934420",
    lng: "14.46998270"
    },
    {
    state: "Tyrol",
    stateShort: "7",
    lat: "47.25374140",
    lng: "11.60148700"
    },
    {
    state: "Oberösterreich",
    stateShort: "4",
    lat: "48.02585400",
    lng: "13.97236650"
    },
    {
    state: "Wien",
    stateShort: "9",
    lat: "48.20817430",
    lng: "16.37381890"
    },
    {
    state: "Vorarlberg",
    stateShort: "8",
    lat: "47.24974270",
    lng: "9.97973730"
    }
    ]
    },
    {
    country: "Azerbaijan",
    countryShort: "AZ",
    currency_symbol: "m",
    states: [
    {
    state: "Absheron District",
    stateShort: "ABS",
    lat: "40.36296930",
    lng: "49.27368150"
    },
    {
    state: "Agdam District",
    stateShort: "AGM",
    lat: "39.99318530",
    lng: "46.99495620"
    },
    {
    state: "Agdash District",
    stateShort: "AGS",
    lat: "40.63354270",
    lng: "47.46743100"
    },
    {
    state: "Aghjabadi District",
    stateShort: "AGC",
    lat: "28.78918410",
    lng: "77.51607880"
    },
    {
    state: "Agstafa District",
    stateShort: "AGA",
    lat: "41.26559330",
    lng: "45.51342910"
    },
    {
    state: "Agsu District",
    stateShort: "AGU",
    lat: "40.52833390",
    lng: "48.36508350"
    },
    {
    state: "Astara District",
    stateShort: "AST",
    lat: "38.49378450",
    lng: "48.69443650"
    },
    {
    state: "Babek District",
    stateShort: "BAB",
    lat: "39.15076130",
    lng: "45.44853680"
    },
    {
    state: "Baku",
    stateShort: "BA",
    lat: "40.40926170",
    lng: "49.86709240"
    },
    {
    state: "Balakan District",
    stateShort: "BAL",
    lat: "41.70375090",
    lng: "46.40442130"
    },
    {
    state: "Barda District",
    stateShort: "BAR",
    lat: "40.37065550",
    lng: "47.13789090"
    },
    {
    state: "Beylagan District",
    stateShort: "BEY",
    lat: "39.77230730",
    lng: "47.61541660"
    },
    {
    state: "Bilasuvar District",
    stateShort: "BIL",
    lat: "39.45988330",
    lng: "48.55098130"
    },
    {
    state: "Dashkasan District",
    stateShort: "DAS",
    lat: "40.52022570",
    lng: "46.07793040"
    },
    {
    state: "Fizuli District",
    stateShort: "FUZ",
    lat: "39.53786050",
    lng: "47.30338770"
    },
    {
    state: "Ganja",
    stateShort: "GA",
    lat: "36.36873380",
    lng: "-95.99857670"
    },
    {
    state: "Gədəbəy",
    stateShort: "GAD",
    lat: "40.56996390",
    lng: "45.81068830"
    },
    {
    state: "Gobustan District",
    stateShort: "QOB",
    lat: "40.53261040",
    lng: "48.92737500"
    },
    {
    state: "Goranboy District",
    stateShort: "GOR",
    lat: "40.53805060",
    lng: "46.59908910"
    },
    {
    state: "Goychay",
    stateShort: "GOY",
    lat: "40.62361680",
    lng: "47.74030340"
    },
    {
    state: "Goygol District",
    stateShort: "GYG",
    lat: "40.55953780",
    lng: "46.33149530"
    },
    {
    state: "Hajigabul District",
    stateShort: "HAC",
    lat: "40.03937700",
    lng: "48.92025330"
    },
    {
    state: "Imishli District",
    stateShort: "IMI",
    lat: "39.86946860",
    lng: "48.06652180"
    },
    {
    state: "Ismailli District",
    stateShort: "ISM",
    lat: "40.74299360",
    lng: "48.21255560"
    },
    {
    state: "Jabrayil District",
    stateShort: "CAB",
    lat: "39.26455440",
    lng: "46.96215620"
    },
    {
    state: "Jalilabad District",
    stateShort: "CAL",
    lat: "39.20516320",
    lng: "48.51006040"
    },
    {
    state: "Julfa District",
    stateShort: "CUL",
    lat: "38.96049830",
    lng: "45.62929390"
    },
    {
    state: "Kalbajar District",
    stateShort: "KAL",
    lat: "40.10243290",
    lng: "46.03648720"
    },
    {
    state: "Kangarli District",
    stateShort: "KAN",
    lat: "39.38719400",
    lng: "45.16398520"
    },
    {
    state: "Khachmaz District",
    stateShort: "XAC",
    lat: "41.45911680",
    lng: "48.80206260"
    },
    {
    state: "Khizi District",
    stateShort: "XIZ",
    lat: "40.91094890",
    lng: "49.07292640"
    },
    {
    state: "Khojali District",
    stateShort: "XCI",
    lat: "39.91325530",
    lng: "46.79430500"
    },
    {
    state: "Kurdamir District",
    stateShort: "KUR",
    lat: "40.36986510",
    lng: "48.16446260"
    },
    {
    state: "Lachin District",
    stateShort: "LAC",
    lat: "39.63834140",
    lng: "46.54608530"
    },
    {
    state: "Lankaran",
    stateShort: "LAN",
    lat: "38.75286690",
    lng: "48.84750150"
    },
    {
    state: "Lankaran District",
    stateShort: "LA",
    lat: "38.75286690",
    lng: "48.84750150"
    },
    {
    state: "Lerik District",
    stateShort: "LER",
    lat: "38.77361920",
    lng: "48.41514830"
    },
    {
    state: "Martuni",
    stateShort: "XVD",
    lat: "39.79146930",
    lng: "47.11008140"
    },
    {
    state: "Masally District",
    stateShort: "MAS",
    lat: "39.03407220",
    lng: "48.65893540"
    },
    {
    state: "Mingachevir",
    stateShort: "MI",
    lat: "40.77025630",
    lng: "47.04960150"
    },
    {
    state: "Nakhchivan Autonomous Republic",
    stateShort: "NX",
    lat: "39.32568140",
    lng: "45.49126480"
    },
    {
    state: "Neftchala District",
    stateShort: "NEF",
    lat: "39.38810520",
    lng: "49.24137430"
    },
    {
    state: "Oghuz District",
    stateShort: "OGU",
    lat: "41.07279240",
    lng: "47.46506720"
    },
    {
    state: "Ordubad District",
    stateShort: "ORD",
    lat: "38.90216220",
    lng: "46.02376250"
    },
    {
    state: "Qabala District",
    stateShort: "QAB",
    lat: "40.92539250",
    lng: "47.80161060"
    },
    {
    state: "Qakh District",
    stateShort: "QAX",
    lat: "41.42068270",
    lng: "46.93201840"
    },
    {
    state: "Qazakh District",
    stateShort: "QAZ",
    lat: "41.09710740",
    lng: "45.35163310"
    },
    {
    state: "Quba District",
    stateShort: "QBA",
    lat: "41.15642420",
    lng: "48.41350210"
    },
    {
    state: "Qubadli District",
    stateShort: "QBI",
    lat: "39.27139960",
    lng: "46.63543120"
    },
    {
    state: "Qusar District",
    stateShort: "QUS",
    lat: "41.42668860",
    lng: "48.43455770"
    },
    {
    state: "Saatly District",
    stateShort: "SAT",
    lat: "39.90955030",
    lng: "48.35951220"
    },
    {
    state: "Sabirabad District",
    stateShort: "SAB",
    lat: "39.98706630",
    lng: "48.46925450"
    },
    {
    state: "Sadarak District",
    stateShort: "SAD",
    lat: "39.71051140",
    lng: "44.88642770"
    },
    {
    state: "Salyan District",
    stateShort: "SAL",
    lat: "28.35248110",
    lng: "82.12784000"
    },
    {
    state: "Samukh District",
    stateShort: "SMX",
    lat: "40.76046310",
    lng: "46.40631810"
    },
    {
    state: "Shabran District",
    stateShort: "SBN",
    lat: "41.22283760",
    lng: "48.84573040"
    },
    {
    state: "Shahbuz District",
    stateShort: "SAH",
    lat: "39.44521030",
    lng: "45.65680090"
    },
    {
    state: "Shaki",
    stateShort: "SA",
    lat: "41.19747530",
    lng: "47.15712410"
    },
    {
    state: "Shaki District",
    stateShort: "SAK",
    lat: "41.11346620",
    lng: "47.13169270"
    },
    {
    state: "Shamakhi District",
    stateShort: "SMI",
    lat: "40.63187310",
    lng: "48.63638010"
    },
    {
    state: "Shamkir District",
    stateShort: "SKR",
    lat: "40.82881440",
    lng: "46.01668790"
    },
    {
    state: "Sharur District",
    stateShort: "SAR",
    lat: "39.55363320",
    lng: "44.98456800"
    },
    {
    state: "Shirvan",
    stateShort: "SR",
    lat: "39.94697070",
    lng: "48.92239190"
    },
    {
    state: "Shusha District",
    stateShort: "SUS",
    lat: "39.75374380",
    lng: "46.74647550"
    },
    {
    state: "Siazan District",
    stateShort: "SIY",
    lat: "41.07838330",
    lng: "49.11184770"
    },
    {
    state: "Sumqayit",
    stateShort: "SM",
    lat: "40.58547650",
    lng: "49.63174110"
    },
    {
    state: "Tartar District",
    stateShort: "TAR",
    lat: "40.34438750",
    lng: "46.93765190"
    },
    {
    state: "Tovuz District",
    stateShort: "TOV",
    lat: "40.99545230",
    lng: "45.61659070"
    },
    {
    state: "Ujar District",
    stateShort: "UCA",
    lat: "40.50675250",
    lng: "47.64896410"
    },
    {
    state: "Yardymli District",
    stateShort: "YAR",
    lat: "38.90589170",
    lng: "48.24961270"
    },
    {
    state: "Yevlakh",
    stateShort: "YE",
    lat: "40.61966380",
    lng: "47.15003240"
    },
    {
    state: "Yevlakh District",
    stateShort: "YEV",
    lat: "40.61966380",
    lng: "47.15003240"
    },
    {
    state: "Zangilan District",
    stateShort: "ZAN",
    lat: "39.08568990",
    lng: "46.65247280"
    },
    {
    state: "Zaqatala District",
    stateShort: "ZAQ",
    lat: "41.59068890",
    lng: "46.72403730"
    },
    {
    state: "Zardab District",
    stateShort: "ZAR",
    lat: "40.21481140",
    lng: "47.71494400"
    }
    ]
    },
    {
    country: "Bahrain",
    countryShort: "BH",
    currency_symbol: ".د.ب",
    states: [
    {
    state: "Capital",
    stateShort: "13",
    lat: null,
    lng: null
    },
    {
    state: "Central",
    stateShort: "16",
    lat: "26.14260930",
    lng: "50.56532940"
    },
    {
    state: "Muharraq",
    stateShort: "15",
    lat: "26.26856530",
    lng: "50.64825170"
    },
    {
    state: "Northern",
    stateShort: "17",
    lat: "26.15519140",
    lng: "50.48251730"
    },
    {
    state: "Southern",
    stateShort: "14",
    lat: "25.93810180",
    lng: "50.57568870"
    }
    ]
    },
    {
    country: "Bangladesh",
    countryShort: "BD",
    currency_symbol: "৳",
    states: [
    {
    state: "Bagerhat District",
    stateShort: "05",
    lat: "22.66024360",
    lng: "89.78954780"
    },
    {
    state: "Bahadia",
    stateShort: "33",
    lat: "23.78987120",
    lng: "90.16714830"
    },
    {
    state: "Bandarban District",
    stateShort: "01",
    lat: "21.83110020",
    lng: "92.36863210"
    },
    {
    state: "Barguna District",
    stateShort: "02",
    lat: "22.09529150",
    lng: "90.11206960"
    },
    {
    state: "Barisal District",
    stateShort: "06",
    lat: "22.70220980",
    lng: "90.36963160"
    },
    {
    state: "Barisal Division",
    stateShort: "A",
    lat: "22.38111310",
    lng: "90.33718890"
    },
    {
    state: "Bhola District",
    stateShort: "07",
    lat: "22.17853150",
    lng: "90.71010230"
    },
    {
    state: "Bogra District",
    stateShort: "03",
    lat: "24.85104020",
    lng: "89.36972250"
    },
    {
    state: "Brahmanbaria District",
    stateShort: "04",
    lat: "23.96081810",
    lng: "91.11150140"
    },
    {
    state: "Chandpur District",
    stateShort: "09",
    lat: "23.25131480",
    lng: "90.85178460"
    },
    {
    state: "Chapai Nawabganj District",
    stateShort: "45",
    lat: "24.74131110",
    lng: "88.29120690"
    },
    {
    state: "Chittagong District",
    stateShort: "10",
    lat: "22.51501050",
    lng: "91.75388170"
    },
    {
    state: "Chittagong Division",
    stateShort: "B",
    lat: "23.17931570",
    lng: "91.98815270"
    },
    {
    state: "Chuadanga District",
    stateShort: "12",
    lat: "23.61605120",
    lng: "88.82630060"
    },
    {
    state: "Comilla District",
    stateShort: "08",
    lat: "23.45756670",
    lng: "91.18089960"
    },
    {
    state: "Cox's Bazar District",
    stateShort: "11",
    lat: "21.56406260",
    lng: "92.02821290"
    },
    {
    state: "Dhaka District",
    stateShort: "13",
    lat: "23.81051400",
    lng: "90.33718890"
    },
    {
    state: "Dhaka Division",
    stateShort: "C",
    lat: "23.95357420",
    lng: "90.14949880"
    },
    {
    state: "Dinajpur District",
    stateShort: "14",
    lat: "25.62791230",
    lng: "88.63317580"
    },
    {
    state: "Faridpur District",
    stateShort: "15",
    lat: "23.54239190",
    lng: "89.63089210"
    },
    {
    state: "Feni District",
    stateShort: "16",
    lat: "22.94087840",
    lng: "91.40666460"
    },
    {
    state: "Gaibandha District",
    stateShort: "19",
    lat: "25.32969280",
    lng: "89.54296520"
    },
    {
    state: "Gazipur District",
    stateShort: "18",
    lat: "24.09581710",
    lng: "90.41251810"
    },
    {
    state: "Gopalganj District",
    stateShort: "17",
    lat: "26.48315840",
    lng: "84.43655000"
    },
    {
    state: "Habiganj District",
    stateShort: "20",
    lat: "24.47712360",
    lng: "91.45065650"
    },
    {
    state: "Jamalpur District",
    stateShort: "21",
    lat: "25.08309260",
    lng: "89.78532180"
    },
    {
    state: "Jessore District",
    stateShort: "22",
    lat: "23.16340140",
    lng: "89.21816640"
    },
    {
    state: "Jhalokati District",
    stateShort: "25",
    lat: "22.57208000",
    lng: "90.18696440"
    },
    {
    state: "Jhenaidah District",
    stateShort: "23",
    lat: "23.54498730",
    lng: "89.17260310"
    },
    {
    state: "Joypurhat District",
    stateShort: "24",
    lat: "25.09473490",
    lng: "89.09449370"
    },
    {
    state: "Khagrachari District",
    stateShort: "29",
    lat: "23.13217510",
    lng: "91.94902100"
    },
    {
    state: "Khulna District",
    stateShort: "27",
    lat: "22.67377350",
    lng: "89.39665810"
    },
    {
    state: "Khulna Division",
    stateShort: "D",
    lat: "22.80878160",
    lng: "89.24671910"
    },
    {
    state: "Kishoreganj District",
    stateShort: "26",
    lat: "24.42604570",
    lng: "90.98206680"
    },
    {
    state: "Kurigram District",
    stateShort: "28",
    lat: "25.80724140",
    lng: "89.62947460"
    },
    {
    state: "Kushtia District",
    stateShort: "30",
    lat: "23.89069950",
    lng: "89.10993680"
    },
    {
    state: "Lakshmipur District",
    stateShort: "31",
    lat: "22.94467440",
    lng: "90.82819070"
    },
    {
    state: "Lalmonirhat District",
    stateShort: "32",
    lat: "25.99233980",
    lng: "89.28472510"
    },
    {
    state: "Madaripur District",
    stateShort: "36",
    lat: "23.23933460",
    lng: "90.18696440"
    },
    {
    state: "Meherpur District",
    stateShort: "39",
    lat: "23.80519910",
    lng: "88.67235780"
    },
    {
    state: "Moulvibazar District",
    stateShort: "38",
    lat: "24.30953440",
    lng: "91.73149030"
    },
    {
    state: "Munshiganj District",
    stateShort: "35",
    lat: "23.49809310",
    lng: "90.41266210"
    },
    {
    state: "Mymensingh District",
    stateShort: "34",
    lat: "24.75385750",
    lng: "90.40729190"
    },
    {
    state: "Mymensingh Division",
    stateShort: "H",
    lat: "24.71362000",
    lng: "90.45023680"
    },
    {
    state: "Naogaon District",
    stateShort: "48",
    lat: "24.91315970",
    lng: "88.75309520"
    },
    {
    state: "Narail District",
    stateShort: "43",
    lat: "23.11629290",
    lng: "89.58404040"
    },
    {
    state: "Narayanganj District",
    stateShort: "40",
    lat: "23.71466010",
    lng: "90.56360900"
    },
    {
    state: "Natore District",
    stateShort: "44",
    lat: "24.41024300",
    lng: "89.00761770"
    },
    {
    state: "Netrokona District",
    stateShort: "41",
    lat: "24.81032840",
    lng: "90.86564150"
    },
    {
    state: "Nilphamari District",
    stateShort: "46",
    lat: "25.84827980",
    lng: "88.94141340"
    },
    {
    state: "Noakhali District",
    stateShort: "47",
    lat: "22.87237890",
    lng: "91.09731840"
    },
    {
    state: "Pabna District",
    stateShort: "49",
    lat: "24.15850500",
    lng: "89.44807180"
    },
    {
    state: "Panchagarh District",
    stateShort: "52",
    lat: "26.27087050",
    lng: "88.59517510"
    },
    {
    state: "Patuakhali District",
    stateShort: "51",
    lat: "22.22486320",
    lng: "90.45475030"
    },
    {
    state: "Pirojpur District",
    stateShort: "50",
    lat: "22.57907440",
    lng: "89.97592640"
    },
    {
    state: "Rajbari District",
    stateShort: "53",
    lat: "23.71513400",
    lng: "89.58748190"
    },
    {
    state: "Rajshahi District",
    stateShort: "54",
    lat: "24.37330870",
    lng: "88.60487160"
    },
    {
    state: "Rajshahi Division",
    stateShort: "E",
    lat: "24.71057760",
    lng: "88.94138650"
    },
    {
    state: "Rangamati Hill District",
    stateShort: "56",
    lat: "22.73241730",
    lng: "92.29851340"
    },
    {
    state: "Rangpur District",
    stateShort: "55",
    lat: "25.74679250",
    lng: "89.25083350"
    },
    {
    state: "Rangpur Division",
    stateShort: "F",
    lat: "25.84833880",
    lng: "88.94138650"
    },
    {
    state: "Satkhira District",
    stateShort: "58",
    lat: "22.31548120",
    lng: "89.11145250"
    },
    {
    state: "Shariatpur District",
    stateShort: "62",
    lat: "23.24232140",
    lng: "90.43477110"
    },
    {
    state: "Sherpur District",
    stateShort: "57",
    lat: "25.07462350",
    lng: "90.14949040"
    },
    {
    state: "Sirajganj District",
    stateShort: "59",
    lat: "24.31411150",
    lng: "89.56996150"
    },
    {
    state: "Sunamganj District",
    stateShort: "61",
    lat: "25.07145350",
    lng: "91.39916270"
    },
    {
    state: "Sylhet District",
    stateShort: "60",
    lat: "24.89933570",
    lng: "91.87004730"
    },
    {
    state: "Sylhet Division",
    stateShort: "G",
    lat: "24.70498110",
    lng: "91.67606910"
    },
    {
    state: "Tangail District",
    stateShort: "63",
    lat: "24.39174270",
    lng: "89.99482570"
    },
    {
    state: "Thakurgaon District",
    stateShort: "64",
    lat: "26.04183920",
    lng: "88.42826160"
    }
    ]
    },
    {
    country: "Barbados",
    countryShort: "BB",
    currency_symbol: "Bds$",
    states: [
    {
    state: "Christ Church",
    stateShort: "01",
    lat: "36.00604070",
    lng: "-95.92112100"
    },
    {
    state: "Saint Andrew",
    stateShort: "02",
    lat: null,
    lng: null
    },
    {
    state: "Saint George",
    stateShort: "03",
    lat: "37.09652780",
    lng: "-113.56841640"
    },
    {
    state: "Saint James",
    stateShort: "04",
    lat: "48.52356600",
    lng: "-1.32378850"
    },
    {
    state: "Saint John",
    stateShort: "05",
    lat: "45.27331530",
    lng: "-66.06330800"
    },
    {
    state: "Saint Joseph",
    stateShort: "06",
    lat: "39.76745780",
    lng: "-94.84668100"
    },
    {
    state: "Saint Lucy",
    stateShort: "07",
    lat: "38.76146250",
    lng: "-77.44914390"
    },
    {
    state: "Saint Michael",
    stateShort: "08",
    lat: "36.03597700",
    lng: "-95.84905200"
    },
    {
    state: "Saint Peter",
    stateShort: "09",
    lat: "37.08271190",
    lng: "-94.51712500"
    },
    {
    state: "Saint Philip",
    stateShort: "10",
    lat: "35.23311400",
    lng: "-89.43640420"
    },
    {
    state: "Saint Thomas",
    stateShort: "11",
    lat: "18.33809650",
    lng: "-64.89409460"
    }
    ]
    },
    {
    country: "Belarus",
    countryShort: "BY",
    currency_symbol: "Br",
    states: [
    {
    state: "Brest Region",
    stateShort: "BR",
    lat: "52.52966410",
    lng: "25.46064800"
    },
    {
    state: "Gomel Region",
    stateShort: "HO",
    lat: "52.16487540",
    lng: "29.13332510"
    },
    {
    state: "Grodno Region",
    stateShort: "HR",
    lat: "53.65999450",
    lng: "25.34485710"
    },
    {
    state: "Minsk",
    stateShort: "HM",
    lat: "53.90060110",
    lng: "27.55897200"
    },
    {
    state: "Minsk Region",
    stateShort: "MI",
    lat: "54.10678890",
    lng: "27.41292450"
    },
    {
    state: "Mogilev Region",
    stateShort: "MA",
    lat: "53.51017910",
    lng: "30.40064440"
    },
    {
    state: "Vitebsk Region",
    stateShort: "VI",
    lat: "55.29598330",
    lng: "28.75836270"
    }
    ]
    },
    {
    country: "Belgium",
    countryShort: "BE",
    currency_symbol: "€",
    states: [
    {
    state: "Antwerp",
    stateShort: "VAN",
    lat: "51.21944750",
    lng: "4.40246430"
    },
    {
    state: "Bruxelles",
    stateShort: "BRU",
    lat: "50.85034630",
    lng: "4.35172110"
    },
    {
    state: "East Flanders",
    stateShort: "VOV",
    lat: "51.03621010",
    lng: "3.73731240"
    },
    {
    state: "Vlaams Gewest",
    stateShort: "VLG",
    lat: "51.01087060",
    lng: "3.72646130"
    },
    {
    state: "Flemish Brabant",
    stateShort: "VBR",
    lat: "50.88154340",
    lng: "4.56459700"
    },
    {
    state: "Hainaut",
    stateShort: "WHT",
    lat: "50.52570760",
    lng: "4.06210170"
    },
    {
    state: "Liège",
    stateShort: "WLG",
    lat: "50.63255740",
    lng: "5.57966620"
    },
    {
    state: "Limburg",
    stateShort: "VLI",
    lat: null,
    lng: null
    },
    {
    state: "Luxembourg",
    stateShort: "WLX",
    lat: "49.81527300",
    lng: "6.12958300"
    },
    {
    state: "Namur",
    stateShort: "WNA",
    lat: "50.46738830",
    lng: "4.87198540"
    },
    {
    state: "Wallonia",
    stateShort: "WAL",
    lat: "50.41756370",
    lng: "4.45100660"
    },
    {
    state: "Walloon Brabant",
    stateShort: "WBR",
    lat: "50.63324100",
    lng: "4.52431500"
    },
    {
    state: "West Flanders",
    stateShort: "VWV",
    lat: "40.01793340",
    lng: "-105.28067330"
    }
    ]
    },
    {
    country: "Belize",
    countryShort: "BZ",
    currency_symbol: "$",
    states: [
    {
    state: "Belize District",
    stateShort: "BZ",
    lat: "17.56776790",
    lng: "-88.40160410"
    },
    {
    state: "Cayo District",
    stateShort: "CY",
    lat: "17.09844450",
    lng: "-88.94138650"
    },
    {
    state: "Corozal District",
    stateShort: "CZL",
    lat: "18.13492380",
    lng: "-88.24611830"
    },
    {
    state: "Orange Walk District",
    stateShort: "OW",
    lat: "17.76035300",
    lng: "-88.86469800"
    },
    {
    state: "Stann Creek District",
    stateShort: "SC",
    lat: "16.81166310",
    lng: "-88.40160410"
    },
    {
    state: "Toledo District",
    stateShort: "TOL",
    lat: "16.24919230",
    lng: "-88.86469800"
    }
    ]
    },
    {
    country: "Benin",
    countryShort: "BJ",
    currency_symbol: "CFA",
    states: [
    {
    state: "Alibori Department",
    stateShort: "AL",
    lat: "10.96810930",
    lng: "2.77798130"
    },
    {
    state: "Atakora Department",
    stateShort: "AK",
    lat: "10.79549310",
    lng: "1.67606910"
    },
    {
    state: "Atlantique Department",
    stateShort: "AQ",
    lat: "6.65883910",
    lng: "2.22366670"
    },
    {
    state: "Borgou Department",
    stateShort: "BO",
    lat: "9.53408640",
    lng: "2.77798130"
    },
    {
    state: "Collines Department",
    stateShort: "CO",
    lat: "8.30222970",
    lng: "2.30244600"
    },
    {
    state: "Donga Department",
    stateShort: "DO",
    lat: "9.71918670",
    lng: "1.67606910"
    },
    {
    state: "Kouffo Department",
    stateShort: "KO",
    lat: "7.00358940",
    lng: "1.75388170"
    },
    {
    state: "Littoral Department",
    stateShort: "LI",
    lat: "6.38069730",
    lng: "2.44063870"
    },
    {
    state: "Mono Department",
    stateShort: "MO",
    lat: "37.92186080",
    lng: "-118.95286450"
    },
    {
    state: "Ouémé Department",
    stateShort: "OU",
    lat: "6.61481520",
    lng: "2.49999180"
    },
    {
    state: "Plateau Department",
    stateShort: "PL",
    lat: "7.34451410",
    lng: "2.53960300"
    },
    {
    state: "Zou Department",
    stateShort: "ZO",
    lat: "7.34692680",
    lng: "2.06651970"
    }
    ]
    },
    {
    country: "Bermuda",
    countryShort: "BM",
    currency_symbol: "$",
    states: [
    {
    state: "Devonshire Parish",
    stateShort: "DEV",
    lat: "32.30380620",
    lng: "-64.76069540"
    },
    {
    state: "Hamilton Parish",
    stateShort: "HA",
    lat: "32.34494320",
    lng: "-64.72365000"
    },
    {
    state: "Paget Parish",
    stateShort: "PAG",
    lat: "32.28107400",
    lng: "-64.77847870"
    },
    {
    state: "Pembroke Parish",
    stateShort: "PEM",
    lat: "32.30076720",
    lng: "-64.79626300"
    },
    {
    state: "Saint George's Parish",
    stateShort: "SGE",
    lat: "17.12577590",
    lng: "-62.56198110"
    },
    {
    state: "Sandys Parish",
    stateShort: "SAN",
    lat: "32.29995280",
    lng: "-64.86741030"
    },
    {
    state: "Smith's Parish,",
    stateShort: "SMI",
    lat: "32.31339660",
    lng: "-64.73105880"
    },
    {
    state: "Southampton Parish",
    stateShort: "SOU",
    lat: "32.25400950",
    lng: "-64.82590580"
    },
    {
    state: "Warwick Parish",
    stateShort: "WAR",
    lat: "32.26615340",
    lng: "-64.80811980"
    }
    ]
    },
    {
    country: "Bhutan",
    countryShort: "BT",
    currency_symbol: "Nu.",
    states: [
    {
    state: "Bumthang District",
    stateShort: "33",
    lat: "27.64183900",
    lng: "90.67730460"
    },
    {
    state: "Chukha District",
    stateShort: "12",
    lat: "27.07843040",
    lng: "89.47421770"
    },
    {
    state: "Dagana District",
    stateShort: "22",
    lat: "27.03228610",
    lng: "89.88793040"
    },
    {
    state: "Gasa District",
    stateShort: "GA",
    lat: "28.01858860",
    lng: "89.92532330"
    },
    {
    state: "Haa District",
    stateShort: "13",
    lat: "27.26516690",
    lng: "89.17059980"
    },
    {
    state: "Lhuntse District",
    stateShort: "44",
    lat: "27.82649890",
    lng: "91.13530200"
    },
    {
    state: "Mongar District",
    stateShort: "42",
    lat: "27.26170590",
    lng: "91.28910360"
    },
    {
    state: "Paro District",
    stateShort: "11",
    lat: "27.42859490",
    lng: "89.41665160"
    },
    {
    state: "Pemagatshel District",
    stateShort: "43",
    lat: "27.00238200",
    lng: "91.34692470"
    },
    {
    state: "Punakha District",
    stateShort: "23",
    lat: "27.69037160",
    lng: "89.88793040"
    },
    {
    state: "Samdrup Jongkhar District",
    stateShort: "45",
    lat: "26.80356820",
    lng: "91.50392070"
    },
    {
    state: "Samtse District",
    stateShort: "14",
    lat: "27.02918320",
    lng: "89.05615320"
    },
    {
    state: "Sarpang District",
    stateShort: "31",
    lat: "26.93730410",
    lng: "90.48799160"
    },
    {
    state: "Thimphu District",
    stateShort: "15",
    lat: "27.47122160",
    lng: "89.63390410"
    },
    {
    state: "Trashigang District",
    stateShort: "41",
    lat: "27.25667950",
    lng: "91.75388170"
    },
    {
    state: "Trongsa District",
    stateShort: "32",
    lat: "27.50022690",
    lng: "90.50806340"
    },
    {
    state: "Tsirang District",
    stateShort: "21",
    lat: "27.03220700",
    lng: "90.18696440"
    },
    {
    state: "Wangdue Phodrang District",
    stateShort: "24",
    lat: "27.45260460",
    lng: "90.06749280"
    },
    {
    state: "Zhemgang District",
    stateShort: "34",
    lat: "27.07697500",
    lng: "90.82940020"
    }
    ]
    },
    {
    country: "Bolivia",
    countryShort: "BO",
    currency_symbol: "Bs.",
    states: [
    {
    state: "Beni Department",
    stateShort: "B",
    lat: "-14.37827470",
    lng: "-65.09577920"
    },
    {
    state: "Chuquisaca Department",
    stateShort: "H",
    lat: "-20.02491440",
    lng: "-64.14782360"
    },
    {
    state: "Cochabamba Department",
    stateShort: "C",
    lat: "-17.56816750",
    lng: "-65.47573600"
    },
    {
    state: "La Paz Department",
    stateShort: "L",
    lat: null,
    lng: null
    },
    {
    state: "Oruro Department",
    stateShort: "O",
    lat: "-18.57115790",
    lng: "-67.76159830"
    },
    {
    state: "Pando Department",
    stateShort: "N",
    lat: "-10.79889010",
    lng: "-66.99880110"
    },
    {
    state: "Potosí Department",
    stateShort: "P",
    lat: "-20.62471300",
    lng: "-66.99880110"
    },
    {
    state: "Santa Cruz Department",
    stateShort: "S",
    lat: "-16.74760370",
    lng: "-62.07509980"
    },
    {
    state: "Tarija Department",
    stateShort: "T",
    lat: "-21.58315950",
    lng: "-63.95861110"
    }
    ]
    },
    {
    country: "Bonaire, Sint Eustatius and Saba",
    countryShort: "BQ",
    currency_symbol: "$",
    states: [
    {
    state: "Bonaire",
    stateShort: "BQ1",
    lat: "12.20189020",
    lng: "-68.26238220"
    },
    {
    state: "Saba",
    stateShort: "BQ2",
    lat: "17.63546420",
    lng: "-63.23267630"
    },
    {
    state: "Sint Eustatius",
    stateShort: "BQ3",
    lat: "17.48903060",
    lng: "-62.97355500"
    }
    ]
    },
    {
    country: "Bosnia and Herzegovina",
    countryShort: "BA",
    currency_symbol: "KM",
    states: [
    {
    state: "Bosnian Podrinje Canton",
    stateShort: "05",
    lat: "43.68749000",
    lng: "18.82443940"
    },
    {
    state: "Brčko District",
    stateShort: "BRC",
    lat: "44.84059440",
    lng: "18.74215300"
    },
    {
    state: "Canton 10",
    stateShort: "10",
    lat: "43.95341550",
    lng: "16.94251870"
    },
    {
    state: "Central Bosnia Canton",
    stateShort: "06",
    lat: "44.13818560",
    lng: "17.68667140"
    },
    {
    state: "Federation of Bosnia and Herzegovina",
    stateShort: "BIH",
    lat: "43.88748970",
    lng: "17.84279300"
    },
    {
    state: "Herzegovina-Neretva Canton",
    stateShort: "07",
    lat: "43.52651590",
    lng: "17.76362100"
    },
    {
    state: "Posavina Canton",
    stateShort: "02",
    lat: "45.07520940",
    lng: "18.37763040"
    },
    {
    state: "Republika Srpska",
    stateShort: "SRP",
    lat: "44.72801860",
    lng: "17.31481360"
    },
    {
    state: "Sarajevo Canton",
    stateShort: "09",
    lat: "43.85125640",
    lng: "18.29534420"
    },
    {
    state: "Tuzla Canton",
    stateShort: "03",
    lat: "44.53434630",
    lng: "18.69727970"
    },
    {
    state: "Una-Sana Canton",
    stateShort: "01",
    lat: "44.65031160",
    lng: "16.31716290"
    },
    {
    state: "West Herzegovina Canton",
    stateShort: "08",
    lat: "43.43692440",
    lng: "17.38488310"
    },
    {
    state: "Zenica-Doboj Canton",
    stateShort: "04",
    lat: "44.21271090",
    lng: "18.16046250"
    }
    ]
    },
    {
    country: "Botswana",
    countryShort: "BW",
    currency_symbol: "P",
    states: [
    {
    state: "Central District",
    stateShort: "CE",
    lat: null,
    lng: null
    },
    {
    state: "Ghanzi District",
    stateShort: "GH",
    lat: "-21.86523140",
    lng: "21.85685860"
    },
    {
    state: "Kgalagadi District",
    stateShort: "KG",
    lat: "-24.75502850",
    lng: "21.85685860"
    },
    {
    state: "Kgatleng District",
    stateShort: "KL",
    lat: "-24.19704450",
    lng: "26.23046160"
    },
    {
    state: "Kweneng District",
    stateShort: "KW",
    lat: "-23.83672490",
    lng: "25.28375850"
    },
    {
    state: "Ngamiland",
    stateShort: "NG",
    lat: "-19.19053210",
    lng: "23.00119890"
    },
    {
    state: "North-East District",
    stateShort: "NE",
    lat: "37.58844610",
    lng: "-94.68637820"
    },
    {
    state: "North-West District",
    stateShort: "NW",
    lat: "39.34463070",
    lng: "-76.68542830"
    },
    {
    state: "South-East District",
    stateShort: "SE",
    lat: "31.21637980",
    lng: "-82.35270440"
    },
    {
    state: "Southern District",
    stateShort: "SO",
    lat: null,
    lng: null
    }
    ]
    },

    {
    country: "Brazil",
    countryShort: "BR",
    currency_symbol: "R$",
    states: [
    {
    state: "Acre",
    stateShort: "AC",
    lat: "-9.02379600",
    lng: "-70.81199500"
    },
    {
    state: "Alagoas",
    stateShort: "AL",
    lat: "-9.57130580",
    lng: "-36.78195050"
    },
    {
    state: "Amapá",
    stateShort: "AP",
    lat: "0.90199250",
    lng: "-52.00295650"
    },
    {
    state: "Amazonas",
    stateShort: "AM",
    lat: "-3.07000000",
    lng: "-61.66000000"
    },
    {
    state: "Bahia",
    stateShort: "BA",
    lat: "-11.40987400",
    lng: "-41.28085700"
    },
    {
    state: "Ceará",
    stateShort: "CE",
    lat: "-5.49839770",
    lng: "-39.32062410"
    },
    {
    state: "Distrito Federal",
    stateShort: "DF",
    lat: "-15.79976540",
    lng: "-47.86447150"
    },
    {
    state: "Espírito Santo",
    stateShort: "ES",
    lat: "-19.18342290",
    lng: "-40.30886260"
    },
    {
    state: "Goiás",
    stateShort: "GO",
    lat: "-15.82703690",
    lng: "-49.83622370"
    },
    {
    state: "Maranhão",
    stateShort: "MA",
    lat: "-4.96094980",
    lng: "-45.27441590"
    },
    {
    state: "Mato Grosso",
    stateShort: "MT",
    lat: "-12.68187120",
    lng: "-56.92109900"
    },
    {
    state: "Mato Grosso do Sul",
    stateShort: "MS",
    lat: "-20.77222950",
    lng: "-54.78515310"
    },
    {
    state: "Minas Gerais",
    stateShort: "MG",
    lat: "-18.51217800",
    lng: "-44.55503080"
    },
    {
    state: "Pará",
    stateShort: "PA",
    lat: "-1.99812710",
    lng: "-54.93061520"
    },
    {
    state: "Paraíba",
    stateShort: "PB",
    lat: "-7.23996090",
    lng: "-36.78195050"
    },
    {
    state: "Paraná",
    stateShort: "PR",
    lat: "-25.25208880",
    lng: "-52.02154150"
    },
    {
    state: "Pernambuco",
    stateShort: "PE",
    lat: "-8.81371730",
    lng: "-36.95410700"
    },
    {
    state: "Piauí",
    stateShort: "PI",
    lat: "-7.71834010",
    lng: "-42.72892360"
    },
    {
    state: "Rio de Janeiro",
    stateShort: "RJ",
    lat: "-22.90684670",
    lng: "-43.17289650"
    },
    {
    state: "Rio Grande do Norte",
    stateShort: "RN",
    lat: "-5.40258030",
    lng: "-36.95410700"
    },
    {
    state: "Rio Grande do Sul",
    stateShort: "RS",
    lat: "-30.03463160",
    lng: "-51.21769860"
    },
    {
    state: "Rondônia",
    stateShort: "RO",
    lat: "-11.50573410",
    lng: "-63.58061100"
    },
    {
    state: "Roraima",
    stateShort: "RR",
    lat: "2.73759710",
    lng: "-62.07509980"
    },
    {
    state: "Santa Catarina",
    stateShort: "SC",
    lat: "-27.33000000",
    lng: "-49.44000000"
    },
    {
    state: "São Paulo",
    stateShort: "SP",
    lat: "-23.55051990",
    lng: "-46.63330940"
    },
    {
    state: "Sergipe",
    stateShort: "SE",
    lat: "-10.57409340",
    lng: "-37.38565810"
    },
    {
    state: "Tocantins",
    stateShort: "TO",
    lat: "-10.17528000",
    lng: "-48.29824740"
    }
    ]
    },

    {
    country: "Brunei",
    countryShort: "BN",
    currency_symbol: "B$",
    states: [
    {
    state: "Belait District",
    stateShort: "BE",
    lat: "4.37507490",
    lng: "114.61928990"
    },
    {
    state: "Brunei-Muara District",
    stateShort: "BM",
    lat: "4.93112060",
    lng: "114.95168690"
    },
    {
    state: "Temburong District",
    stateShort: "TE",
    lat: "4.62041280",
    lng: "115.14148400"
    },
    {
    state: "Tutong District",
    stateShort: "TU",
    lat: "4.71403730",
    lng: "114.66679390"
    }
    ]
    },
    {
    country: "Bulgaria",
    countryShort: "BG",
    currency_symbol: "Лв.",
    states: [
    {
    state: "Blagoevgrad Province",
    stateShort: "01",
    lat: "42.02086140",
    lng: "23.09433560"
    },
    {
    state: "Burgas Province",
    stateShort: "02",
    lat: "42.50480000",
    lng: "27.46260790"
    },
    {
    state: "Dobrich Province",
    stateShort: "08",
    lat: "43.57278600",
    lng: "27.82728020"
    },
    {
    state: "Gabrovo Province",
    stateShort: "07",
    lat: "42.86847000",
    lng: "25.31688900"
    },
    {
    state: "Haskovo Province",
    stateShort: "26",
    lat: "41.93441780",
    lng: "25.55546720"
    },
    {
    state: "Kardzhali Province",
    stateShort: "09",
    lat: "41.63384160",
    lng: "25.37766870"
    },
    {
    state: "Kyustendil Province",
    stateShort: "10",
    lat: "42.28687990",
    lng: "22.69396350"
    },
    {
    state: "Lovech Province",
    stateShort: "11",
    lat: "43.13677980",
    lng: "24.71393350"
    },
    {
    state: "Montana Province",
    stateShort: "12",
    lat: "43.40851480",
    lng: "23.22575890"
    },
    {
    state: "Pazardzhik Province",
    stateShort: "13",
    lat: "42.19275670",
    lng: "24.33362260"
    },
    {
    state: "Pernik Province",
    stateShort: "14",
    lat: "42.60519900",
    lng: "23.03779160"
    },
    {
    state: "Pleven Province",
    stateShort: "15",
    lat: "43.41701690",
    lng: "24.60667080"
    },
    {
    state: "Plovdiv Province",
    stateShort: "16",
    lat: "42.13540790",
    lng: "24.74529040"
    },
    {
    state: "Razgrad Province",
    stateShort: "17",
    lat: "43.52717050",
    lng: "26.52412280"
    },
    {
    state: "Ruse Province",
    stateShort: "18",
    lat: "43.83559640",
    lng: "25.96561440"
    },
    {
    state: "Shumen",
    stateShort: "27",
    lat: "43.27123980",
    lng: "26.93612860"
    },
    {
    state: "Silistra Province",
    stateShort: "19",
    lat: "44.11471010",
    lng: "27.26714540"
    },
    {
    state: "Sliven Province",
    stateShort: "20",
    lat: "42.68167020",
    lng: "26.32285690"
    },
    {
    state: "Smolyan Province",
    stateShort: "21",
    lat: "41.57741480",
    lng: "24.70108710"
    },
    {
    state: "Sofia City Province",
    stateShort: "22",
    lat: "42.75701090",
    lng: "23.45046830"
    },
    {
    state: "Sofia Province",
    stateShort: "23",
    lat: "42.67344000",
    lng: "23.83349370"
    },
    {
    state: "Stara Zagora Province",
    stateShort: "24",
    lat: "42.42577090",
    lng: "25.63448550"
    },
    {
    state: "Targovishte Province",
    stateShort: "25",
    lat: "43.24623490",
    lng: "26.56912510"
    },
    {
    state: "Varna Province",
    stateShort: "03",
    lat: "43.20464770",
    lng: "27.91054880"
    },
    {
    state: "Veliko Tarnovo Province",
    stateShort: "04",
    lat: "43.07565390",
    lng: "25.61715000"
    },
    {
    state: "Vidin Province",
    stateShort: "05",
    lat: "43.99617390",
    lng: "22.86795150"
    },
    {
    state: "Vratsa Province",
    stateShort: "06",
    lat: "43.21018060",
    lng: "23.55292100"
    },
    {
    state: "Yambol Province",
    stateShort: "28",
    lat: "42.48414940",
    lng: "26.50352960"
    }
    ]
    },
    {
    country: "Burkina Faso",
    countryShort: "BF",
    currency_symbol: "CFA",
    states: [
    {
    state: "Balé Province",
    stateShort: "BAL",
    lat: "11.78206020",
    lng: "-3.01757120"
    },
    {
    state: "Bam Province",
    stateShort: "BAM",
    lat: "13.44613300",
    lng: "-1.59839590"
    },
    {
    state: "Banwa Province",
    stateShort: "BAN",
    lat: "12.13230530",
    lng: "-4.15137640"
    },
    {
    state: "Bazèga Province",
    stateShort: "BAZ",
    lat: "11.97676920",
    lng: "-1.44346900"
    },
    {
    state: "Boucle du Mouhoun Region",
    stateShort: "01",
    lat: "12.41660000",
    lng: "-3.41955270"
    },
    {
    state: "Bougouriba Province",
    stateShort: "BGR",
    lat: "10.87226460",
    lng: "-3.33889170"
    },
    {
    state: "Boulgou",
    stateShort: "BLG",
    lat: "11.43367660",
    lng: "-0.37483540"
    },
    {
    state: "Cascades Region",
    stateShort: "02",
    lat: "10.40729920",
    lng: "-4.56244260"
    },
    {
    state: "Centre",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Centre-Est Region",
    stateShort: "04",
    lat: "11.52476740",
    lng: "-0.14949880"
    },
    {
    state: "Centre-Nord Region",
    stateShort: "05",
    lat: "13.17244640",
    lng: "-0.90566230"
    },
    {
    state: "Centre-Ouest Region",
    stateShort: "06",
    lat: "11.87984660",
    lng: "-2.30244600"
    },
    {
    state: "Centre-Sud Region",
    stateShort: "07",
    lat: "11.52289110",
    lng: "-1.05861350"
    },
    {
    state: "Comoé Province",
    stateShort: "COM",
    lat: "10.40729920",
    lng: "-4.56244260"
    },
    {
    state: "Est Region",
    stateShort: "08",
    lat: "12.43655260",
    lng: "0.90566230"
    },
    {
    state: "Ganzourgou Province",
    stateShort: "GAN",
    lat: "12.25376480",
    lng: "-0.75328090"
    },
    {
    state: "Gnagna Province",
    stateShort: "GNA",
    lat: "12.89749920",
    lng: "0.07467670"
    },
    {
    state: "Gourma Province",
    stateShort: "GOU",
    lat: "12.16244730",
    lng: "0.67730460"
    },
    {
    state: "Hauts-Bassins Region",
    stateShort: "09",
    lat: "11.49420030",
    lng: "-4.23333550"
    },
    {
    state: "Houet Province",
    stateShort: "HOU",
    lat: "11.13204470",
    lng: "-4.23333550"
    },
    {
    state: "Ioba Province",
    stateShort: "IOB",
    lat: "11.05620340",
    lng: "-3.01757120"
    },
    {
    state: "Kadiogo Province",
    stateShort: "KAD",
    lat: "12.34258970",
    lng: "-1.44346900"
    },
    {
    state: "Kénédougou Province",
    stateShort: "KEN",
    lat: "11.39193950",
    lng: "-4.97665400"
    },
    {
    state: "Komondjari Province",
    stateShort: "KMD",
    lat: "12.71265270",
    lng: "0.67730460"
    },
    {
    state: "Kompienga Province",
    stateShort: "KMP",
    lat: "11.52383620",
    lng: "0.75328090"
    },
    {
    state: "Kossi Province",
    stateShort: "KOS",
    lat: "12.96045800",
    lng: "-3.90626880"
    },
    {
    state: "Koulpélogo Province",
    stateShort: "KOP",
    lat: "11.52476740",
    lng: "0.14949880"
    },
    {
    state: "Kouritenga Province",
    stateShort: "KOT",
    lat: "12.16318130",
    lng: "-0.22446620"
    },
    {
    state: "Kourwéogo Province",
    stateShort: "KOW",
    lat: "12.70774950",
    lng: "-1.75388170"
    },
    {
    state: "Léraba Province",
    stateShort: "LER",
    lat: "10.66487850",
    lng: "-5.31025050"
    },
    {
    state: "Loroum Province",
    stateShort: "LOR",
    lat: "13.81298140",
    lng: "-2.06651970"
    },
    {
    state: "Mouhoun",
    stateShort: "MOU",
    lat: "12.14323810",
    lng: "-3.33889170"
    },
    {
    state: "Nahouri Province",
    stateShort: "NAO",
    lat: "11.25022670",
    lng: "-1.13530200"
    },
    {
    state: "Namentenga Province",
    stateShort: "NAM",
    lat: "13.08125840",
    lng: "-0.52578230"
    },
    {
    state: "Nayala Province",
    stateShort: "NAY",
    lat: "12.69645580",
    lng: "-3.01757120"
    },
    {
    state: "Nord Region, Burkina Faso",
    stateShort: "10",
    lat: "13.71825200",
    lng: "-2.30244600"
    },
    {
    state: "Noumbiel Province",
    stateShort: "NOU",
    lat: "9.84409460",
    lng: "-2.97755580"
    },
    {
    state: "Oubritenga Province",
    stateShort: "OUB",
    lat: "12.70960870",
    lng: "-1.44346900"
    },
    {
    state: "Oudalan Province",
    stateShort: "OUD",
    lat: "14.47190200",
    lng: "-0.45023680"
    },
    {
    state: "Passoré Province",
    stateShort: "PAS",
    lat: "12.88812210",
    lng: "-2.22366670"
    },
    {
    state: "Plateau-Central Region",
    stateShort: "11",
    lat: "12.25376480",
    lng: "-0.75328090"
    },
    {
    state: "Poni Province",
    stateShort: "PON",
    lat: "10.33259960",
    lng: "-3.33889170"
    },
    {
    state: "Sahel Region",
    stateShort: "12",
    lat: "14.10008650",
    lng: "-0.14949880"
    },
    {
    state: "Sanguié Province",
    stateShort: "SNG",
    lat: "12.15018610",
    lng: "-2.69838680"
    },
    {
    state: "Sanmatenga Province",
    stateShort: "SMT",
    lat: "13.35653040",
    lng: "-1.05861350"
    },
    {
    state: "Séno Province",
    stateShort: "SEN",
    lat: "14.00722340",
    lng: "-0.07467670"
    },
    {
    state: "Sissili Province",
    stateShort: "SIS",
    lat: "11.24412190",
    lng: "-2.22366670"
    },
    {
    state: "Soum Province",
    stateShort: "SOM",
    lat: "14.09628410",
    lng: "-1.36621600"
    },
    {
    state: "Sourou Province",
    stateShort: "SOR",
    lat: "13.34180300",
    lng: "-2.93757390"
    },
    {
    state: "Sud-Ouest Region",
    stateShort: "13",
    lat: "10.42314930",
    lng: "-3.25836260"
    },
    {
    state: "Tapoa Province",
    stateShort: "TAP",
    lat: "12.24970720",
    lng: "1.67606910"
    },
    {
    state: "Tuy Province",
    stateShort: "TUI",
    lat: "38.88868400",
    lng: "-77.00471900"
    },
    {
    state: "Yagha Province",
    stateShort: "YAG",
    lat: "13.35761570",
    lng: "0.75328090"
    },
    {
    state: "Yatenga Province",
    stateShort: "YAT",
    lat: "13.62493440",
    lng: "-2.38136210"
    },
    {
    state: "Ziro Province",
    stateShort: "ZIR",
    lat: "11.60949950",
    lng: "-1.90992380"
    },
    {
    state: "Zondoma Province",
    stateShort: "ZON",
    lat: "13.11659260",
    lng: "-2.42087130"
    },
    {
    state: "Zoundwéogo Province",
    stateShort: "ZOU",
    lat: "11.61411740",
    lng: "-0.98206680"
    }
    ]
    },
    {
    country: "Burundi",
    countryShort: "BI",
    currency_symbol: "FBu",
    states: [
    {
    state: "Bubanza Province",
    stateShort: "BB",
    lat: "-3.15724030",
    lng: "29.37149090"
    },
    {
    state: "Bujumbura Mairie Province",
    stateShort: "BM",
    lat: "-3.38841410",
    lng: "29.34826460"
    },
    {
    state: "Bujumbura Rural Province",
    stateShort: "BL",
    lat: "-3.50901440",
    lng: "29.46435900"
    },
    {
    state: "Bururi Province",
    stateShort: "BR",
    lat: "-3.90068510",
    lng: "29.51077080"
    },
    {
    state: "Cankuzo Province",
    stateShort: "CA",
    lat: "-3.15277880",
    lng: "30.61998950"
    },
    {
    state: "Cibitoke Province",
    stateShort: "CI",
    lat: "-2.81028970",
    lng: "29.18557850"
    },
    {
    state: "Gitega Province",
    stateShort: "GI",
    lat: "-3.49290510",
    lng: "29.92779470"
    },
    {
    state: "Karuzi Province",
    stateShort: "KR",
    lat: "-3.13403470",
    lng: "30.11273500"
    },
    {
    state: "Kayanza Province",
    stateShort: "KY",
    lat: "-3.00779810",
    lng: "29.64991620"
    },
    {
    state: "Kirundo Province",
    stateShort: "KI",
    lat: "-2.57628820",
    lng: "30.11273500"
    },
    {
    state: "Makamba Province",
    stateShort: "MA",
    lat: "-4.32570620",
    lng: "29.69626770"
    },
    {
    state: "Muramvya Province",
    stateShort: "MU",
    lat: "-3.28983980",
    lng: "29.64991620"
    },
    {
    state: "Muyinga Province",
    stateShort: "MY",
    lat: "-2.77935110",
    lng: "30.29741990"
    },
    {
    state: "Mwaro Province",
    stateShort: "MW",
    lat: "-3.50259180",
    lng: "29.64991620"
    },
    {
    state: "Ngozi Province",
    stateShort: "NG",
    lat: "-2.89582430",
    lng: "29.88152030"
    },
    {
    state: "Rumonge Province",
    stateShort: "RM",
    lat: "-3.97540490",
    lng: "29.43880140"
    },
    {
    state: "Rutana Province",
    stateShort: "RT",
    lat: "-3.87915230",
    lng: "30.06652360"
    },
    {
    state: "Ruyigi Province",
    stateShort: "RY",
    lat: "-3.44620700",
    lng: "30.25127280"
    }
    ]
    },
    {
    country: "Cambodia",
    countryShort: "KH",
    currency_symbol: "KHR",
    states: [
    {
    state: "Banteay Meanchey",
    stateShort: "1",
    lat: "13.75319140",
    lng: "102.98961500"
    },
    {
    state: "Battambang",
    stateShort: "2",
    lat: "13.02869710",
    lng: "102.98961500"
    },
    {
    state: "Kampong Cham",
    stateShort: "3",
    lat: "12.09829180",
    lng: "105.31311850"
    },
    {
    state: "Kampong Chhnang",
    stateShort: "4",
    lat: "12.13923520",
    lng: "104.56552730"
    },
    {
    state: "Kampong Speu",
    stateShort: "5",
    lat: "11.61551090",
    lng: "104.37919120"
    },
    {
    state: "Kampong Thom",
    stateShort: "6",
    lat: "12.81674850",
    lng: "103.84131040"
    },
    {
    state: "Kampot",
    stateShort: "7",
    lat: "10.73253510",
    lng: "104.37919120"
    },
    {
    state: "Kandal",
    stateShort: "8",
    lat: "11.22373830",
    lng: "105.12589550"
    },
    {
    state: "Kep",
    stateShort: "23",
    lat: "10.53608900",
    lng: "104.35591580"
    },
    {
    state: "Koh Kong",
    stateShort: "9",
    lat: "11.57628040",
    lng: "103.35872880"
    },
    {
    state: "Kratie",
    stateShort: "10",
    lat: "12.50436080",
    lng: "105.96998780"
    },
    {
    state: "Mondulkiri",
    stateShort: "11",
    lat: "12.78794270",
    lng: "107.10119310"
    },
    {
    state: "Oddar Meanchey",
    stateShort: "22",
    lat: "14.16097380",
    lng: "103.82162610"
    },
    {
    state: "Pailin",
    stateShort: "24",
    lat: "12.90929620",
    lng: "102.66755750"
    },
    {
    state: "Phnom Penh",
    stateShort: "12",
    lat: "11.55637380",
    lng: "104.92820990"
    },
    {
    state: "Preah Vihear",
    stateShort: "13",
    lat: "14.00857970",
    lng: "104.84546190"
    },
    {
    state: "Prey Veng",
    stateShort: "14",
    lat: "11.38024420",
    lng: "105.50054830"
    },
    {
    state: "Pursat",
    stateShort: "15",
    lat: "12.27209560",
    lng: "103.72891670"
    },
    {
    state: "Ratanakiri",
    stateShort: "16",
    lat: "13.85766070",
    lng: "107.10119310"
    },
    {
    state: "Siem Reap",
    stateShort: "17",
    lat: "13.33026600",
    lng: "104.10013260"
    },
    {
    state: "Sihanoukville",
    stateShort: "18",
    lat: "10.75818990",
    lng: "103.82162610"
    },
    {
    state: "Stung Treng",
    stateShort: "19",
    lat: "13.57647300",
    lng: "105.96998780"
    },
    {
    state: "Svay Rieng",
    stateShort: "20",
    lat: "11.14272200",
    lng: "105.82902980"
    },
    {
    state: "Takeo",
    stateShort: "21",
    lat: "10.93215190",
    lng: "104.79877100"
    }
    ]
    },
    {
    country: "Cameroon",
    countryShort: "CM",
    currency_symbol: "FCFA",
    states: [
    {
    state: "Adamawa",
    stateShort: "AD",
    lat: "9.32647510",
    lng: "12.39838530"
    },
    {
    state: "Centre",
    stateShort: "CE",
    lat: null,
    lng: null
    },
    {
    state: "East",
    stateShort: "ES",
    lat: "39.01853360",
    lng: "-94.27924110"
    },
    {
    state: "Far North",
    stateShort: "EN",
    lat: "66.76134510",
    lng: "124.12375300"
    },
    {
    state: "Littoral",
    stateShort: "LT",
    lat: "48.46227570",
    lng: "-68.51780710"
    },
    {
    state: "North",
    stateShort: "NO",
    lat: "37.09024000",
    lng: "-95.71289100"
    },
    {
    state: "Northwest",
    stateShort: "NW",
    lat: "36.37118570",
    lng: "-94.19346060"
    },
    {
    state: "South",
    stateShort: "SU",
    lat: "37.63159500",
    lng: "-97.34584090"
    },
    {
    state: "Southwest",
    stateShort: "SW",
    lat: "36.19088130",
    lng: "-95.88974480"
    },
    {
    state: "West",
    stateShort: "OU",
    lat: "37.03649890",
    lng: "-95.67059870"
    }
    ]
    },
    {
    country: "Canada",
    countryShort: "CA",
    currency_symbol: "$",
    states: [
      {
         state: "Quebec",
         stateShort: "QC",
         lat: "44.0019",
         lng: "-72.54913610"
         },
    {
    state: "Alberta",
    stateShort: "AB",
    lat: "53.93327060",
    lng: "-116.57650350"
    },
    {
    state: "British Columbia",
    stateShort: "BC",
    lat: "53.72666830",
    lng: "-127.64762050"
    },
    {
    state: "Manitoba",
    stateShort: "MB",
    lat: "53.76086080",
    lng: "-98.81387620"
    },
    {
    state: "New Brunswick",
    stateShort: "NB",
    lat: "46.56531630",
    lng: "-66.46191640"
    },
    {
    state: "Newfoundland and Labrador",
    stateShort: "NL",
    lat: "53.13550910",
    lng: "-57.66043640"
    },
    {
    state: "Northwest Territories",
    stateShort: "NT",
    lat: "64.82554410",
    lng: "-124.84573340"
    },
    {
    state: "Nova Scotia",
    stateShort: "NS",
    lat: "44.68198660",
    lng: "-63.74431100"
    },
    {
    state: "Nunavut",
    stateShort: "NU",
    lat: "70.29977110",
    lng: "-83.10757700"
    },
    {
    state: "Ontario",
    stateShort: "ON",
    lat: "51.25377500",
    lng: "-85.32321400"
    },
    {
    state: "Prince Edward Island",
    stateShort: "PE",
    lat: "46.51071200",
    lng: "-63.41681360"
    },
    
    {
    state: "Saskatchewan",
    stateShort: "SK",
    lat: "52.93991590",
    lng: "-106.45086390"
    },
    {
    state: "Yukon",
    stateShort: "YT",
    lat: "35.50672150",
    lng: "-97.76254410"
    }
    ]
    },
    {
    country: "Cape Verde",
    countryShort: "CV",
    currency_symbol: "$",
    states: [
    {
    state: "Barlavento Islands",
    stateShort: "B",
    lat: "16.82368450",
    lng: "-23.99348810"
    },
    {
    state: "Boa Vista",
    stateShort: "BV",
    lat: "38.74346600",
    lng: "-120.73042970"
    },
    {
    state: "Brava",
    stateShort: "BR",
    lat: "40.98977780",
    lng: "-73.68357150"
    },
    {
    state: "Maio Municipality",
    stateShort: "MA",
    lat: "15.20030980",
    lng: "-23.16797930"
    },
    {
    state: "Mosteiros",
    stateShort: "MO",
    lat: "37.89043480",
    lng: "-25.82075560"
    },
    {
    state: "Paul",
    stateShort: "PA",
    lat: "37.06250000",
    lng: "-95.67706800"
    },
    {
    state: "Porto Novo",
    stateShort: "PN",
    lat: "6.49685740",
    lng: "2.62885230"
    },
    {
    state: "Praia",
    stateShort: "PR",
    lat: "14.93305000",
    lng: "-23.51332670"
    },
    {
    state: "Ribeira Brava Municipality",
    stateShort: "RB",
    lat: "16.60707390",
    lng: "-24.20338430"
    },
    {
    state: "Ribeira Grande",
    stateShort: "RG",
    lat: "37.82103690",
    lng: "-25.51481370"
    },
    {
    state: "Ribeira Grande de Santiago",
    stateShort: "RS",
    lat: "14.98302980",
    lng: "-23.65617250"
    },
    {
    state: "Sal",
    stateShort: "SL",
    lat: "26.59581220",
    lng: "-80.20450830"
    },
    {
    state: "Santa Catarina",
    stateShort: "CA",
    lat: "-27.24233920",
    lng: "-50.21885560"
    },
    {
    state: "Santa Catarina do Fogo",
    stateShort: "CF",
    lat: "14.93091040",
    lng: "-24.32225770"
    },
    {
    state: "Santa Cruz",
    stateShort: "CR",
    lat: "36.97411710",
    lng: "-122.03079630"
    },
    {
    state: "São Domingos",
    stateShort: "SD",
    lat: "15.02861650",
    lng: "-23.56392200"
    },
    {
    state: "São Filipe",
    stateShort: "SF",
    lat: "14.89516790",
    lng: "-24.49456360"
    },
    {
    state: "São Lourenço dos Órgãos",
    stateShort: "SO",
    lat: "15.05378410",
    lng: "-23.60856120"
    },
    {
    state: "São Miguel",
    stateShort: "SM",
    lat: "37.78041100",
    lng: "-25.49704660"
    },
    {
    state: "São Vicente",
    stateShort: "SV",
    lat: "-23.96071570",
    lng: "-46.39620220"
    },
    {
    state: "Sotavento Islands",
    stateShort: "S",
    lat: "15.00000000",
    lng: "-24.00000000"
    },
    {
    state: "Tarrafal",
    stateShort: "TA",
    lat: "15.27605780",
    lng: "-23.74840770"
    },
    {
    state: "Tarrafal de São Nicolau",
    stateShort: "TS",
    lat: "16.56364980",
    lng: "-24.35494200"
    }
    ]
    },

    {
    country: "Central African Republic",
    countryShort: "CF",
    currency_symbol: "FCFA",
    states: [
    {
    state: "Bamingui-Bangoran Prefecture",
    stateShort: "BB",
    lat: "8.27334550",
    lng: "20.71224650"
    },
    {
    state: "Bangui",
    stateShort: "BGF",
    lat: "4.39467350",
    lng: "18.55818990"
    },
    {
    state: "Basse-Kotto Prefecture",
    stateShort: "BK",
    lat: "4.87193190",
    lng: "21.28450250"
    },
    {
    state: "Haut-Mbomou Prefecture",
    stateShort: "HM",
    lat: "6.25371340",
    lng: "25.47335540"
    },
    {
    state: "Haute-Kotto Prefecture",
    stateShort: "HK",
    lat: "7.79643790",
    lng: "23.38235450"
    },
    {
    state: "Kémo Prefecture",
    stateShort: "KG",
    lat: "5.88677940",
    lng: "19.37832060"
    },
    {
    state: "Lobaye Prefecture",
    stateShort: "LB",
    lat: "4.35259810",
    lng: "17.47951730"
    },
    {
    state: "Mambéré-Kadéï",
    stateShort: "HS",
    lat: "4.70556530",
    lng: "15.96998780"
    },
    {
    state: "Mbomou Prefecture",
    stateShort: "MB",
    lat: "5.55683700",
    lng: "23.76328280"
    },
    {
    state: "Nana-Grébizi Economic Prefecture",
    stateShort: "KB",
    lat: "7.18486070",
    lng: "19.37832060"
    },
    {
    state: "Nana-Mambéré Prefecture",
    stateShort: "NM",
    lat: "5.69321350",
    lng: "15.21948080"
    },
    {
    state: "Ombella-M'Poko Prefecture",
    stateShort: "MP",
    lat: "5.11888250",
    lng: "18.42760470"
    },
    {
    state: "Ouaka Prefecture",
    stateShort: "UK",
    lat: "6.31682160",
    lng: "20.71224650"
    },
    {
    state: "Ouham Prefecture",
    stateShort: "AC",
    lat: "7.09091100",
    lng: "17.66888700"
    },
    {
    state: "Ouham-Pendé Prefecture",
    stateShort: "OP",
    lat: "6.48509840",
    lng: "16.15809370"
    },
    {
    state: "Sangha-Mbaéré",
    stateShort: "SE",
    lat: "3.43686070",
    lng: "16.34637910"
    },
    {
    state: "Vakaga Prefecture",
    stateShort: "VK",
    lat: "9.51132960",
    lng: "22.23840170"
    }
    ]
    },
    {
    country: "Chad",
    countryShort: "TD",
    currency_symbol: "FCFA",
    states: [
    {
    state: "Bahr el Gazel",
    stateShort: "BG",
    lat: "14.77022660",
    lng: "16.91225100"
    },
    {
    state: "Batha",
    stateShort: "BA",
    lat: "13.93717750",
    lng: "18.42760470"
    },
    {
    state: "Borkou",
    stateShort: "BO",
    lat: "17.86888450",
    lng: "18.80761950"
    },
    {
    state: "Chari-Baguirmi",
    stateShort: "CB",
    lat: "11.46186260",
    lng: "15.24463940"
    },
    {
    state: "Ennedi-Est",
    stateShort: "EE",
    lat: "16.34204960",
    lng: "23.00119890"
    },
    {
    state: "Ennedi-Ouest",
    stateShort: "EO",
    lat: "18.97756300",
    lng: "21.85685860"
    },
    {
    state: "Guéra",
    stateShort: "GR",
    lat: "11.12190150",
    lng: "18.42760470"
    },
    {
    state: "Hadjer-Lamis",
    stateShort: "HL",
    lat: "12.45772730",
    lng: "16.72346390"
    },
    {
    state: "Kanem",
    stateShort: "KA",
    lat: "14.87812620",
    lng: "15.40680790"
    },
    {
    state: "Lac",
    stateShort: "LC",
    lat: "13.69153770",
    lng: "14.10013260"
    },
    {
    state: "Logone Occidental",
    stateShort: "LO",
    lat: "8.75967600",
    lng: "15.87600400"
    },
    {
    state: "Logone Oriental",
    stateShort: "LR",
    lat: "8.31499490",
    lng: "16.34637910"
    },
    {
    state: "Mandoul",
    stateShort: "MA",
    lat: "8.60309100",
    lng: "17.47951730"
    },
    {
    state: "Mayo-Kebbi Est",
    stateShort: "ME",
    lat: "9.40460390",
    lng: "14.84546190"
    },
    {
    state: "Mayo-Kebbi Ouest",
    stateShort: "MO",
    lat: "10.41130140",
    lng: "15.59433880"
    },
    {
    state: "Moyen-Chari",
    stateShort: "MC",
    lat: "9.06399980",
    lng: "18.42760470"
    },
    {
    state: "N'Djamena",
    stateShort: "ND",
    lat: "12.13484570",
    lng: "15.05574150"
    },
    {
    state: "Ouaddaï",
    stateShort: "OD",
    lat: "13.74847600",
    lng: "20.71224650"
    },
    {
    state: "Salamat",
    stateShort: "SA",
    lat: "10.96916010",
    lng: "20.71224650"
    },
    {
    state: "Sila",
    stateShort: "SI",
    lat: "12.13074000",
    lng: "21.28450250"
    },
    {
    state: "Tandjilé",
    stateShort: "TA",
    lat: "9.66257290",
    lng: "16.72346390"
    },
    {
    state: "Tibesti",
    stateShort: "TI",
    lat: "21.36500310",
    lng: "16.91225100"
    },
    {
    state: "Wadi Fira",
    stateShort: "WF",
    lat: "15.08924160",
    lng: "21.47528510"
    }
    ]
    },
    {
    country: "Chile",
    countryShort: "CL",
    currency_symbol: "$",
    states: [
    {
    state: "Aisén del General Carlos Ibañez del Campo",
    stateShort: "AI",
    lat: "-46.37834500",
    lng: "-72.30076230"
    },
    {
    state: "Antofagasta",
    stateShort: "AN",
    lat: "-23.83691040",
    lng: "-69.28775350"
    },
    {
    state: "Arica y Parinacota",
    stateShort: "AP",
    lat: "-18.59404850",
    lng: "-69.47845410"
    },
    {
    state: "Atacama",
    stateShort: "AT",
    lat: "-27.56605580",
    lng: "-70.05031400"
    },
    {
    state: "Biobío",
    stateShort: "BI",
    lat: "-37.44644280",
    lng: "-72.14161320"
    },
    {
    state: "Coquimbo",
    stateShort: "CO",
    lat: "-30.54018100",
    lng: "-70.81199530"
    },
    {
    state: "La Araucanía",
    stateShort: "AR",
    lat: "-38.94892100",
    lng: "-72.33111300"
    },
    {
    state: "Libertador General Bernardo O'Higgins",
    stateShort: "LI",
    lat: "-34.57553740",
    lng: "-71.00223110"
    },
    {
    state: "Los Lagos",
    stateShort: "LL",
    lat: "-41.91977790",
    lng: "-72.14161320"
    },
    {
    state: "Los Ríos",
    stateShort: "LR",
    lat: "-40.23102170",
    lng: "-72.33111300"
    },
    {
    state: "Magallanes y de la Antártica Chilena",
    stateShort: "MA",
    lat: "-52.20643160",
    lng: "-72.16850010"
    },
    {
    state: "Maule",
    stateShort: "ML",
    lat: "-35.51636030",
    lng: "-71.57239530"
    },
    {
    state: "Ñuble",
    stateShort: "NB",
    lat: "-36.72257430",
    lng: "-71.76224810"
    },
    {
    state: "Región Metropolitana de Santiago",
    stateShort: "RM",
    lat: "-33.43755450",
    lng: "-70.65048960"
    },
    {
    state: "Tarapacá",
    stateShort: "TA",
    lat: "-20.20287990",
    lng: "-69.28775350"
    },
    {
    state: "Valparaíso",
    stateShort: "VS",
    lat: "-33.04723800",
    lng: "-71.61268850"
    }
    ]
    },
    {
    country: "China",
    countryShort: "CN",
    currency_symbol: "¥",
    states: [
    {
    state: "Anhui",
    stateShort: "AH",
    lat: "30.60067730",
    lng: "117.92490020"
    },
    {
    state: "Beijing",
    stateShort: "BJ",
    lat: "39.90419990",
    lng: "116.40739630"
    },
    {
    state: "Chongqing",
    stateShort: "CQ",
    lat: "29.43158610",
    lng: "106.91225100"
    },
    {
    state: "Fujian",
    stateShort: "FJ",
    lat: "26.48368420",
    lng: "117.92490020"
    },
    {
    state: "Gansu",
    stateShort: "GS",
    lat: "35.75183260",
    lng: "104.28611160"
    },
    {
    state: "Guangdong",
    stateShort: "GD",
    lat: "23.37903330",
    lng: "113.76328280"
    },
    {
    state: "Guangxi Zhuang",
    stateShort: "GX",
    lat: "23.72475990",
    lng: "108.80761950"
    },
    {
    state: "Guizhou",
    stateShort: "GZ",
    lat: "26.84296450",
    lng: "107.29028390"
    },
    {
    state: "Hainan",
    stateShort: "HI",
    lat: "19.56639470",
    lng: "109.94968600"
    },
    {
    state: "Hebei",
    stateShort: "HE",
    lat: "37.89565940",
    lng: "114.90422080"
    },
    {
    state: "Heilongjiang",
    stateShort: "HL",
    lat: "47.12164720",
    lng: "128.73823100"
    },
    {
    state: "Henan",
    stateShort: "HA",
    lat: "34.29043020",
    lng: "113.38235450"
    },
    {
    state: "Hong Kong SAR",
    stateShort: "HK",
    lat: "22.31930390",
    lng: "114.16936110"
    },
    {
    state: "Hubei",
    stateShort: "HB",
    lat: "30.73781180",
    lng: "112.23840170"
    },
    {
    state: "Hunan",
    stateShort: "HN",
    lat: "27.36830090",
    lng: "109.28193470"
    },
    {
    state: "Inner Mongolia",
    stateShort: "NM",
    lat: "43.37822000",
    lng: "115.05948150"
    },
    {
    state: "Jiangsu",
    stateShort: "JS",
    lat: "33.14017150",
    lng: "119.78892480"
    },
    {
    state: "Jiangxi",
    stateShort: "JX",
    lat: "27.08745640",
    lng: "114.90422080"
    },
    {
    state: "Jilin",
    stateShort: "JL",
    lat: "43.83788300",
    lng: "126.54957200"
    },
    {
    state: "Liaoning",
    stateShort: "LN",
    lat: "41.94365430",
    lng: "122.52903760"
    },
    {
    state: "Macau SAR",
    stateShort: "MO",
    lat: "22.19874500",
    lng: "113.54387300"
    },
    {
    state: "Ningxia Huizu",
    stateShort: "NX",
    lat: "37.19873100",
    lng: "106.15809370"
    },
    {
    state: "Qinghai",
    stateShort: "QH",
    lat: "35.74479800",
    lng: "96.40773580"
    },
    {
    state: "Shaanxi",
    stateShort: "SN",
    lat: "35.39399080",
    lng: "109.18800470"
    },
    {
    state: "Shandong",
    stateShort: "SD",
    lat: "37.80060640",
    lng: "-122.26999180"
    },
    {
    state: "Shanghai",
    stateShort: "SH",
    lat: "31.23041600",
    lng: "121.47370100"
    },
    {
    state: "Shanxi",
    stateShort: "SX",
    lat: "37.24256490",
    lng: "111.85685860"
    },
    {
    state: "Sichuan",
    stateShort: "SC",
    lat: "30.26380320",
    lng: "102.80547530"
    },
    {
    state: "Taiwan",
    stateShort: "TW",
    lat: "23.69781000",
    lng: "120.96051500"
    },
    {
    state: "Tianjin",
    stateShort: "TJ",
    lat: "39.12522910",
    lng: "117.01534350"
    },
    {
    state: "Xinjiang",
    stateShort: "XJ",
    lat: "42.52463570",
    lng: "87.53958550"
    },
    {
    state: "Xizang",
    stateShort: "XZ",
    lat: "30.15336050",
    lng: "88.78786780"
    },
    {
    state: "Yunnan",
    stateShort: "YN",
    lat: "24.47528470",
    lng: "101.34310580"
    },
    {
    state: "Zhejiang",
    stateShort: "ZJ",
    lat: "29.14164320",
    lng: "119.78892480"
    }
    ]
    },
  

    {
    country: "Colombia",
    countryShort: "CO",
    currency_symbol: "$",
    states: [
    {
    state: "Amazonas",
    stateShort: "AMA",
    lat: "-1.44291230",
    lng: "-71.57239530"
    },
    {
    state: "Antioquia",
    stateShort: "ANT",
    lat: "7.19860640",
    lng: "-75.34121790"
    },
    {
    state: "Arauca",
    stateShort: "ARA",
    lat: "6.54730600",
    lng: "-71.00223110"
    },
    {
    state: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    stateShort: "SAP",
    lat: "12.55673240",
    lng: "-81.71852530"
    },
    {
    state: "Atlántico",
    stateShort: "ATL",
    lat: "10.69661590",
    lng: "-74.87410450"
    },
    {
    state: "Bogotá D.C.",
    stateShort: "DC",
    lat: "4.28204150",
    lng: "-74.50270420"
    },
    {
    state: "Bolívar",
    stateShort: "BOL",
    lat: "8.67043820",
    lng: "-74.03001220"
    },
    {
    state: "Boyacá",
    stateShort: "BOY",
    lat: "5.45451100",
    lng: "-73.36200300"
    },
    {
    state: "Caldas",
    stateShort: "CAL",
    lat: "5.29826000",
    lng: "-75.24790610"
    },
    {
    state: "Caquetá",
    stateShort: "CAQ",
    lat: "0.86989200",
    lng: "-73.84190630"
    },
    {
    state: "Casanare",
    stateShort: "CAS",
    lat: "5.75892690",
    lng: "-71.57239530"
    },
    {
    state: "Cauca",
    stateShort: "CAU",
    lat: "2.70498130",
    lng: "-76.82596520"
    },
    {
    state: "Cesar",
    stateShort: "CES",
    lat: "9.33729480",
    lng: "-73.65362090"
    },
    {
    state: "Chocó",
    stateShort: "CHO",
    lat: "5.25280330",
    lng: "-76.82596520"
    },
    {
    state: "Córdoba",
    stateShort: "COR",
    lat: "8.04929300",
    lng: "-75.57405000"
    },
    {
    state: "Cundinamarca",
    stateShort: "CUN",
    lat: "5.02600300",
    lng: "-74.03001220"
    },
    {
    state: "Guainía",
    stateShort: "GUA",
    lat: "2.58539300",
    lng: "-68.52471490"
    },
    {
    state: "Guaviare",
    stateShort: "GUV",
    lat: "2.04392400",
    lng: "-72.33111300"
    },
    {
    state: "Huila",
    stateShort: "HUI",
    lat: "2.53593490",
    lng: "-75.52766990"
    },
    {
    state: "La Guajira",
    stateShort: "LAG",
    lat: "11.35477430",
    lng: "-72.52048270"
    },
    {
    state: "Magdalena",
    stateShort: "MAG",
    lat: "10.41130140",
    lng: "-74.40566120"
    },
    {
    state: "Meta",
    stateShort: "MET",
    lat: "39.76732580",
    lng: "-104.97535950"
    },
    {
    state: "Nariño",
    stateShort: "NAR",
    lat: "1.28915100",
    lng: "-77.35794000"
    },
    {
    state: "Norte de Santander",
    stateShort: "NSA",
    lat: "7.94628310",
    lng: "-72.89880690"
    },
    {
    state: "Putumayo",
    stateShort: "PUT",
    lat: "0.43595060",
    lng: "-75.52766990"
    },
    {
    state: "Quindío",
    stateShort: "QUI",
    lat: "4.46101910",
    lng: "-75.66735600"
    },
    {
    state: "Risaralda",
    stateShort: "RIS",
    lat: "5.31584750",
    lng: "-75.99276520"
    },
    {
    state: "Santander",
    stateShort: "SAN",
    lat: "6.64370760",
    lng: "-73.65362090"
    },
    {
    state: "Sucre",
    stateShort: "SUC",
    lat: "8.81397700",
    lng: "-74.72328300"
    },
    {
    state: "Tolima",
    stateShort: "TOL",
    lat: "4.09251680",
    lng: "-75.15453810"
    },
    {
    state: "Valle del Cauca",
    stateShort: "VAC",
    lat: "3.80088930",
    lng: "-76.64127120"
    },
    {
    state: "Vaupés",
    stateShort: "VAU",
    lat: "0.85535610",
    lng: "-70.81199530"
    },
    {
    state: "Vichada",
    stateShort: "VID",
    lat: "4.42344520",
    lng: "-69.28775350"
    }
    ]
    },
    {
    country: "Comoros",
    countryShort: "KM",
    currency_symbol: "CF",
    states: [
    {
    state: "Anjouan",
    stateShort: "A",
    lat: "-12.21381450",
    lng: "44.43706060"
    },
    {
    state: "Grande Comore",
    stateShort: "G",
    lat: "-11.71673380",
    lng: "43.36807880"
    },
    {
    state: "Mohéli",
    stateShort: "M",
    lat: "-12.33773760",
    lng: "43.73340890"
    }
    ]
    },
    {
    country: "Congo",
    countryShort: "CG",
    currency_symbol: "FC",
    states: [
    {
    state: "Bouenza Department",
    stateShort: "11",
    lat: "-4.11280790",
    lng: "13.72891670"
    },
    {
    state: "Brazzaville",
    stateShort: "BZV",
    lat: "-4.26335970",
    lng: "15.24288530"
    },
    {
    state: "Cuvette Department",
    stateShort: "8",
    lat: "-0.28774460",
    lng: "16.15809370"
    },
    {
    state: "Cuvette-Ouest Department",
    stateShort: "15",
    lat: "0.14475500",
    lng: "14.47233010"
    },
    {
    state: "Kouilou Department",
    stateShort: "5",
    lat: "-4.14284130",
    lng: "11.88917210"
    },
    {
    state: "Lékoumou Department",
    stateShort: "2",
    lat: "-3.17038200",
    lng: "13.35872880"
    },
    {
    state: "Likouala Department",
    stateShort: "7",
    lat: "2.04392400",
    lng: "17.66888700"
    },
    {
    state: "Niari Department",
    stateShort: "9",
    lat: "-3.18427000",
    lng: "12.25479190"
    },
    {
    state: "Plateaux Department",
    stateShort: "14",
    lat: "-2.06800880",
    lng: "15.40680790"
    },
    {
    state: "Pointe-Noire",
    stateShort: "16",
    lat: "-4.76916230",
    lng: "11.86636200"
    },
    {
    state: "Pool Department",
    stateShort: "12",
    lat: "-3.77626280",
    lng: "14.84546190"
    },
    {
    state: "Sangha Department",
    stateShort: "13",
    lat: "1.46623280",
    lng: "15.40680790"
    }
    ]
    },

    {
    country: "Costa Rica",
    countryShort: "CR",
    currency_symbol: "₡",
    states: [
    {
    state: "Alajuela Province",
    stateShort: "A",
    lat: "10.39158300",
    lng: "-84.43827210"
    },
    {
    state: "Guanacaste Province",
    stateShort: "G",
    lat: "10.62673990",
    lng: "-85.44367060"
    },
    {
    state: "Heredia Province",
    stateShort: "H",
    lat: "10.47352300",
    lng: "-84.01674230"
    },
    {
    state: "Limón Province",
    stateShort: "L",
    lat: "9.98963980",
    lng: "-83.03324170"
    },
    {
    state: "Provincia de Cartago",
    stateShort: "C",
    lat: "9.86223110",
    lng: "-83.92141870"
    },
    {
    state: "Puntarenas Province",
    stateShort: "P",
    lat: "9.21695310",
    lng: "-83.33618800"
    },
    {
    state: "San José Province",
    stateShort: "SJ",
    lat: "9.91297270",
    lng: "-84.07682940"
    }
    ]
    },
    {
    country: "Cote D'Ivoire (Ivory Coast)",
    countryShort: "CI",
    currency_symbol: "CFA",
    states: [
    {
    state: "Abidjan",
    stateShort: "AB",
    lat: "5.35995170",
    lng: "-4.00825630"
    },
    {
    state: "Agnéby",
    stateShort: "16",
    lat: "5.32245030",
    lng: "-4.34495290"
    },
    {
    state: "Bafing Region",
    stateShort: "17",
    lat: "8.32520470",
    lng: "-7.52472430"
    },
    {
    state: "Bas-Sassandra District",
    stateShort: "BS",
    lat: "5.27983560",
    lng: "-6.15269850"
    },
    {
    state: "Bas-Sassandra Region",
    stateShort: "09",
    lat: "5.35679160",
    lng: "-6.74939930"
    },
    {
    state: "Comoé District",
    stateShort: "CM",
    lat: "5.55279300",
    lng: "-3.25836260"
    },
    {
    state: "Denguélé District",
    stateShort: "DN",
    lat: "48.07077630",
    lng: "-68.56093410"
    },
    {
    state: "Denguélé Region",
    stateShort: "10",
    lat: "9.46623720",
    lng: "-7.43813550"
    },
    {
    state: "Dix-Huit Montagnes",
    stateShort: "06",
    lat: "7.37623730",
    lng: "-7.43813550"
    },
    {
    state: "Fromager",
    stateShort: "18",
    lat: "45.54502130",
    lng: "-73.60462230"
    },
    {
    state: "Gôh-Djiboua District",
    stateShort: "GD",
    lat: "5.87113930",
    lng: "-5.56172790"
    },
    {
    state: "Haut-Sassandra",
    stateShort: "02",
    lat: "6.87578480",
    lng: "-6.57833870"
    },
    {
    state: "Lacs District",
    stateShort: "LC",
    lat: "48.19801690",
    lng: "-80.45644120"
    },
    {
    state: "Lacs Region",
    stateShort: "07",
    lat: "47.73958660",
    lng: "-70.41866520"
    },
    {
    state: "Lagunes District",
    stateShort: "LG",
    lat: "5.88273340",
    lng: "-4.23333550"
    },
    {
    state: "Lagunes region",
    stateShort: "01",
    lat: "5.88273340",
    lng: "-4.23333550"
    },
    {
    state: "Marahoué Region",
    stateShort: "12",
    lat: "6.88462070",
    lng: "-5.89871390"
    },
    {
    state: "Montagnes District",
    stateShort: "MG",
    lat: "7.37623730",
    lng: "-7.43813550"
    },
    {
    state: "Moyen-Cavally",
    stateShort: "19",
    lat: "6.52087930",
    lng: "-7.61142170"
    },
    {
    state: "Moyen-Comoé",
    stateShort: "05",
    lat: "6.65149170",
    lng: "-3.50034540"
    },
    {
    state: "N'zi-Comoé",
    stateShort: "11",
    lat: "7.24567490",
    lng: "-4.23333550"
    },
    {
    state: "Sassandra-Marahoué District",
    stateShort: "SM",
    lat: "6.88033480",
    lng: "-6.23759470"
    },
    {
    state: "Savanes Region",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Sud-Bandama",
    stateShort: "15",
    lat: "5.53570830",
    lng: "-5.56172790"
    },
    {
    state: "Sud-Comoé",
    stateShort: "13",
    lat: "5.55279300",
    lng: "-3.25836260"
    },
    {
    state: "Vallée du Bandama District",
    stateShort: "VB",
    lat: "8.27897800",
    lng: "-4.89356270"
    },
    {
    state: "Vallée du Bandama Region",
    stateShort: "04",
    lat: "8.27897800",
    lng: "-4.89356270"
    },
    {
    state: "Woroba District",
    stateShort: "WR",
    lat: "8.24913720",
    lng: "-6.92091350"
    },
    {
    state: "Worodougou",
    stateShort: "14",
    lat: "8.25489620",
    lng: "-6.57833870"
    },
    {
    state: "Yamoussoukro",
    stateShort: "YM",
    lat: "6.82762280",
    lng: "-5.28934330"
    },
    {
    state: "Zanzan Region",
    stateShort: "ZZ",
    lat: "8.82079040",
    lng: "-3.41955270"
    }
    ]
    },
    {
    country: "Croatia",
    countryShort: "HR",
    currency_symbol: "kn",
    states: [
    {
    state: "Bjelovar-Bilogora",
    stateShort: "07",
    lat: "45.89879720",
    lng: "16.84230930"
    },
    {
    state: "Brod-Posavina",
    stateShort: "12",
    lat: "45.26379510",
    lng: "17.32645620"
    },
    {
    state: "Dubrovnik-Neretva",
    stateShort: "19",
    lat: "43.07665880",
    lng: "17.52684710"
    },
    {
    state: "Istria",
    stateShort: "18",
    lat: "45.12864550",
    lng: "13.90154200"
    },
    {
    state: "Karlovac",
    stateShort: "04",
    lat: "45.26133520",
    lng: "15.52542016"
    },
    {
    state: "Koprivnica-Križevci",
    stateShort: "06",
    lat: "46.15689190",
    lng: "16.83908260"
    },
    {
    state: "Krapina-Zagorje",
    stateShort: "02",
    lat: "46.10133930",
    lng: "15.88096930"
    },
    {
    state: "Lika-Senj",
    stateShort: "09",
    lat: "44.61922180",
    lng: "15.47016080"
    },
    {
    state: "Međimurje",
    stateShort: "20",
    lat: "46.37666440",
    lng: "16.42132980"
    },
    {
    state: "Osijek-Baranja",
    stateShort: "14",
    lat: "45.55764280",
    lng: "18.39421410"
    },
    {
    state: "Požega-Slavonia",
    stateShort: "11",
    lat: "45.34178680",
    lng: "17.81143590"
    },
    {
    state: "Primorje-Gorski Kotar",
    stateShort: "08",
    lat: "45.31739960",
    lng: "14.81674660"
    },
    {
    state: "Šibenik-Knin",
    stateShort: "15",
    lat: "43.92814850",
    lng: "16.10376940"
    },
    {
    state: "Sisak-Moslavina",
    stateShort: "03",
    lat: "45.38379260",
    lng: "16.53809940"
    },
    {
    state: "Split-Dalmatia",
    stateShort: "17",
    lat: "43.52403280",
    lng: "16.81783770"
    },
    {
    state: "Varaždin",
    stateShort: "05",
    lat: "46.23174730",
    lng: "16.33605590"
    },
    {
    state: "Virovitica-Podravina",
    stateShort: "10",
    lat: "45.65579850",
    lng: "17.79324720"
    },
    {
    state: "Vukovar-Syrmia",
    stateShort: "16",
    lat: "45.17735520",
    lng: "18.80535270"
    },
    {
    state: "Zadar",
    stateShort: "13",
    lat: "44.14693900",
    lng: "15.61649430"
    },
    {
    state: "Zagreb",
    stateShort: "01",
    lat: "45.87066120",
    lng: "16.39549100"
    },
    {
    state: "Zagreb",
    stateShort: "21",
    lat: "45.81501080",
    lng: "15.98191890"
    }
    ]
    },
    {
    country: "Cuba",
    countryShort: "CU",
    currency_symbol: "$",
    states: [
    {
    state: "Artemisa Province",
    stateShort: "15",
    lat: "22.75229030",
    lng: "-82.99316070"
    },
    {
    state: "Camagüey Province",
    stateShort: "09",
    lat: "21.21672470",
    lng: "-77.74520810"
    },
    {
    state: "Ciego de Ávila Province",
    stateShort: "08",
    lat: "21.93295150",
    lng: "-78.56608520"
    },
    {
    state: "Cienfuegos Province",
    stateShort: "06",
    lat: "22.23797830",
    lng: "-80.36586500"
    },
    {
    state: "Granma Province",
    stateShort: "12",
    lat: "20.38449020",
    lng: "-76.64127120"
    },
    {
    state: "Guantánamo Province",
    stateShort: "14",
    lat: "20.14559170",
    lng: "-74.87410450"
    },
    {
    state: "Havana Province",
    stateShort: "03",
    lat: "23.05406980",
    lng: "-82.34518900"
    },
    {
    state: "Holguín Province",
    stateShort: "11",
    lat: "20.78378930",
    lng: "-75.80690820"
    },
    {
    state: "Isla de la Juventud",
    stateShort: "99",
    lat: "21.70847370",
    lng: "-82.82202320"
    },
    {
    state: "Las Tunas Province",
    stateShort: "10",
    lat: "21.06051620",
    lng: "-76.91820970"
    },
    {
    state: "Matanzas Province",
    stateShort: "04",
    lat: "22.57671230",
    lng: "-81.33994140"
    },
    {
    state: "Mayabeque Province",
    stateShort: "16",
    lat: "22.89265290",
    lng: "-81.95348150"
    },
    {
    state: "Pinar del Río Province",
    stateShort: "01",
    lat: "22.40762560",
    lng: "-83.84730150"
    },
    {
    state: "Sancti Spíritus Province",
    stateShort: "07",
    lat: "21.99382140",
    lng: "-79.47038850"
    },
    {
    state: "Santiago de Cuba Province",
    stateShort: "13",
    lat: "20.23976820",
    lng: "-75.99276520"
    },
    {
    state: "Villa Clara Province",
    stateShort: "05",
    lat: "22.49372040",
    lng: "-79.91927020"
    }
    ]
    },

    {
    country: "Cyprus",
    countryShort: "CY",
    currency_symbol: "€",
    states: [
    {
    state: "Famagusta District (Mağusa)",
    stateShort: "04",
    lat: "35.28570230",
    lng: "33.84112880"
    },
    {
    state: "Kyrenia District (Keryneia)",
    stateShort: "06",
    lat: "35.29919400",
    lng: "33.23632460"
    },
    {
    state: "Larnaca District (Larnaka)",
    stateShort: "03",
    lat: "34.85072060",
    lng: "33.48319060"
    },
    {
    state: "Limassol District (Leymasun)",
    stateShort: "02",
    lat: "34.70713010",
    lng: "33.02261740"
    },
    {
    state: "Nicosia District (Lefkoşa)",
    stateShort: "01",
    lat: "35.18556590",
    lng: "33.38227640"
    },
    {
    state: "Paphos District (Pafos)",
    stateShort: "05",
    lat: "34.91645940",
    lng: "32.49200880"
    }
    ]
    },
    {
    country: "Czech Republic",
    countryShort: "CZ",
    currency_symbol: "Kč",
    states: [
    {
    state: "Benešov",
    stateShort: "201",
    lat: "49.69008280",
    lng: "14.77643990"
    },
    {
    state: "Beroun",
    stateShort: "202",
    lat: "49.95734280",
    lng: "13.98407150"
    },
    {
    state: "Blansko",
    stateShort: "641",
    lat: "49.36485020",
    lng: "16.64775520"
    },
    {
    state: "Břeclav",
    stateShort: "644",
    lat: "48.75314000",
    lng: "16.88251690"
    },
    {
    state: "Brno-město",
    stateShort: "642",
    lat: "49.19506020",
    lng: "16.60683710"
    },
    {
    state: "Brno-venkov",
    stateShort: "643",
    lat: "49.12501380",
    lng: "16.45588240"
    },
    {
    state: "Bruntál",
    stateShort: "801",
    lat: "49.98817670",
    lng: "17.46369410"
    },
    {
    state: "Česká Lípa",
    stateShort: "511",
    lat: "50.67852010",
    lng: "14.53969910"
    },
    {
    state: "České Budějovice",
    stateShort: "311",
    lat: "48.97755530",
    lng: "14.51507470"
    },
    {
    state: "Český Krumlov",
    stateShort: "312",
    lat: "48.81273540",
    lng: "14.31746570"
    },
    {
    state: "Cheb",
    stateShort: "411",
    lat: "50.07953340",
    lng: "12.36986360"
    },
    {
    state: "Chomutov",
    stateShort: "422",
    lat: "50.45838720",
    lng: "13.30179100"
    },
    {
    state: "Chrudim",
    stateShort: "531",
    lat: "49.88302160",
    lng: "15.82908660"
    },
    {
    state: "Děčín",
    stateShort: "421",
    lat: "50.77255630",
    lng: "14.21276120"
    },
    {
    state: "Domažlice",
    stateShort: "321",
    lat: "49.43970270",
    lng: "12.93114350"
    },
    {
    state: "Frýdek-Místek",
    stateShort: "802",
    lat: "49.68193050",
    lng: "18.36732160"
    },
    {
    state: "Havlíčkův Brod",
    stateShort: "631",
    lat: "49.60433640",
    lng: "15.57965520"
    },
    {
    state: "Hodonín",
    stateShort: "645",
    lat: "48.85293910",
    lng: "17.12600250"
    },
    {
    state: "Hradec Králové",
    stateShort: "521",
    lat: "50.24148050",
    lng: "15.67430000"
    },
    {
    state: "Jablonec nad Nisou",
    stateShort: "512",
    lat: "50.72205280",
    lng: "15.17031350"
    },
    {
    state: "Jeseník",
    stateShort: "711",
    lat: "50.22462490",
    lng: "17.19804710"
    },
    {
    state: "Jičín",
    stateShort: "522",
    lat: "50.43533250",
    lng: "15.36104400"
    },
    {
    state: "Jihlava",
    stateShort: "632",
    lat: "49.39837820",
    lng: "15.58704150"
    },
    {
    state: "Jihočeský kraj",
    stateShort: "31",
    lat: "48.94577890",
    lng: "14.44160550"
    },
    {
    state: "Jihomoravský kraj",
    stateShort: "64",
    lat: "48.95445280",
    lng: "16.76768990"
    },
    {
    state: "Jindřichův Hradec",
    stateShort: "313",
    lat: "49.14448230",
    lng: "15.00613890"
    },
    {
    state: "Karlovarský kraj",
    stateShort: "41",
    lat: "50.14350000",
    lng: "12.75018990"
    },
    {
    state: "Karlovy Vary",
    stateShort: "412",
    lat: "50.14350000",
    lng: "12.75018990"
    },
    {
    state: "Karviná",
    stateShort: "803",
    lat: "49.85665240",
    lng: "18.54321860"
    },
    {
    state: "Kladno",
    stateShort: "203",
    lat: "50.19402580",
    lng: "14.10436570"
    },
    {
    state: "Klatovy",
    stateShort: "322",
    lat: "49.39555490",
    lng: "13.29509370"
    },
    {
    state: "Kolín",
    stateShort: "204",
    lat: "49.98832930",
    lng: "15.05519770"
    },
    {
    state: "Kraj Vysočina",
    stateShort: "63",
    lat: "49.44900520",
    lng: "15.64059340"
    },
    {
    state: "Královéhradecký kraj",
    stateShort: "52",
    lat: "50.35124840",
    lng: "15.79764590"
    },
    {
    state: "Kroměříž",
    stateShort: "721",
    lat: "49.29165820",
    lng: "17.39938000"
    },
    {
    state: "Kutná Hora",
    stateShort: "205",
    lat: "49.94920890",
    lng: "15.24704400"
    },
    {
    state: "Liberec",
    stateShort: "513",
    lat: "50.75641010",
    lng: "14.99650410"
    },
    {
    state: "Liberecký kraj",
    stateShort: "51",
    lat: "50.65942400",
    lng: "14.76324240"
    },
    {
    state: "Litoměřice",
    stateShort: "423",
    lat: "50.53841970",
    lng: "14.13054580"
    },
    {
    state: "Louny",
    stateShort: "424",
    lat: "50.35398120",
    lng: "13.80335510"
    },
    {
    state: "Mělník",
    stateShort: "206",
    lat: "50.31044150",
    lng: "14.51792230"
    },
    {
    state: "Mladá Boleslav",
    stateShort: "207",
    lat: "50.42523170",
    lng: "14.93624770"
    },
    {
    state: "Moravskoslezský kraj",
    stateShort: "80",
    lat: "49.73053270",
    lng: "18.23326370"
    },
    {
    state: "Most",
    stateShort: "425",
    lat: "37.15540830",
    lng: "-94.29488840"
    },
    {
    state: "Náchod",
    stateShort: "523",
    lat: "50.41457220",
    lng: "16.16563470"
    },
    {
    state: "Nový Jičín",
    stateShort: "804",
    lat: "49.59432510",
    lng: "18.01353560"
    },
    {
    state: "Nymburk",
    stateShort: "208",
    lat: "50.18558160",
    lng: "15.04366040"
    },
    {
    state: "Olomouc",
    stateShort: "712",
    lat: "49.59377800",
    lng: "17.25087870"
    },
    {
    state: "Olomoucký kraj",
    stateShort: "71",
    lat: "49.65865490",
    lng: "17.08114060"
    },
    {
    state: "Opava",
    stateShort: "805",
    lat: "49.90837570",
    lng: "17.91633800"
    },
    {
    state: "Ostrava-město",
    stateShort: "806",
    lat: "49.82092260",
    lng: "18.26252430"
    },
    {
    state: "Pardubice",
    stateShort: "532",
    lat: "49.94444790",
    lng: "16.28569160"
    },
    {
    state: "Pardubický kraj",
    stateShort: "53",
    lat: "49.94444790",
    lng: "16.28569160"
    },
    {
    state: "Pelhřimov",
    stateShort: "633",
    lat: "49.43062070",
    lng: "15.22298300"
    },
    {
    state: "Písek",
    stateShort: "314",
    lat: "49.34199380",
    lng: "14.24697600"
    },
    {
    state: "Plzeň-jih",
    stateShort: "324",
    lat: "49.59048850",
    lng: "13.57158610"
    },
    {
    state: "Plzeň-město",
    stateShort: "323",
    lat: "49.73843140",
    lng: "13.37363710"
    },
    {
    state: "Plzeň-sever",
    stateShort: "325",
    lat: "49.87748930",
    lng: "13.25374280"
    },
    {
    state: "Plzeňský kraj",
    stateShort: "32",
    lat: "49.41348120",
    lng: "13.31572460"
    },
    {
    state: "Prachatice",
    stateShort: "315",
    lat: "49.01091000",
    lng: "14.00000050"
    },
    {
    state: "Praha-východ",
    stateShort: "209",
    lat: "49.93893070",
    lng: "14.79244720"
    },
    {
    state: "Praha-západ",
    stateShort: "20A",
    lat: "49.89352350",
    lng: "14.32937790"
    },
    {
    state: "Praha, Hlavní město",
    stateShort: "10",
    lat: "50.07553810",
    lng: "14.43780050"
    },
    {
    state: "Přerov",
    stateShort: "714",
    lat: "49.46713560",
    lng: "17.50773320"
    },
    {
    state: "Příbram",
    stateShort: "20B",
    lat: "49.69479590",
    lng: "14.08238100"
    },
    {
    state: "Prostějov",
    stateShort: "713",
    lat: "49.44184010",
    lng: "17.12779040"
    },
    {
    state: "Rakovník",
    stateShort: "20C",
    lat: "50.10612300",
    lng: "13.73966230"
    },
    {
    state: "Rokycany",
    stateShort: "326",
    lat: "49.82628270",
    lng: "13.68749430"
    },
    {
    state: "Rychnov nad Kněžnou",
    stateShort: "524",
    lat: "50.16596510",
    lng: "16.27768420"
    },
    {
    state: "Semily",
    stateShort: "514",
    lat: "50.60515760",
    lng: "15.32814090"
    },
    {
    state: "Sokolov",
    stateShort: "413",
    lat: "50.20134340",
    lng: "12.60546360"
    },
    {
    state: "Strakonice",
    stateShort: "316",
    lat: "49.26040430",
    lng: "13.91030850"
    },
    {
    state: "Středočeský kraj",
    stateShort: "20",
    lat: "49.87822230",
    lng: "14.93629550"
    },
    {
    state: "Šumperk",
    stateShort: "715",
    lat: "49.97784070",
    lng: "16.97177540"
    },
    {
    state: "Svitavy",
    stateShort: "533",
    lat: "49.75516290",
    lng: "16.46918610"
    },
    {
    state: "Tábor",
    stateShort: "317",
    lat: "49.36462930",
    lng: "14.71912930"
    },
    {
    state: "Tachov",
    stateShort: "327",
    lat: "49.79878030",
    lng: "12.63619210"
    },
    {
    state: "Teplice",
    stateShort: "426",
    lat: "50.65846050",
    lng: "13.75132270"
    },
    {
    state: "Třebíč",
    stateShort: "634",
    lat: "49.21478690",
    lng: "15.87955160"
    },
    {
    state: "Trutnov",
    stateShort: "525",
    lat: "50.56538380",
    lng: "15.90909230"
    },
    {
    state: "Uherské Hradiště",
    stateShort: "722",
    lat: "49.05979690",
    lng: "17.49585010"
    },
    {
    state: "Ústecký kraj",
    stateShort: "42",
    lat: "50.61190370",
    lng: "13.78700860"
    },
    {
    state: "Ústí nad Labem",
    stateShort: "427",
    lat: "50.61190370",
    lng: "13.78700860"
    },
    {
    state: "Ústí nad Orlicí",
    stateShort: "534",
    lat: "49.97218010",
    lng: "16.39966170"
    },
    {
    state: "Vsetín",
    stateShort: "723",
    lat: "49.37932500",
    lng: "18.06181620"
    },
    {
    state: "Vyškov",
    stateShort: "646",
    lat: "49.21274450",
    lng: "16.98559270"
    },
    {
    state: "Žďár nad Sázavou",
    stateShort: "635",
    lat: "49.56430120",
    lng: "15.93910300"
    },
    {
    state: "Zlín",
    stateShort: "724",
    lat: "49.16960520",
    lng: "17.80252200"
    },
    {
    state: "Zlínský kraj",
    stateShort: "72",
    lat: "49.21622960",
    lng: "17.77203530"
    },
    {
    state: "Znojmo",
    stateShort: "647",
    lat: "48.92723270",
    lng: "16.10378080"
    }
    ]
    },
    {
    country: "Democratic Republic of the Congo",
    countryShort: "CD",
    currency_symbol: "FC",
    states: [
    {
    state: "Bas-Uélé",
    stateShort: "BU",
    lat: "3.99010090",
    lng: "24.90422080"
    },
    {
    state: "Équateur",
    stateShort: "EQ",
    lat: "-1.83123900",
    lng: "-78.18340600"
    },
    {
    state: "Haut-Katanga",
    stateShort: "HK",
    lat: "-10.41020750",
    lng: "27.54958460"
    },
    {
    state: "Haut-Lomami",
    stateShort: "HL",
    lat: "-7.70527520",
    lng: "24.90422080"
    },
    {
    state: "Haut-Uélé",
    stateShort: "HU",
    lat: "3.58451540",
    lng: "28.29943500"
    },
    {
    state: "Ituri",
    stateShort: "IT",
    lat: "1.59576820",
    lng: "29.41793240"
    },
    {
    state: "Kasaï",
    stateShort: "KS",
    lat: "-5.04719790",
    lng: "20.71224650"
    },
    {
    state: "Kasaï Central",
    stateShort: "KC",
    lat: "-8.44045910",
    lng: "20.41659340"
    },
    {
    state: "Kasaï Oriental",
    stateShort: "KE",
    lat: "-6.03362300",
    lng: "23.57285010"
    },
    {
    state: "Kinshasa",
    stateShort: "KN",
    lat: "-4.44193110",
    lng: "15.26629310"
    },
    {
    state: "Kongo Central",
    stateShort: "BC",
    lat: "-5.23656850",
    lng: "13.91439900"
    },
    {
    state: "Kwango",
    stateShort: "KG",
    lat: "-6.43374090",
    lng: "17.66888700"
    },
    {
    state: "Kwilu",
    stateShort: "KL",
    lat: "-5.11888250",
    lng: "18.42760470"
    },
    {
    state: "Lomami",
    stateShort: "LO",
    lat: "-6.14539310",
    lng: "24.52426400"
    },
    {
    state: "Lualaba",
    stateShort: "LU",
    lat: "-10.48086980",
    lng: "25.62978160"
    },
    {
    state: "Mai-Ndombe",
    stateShort: "MN",
    lat: "-2.63574340",
    lng: "18.42760470"
    },
    {
    state: "Maniema",
    stateShort: "MA",
    lat: "-3.07309290",
    lng: "26.04138890"
    },
    {
    state: "Mongala",
    stateShort: "MO",
    lat: "1.99623240",
    lng: "21.47528510"
    },
    {
    state: "Nord-Kivu",
    stateShort: "NK",
    lat: "-0.79177290",
    lng: "29.04599270"
    },
    {
    state: "Nord-Ubangi",
    stateShort: "NU",
    lat: "3.78787260",
    lng: "21.47528510"
    },
    {
    state: "Sankuru",
    stateShort: "SA",
    lat: "-2.84374530",
    lng: "23.38235450"
    },
    {
    state: "Sud-Kivu",
    stateShort: "SK",
    lat: "-3.01165800",
    lng: "28.29943500"
    },
    {
    state: "Sud-Ubangi",
    stateShort: "SU",
    lat: "3.22999420",
    lng: "19.18800470"
    },
    {
    state: "Tanganyika",
    stateShort: "TA",
    lat: "-6.27401180",
    lng: "27.92490020"
    },
    {
    state: "Tshopo",
    stateShort: "TO",
    lat: "0.54554620",
    lng: "24.90422080"
    },
    {
    state: "Tshuapa",
    stateShort: "TU",
    lat: "-0.99030230",
    lng: "23.02888440"
    }
    ]
    },
    {
    country: "Denmark",
    countryShort: "DK",
    currency_symbol: "Kr.",
    states: [
    {
    state: "Capital Region of Denmark",
    stateShort: "84",
    lat: "55.67518120",
    lng: "12.54932610"
    },
    {
    state: "Central Denmark Region",
    stateShort: "82",
    lat: "56.30213900",
    lng: "9.30277700"
    },
    {
    state: "North Denmark Region",
    stateShort: "81",
    lat: "56.83074160",
    lng: "9.49305270"
    },
    {
    state: "Region of Southern Denmark",
    stateShort: "83",
    lat: "55.33077140",
    lng: "9.09249030"
    },
    {
    state: "Region Zealand",
    stateShort: "85",
    lat: "55.46325180",
    lng: "11.72149790"
    }
    ]
    },
    {
    country: "Djibouti",
    countryShort: "DJ",
    currency_symbol: "Fdj",
    states: [
    {
    state: "Ali Sabieh Region",
    stateShort: "AS",
    lat: "11.19289730",
    lng: "42.94169800"
    },
    {
    state: "Arta Region",
    stateShort: "AR",
    lat: "11.52555280",
    lng: "42.84794740"
    },
    {
    state: "Dikhil Region",
    stateShort: "DI",
    lat: "11.10543360",
    lng: "42.37047440"
    },
    {
    state: "Djibouti",
    stateShort: "DJ",
    lat: "11.82513800",
    lng: "42.59027500"
    },
    {
    state: "Obock Region",
    stateShort: "OB",
    lat: "12.38956910",
    lng: "43.01948970"
    },
    {
    state: "Tadjourah Region",
    stateShort: "TA",
    lat: "11.93388850",
    lng: "42.39383750"
    }
    ]
    },
    {
    country: "Dominica",
    countryShort: "DM",
    currency_symbol: "$",
    states: [
    {
    state: "Saint Andrew Parish",
    stateShort: "02",
    lat: null,
    lng: null
    },
    {
    state: "Saint David Parish",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Saint George Parish",
    stateShort: "04",
    lat: null,
    lng: null
    },
    {
    state: "Saint John Parish",
    stateShort: "05",
    lat: null,
    lng: null
    },
    {
    state: "Saint Joseph Parish",
    stateShort: "06",
    lat: "39.02227120",
    lng: "-94.71765040"
    },
    {
    state: "Saint Luke Parish",
    stateShort: "07",
    lat: "42.10513630",
    lng: "-80.05707220"
    },
    {
    state: "Saint Mark Parish",
    stateShort: "08",
    lat: null,
    lng: null
    },
    {
    state: "Saint Patrick Parish",
    stateShort: "09",
    lat: null,
    lng: null
    },
    {
    state: "Saint Paul Parish",
    stateShort: "10",
    lat: "38.86146000",
    lng: "-90.74356190"
    },
    {
    state: "Saint Peter Parish",
    stateShort: "11",
    lat: "40.45241940",
    lng: "-80.00850560"
    }
    ]
    },
    {
    country: "Dominican Republic",
    countryShort: "DO",
    currency_symbol: "$",
    states: [
    {
    state: "Azua Province",
    stateShort: "02",
    lat: "18.45527090",
    lng: "-70.73809280"
    },
    {
    state: "Baoruco Province",
    stateShort: "03",
    lat: "18.48798980",
    lng: "-71.41822490"
    },
    {
    state: "Barahona Province",
    stateShort: "04",
    lat: "18.21390660",
    lng: "-71.10437590"
    },
    {
    state: "Dajabón Province",
    stateShort: "05",
    lat: "19.54992410",
    lng: "-71.70865140"
    },
    {
    state: "Distrito Nacional",
    stateShort: "01",
    lat: "18.48605750",
    lng: "-69.93121170"
    },
    {
    state: "Duarte Province",
    stateShort: "06",
    lat: "19.20908230",
    lng: "-70.02700040"
    },
    {
    state: "El Seibo Province",
    stateShort: "08",
    lat: "18.76584960",
    lng: "-69.04066800"
    },
    {
    state: "Espaillat Province",
    stateShort: "09",
    lat: "19.62776580",
    lng: "-70.27867750"
    },
    {
    state: "Hato Mayor Province",
    stateShort: "30",
    lat: "18.76357990",
    lng: "-69.25576370"
    },
    {
    state: "Hermanas Mirabal Province",
    stateShort: "19",
    lat: "19.37475590",
    lng: "-70.35132350"
    },
    {
    state: "Independencia",
    stateShort: "10",
    lat: "32.63357480",
    lng: "-115.42892940"
    },
    {
    state: "La Altagracia Province",
    stateShort: "11",
    lat: "18.58502360",
    lng: "-68.62010720"
    },
    {
    state: "La Romana Province",
    stateShort: "12",
    lat: "18.43102710",
    lng: "-68.98373730"
    },
    {
    state: "La Vega Province",
    stateShort: "13",
    lat: "19.22115540",
    lng: "-70.52887530"
    },
    {
    state: "María Trinidad Sánchez Province",
    stateShort: "14",
    lat: "19.37345970",
    lng: "-69.85144390"
    },
    {
    state: "Monseñor Nouel Province",
    stateShort: "28",
    lat: "18.92152340",
    lng: "-70.38368150"
    },
    {
    state: "Monte Cristi Province",
    stateShort: "15",
    lat: "19.73968990",
    lng: "-71.44339840"
    },
    {
    state: "Monte Plata Province",
    stateShort: "29",
    lat: "18.80808780",
    lng: "-69.78691460"
    },
    {
    state: "Pedernales Province",
    stateShort: "16",
    lat: "17.85376260",
    lng: "-71.33032090"
    },
    {
    state: "Peravia Province",
    stateShort: "17",
    lat: "18.27865940",
    lng: "-70.33358870"
    },
    {
    state: "Puerto Plata Province",
    stateShort: "18",
    lat: "19.75432250",
    lng: "-70.83328470"
    },
    {
    state: "Samaná Province",
    stateShort: "20",
    lat: "19.20583710",
    lng: "-69.33629490"
    },
    {
    state: "San Cristóbal Province",
    stateShort: "21",
    lat: "18.41804140",
    lng: "-70.10658490"
    },
    {
    state: "San José de Ocoa Province",
    stateShort: "31",
    lat: "18.54385800",
    lng: "-70.50418160"
    },
    {
    state: "San Juan Province",
    stateShort: "22",
    lat: "-31.52871270",
    lng: "-68.53604030"
    },
    {
    state: "San Pedro de Macorís",
    stateShort: "23",
    lat: "18.46266000",
    lng: "-69.30512340"
    },
    {
    state: "Sánchez Ramírez Province",
    stateShort: "24",
    lat: "19.05270600",
    lng: "-70.14922640"
    },
    {
    state: "Santiago Province",
    stateShort: "25",
    lat: "-33.45000000",
    lng: "-70.66670000"
    },
    {
    state: "Santiago Rodríguez Province",
    stateShort: "26",
    lat: "19.47131810",
    lng: "-71.33958010"
    },
    {
    state: "Santo Domingo Province",
    stateShort: "32",
    lat: "18.51042530",
    lng: "-69.84040540"
    },
    {
    state: "Valverde Province",
    stateShort: "27",
    lat: "19.58812210",
    lng: "-70.98033100"
    }
    ]
    },
    {
    country: "East Timor",
    countryShort: "TL",
    currency_symbol: "$",
    states: [
    {
    state: "Aileu municipality",
    stateShort: "AL",
    lat: "-8.70439940",
    lng: "125.60954740"
    },
    {
    state: "Ainaro Municipality",
    stateShort: "AN",
    lat: "-9.01131710",
    lng: "125.52200120"
    },
    {
    state: "Baucau Municipality",
    stateShort: "BA",
    lat: "-8.47143080",
    lng: "126.45759910"
    },
    {
    state: "Bobonaro Municipality",
    stateShort: "BO",
    lat: "-8.96554060",
    lng: "125.25879640"
    },
    {
    state: "Cova Lima Municipality",
    stateShort: "CO",
    lat: "-9.26503750",
    lng: "125.25879640"
    },
    {
    state: "Dili municipality",
    stateShort: "DI",
    lat: "-8.24496130",
    lng: "125.58766970"
    },
    {
    state: "Ermera District",
    stateShort: "ER",
    lat: "-8.75248020",
    lng: "125.39872940"
    },
    {
    state: "Lautém Municipality",
    stateShort: "LA",
    lat: "-8.36423070",
    lng: "126.90438450"
    },
    {
    state: "Liquiçá Municipality",
    stateShort: "LI",
    lat: "-8.66740950",
    lng: "125.25879640"
    },
    {
    state: "Manatuto District",
    stateShort: "MT",
    lat: "-8.51556080",
    lng: "126.01592550"
    },
    {
    state: "Manufahi Municipality",
    stateShort: "MF",
    lat: "-9.01454950",
    lng: "125.82799590"
    },
    {
    state: "Viqueque Municipality",
    stateShort: "VI",
    lat: "-8.85979180",
    lng: "126.36335160"
    }
    ]
    },
    {
    country: "Ecuador",
    countryShort: "EC",
    currency_symbol: "$",
    states: [
    {
    state: "Azuay",
    stateShort: "A",
    lat: "-2.89430680",
    lng: "-78.99683440"
    },
    {
    state: "Bolívar",
    stateShort: "B",
    lat: "-1.70958280",
    lng: "-79.04504290"
    },
    {
    state: "Cañar",
    stateShort: "F",
    lat: "-2.55893150",
    lng: "-78.93881910"
    },
    {
    state: "Carchi",
    stateShort: "C",
    lat: "0.50269120",
    lng: "-77.90425210"
    },
    {
    state: "Chimborazo",
    stateShort: "H",
    lat: "-1.66479950",
    lng: "-78.65432550"
    },
    {
    state: "Cotopaxi",
    stateShort: "X",
    lat: "-0.83842060",
    lng: "-78.66626780"
    },
    {
    state: "El Oro",
    stateShort: "O",
    lat: "-3.25924130",
    lng: "-79.95835410"
    },
    {
    state: "Esmeraldas",
    stateShort: "E",
    lat: "0.96817890",
    lng: "-79.65172020"
    },
    {
    state: "Galápagos",
    stateShort: "W",
    lat: "-0.95376910",
    lng: "-90.96560190"
    },
    {
    state: "Guayas",
    stateShort: "G",
    lat: "-1.95748390",
    lng: "-79.91927020"
    },
    {
    state: "Imbabura",
    stateShort: "I",
    lat: "0.34997680",
    lng: "-78.12601290"
    },
    {
    state: "Loja",
    stateShort: "L",
    lat: "-3.99313000",
    lng: "-79.20422000"
    },
    {
    state: "Los Ríos",
    stateShort: "R",
    lat: "-1.02306070",
    lng: "-79.46088970"
    },
    {
    state: "Manabí",
    stateShort: "M",
    lat: "-1.05434340",
    lng: "-80.45264400"
    },
    {
    state: "Morona-Santiago",
    stateShort: "S",
    lat: "-2.30510620",
    lng: "-78.11468660"
    },
    {
    state: "Napo",
    stateShort: "N",
    lat: "-0.99559640",
    lng: "-77.81296840"
    },
    {
    state: "Orellana",
    stateShort: "D",
    lat: "-0.45451630",
    lng: "-76.99502860"
    },
    {
    state: "Pastaza",
    stateShort: "Y",
    lat: "-1.48822650",
    lng: "-78.00310570"
    },
    {
    state: "Pichincha",
    stateShort: "P",
    lat: "-0.14648470",
    lng: "-78.47519450"
    },
    {
    state: "Santa Elena",
    stateShort: "SE",
    lat: "-2.22671050",
    lng: "-80.85949900"
    },
    {
    state: "Santo Domingo de los Tsáchilas",
    stateShort: "SD",
    lat: "-0.25218820",
    lng: "-79.18793830"
    },
    {
    state: "Sucumbíos",
    stateShort: "U",
    lat: "0.08892310",
    lng: "-76.88975570"
    },
    {
    state: "Tungurahua",
    stateShort: "T",
    lat: "-1.26352840",
    lng: "-78.56608520"
    },
    {
    state: "Zamora Chinchipe",
    stateShort: "Z",
    lat: "-4.06558920",
    lng: "-78.95035250"
    }
    ]
    },
    {
    country: "Egypt",
    countryShort: "EG",
    currency_symbol: "ج.م",
    states: [
    {
    state: "Alexandria",
    stateShort: "ALX",
    lat: "30.87605680",
    lng: "29.74260400"
    },
    {
    state: "Aswan",
    stateShort: "ASN",
    lat: "23.69664980",
    lng: "32.71813750"
    },
    {
    state: "Asyut",
    stateShort: "AST",
    lat: "27.21338310",
    lng: "31.44561790"
    },
    {
    state: "Beheira",
    stateShort: "BH",
    lat: "30.84809860",
    lng: "30.34355060"
    },
    {
    state: "Beni Suef",
    stateShort: "BNS",
    lat: "28.89388370",
    lng: "31.44561790"
    },
    {
    state: "Cairo",
    stateShort: "C",
    lat: "29.95375640",
    lng: "31.53700030"
    },
    {
    state: "Dakahlia",
    stateShort: "DK",
    lat: "31.16560440",
    lng: "31.49131820"
    },
    {
    state: "Damietta",
    stateShort: "DT",
    lat: "31.36257990",
    lng: "31.67393710"
    },
    {
    state: "Faiyum",
    stateShort: "FYM",
    lat: "29.30840210",
    lng: "30.84284970"
    },
    {
    state: "Gharbia",
    stateShort: "GH",
    lat: "30.87535560",
    lng: "31.03351000"
    },
    {
    state: "Giza",
    stateShort: "GZ",
    lat: "28.76662160",
    lng: "29.23207840"
    },
    {
    state: "Ismailia",
    stateShort: "IS",
    lat: "30.58309340",
    lng: "32.26538870"
    },
    {
    state: "Kafr el-Sheikh",
    stateShort: "KFS",
    lat: "31.30854440",
    lng: "30.80394740"
    },
    {
    state: "Luxor",
    stateShort: "LX",
    lat: "25.39444440",
    lng: "32.49200880"
    },
    {
    state: "Matrouh",
    stateShort: "MT",
    lat: "29.56963500",
    lng: "26.41938900"
    },
    {
    state: "Minya",
    stateShort: "MN",
    lat: "28.28472900",
    lng: "30.52790960"
    },
    {
    state: "Monufia",
    stateShort: "MNF",
    lat: "30.59724550",
    lng: "30.98763210"
    },
    {
    state: "New Valley",
    stateShort: "WAD",
    lat: "24.54556380",
    lng: "27.17353160"
    },
    {
    state: "North Sinai",
    stateShort: "SIN",
    lat: "30.28236500",
    lng: "33.61757700"
    },
    {
    state: "Port Said",
    stateShort: "PTS",
    lat: "31.07586060",
    lng: "32.26538870"
    },
    {
    state: "Qalyubia",
    stateShort: "KB",
    lat: "30.32923680",
    lng: "31.21684660"
    },
    {
    state: "Qena",
    stateShort: "KN",
    lat: "26.23460330",
    lng: "32.98883190"
    },
    {
    state: "Red Sea",
    stateShort: "BA",
    lat: "24.68263160",
    lng: "34.15319470"
    },
    {
    state: "Sharqia",
    stateShort: "SHR",
    lat: "30.67305450",
    lng: "31.15932470"
    },
    {
    state: "Sohag",
    stateShort: "SHG",
    lat: "26.69383400",
    lng: "32.17460500"
    },
    {
    state: "South Sinai",
    stateShort: "JS",
    lat: "29.31018280",
    lng: "34.15319470"
    },
    {
    state: "Suez",
    stateShort: "SUZ",
    lat: "29.36822550",
    lng: "32.17460500"
    }
    ]
    },
    {
    country: "El Salvador",
    countryShort: "SV",
    currency_symbol: "$",
    states: [
    {
    state: "Ahuachapán Department",
    stateShort: "AH",
    lat: "13.82161480",
    lng: "-89.92532330"
    },
    {
    state: "Cabañas Department",
    stateShort: "CA",
    lat: "13.86482880",
    lng: "-88.74939980"
    },
    {
    state: "Chalatenango Department",
    stateShort: "CH",
    lat: "14.19166480",
    lng: "-89.17059980"
    },
    {
    state: "Cuscatlán Department",
    stateShort: "CU",
    lat: "13.86619570",
    lng: "-89.05615320"
    },
    {
    state: "La Libertad Department",
    stateShort: "LI",
    lat: "13.68176610",
    lng: "-89.36062980"
    },
    {
    state: "La Paz Department",
    stateShort: "PA",
    lat: null,
    lng: null
    },
    {
    state: "La Unión Department",
    stateShort: "UN",
    lat: "13.48864430",
    lng: "-87.89424510"
    },
    {
    state: "Morazán Department",
    stateShort: "MO",
    lat: "13.76820000",
    lng: "-88.12913870"
    },
    {
    state: "San Miguel Department",
    stateShort: "SM",
    lat: "13.44510410",
    lng: "-88.24611830"
    },
    {
    state: "San Salvador Department",
    stateShort: "SS",
    lat: "13.77399970",
    lng: "-89.20867730"
    },
    {
    state: "San Vicente Department",
    stateShort: "SV",
    lat: "13.58685610",
    lng: "-88.74939980"
    },
    {
    state: "Santa Ana Department",
    stateShort: "SA",
    lat: "14.14611210",
    lng: "-89.51200840"
    },
    {
    state: "Sonsonate Department",
    stateShort: "SO",
    lat: "13.68235800",
    lng: "-89.66281110"
    },
    {
    state: "Usulután Department",
    stateShort: "US",
    lat: "13.44706340",
    lng: "-88.55653100"
    }
    ]
    },
    {
    country: "Equatorial Guinea",
    countryShort: "GQ",
    currency_symbol: "FCFA",
    states: [
    {
    state: "Annobón Province",
    stateShort: "AN",
    lat: "-1.42687820",
    lng: "5.63528010"
    },
    {
    state: "Bioko Norte Province",
    stateShort: "BN",
    lat: "3.65950720",
    lng: "8.79218360"
    },
    {
    state: "Bioko Sur Province",
    stateShort: "BS",
    lat: "3.42097850",
    lng: "8.61606740"
    },
    {
    state: "Centro Sur Province",
    stateShort: "CS",
    lat: "1.34360840",
    lng: "10.43965600"
    },
    {
    state: "Insular Region",
    stateShort: "I",
    lat: "37.09024000",
    lng: "-95.71289100"
    },
    {
    state: "Kié-Ntem Province",
    stateShort: "KN",
    lat: "2.02809300",
    lng: "11.07117580"
    },
    {
    state: "Litoral Province",
    stateShort: "LI",
    lat: "1.57502440",
    lng: "9.81249350"
    },
    {
    state: "Río Muni",
    stateShort: "C",
    lat: "1.46106060",
    lng: "9.67868940"
    },
    {
    state: "Wele-Nzas Province",
    stateShort: "WN",
    lat: "1.41661620",
    lng: "11.07117580"
    }
    ]
    },
    {
    country: "Eritrea",
    countryShort: "ER",
    currency_symbol: "Nfk",
    states: [
    {
    state: "Anseba Region",
    stateShort: "AN",
    lat: "16.47455310",
    lng: "37.80876930"
    },
    {
    state: "Debub Region",
    stateShort: "DU",
    lat: "14.94786920",
    lng: "39.15436770"
    },
    {
    state: "Gash-Barka Region",
    stateShort: "GB",
    lat: "15.40688250",
    lng: "37.63866220"
    },
    {
    state: "Maekel Region",
    stateShort: "MA",
    lat: "15.35514090",
    lng: "38.86236830"
    },
    {
    state: "Northern Red Sea Region",
    stateShort: "SK",
    lat: "16.25839970",
    lng: "38.82054540"
    },
    {
    state: "Southern Red Sea Region",
    stateShort: "DK",
    lat: "13.51371030",
    lng: "41.76064720"
    }
    ]
    },
    {
    country: "Estonia",
    countryShort: "EE",
    currency_symbol: "€",
    states: [
    {
    state: "Harju County",
    stateShort: "37",
    lat: "59.33342390",
    lng: "25.24669740"
    },
    {
    state: "Hiiu County",
    stateShort: "39",
    lat: "58.92395530",
    lng: "22.59194680"
    },
    {
    state: "Ida-Viru County",
    stateShort: "44",
    lat: "59.25926630",
    lng: "27.41365350"
    },
    {
    state: "Järva County",
    stateShort: "51",
    lat: "58.88667130",
    lng: "25.50006240"
    },
    {
    state: "Jõgeva County",
    stateShort: "49",
    lat: "58.75061430",
    lng: "26.36048780"
    },
    {
    state: "Lääne County",
    stateShort: "57",
    lat: "58.97227420",
    lng: "23.87408340"
    },
    {
    state: "Lääne-Viru County",
    stateShort: "59",
    lat: "59.30188160",
    lng: "26.32803120"
    },
    {
    state: "Pärnu County",
    stateShort: "67",
    lat: "58.52619520",
    lng: "24.40201590"
    },
    {
    state: "Põlva County",
    stateShort: "65",
    lat: "58.11606220",
    lng: "27.20663940"
    },
    {
    state: "Rapla County",
    stateShort: "70",
    lat: "58.84926250",
    lng: "24.73465690"
    },
    {
    state: "Saare County",
    stateShort: "74",
    lat: "58.48497210",
    lng: "22.61364080"
    },
    {
    state: "Tartu County",
    stateShort: "78",
    lat: "58.40571280",
    lng: "26.80157600"
    },
    {
    state: "Valga County",
    stateShort: "82",
    lat: "57.91034410",
    lng: "26.16018190"
    },
    {
    state: "Viljandi County",
    stateShort: "84",
    lat: "58.28217460",
    lng: "25.57522330"
    },
    {
    state: "Võru County",
    stateShort: "86",
    lat: "57.73773720",
    lng: "27.13989380"
    }
    ]
    },
    {
    country: "Ethiopia",
    countryShort: "ET",
    currency_symbol: "Nkf",
    states: [
    {
    state: "Addis Ababa",
    stateShort: "AA",
    lat: "8.98060340",
    lng: "38.75776050"
    },
    {
    state: "Afar Region",
    stateShort: "AF",
    lat: "11.75593880",
    lng: "40.95868800"
    },
    {
    state: "Amhara Region",
    stateShort: "AM",
    lat: "11.34942470",
    lng: "37.97845850"
    },
    {
    state: "Benishangul-Gumuz Region",
    stateShort: "BE",
    lat: "10.78028890",
    lng: "35.56578620"
    },
    {
    state: "Dire Dawa",
    stateShort: "DD",
    lat: "9.60087470",
    lng: "41.85014200"
    },
    {
    state: "Gambela Region",
    stateShort: "GA",
    lat: "7.92196870",
    lng: "34.15319470"
    },
    {
    state: "Harari Region",
    stateShort: "HA",
    lat: "9.31486600",
    lng: "42.19677160"
    },
    {
    state: "Oromia Region",
    stateShort: "OR",
    lat: "7.54603770",
    lng: "40.63468510"
    },
    {
    state: "Somali Region",
    stateShort: "SO",
    lat: "6.66122930",
    lng: "43.79084530"
    },
    {
    state: "Southern Nations, Nationalities, and Peoples' Region",
    stateShort: "SN",
    lat: "6.51569110",
    lng: "36.95410700"
    },
    {
    state: "Tigray Region",
    stateShort: "TI",
    lat: "14.03233360",
    lng: "38.31657250"
    }
    ]
    },

    {
    country: "Fiji Islands",
    countryShort: "FJ",
    currency_symbol: "FJ$",
    states: [
    {
    state: "Ba",
    stateShort: "01",
    lat: "36.06138930",
    lng: "-95.80058720"
    },
    {
    state: "Bua",
    stateShort: "02",
    lat: "43.09645840",
    lng: "-89.50088000"
    },
    {
    state: "Cakaudrove",
    stateShort: "03",
    lat: "-16.58141050",
    lng: "179.51200840"
    },
    {
    state: "Central Division",
    stateShort: "C",
    lat: "34.04400660",
    lng: "-118.24727380"
    },
    {
    state: "Eastern Division",
    stateShort: "E",
    lat: "32.80943050",
    lng: "-117.12899370"
    },
    {
    state: "Kadavu",
    stateShort: "04",
    lat: "-19.01271220",
    lng: "178.18766760"
    },
    {
    state: "Lau",
    stateShort: "05",
    lat: "31.66870150",
    lng: "-106.39557630"
    },
    {
    state: "Lomaiviti",
    stateShort: "06",
    lat: "-17.70900000",
    lng: "179.09100000"
    },
    {
    state: "Macuata",
    stateShort: "07",
    lat: "-16.48649220",
    lng: "179.28472510"
    },
    {
    state: "Nadroga-Navosa",
    stateShort: "08",
    lat: "-17.98652780",
    lng: "177.65811300"
    },
    {
    state: "Naitasiri",
    stateShort: "09",
    lat: "-17.89757540",
    lng: "178.20715980"
    },
    {
    state: "Namosi",
    stateShort: "10",
    lat: "-18.08641760",
    lng: "178.12913870"
    },
    {
    state: "Northern Division",
    stateShort: "N",
    lat: "32.87687660",
    lng: "-117.21563450"
    },
    {
    state: "Ra",
    stateShort: "11",
    lat: "37.10031530",
    lng: "-95.67442460"
    },
    {
    state: "Rewa",
    stateShort: "12",
    lat: "34.79235170",
    lng: "-82.36092640"
    },
    {
    state: "Rotuma",
    stateShort: "R",
    lat: "-12.50250690",
    lng: "177.07241640"
    },
    {
    state: "Serua",
    stateShort: "13",
    lat: "-18.18047490",
    lng: "178.05097900"
    },
    {
    state: "Tailevu",
    stateShort: "14",
    lat: "-17.82691110",
    lng: "178.29324800"
    },
    {
    state: "Western Division",
    stateShort: "W",
    lat: "42.96621980",
    lng: "-78.70211340"
    }
    ]
    },
    {
    country: "Finland",
    countryShort: "FI",
    currency_symbol: "€",
    states: [
    {
    state: "Åland Islands",
    stateShort: "01",
    lat: "60.17852470",
    lng: "19.91561050"
    },
    {
    state: "Central Finland",
    stateShort: "08",
    lat: "62.56667430",
    lng: "25.55494450"
    },
    {
    state: "Central Ostrobothnia",
    stateShort: "07",
    lat: "63.56217350",
    lng: "24.00136310"
    },
    {
    state: "Finland Proper",
    stateShort: "19",
    lat: "60.36279140",
    lng: "22.44393690"
    },
    {
    state: "Kainuu",
    stateShort: "05",
    lat: "64.37365640",
    lng: "28.74374750"
    },
    {
    state: "Kymenlaakso",
    stateShort: "09",
    lat: "60.78051200",
    lng: "26.88293360"
    },
    {
    state: "Lapland",
    stateShort: "10",
    lat: "67.92223040",
    lng: "26.50464380"
    },
    {
    state: "North Karelia",
    stateShort: "13",
    lat: "62.80620780",
    lng: "30.15538870"
    },
    {
    state: "Northern Ostrobothnia",
    stateShort: "14",
    lat: "65.27949300",
    lng: "26.28904170"
    },
    {
    state: "Northern Savonia",
    stateShort: "15",
    lat: "63.08448000",
    lng: "27.02535040"
    },
    {
    state: "Ostrobothnia",
    stateShort: "12",
    lat: "63.11817570",
    lng: "21.90610620"
    },
    {
    state: "Päijänne Tavastia",
    stateShort: "16",
    lat: "61.32300410",
    lng: "25.73224960"
    },
    {
    state: "Pirkanmaa",
    stateShort: "11",
    lat: "61.69869180",
    lng: "23.78955980"
    },
    {
    state: "Satakunta",
    stateShort: "17",
    lat: "61.59327580",
    lng: "22.14830810"
    },
    {
    state: "South Karelia",
    stateShort: "02",
    lat: "61.11819490",
    lng: "28.10243720"
    },
    {
    state: "Southern Ostrobothnia",
    stateShort: "03",
    lat: "62.94330990",
    lng: "23.52852670"
    },
    {
    state: "Southern Savonia",
    stateShort: "04",
    lat: "61.69451480",
    lng: "27.80050150"
    },
    {
    state: "Tavastia Proper",
    stateShort: "06",
    lat: "60.90701500",
    lng: "24.30054980"
    },
    {
    state: "Uusimaa",
    stateShort: "18",
    lat: "60.21872000",
    lng: "25.27162100"
    }
    ]
    },
    {
    country: "France",
    countryShort: "FR",
    currency_symbol: "€",
    states: [
    {
    state: "Ain",
    stateShort: "01",
    lat: "46.06508600",
    lng: "4.88861500"
    },
    {
    state: "Aisne",
    stateShort: "02",
    lat: "49.45289210",
    lng: "3.04651110"
    },
    {
    state: "Allier",
    stateShort: "03",
    lat: "46.36708630",
    lng: "2.58082770"
    },
    {
    state: "Alpes-de-Haute-Provence",
    stateShort: "04",
    lat: "44.16377520",
    lng: "5.67247800"
    },
    {
    state: "Alpes-Maritimes",
    stateShort: "06",
    lat: "43.92041700",
    lng: "6.61678220"
    },
    {
    state: "Alsace",
    stateShort: "6AE",
    lat: "48.31817950",
    lng: "7.44162410"
    },
    {
    state: "Ardèche",
    stateShort: "07",
    lat: "44.81486950",
    lng: "3.81334830"
    },
    {
    state: "Ardennes",
    stateShort: "08",
    lat: "49.69759510",
    lng: "4.14895760"
    },
    {
    state: "Ariège",
    stateShort: "09",
    lat: "42.94347830",
    lng: "0.94048640"
    },
    {
    state: "Aube",
    stateShort: "10",
    lat: "48.31975470",
    lng: "3.56371040"
    },
    {
    state: "Aude",
    stateShort: "11",
    lat: "43.05411400",
    lng: "1.90384760"
    },
    {
    state: "Auvergne-Rhône-Alpes",
    stateShort: "ARA",
    lat: "45.44714310",
    lng: "4.38525070"
    },
    {
    state: "Aveyron",
    stateShort: "12",
    lat: "44.31563620",
    lng: "2.08523790"
    },
    {
    state: "Bas-Rhin",
    stateShort: "67",
    lat: "48.59864440",
    lng: "7.02666760"
    },
    {
    state: "Bouches-du-Rhône",
    stateShort: "13",
    lat: "43.54038650",
    lng: "4.46138290"
    },
    {
    state: "Bourgogne-Franche-Comté",
    stateShort: "BFC",
    lat: "47.28051270",
    lng: "4.99943720"
    },
    {
    state: "Bretagne",
    stateShort: "BRE",
    lat: "48.20204710",
    lng: "-2.93264350"
    },
    {
    state: "Calvados",
    stateShort: "14",
    lat: "49.09035140",
    lng: "-0.91706480"
    },
    {
    state: "Cantal",
    stateShort: "15",
    lat: "45.04921770",
    lng: "2.15672720"
    },
    {
    state: "Centre-Val de Loire",
    stateShort: "CVL",
    lat: "47.75156860",
    lng: "1.67506310"
    },
    {
    state: "Charente",
    stateShort: "16",
    lat: "45.66584790",
    lng: "-0.31845770"
    },
    {
    state: "Charente-Maritime",
    stateShort: "17",
    lat: "45.72968280",
    lng: "-1.33881160"
    },
    {
    state: "Cher",
    stateShort: "18",
    lat: "47.02436280",
    lng: "1.86627320"
    },
    {
    state: "Clipperton",
    stateShort: "CP",
    lat: "10.28335410",
    lng: "-109.22542150"
    },
    {
    state: "Corrèze",
    stateShort: "19",
    lat: "45.34237070",
    lng: "1.31717330"
    },
    {
    state: "Corse",
    stateShort: "20R",
    lat: "42.03960420",
    lng: "9.01289260"
    },
    {
    state: "Corse-du-Sud",
    stateShort: "2A",
    lat: "41.85720550",
    lng: "8.41091830"
    },
    {
    state: "Côte-d'Or",
    stateShort: "21",
    lat: "47.46513020",
    lng: "4.23154950"
    },
    {
    state: "Côtes-d'Armor",
    stateShort: "22",
    lat: "48.46633360",
    lng: "-3.34789610"
    },
    {
    state: "Creuse",
    stateShort: "23",
    lat: "46.05903940",
    lng: "1.43150500"
    },
    {
    state: "Deux-Sèvres",
    stateShort: "79",
    lat: "46.53868170",
    lng: "-0.90199480"
    },
    {
    state: "Dordogne",
    stateShort: "24",
    lat: "45.14234160",
    lng: "0.14274080"
    },
    {
    state: "Doubs",
    stateShort: "25",
    lat: "46.93217740",
    lng: "6.34762140"
    },
    {
    state: "Drôme",
    stateShort: "26",
    lat: "44.72933570",
    lng: "4.67821580"
    },
    {
    state: "Essonne",
    stateShort: "91",
    lat: "48.53046150",
    lng: "1.96990560"
    },
    {
    state: "Eure",
    stateShort: "27",
    lat: "49.07540350",
    lng: "0.48937320"
    },
    {
    state: "Eure-et-Loir",
    stateShort: "28",
    lat: "48.44697840",
    lng: "0.81470250"
    },
    {
    state: "Finistère",
    stateShort: "29",
    lat: "48.22696100",
    lng: "-4.82437330"
    },
    {
    state: "French Guiana",
    stateShort: "973",
    lat: "3.93388900",
    lng: "-53.12578200"
    },
    {
    state: "French Polynesia",
    stateShort: "PF",
    lat: "-17.67974200",
    lng: "-149.40684300"
    },
    {
    state: "French Southern and Antarctic Lands",
    stateShort: "TF",
    lat: "-47.54466040",
    lng: "51.28375420"
    },
    {
    state: "Gard",
    stateShort: "30",
    lat: "43.95952760",
    lng: "3.49356810"
    },
    {
    state: "Gers",
    stateShort: "32",
    lat: "43.69505340",
    lng: "-0.09997280"
    },
    {
    state: "Gironde",
    stateShort: "33",
    lat: "44.89584690",
    lng: "-1.59405320"
    },
    {
    state: "Grand-Est",
    stateShort: "GES",
    lat: "48.69980300",
    lng: "6.18780740"
    },
    {
    state: "Guadeloupe",
    stateShort: "971",
    lat: "16.26500000",
    lng: "-61.55100000"
    },
    {
    state: "Haut-Rhin",
    stateShort: "68",
    lat: "47.86537740",
    lng: "6.67113810"
    },
    {
    state: "Haute-Corse",
    stateShort: "2B",
    lat: "42.42958660",
    lng: "8.50625610"
    },
    {
    state: "Haute-Garonne",
    stateShort: "31",
    lat: "43.30505550",
    lng: "0.68455150"
    },
    {
    state: "Haute-Loire",
    stateShort: "43",
    lat: "45.08538060",
    lng: "3.22607070"
    },
    {
    state: "Haute-Marne",
    stateShort: "52",
    lat: "48.13248210",
    lng: "4.69834990"
    },
    {
    state: "Haute-Saône",
    stateShort: "70",
    lat: "47.63789960",
    lng: "5.53550550"
    },
    {
    state: "Haute-Savoie",
    stateShort: "74",
    lat: "46.04452770",
    lng: "5.86413800"
    },
    {
    state: "Haute-Vienne",
    stateShort: "87",
    lat: "45.91868780",
    lng: "0.70972060"
    },
    {
    state: "Hautes-Alpes",
    stateShort: "05",
    lat: "44.65626820",
    lng: "5.68732110"
    },
    {
    state: "Hautes-Pyrénées",
    stateShort: "65",
    lat: "43.14294620",
    lng: "-0.40097360"
    },
    {
    state: "Hauts-de-France",
    stateShort: "HDF",
    lat: "50.48011530",
    lng: "2.79372650"
    },
    {
    state: "Hauts-de-Seine",
    stateShort: "92",
    lat: "48.84030080",
    lng: "2.10125590"
    },
    {
    state: "Hérault",
    stateShort: "34",
    lat: "43.59111200",
    lng: "2.80661080"
    },
    {
    state: "Île-de-France",
    stateShort: "IDF",
    lat: "48.84991980",
    lng: "2.63704110"
    },
    {
    state: "Ille-et-Vilaine",
    stateShort: "35",
    lat: "48.17624840",
    lng: "-2.21304010"
    },
    {
    state: "Indre",
    stateShort: "36",
    lat: "46.81175500",
    lng: "0.97555230"
    },
    {
    state: "Indre-et-Loire",
    stateShort: "37",
    lat: "47.22285820",
    lng: "0.14896190"
    },
    {
    state: "Isère",
    stateShort: "38",
    lat: "45.28922710",
    lng: "4.99023550"
    },
    {
    state: "Jura",
    stateShort: "39",
    lat: "46.78287410",
    lng: "5.16918440"
    },
    {
    state: "La Réunion",
    stateShort: "974",
    lat: "-21.11514100",
    lng: "55.53638400"
    },
    {
    state: "Landes",
    stateShort: "40",
    lat: "44.00950800",
    lng: "-1.25385790"
    },
    {
    state: "Loir-et-Cher",
    stateShort: "41",
    lat: "47.65937600",
    lng: "0.85376310"
    },
    {
    state: "Loire",
    stateShort: "42",
    lat: "46.35228120",
    lng: "-1.17563390"
    },
    {
    state: "Loire-Atlantique",
    stateShort: "44",
    lat: "47.34757210",
    lng: "-2.34663120"
    },
    {
    state: "Loiret",
    stateShort: "45",
    lat: "47.91354310",
    lng: "1.76009900"
    },
    {
    state: "Lot",
    stateShort: "46",
    lat: "44.62460700",
    lng: "1.03576310"
    },
    {
    state: "Lot-et-Garonne",
    stateShort: "47",
    lat: "44.36873140",
    lng: "-0.09161690"
    },
    {
    state: "Lozère",
    stateShort: "48",
    lat: "44.54227790",
    lng: "2.92934590"
    },
    {
    state: "Maine-et-Loire",
    stateShort: "49",
    lat: "47.38900340",
    lng: "-1.12025270"
    },
    {
    state: "Manche",
    stateShort: "50",
    lat: "49.08817340",
    lng: "-2.46272090"
    },
    {
    state: "Marne",
    stateShort: "51",
    lat: "48.96107450",
    lng: "3.65737670"
    },
    {
    state: "Martinique",
    stateShort: "972",
    lat: "14.64152800",
    lng: "-61.02417400"
    },
    {
    state: "Mayenne",
    stateShort: "53",
    lat: "48.30668420",
    lng: "-0.64901820"
    },
    {
    state: "Mayotte",
    stateShort: "976",
    lat: "-12.82750000",
    lng: "45.16624400"
    },
    {
    state: "Métropole de Lyon",
    stateShort: "69M",
    lat: "45.74826290",
    lng: "4.59584040"
    },
    {
    state: "Meurthe-et-Moselle",
    stateShort: "54",
    lat: "48.95566150",
    lng: "5.71423500"
    },
    {
    state: "Meuse",
    stateShort: "55",
    lat: "49.01246200",
    lng: "4.81087340"
    },
    {
    state: "Morbihan",
    stateShort: "56",
    lat: "47.74395180",
    lng: "-3.44555240"
    },
    {
    state: "Moselle",
    stateShort: "57",
    lat: "49.02045660",
    lng: "6.20553220"
    },
    {
    state: "Nièvre",
    stateShort: "58",
    lat: "47.11921640",
    lng: "2.97797130"
    },
    {
    state: "Nord",
    stateShort: "59",
    lat: "50.52854770",
    lng: "2.60007760"
    },
    {
    state: "Normandie",
    stateShort: "NOR",
    lat: "48.87987040",
    lng: "0.17125290"
    },
    {
    state: "Nouvelle-Aquitaine",
    stateShort: "NAQ",
    lat: "45.70871820",
    lng: "0.62689100"
    },
    {
    state: "Occitanie",
    stateShort: "OCC",
    lat: "43.89272320",
    lng: "3.28276250"
    },
    {
    state: "Oise",
    stateShort: "60",
    lat: "49.41173350",
    lng: "1.86688250"
    },
    {
    state: "Orne",
    stateShort: "61",
    lat: "48.57576440",
    lng: "-0.50242950"
    },
    {
    state: "Paris",
    stateShort: "75C",
    lat: "48.85661400",
    lng: "2.35222190"
    },
    {
    state: "Pas-de-Calais",
    stateShort: "62",
    lat: "50.51446990",
    lng: "1.81149800"
    },
    {
    state: "Pays-de-la-Loire",
    stateShort: "PDL",
    lat: "47.76328360",
    lng: "-0.32996870"
    },
    {
    state: "Provence-Alpes-Côte-d’Azur",
    stateShort: "PAC",
    lat: "43.93516910",
    lng: "6.06791940"
    },
    {
    state: "Puy-de-Dôme",
    stateShort: "63",
    lat: "45.77141850",
    lng: "2.62626760"
    },
    {
    state: "Pyrénées-Atlantiques",
    stateShort: "64",
    lat: "43.18681700",
    lng: "-1.44170710"
    },
    {
    state: "Pyrénées-Orientales",
    stateShort: "66",
    lat: "42.62541790",
    lng: "1.88929580"
    },
    {
    state: "Rhône",
    stateShort: "69",
    lat: "44.93433000",
    lng: "4.24093290"
    },
    {
    state: "Saint Pierre and Miquelon",
    stateShort: "PM",
    lat: "46.88520000",
    lng: "-56.31590000"
    },
    {
    state: "Saint-Barthélemy",
    stateShort: "BL",
    lat: "17.90051340",
    lng: "-62.82058710"
    },
    {
    state: "Saint-Martin",
    stateShort: "MF",
    lat: "18.07082980",
    lng: "-63.05008090"
    },
    {
    state: "Saône-et-Loire",
    stateShort: "71",
    lat: "46.65548830",
    lng: "3.98350500"
    },
    {
    state: "Sarthe",
    stateShort: "72",
    lat: "48.02627330",
    lng: "-0.32613170"
    },
    {
    state: "Savoie",
    stateShort: "73",
    lat: "45.49469900",
    lng: "5.84329840"
    },
    {
    state: "Seine-et-Marne",
    stateShort: "77",
    lat: "48.61853940",
    lng: "2.41525610"
    },
    {
    state: "Seine-Maritime",
    stateShort: "76",
    lat: "49.66096810",
    lng: "0.36775610"
    },
    {
    state: "Seine-Saint-Denis",
    stateShort: "93",
    lat: "48.90993180",
    lng: "2.30573790"
    },
    {
    state: "Somme",
    stateShort: "80",
    lat: "49.96859220",
    lng: "1.73106960"
    },
    {
    state: "Tarn",
    stateShort: "81",
    lat: "43.79149770",
    lng: "1.67588930"
    },
    {
    state: "Tarn-et-Garonne",
    stateShort: "82",
    lat: "44.08089500",
    lng: "1.08916570"
    },
    {
    state: "Territoire de Belfort",
    stateShort: "90",
    lat: "47.62930720",
    lng: "6.66962000"
    },
    {
    state: "Val-d'Oise",
    stateShort: "95",
    lat: "49.07518180",
    lng: "1.82169140"
    },
    {
    state: "Val-de-Marne",
    stateShort: "94",
    lat: "48.77470040",
    lng: "2.32210390"
    },
    {
    state: "Var",
    stateShort: "83",
    lat: "43.39507300",
    lng: "5.73424170"
    },
    {
    state: "Vaucluse",
    stateShort: "84",
    lat: "44.04475000",
    lng: "4.64277180"
    },
    {
    state: "Vendée",
    stateShort: "85",
    lat: "46.67541030",
    lng: "-2.02983920"
    },
    {
    state: "Vienne",
    stateShort: "86",
    lat: "45.52213140",
    lng: "4.84531360"
    },
    {
    state: "Vosges",
    stateShort: "88",
    lat: "48.16301730",
    lng: "5.73556000"
    },
    {
    state: "Wallis and Futuna",
    stateShort: "WF",
    lat: "-14.29380000",
    lng: "-178.11650000"
    },
    {
    state: "Yonne",
    stateShort: "89",
    lat: "47.85476140",
    lng: "3.03394040"
    },
    {
    state: "Yvelines",
    stateShort: "78",
    lat: "48.76153010",
    lng: "1.27729490"
    }
    ]
    },

   
 
    {
    country: "Gabon",
    countryShort: "GA",
    currency_symbol: "FCFA",
    states: [
    {
    state: "Estuaire Province",
    stateShort: "1",
    lat: "0.44328640",
    lng: "10.08072980"
    },
    {
    state: "Haut-Ogooué Province",
    stateShort: "2",
    lat: "-1.47625440",
    lng: "13.91439900"
    },
    {
    state: "Moyen-Ogooué Province",
    stateShort: "3",
    lat: "-0.44278400",
    lng: "10.43965600"
    },
    {
    state: "Ngounié Province",
    stateShort: "4",
    lat: "-1.49303030",
    lng: "10.98070030"
    },
    {
    state: "Nyanga Province",
    stateShort: "5",
    lat: "-2.88210330",
    lng: "11.16173560"
    },
    {
    state: "Ogooué-Ivindo Province",
    stateShort: "6",
    lat: "0.88183110",
    lng: "13.17403480"
    },
    {
    state: "Ogooué-Lolo Province",
    stateShort: "7",
    lat: "-0.88440930",
    lng: "12.43805810"
    },
    {
    state: "Ogooué-Maritime Province",
    stateShort: "8",
    lat: "-1.34659750",
    lng: "9.72326730"
    },
    {
    state: "Woleu-Ntem Province",
    stateShort: "9",
    lat: "2.29898270",
    lng: "11.44669140"
    }
    ]
    },
    {
    country: "Gambia The",
    countryShort: "GM",
    currency_symbol: "D",
    states: [
    {
    state: "Banjul",
    stateShort: "B",
    lat: "13.45487610",
    lng: "-16.57903230"
    },
    {
    state: "Central River Division",
    stateShort: "M",
    lat: "13.59944690",
    lng: "-14.89216680"
    },
    {
    state: "Lower River Division",
    stateShort: "L",
    lat: "13.35533060",
    lng: "-15.92299000"
    },
    {
    state: "North Bank Division",
    stateShort: "N",
    lat: "13.52854360",
    lng: "-16.01699710"
    },
    {
    state: "Upper River Division",
    stateShort: "U",
    lat: "13.42573660",
    lng: "-14.00723480"
    },
    {
    state: "West Coast Division",
    stateShort: "W",
    lat: "5.97727980",
    lng: "116.07542880"
    }
    ]
    },
    {
    country: "Georgia",
    countryShort: "GE",
    currency_symbol: "ლ",
    states: [
    {
    state: "Adjara",
    stateShort: "AJ",
    lat: "41.60056260",
    lng: "42.06883830"
    },
    {
    state: "Autonomous Republic of Abkhazia",
    stateShort: "AB",
    lat: "43.00155440",
    lng: "41.02340700"
    },
    {
    state: "Guria",
    stateShort: "GU",
    lat: "41.94427360",
    lng: "42.04580910"
    },
    {
    state: "Imereti",
    stateShort: "IM",
    lat: "42.23010800",
    lng: "42.90086640"
    },
    {
    state: "Kakheti",
    stateShort: "KA",
    lat: "41.64816020",
    lng: "45.69055540"
    },
    {
    state: "Khelvachauri Municipality",
    stateShort: "29",
    lat: "41.58019260",
    lng: "41.66107420"
    },
    {
    state: "Kvemo Kartli",
    stateShort: "KK",
    lat: "41.47918330",
    lng: "44.65604510"
    },
    {
    state: "Mtskheta-Mtianeti",
    stateShort: "MM",
    lat: "42.16821850",
    lng: "44.65060580"
    },
    {
    state: "Racha-Lechkhumi and Kvemo Svaneti",
    stateShort: "RL",
    lat: "42.67188730",
    lng: "43.05628360"
    },
    {
    state: "Samegrelo-Zemo Svaneti",
    stateShort: "SZ",
    lat: "42.73522470",
    lng: "42.16893620"
    },
    {
    state: "Samtskhe-Javakheti",
    stateShort: "SJ",
    lat: "41.54792960",
    lng: "43.27764000"
    },
    {
    state: "Senaki Municipality",
    stateShort: "50",
    lat: "42.26963600",
    lng: "42.06568960"
    },
    {
    state: "Shida Kartli",
    stateShort: "SK",
    lat: "42.07569440",
    lng: "43.95404620"
    },
    {
    state: "Tbilisi",
    stateShort: "TB",
    lat: "41.71513770",
    lng: "44.82709600"
    }
    ]
    },
    {
    country: "Germany",
    countryShort: "DE",
    currency_symbol: "€",
    states: [
    {
    state: "Baden-Württemberg",
    stateShort: "BW",
    lat: "48.66160370",
    lng: "9.35013360"
    },
    {
    state: "Bayern",
    stateShort: "BY",
    lat: "48.79044720",
    lng: "11.49788950"
    },
    {
    state: "Berlin",
    stateShort: "BE",
    lat: "52.52000660",
    lng: "13.40495400"
    },
    {
    state: "Brandenburg",
    stateShort: "BB",
    lat: "52.41252870",
    lng: "12.53164440"
    },
    {
    state: "Bremen",
    stateShort: "HB",
    lat: "53.07929620",
    lng: "8.80169360"
    },
    {
    state: "Hamburg",
    stateShort: "HH",
    lat: "53.55108460",
    lng: "9.99368190"
    },
    {
    state: "Hesse",
    stateShort: "HE",
    lat: "50.65205150",
    lng: "9.16243760"
    },
    {
    state: "Niedersachsen",
    stateShort: "NI",
    lat: "52.63670360",
    lng: "9.84507660"
    },
    {
    state: "Mecklenburg-Vorpommern",
    stateShort: "MV",
    lat: "53.61265050",
    lng: "12.42959530"
    },
    {
    state: "Nordrhein-Westfalen",
    stateShort: "NW",
    lat: "51.43323670",
    lng: "7.66159380"
    },
    {
    state: "Rheinland-Pfalz",
    stateShort: "RP",
    lat: "50.11834600",
    lng: "7.30895270"
    },
    {
    state: "Saarland",
    stateShort: "SL",
    lat: "49.39642340",
    lng: "7.02296070"
    },
    {
    state: "Saxony",
    stateShort: "SN",
    lat: "51.10454070",
    lng: "13.20173840"
    },
    {
    state: "Sachsen-Anhalt",
    stateShort: "ST",
    lat: "51.95026490",
    lng: "11.69227340"
    },
    {
    state: "Schleswig-Holstein",
    stateShort: "SH",
    lat: "54.21936720",
    lng: "9.69611670"
    },
    {
    state: "Thüringen (Thuringia)",
    stateShort: "TH",
    lat: "51.01098920",
    lng: "10.84534600"
    }
    ]
    },
    {
    country: "Ghana",
    countryShort: "GH",
    currency_symbol: "GH₵",
    states: [
    {
    state: "Ahafo",
    stateShort: "AF",
    lat: "7.58213720",
    lng: "-2.54974630"
    },
    {
    state: "Ashanti",
    stateShort: "AH",
    lat: "6.74704360",
    lng: "-1.52086240"
    },
    {
    state: "Bono",
    stateShort: "BO",
    lat: "7.65000000",
    lng: "-2.50000000"
    },
    {
    state: "Bono East",
    stateShort: "BE",
    lat: "7.75000000",
    lng: "-1.05000000"
    },
    {
    state: "Central",
    stateShort: "CP",
    lat: "5.50000000",
    lng: "-1.00000000"
    },
    {
    state: "Eastern",
    stateShort: "EP",
    lat: "6.50000000",
    lng: "-0.50000000"
    },
    {
    state: "Greater Accra",
    stateShort: "AA",
    lat: "5.81428360",
    lng: "0.07467670"
    },
    {
    state: "North East",
    stateShort: "NE",
    lat: "10.51666700",
    lng: "-0.36666700"
    },
    {
    state: "Northern",
    stateShort: "NP",
    lat: "9.50000000",
    lng: "-1.00000000"
    },
    {
    state: "Oti",
    stateShort: "OT",
    lat: "7.90000000",
    lng: "0.30000000"
    },
    {
    state: "Savannah",
    stateShort: "SV",
    lat: "9.08333300",
    lng: "-1.81666700"
    },
    {
    state: "Upper East",
    stateShort: "UE",
    lat: "10.70824990",
    lng: "-0.98206680"
    },
    {
    state: "Upper West",
    stateShort: "UW",
    lat: "10.25297570",
    lng: "-2.14502450"
    },
    {
    state: "Volta",
    stateShort: "TV",
    lat: "6.57813730",
    lng: "0.45023680"
    },
    {
    state: "Western",
    stateShort: "WP",
    lat: "5.50000000",
    lng: "-2.50000000"
    },
    {
    state: "Western North",
    stateShort: "WN",
    lat: "6.30000000",
    lng: "-2.80000000"
    }
    ]
    },
    
    {
    country: "Greece",
    countryShort: "GR",
    currency_symbol: "€",
    states: [
    {
    state: "Achaea Regional Unit",
    stateShort: "13",
    lat: "38.11587290",
    lng: "21.95224910"
    },
    {
    state: "Aetolia-Acarnania Regional Unit",
    stateShort: "01",
    lat: "38.70843860",
    lng: "21.37989280"
    },
    {
    state: "Arcadia Prefecture",
    stateShort: "12",
    lat: "37.55578250",
    lng: "22.33377690"
    },
    {
    state: "Argolis Regional Unit",
    stateShort: "11",
    lat: null,
    lng: null
    },
    {
    state: "Attica Region",
    stateShort: "I",
    lat: "38.04575680",
    lng: "23.85847370"
    },
    {
    state: "Boeotia Regional Unit",
    stateShort: "03",
    lat: "38.36636640",
    lng: "23.09650640"
    },
    {
    state: "Central Greece Region",
    stateShort: "H",
    lat: "38.60439840",
    lng: "22.71521310"
    },
    {
    state: "Central Macedonia",
    stateShort: "B",
    lat: "40.62117300",
    lng: "23.19180210"
    },
    {
    state: "Chania Regional Unit",
    stateShort: "94",
    lat: "35.51382980",
    lng: "24.01803670"
    },
    {
    state: "Corfu Prefecture",
    stateShort: "22",
    lat: "39.62498380",
    lng: "19.92234610"
    },
    {
    state: "Corinthia Regional Unit",
    stateShort: "15",
    lat: null,
    lng: null
    },
    {
    state: "Crete Region",
    stateShort: "M",
    lat: "35.24011700",
    lng: "24.80926910"
    },
    {
    state: "Drama Regional Unit",
    stateShort: "52",
    lat: "41.23400230",
    lng: "24.23904980"
    },
    {
    state: "East Attica Regional Unit",
    stateShort: "A2",
    lat: "38.20540930",
    lng: "23.85847370"
    },
    {
    state: "East Macedonia and Thrace",
    stateShort: "A",
    lat: "41.12951260",
    lng: "24.88771910"
    },
    {
    state: "Epirus Region",
    stateShort: "D",
    lat: "39.57064130",
    lng: "20.76428430"
    },
    {
    state: "Euboea",
    stateShort: "04",
    lat: "38.52360360",
    lng: "23.85847370"
    },
    {
    state: "Grevena Prefecture",
    stateShort: "51",
    lat: "40.08376260",
    lng: "21.42732990"
    },
    {
    state: "Imathia Regional Unit",
    stateShort: "53",
    lat: "40.60600670",
    lng: "22.14302150"
    },
    {
    state: "Ioannina Regional Unit",
    stateShort: "33",
    lat: "39.66502880",
    lng: "20.85374660"
    },
    {
    state: "Ionian Islands Region",
    stateShort: "F",
    lat: "37.96948980",
    lng: "21.38023720"
    },
    {
    state: "Karditsa Regional Unit",
    stateShort: "41",
    lat: "39.36402580",
    lng: "21.92140490"
    },
    {
    state: "Kastoria Regional Unit",
    stateShort: "56",
    lat: "40.51926910",
    lng: "21.26871710"
    },
    {
    state: "Kefalonia Prefecture",
    stateShort: "23",
    lat: "38.17536750",
    lng: "20.56921790"
    },
    {
    state: "Kilkis Regional Unit",
    stateShort: "57",
    lat: "40.99370710",
    lng: "22.87536740"
    },
    {
    state: "Kozani Prefecture",
    stateShort: "58",
    lat: "40.30055860",
    lng: "21.78877370"
    },
    {
    state: "Laconia",
    stateShort: "16",
    lat: "43.52785460",
    lng: "-71.47035090"
    },
    {
    state: "Larissa Prefecture",
    stateShort: "42",
    lat: "39.63902240",
    lng: "22.41912540"
    },
    {
    state: "Lefkada Regional Unit",
    stateShort: "24",
    lat: "38.83336630",
    lng: "20.70691080"
    },
    {
    state: "Pella Regional Unit",
    stateShort: "59",
    lat: "40.91480390",
    lng: "22.14302150"
    },
    {
    state: "Peloponnese Region",
    stateShort: "J",
    lat: "37.50794720",
    lng: "22.37349000"
    },
    {
    state: "Phthiotis Prefecture",
    stateShort: "06",
    lat: "38.99978500",
    lng: "22.33377690"
    },
    {
    state: "Preveza Prefecture",
    stateShort: "34",
    lat: "38.95926490",
    lng: "20.75171550"
    },
    {
    state: "Serres Prefecture",
    stateShort: "62",
    lat: "41.08638540",
    lng: "23.54838190"
    },
    {
    state: "South Aegean",
    stateShort: "L",
    lat: "37.08553020",
    lng: "25.14892150"
    },
    {
    state: "Thessaloniki Regional Unit",
    stateShort: "54",
    lat: "40.64006290",
    lng: "22.94441910"
    },
    {
    state: "West Greece Region",
    stateShort: "G",
    lat: "38.51154960",
    lng: "21.57067860"
    },
    {
    state: "West Macedonia Region",
    stateShort: "C",
    lat: "40.30040580",
    lng: "21.79035590"
    }
    ]
    },
  
    {
    country: "Grenada",
    countryShort: "GD",
    currency_symbol: "$",
    states: [
    {
    state: "Carriacou and Petite Martinique",
    stateShort: "10",
    lat: "12.47858880",
    lng: "-61.44938420"
    },
    {
    state: "Saint Andrew Parish",
    stateShort: "01",
    lat: null,
    lng: null
    },
    {
    state: "Saint David Parish",
    stateShort: "02",
    lat: null,
    lng: null
    },
    {
    state: "Saint George Parish",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Saint John Parish",
    stateShort: "04",
    lat: "30.11183310",
    lng: "-90.48799160"
    },
    {
    state: "Saint Mark Parish",
    stateShort: "05",
    lat: "40.58818630",
    lng: "-73.94957010"
    },
    {
    state: "Saint Patrick Parish",
    stateShort: "06",
    lat: null,
    lng: null
    }
    ]
    },
  
  
    {
    country: "Guatemala",
    countryShort: "GT",
    currency_symbol: "Q",
    states: [
    {
    state: "Alta Verapaz Department",
    stateShort: "AV",
    lat: "15.59428830",
    lng: "-90.14949880"
    },
    {
    state: "Baja Verapaz Department",
    stateShort: "BV",
    lat: "15.12558670",
    lng: "-90.37483540"
    },
    {
    state: "Chimaltenango Department",
    stateShort: "CM",
    lat: "14.56347870",
    lng: "-90.98206680"
    },
    {
    state: "Chiquimula Department",
    stateShort: "CQ",
    lat: "14.75149990",
    lng: "-89.47421770"
    },
    {
    state: "El Progreso Department",
    stateShort: "PR",
    lat: "14.93887320",
    lng: "-90.07467670"
    },
    {
    state: "Escuintla Department",
    stateShort: "ES",
    lat: "14.19109120",
    lng: "-90.98206680"
    },
    {
    state: "Guatemala Department",
    stateShort: "GU",
    lat: "14.56494010",
    lng: "-90.52578230"
    },
    {
    state: "Huehuetenango Department",
    stateShort: "HU",
    lat: "15.58799140",
    lng: "-91.67606910"
    },
    {
    state: "Izabal Department",
    stateShort: "IZ",
    lat: "15.49765170",
    lng: "-88.86469800"
    },
    {
    state: "Jalapa Department",
    stateShort: "JA",
    lat: "14.61214460",
    lng: "-89.96267990"
    },
    {
    state: "Jutiapa Department",
    stateShort: "JU",
    lat: "14.19308020",
    lng: "-89.92532330"
    },
    {
    state: "Petén Department",
    stateShort: "PE",
    lat: "16.91203300",
    lng: "-90.29957850"
    },
    {
    state: "Quetzaltenango Department",
    stateShort: "QZ",
    lat: "14.79243300",
    lng: "-91.71495800"
    },
    {
    state: "Quiché Department",
    stateShort: "QC",
    lat: "15.49838080",
    lng: "-90.98206680"
    },
    {
    state: "Retalhuleu Department",
    stateShort: "RE",
    lat: "14.52454850",
    lng: "-91.68578800"
    },
    {
    state: "Sacatepéquez Department",
    stateShort: "SA",
    lat: "14.51783790",
    lng: "-90.71527490"
    },
    {
    state: "San Marcos Department",
    stateShort: "SM",
    lat: "14.93095690",
    lng: "-91.90992380"
    },
    {
    state: "Santa Rosa Department",
    stateShort: "SR",
    lat: "38.44057590",
    lng: "-122.70375430"
    },
    {
    state: "Sololá Department",
    stateShort: "SO",
    lat: "14.74852300",
    lng: "-91.28910360"
    },
    {
    state: "Suchitepéquez Department",
    stateShort: "SU",
    lat: "14.42159820",
    lng: "-91.40482490"
    },
    {
    state: "Totonicapán Department",
    stateShort: "TO",
    lat: "14.91734020",
    lng: "-91.36139230"
    }
    ]
    },
  
    {
    country: "Guinea",
    countryShort: "GN",
    currency_symbol: "FG",
    states: [
    {
    state: "Beyla Prefecture",
    stateShort: "BE",
    lat: "8.91981780",
    lng: "-8.30884410"
    },
    {
    state: "Boffa Prefecture",
    stateShort: "BF",
    lat: "10.18082540",
    lng: "-14.03916150"
    },
    {
    state: "Boké Prefecture",
    stateShort: "BK",
    lat: "11.08473790",
    lng: "-14.37919120"
    },
    {
    state: "Boké Region",
    stateShort: "B",
    lat: "11.18646720",
    lng: "-14.10013260"
    },
    {
    state: "Conakry",
    stateShort: "C",
    lat: "9.64118550",
    lng: "-13.57840120"
    },
    {
    state: "Coyah Prefecture",
    stateShort: "CO",
    lat: "9.77155350",
    lng: "-13.31252990"
    },
    {
    state: "Dabola Prefecture",
    stateShort: "DB",
    lat: "10.72978060",
    lng: "-11.11078540"
    },
    {
    state: "Dalaba Prefecture",
    stateShort: "DL",
    lat: "10.68681760",
    lng: "-12.24906970"
    },
    {
    state: "Dinguiraye Prefecture",
    stateShort: "DI",
    lat: "11.68442220",
    lng: "-10.80000510"
    },
    {
    state: "Dubréka Prefecture",
    stateShort: "DU",
    lat: "9.79073480",
    lng: "-13.51477350"
    },
    {
    state: "Faranah Prefecture",
    stateShort: "FA",
    lat: "9.90573990",
    lng: "-10.80000510"
    },
    {
    state: "Forécariah Prefecture",
    stateShort: "FO",
    lat: "9.38861870",
    lng: "-13.08179030"
    },
    {
    state: "Fria Prefecture",
    stateShort: "FR",
    lat: "10.36745430",
    lng: "-13.58418710"
    },
    {
    state: "Gaoual Prefecture",
    stateShort: "GA",
    lat: "11.57628040",
    lng: "-13.35872880"
    },
    {
    state: "Guéckédou Prefecture",
    stateShort: "GU",
    lat: "8.56496880",
    lng: "-10.13111630"
    },
    {
    state: "Kankan Prefecture",
    stateShort: "KA",
    lat: "10.30344650",
    lng: "-9.36730840"
    },
    {
    state: "Kankan Region",
    stateShort: "K",
    lat: "10.12092300",
    lng: "-9.54509740"
    },
    {
    state: "Kérouané Prefecture",
    stateShort: "KE",
    lat: "9.25366430",
    lng: "-9.01289260"
    },
    {
    state: "Kindia Prefecture",
    stateShort: "KD",
    lat: "10.10132920",
    lng: "-12.71351210"
    },
    {
    state: "Kindia Region",
    stateShort: "D",
    lat: "10.17816940",
    lng: "-12.98961500"
    },
    {
    state: "Kissidougou Prefecture",
    stateShort: "KS",
    lat: "9.22520220",
    lng: "-10.08072980"
    },
    {
    state: "Koubia Prefecture",
    stateShort: "KB",
    lat: "11.58235400",
    lng: "-11.89202370"
    },
    {
    state: "Koundara Prefecture",
    stateShort: "KN",
    lat: "12.48940210",
    lng: "-13.30675620"
    },
    {
    state: "Kouroussa Prefecture",
    stateShort: "KO",
    lat: "10.64892290",
    lng: "-9.88505860"
    },
    {
    state: "Labé Prefecture",
    stateShort: "LA",
    lat: "11.35419390",
    lng: "-12.34638750"
    },
    {
    state: "Labé Region",
    stateShort: "L",
    lat: "11.32320420",
    lng: "-12.28913140"
    },
    {
    state: "Lélouma Prefecture",
    stateShort: "LE",
    lat: "11.18333300",
    lng: "-12.93333300"
    },
    {
    state: "Lola Prefecture",
    stateShort: "LO",
    lat: "7.96138180",
    lng: "-8.39649380"
    },
    {
    state: "Macenta Prefecture",
    stateShort: "MC",
    lat: "8.46157950",
    lng: "-9.27855830"
    },
    {
    state: "Mali Prefecture",
    stateShort: "ML",
    lat: "11.98370900",
    lng: "-12.25479190"
    },
    {
    state: "Mamou Prefecture",
    stateShort: "MM",
    lat: "10.57360240",
    lng: "-11.88917210"
    },
    {
    state: "Mamou Region",
    stateShort: "M",
    lat: "10.57360240",
    lng: "-11.88917210"
    },
    {
    state: "Mandiana Prefecture",
    stateShort: "MD",
    lat: "10.61728270",
    lng: "-8.69857160"
    },
    {
    state: "Nzérékoré Prefecture",
    stateShort: "NZ",
    lat: "7.74783590",
    lng: "-8.82525020"
    },
    {
    state: "Nzérékoré Region",
    stateShort: "N",
    lat: "8.03858700",
    lng: "-8.83627550"
    },
    {
    state: "Pita Prefecture",
    stateShort: "PI",
    lat: "10.80620860",
    lng: "-12.71351210"
    },
    {
    state: "Siguiri Prefecture",
    stateShort: "SI",
    lat: "11.41481130",
    lng: "-9.17883040"
    },
    {
    state: "Télimélé Prefecture",
    stateShort: "TE",
    lat: "10.90893640",
    lng: "-13.02993310"
    },
    {
    state: "Tougué Prefecture",
    stateShort: "TO",
    lat: "11.38415830",
    lng: "-11.61577730"
    },
    {
    state: "Yomou Prefecture",
    stateShort: "YO",
    lat: "7.56962790",
    lng: "-9.25915710"
    }
    ]
    },
    {
    country: "Guinea-Bissau",
    countryShort: "GW",
    currency_symbol: "CFA",
    states: [
    {
    state: "Bafatá",
    stateShort: "BA",
    lat: "12.17352430",
    lng: "-14.65295200"
    },
    {
    state: "Biombo Region",
    stateShort: "BM",
    lat: "11.85290610",
    lng: "-15.73511710"
    },
    {
    state: "Bolama Region",
    stateShort: "BL",
    lat: "11.14805910",
    lng: "-16.13457050"
    },
    {
    state: "Cacheu Region",
    stateShort: "CA",
    lat: "12.05514160",
    lng: "-16.06401790"
    },
    {
    state: "Gabú Region",
    stateShort: "GA",
    lat: "11.89624880",
    lng: "-14.10013260"
    },
    {
    state: "Leste Province",
    stateShort: "L",
    lat: null,
    lng: null
    },
    {
    state: "Norte Province",
    stateShort: "N",
    lat: "7.87218110",
    lng: "123.88577470"
    },
    {
    state: "Oio Region",
    stateShort: "OI",
    lat: "12.27607090",
    lng: "-15.31311850"
    },
    {
    state: "Quinara Region",
    stateShort: "QU",
    lat: "11.79556200",
    lng: "-15.17268160"
    },
    {
    state: "Sul Province",
    stateShort: "S",
    lat: "-10.28665780",
    lng: "20.71224650"
    },
    {
    state: "Tombali Region",
    stateShort: "TO",
    lat: "11.36326960",
    lng: "-14.98561760"
    }
    ]
    },
    {
    country: "Guyana",
    countryShort: "GY",
    currency_symbol: "$",
    states: [
    {
    state: "Barima-Waini",
    stateShort: "BA",
    lat: "7.48824190",
    lng: "-59.65644940"
    },
    {
    state: "Cuyuni-Mazaruni",
    stateShort: "CU",
    lat: "6.46421410",
    lng: "-60.21107520"
    },
    {
    state: "Demerara-Mahaica",
    stateShort: "DE",
    lat: "6.54642600",
    lng: "-58.09820460"
    },
    {
    state: "East Berbice-Corentyne",
    stateShort: "EB",
    lat: "2.74779220",
    lng: "-57.46272590"
    },
    {
    state: "Essequibo Islands-West Demerara",
    stateShort: "ES",
    lat: "6.57201320",
    lng: "-58.46299970"
    },
    {
    state: "Mahaica-Berbice",
    stateShort: "MA",
    lat: "6.23849600",
    lng: "-57.91625550"
    },
    {
    state: "Pomeroon-Supenaam",
    stateShort: "PM",
    lat: "7.12941660",
    lng: "-58.92062950"
    },
    {
    state: "Potaro-Siparuni",
    stateShort: "PT",
    lat: "4.78558530",
    lng: "-59.28799770"
    },
    {
    state: "Upper Demerara-Berbice",
    stateShort: "UD",
    lat: "5.30648790",
    lng: "-58.18929210"
    },
    {
    state: "Upper Takutu-Upper Essequibo",
    stateShort: "UT",
    lat: "2.92395950",
    lng: "-58.73736340"
    }
    ]
    },
    {
    country: "Haiti",
    countryShort: "HT",
    currency_symbol: "G",
    states: [
    {
    state: "Artibonite",
    stateShort: "AR",
    lat: "19.36290200",
    lng: "-72.42581450"
    },
    {
    state: "Centre",
    stateShort: "CE",
    lat: "32.83702510",
    lng: "-96.77738820"
    },
    {
    state: "Grand'Anse",
    stateShort: "GA",
    lat: "12.01666670",
    lng: "-61.76666670"
    },
    {
    state: "Nippes",
    stateShort: "NI",
    lat: "18.39907350",
    lng: "-73.41802110"
    },
    {
    state: "Nord",
    stateShort: "ND",
    lat: "43.19052600",
    lng: "-89.43792100"
    },
    {
    state: "Nord-Est",
    stateShort: "NE",
    lat: "19.48897230",
    lng: "-71.85713310"
    },
    {
    state: "Nord-Ouest",
    stateShort: "NO",
    lat: "19.83740090",
    lng: "-73.04052770"
    },
    {
    state: "Ouest",
    stateShort: "OU",
    lat: "45.45472490",
    lng: "-73.65023650"
    },
    {
    state: "Sud",
    stateShort: "SD",
    lat: "29.92132480",
    lng: "-90.09737720"
    },
    {
    state: "Sud-Est",
    stateShort: "SE",
    lat: "18.27835980",
    lng: "-72.35479150"
    }
    ]
    },
 
    {
    country: "Honduras",
    countryShort: "HN",
    currency_symbol: "L",
    states: [
    {
    state: "Atlántida Department",
    stateShort: "AT",
    lat: "15.66962830",
    lng: "-87.14228950"
    },
    {
    state: "Bay Islands Department",
    stateShort: "IB",
    lat: "16.48266140",
    lng: "-85.87932520"
    },
    {
    state: "Choluteca Department",
    stateShort: "CH",
    lat: "13.25043250",
    lng: "-87.14228950"
    },
    {
    state: "Colón Department",
    stateShort: "CL",
    lat: "15.64259650",
    lng: "-85.52002400"
    },
    {
    state: "Comayagua Department",
    stateShort: "CM",
    lat: "14.55348280",
    lng: "-87.61863790"
    },
    {
    state: "Copán Department",
    stateShort: "CP",
    lat: "14.93608380",
    lng: "-88.86469800"
    },
    {
    state: "Cortés Department",
    stateShort: "CR",
    lat: "15.49235080",
    lng: "-88.09007620"
    },
    {
    state: "El Paraíso Department",
    stateShort: "EP",
    lat: "13.98212940",
    lng: "-86.49965460"
    },
    {
    state: "Francisco Morazán Department",
    stateShort: "FM",
    lat: "14.45411000",
    lng: "-87.06242610"
    },
    {
    state: "Gracias a Dios Department",
    stateShort: "GD",
    lat: "15.34180600",
    lng: "-84.60604490"
    },
    {
    state: "Intibucá Department",
    stateShort: "IN",
    lat: "14.37273400",
    lng: "-88.24611830"
    },
    {
    state: "La Paz Department",
    stateShort: "LP",
    lat: "-15.08924160",
    lng: "-68.52471490"
    },
    {
    state: "Lempira Department",
    stateShort: "LE",
    lat: "14.18876980",
    lng: "-88.55653100"
    },
    {
    state: "Ocotepeque Department",
    stateShort: "OC",
    lat: "14.51703470",
    lng: "-89.05615320"
    },
    {
    state: "Olancho Department",
    stateShort: "OL",
    lat: "14.80674060",
    lng: "-85.76666450"
    },
    {
    state: "Santa Bárbara Department",
    stateShort: "SB",
    lat: "15.12027950",
    lng: "-88.40160410"
    },
    {
    state: "Valle Department",
    stateShort: "VA",
    lat: "13.57829360",
    lng: "-87.57912870"
    },
    {
    state: "Yoro Department",
    stateShort: "YO",
    lat: "15.29496790",
    lng: "-87.14228950"
    }
    ]
    },
    {
    country: "Hong Kong S.A.R.",
    countryShort: "HK",
    currency_symbol: "$",
    states: [
    {
    state: "Central and Western District",
    stateShort: "HCW",
    lat: "22.28666000",
    lng: "114.15497000"
    },
    {
    state: "Eastern",
    stateShort: "HEA",
    lat: "22.28411000",
    lng: "114.22414000"
    },
    {
    state: "Islands District",
    stateShort: "NIS",
    lat: "22.26114000",
    lng: "113.94608000"
    },
    {
    state: "Kowloon City",
    stateShort: "KKC",
    lat: "22.32820000",
    lng: "114.19155000"
    },
    {
    state: "Kwai Tsing",
    stateShort: "NKT",
    lat: "22.35488000",
    lng: "114.08401000"
    },
    {
    state: "Kwun Tong",
    stateShort: "KKT",
    lat: "22.31326000",
    lng: "114.22581000"
    },
    {
    state: "North",
    stateShort: "NNO",
    lat: "22.49471000",
    lng: "114.13812000"
    },
    {
    state: "Sai Kung District",
    stateShort: "NSK",
    lat: "22.38143000",
    lng: "114.27052000"
    },
    {
    state: "Sha Tin",
    stateShort: "NST",
    lat: "22.38715000",
    lng: "114.19534000"
    },
    {
    state: "Sham Shui Po",
    stateShort: "KSS",
    lat: "22.33074000",
    lng: "114.16220000"
    },
    {
    state: "Southern",
    stateShort: "HSO",
    lat: "22.24725000",
    lng: "114.15884000"
    },
    {
    state: "Tai Po District",
    stateShort: "NTP",
    lat: "22.45085000",
    lng: "114.16422000"
    },
    {
    state: "Tsuen Wan District",
    stateShort: "NTW",
    lat: "22.36281000",
    lng: "114.12907000"
    },
    {
    state: "Tuen Mun",
    stateShort: "NTM",
    lat: "22.39163000",
    lng: "113.97708850"
    },
    {
    state: "Wan Chai",
    stateShort: "HWC",
    lat: "22.27968000",
    lng: "114.17168000"
    },
    {
    state: "Wong Tai Sin",
    stateShort: "KWT",
    lat: "22.33353000",
    lng: "114.19686000"
    },
    {
    state: "Yau Tsim Mong",
    stateShort: "KYT",
    lat: "22.32138000",
    lng: "114.17260000"
    },
    {
    state: "Yuen Long District",
    stateShort: "NYL",
    lat: "22.44559000",
    lng: "114.02218000"
    }
    ]
    },
    {
    country: "Hungary",
    countryShort: "HU",
    currency_symbol: "Ft",
    states: [
    {
    state: "Bács-Kiskun",
    stateShort: "BK",
    lat: "46.56614370",
    lng: "19.42724640"
    },
    {
    state: "Baranya",
    stateShort: "BA",
    lat: "46.04845850",
    lng: "18.27191730"
    },
    {
    state: "Békés",
    stateShort: "BE",
    lat: "46.67048990",
    lng: "21.04349960"
    },
    {
    state: "Békéscsaba",
    stateShort: "BC",
    lat: "46.67359390",
    lng: "21.08773090"
    },
    {
    state: "Borsod-Abaúj-Zemplén",
    stateShort: "BZ",
    lat: "48.29394010",
    lng: "20.69341120"
    },
    {
    state: "Budapest",
    stateShort: "BU",
    lat: "47.49791200",
    lng: "19.04023500"
    },
    {
    state: "Csongrád County",
    stateShort: "CS",
    lat: "46.41670500",
    lng: "20.25661610"
    },
    {
    state: "Debrecen",
    stateShort: "DE",
    lat: "47.53160490",
    lng: "21.62731240"
    },
    {
    state: "Dunaújváros",
    stateShort: "DU",
    lat: "46.96190590",
    lng: "18.93552270"
    },
    {
    state: "Eger",
    stateShort: "EG",
    lat: "47.90253480",
    lng: "20.37722840"
    },
    {
    state: "Érd",
    stateShort: "ER",
    lat: "47.39197180",
    lng: "18.90454400"
    },
    {
    state: "Fejér County",
    stateShort: "FE",
    lat: "47.12179320",
    lng: "18.52948150"
    },
    {
    state: "Győr",
    stateShort: "GY",
    lat: "47.68745690",
    lng: "17.65039740"
    },
    {
    state: "Győr-Moson-Sopron County",
    stateShort: "GS",
    lat: "47.65092850",
    lng: "17.25058830"
    },
    {
    state: "Hajdú-Bihar County",
    stateShort: "HB",
    lat: "47.46883550",
    lng: "21.54532270"
    },
    {
    state: "Heves County",
    stateShort: "HE",
    lat: "47.80576170",
    lng: "20.20385590"
    },
    {
    state: "Hódmezővásárhely",
    stateShort: "HV",
    lat: "46.41812620",
    lng: "20.33003150"
    },
    {
    state: "Jász-Nagykun-Szolnok County",
    stateShort: "JN",
    lat: "47.25555790",
    lng: "20.52324560"
    },
    {
    state: "Kaposvár",
    stateShort: "KV",
    lat: "46.35936060",
    lng: "17.79676390"
    },
    {
    state: "Kecskemét",
    stateShort: "KM",
    lat: "46.89637110",
    lng: "19.68968610"
    },
    {
    state: "Komárom-Esztergom",
    stateShort: "KE",
    lat: "47.57797860",
    lng: "18.12568550"
    },
    {
    state: "Miskolc",
    stateShort: "MI",
    lat: "48.10347750",
    lng: "20.77843840"
    },
    {
    state: "Nagykanizsa",
    stateShort: "NK",
    lat: "46.45902180",
    lng: "16.98967960"
    },
    {
    state: "Nógrád County",
    stateShort: "NO",
    lat: "47.90410310",
    lng: "19.04985040"
    },
    {
    state: "Nyíregyháza",
    stateShort: "NY",
    lat: "47.94953240",
    lng: "21.72440530"
    },
    {
    state: "Pécs",
    stateShort: "PS",
    lat: "46.07273450",
    lng: "18.23226600"
    },
    {
    state: "Pest County",
    stateShort: "PE",
    lat: "47.44800010",
    lng: "19.46181280"
    },
    {
    state: "Salgótarján",
    stateShort: "ST",
    lat: "48.09352370",
    lng: "19.79998130"
    },
    {
    state: "Somogy County",
    stateShort: "SO",
    lat: "46.55485900",
    lng: "17.58667320"
    },
    {
    state: "Sopron",
    stateShort: "SN",
    lat: "47.68166190",
    lng: "16.58447950"
    },
    {
    state: "Szabolcs-Szatmár-Bereg County",
    stateShort: "SZ",
    lat: "48.03949540",
    lng: "22.00333000"
    },
    {
    state: "Szeged",
    stateShort: "SD",
    lat: "46.25301020",
    lng: "20.14142530"
    },
    {
    state: "Székesfehérvár",
    stateShort: "SF",
    lat: "47.18602620",
    lng: "18.42213580"
    },
    {
    state: "Szekszárd",
    stateShort: "SS",
    lat: "46.34743260",
    lng: "18.70622930"
    },
    {
    state: "Szolnok",
    stateShort: "SK",
    lat: "47.16213550",
    lng: "20.18247120"
    },
    {
    state: "Szombathely",
    stateShort: "SH",
    lat: "47.23068510",
    lng: "16.62184410"
    },
    {
    state: "Tatabánya",
    stateShort: "TB",
    lat: "47.56924600",
    lng: "18.40481800"
    },
    {
    state: "Tolna County",
    stateShort: "TO",
    lat: "46.47627540",
    lng: "18.55706270"
    },
    {
    state: "Vas County",
    stateShort: "VA",
    lat: "47.09291110",
    lng: "16.68121830"
    },
    {
    state: "Veszprém",
    stateShort: "VM",
    lat: "47.10280870",
    lng: "17.90930190"
    },
    {
    state: "Veszprém County",
    stateShort: "VE",
    lat: "47.09309740",
    lng: "17.91007630"
    },
    {
    state: "Zala County",
    stateShort: "ZA",
    lat: "46.73844040",
    lng: "16.91522520"
    },
    {
    state: "Zalaegerszeg",
    stateShort: "ZE",
    lat: "46.84169360",
    lng: "16.84163220"
    }
    ]
    },
    {
    country: "Iceland",
    countryShort: "IS",
    currency_symbol: "kr",
    states: [
    {
    state: "Capital Region",
    stateShort: "1",
    lat: "38.56569570",
    lng: "-92.18169490"
    },
    {
    state: "Eastern Region",
    stateShort: "7",
    lat: null,
    lng: null
    },
    {
    state: "Northeastern Region",
    stateShort: "6",
    lat: "43.29942850",
    lng: "-74.21793260"
    },
    {
    state: "Northwestern Region",
    stateShort: "5",
    lat: "41.91339320",
    lng: "-73.04716880"
    },
    {
    state: "Southern Peninsula Region",
    stateShort: "2",
    lat: "63.91548030",
    lng: "-22.36496670"
    },
    {
    state: "Southern Region",
    stateShort: "8",
    lat: null,
    lng: null
    },
    {
    state: "Western Region",
    stateShort: "3",
    lat: null,
    lng: null
    },
    {
    state: "Westfjords",
    stateShort: "4",
    lat: "65.91961500",
    lng: "-21.88117640"
    }
    ]
    },
    {
    country: "India",
    countryShort: "IN",
    currency_symbol: "₹",
    states: [
    {
    state: "Andaman and Nicobar Islands",
    stateShort: "AN",
    lat: "11.74008670",
    lng: "92.65864010"
    },
    {
    state: "Andhra Pradesh",
    stateShort: "AP",
    lat: "15.91289980",
    lng: "79.73998750"
    },
    {
    state: "Arunachal Pradesh",
    stateShort: "AR",
    lat: "28.21799940",
    lng: "94.72775280"
    },
    {
    state: "Assam",
    stateShort: "AS",
    lat: "26.20060430",
    lng: "92.93757390"
    },
    {
    state: "Bihar",
    stateShort: "BR",
    lat: "25.09607420",
    lng: "85.31311940"
    },
    {
    state: "Chandigarh",
    stateShort: "CH",
    lat: "30.73331480",
    lng: "76.77941790"
    },
    {
    state: "Chhattisgarh",
    stateShort: "CT",
    lat: "21.27865670",
    lng: "81.86614420"
    },
    {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    stateShort: "DH",
    lat: "20.39737360",
    lng: "72.83279910"
    },
    {
    state: "Delhi",
    stateShort: "DL",
    lat: "28.70405920",
    lng: "77.10249020"
    },
    {
    state: "Goa",
    stateShort: "GA",
    lat: "15.29932650",
    lng: "74.12399600"
    },
    {
    state: "Gujarat",
    stateShort: "GJ",
    lat: "22.25865200",
    lng: "71.19238050"
    },
    {
    state: "Haryana",
    stateShort: "HR",
    lat: "29.05877570",
    lng: "76.08560100"
    },
    {
    state: "Himachal Pradesh",
    stateShort: "HP",
    lat: "31.10482940",
    lng: "77.17339010"
    },
    {
    state: "Jammu and Kashmir",
    stateShort: "JK",
    lat: "33.27783900",
    lng: "75.34121790"
    },
    {
    state: "Jharkhand",
    stateShort: "JH",
    lat: "23.61018080",
    lng: "85.27993540"
    },
    {
    state: "Karnataka",
    stateShort: "KA",
    lat: "15.31727750",
    lng: "75.71388840"
    },
    {
    state: "Kerala",
    stateShort: "KL",
    lat: "10.85051590",
    lng: "76.27108330"
    },
    {
    state: "Ladakh",
    stateShort: "LA",
    lat: "34.22684750",
    lng: "77.56194190"
    },
    {
    state: "Lakshadweep",
    stateShort: "LD",
    lat: "10.32802650",
    lng: "72.78463360"
    },
    {
    state: "Madhya Pradesh",
    stateShort: "MP",
    lat: "22.97342290",
    lng: "78.65689420"
    },
    {
    state: "Maharashtra",
    stateShort: "MH",
    lat: "19.75147980",
    lng: "75.71388840"
    },
    {
    state: "Manipur",
    stateShort: "MN",
    lat: "24.66371730",
    lng: "93.90626880"
    },
    {
    state: "Meghalaya",
    stateShort: "ML",
    lat: "25.46703080",
    lng: "91.36621600"
    },
    {
    state: "Mizoram",
    stateShort: "MZ",
    lat: "23.16454300",
    lng: "92.93757390"
    },
    {
    state: "Nagaland",
    stateShort: "NL",
    lat: "26.15843540",
    lng: "94.56244260"
    },
    {
    state: "Odisha",
    stateShort: "OR",
    lat: "20.95166580",
    lng: "85.09852360"
    },
    {
    state: "Puducherry",
    stateShort: "PY",
    lat: "11.94159150",
    lng: "79.80831330"
    },
    {
    state: "Punjab",
    stateShort: "PB",
    lat: "31.14713050",
    lng: "75.34121790"
    },
    {
    state: "Rajasthan",
    stateShort: "RJ",
    lat: "27.02380360",
    lng: "74.21793260"
    },
    {
    state: "Sikkim",
    stateShort: "SK",
    lat: "27.53297180",
    lng: "88.51221780"
    },
    {
    state: "Tamil Nadu",
    stateShort: "TN",
    lat: "11.12712250",
    lng: "78.65689420"
    },
    {
    state: "Telangana",
    stateShort: "TG",
    lat: "18.11243720",
    lng: "79.01929970"
    },
    {
    state: "Tripura",
    stateShort: "TR",
    lat: "23.94084820",
    lng: "91.98815270"
    },
    {
    state: "Uttar Pradesh",
    stateShort: "UP",
    lat: "26.84670880",
    lng: "80.94615920"
    },
    {
    state: "Uttarakhand",
    stateShort: "UT",
    lat: "30.06675300",
    lng: "79.01929970"
    },
    {
    state: "West Bengal",
    stateShort: "WB",
    lat: "22.98675690",
    lng: "87.85497550"
    }
    ]
    },
    {
    country: "Indonesia",
    countryShort: "ID",
    currency_symbol: "Rp",
    states: [
    {
    state: "Aceh",
    stateShort: "AC",
    lat: "4.69513500",
    lng: "96.74939930"
    },
    {
    state: "Bali",
    stateShort: "BA",
    lat: "-8.34053890",
    lng: "115.09195090"
    },
    {
    state: "Banten",
    stateShort: "BT",
    lat: "-6.40581720",
    lng: "106.06401790"
    },
    {
    state: "Bengkulu",
    stateShort: "BE",
    lat: "-3.79284510",
    lng: "102.26076410"
    },
    {
    state: "DI Yogyakarta",
    stateShort: "YO",
    lat: "-7.87538490",
    lng: "110.42620880"
    },
    {
    state: "DKI Jakarta",
    stateShort: "JK",
    lat: "-6.20876340",
    lng: "106.84559900"
    },
    {
    state: "Gorontalo",
    stateShort: "GO",
    lat: "0.54354420",
    lng: "123.05676930"
    },
    {
    state: "Jambi",
    stateShort: "JA",
    lat: "-1.61012290",
    lng: "103.61312030"
    },
    {
    state: "Jawa Barat",
    stateShort: "JB",
    lat: "-7.09091100",
    lng: "107.66888700"
    },
    {
    state: "Jawa Tengah",
    stateShort: "JT",
    lat: "-7.15097500",
    lng: "110.14025940"
    },
    {
    state: "Jawa Timur",
    stateShort: "JI",
    lat: "-7.53606390",
    lng: "112.23840170"
    },
    {
    state: "Kalimantan Barat",
    stateShort: "KB",
    lat: "0.47734750",
    lng: "106.61314050"
    },
    {
    state: "Kalimantan Selatan",
    stateShort: "KS",
    lat: "-3.09264150",
    lng: "115.28375850"
    },
    {
    state: "Kalimantan Tengah",
    stateShort: "KT",
    lat: "-1.68148780",
    lng: "113.38235450"
    },
    {
    state: "Kalimantan Timur",
    stateShort: "KI",
    lat: "0.53865860",
    lng: "116.41938900"
    },
    {
    state: "Kalimantan Utara",
    stateShort: "KU",
    lat: "3.07309290",
    lng: "116.04138890"
    },
    {
    state: "Kepulauan Bangka Belitung",
    stateShort: "BB",
    lat: "-2.74105130",
    lng: "106.44058720"
    },
    {
    state: "Kepulauan Riau",
    stateShort: "KR",
    lat: "3.94565140",
    lng: "108.14286690"
    },
    {
    state: "Lampung",
    stateShort: "LA",
    lat: "-4.55858490",
    lng: "105.40680790"
    },
    {
    state: "Maluku",
    stateShort: "MA",
    lat: "-3.23846160",
    lng: "130.14527340"
    },
    {
    state: "Maluku Utara",
    stateShort: "MU",
    lat: "1.57099930",
    lng: "127.80876930"
    },
    {
    state: "Nusa Tenggara Barat",
    stateShort: "NB",
    lat: "-8.65293340",
    lng: "117.36164760"
    },
    {
    state: "Nusa Tenggara Timur",
    stateShort: "NT",
    lat: "-8.65738190",
    lng: "121.07937050"
    },
    {
    state: "Papua",
    stateShort: "PA",
    lat: "-5.01222020",
    lng: "141.34701590"
    },
    {
    state: "Papua Barat",
    stateShort: "PB",
    lat: "-1.33611540",
    lng: "133.17471620"
    },
    {
    state: "Riau",
    stateShort: "RI",
    lat: "0.29334690",
    lng: "101.70682940"
    },
    {
    state: "Sulawesi Barat",
    stateShort: "SR",
    lat: "-2.84413710",
    lng: "119.23207840"
    },
    {
    state: "Sulawesi Selatan",
    stateShort: "SN",
    lat: "-3.66879940",
    lng: "119.97405340"
    },
    {
    state: "Sulawesi Tengah",
    stateShort: "ST",
    lat: "-1.43002540",
    lng: "121.44561790"
    },
    {
    state: "Sulawesi Tenggara",
    stateShort: "SG",
    lat: "-4.14491000",
    lng: "122.17460500"
    },
    {
    state: "Sulawesi Utara",
    stateShort: "SA",
    lat: "0.62469320",
    lng: "123.97500180"
    },
    {
    state: "Sumatera Barat",
    stateShort: "SB",
    lat: "-0.73993970",
    lng: "100.80000510"
    },
    {
    state: "Sumatera Selatan",
    stateShort: "SS",
    lat: "-3.31943740",
    lng: "103.91439900"
    },
    {
    state: "Sumatera Utara",
    stateShort: "SU",
    lat: "2.11535470",
    lng: "99.54509740"
    }
    ]
    },
    {
    country: "Iran",
    countryShort: "IR",
    currency_symbol: "﷼",
    states: [
      {
         state: "Tehran",
         stateShort: "23",
         lat: "35.72484160",
         lng: "51.38165300"
         },
    {
    state: "Alborz",
    stateShort: "30",
    lat: "35.99604670",
    lng: "50.92892460"
    },
    {
    state: "Ardabil",
    stateShort: "24",
    lat: "38.48532760",
    lng: "47.89112090"
    },
    {
    state: "Bushehr",
    stateShort: "18",
    lat: "28.76207390",
    lng: "51.51500770"
    },
    {
    state: "Chaharmahal and Bakhtiari",
    stateShort: "14",
    lat: "31.99704190",
    lng: "50.66138490"
    },
    {
    state: "East Azerbaijan",
    stateShort: "03",
    lat: "37.90357330",
    lng: "46.26821090"
    },
    {
    state: "Fars",
    stateShort: "07",
    lat: "29.10438130",
    lng: "53.04589300"
    },
    {
    state: "Gilan",
    stateShort: "01",
    lat: "37.28094550",
    lng: "49.59241340"
    },
    {
    state: "Golestan",
    stateShort: "27",
    lat: "37.28981230",
    lng: "55.13758340"
    },
    {
    state: "Hamadan",
    stateShort: "13",
    lat: "34.91936070",
    lng: "47.48329250"
    },
    {
    state: "Hormozgan",
    stateShort: "22",
    lat: "27.13872300",
    lng: "55.13758340"
    },
    {
    state: "Ilam",
    stateShort: "16",
    lat: "33.29576180",
    lng: "46.67053400"
    },
    {
    state: "Isfahan",
    stateShort: "10",
    lat: "33.27710730",
    lng: "52.36133780"
    },
    {
    state: "Kerman",
    stateShort: "08",
    lat: "29.48500890",
    lng: "57.64390480"
    },
    {
    state: "Kermanshah",
    stateShort: "05",
    lat: "34.45762330",
    lng: "46.67053400"
    },
    {
    state: "Khuzestan",
    stateShort: "06",
    lat: "31.43601490",
    lng: "49.04131200"
    },
    {
    state: "Kohgiluyeh and Boyer-Ahmad",
    stateShort: "17",
    lat: "30.72458600",
    lng: "50.84563230"
    },
    {
    state: "Kurdistan",
    stateShort: "12",
    lat: "35.95535790",
    lng: "47.13621250"
    },
    {
    state: "Lorestan",
    stateShort: "15",
    lat: "33.58183940",
    lng: "48.39881860"
    },
    {
    state: "Markazi",
    stateShort: "00",
    lat: "34.61230500",
    lng: "49.85472660"
    },
    {
    state: "Mazandaran",
    stateShort: "02",
    lat: "36.22623930",
    lng: "52.53186040"
    },
    {
    state: "North Khorasan",
    stateShort: "28",
    lat: "37.47103530",
    lng: "57.10131880"
    },
    {
    state: "Qazvin",
    stateShort: "26",
    lat: "36.08813170",
    lng: "49.85472660"
    },
    {
    state: "Qom",
    stateShort: "25",
    lat: "34.64157640",
    lng: "50.87460350"
    },
    {
    state: "Razavi Khorasan",
    stateShort: "09",
    lat: "35.10202530",
    lng: "59.10417580"
    },
    {
    state: "Semnan",
    stateShort: "20",
    lat: "35.22555850",
    lng: "54.43421380"
    },
    {
    state: "Sistan and Baluchestan",
    stateShort: "11",
    lat: "27.52999060",
    lng: "60.58206760"
    },
    {
    state: "South Khorasan",
    stateShort: "29",
    lat: "32.51756430",
    lng: "59.10417580"
    },
    
    {
    state: "West Azarbaijan",
    stateShort: "04",
    lat: "37.45500620",
    lng: "45.00000000"
    },
    {
    state: "Yazd",
    stateShort: "21",
    lat: "32.10063870",
    lng: "54.43421380"
    },
    {
    state: "Zanjan",
    stateShort: "19",
    lat: "36.50181850",
    lng: "48.39881860"
    }
    ]
    },
    {
    country: "Iraq",
    countryShort: "IQ",
    currency_symbol: "د.ع",
    states: [
    {
    state: "Al Anbar",
    stateShort: "AN",
    lat: "32.55976140",
    lng: "41.91964710"
    },
    {
    state: "Al Muthanna",
    stateShort: "MU",
    lat: "29.91331710",
    lng: "45.29938620"
    },
    {
    state: "Al-Qādisiyyah",
    stateShort: "QA",
    lat: "32.04369100",
    lng: "45.14945050"
    },
    {
    state: "Babylon",
    stateShort: "BB",
    lat: "32.46819100",
    lng: "44.55019350"
    },
    {
    state: "Baghdad",
    stateShort: "BG",
    lat: "33.31526180",
    lng: "44.36606530"
    },
    {
    state: "Basra",
    stateShort: "BA",
    lat: "30.51142520",
    lng: "47.82962530"
    },
    {
    state: "Dhi Qar",
    stateShort: "DQ",
    lat: "31.10422920",
    lng: "46.36246860"
    },
    {
    state: "Diyala",
    stateShort: "DI",
    lat: "33.77334870",
    lng: "45.14945050"
    },
    {
    state: "Dohuk",
    stateShort: "DA",
    lat: "36.90772520",
    lng: "43.06316890"
    },
    {
    state: "Erbil",
    stateShort: "AR",
    lat: "36.55706280",
    lng: "44.38512630"
    },
    {
    state: "Karbala",
    stateShort: "KA",
    lat: "32.40454930",
    lng: "43.86732220"
    },
    {
    state: "Kirkuk",
    stateShort: "KI",
    lat: "35.32920140",
    lng: "43.94367880"
    },
    {
    state: "Maysan",
    stateShort: "MA",
    lat: "31.87340020",
    lng: "47.13621250"
    },
    {
    state: "Najaf",
    stateShort: "NA",
    lat: "31.35174860",
    lng: "44.09603110"
    },
    {
    state: "Nineveh",
    stateShort: "NI",
    lat: "36.22957400",
    lng: "42.23624350"
    },
    {
    state: "Saladin",
    stateShort: "SD",
    lat: "34.53375270",
    lng: "43.48373800"
    },
    {
    state: "Sulaymaniyah",
    stateShort: "SU",
    lat: "35.54663480",
    lng: "45.30036830"
    },
    {
    state: "Wasit",
    stateShort: "WA",
    lat: "32.60240940",
    lng: "45.75209850"
    }
    ]
    },
    {
    country: "Ireland",
    countryShort: "IE",
    currency_symbol: "€",
    states: [
    {
    state: "Carlow",
    stateShort: "CW",
    lat: "52.72322170",
    lng: "-6.81082950"
    },
    {
    state: "Cavan",
    stateShort: "CN",
    lat: "53.97654240",
    lng: "-7.29966230"
    },
    {
    state: "Clare",
    stateShort: "CE",
    lat: "43.04664000",
    lng: "-87.89958100"
    },
    {
    state: "Connacht",
    stateShort: "C",
    lat: "53.83762430",
    lng: "-8.95844810"
    },
    {
    state: "Cork",
    stateShort: "CO",
    lat: "51.89851430",
    lng: "-8.47560350"
    },
    {
    state: "Donegal",
    stateShort: "DL",
    lat: "54.65489930",
    lng: "-8.10409670"
    },
    {
    state: "Dublin",
    stateShort: "D",
    lat: "53.34980530",
    lng: "-6.26030970"
    },
    {
    state: "Galway",
    stateShort: "G",
    lat: "53.35645090",
    lng: "-8.85341130"
    },
    {
    state: "Kerry",
    stateShort: "KY",
    lat: "52.15446070",
    lng: "-9.56686330"
    },
    {
    state: "Kildare",
    stateShort: "KE",
    lat: "53.21204340",
    lng: "-6.81947080"
    },
    {
    state: "Kilkenny",
    stateShort: "KK",
    lat: "52.57769570",
    lng: "-7.21800200"
    },
    {
    state: "Laois",
    stateShort: "LS",
    lat: "52.99429500",
    lng: "-7.33230070"
    },
    {
    state: "Leinster",
    stateShort: "L",
    lat: "53.32715380",
    lng: "-7.51408410"
    },
    {
    state: "Limerick",
    stateShort: "LK",
    lat: "52.50905170",
    lng: "-8.74749550"
    },
    {
    state: "Longford",
    stateShort: "LD",
    lat: "53.72749820",
    lng: "-7.79315270"
    },
    {
    state: "Louth",
    stateShort: "LH",
    lat: "53.92523240",
    lng: "-6.48894230"
    },
    {
    state: "Mayo",
    stateShort: "MO",
    lat: "54.01526040",
    lng: "-9.42893690"
    },
    {
    state: "Meath",
    stateShort: "MH",
    lat: "53.60554800",
    lng: "-6.65641690"
    },
    {
    state: "Monaghan",
    stateShort: "MN",
    lat: "54.24920460",
    lng: "-6.96831320"
    },
    {
    state: "Munster",
    stateShort: "M",
    lat: "51.94711970",
    lng: "7.58453200"
    },
    {
    state: "Offaly",
    stateShort: "OY",
    lat: "53.23568710",
    lng: "-7.71222290"
    },
    {
    state: "Roscommon",
    stateShort: "RN",
    lat: "53.75926040",
    lng: "-8.26816210"
    },
    {
    state: "Sligo",
    stateShort: "SO",
    lat: "54.15532770",
    lng: "-8.60645320"
    },
    {
    state: "Tipperary",
    stateShort: "TA",
    lat: "52.47378940",
    lng: "-8.16185140"
    },
    {
    state: "Ulster",
    stateShort: "U",
    lat: "54.76165550",
    lng: "-6.96122730"
    },
    {
    state: "Waterford",
    stateShort: "WD",
    lat: "52.19435490",
    lng: "-7.62275120"
    },
    {
    state: "Westmeath",
    stateShort: "WH",
    lat: "53.53453080",
    lng: "-7.46532170"
    },
    {
    state: "Wexford",
    stateShort: "WX",
    lat: "52.47936030",
    lng: "-6.58399130"
    },
    {
    state: "Wicklow",
    stateShort: "WW",
    lat: "52.98623130",
    lng: "-6.36725430"
    }
    ]
    },
    {
    country: "Israel",
    countryShort: "IL",
    currency_symbol: "₪",
    states: [
    {
    state: "Central District",
    stateShort: "M",
    lat: "47.60875830",
    lng: "-122.29642350"
    },
    {
    state: "Haifa District",
    stateShort: "HA",
    lat: "32.48141110",
    lng: "34.99475100"
    },
    {
    state: "Jerusalem District",
    stateShort: "JM",
    lat: "31.76482430",
    lng: "34.99475100"
    },
    {
    state: "Northern District",
    stateShort: "Z",
    lat: "36.15118640",
    lng: "-95.99517630"
    },
    {
    state: "Southern District",
    stateShort: "D",
    lat: "40.71375860",
    lng: "-74.00090590"
    },
    {
    state: "Tel Aviv District",
    stateShort: "TA",
    lat: "32.09290750",
    lng: "34.80721650"
    }
    ]
    },
    {
    country: "Italy",
    countryShort: "IT",
    currency_symbol: "€",
    states: [
    {
    state: "Abruzzo",
    stateShort: "65",
    lat: "42.19201190",
    lng: "13.72891670"
    },
    {
    state: "Agrigento",
    stateShort: "AG",
    lat: "37.31052020",
    lng: "13.58579780"
    },
    {
    state: "Alessandria",
    stateShort: "AL",
    lat: "44.81755870",
    lng: "8.70466270"
    },
    {
    state: "Ancona",
    stateShort: "AN",
    lat: "43.54932450",
    lng: "13.26634790"
    },
    {
    state: "Aosta Valley",
    stateShort: "23",
    lat: "45.73888780",
    lng: "7.42618660"
    },
    {
    state: "Apulia",
    stateShort: "75",
    lat: "40.79283930",
    lng: "17.10119310"
    },
    {
    state: "Ascoli Piceno",
    stateShort: "AP",
    lat: "42.86389330",
    lng: "13.58997330"
    },
    {
    state: "Asti",
    stateShort: "AT",
    lat: "44.90076520",
    lng: "8.20643150"
    },
    {
    state: "Avellino",
    stateShort: "AV",
    lat: "40.99645100",
    lng: "15.12589550"
    },
    {
    state: "Barletta-Andria-Trani",
    stateShort: "BT",
    lat: "41.20045430",
    lng: "16.20514840"
    },
    {
    state: "Basilicata",
    stateShort: "77",
    lat: "40.64307660",
    lng: "15.96998780"
    },
    {
    state: "Belluno",
    stateShort: "BL",
    lat: "46.24976590",
    lng: "12.19695650"
    },
    {
    state: "Benevento",
    stateShort: "BN",
    lat: "41.20350930",
    lng: "14.75209390"
    },
    {
    state: "Bergamo",
    stateShort: "BG",
    lat: "45.69826420",
    lng: "9.67726980"
    },
    {
    state: "Biella",
    stateShort: "BI",
    lat: "45.56281760",
    lng: "8.05827170"
    },
    {
    state: "Brescia",
    stateShort: "BS",
    lat: "45.54155260",
    lng: "10.21180190"
    },
    {
    state: "Brindisi",
    stateShort: "BR",
    lat: "40.61126630",
    lng: "17.76362100"
    },
    {
    state: "Calabria",
    stateShort: "78",
    lat: "39.30877140",
    lng: "16.34637910"
    },
    {
    state: "Caltanissetta",
    stateShort: "CL",
    lat: "37.48601300",
    lng: "14.06149820"
    },
    {
    state: "Campania",
    stateShort: "72",
    lat: "40.66708870",
    lng: "15.10681130"
    },
    {
    state: "Campobasso",
    stateShort: "CB",
    lat: "41.67388650",
    lng: "14.75209390"
    },
    {
    state: "Caserta",
    stateShort: "CE",
    lat: "41.20783540",
    lng: "14.10013260"
    },
    {
    state: "Catanzaro",
    stateShort: "CZ",
    lat: "38.88963480",
    lng: "16.44058720"
    },
    {
    state: "Chieti",
    stateShort: "CH",
    lat: "42.03344280",
    lng: "14.37919120"
    },
    {
    state: "Como",
    stateShort: "CO",
    lat: "45.80804160",
    lng: "9.08517930"
    },
    {
    state: "Cosenza",
    stateShort: "CS",
    lat: "39.56441050",
    lng: "16.25221430"
    },
    {
    state: "Cremona",
    stateShort: "CR",
    lat: "45.20143750",
    lng: "9.98365820"
    },
    {
    state: "Crotone",
    stateShort: "KR",
    lat: "39.13098560",
    lng: "17.00670310"
    },
    {
    state: "Cuneo",
    stateShort: "CN",
    lat: "44.59703140",
    lng: "7.61142170"
    },
    {
    state: "Emilia-Romagna",
    stateShort: "45",
    lat: "44.59676070",
    lng: "11.21863960"
    },
    {
    state: "Enna",
    stateShort: "EN",
    lat: "37.56762160",
    lng: "14.27953490"
    },
    {
    state: "Fermo",
    stateShort: "FM",
    lat: "43.09313670",
    lng: "13.58997330"
    },
    {
    state: "Ferrara",
    stateShort: "FE",
    lat: "44.76636800",
    lng: "11.76440680"
    },
    {
    state: "Foggia",
    stateShort: "FG",
    lat: "41.63844800",
    lng: "15.59433880"
    },
    {
    state: "Forlì-Cesena",
    stateShort: "FC",
    lat: "43.99476810",
    lng: "11.98046130"
    },
    {
    state: "Friuli-venezia giulia",
    stateShort: "36",
    lat: "46.22591770",
    lng: "13.10336460"
    },
    {
    state: "Frosinone",
    stateShort: "FR",
    lat: "41.65765280",
    lng: "13.63627150"
    },
    {
    state: "Gorizia",
    stateShort: "GO",
    lat: "45.90538990",
    lng: "13.51637250"
    },
    {
    state: "Grosseto",
    stateShort: "GR",
    lat: "42.85180070",
    lng: "11.25237920"
    },
    {
    state: "Imperia",
    stateShort: "IM",
    lat: "43.94186600",
    lng: "7.82863680"
    },
    {
    state: "Isernia",
    stateShort: "IS",
    lat: "41.58915550",
    lng: "14.19309180"
    },
    {
    state: "L'Aquila",
    stateShort: "AQ",
    lat: "42.12563170",
    lng: "13.63627150"
    },
    {
    state: "La Spezia",
    stateShort: "SP",
    lat: "44.24479130",
    lng: "9.76786870"
    },
    {
    state: "Latina",
    stateShort: "LT",
    lat: "41.40874760",
    lng: "13.08179030"
    },
    {
    state: "Lazio",
    stateShort: "62",
    lat: "41.81224100",
    lng: "12.73851000"
    },
    {
    state: "Lecce",
    stateShort: "LE",
    lat: "40.23473930",
    lng: "18.14286690"
    },
    {
    state: "Lecco",
    stateShort: "LC",
    lat: "45.93829410",
    lng: "9.38572900"
    },
    {
    state: "Liguria",
    stateShort: "42",
    lat: "44.31679170",
    lng: "8.39649380"
    },
    {
    state: "Livorno",
    stateShort: "LI",
    lat: "43.02398480",
    lng: "10.66471010"
    },
    {
    state: "Lodi",
    stateShort: "LO",
    lat: "45.24050360",
    lng: "9.52925120"
    },
    {
    state: "Lombardy",
    stateShort: "25",
    lat: "45.47906710",
    lng: "9.84524330"
    },
    {
    state: "Lucca",
    stateShort: "LU",
    lat: "43.83767360",
    lng: "10.49505300"
    },
    {
    state: "Macerata",
    stateShort: "MC",
    lat: "43.24593220",
    lng: "13.26634790"
    },
    {
    state: "Mantua",
    stateShort: "MN",
    lat: "45.16677280",
    lng: "10.77536130"
    },
    {
    state: "Marche",
    stateShort: "57",
    lat: "43.30456200",
    lng: "13.71947000"
    },
    {
    state: "Massa and Carrara",
    stateShort: "MS",
    lat: "44.22139980",
    lng: "10.03596610"
    },
    {
    state: "Matera",
    stateShort: "MT",
    lat: "40.66634960",
    lng: "16.60436360"
    },
    {
    state: "Medio Campidano",
    stateShort: "VS",
    lat: "39.53173890",
    lng: "8.70407500"
    },
    {
    state: "Modena",
    stateShort: "MO",
    lat: "44.55137990",
    lng: "10.91804800"
    },
    {
    state: "Molise",
    stateShort: "67",
    lat: "41.67388650",
    lng: "14.75209390"
    },
    {
    state: "Monza and Brianza",
    stateShort: "MB",
    lat: "45.62359900",
    lng: "9.25880150"
    },
    {
    state: "Novara",
    stateShort: "NO",
    lat: "45.54851330",
    lng: "8.51507930"
    },
    {
    state: "Nuoro",
    stateShort: "NU",
    lat: "40.32869040",
    lng: "9.45615500"
    },
    {
    state: "Oristano",
    stateShort: "OR",
    lat: "40.05990680",
    lng: "8.74811670"
    },
    {
    state: "Padua",
    stateShort: "PD",
    lat: "45.36618640",
    lng: "11.82091390"
    },
    {
    state: "Palermo",
    stateShort: "PA",
    lat: "38.11569000",
    lng: "13.36148680"
    },
    {
    state: "Parma",
    stateShort: "PR",
    lat: "44.80153220",
    lng: "10.32793540"
    },
    {
    state: "Pavia",
    stateShort: "PV",
    lat: "45.32181660",
    lng: "8.84662360"
    },
    {
    state: "Perugia",
    stateShort: "PG",
    lat: "42.93800400",
    lng: "12.62162110"
    },
    {
    state: "Pesaro and Urbino",
    stateShort: "PU",
    lat: "43.61301180",
    lng: "12.71351210"
    },
    {
    state: "Pescara",
    stateShort: "PE",
    lat: "42.35706550",
    lng: "13.96080910"
    },
    {
    state: "Piacenza",
    stateShort: "PC",
    lat: "44.82631120",
    lng: "9.52914470"
    },
    {
    state: "Piedmont",
    stateShort: "21",
    lat: "45.05223660",
    lng: "7.51538850"
    },
    {
    state: "Pisa",
    stateShort: "PI",
    lat: "43.72283150",
    lng: "10.40171940"
    },
    {
    state: "Pistoia",
    stateShort: "PT",
    lat: "43.95437330",
    lng: "10.89030990"
    },
    {
    state: "Pordenone",
    stateShort: "PN",
    lat: "46.03788620",
    lng: "12.71083500"
    },
    {
    state: "Potenza",
    stateShort: "PZ",
    lat: "40.41821940",
    lng: "15.87600400"
    },
    {
    state: "Prato",
    stateShort: "PO",
    lat: "44.04539000",
    lng: "11.11644520"
    },
    {
    state: "Ragusa",
    stateShort: "RG",
    lat: "36.92692730",
    lng: "14.72551290"
    },
    {
    state: "Ravenna",
    stateShort: "RA",
    lat: "44.41844430",
    lng: "12.20359980"
    },
    {
    state: "Reggio Emilia",
    stateShort: "RE",
    lat: "44.58565800",
    lng: "10.55647360"
    },
    {
    state: "Rieti",
    stateShort: "RI",
    lat: "42.36744050",
    lng: "12.89750980"
    },
    {
    state: "Rimini",
    stateShort: "RN",
    lat: "44.06782880",
    lng: "12.56951580"
    },
    {
    state: "Rovigo",
    stateShort: "RO",
    lat: "45.02418180",
    lng: "11.82381620"
    },
    {
    state: "Salerno",
    stateShort: "SA",
    lat: "40.42878320",
    lng: "15.21948080"
    },
    {
    state: "Sardinia",
    stateShort: "88",
    lat: "40.12087520",
    lng: "9.01289260"
    },
    {
    state: "Sassari",
    stateShort: "SS",
    lat: "40.79679070",
    lng: "8.57504070"
    },
    {
    state: "Savona",
    stateShort: "SV",
    lat: "44.28879950",
    lng: "8.26505800"
    },
    {
    state: "Sicilia",
    stateShort: "82",
    lat: "37.59999380",
    lng: "14.01535570"
    },
    {
    state: "Siena",
    stateShort: "SI",
    lat: "43.29377320",
    lng: "11.43391480"
    },
    {
    state: "Siracusa",
    stateShort: "SR",
    lat: "37.06569240",
    lng: "15.28571090"
    },
    {
    state: "Sondrio",
    stateShort: "SO",
    lat: "46.17276360",
    lng: "9.79949170"
    },
    {
    state: "South Sardinia",
    stateShort: "SU",
    lat: "39.38935350",
    lng: "8.93970000"
    },
    {
    state: "Taranto",
    stateShort: "TA",
    lat: "40.57409010",
    lng: "17.24299760"
    },
    {
    state: "Teramo",
    stateShort: "TE",
    lat: "42.58956080",
    lng: "13.63627150"
    },
    {
    state: "Terni",
    stateShort: "TR",
    lat: "42.56345340",
    lng: "12.52980280"
    },
    {
    state: "Trapani",
    stateShort: "TP",
    lat: "38.01831160",
    lng: "12.51482650"
    },
    {
    state: "Trentino-south tyrol",
    stateShort: "32",
    lat: "46.43366620",
    lng: "11.16932960"
    },
    {
    state: "Treviso",
    stateShort: "TV",
    lat: "45.66685170",
    lng: "12.24306170"
    },
    {
    state: "Trieste",
    stateShort: "TS",
    lat: "45.68948230",
    lng: "13.78330720"
    },
    {
    state: "Tuscany",
    stateShort: "52",
    lat: "43.77105130",
    lng: "11.24862080"
    },
    {
    state: "Udine",
    stateShort: "UD",
    lat: "46.14079720",
    lng: "13.16628960"
    },
    {
    state: "Umbria",
    stateShort: "55",
    lat: "42.93800400",
    lng: "12.62162110"
    },
    {
    state: "Varese",
    stateShort: "VA",
    lat: "45.79902600",
    lng: "8.73009450"
    },
    {
    state: "Veneto",
    stateShort: "34",
    lat: "45.44146620",
    lng: "12.31525950"
    },
    {
    state: "Verbano-Cusio-Ossola",
    stateShort: "VB",
    lat: "46.13996880",
    lng: "8.27246490"
    },
    {
    state: "Vercelli",
    stateShort: "VC",
    lat: "45.32022040",
    lng: "8.41850800"
    },
    {
    state: "Verona",
    stateShort: "VR",
    lat: "45.44184980",
    lng: "11.07353160"
    },
    {
    state: "Vibo Valentia",
    stateShort: "VV",
    lat: "38.63785650",
    lng: "16.20514840"
    },
    {
    state: "Vicenza",
    stateShort: "VI",
    lat: "45.69839950",
    lng: "11.56614650"
    },
    {
    state: "Viterbo",
    stateShort: "VT",
    lat: "42.40024200",
    lng: "11.88917210"
    }
    ]
    },
    {
    country: "Jamaica",
    countryShort: "JM",
    currency_symbol: "J$",
    states: [
    {
    state: "Clarendon Parish",
    stateShort: "13",
    lat: "17.95571830",
    lng: "-77.24051530"
    },
    {
    state: "Hanover Parish",
    stateShort: "09",
    lat: "18.40977070",
    lng: "-78.13363800"
    },
    {
    state: "Kingston Parish",
    stateShort: "01",
    lat: "17.96832710",
    lng: "-76.78270200"
    },
    {
    state: "Manchester Parish",
    stateShort: "12",
    lat: "18.06696540",
    lng: "-77.51607880"
    },
    {
    state: "Portland Parish",
    stateShort: "04",
    lat: "18.08442740",
    lng: "-76.41002670"
    },
    {
    state: "Saint Andrew",
    stateShort: "02",
    lat: "37.22451030",
    lng: "-95.70211890"
    },
    {
    state: "Saint Ann Parish",
    stateShort: "06",
    lat: "37.28714520",
    lng: "-77.41035330"
    },
    {
    state: "Saint Catherine Parish",
    stateShort: "14",
    lat: "18.03641340",
    lng: "-77.05644640"
    },
    {
    state: "Saint Elizabeth Parish",
    stateShort: "11",
    lat: "38.99253080",
    lng: "-94.58992000"
    },
    {
    state: "Saint James Parish",
    stateShort: "08",
    lat: "30.01792920",
    lng: "-90.79132270"
    },
    {
    state: "Saint Mary Parish",
    stateShort: "05",
    lat: "36.09252200",
    lng: "-95.97384400"
    },
    {
    state: "Saint Thomas Parish",
    stateShort: "03",
    lat: "41.44253890",
    lng: "-81.74022180"
    },
    {
    state: "Trelawny Parish",
    stateShort: "07",
    lat: "18.35261430",
    lng: "-77.60778650"
    },
    {
    state: "Westmoreland Parish",
    stateShort: "10",
    lat: "18.29443780",
    lng: "-78.15644320"
    }
    ]
    },
    {
    country: "Japan",
    countryShort: "JP",
    currency_symbol: "¥",
    states: [
    {
    state: "Aichi",
    stateShort: "23",
    lat: "35.01825050",
    lng: "137.29238930"
    },
    {
    state: "Akita",
    stateShort: "05",
    lat: "40.13762930",
    lng: "140.33434100"
    },
    {
    state: "Aomori",
    stateShort: "02",
    lat: "40.76570770",
    lng: "140.91758790"
    },
    {
    state: "Chiba",
    stateShort: "12",
    lat: "35.33541550",
    lng: "140.18325160"
    },
    {
    state: "Ehime",
    stateShort: "38",
    lat: "33.60253060",
    lng: "132.78575830"
    },
    {
    state: "Fukui",
    stateShort: "18",
    lat: "35.89622700",
    lng: "136.21115790"
    },
    {
    state: "Fukuoka",
    stateShort: "40",
    lat: "33.56625590",
    lng: "130.71585700"
    },
    {
    state: "Fukushima",
    stateShort: "07",
    lat: "37.38343730",
    lng: "140.18325160"
    },
    {
    state: "Gifu",
    stateShort: "21",
    lat: "35.74374910",
    lng: "136.98051030"
    },
    {
    state: "Gunma",
    stateShort: "10",
    lat: "36.56053880",
    lng: "138.87999720"
    },
    {
    state: "Hiroshima",
    stateShort: "34",
    lat: "34.88234080",
    lng: "133.01948970"
    },
    {
    state: "Hokkaidō",
    stateShort: "01",
    lat: "43.22032660",
    lng: "142.86347370"
    },
    {
    state: "Hyōgo",
    stateShort: "28",
    lat: "34.85795180",
    lng: "134.54537870"
    },
    {
    state: "Ibaraki",
    stateShort: "08",
    lat: "36.21935710",
    lng: "140.18325160"
    },
    {
    state: "Ishikawa",
    stateShort: "17",
    lat: "36.32603170",
    lng: "136.52896530"
    },
    {
    state: "Iwate",
    stateShort: "03",
    lat: "39.58329890",
    lng: "141.25345740"
    },
    {
    state: "Kagawa",
    stateShort: "37",
    lat: "34.22259150",
    lng: "134.01991520"
    },
    {
    state: "Kagoshima",
    stateShort: "46",
    lat: "31.39119580",
    lng: "130.87785860"
    },
    {
    state: "Kanagawa",
    stateShort: "14",
    lat: "35.49135350",
    lng: "139.28414300"
    },
    {
    state: "Kōchi",
    stateShort: "39",
    lat: "33.28791610",
    lng: "132.27592620"
    },
    {
    state: "Kumamoto",
    stateShort: "43",
    lat: "32.85944270",
    lng: "130.79691490"
    },
    {
    state: "Kyōto",
    stateShort: "26",
    lat: "35.15666090",
    lng: "135.52519820"
    },
    {
    state: "Mie",
    stateShort: "24",
    lat: "33.81439010",
    lng: "136.04870470"
    },
    {
    state: "Miyagi",
    stateShort: "04",
    lat: "38.63061200",
    lng: "141.11930480"
    },
    {
    state: "Miyazaki",
    stateShort: "45",
    lat: "32.60360220",
    lng: "131.44125100"
    },
    {
    state: "Nagano",
    stateShort: "20",
    lat: "36.15439410",
    lng: "137.92182040"
    },
    {
    state: "Nagasaki",
    stateShort: "42",
    lat: "33.24885250",
    lng: "129.69309120"
    },
    {
    state: "Nara",
    stateShort: "29",
    lat: "34.29755280",
    lng: "135.82797340"
    },
    {
    state: "Niigata",
    stateShort: "15",
    lat: "37.51783860",
    lng: "138.92697940"
    },
    {
    state: "Ōita",
    stateShort: "44",
    lat: "33.15892990",
    lng: "131.36111210"
    },
    {
    state: "Okayama",
    stateShort: "33",
    lat: "34.89634070",
    lng: "133.63753140"
    },
    {
    state: "Okinawa",
    stateShort: "47",
    lat: "26.12019110",
    lng: "127.70250120"
    },
    {
    state: "Ōsaka",
    stateShort: "27",
    lat: "34.64133150",
    lng: "135.56293940"
    },
    {
    state: "Saga",
    stateShort: "41",
    lat: "33.30783710",
    lng: "130.22712430"
    },
    {
    state: "Saitama",
    stateShort: "11",
    lat: "35.99625130",
    lng: "139.44660050"
    },
    {
    state: "Shiga",
    stateShort: "25",
    lat: "35.32920140",
    lng: "136.05632120"
    },
    {
    state: "Shimane",
    stateShort: "32",
    lat: "35.12440940",
    lng: "132.62934460"
    },
    {
    state: "Shizuoka",
    stateShort: "22",
    lat: "35.09293970",
    lng: "138.31902760"
    },
    {
    state: "Tochigi",
    stateShort: "09",
    lat: "36.67147390",
    lng: "139.85472660"
    },
    {
    state: "Tokushima",
    stateShort: "36",
    lat: "33.94196550",
    lng: "134.32365570"
    },
    {
    state: "Tokyo",
    stateShort: "13",
    lat: "35.67619190",
    lng: "139.65031060"
    },
    {
    state: "Tottori",
    stateShort: "31",
    lat: "35.35731610",
    lng: "133.40666180"
    },
    {
    state: "Toyama",
    stateShort: "16",
    lat: "36.69582660",
    lng: "137.21370710"
    },
    {
    state: "Wakayama",
    stateShort: "30",
    lat: "33.94809140",
    lng: "135.37453580"
    },
    {
    state: "Yamagata",
    stateShort: "06",
    lat: "38.53705640",
    lng: "140.14351980"
    },
    {
    state: "Yamaguchi",
    stateShort: "35",
    lat: "34.27967690",
    lng: "131.52127420"
    },
    {
    state: "Yamanashi",
    stateShort: "19",
    lat: "35.66351130",
    lng: "138.63888790"
    }
    ]
    },
 
    {
    country: "Jordan",
    countryShort: "JO",
    currency_symbol: "ا.د",
    states: [
    {
    state: "Ajloun",
    stateShort: "AJ",
    lat: "32.33255840",
    lng: "35.75168440"
    },
    {
    state: "Amman",
    stateShort: "AM",
    lat: "31.94536330",
    lng: "35.92838950"
    },
    {
    state: "Aqaba",
    stateShort: "AQ",
    lat: "29.53208600",
    lng: "35.00628210"
    },
    {
    state: "Balqa",
    stateShort: "BA",
    lat: "32.03668060",
    lng: "35.72884800"
    },
    {
    state: "Irbid",
    stateShort: "IR",
    lat: "32.55696360",
    lng: "35.84789650"
    },
    {
    state: "Jerash",
    stateShort: "JA",
    lat: "32.27472370",
    lng: "35.89609540"
    },
    {
    state: "Karak",
    stateShort: "KA",
    lat: "31.18535270",
    lng: "35.70476820"
    },
    {
    state: "Ma'an",
    stateShort: "MN",
    lat: "30.19267890",
    lng: "35.72493190"
    },
    {
    state: "Madaba",
    stateShort: "MD",
    lat: "31.71960970",
    lng: "35.79327540"
    },
    {
    state: "Mafraq",
    stateShort: "MA",
    lat: "32.34169230",
    lng: "36.20201750"
    },
    {
    state: "Tafilah",
    stateShort: "AT",
    lat: "30.83380630",
    lng: "35.61605130"
    },
    {
    state: "Zarqa",
    stateShort: "AZ",
    lat: "32.06085050",
    lng: "36.09421210"
    }
    ]
    },
    {
    country: "Kazakhstan",
    countryShort: "KZ",
    currency_symbol: "лв",
    states: [
    {
    state: "Akmola Region",
    stateShort: "AKM",
    lat: "51.91653200",
    lng: "69.41104940"
    },
    {
    state: "Aktobe Region",
    stateShort: "AKT",
    lat: "48.77970780",
    lng: "57.99743780"
    },
    {
    state: "Almaty",
    stateShort: "ALA",
    lat: "43.22201460",
    lng: "76.85124850"
    },
    {
    state: "Almaty Region",
    stateShort: "ALM",
    lat: "45.01192270",
    lng: "78.42293920"
    },
    {
    state: "Atyrau Region",
    stateShort: "ATY",
    lat: "47.10761880",
    lng: "51.91413300"
    },
    {
    state: "Baikonur",
    stateShort: "BAY",
    lat: "45.96458510",
    lng: "63.30524280"
    },
    {
    state: "East Kazakhstan Region",
    stateShort: "VOS",
    lat: "48.70626870",
    lng: "80.79225340"
    },
    {
    state: "Jambyl Region",
    stateShort: "ZHA",
    lat: "44.22203080",
    lng: "72.36579670"
    },
    {
    state: "Karaganda Region",
    stateShort: "KAR",
    lat: "47.90221820",
    lng: "71.77068070"
    },
    {
    state: "Kostanay Region",
    stateShort: "KUS",
    lat: "51.50770960",
    lng: "64.04790730"
    },
    {
    state: "Kyzylorda Region",
    stateShort: "KZY",
    lat: "44.69226130",
    lng: "62.65718850"
    },
    {
    state: "Mangystau Region",
    stateShort: "MAN",
    lat: "44.59080200",
    lng: "53.84995080"
    },
    {
    state: "North Kazakhstan Region",
    stateShort: "SEV",
    lat: "54.16220660",
    lng: "69.93870710"
    },
    {
    state: "Nur-Sultan",
    stateShort: "AST",
    lat: "51.16052270",
    lng: "71.47035580"
    },
    {
    state: "Pavlodar Region",
    stateShort: "PAV",
    lat: "52.28784440",
    lng: "76.97334530"
    },
    {
    state: "Turkestan Region",
    stateShort: "YUZ",
    lat: "43.36669580",
    lng: "68.40944050"
    },
    {
    state: "West Kazakhstan Province",
    stateShort: "ZAP",
    lat: "49.56797270",
    lng: "50.80666160"
    }
    ]
    },
    {
    country: "Kenya",
    countryShort: "KE",
    currency_symbol: "KSh",
    states: [
    {
    state: "Baringo",
    stateShort: "01",
    lat: "0.85549880",
    lng: "36.08934060"
    },
    {
    state: "Bomet",
    stateShort: "02",
    lat: "-0.80150090",
    lng: "35.30272260"
    },
    {
    state: "Bungoma",
    stateShort: "03",
    lat: "0.56952520",
    lng: "34.55837660"
    },
    {
    state: "Busia",
    stateShort: "04",
    lat: "0.43465060",
    lng: "34.24215970"
    },
    {
    state: "Elgeyo-Marakwet",
    stateShort: "05",
    lat: "1.04982370",
    lng: "35.47819260"
    },
    {
    state: "Embu",
    stateShort: "06",
    lat: "-0.65604770",
    lng: "37.72376780"
    },
    {
    state: "Garissa",
    stateShort: "07",
    lat: "-0.45322930",
    lng: "39.64609880"
    },
    {
    state: "Homa Bay",
    stateShort: "08",
    lat: "-0.62206550",
    lng: "34.33103640"
    },
    {
    state: "Isiolo",
    stateShort: "09",
    lat: "0.35243520",
    lng: "38.48499230"
    },
    {
    state: "Kajiado",
    stateShort: "10",
    lat: "-2.09807510",
    lng: "36.78195050"
    },
    {
    state: "Kakamega",
    stateShort: "11",
    lat: "0.30789400",
    lng: "34.77407930"
    },
    {
    state: "Kericho",
    stateShort: "12",
    lat: "-0.18279130",
    lng: "35.47819260"
    },
    {
    state: "Kiambu",
    stateShort: "13",
    lat: "-1.03137010",
    lng: "36.86807910"
    },
    {
    state: "Kilifi",
    stateShort: "14",
    lat: "-3.51065080",
    lng: "39.90932690"
    },
    {
    state: "Kirinyaga",
    stateShort: "15",
    lat: "-0.65905650",
    lng: "37.38272340"
    },
    {
    state: "Kisii",
    stateShort: "16",
    lat: "-0.67733400",
    lng: "34.77960300"
    },
    {
    state: "Kisumu",
    stateShort: "17",
    lat: "-0.09170160",
    lng: "34.76795680"
    },
    {
    state: "Kitui",
    stateShort: "18",
    lat: "-1.68328220",
    lng: "38.31657250"
    },
    {
    state: "Kwale",
    stateShort: "19",
    lat: "-4.18161150",
    lng: "39.46056120"
    },
    {
    state: "Laikipia",
    stateShort: "20",
    lat: "0.36060630",
    lng: "36.78195050"
    },
    {
    state: "Lamu",
    stateShort: "21",
    lat: "-2.23550580",
    lng: "40.47200040"
    },
    {
    state: "Machakos",
    stateShort: "22",
    lat: "-1.51768370",
    lng: "37.26341460"
    },
    {
    state: "Makueni",
    stateShort: "23",
    lat: "-2.25587340",
    lng: "37.89366630"
    },
    {
    state: "Mandera",
    stateShort: "24",
    lat: "3.57379910",
    lng: "40.95868800"
    },
    {
    state: "Marsabit",
    stateShort: "25",
    lat: "2.44264030",
    lng: "37.97845850"
    },
    {
    state: "Meru",
    stateShort: "26",
    lat: "0.35571740",
    lng: "37.80876930"
    },
    {
    state: "Migori",
    stateShort: "27",
    lat: "-0.93657020",
    lng: "34.41982430"
    },
    {
    state: "Mombasa",
    stateShort: "28",
    lat: "-3.97682910",
    lng: "39.71371810"
    },
    {
    state: "Murang'a",
    stateShort: "29",
    lat: "-0.78392810",
    lng: "37.04003390"
    },
    {
    state: "Nairobi City",
    stateShort: "30",
    lat: "-1.29206590",
    lng: "36.82194620"
    },
    {
    state: "Nakuru",
    stateShort: "31",
    lat: "-0.30309880",
    lng: "36.08002600"
    },
    {
    state: "Nandi",
    stateShort: "32",
    lat: "0.18358670",
    lng: "35.12687810"
    },
    {
    state: "Narok",
    stateShort: "33",
    lat: "-1.10411100",
    lng: "36.08934060"
    },
    {
    state: "Nyamira",
    stateShort: "34",
    lat: "-0.56694050",
    lng: "34.93412340"
    },
    {
    state: "Nyandarua",
    stateShort: "35",
    lat: "-0.18038550",
    lng: "36.52296410"
    },
    {
    state: "Nyeri",
    stateShort: "36",
    lat: "-0.41969150",
    lng: "37.04003390"
    },
    {
    state: "Samburu",
    stateShort: "37",
    lat: "1.21545060",
    lng: "36.95410700"
    },
    {
    state: "Siaya",
    stateShort: "38",
    lat: "-0.06173280",
    lng: "34.24215970"
    },
    {
    state: "Taita–Taveta",
    stateShort: "39",
    lat: "-3.31606870",
    lng: "38.48499230"
    },
    {
    state: "Tana River",
    stateShort: "40",
    lat: "-1.65184680",
    lng: "39.65181650"
    },
    {
    state: "Tharaka-Nithi",
    stateShort: "41",
    lat: "-0.29648510",
    lng: "37.72376780"
    },
    {
    state: "Trans Nzoia",
    stateShort: "42",
    lat: "1.05666670",
    lng: "34.95066250"
    },
    {
    state: "Turkana",
    stateShort: "43",
    lat: "3.31224770",
    lng: "35.56578620"
    },
    {
    state: "Uasin Gishu",
    stateShort: "44",
    lat: "0.55276380",
    lng: "35.30272260"
    },
    {
    state: "Vihiga",
    stateShort: "45",
    lat: "0.07675530",
    lng: "34.70776650"
    },
    {
    state: "Wajir",
    stateShort: "46",
    lat: "1.63604750",
    lng: "40.30886260"
    },
    {
    state: "West Pokot",
    stateShort: "47",
    lat: "1.62100760",
    lng: "35.39050460"
    }
    ]
    },
    {
    country: "Kiribati",
    countryShort: "KI",
    currency_symbol: "$",
    states: [
    {
    state: "Gilbert Islands",
    stateShort: "G",
    lat: "0.35242620",
    lng: "174.75526340"
    },
    {
    state: "Line Islands",
    stateShort: "L",
    lat: "1.74294390",
    lng: "-157.21328260"
    },
    {
    state: "Phoenix Islands",
    stateShort: "P",
    lat: "33.32843690",
    lng: "-111.98247740"
    }
    ]
    },
    {
    country: "Kosovo",
    countryShort: "XK",
    currency_symbol: "€",
    states: [
    {
    state: "Đakovica District (Gjakove)",
    stateShort: "XDG",
    lat: "42.43757560",
    lng: "20.37854380"
    },
    {
    state: "Gjilan District",
    stateShort: "XGJ",
    lat: "42.46352060",
    lng: "21.46940110"
    },
    {
    state: "Kosovska Mitrovica District",
    stateShort: "XKM",
    lat: "42.89139090",
    lng: "20.86599950"
    },
    {
    state: "Peć District",
    stateShort: "XPE",
    lat: "42.65921550",
    lng: "20.28876240"
    },
    {
    state: "Pristina (Priştine)",
    stateShort: "XPI",
    lat: "42.66291380",
    lng: "21.16550280"
    },
    {
    state: "Prizren District",
    stateShort: "XPR",
    lat: "42.21525220",
    lng: "20.74147720"
    },
    {
    state: "Uroševac District (Ferizaj)",
    stateShort: "XUF",
    lat: "42.37018440",
    lng: "21.14832810"
    }
    ]
    },
    {
    country: "Kuwait",
    countryShort: "KW",
    currency_symbol: "ك.د",
    states: [
    {
    state: "Al Ahmadi",
    stateShort: "AH",
    lat: "28.57451250",
    lng: "48.10247430"
    },
    {
    state: "Al Farwaniyah",
    stateShort: "FA",
    lat: "29.27335700",
    lng: "47.94001540"
    },
    {
    state: "Al Jahra",
    stateShort: "JA",
    lat: "29.99318310",
    lng: "47.76347310"
    },
    {
    state: "Capital",
    stateShort: "KU",
    lat: "26.22851610",
    lng: "50.58604970"
    },
    {
    state: "Hawalli",
    stateShort: "HA",
    lat: "29.30567160",
    lng: "48.03076130"
    },
    {
    state: "Mubarak Al-Kabeer",
    stateShort: "MU",
    lat: "29.21224000",
    lng: "48.06051080"
    }
    ]
    },
    {
    country: "Kyrgyzstan",
    countryShort: "KG",
    currency_symbol: "лв",
    states: [
    {
    state: "Batken Region",
    stateShort: "B",
    lat: "39.97214250",
    lng: "69.85974060"
    },
    {
    state: "Bishkek",
    stateShort: "GB",
    lat: "42.87462120",
    lng: "74.56976170"
    },
    {
    state: "Chuy Region",
    stateShort: "C",
    lat: "42.56550000",
    lng: "74.40566120"
    },
    {
    state: "Issyk-Kul Region",
    stateShort: "Y",
    lat: "42.18594280",
    lng: "77.56194190"
    },
    {
    state: "Jalal-Abad Region",
    stateShort: "J",
    lat: "41.10680800",
    lng: "72.89880690"
    },
    {
    state: "Naryn Region",
    stateShort: "N",
    lat: "41.29432270",
    lng: "75.34121790"
    },
    {
    state: "Osh",
    stateShort: "GO",
    lat: "36.06313990",
    lng: "-95.91828950"
    },
    {
    state: "Osh Region",
    stateShort: "O",
    lat: "39.84073660",
    lng: "72.89880690"
    },
    {
    state: "Talas Region",
    stateShort: "T",
    lat: "42.28673390",
    lng: "72.52048270"
    }
    ]
    },
    {
    country: "Laos",
    countryShort: "LA",
    currency_symbol: "₭",
    states: [
    {
    state: "Attapeu Province",
    stateShort: "AT",
    lat: "14.93634000",
    lng: "107.10119310"
    },
    {
    state: "Bokeo Province",
    stateShort: "BK",
    lat: "20.28726620",
    lng: "100.70978670"
    },
    {
    state: "Bolikhamsai Province",
    stateShort: "BL",
    lat: "18.43629240",
    lng: "104.47233010"
    },
    {
    state: "Champasak Province",
    stateShort: "CH",
    lat: "14.65786640",
    lng: "105.96998780"
    },
    {
    state: "Houaphanh Province",
    stateShort: "HO",
    lat: "20.32541750",
    lng: "104.10013260"
    },
    {
    state: "Khammouane Province",
    stateShort: "KH",
    lat: "17.63840660",
    lng: "105.21948080"
    },
    {
    state: "Luang Namtha Province",
    stateShort: "LM",
    lat: "20.91701870",
    lng: "101.16173560"
    },
    {
    state: "Luang Prabang Province",
    stateShort: "LP",
    lat: "20.06562290",
    lng: "102.62162110"
    },
    {
    state: "Oudomxay Province",
    stateShort: "OU",
    lat: "20.49219290",
    lng: "101.88917210"
    },
    {
    state: "Phongsaly Province",
    stateShort: "PH",
    lat: "21.59193770",
    lng: "102.25479190"
    },
    {
    state: "Sainyabuli Province",
    stateShort: "XA",
    lat: "19.39078860",
    lng: "101.52480550"
    },
    {
    state: "Salavan Province",
    stateShort: "SL",
    lat: "15.81710730",
    lng: "106.25221430"
    },
    {
    state: "Savannakhet Province",
    stateShort: "SV",
    lat: "16.50653810",
    lng: "105.59433880"
    },
    {
    state: "Sekong Province",
    stateShort: "XE",
    lat: "15.57674460",
    lng: "107.00670310"
    },
    {
    state: "Vientiane Prefecture",
    stateShort: "VT",
    lat: "18.11054100",
    lng: "102.52980280"
    },
    {
    state: "Vientiane Province",
    stateShort: "VI",
    lat: "18.57050630",
    lng: "102.62162110"
    },
    {
    state: "Xaisomboun",
    stateShort: "XN",
    lat: null,
    lng: null
    },
    {
    state: "Xaisomboun Province",
    stateShort: "XS",
    lat: "18.43629240",
    lng: "104.47233010"
    },
    {
    state: "Xiangkhouang Province",
    stateShort: "XI",
    lat: "19.60930030",
    lng: "103.72891670"
    }
    ]
    },
    {
    country: "Latvia",
    countryShort: "LV",
    currency_symbol: "€",
    states: [
    {
    state: "Aglona Municipality",
    stateShort: "001",
    lat: "56.10890060",
    lng: "27.12862270"
    },
    {
    state: "Aizkraukle Municipality",
    stateShort: "002",
    lat: "56.64610800",
    lng: "25.23708540"
    },
    {
    state: "Aizpute Municipality",
    stateShort: "003",
    lat: "56.71825960",
    lng: "21.60727590"
    },
    {
    state: "Aknīste Municipality",
    stateShort: "004",
    lat: "56.16130370",
    lng: "25.74848270"
    },
    {
    state: "Aloja Municipality",
    stateShort: "005",
    lat: "57.76713600",
    lng: "24.87708390"
    },
    {
    state: "Alsunga Municipality",
    stateShort: "006",
    lat: "56.98285310",
    lng: "21.55559190"
    },
    {
    state: "Alūksne Municipality",
    stateShort: "007",
    lat: "57.42544850",
    lng: "27.04249680"
    },
    {
    state: "Amata Municipality",
    stateShort: "008",
    lat: "56.99387260",
    lng: "25.26276750"
    },
    {
    state: "Ape Municipality",
    stateShort: "009",
    lat: "57.53926970",
    lng: "26.69416490"
    },
    {
    state: "Auce Municipality",
    stateShort: "010",
    lat: "56.46016800",
    lng: "22.90547810"
    },
    {
    state: "Babīte Municipality",
    stateShort: "012",
    lat: "56.95415500",
    lng: "23.94539900"
    },
    {
    state: "Baldone Municipality",
    stateShort: "013",
    lat: "56.74246000",
    lng: "24.39115440"
    },
    {
    state: "Baltinava Municipality",
    stateShort: "014",
    lat: "56.94584680",
    lng: "27.64410660"
    },
    {
    state: "Balvi Municipality",
    stateShort: "015",
    lat: "57.13262400",
    lng: "27.26466850"
    },
    {
    state: "Bauska Municipality",
    stateShort: "016",
    lat: "56.41018680",
    lng: "24.20006890"
    },
    {
    state: "Beverīna Municipality",
    stateShort: "017",
    lat: "57.51971090",
    lng: "25.60736540"
    },
    {
    state: "Brocēni Municipality",
    stateShort: "018",
    lat: "56.73475410",
    lng: "22.63573710"
    },
    {
    state: "Burtnieki Municipality",
    stateShort: "019",
    lat: "57.69490040",
    lng: "25.27647770"
    },
    {
    state: "Carnikava Municipality",
    stateShort: "020",
    lat: "57.10241210",
    lng: "24.21086620"
    },
    {
    state: "Cēsis Municipality",
    stateShort: "022",
    lat: "57.31028970",
    lng: "25.26761250"
    },
    {
    state: "Cesvaine Municipality",
    stateShort: "021",
    lat: "56.96792640",
    lng: "26.30831720"
    },
    {
    state: "Cibla Municipality",
    stateShort: "023",
    lat: "56.61023440",
    lng: "27.86965980"
    },
    {
    state: "Dagda Municipality",
    stateShort: "024",
    lat: "56.09560890",
    lng: "27.53245900"
    },
    {
    state: "Daugavpils",
    stateShort: "DGV",
    lat: "55.87473600",
    lng: "26.53617900"
    },
    {
    state: "Daugavpils Municipality",
    stateShort: "025",
    lat: "55.89917830",
    lng: "26.61020120"
    },
    {
    state: "Dobele Municipality",
    stateShort: "026",
    lat: "56.62630500",
    lng: "23.28090660"
    },
    {
    state: "Dundaga Municipality",
    stateShort: "027",
    lat: "57.50491670",
    lng: "22.35051140"
    },
    {
    state: "Durbe Municipality",
    stateShort: "028",
    lat: "56.62798570",
    lng: "21.49162450"
    },
    {
    state: "Engure Municipality",
    stateShort: "029",
    lat: "57.16235000",
    lng: "23.21966340"
    },
    {
    state: "Ērgļi Municipality",
    stateShort: "030",
    lat: "56.92370650",
    lng: "25.67538520"
    },
    {
    state: "Garkalne Municipality",
    stateShort: "031",
    lat: "57.01903870",
    lng: "24.38261810"
    },
    {
    state: "Grobiņa Municipality",
    stateShort: "032",
    lat: "56.53963200",
    lng: "21.16689200"
    },
    {
    state: "Gulbene Municipality",
    stateShort: "033",
    lat: "57.21556450",
    lng: "26.64529550"
    },
    {
    state: "Iecava Municipality",
    stateShort: "034",
    lat: "56.59867930",
    lng: "24.19962720"
    },
    {
    state: "Ikšķile Municipality",
    stateShort: "035",
    lat: "56.83736670",
    lng: "24.49747450"
    },
    {
    state: "Ilūkste Municipality",
    stateShort: "036",
    lat: "55.97825470",
    lng: "26.29650880"
    },
    {
    state: "Inčukalns Municipality",
    stateShort: "037",
    lat: "57.09943420",
    lng: "24.68555700"
    },
    {
    state: "Jaunjelgava Municipality",
    stateShort: "038",
    lat: "56.52836590",
    lng: "25.39214430"
    },
    {
    state: "Jaunpiebalga Municipality",
    stateShort: "039",
    lat: "57.14334710",
    lng: "25.99518880"
    },
    {
    state: "Jaunpils Municipality",
    stateShort: "040",
    lat: "56.73141940",
    lng: "23.01256160"
    },
    {
    state: "Jēkabpils",
    stateShort: "JKB",
    lat: "56.50145500",
    lng: "25.87829900"
    },
    {
    state: "Jēkabpils Municipality",
    stateShort: "042",
    lat: "56.29193200",
    lng: "25.98120170"
    },
    {
    state: "Jelgava",
    stateShort: "JEL",
    lat: "56.65110910",
    lng: "23.72135410"
    },
    {
    state: "Jelgava Municipality",
    stateShort: "041",
    lat: "56.58956890",
    lng: "23.66104810"
    },
    {
    state: "Jūrmala",
    stateShort: "JUR",
    lat: "56.94707900",
    lng: "23.61684850"
    },
    {
    state: "Kandava Municipality",
    stateShort: "043",
    lat: "57.03406730",
    lng: "22.78018130"
    },
    {
    state: "Kārsava Municipality",
    stateShort: "044",
    lat: "56.76458420",
    lng: "27.73582950"
    },
    {
    state: "Ķegums Municipality",
    stateShort: "051",
    lat: "56.74753570",
    lng: "24.71736450"
    },
    {
    state: "Ķekava Municipality",
    stateShort: "052",
    lat: "56.80643510",
    lng: "24.19394930"
    },
    {
    state: "Kocēni Municipality",
    stateShort: "045",
    lat: "57.52262920",
    lng: "25.33495070"
    },
    {
    state: "Koknese Municipality",
    stateShort: "046",
    lat: "56.72055600",
    lng: "25.48939090"
    },
    {
    state: "Krāslava Municipality",
    stateShort: "047",
    lat: "55.89514640",
    lng: "27.18145770"
    },
    {
    state: "Krimulda Municipality",
    stateShort: "048",
    lat: "57.17912730",
    lng: "24.71401270"
    },
    {
    state: "Krustpils Municipality",
    stateShort: "049",
    lat: "56.54155780",
    lng: "26.24463970"
    },
    {
    state: "Kuldīga Municipality",
    stateShort: "050",
    lat: "56.96872570",
    lng: "21.96137390"
    },
    {
    state: "Lielvārde Municipality",
    stateShort: "053",
    lat: "56.73929760",
    lng: "24.97116180"
    },
    {
    state: "Liepāja",
    stateShort: "LPX",
    lat: "56.50466780",
    lng: "21.01080600"
    },
    {
    state: "Līgatne Municipality",
    stateShort: "055",
    lat: "57.19442040",
    lng: "25.09406810"
    },
    {
    state: "Limbaži Municipality",
    stateShort: "054",
    lat: "57.54032270",
    lng: "24.71344510"
    },
    {
    state: "Līvāni Municipality",
    stateShort: "056",
    lat: "56.35509420",
    lng: "26.17251900"
    },
    {
    state: "Lubāna Municipality",
    stateShort: "057",
    lat: "56.89992690",
    lng: "26.71987890"
    },
    {
    state: "Ludza Municipality",
    stateShort: "058",
    lat: "56.54595900",
    lng: "27.71431990"
    },
    {
    state: "Madona Municipality",
    stateShort: "059",
    lat: "56.85989230",
    lng: "26.22762010"
    },
    {
    state: "Mālpils Municipality",
    stateShort: "061",
    lat: "57.00841190",
    lng: "24.95742780"
    },
    {
    state: "Mārupe Municipality",
    stateShort: "062",
    lat: "56.89657170",
    lng: "24.04600490"
    },
    {
    state: "Mazsalaca Municipality",
    stateShort: "060",
    lat: "57.92677490",
    lng: "25.06698950"
    },
    {
    state: "Mērsrags Municipality",
    stateShort: "063",
    lat: "57.33068810",
    lng: "23.10237070"
    },
    {
    state: "Naukšēni Municipality",
    stateShort: "064",
    lat: "57.92953610",
    lng: "25.51192660"
    },
    {
    state: "Nereta Municipality",
    stateShort: "065",
    lat: "56.19866550",
    lng: "25.32529690"
    },
    {
    state: "Nīca Municipality",
    stateShort: "066",
    lat: "56.34649830",
    lng: "21.06549300"
    },
    {
    state: "Ogre Municipality",
    stateShort: "067",
    lat: "56.81473550",
    lng: "24.60445550"
    },
    {
    state: "Olaine Municipality",
    stateShort: "068",
    lat: "56.79523530",
    lng: "24.01535890"
    },
    {
    state: "Ozolnieki Municipality",
    stateShort: "069",
    lat: "56.67563050",
    lng: "23.89948160"
    },
    {
    state: "Pārgauja Municipality",
    stateShort: "070",
    lat: "57.36481220",
    lng: "24.98220450"
    },
    {
    state: "Pāvilosta Municipality",
    stateShort: "071",
    lat: "56.88654240",
    lng: "21.19468490"
    },
    {
    state: "Pļaviņas Municipality",
    stateShort: "072",
    lat: "56.61773130",
    lng: "25.71940430"
    },
    {
    state: "Preiļi Municipality",
    stateShort: "073",
    lat: "56.15111570",
    lng: "26.74397670"
    },
    {
    state: "Priekule Municipality",
    stateShort: "074",
    lat: "56.41794130",
    lng: "21.55033360"
    },
    {
    state: "Priekuļi Municipality",
    stateShort: "075",
    lat: "57.36171380",
    lng: "25.44104230"
    },
    {
    state: "Rauna Municipality",
    stateShort: "076",
    lat: "57.33169300",
    lng: "25.61003390"
    },
    {
    state: "Rēzekne",
    stateShort: "REZ",
    lat: "56.50992230",
    lng: "27.33313570"
    },
    {
    state: "Rēzekne Municipality",
    stateShort: "077",
    lat: "56.32736380",
    lng: "27.32843310"
    },
    {
    state: "Riebiņi Municipality",
    stateShort: "078",
    lat: "56.34361900",
    lng: "26.80181380"
    },
    {
    state: "Riga",
    stateShort: "RIX",
    lat: "56.94964870",
    lng: "24.10518650"
    },
    {
    state: "Roja Municipality",
    stateShort: "079",
    lat: "57.50467130",
    lng: "22.80121640"
    },
    {
    state: "Ropaži Municipality",
    stateShort: "080",
    lat: "56.96157860",
    lng: "24.60104760"
    },
    {
    state: "Rucava Municipality",
    stateShort: "081",
    lat: "56.15931240",
    lng: "21.16181210"
    },
    {
    state: "Rugāji Municipality",
    stateShort: "082",
    lat: "57.00560230",
    lng: "27.13172030"
    },
    {
    state: "Rūjiena Municipality",
    stateShort: "084",
    lat: "57.89372910",
    lng: "25.33910080"
    },
    {
    state: "Rundāle Municipality",
    stateShort: "083",
    lat: "56.40972100",
    lng: "24.01241390"
    },
    {
    state: "Sala Municipality",
    stateShort: "085",
    lat: "59.96796130",
    lng: "16.49782170"
    },
    {
    state: "Salacgrīva Municipality",
    stateShort: "086",
    lat: "57.75808830",
    lng: "24.35431810"
    },
    {
    state: "Salaspils Municipality",
    stateShort: "087",
    lat: "56.86081520",
    lng: "24.34978810"
    },
    {
    state: "Saldus Municipality",
    stateShort: "088",
    lat: "56.66650880",
    lng: "22.49354930"
    },
    {
    state: "Saulkrasti Municipality",
    stateShort: "089",
    lat: "57.25794180",
    lng: "24.41831460"
    },
    {
    state: "Sēja Municipality",
    stateShort: "090",
    lat: "57.20069950",
    lng: "24.59228210"
    },
    {
    state: "Sigulda Municipality",
    stateShort: "091",
    lat: "57.10550920",
    lng: "24.83142590"
    },
    {
    state: "Skrīveri Municipality",
    stateShort: "092",
    lat: "56.67613910",
    lng: "25.09788490"
    },
    {
    state: "Skrunda Municipality",
    stateShort: "093",
    lat: "56.66434580",
    lng: "22.00457290"
    },
    {
    state: "Smiltene Municipality",
    stateShort: "094",
    lat: "57.42303320",
    lng: "25.90027800"
    },
    {
    state: "Stopiņi Municipality",
    stateShort: "095",
    lat: "56.93644900",
    lng: "24.28729490"
    },
    {
    state: "Strenči Municipality",
    stateShort: "096",
    lat: "57.62254710",
    lng: "25.80480860"
    },
    {
    state: "Talsi Municipality",
    stateShort: "097",
    lat: "57.34152080",
    lng: "22.57131250"
    },
    {
    state: "Tērvete Municipality",
    stateShort: "098",
    lat: "56.41192010",
    lng: "23.31883320"
    },
    {
    state: "Tukums Municipality",
    stateShort: "099",
    lat: "56.96728680",
    lng: "23.15243790"
    },
    {
    state: "Vaiņode Municipality",
    stateShort: "100",
    lat: "56.41542710",
    lng: "21.85139840"
    },
    {
    state: "Valka Municipality",
    stateShort: "101",
    lat: "57.77439000",
    lng: "26.01700500"
    },
    {
    state: "Valmiera",
    stateShort: "VMR",
    lat: "57.53846590",
    lng: "25.42636180"
    },
    {
    state: "Varakļāni Municipality",
    stateShort: "102",
    lat: "56.66880060",
    lng: "26.56364140"
    },
    {
    state: "Vārkava Municipality",
    stateShort: "103",
    lat: "56.24657440",
    lng: "26.56643710"
    },
    {
    state: "Vecpiebalga Municipality",
    stateShort: "104",
    lat: "57.06033560",
    lng: "25.81615920"
    },
    {
    state: "Vecumnieki Municipality",
    stateShort: "105",
    lat: "56.60623370",
    lng: "24.52218910"
    },
    {
    state: "Ventspils",
    stateShort: "VEN",
    lat: "57.39372160",
    lng: "21.56470660"
    },
    {
    state: "Ventspils Municipality",
    stateShort: "106",
    lat: "57.28336820",
    lng: "21.85875580"
    },
    {
    state: "Viesīte Municipality",
    stateShort: "107",
    lat: "56.31130850",
    lng: "25.50704640"
    },
    {
    state: "Viļaka Municipality",
    stateShort: "108",
    lat: "57.17222630",
    lng: "27.66731880"
    },
    {
    state: "Viļāni Municipality",
    stateShort: "109",
    lat: "56.54561710",
    lng: "26.91679270"
    },
    {
    state: "Zilupe Municipality",
    stateShort: "110",
    lat: "56.30189850",
    lng: "28.13395900"
    }
    ]
    },
    {
    country: "Lebanon",
    countryShort: "LB",
    currency_symbol: "£",
    states: [
    {
    state: "Akkar",
    stateShort: "AK",
    lat: "34.53287630",
    lng: "36.13281320"
    },
    {
    state: "Baalbek-Hermel",
    stateShort: "BH",
    lat: "34.26585560",
    lng: "36.34980970"
    },
    {
    state: "Beirut",
    stateShort: "BA",
    lat: "33.88861060",
    lng: "35.49547720"
    },
    {
    state: "Beqaa",
    stateShort: "BI",
    lat: "33.84626620",
    lng: "35.90194890"
    },
    {
    state: "Mount Lebanon",
    stateShort: "JL",
    lat: "33.81008580",
    lng: "35.59731390"
    },
    {
    state: "Nabatieh",
    stateShort: "NA",
    lat: "33.37716930",
    lng: "35.48382930"
    },
    {
    state: "North",
    stateShort: "AS",
    lat: "34.43806250",
    lng: "35.83082330"
    },
    {
    state: "South",
    stateShort: "JA",
    lat: "33.27214790",
    lng: "35.20327780"
    }
    ]
    },
    {
    country: "Lesotho",
    countryShort: "LS",
    currency_symbol: "L",
    states: [
    {
    state: "Berea District",
    stateShort: "D",
    lat: "41.36616140",
    lng: "-81.85430260"
    },
    {
    state: "Butha-Buthe District",
    stateShort: "B",
    lat: "-28.76537540",
    lng: "28.24681480"
    },
    {
    state: "Leribe District",
    stateShort: "C",
    lat: "-28.86380650",
    lng: "28.04788260"
    },
    {
    state: "Mafeteng District",
    stateShort: "E",
    lat: "-29.80410080",
    lng: "27.50261740"
    },
    {
    state: "Maseru District",
    stateShort: "A",
    lat: "-29.51656500",
    lng: "27.83114280"
    },
    {
    state: "Mohale's Hoek District",
    stateShort: "F",
    lat: "-30.14259170",
    lng: "27.46738450"
    },
    {
    state: "Mokhotlong District",
    stateShort: "J",
    lat: "-29.25731930",
    lng: "28.95286450"
    },
    {
    state: "Qacha's Nek District",
    stateShort: "H",
    lat: "-30.11145650",
    lng: "28.67897900"
    },
    {
    state: "Quthing District",
    stateShort: "G",
    lat: "-30.40156870",
    lng: "27.70801330"
    },
    {
    state: "Thaba-Tseka District",
    stateShort: "K",
    lat: "-29.52389750",
    lng: "28.60897520"
    }
    ]
    },
    {
    country: "Liberia",
    countryShort: "LR",
    currency_symbol: "$",
    states: [
    {
    state: "Bomi County",
    stateShort: "BM",
    lat: "6.75629260",
    lng: "-10.84514670"
    },
    {
    state: "Bong County",
    stateShort: "BG",
    lat: "6.82950190",
    lng: "-9.36730840"
    },
    {
    state: "Gbarpolu County",
    stateShort: "GP",
    lat: "7.49526370",
    lng: "-10.08072980"
    },
    {
    state: "Grand Bassa County",
    stateShort: "GB",
    lat: "6.23084520",
    lng: "-9.81249350"
    },
    {
    state: "Grand Cape Mount County",
    stateShort: "CM",
    lat: "7.04677580",
    lng: "-11.07117580"
    },
    {
    state: "Grand Gedeh County",
    stateShort: "GG",
    lat: "5.92220780",
    lng: "-8.22129790"
    },
    {
    state: "Grand Kru County",
    stateShort: "GK",
    lat: "4.76138620",
    lng: "-8.22129790"
    },
    {
    state: "Lofa County",
    stateShort: "LO",
    lat: "8.19111840",
    lng: "-9.72326730"
    },
    {
    state: "Margibi County",
    stateShort: "MG",
    lat: "6.51518750",
    lng: "-10.30488970"
    },
    {
    state: "Maryland County",
    stateShort: "MY",
    lat: "39.04575490",
    lng: "-76.64127120"
    },
    {
    state: "Montserrado County",
    stateShort: "MO",
    lat: "6.55258150",
    lng: "-10.52961150"
    },
    {
    state: "Nimba",
    stateShort: "NI",
    lat: "7.61666670",
    lng: "-8.41666670"
    },
    {
    state: "River Cess County",
    stateShort: "RI",
    lat: "5.90253280",
    lng: "-9.45615500"
    },
    {
    state: "River Gee County",
    stateShort: "RG",
    lat: "5.26048940",
    lng: "-7.87216000"
    },
    {
    state: "Sinoe County",
    stateShort: "SI",
    lat: "5.49871000",
    lng: "-8.66005860"
    }
    ]
    },
    {
    country: "Libya",
    countryShort: "LY",
    currency_symbol: "د.ل",
    states: [
    {
    state: "Al Wahat District",
    stateShort: "WA",
    lat: "29.04668080",
    lng: "21.85685860"
    },
    {
    state: "Benghazi",
    stateShort: "BA",
    lat: "32.11942420",
    lng: "20.08679090"
    },
    {
    state: "Derna District",
    stateShort: "DR",
    lat: "32.75561300",
    lng: "22.63774320"
    },
    {
    state: "Ghat District",
    stateShort: "GT",
    lat: "24.96403710",
    lng: "10.17592850"
    },
    {
    state: "Jabal al Akhdar",
    stateShort: "JA",
    lat: "23.18560810",
    lng: "57.37138790"
    },
    {
    state: "Jabal al Gharbi District",
    stateShort: "JG",
    lat: "30.26380320",
    lng: "12.80547530"
    },
    {
    state: "Jafara",
    stateShort: "JI",
    lat: "32.45259040",
    lng: "12.94355360"
    },
    {
    state: "Jufra",
    stateShort: "JU",
    lat: "27.98351350",
    lng: "16.91225100"
    },
    {
    state: "Kufra District",
    stateShort: "KF",
    lat: "23.31123890",
    lng: "21.85685860"
    },
    {
    state: "Marj District",
    stateShort: "MJ",
    lat: "32.05503630",
    lng: "21.18911510"
    },
    {
    state: "Misrata District",
    stateShort: "MI",
    lat: "32.32558840",
    lng: "15.09925560"
    },
    {
    state: "Murqub",
    stateShort: "MB",
    lat: "32.45996770",
    lng: "14.10013260"
    },
    {
    state: "Murzuq District",
    stateShort: "MQ",
    lat: "25.91822620",
    lng: "13.92600010"
    },
    {
    state: "Nalut District",
    stateShort: "NL",
    lat: "31.87423480",
    lng: "10.97504840"
    },
    {
    state: "Nuqat al Khams",
    stateShort: "NQ",
    lat: "32.69149090",
    lng: "11.88917210"
    },
    {
    state: "Sabha District",
    stateShort: "SB",
    lat: "27.03654060",
    lng: "14.42902360"
    },
    {
    state: "Sirte District",
    stateShort: "SR",
    lat: "31.18968900",
    lng: "16.57019270"
    },
    {
    state: "Tripoli District",
    stateShort: "TB",
    lat: "32.64080210",
    lng: "13.26634790"
    },
    {
    state: "Wadi al Hayaa District",
    stateShort: "WD",
    lat: "26.42259260",
    lng: "12.62162110"
    },
    {
    state: "Wadi al Shatii District",
    stateShort: "WS",
    lat: "27.73514680",
    lng: "12.43805810"
    },
    {
    state: "Zawiya District",
    stateShort: "ZA",
    lat: "32.76302820",
    lng: "12.73649620"
    }
    ]
    },
    {
    country: "Liechtenstein",
    countryShort: "LI",
    currency_symbol: "CHf",
    states: [
    {
    state: "Balzers",
    stateShort: "01",
    lat: "42.05283570",
    lng: "-88.03668480"
    },
    {
    state: "Eschen",
    stateShort: "02",
    lat: "40.76695740",
    lng: "-73.95228210"
    },
    {
    state: "Gamprin",
    stateShort: "03",
    lat: "47.21324900",
    lng: "9.50251950"
    },
    {
    state: "Mauren",
    stateShort: "04",
    lat: "47.21892850",
    lng: "9.54173500"
    },
    {
    state: "Planken",
    stateShort: "05",
    lat: "40.66505760",
    lng: "-73.50479800"
    },
    {
    state: "Ruggell",
    stateShort: "06",
    lat: "47.25292220",
    lng: "9.54021270"
    },
    {
    state: "Schaan",
    stateShort: "07",
    lat: "47.12043400",
    lng: "9.59416020"
    },
    {
    state: "Schellenberg",
    stateShort: "08",
    lat: "47.23096600",
    lng: "9.54678430"
    },
    {
    state: "Triesen",
    stateShort: "09",
    lat: "47.10979880",
    lng: "9.52482960"
    },
    {
    state: "Triesenberg",
    stateShort: "10",
    lat: "47.12245110",
    lng: "9.57019850"
    },
    {
    state: "Vaduz",
    stateShort: "11",
    lat: "47.14103030",
    lng: "9.52092770"
    }
    ]
    },
    {
    country: "Lithuania",
    countryShort: "LT",
    currency_symbol: "€",
    states: [
    {
    state: "Akmenė District Municipality",
    stateShort: "01",
    lat: "56.24550290",
    lng: "22.74711690"
    },
    {
    state: "Alytus City Municipality",
    stateShort: "02",
    lat: "54.39629380",
    lng: "24.04587610"
    },
    {
    state: "Alytus County",
    stateShort: "AL",
    lat: "54.20002140",
    lng: "24.15126340"
    },
    {
    state: "Alytus District Municipality",
    stateShort: "03",
    lat: "54.32974960",
    lng: "24.19609310"
    },
    {
    state: "Birštonas Municipality",
    stateShort: "05",
    lat: "54.56696640",
    lng: "24.00930980"
    },
    {
    state: "Biržai District Municipality",
    stateShort: "06",
    lat: "56.20177190",
    lng: "24.75601180"
    },
    {
    state: "Druskininkai municipality",
    stateShort: "07",
    lat: "53.99336850",
    lng: "24.03424380"
    },
    {
    state: "Elektrėnai municipality",
    stateShort: "08",
    lat: "54.76539340",
    lng: "24.77405830"
    },
    {
    state: "Ignalina District Municipality",
    stateShort: "09",
    lat: "55.40903420",
    lng: "26.32848930"
    },
    {
    state: "Jonava District Municipality",
    stateShort: "10",
    lat: "55.07272420",
    lng: "24.27933370"
    },
    {
    state: "Joniškis District Municipality",
    stateShort: "11",
    lat: "56.23607300",
    lng: "23.61365790"
    },
    {
    state: "Jurbarkas District Municipality",
    stateShort: "12",
    lat: "55.07740700",
    lng: "22.74195690"
    },
    {
    state: "Kaišiadorys District Municipality",
    stateShort: "13",
    lat: "54.85886690",
    lng: "24.42779290"
    },
    {
    state: "Kalvarija municipality",
    stateShort: "14",
    lat: "54.37616740",
    lng: "23.19203210"
    },
    {
    state: "Kaunas City Municipality",
    stateShort: "15",
    lat: "54.91453260",
    lng: "23.90535180"
    },
    {
    state: "Kaunas County",
    stateShort: "KU",
    lat: "54.98728630",
    lng: "23.95257360"
    },
    {
    state: "Kaunas District Municipality",
    stateShort: "16",
    lat: "54.99362360",
    lng: "23.63249410"
    },
    {
    state: "Kazlų Rūda municipality",
    stateShort: "17",
    lat: "54.78075260",
    lng: "23.48402430"
    },
    {
    state: "Kėdainiai District Municipality",
    stateShort: "18",
    lat: "55.35609470",
    lng: "23.98326830"
    },
    {
    state: "Kelmė District Municipality",
    stateShort: "19",
    lat: "55.62663520",
    lng: "22.87817200"
    },
    {
    state: "Klaipeda City Municipality",
    stateShort: "20",
    lat: "55.70329480",
    lng: "21.14427950"
    },
    {
    state: "Klaipėda County",
    stateShort: "KL",
    lat: "55.65197440",
    lng: "21.37439560"
    },
    {
    state: "Klaipėda District Municipality",
    stateShort: "21",
    lat: "55.68416150",
    lng: "21.44164640"
    },
    {
    state: "Kretinga District Municipality",
    stateShort: "22",
    lat: "55.88384200",
    lng: "21.23509190"
    },
    {
    state: "Kupiškis District Municipality",
    stateShort: "23",
    lat: "55.84287410",
    lng: "25.02958160"
    },
    {
    state: "Lazdijai District Municipality",
    stateShort: "24",
    lat: "54.23432670",
    lng: "23.51565050"
    },
    {
    state: "Marijampolė County",
    stateShort: "MR",
    lat: "54.78199710",
    lng: "23.13413650"
    },
    {
    state: "Marijampolė Municipality",
    stateShort: "25",
    lat: "54.57110940",
    lng: "23.48593710"
    },
    {
    state: "Mažeikiai District Municipality",
    stateShort: "26",
    lat: "56.30924390",
    lng: "22.34146800"
    },
    {
    state: "Molėtai District Municipality",
    stateShort: "27",
    lat: "55.22653090",
    lng: "25.41800110"
    },
    {
    state: "Neringa Municipality",
    stateShort: "28",
    lat: "55.45724030",
    lng: "21.08390050"
    },
    {
    state: "Pagėgiai municipality",
    stateShort: "29",
    lat: "55.17213200",
    lng: "21.96836140"
    },
    {
    state: "Pakruojis District Municipality",
    stateShort: "30",
    lat: "56.07326050",
    lng: "23.93899060"
    },
    {
    state: "Palanga City Municipality",
    stateShort: "31",
    lat: "55.92019800",
    lng: "21.06776140"
    },
    {
    state: "Panevėžys City Municipality",
    stateShort: "32",
    lat: "55.73479150",
    lng: "24.35747740"
    },
    {
    state: "Panevėžys County",
    stateShort: "PN",
    lat: "55.97480490",
    lng: "25.07947670"
    },
    {
    state: "Panevėžys District Municipality",
    stateShort: "33",
    lat: "55.61667280",
    lng: "24.31422830"
    },
    {
    state: "Pasvalys District Municipality",
    stateShort: "34",
    lat: "56.06046190",
    lng: "24.39629100"
    },
    {
    state: "Plungė District Municipality",
    stateShort: "35",
    lat: "55.91078400",
    lng: "21.84540690"
    },
    {
    state: "Prienai District Municipality",
    stateShort: "36",
    lat: "54.63835800",
    lng: "23.94680090"
    },
    {
    state: "Radviliškis District Municipality",
    stateShort: "37",
    lat: "55.81083990",
    lng: "23.54648700"
    },
    {
    state: "Raseiniai District Municipality",
    stateShort: "38",
    lat: "55.38194990",
    lng: "23.11561290"
    },
    {
    state: "Rietavas municipality",
    stateShort: "39",
    lat: "55.70217190",
    lng: "21.99865640"
    },
    {
    state: "Rokiškis District Municipality",
    stateShort: "40",
    lat: "55.95550390",
    lng: "25.58592490"
    },
    {
    state: "Šakiai District Municipality",
    stateShort: "41",
    lat: "54.95267100",
    lng: "23.04801990"
    },
    {
    state: "Šalčininkai District Municipality",
    stateShort: "42",
    lat: "54.30976700",
    lng: "25.38756400"
    },
    {
    state: "Šiauliai City Municipality",
    stateShort: "43",
    lat: "55.93490850",
    lng: "23.31368230"
    },
    {
    state: "Šiauliai County",
    stateShort: "SA",
    lat: "55.99857510",
    lng: "23.13800510"
    },
    {
    state: "Šiauliai District Municipality",
    stateShort: "44",
    lat: "55.97214560",
    lng: "23.03323710"
    },
    {
    state: "Šilalė District Municipality",
    stateShort: "45",
    lat: "55.49268000",
    lng: "22.18455590"
    },
    {
    state: "Šilutė District Municipality",
    stateShort: "46",
    lat: "55.35041400",
    lng: "21.46598590"
    },
    {
    state: "Širvintos District Municipality",
    stateShort: "47",
    lat: "55.04310200",
    lng: "24.95698100"
    },
    {
    state: "Skuodas District Municipality",
    stateShort: "48",
    lat: "56.27021690",
    lng: "21.52143310"
    },
    {
    state: "Švenčionys District Municipality",
    stateShort: "49",
    lat: "55.10280980",
    lng: "26.00718550"
    },
    {
    state: "Tauragė County",
    stateShort: "TA",
    lat: "55.30725860",
    lng: "22.35729390"
    },
    {
    state: "Tauragė District Municipality",
    stateShort: "50",
    lat: "55.25036600",
    lng: "22.29095000"
    },
    {
    state: "Telšiai County",
    stateShort: "TE",
    lat: "56.10266160",
    lng: "22.11139150"
    },
    {
    state: "Telšiai District Municipality",
    stateShort: "51",
    lat: "55.91752150",
    lng: "22.34518400"
    },
    {
    state: "Trakai District Municipality",
    stateShort: "52",
    lat: "54.63791130",
    lng: "24.93468940"
    },
    {
    state: "Ukmergė District Municipality",
    stateShort: "53",
    lat: "55.24526500",
    lng: "24.77607490"
    },
    {
    state: "Utena County",
    stateShort: "UT",
    lat: "55.53189690",
    lng: "25.79046990"
    },
    {
    state: "Utena District Municipality",
    stateShort: "54",
    lat: "55.50846140",
    lng: "25.68326420"
    },
    {
    state: "Varėna District Municipality",
    stateShort: "55",
    lat: "54.22033300",
    lng: "24.57899700"
    },
    {
    state: "Vilkaviškis District Municipality",
    stateShort: "56",
    lat: "54.65194500",
    lng: "23.03515500"
    },
    {
    state: "Vilnius City Municipality",
    stateShort: "57",
    lat: "54.67107610",
    lng: "25.28787210"
    },
    {
    state: "Vilnius County",
    stateShort: "VL",
    lat: "54.80865020",
    lng: "25.21821390"
    },
    {
    state: "Vilnius District Municipality",
    stateShort: "58",
    lat: "54.77325780",
    lng: "25.58671130"
    },
    {
    state: "Visaginas Municipality",
    stateShort: "59",
    lat: "55.59411800",
    lng: "26.43079540"
    },
    {
    state: "Zarasai District Municipality",
    stateShort: "60",
    lat: "55.73096090",
    lng: "26.24529500"
    }
    ]
    },
    {
    country: "Luxembourg",
    countryShort: "LU",
    currency_symbol: "€",
    states: [
    {
    state: "Capellen",
    stateShort: "CA",
    lat: "49.64039310",
    lng: "5.95538460"
    },
    {
    state: "Clervaux",
    stateShort: "CL",
    lat: "50.05463130",
    lng: "6.02858750"
    },
    {
    state: "Diekirch",
    stateShort: "DI",
    lat: "49.86717840",
    lng: "6.15956330"
    },
    {
    state: "Echternach",
    stateShort: "EC",
    lat: "49.81141330",
    lng: "6.41756350"
    },
    {
    state: "Esch-sur-Alzette",
    stateShort: "ES",
    lat: "49.50088050",
    lng: "5.98609250"
    },
    {
    state: "Grevenmacher",
    stateShort: "GR",
    lat: "49.68084100",
    lng: "6.44075930"
    },
    {
    state: "Luxembourg",
    stateShort: "LU",
    lat: "49.63010250",
    lng: "6.15201850"
    },
    {
    state: "Mersch",
    stateShort: "ME",
    lat: "49.75429060",
    lng: "6.12921850"
    },
    {
    state: "Redange",
    stateShort: "RD",
    lat: "49.76455000",
    lng: "5.88948000"
    },
    {
    state: "Remich",
    stateShort: "RM",
    lat: "49.54501700",
    lng: "6.36742220"
    },
    {
    state: "Vianden",
    stateShort: "VD",
    lat: "49.93419240",
    lng: "6.20199170"
    },
    {
    state: "Wiltz",
    stateShort: "WI",
    lat: "49.96622000",
    lng: "5.93243060"
    },
    {
    state: "Diekirch",
    stateShort: "D",
    lat: "49.86717200",
    lng: "6.15963620"
    },
    {
    state: "Grevenmacher",
    stateShort: "G",
    lat: "49.68085100",
    lng: "6.44075240"
    },
    {
    state: "Luxembourg",
    stateShort: "L",
    lat: "49.59537060",
    lng: "6.13331780"
    }
    ]
    },
  
    {
    country: "Macedonia",
    countryShort: "MK",
    currency_symbol: "ден",
    states: [
    {
    state: "Aerodrom Municipality",
    stateShort: "01",
    lat: "41.94643630",
    lng: "21.49317130"
    },
    {
    state: "Aračinovo Municipality",
    stateShort: "02",
    lat: "42.02473810",
    lng: "21.57664070"
    },
    {
    state: "Berovo Municipality",
    stateShort: "03",
    lat: "41.66619290",
    lng: "22.76288300"
    },
    {
    state: "Bitola Municipality",
    stateShort: "04",
    lat: "41.03633020",
    lng: "21.33219740"
    },
    {
    state: "Bogdanci Municipality",
    stateShort: "05",
    lat: "41.18696160",
    lng: "22.59602680"
    },
    {
    state: "Bogovinje Municipality",
    stateShort: "06",
    lat: "41.92363710",
    lng: "20.91638870"
    },
    {
    state: "Bosilovo Municipality",
    stateShort: "07",
    lat: "41.49048640",
    lng: "22.78671740"
    },
    {
    state: "Brvenica Municipality",
    stateShort: "08",
    lat: "41.96814820",
    lng: "20.98195860"
    },
    {
    state: "Butel Municipality",
    stateShort: "09",
    lat: "42.08950680",
    lng: "21.46336100"
    },
    {
    state: "Čair Municipality",
    stateShort: "79",
    lat: "41.99303550",
    lng: "21.43653180"
    },
    {
    state: "Čaška Municipality",
    stateShort: "80",
    lat: "41.64743800",
    lng: "21.69141150"
    },
    {
    state: "Centar Municipality",
    stateShort: "77",
    lat: "41.96989340",
    lng: "21.42162670"
    },
    {
    state: "Centar Župa Municipality",
    stateShort: "78",
    lat: "41.46522590",
    lng: "20.59305480"
    },
    {
    state: "Češinovo-Obleševo Municipality",
    stateShort: "81",
    lat: "41.86393160",
    lng: "22.26224600"
    },
    {
    state: "Čučer-Sandevo Municipality",
    stateShort: "82",
    lat: "42.14839460",
    lng: "21.40374070"
    },
    {
    state: "Debarca Municipality",
    stateShort: "22",
    lat: "41.35840770",
    lng: "20.85529190"
    },
    {
    state: "Delčevo Municipality",
    stateShort: "23",
    lat: "41.96843870",
    lng: "22.76288300"
    },
    {
    state: "Demir Hisar Municipality",
    stateShort: "25",
    lat: "41.22708300",
    lng: "21.14142260"
    },
    {
    state: "Demir Kapija Municipality",
    stateShort: "24",
    lat: "41.37955380",
    lng: "22.21455710"
    },
    {
    state: "Dojran Municipality",
    stateShort: "26",
    lat: "41.24366720",
    lng: "22.69137640"
    },
    {
    state: "Dolneni Municipality",
    stateShort: "27",
    lat: "41.46409350",
    lng: "21.40374070"
    },
    {
    state: "Drugovo Municipality",
    stateShort: "28",
    lat: "41.44081530",
    lng: "20.92682010"
    },
    {
    state: "Gazi Baba Municipality",
    stateShort: "17",
    lat: "42.01629610",
    lng: "21.49913340"
    },
    {
    state: "Gevgelija Municipality",
    stateShort: "18",
    lat: "41.21186060",
    lng: "22.38146240"
    },
    {
    state: "Gjorče Petrov Municipality",
    stateShort: "29",
    lat: "42.06063740",
    lng: "21.32027360"
    },
    {
    state: "Gostivar Municipality",
    stateShort: "19",
    lat: "41.80255410",
    lng: "20.90893780"
    },
    {
    state: "Gradsko Municipality",
    stateShort: "20",
    lat: "41.59916080",
    lng: "21.88070640"
    },
    {
    state: "Greater Skopje",
    stateShort: "85",
    lat: "41.99812940",
    lng: "21.42543550"
    },
    {
    state: "Ilinden Municipality",
    stateShort: "34",
    lat: "41.99574430",
    lng: "21.56769750"
    },
    {
    state: "Jegunovce Municipality",
    stateShort: "35",
    lat: "42.07407200",
    lng: "21.12204780"
    },
    {
    state: "Karbinci",
    stateShort: "37",
    lat: "41.81801590",
    lng: "22.23247580"
    },
    {
    state: "Karpoš Municipality",
    stateShort: "38",
    lat: "41.97096610",
    lng: "21.39181680"
    },
    {
    state: "Kavadarci Municipality",
    stateShort: "36",
    lat: "41.28900680",
    lng: "21.99994350"
    },
    {
    state: "Kičevo Municipality",
    stateShort: "40",
    lat: "41.51291120",
    lng: "20.95250650"
    },
    {
    state: "Kisela Voda Municipality",
    stateShort: "39",
    lat: "41.92748000",
    lng: "21.49317130"
    },
    {
    state: "Kočani Municipality",
    stateShort: "42",
    lat: "41.98583740",
    lng: "22.40530460"
    },
    {
    state: "Konče Municipality",
    stateShort: "41",
    lat: "41.51710110",
    lng: "22.38146240"
    },
    {
    state: "Kratovo Municipality",
    stateShort: "43",
    lat: "42.05371410",
    lng: "22.07148350"
    },
    {
    state: "Kriva Palanka Municipality",
    stateShort: "44",
    lat: "42.20584540",
    lng: "22.33079650"
    },
    {
    state: "Krivogaštani Municipality",
    stateShort: "45",
    lat: "41.30823060",
    lng: "21.36796890"
    },
    {
    state: "Kruševo Municipality",
    stateShort: "46",
    lat: "41.37693310",
    lng: "21.26065540"
    },
    {
    state: "Kumanovo Municipality",
    stateShort: "47",
    lat: "42.07326130",
    lng: "21.78531430"
    },
    {
    state: "Lipkovo Municipality",
    stateShort: "48",
    lat: "42.20066260",
    lng: "21.61837550"
    },
    {
    state: "Lozovo Municipality",
    stateShort: "49",
    lat: "41.78181390",
    lng: "21.90008270"
    },
    {
    state: "Makedonska Kamenica Municipality",
    stateShort: "51",
    lat: "42.06946040",
    lng: "22.54834900"
    },
    {
    state: "Makedonski Brod Municipality",
    stateShort: "52",
    lat: "41.51330880",
    lng: "21.21743290"
    },
    {
    state: "Mavrovo and Rostuša Municipality",
    stateShort: "50",
    lat: "41.60924270",
    lng: "20.60124880"
    },
    {
    state: "Mogila Municipality",
    stateShort: "53",
    lat: "41.14796450",
    lng: "21.45143690"
    },
    {
    state: "Negotino Municipality",
    stateShort: "54",
    lat: "41.49899850",
    lng: "22.09532970"
    },
    {
    state: "Novaci Municipality",
    stateShort: "55",
    lat: "41.04426610",
    lng: "21.45888940"
    },
    {
    state: "Novo Selo Municipality",
    stateShort: "56",
    lat: "41.43255800",
    lng: "22.88204890"
    },
    {
    state: "Ohrid Municipality",
    stateShort: "58",
    lat: "41.06820880",
    lng: "20.75992660"
    },
    {
    state: "Oslomej Municipality",
    stateShort: "57",
    lat: "41.57583910",
    lng: "21.02219600"
    },
    {
    state: "Pehčevo Municipality",
    stateShort: "60",
    lat: "41.77371320",
    lng: "22.88204890"
    },
    {
    state: "Petrovec Municipality",
    stateShort: "59",
    lat: "41.90298970",
    lng: "21.68992100"
    },
    {
    state: "Plasnica Municipality",
    stateShort: "61",
    lat: "41.45463490",
    lng: "21.10565390"
    },
    {
    state: "Prilep Municipality",
    stateShort: "62",
    lat: "41.26931420",
    lng: "21.71376940"
    },
    {
    state: "Probištip Municipality",
    stateShort: "63",
    lat: "41.95891460",
    lng: "22.16686700"
    },
    {
    state: "Radoviš Municipality",
    stateShort: "64",
    lat: "41.64955310",
    lng: "22.47682870"
    },
    {
    state: "Rankovce Municipality",
    stateShort: "65",
    lat: "42.18081410",
    lng: "22.09532970"
    },
    {
    state: "Resen Municipality",
    stateShort: "66",
    lat: "40.93680930",
    lng: "21.04604070"
    },
    {
    state: "Rosoman Municipality",
    stateShort: "67",
    lat: "41.48480060",
    lng: "21.88070640"
    },
    {
    state: "Saraj Municipality",
    stateShort: "68",
    lat: "41.98694960",
    lng: "21.26065540"
    },
    {
    state: "Sopište Municipality",
    stateShort: "70",
    lat: "41.86384920",
    lng: "21.30834990"
    },
    {
    state: "Staro Nagoričane Municipality",
    stateShort: "71",
    lat: "42.21916920",
    lng: "21.90455410"
    },
    {
    state: "Štip Municipality",
    stateShort: "83",
    lat: "41.70792970",
    lng: "22.19071220"
    },
    {
    state: "Struga Municipality",
    stateShort: "72",
    lat: "41.31737440",
    lng: "20.66456830"
    },
    {
    state: "Strumica Municipality",
    stateShort: "73",
    lat: "41.43780040",
    lng: "22.64274280"
    },
    {
    state: "Studeničani Municipality",
    stateShort: "74",
    lat: "41.92256390",
    lng: "21.53639650"
    },
    {
    state: "Šuto Orizari Municipality",
    stateShort: "84",
    lat: "42.02904160",
    lng: "21.40970270"
    },
    {
    state: "Sveti Nikole Municipality",
    stateShort: "69",
    lat: "41.89803120",
    lng: "21.99994350"
    },
    {
    state: "Tearce Municipality",
    stateShort: "75",
    lat: "42.07775110",
    lng: "21.05349230"
    },
    {
    state: "Tetovo Municipality",
    stateShort: "76",
    lat: "42.02748600",
    lng: "20.95066360"
    },
    {
    state: "Valandovo Municipality",
    stateShort: "10",
    lat: "41.32119090",
    lng: "22.50066930"
    },
    {
    state: "Vasilevo Municipality",
    stateShort: "11",
    lat: "41.47416990",
    lng: "22.64221280"
    },
    {
    state: "Veles Municipality",
    stateShort: "13",
    lat: "41.72744260",
    lng: "21.71376940"
    },
    {
    state: "Vevčani Municipality",
    stateShort: "12",
    lat: "41.24075430",
    lng: "20.59156490"
    },
    {
    state: "Vinica Municipality",
    stateShort: "14",
    lat: "41.85710200",
    lng: "22.57218810"
    },
    {
    state: "Vraneštica Municipality",
    stateShort: "15",
    lat: "41.48290870",
    lng: "21.05796320"
    },
    {
    state: "Vrapčište Municipality",
    stateShort: "16",
    lat: "41.87911600",
    lng: "20.83145000"
    },
    {
    state: "Zajas Municipality",
    stateShort: "31",
    lat: "41.60303280",
    lng: "20.87913430"
    },
    {
    state: "Zelenikovo Municipality",
    stateShort: "32",
    lat: "41.87338120",
    lng: "21.60272500"
    },
    {
    state: "Želino Municipality",
    stateShort: "30",
    lat: "41.90065310",
    lng: "21.11757670"
    },
    {
    state: "Zrnovci Municipality",
    stateShort: "33",
    lat: "41.82282210",
    lng: "22.41722560"
    }
    ]
    },
    {
    country: "Madagascar",
    countryShort: "MG",
    currency_symbol: "Ar",
    states: [
    {
    state: "Antananarivo Province",
    stateShort: "T",
    lat: "-18.70514740",
    lng: "46.82528380"
    },
    {
    state: "Antsiranana Province",
    stateShort: "D",
    lat: "-13.77153900",
    lng: "49.52799960"
    },
    {
    state: "Fianarantsoa Province",
    stateShort: "F",
    lat: "-22.35362400",
    lng: "46.82528380"
    },
    {
    state: "Mahajanga Province",
    stateShort: "M",
    lat: "-16.52388300",
    lng: "46.51626200"
    },
    {
    state: "Toamasina Province",
    stateShort: "A",
    lat: "-18.14428110",
    lng: "49.39578360"
    },
    {
    state: "Toliara Province",
    stateShort: "U",
    lat: "-23.35161910",
    lng: "43.68549360"
    }
    ]
    },
    {
    country: "Malawi",
    countryShort: "MW",
    currency_symbol: "MK",
    states: [
    {
    state: "Balaka District",
    stateShort: "BA",
    lat: "-15.05065950",
    lng: "35.08285880"
    },
    {
    state: "Blantyre District",
    stateShort: "BL",
    lat: "-15.67785410",
    lng: "34.95066250"
    },
    {
    state: "Central Region",
    stateShort: "C",
    lat: null,
    lng: null
    },
    {
    state: "Chikwawa District",
    stateShort: "CK",
    lat: "-16.19584460",
    lng: "34.77407930"
    },
    {
    state: "Chiradzulu District",
    stateShort: "CR",
    lat: "-15.74231510",
    lng: "35.25879640"
    },
    {
    state: "Chitipa district",
    stateShort: "CT",
    lat: "-9.70376550",
    lng: "33.27002530"
    },
    {
    state: "Dedza District",
    stateShort: "DE",
    lat: "-14.18945850",
    lng: "34.24215970"
    },
    {
    state: "Dowa District",
    stateShort: "DO",
    lat: "-13.60412560",
    lng: "33.88577470"
    },
    {
    state: "Karonga District",
    stateShort: "KR",
    lat: "-9.90363650",
    lng: "33.97500180"
    },
    {
    state: "Kasungu District",
    stateShort: "KS",
    lat: "-13.13670650",
    lng: "33.25879300"
    },
    {
    state: "Likoma District",
    stateShort: "LK",
    lat: "-12.05840050",
    lng: "34.73540310"
    },
    {
    state: "Lilongwe District",
    stateShort: "LI",
    lat: "-14.04752280",
    lng: "33.61757700"
    },
    {
    state: "Machinga District",
    stateShort: "MH",
    lat: "-14.94072630",
    lng: "35.47819260"
    },
    {
    state: "Mangochi District",
    stateShort: "MG",
    lat: "-14.13882480",
    lng: "35.03881640"
    },
    {
    state: "Mchinji District",
    stateShort: "MC",
    lat: "-13.74015250",
    lng: "32.98883190"
    },
    {
    state: "Mulanje District",
    stateShort: "MU",
    lat: "-15.93464340",
    lng: "35.52200120"
    },
    {
    state: "Mwanza District",
    stateShort: "MW",
    lat: "-2.46711970",
    lng: "32.89868120"
    },
    {
    state: "Mzimba District",
    stateShort: "MZ",
    lat: "-11.74754520",
    lng: "33.52800720"
    },
    {
    state: "Nkhata Bay District",
    stateShort: "NB",
    lat: "-11.71850420",
    lng: "34.33103640"
    },
    {
    state: "Nkhotakota District",
    stateShort: "NK",
    lat: "-12.75419610",
    lng: "34.24215970"
    },
    {
    state: "Northern Region",
    stateShort: "N",
    lat: null,
    lng: null
    },
    {
    state: "Nsanje District",
    stateShort: "NS",
    lat: "-16.72882020",
    lng: "35.17087410"
    },
    {
    state: "Ntcheu District",
    stateShort: "NU",
    lat: "-14.90375380",
    lng: "34.77407930"
    },
    {
    state: "Ntchisi District",
    stateShort: "NI",
    lat: "-13.28419920",
    lng: "33.88577470"
    },
    {
    state: "Phalombe District",
    stateShort: "PH",
    lat: "-15.70920380",
    lng: "35.65328480"
    },
    {
    state: "Rumphi District",
    stateShort: "RU",
    lat: "-10.78515370",
    lng: "34.33103640"
    },
    {
    state: "Salima District",
    stateShort: "SA",
    lat: "-13.68095860",
    lng: "34.41982430"
    },
    {
    state: "Southern Region",
    stateShort: "S",
    lat: "32.75049570",
    lng: "-97.33154760"
    },
    {
    state: "Thyolo District",
    stateShort: "TH",
    lat: "-16.12991770",
    lng: "35.12687810"
    },
    {
    state: "Zomba District",
    stateShort: "ZO",
    lat: "-15.37658570",
    lng: "35.33565180"
    }
    ]
    },
    {
    country: "Malaysia",
    countryShort: "MY",
    currency_symbol: "RM",
    states: [
    {
    state: "Johor",
    stateShort: "01",
    lat: "1.48536820",
    lng: "103.76181540"
    },
    {
    state: "Kedah",
    stateShort: "02",
    lat: "6.11839640",
    lng: "100.36845950"
    },
    {
    state: "Kelantan",
    stateShort: "03",
    lat: "6.12539690",
    lng: "102.23807100"
    },
    {
    state: "Kuala Lumpur",
    stateShort: "14",
    lat: "3.13900300",
    lng: "101.68685500"
    },
    {
    state: "Labuan",
    stateShort: "15",
    lat: "5.28314560",
    lng: "115.23082500"
    },
    {
    state: "Malacca",
    stateShort: "04",
    lat: "2.18959400",
    lng: "102.25008680"
    },
    {
    state: "Negeri Sembilan",
    stateShort: "05",
    lat: "2.72580580",
    lng: "101.94237820"
    },
    {
    state: "Pahang",
    stateShort: "06",
    lat: "3.81263180",
    lng: "103.32562040"
    },
    {
    state: "Penang",
    stateShort: "07",
    lat: "5.41639350",
    lng: "100.33267860"
    },
    {
    state: "Perak",
    stateShort: "08",
    lat: "4.59211260",
    lng: "101.09010900"
    },
    {
    state: "Perlis",
    stateShort: "09",
    lat: "29.92270940",
    lng: "-90.12285590"
    },
    {
    state: "Putrajaya",
    stateShort: "16",
    lat: "2.92636100",
    lng: "101.69644500"
    },
    {
    state: "Sabah",
    stateShort: "12",
    lat: "5.97883980",
    lng: "116.07531990"
    },
    {
    state: "Sarawak",
    stateShort: "13",
    lat: "1.55327830",
    lng: "110.35921270"
    },
    {
    state: "Selangor",
    stateShort: "10",
    lat: "3.07383790",
    lng: "101.51834690"
    },
    {
    state: "Terengganu",
    stateShort: "11",
    lat: "5.31169160",
    lng: "103.13241540"
    }
    ]
    },
    {
    country: "Maldives",
    countryShort: "MV",
    currency_symbol: "Rf",
    states: [
    {
    state: "Addu Atoll",
    stateShort: "01",
    lat: "-0.63009950",
    lng: "73.15856260"
    },
    {
    state: "Alif Alif Atoll",
    stateShort: "02",
    lat: "4.08500000",
    lng: "72.85154790"
    },
    {
    state: "Alif Dhaal Atoll",
    stateShort: "00",
    lat: "3.65433020",
    lng: "72.80427970"
    },
    {
    state: "Central Province",
    stateShort: "CE",
    lat: null,
    lng: null
    },
    {
    state: "Dhaalu Atoll",
    stateShort: "17",
    lat: "2.84685020",
    lng: "72.94605660"
    },
    {
    state: "Faafu Atoll",
    stateShort: "14",
    lat: "3.23094090",
    lng: "72.94605660"
    },
    {
    state: "Gaafu Alif Atoll",
    stateShort: "27",
    lat: "0.61248130",
    lng: "73.32370800"
    },
    {
    state: "Gaafu Dhaalu Atoll",
    stateShort: "28",
    lat: "0.35880400",
    lng: "73.18216230"
    },
    {
    state: "Gnaviyani Atoll",
    stateShort: "29",
    lat: "-0.30064250",
    lng: "73.42391430"
    },
    {
    state: "Haa Alif Atoll",
    stateShort: "07",
    lat: "6.99034880",
    lng: "72.94605660"
    },
    {
    state: "Haa Dhaalu Atoll",
    stateShort: "23",
    lat: "6.57827170",
    lng: "72.94605660"
    },
    {
    state: "Kaafu Atoll",
    stateShort: "26",
    lat: "4.45589790",
    lng: "73.55941280"
    },
    {
    state: "Laamu Atoll",
    stateShort: "05",
    lat: "1.94307370",
    lng: "73.41802110"
    },
    {
    state: "Lhaviyani Atoll",
    stateShort: "03",
    lat: "5.37470210",
    lng: "73.51229280"
    },
    {
    state: "Malé",
    stateShort: "MLE",
    lat: "46.34888670",
    lng: "10.90724890"
    },
    {
    state: "Meemu Atoll",
    stateShort: "12",
    lat: "3.00903450",
    lng: "73.51229280"
    },
    {
    state: "Noonu Atoll",
    stateShort: "25",
    lat: "5.85512760",
    lng: "73.32370800"
    },
    {
    state: "North Central Province",
    stateShort: "NC",
    lat: null,
    lng: null
    },
    {
    state: "North Province",
    stateShort: "NO",
    lat: "8.88550270",
    lng: "80.27673270"
    },
    {
    state: "Raa Atoll",
    stateShort: "13",
    lat: "5.60064570",
    lng: "72.94605660"
    },
    {
    state: "Shaviyani Atoll",
    stateShort: "24",
    lat: "6.17511000",
    lng: "73.13496050"
    },
    {
    state: "South Central Province",
    stateShort: "SC",
    lat: "7.25649960",
    lng: "80.72144170"
    },
    {
    state: "South Province",
    stateShort: "SU",
    lat: "-21.74820060",
    lng: "166.17837390"
    },
    {
    state: "Thaa Atoll",
    stateShort: "08",
    lat: "2.43111610",
    lng: "73.18216230"
    },
    {
    state: "Upper South Province",
    stateShort: "US",
    lat: "0.23070000",
    lng: "73.27948460"
    },
    {
    state: "Vaavu Atoll",
    stateShort: "04",
    lat: "3.39554380",
    lng: "73.51229280"
    }
    ]
    },
    {
    country: "Mali",
    countryShort: "ML",
    currency_symbol: "CFA",
    states: [
    {
    state: "Bamako",
    stateShort: "BKO",
    lat: "12.63923160",
    lng: "-8.00288920"
    },
    {
    state: "Gao Region",
    stateShort: "7",
    lat: "16.90663320",
    lng: "1.52086240"
    },
    {
    state: "Kayes Region",
    stateShort: "1",
    lat: "14.08183080",
    lng: "-9.90181310"
    },
    {
    state: "Kidal Region",
    stateShort: "8",
    lat: "18.79868320",
    lng: "1.83183340"
    },
    {
    state: "Koulikoro Region",
    stateShort: "2",
    lat: "13.80180740",
    lng: "-7.43813550"
    },
    {
    state: "Ménaka Region",
    stateShort: "9",
    lat: "15.91564210",
    lng: "2.39617400"
    },
    {
    state: "Mopti Region",
    stateShort: "5",
    lat: "14.63380390",
    lng: "-3.41955270"
    },
    {
    state: "Ségou Region",
    stateShort: "4",
    lat: "13.83944560",
    lng: "-6.06791940"
    },
    {
    state: "Sikasso Region",
    stateShort: "3",
    lat: "10.89051860",
    lng: "-7.43813550"
    },
    {
    state: "Taoudénit Region",
    stateShort: "10",
    lat: "22.67641320",
    lng: "-3.97891430"
    },
    {
    state: "Tombouctou Region",
    stateShort: "6",
    lat: "21.05267060",
    lng: "-3.74350900"
    }
    ]
    },
    {
    country: "Malta",
    countryShort: "MT",
    currency_symbol: "€",
    states: [
    {
    state: "Attard",
    stateShort: "01",
    lat: "35.89049670",
    lng: "14.41993220"
    },
    {
    state: "Balzan",
    stateShort: "02",
    lat: "35.89574140",
    lng: "14.45340650"
    },
    {
    state: "Birgu",
    stateShort: "03",
    lat: "35.88792140",
    lng: "14.52256200"
    },
    {
    state: "Birkirkara",
    stateShort: "04",
    lat: "35.89547060",
    lng: "14.46650720"
    },
    {
    state: "Birżebbuġa",
    stateShort: "05",
    lat: "35.81359890",
    lng: "14.52474630"
    },
    {
    state: "Cospicua",
    stateShort: "06",
    lat: "35.88067530",
    lng: "14.52183380"
    },
    {
    state: "Dingli",
    stateShort: "07",
    lat: "35.86273090",
    lng: "14.38501070"
    },
    {
    state: "Fgura",
    stateShort: "08",
    lat: "35.87382690",
    lng: "14.52329010"
    },
    {
    state: "Floriana",
    stateShort: "09",
    lat: "45.49521850",
    lng: "-73.71395760"
    },
    {
    state: "Fontana",
    stateShort: "10",
    lat: "34.09223350",
    lng: "-117.43504800"
    },
    {
    state: "Għajnsielem",
    stateShort: "13",
    lat: "36.02479660",
    lng: "14.28029610"
    },
    {
    state: "Għarb",
    stateShort: "14",
    lat: "36.06890900",
    lng: "14.20180980"
    },
    {
    state: "Għargħur",
    stateShort: "15",
    lat: "35.92205690",
    lng: "14.45631760"
    },
    {
    state: "Għasri",
    stateShort: "16",
    lat: "36.06680750",
    lng: "14.21924750"
    },
    {
    state: "Għaxaq",
    stateShort: "17",
    lat: "35.84403590",
    lng: "14.51600900"
    },
    {
    state: "Gudja",
    stateShort: "11",
    lat: "35.84698030",
    lng: "14.50290400"
    },
    {
    state: "Gżira",
    stateShort: "12",
    lat: "35.90589700",
    lng: "14.49533380"
    },
    {
    state: "Ħamrun",
    stateShort: "18",
    lat: "35.88612370",
    lng: "14.48834420"
    },
    {
    state: "Iklin",
    stateShort: "19",
    lat: "35.90987740",
    lng: "14.45777320"
    },
    {
    state: "Kalkara",
    stateShort: "21",
    lat: "35.89142420",
    lng: "14.53202780"
    },
    {
    state: "Kerċem",
    stateShort: "22",
    lat: "36.04479390",
    lng: "14.22506050"
    },
    {
    state: "Kirkop",
    stateShort: "23",
    lat: "35.84378620",
    lng: "14.48543240"
    },
    {
    state: "Lija",
    stateShort: "24",
    lat: "49.18007600",
    lng: "-123.10331700"
    },
    {
    state: "Luqa",
    stateShort: "25",
    lat: "35.85828650",
    lng: "14.48688830"
    },
    {
    state: "Marsa",
    stateShort: "26",
    lat: "34.03195870",
    lng: "-118.44555350"
    },
    {
    state: "Marsaskala",
    stateShort: "27",
    lat: "35.86036400",
    lng: "14.55678760"
    },
    {
    state: "Marsaxlokk",
    stateShort: "28",
    lat: "35.84116990",
    lng: "14.53930970"
    },
    {
    state: "Mdina",
    stateShort: "29",
    lat: "35.88809300",
    lng: "14.40683570"
    },
    {
    state: "Mellieħa",
    stateShort: "30",
    lat: "35.95235290",
    lng: "14.35009750"
    },
    {
    state: "Mġarr",
    stateShort: "31",
    lat: "35.91893270",
    lng: "14.36173430"
    },
    {
    state: "Mosta",
    stateShort: "32",
    lat: "35.91415040",
    lng: "14.42284270"
    },
    {
    state: "Mqabba",
    stateShort: "33",
    lat: "35.84441430",
    lng: "14.46941860"
    },
    {
    state: "Msida",
    stateShort: "34",
    lat: "35.89563880",
    lng: "14.48688830"
    },
    {
    state: "Mtarfa",
    stateShort: "35",
    lat: "35.88951250",
    lng: "14.39519530"
    },
    {
    state: "Munxar",
    stateShort: "36",
    lat: "36.02880580",
    lng: "14.22506050"
    },
    {
    state: "Nadur",
    stateShort: "37",
    lat: "36.04470190",
    lng: "14.29192730"
    },
    {
    state: "Naxxar",
    stateShort: "38",
    lat: "35.93175180",
    lng: "14.43157460"
    },
    {
    state: "Paola",
    stateShort: "39",
    lat: "38.57223530",
    lng: "-94.87912940"
    },
    {
    state: "Pembroke",
    stateShort: "40",
    lat: "34.68016260",
    lng: "-79.19503730"
    },
    {
    state: "Pietà",
    stateShort: "41",
    lat: "42.21862000",
    lng: "-83.73464700"
    },
    {
    state: "Qala",
    stateShort: "42",
    lat: "36.03886280",
    lng: "14.31810100"
    },
    {
    state: "Qormi",
    stateShort: "43",
    lat: "35.87643880",
    lng: "14.46941860"
    },
    {
    state: "Qrendi",
    stateShort: "44",
    lat: "35.83284880",
    lng: "14.45486210"
    },
    {
    state: "Rabat",
    stateShort: "46",
    lat: "33.97159040",
    lng: "-6.84981290"
    },
    {
    state: "Saint Lawrence",
    stateShort: "50",
    lat: "38.95780560",
    lng: "-95.25656890"
    },
    {
    state: "San Ġwann",
    stateShort: "49",
    lat: "35.90773650",
    lng: "14.47524160"
    },
    {
    state: "Sannat",
    stateShort: "52",
    lat: "36.01926430",
    lng: "14.25994370"
    },
    {
    state: "Santa Luċija",
    stateShort: "53",
    lat: "35.85614200",
    lng: "14.50436000"
    },
    {
    state: "Santa Venera",
    stateShort: "54",
    lat: "35.89022010",
    lng: "14.47669740"
    },
    {
    state: "Senglea",
    stateShort: "20",
    lat: "35.88730410",
    lng: "14.51673710"
    },
    {
    state: "Siġġiewi",
    stateShort: "55",
    lat: "35.84637420",
    lng: "14.43157460"
    },
    {
    state: "Sliema",
    stateShort: "56",
    lat: "35.91100810",
    lng: "14.50290400"
    },
    {
    state: "St. Julian's",
    stateShort: "48",
    lat: "42.21225130",
    lng: "-85.89171270"
    },
    {
    state: "St. Paul's Bay",
    stateShort: "51",
    lat: "35.93601700",
    lng: "14.39665030"
    },
    {
    state: "Swieqi",
    stateShort: "57",
    lat: "35.91911820",
    lng: "14.46941860"
    },
    {
    state: "Ta' Xbiex",
    stateShort: "58",
    lat: "35.89914480",
    lng: "14.49635190"
    },
    {
    state: "Tarxien",
    stateShort: "59",
    lat: "35.86725520",
    lng: "14.51164050"
    },
    {
    state: "Valletta",
    stateShort: "60",
    lat: "35.89890850",
    lng: "14.51455280"
    },
    {
    state: "Victoria",
    stateShort: "45",
    lat: "28.80526740",
    lng: "-97.00359820"
    },
    {
    state: "Xagħra",
    stateShort: "61",
    lat: "36.05084500",
    lng: "14.26748200"
    },
    {
    state: "Xewkija",
    stateShort: "62",
    lat: "36.02992360",
    lng: "14.25994370"
    },
    {
    state: "Xgħajra",
    stateShort: "63",
    lat: "35.88682820",
    lng: "14.54723910"
    },
    {
    state: "Żabbar",
    stateShort: "64",
    lat: "35.87247150",
    lng: "14.54513540"
    },
    {
    state: "Żebbuġ Gozo",
    stateShort: "65",
    lat: "36.07164030",
    lng: "14.24540800"
    },
    {
    state: "Żebbuġ Malta",
    stateShort: "66",
    lat: "35.87646480",
    lng: "14.43908400"
    },
    {
    state: "Żejtun",
    stateShort: "67",
    lat: "35.85487140",
    lng: "14.53639690"
    },
    {
    state: "Żurrieq",
    stateShort: "68",
    lat: "35.82163060",
    lng: "14.48106480"
    }
    ]
    },
   
    {
    country: "Marshall Islands",
    countryShort: "MH",
    currency_symbol: "$",
    states: [
    {
    state: "Ralik Chain",
    stateShort: "L",
    lat: "8.13614600",
    lng: "164.88679560"
    },
    {
    state: "Ratak Chain",
    stateShort: "T",
    lat: "10.27632760",
    lng: "170.55009370"
    }
    ]
    },
 
    {
    country: "Mauritania",
    countryShort: "MR",
    currency_symbol: "MRU",
    states: [
    {
    state: "Adrar",
    stateShort: "07",
    lat: "19.86521760",
    lng: "-12.80547530"
    },
    {
    state: "Assaba",
    stateShort: "03",
    lat: "16.77595580",
    lng: "-11.52480550"
    },
    {
    state: "Brakna",
    stateShort: "05",
    lat: "17.23175610",
    lng: "-13.17403480"
    },
    {
    state: "Dakhlet Nouadhibou",
    stateShort: "08",
    lat: "20.59855880",
    lng: "-16.25221430"
    },
    {
    state: "Gorgol",
    stateShort: "04",
    lat: "15.97173570",
    lng: "-12.62162110"
    },
    {
    state: "Guidimaka",
    stateShort: "10",
    lat: "15.25573310",
    lng: "-12.25479190"
    },
    {
    state: "Hodh Ech Chargui",
    stateShort: "01",
    lat: "18.67370260",
    lng: "-7.09287700"
    },
    {
    state: "Hodh El Gharbi",
    stateShort: "02",
    lat: "16.69121490",
    lng: "-9.54509740"
    },
    {
    state: "Inchiri",
    stateShort: "12",
    lat: "20.02805610",
    lng: "-15.40680790"
    },
    {
    state: "Nouakchott-Nord",
    stateShort: "14",
    lat: "18.11302050",
    lng: "-15.89949560"
    },
    {
    state: "Nouakchott-Ouest",
    stateShort: "13",
    lat: "18.15113570",
    lng: "-15.99349100"
    },
    {
    state: "Nouakchott-Sud",
    stateShort: "15",
    lat: "17.97092880",
    lng: "-15.94648740"
    },
    {
    state: "Tagant",
    stateShort: "09",
    lat: "18.54675270",
    lng: "-9.90181310"
    },
    {
    state: "Tiris Zemmour",
    stateShort: "11",
    lat: "24.57737640",
    lng: "-9.90181310"
    },
    {
    state: "Trarza",
    stateShort: "06",
    lat: "17.86649640",
    lng: "-14.65878210"
    }
    ]
    },
    {
    country: "Mauritius",
    countryShort: "MU",
    currency_symbol: "₨",
    states: [
    {
    state: "Agalega Islands",
    stateShort: "AG",
    lat: "-10.40000000",
    lng: "56.61666670"
    },
    {
    state: "Black River",
    stateShort: "BL",
    lat: "-20.37084920",
    lng: "57.39486490"
    },
    {
    state: "Flacq",
    stateShort: "FL",
    lat: "-20.22578360",
    lng: "57.71192740"
    },
    {
    state: "Grand Port",
    stateShort: "GP",
    lat: "-20.38515460",
    lng: "57.66657420"
    },
    {
    state: "Moka",
    stateShort: "MO",
    lat: "-20.23997820",
    lng: "57.57592600"
    },
    {
    state: "Pamplemousses",
    stateShort: "PA",
    lat: "-20.11360080",
    lng: "57.57592600"
    },
    {
    state: "Plaines Wilhems",
    stateShort: "PW",
    lat: "-20.30548720",
    lng: "57.48535610"
    },
    {
    state: "Port Louis",
    stateShort: "PL",
    lat: "-20.16089120",
    lng: "57.50122220"
    },
    {
    state: "Rivière du Rempart",
    stateShort: "RR",
    lat: "-20.05609830",
    lng: "57.65523890"
    },
    {
    state: "Rodrigues Island",
    stateShort: "RO",
    lat: "-19.72453850",
    lng: "63.42721850"
    },
    {
    state: "Saint Brandon Islands",
    stateShort: "CC",
    lat: "-16.58333300",
    lng: "59.61666700"
    },
    {
    state: "Savanne",
    stateShort: "SA",
    lat: "-20.47395300",
    lng: "57.48535610"
    }
    ]
    },
  
    {
    country: "Mexico",
    countryShort: "MX",
    currency_symbol: "$",
    states: [
    {
    state: "Aguascalientes",
    stateShort: "AGU",
    lat: "21.88525620",
    lng: "-102.29156770"
    },
    {
    state: "Baja California",
    stateShort: "BCN",
    lat: "30.84063380",
    lng: "-115.28375850"
    },
    {
    state: "Baja California Sur",
    stateShort: "BCS",
    lat: "26.04444460",
    lng: "-111.66607250"
    },
    {
    state: "Campeche",
    stateShort: "CAM",
    lat: "19.83012510",
    lng: "-90.53490870"
    },
    {
    state: "Chiapas",
    stateShort: "CHP",
    lat: "16.75693180",
    lng: "-93.12923530"
    },
    {
    state: "Chihuahua",
    stateShort: "CHH",
    lat: "28.63299570",
    lng: "-106.06910040"
    },
    {
    state: "Ciudad de México",
    stateShort: "CDMX",
    lat: "19.43260770",
    lng: "-99.13320800"
    },
    {
    state: "Coahuila de Zaragoza",
    stateShort: "COA",
    lat: "27.05867600",
    lng: "-101.70682940"
    },
    {
    state: "Colima",
    stateShort: "COL",
    lat: "19.24523420",
    lng: "-103.72408680"
    },
    {
    state: "Durango",
    stateShort: "DUR",
    lat: "37.27528000",
    lng: "-107.88006670"
    },
    {
    state: "Estado de México",
    stateShort: "MEX",
    lat: "23.63450100",
    lng: "-102.55278400"
    },
    {
    state: "Guanajuato",
    stateShort: "GUA",
    lat: "21.01901450",
    lng: "-101.25735860"
    },
    {
    state: "Guerrero",
    stateShort: "GRO",
    lat: "17.43919260",
    lng: "-99.54509740"
    },
    {
    state: "Hidalgo",
    stateShort: "HID",
    lat: "26.10035470",
    lng: "-98.26306840"
    },
    {
    state: "Jalisco",
    stateShort: "JAL",
    lat: "20.65953820",
    lng: "-103.34943760"
    },
    {
    state: "Michoacán de Ocampo",
    stateShort: "MIC",
    lat: "19.56651920",
    lng: "-101.70682940"
    },
    {
    state: "Morelos",
    stateShort: "MOR",
    lat: "18.68130490",
    lng: "-99.10134980"
    },
    {
    state: "Nayarit",
    stateShort: "NAY",
    lat: "21.75138440",
    lng: "-104.84546190"
    },
    {
    state: "Nuevo León",
    stateShort: "NLE",
    lat: "25.59217200",
    lng: "-99.99619470"
    },
    {
    state: "Oaxaca",
    stateShort: "OAX",
    lat: "17.07318420",
    lng: "-96.72658890"
    },
    {
    state: "Puebla",
    stateShort: "PUE",
    lat: "19.04143980",
    lng: "-98.20627270"
    },
    {
    state: "Querétaro",
    stateShort: "QUE",
    lat: "20.58879320",
    lng: "-100.38988810"
    },
    {
    state: "Quintana Roo",
    stateShort: "ROO",
    lat: "19.18173930",
    lng: "-88.47913760"
    },
    {
    state: "San Luis Potosí",
    stateShort: "SLP",
    lat: "22.15646990",
    lng: "-100.98554090"
    },
    {
    state: "Sinaloa",
    stateShort: "SIN",
    lat: "25.17210910",
    lng: "-107.47951730"
    },
    {
    state: "Sonora",
    stateShort: "SON",
    lat: "37.98294960",
    lng: "-120.38217240"
    },
    {
    state: "Tabasco",
    stateShort: "TAB",
    lat: "17.84091730",
    lng: "-92.61892730"
    },
    {
    state: "Tamaulipas",
    stateShort: "TAM",
    lat: "24.26694000",
    lng: "-98.83627550"
    },
    {
    state: "Tlaxcala",
    stateShort: "TLA",
    lat: "19.31815400",
    lng: "-98.23749540"
    },
    {
    state: "Veracruz de Ignacio de la Llave",
    stateShort: "VER",
    lat: "19.17377300",
    lng: "-96.13422410"
    },
    {
    state: "Yucatán",
    stateShort: "YUC",
    lat: "20.70987860",
    lng: "-89.09433770"
    },
    {
    state: "Zacatecas",
    stateShort: "ZAC",
    lat: "22.77085550",
    lng: "-102.58324260"
    }
    ]
    },
    {
    country: "Micronesia",
    countryShort: "FM",
    currency_symbol: "$",
    states: [
    {
    state: "Chuuk State",
    stateShort: "TRK",
    lat: "7.13867590",
    lng: "151.55930650"
    },
    {
    state: "Kosrae State",
    stateShort: "KSA",
    lat: "5.30956180",
    lng: "162.98148770"
    },
    {
    state: "Pohnpei State",
    stateShort: "PNI",
    lat: "6.85412540",
    lng: "158.26238220"
    },
    {
    state: "Yap State",
    stateShort: "YAP",
    lat: "8.67164900",
    lng: "142.84393350"
    }
    ]
    },
    {
    country: "Moldova",
    countryShort: "MD",
    currency_symbol: "L",
    states: [
    {
    state: "Anenii Noi District",
    stateShort: "AN",
    lat: "46.87956630",
    lng: "29.23121750"
    },
    {
    state: "Bălți Municipality",
    stateShort: "BA",
    lat: "47.75399470",
    lng: "27.91841480"
    },
    {
    state: "Basarabeasca District",
    stateShort: "BS",
    lat: "46.42370600",
    lng: "28.89354920"
    },
    {
    state: "Bender Municipality",
    stateShort: "BD",
    lat: "46.82275510",
    lng: "29.46201010"
    },
    {
    state: "Briceni District",
    stateShort: "BR",
    lat: "48.36320220",
    lng: "27.07503980"
    },
    {
    state: "Cahul District",
    stateShort: "CA",
    lat: "45.89394040",
    lng: "28.18902750"
    },
    {
    state: "Călărași District",
    stateShort: "CL",
    lat: "47.28694600",
    lng: "28.27453100"
    },
    {
    state: "Cantemir District",
    stateShort: "CT",
    lat: "46.27717420",
    lng: "28.20096530"
    },
    {
    state: "Căușeni District",
    stateShort: "CS",
    lat: "46.65547150",
    lng: "29.40912220"
    },
    {
    state: "Chișinău Municipality",
    stateShort: "CU",
    lat: "47.01045290",
    lng: "28.86381020"
    },
    {
    state: "Cimișlia District",
    stateShort: "CM",
    lat: "46.52508510",
    lng: "28.77218350"
    },
    {
    state: "Criuleni District",
    stateShort: "CR",
    lat: "47.21361140",
    lng: "29.15575190"
    },
    {
    state: "Dondușeni District",
    stateShort: "DO",
    lat: "48.23383050",
    lng: "27.59980870"
    },
    {
    state: "Drochia District",
    stateShort: "DR",
    lat: "48.07977880",
    lng: "27.86041140"
    },
    {
    state: "Dubăsari District",
    stateShort: "DU",
    lat: "47.26439420",
    lng: "29.15503480"
    },
    {
    state: "Edineț District",
    stateShort: "ED",
    lat: "48.16789910",
    lng: "27.29361430"
    },
    {
    state: "Fălești District",
    stateShort: "FA",
    lat: "47.56477250",
    lng: "27.72655930"
    },
    {
    state: "Florești District",
    stateShort: "FL",
    lat: "47.86678490",
    lng: "28.33918640"
    },
    {
    state: "Gagauzia",
    stateShort: "GA",
    lat: "46.09794350",
    lng: "28.63846450"
    },
    {
    state: "Glodeni District",
    stateShort: "GL",
    lat: "47.77901560",
    lng: "27.51680100"
    },
    {
    state: "Hîncești District",
    stateShort: "HI",
    lat: "46.82811470",
    lng: "28.58508890"
    },
    {
    state: "Ialoveni District",
    stateShort: "IA",
    lat: "46.86308600",
    lng: "28.82342180"
    },
    {
    state: "Nisporeni District",
    stateShort: "NI",
    lat: "47.07513490",
    lng: "28.17681550"
    },
    {
    state: "Ocnița District",
    stateShort: "OC",
    lat: "48.41104350",
    lng: "27.47680920"
    },
    {
    state: "Orhei District",
    stateShort: "OR",
    lat: "47.38604000",
    lng: "28.83030820"
    },
    {
    state: "Rezina District",
    stateShort: "RE",
    lat: "47.71804470",
    lng: "28.88710240"
    },
    {
    state: "Rîșcani District",
    stateShort: "RI",
    lat: "47.90701530",
    lng: "27.53749960"
    },
    {
    state: "Sîngerei District",
    stateShort: "SI",
    lat: "47.63891340",
    lng: "28.13718160"
    },
    {
    state: "Șoldănești District",
    stateShort: "SD",
    lat: "47.81473890",
    lng: "28.78895860"
    },
    {
    state: "Soroca District",
    stateShort: "SO",
    lat: "48.15497430",
    lng: "28.28707830"
    },
    {
    state: "Ștefan Vodă District",
    stateShort: "SV",
    lat: "46.55404880",
    lng: "29.70224200"
    },
    {
    state: "Strășeni District",
    stateShort: "ST",
    lat: "47.14502670",
    lng: "28.61367360"
    },
    {
    state: "Taraclia District",
    stateShort: "TA",
    lat: "45.89865100",
    lng: "28.66716440"
    },
    {
    state: "Telenești District",
    stateShort: "TE",
    lat: "47.49839620",
    lng: "28.36760190"
    },
    {
    state: "Transnistria autonomous territorial unit",
    stateShort: "SN",
    lat: "47.21529720",
    lng: "29.46380540"
    },
    {
    state: "Ungheni District",
    stateShort: "UN",
    lat: "47.23057670",
    lng: "27.78926610"
    }
    ]
    },
    {
    country: "Monaco",
    countryShort: "MC",
    currency_symbol: "€",
    states: [
    {
    state: "La Colle",
    stateShort: "CL",
    lat: "43.73274650",
    lng: "7.41372760"
    },
    {
    state: "La Condamine",
    stateShort: "CO",
    lat: "43.73506650",
    lng: "7.41990600"
    },
    {
    state: "Moneghetti",
    stateShort: "MG",
    lat: "43.73649270",
    lng: "7.41533830"
    },
    {
      "state": "Monaco-Ville",
      
        lat: 43.73083,
        lng: 7.42389,
         stateShort: "MV",
      "description": "The historical and administrative center, featuring landmarks such as the Prince's Palace, the Monaco Cathedral, and the Oceanographic Museum. It retains a medieval village charm with its narrow, winding streets."
    },
    {
      "state": "Monte Carlo",
      
        lat: 43.7396,
        lng: 7.4272,
         stateShort: "MC",
      "description": "Known for its luxurious lifestyle, the Monte Carlo Casino, and the annual Formula 1 Grand Prix. It is a hub of glamour with high-end shops, hotels, and restaurants."
    },
    {
      "state": "La Condamine",
      
        lat: 43.7342,
        lng: 7.4206,
         stateShort: "LC",
      "description": "Located around the Port Hercules, La Condamine is Monaco’s commercial district. It hosts the Condamine Market and offers a variety of shops and eateries."
    },
    {
      "state": "Fontvieille",
      
        lat: 43.7260,
        lng: 7.4150,
         stateShort: "FV",
      "description": "A relatively newer district built on reclaimed land, Fontvieille combines modern living with nature. It features the Princess Grace Rose Garden, the Stade Louis II stadium, and a mix of residential and commercial buildings."
    }
    ]
    },
    {
    country: "Mongolia",
    countryShort: "MN",
    currency_symbol: "₮",
    states: [
    {
    state: "Arkhangai Province",
    stateShort: "073",
    lat: "47.89711010",
    lng: "100.72401650"
    },
    {
    state: "Bayan-Ölgii Province",
    stateShort: "071",
    lat: "48.39832540",
    lng: "89.66259150"
    },
    {
    state: "Bayankhongor Province",
    stateShort: "069",
    lat: "45.15267070",
    lng: "100.10736670"
    },
    {
    state: "Bulgan Province",
    stateShort: "067",
    lat: "48.96909130",
    lng: "102.88317230"
    },
    {
    state: "Darkhan-Uul Province",
    stateShort: "037",
    lat: "49.46484340",
    lng: "105.97459190"
    },
    {
    state: "Dornod Province",
    stateShort: "061",
    lat: "47.46581540",
    lng: "115.39271200"
    },
    {
    state: "Dornogovi Province",
    stateShort: "063",
    lat: "43.96538890",
    lng: "109.17734590"
    },
    {
    state: "Dundgovi Province",
    stateShort: "059",
    lat: "45.58227860",
    lng: "106.76442090"
    },
    {
    state: "Govi-Altai Province",
    stateShort: "065",
    lat: "45.45112270",
    lng: "95.85057660"
    },
    {
    state: "Govisümber Province",
    stateShort: "064",
    lat: "46.47627540",
    lng: "108.55706270"
    },
    {
    state: "Khentii Province",
    stateShort: "039",
    lat: "47.60812090",
    lng: "109.93728560"
    },
    {
    state: "Khovd Province",
    stateShort: "043",
    lat: "47.11296540",
    lng: "92.31107520"
    },
    {
    state: "Khövsgöl Province",
    stateShort: "041",
    lat: "50.22044840",
    lng: "100.32137680"
    },
    {
    state: "Ömnögovi Province",
    stateShort: "053",
    lat: "43.50002400",
    lng: "104.28611160"
    },
    {
    state: "Orkhon Province",
    stateShort: "035",
    lat: "49.00470500",
    lng: "104.30165270"
    },
    {
    state: "Övörkhangai Province",
    stateShort: "055",
    lat: "45.76243920",
    lng: "103.09170320"
    },
    {
    state: "Selenge Province",
    stateShort: "049",
    lat: "50.00592730",
    lng: "106.44341080"
    },
    {
    state: "Sükhbaatar Province",
    stateShort: "051",
    lat: "46.56531630",
    lng: "113.53808360"
    },
    {
    state: "Töv Province",
    stateShort: "047",
    lat: "47.21240560",
    lng: "106.41541000"
    },
    {
    state: "Uvs Province",
    stateShort: "046",
    lat: "49.64497070",
    lng: "93.27365760"
    },
    {
    state: "Zavkhan Province",
    stateShort: "057",
    lat: "48.23881470",
    lng: "96.07030190"
    }
    ]
    },
    {
    country: "Montenegro",
    countryShort: "ME",
    currency_symbol: "€",
    states: [
    {
    state: "Andrijevica Municipality",
    stateShort: "01",
    lat: "42.73624770",
    lng: "19.78595560"
    },
    {
    state: "Bar Municipality",
    stateShort: "02",
    lat: "42.12781190",
    lng: "19.14043800"
    },
    {
    state: "Berane Municipality",
    stateShort: "03",
    lat: "42.82572890",
    lng: "19.90205090"
    },
    {
    state: "Bijelo Polje Municipality",
    stateShort: "04",
    lat: "43.08465260",
    lng: "19.71154720"
    },
    {
    state: "Budva Municipality",
    stateShort: "05",
    lat: "42.31407200",
    lng: "18.83138320"
    },
    {
    state: "Danilovgrad Municipality",
    stateShort: "07",
    lat: "42.58357000",
    lng: "19.14043800"
    },
    {
    state: "Gusinje Municipality",
    stateShort: "22",
    lat: "42.55634550",
    lng: "19.83060510"
    },
    {
    state: "Kolašin Municipality",
    stateShort: "09",
    lat: "42.76019160",
    lng: "19.42591140"
    },
    {
    state: "Kotor Municipality",
    stateShort: "10",
    lat: "42.57402610",
    lng: "18.64131450"
    },
    {
    state: "Mojkovac Municipality",
    stateShort: "11",
    lat: "42.96880180",
    lng: "19.52110630"
    },
    {
    state: "Nikšić Municipality",
    stateShort: "12",
    lat: "42.79971840",
    lng: "18.76009630"
    },
    {
    state: "Old Royal Capital Cetinje",
    stateShort: "06",
    lat: "42.39309590",
    lng: "18.91159640"
    },
    {
    state: "Petnjica Municipality",
    stateShort: "23",
    lat: "42.93534800",
    lng: "20.02114490"
    },
    {
    state: "Plav Municipality",
    stateShort: "13",
    lat: "42.60013370",
    lng: "19.94075410"
    },
    {
    state: "Pljevlja Municipality",
    stateShort: "14",
    lat: "43.27233830",
    lng: "19.28315310"
    },
    {
    state: "Plužine Municipality",
    stateShort: "15",
    lat: "43.15933840",
    lng: "18.85514840"
    },
    {
    state: "Podgorica Municipality",
    stateShort: "16",
    lat: "42.36938340",
    lng: "19.28315310"
    },
    {
    state: "Rožaje Municipality",
    stateShort: "17",
    lat: "42.84083890",
    lng: "20.16706280"
    },
    {
    state: "Šavnik Municipality",
    stateShort: "18",
    lat: "42.96037560",
    lng: "19.14043800"
    },
    {
    state: "Tivat Municipality",
    stateShort: "19",
    lat: "42.42348000",
    lng: "18.71851840"
    },
    {
    state: "Ulcinj Municipality",
    stateShort: "20",
    lat: "41.96527950",
    lng: "19.30694320"
    },
    {
    state: "Žabljak Municipality",
    stateShort: "21",
    lat: "43.15551520",
    lng: "19.12260180"
    }
    ]
    },
 
    {
    country: "Morocco",
    countryShort: "MA",
    currency_symbol: "DH",
    states: [
    {
    state: "Agadir-Ida-Ou-Tanane",
    stateShort: "AGD",
    lat: "30.64620910",
    lng: "-9.83390610"
    },
    {
    state: "Al Haouz",
    stateShort: "HAO",
    lat: "31.29567290",
    lng: "-7.87216000"
    },
    {
    state: "Al Hoceïma",
    stateShort: "HOC",
    lat: "35.24455890",
    lng: "-3.93174680"
    },
    {
    state: "Aousserd (EH)",
    stateShort: "AOU",
    lat: "22.55215380",
    lng: "-14.32973530"
    },
    {
    state: "Assa-Zag (EH-partial)",
    stateShort: "ASZ",
    lat: "28.14023950",
    lng: "-9.72326730"
    },
    {
    state: "Azilal",
    stateShort: "AZI",
    lat: "32.00426200",
    lng: "-6.57833870"
    },
    {
    state: "Béni Mellal",
    stateShort: "BEM",
    lat: "32.34244300",
    lng: "-6.37579900"
    },
    {
    state: "Béni Mellal-Khénifra",
    stateShort: "05",
    lat: "32.57191840",
    lng: "-6.06791940"
    },
    {
    state: "Benslimane",
    stateShort: "BES",
    lat: "33.61896980",
    lng: "-7.13055360"
    },
    {
    state: "Berkane",
    stateShort: "BER",
    lat: "34.88408760",
    lng: "-2.34188700"
    },
    {
    state: "Berrechid",
    stateShort: "BRR",
    lat: "33.26025230",
    lng: "-7.59848370"
    },
    {
    state: "Boujdour (EH)",
    stateShort: "BOD",
    lat: "26.12524930",
    lng: "-14.48473470"
    },
    {
    state: "Boulemane",
    stateShort: "BOM",
    lat: "33.36251590",
    lng: "-4.73033970"
    },
    {
    state: "Casablanca",
    stateShort: "CAS",
    lat: "33.57226780",
    lng: "-7.65703260"
    },
    {
    state: "Casablanca-Settat",
    stateShort: "06",
    lat: "33.21608720",
    lng: "-7.43813550"
    },
    {
    state: "Chefchaouen",
    stateShort: "CHE",
    lat: "35.01817200",
    lng: "-5.14320680"
    },
    {
    state: "Chichaoua",
    stateShort: "CHI",
    lat: "31.53835810",
    lng: "-8.76463880"
    },
    {
    state: "Chtouka-Ait Baha",
    stateShort: "CHT",
    lat: "30.10724220",
    lng: "-9.27855830"
    },
    {
    state: "Dakhla-Oued Ed-Dahab (EH)",
    stateShort: "12",
    lat: "22.73378920",
    lng: "-14.28611160"
    },
    {
    state: "Drâa-Tafilalet",
    stateShort: "08",
    lat: "31.14995380",
    lng: "-5.39395510"
    },
    {
    state: "Driouch",
    stateShort: "DRI",
    lat: "34.97603200",
    lng: "-3.39644930"
    },
    {
    state: "El Hajeb",
    stateShort: "HAJ",
    lat: "33.68573500",
    lng: "-5.36778440"
    },
    {
    state: "El Jadida",
    stateShort: "JDI",
    lat: "33.23163260",
    lng: "-8.50071160"
    },
    {
    state: "El Kelâa des Sraghna",
    stateShort: "KES",
    lat: "32.05227670",
    lng: "-7.35165580"
    },
    {
    state: "Errachidia",
    stateShort: "ERR",
    lat: "31.90512750",
    lng: "-4.72775280"
    },
    {
    state: "Es-Semara (EH-partial)",
    stateShort: "ESM",
    lat: "26.74185600",
    lng: "-11.67836710"
    },
    {
    state: "Essaouira",
    stateShort: "ESI",
    lat: "31.50849260",
    lng: "-9.75950410"
    },
    {
    state: "Fahs-Anjra",
    stateShort: "FAH",
    lat: "35.76019920",
    lng: "-5.66683060"
    },
    {
    state: "Fès",
    stateShort: "FES",
    lat: "34.02395790",
    lng: "-5.03675990"
    },
    {
    state: "Fès-Meknès",
    stateShort: "03",
    lat: "34.06252900",
    lng: "-4.72775280"
    },
    {
    state: "Figuig",
    stateShort: "FIG",
    lat: "32.10926130",
    lng: "-1.22980600"
    },
    {
    state: "Fquih Ben Salah",
    stateShort: "FQH",
    lat: "32.50016800",
    lng: "-6.71007170"
    },
    {
    state: "Guelmim",
    stateShort: "GUE",
    lat: "28.98836590",
    lng: "-10.05274980"
    },
    {
    state: "Guelmim-Oued Noun (EH-partial)",
    stateShort: "10",
    lat: "28.48442810",
    lng: "-10.08072980"
    },
    {
    state: "Guercif",
    stateShort: "GUF",
    lat: "34.23450360",
    lng: "-3.38130050"
    },
    {
    state: "Ifrane",
    stateShort: "IFR",
    lat: "33.52280620",
    lng: "-5.11095520"
    },
    {
    state: "Inezgane-Ait Melloul",
    stateShort: "INE",
    lat: "30.35090980",
    lng: "-9.38951100"
    },
    {
    state: "Jerada",
    stateShort: "JRA",
    lat: "34.30617910",
    lng: "-2.17941360"
    },
    {
    state: "Kénitra",
    stateShort: "KEN",
    lat: "34.25405030",
    lng: "-6.58901660"
    },
    {
    state: "Khémisset",
    stateShort: "KHE",
    lat: "33.81537040",
    lng: "-6.05733020"
    },
    {
    state: "Khénifra",
    stateShort: "KHN",
    lat: "32.93404710",
    lng: "-5.66157100"
    },
    {
    state: "Khouribga",
    stateShort: "KHO",
    lat: "32.88602300",
    lng: "-6.92086550"
    },
    {
    state: "L'Oriental",
    stateShort: "02",
    lat: "37.06968300",
    lng: "-94.51227700"
    },
    {
    state: "Laâyoune (EH)",
    stateShort: "LAA",
    lat: "27.15003840",
    lng: "-13.19907580"
    },
    {
    state: "Laâyoune-Sakia El Hamra (EH-partial)",
    stateShort: "11",
    lat: "27.86831940",
    lng: "-11.98046130"
    },
    {
    state: "Larache",
    stateShort: "LAR",
    lat: "35.17442710",
    lng: "-6.14739640"
    },
    {
    state: "M’diq-Fnideq",
    stateShort: "MDF",
    lat: "35.77330190",
    lng: "-5.51433000"
    },
    {
    state: "Marrakech",
    stateShort: "MAR",
    lat: "31.63460230",
    lng: "-8.07789320"
    },
    {
    state: "Marrakesh-Safi",
    stateShort: "07",
    lat: "31.73308330",
    lng: "-8.13385580"
    },
    {
    state: "Médiouna",
    stateShort: "MED",
    lat: "33.45409390",
    lng: "-7.51660200"
    },
    {
    state: "Meknès",
    stateShort: "MEK",
    lat: "33.88100000",
    lng: "-5.57303970"
    },
    {
    state: "Midelt",
    stateShort: "MID",
    lat: "32.68550790",
    lng: "-4.75017090"
    },
    {
    state: "Mohammadia",
    stateShort: "MOH",
    lat: "33.68737490",
    lng: "-7.42391420"
    },
    {
    state: "Moulay Yacoub",
    stateShort: "MOU",
    lat: "34.08744790",
    lng: "-5.17840190"
    },
    {
    state: "Nador",
    stateShort: "NAD",
    lat: "34.91719260",
    lng: "-2.85771050"
    },
    {
    state: "Nouaceur",
    stateShort: "NOU",
    lat: "33.36703930",
    lng: "-7.57325370"
    },
    {
    state: "Ouarzazate",
    stateShort: "OUA",
    lat: "30.93354360",
    lng: "-6.93701600"
    },
    {
    state: "Oued Ed-Dahab (EH)",
    stateShort: "OUD",
    lat: "22.73378920",
    lng: "-14.28611160"
    },
    {
    state: "Ouezzane",
    stateShort: "OUZ",
    lat: "34.80634500",
    lng: "-5.59145050"
    },
    {
    state: "Oujda-Angad",
    stateShort: "OUJ",
    lat: "34.68375040",
    lng: "-2.29932390"
    },
    {
    state: "Rabat",
    stateShort: "RAB",
    lat: "33.96919900",
    lng: "-6.92730290"
    },
    {
    state: "Rabat-Salé-Kénitra",
    stateShort: "04",
    lat: "34.07686400",
    lng: "-7.34544760"
    },
    {
    state: "Rehamna",
    stateShort: "REH",
    lat: "32.20329050",
    lng: "-8.56896710"
    },
    {
    state: "Safi",
    stateShort: "SAF",
    lat: "32.29898720",
    lng: "-9.10134980"
    },
    {
    state: "Salé",
    stateShort: "SAL",
    lat: "34.03775700",
    lng: "-6.84270730"
    },
    {
    state: "Sefrou",
    stateShort: "SEF",
    lat: "33.83052440",
    lng: "-4.83531540"
    },
    {
    state: "Settat",
    stateShort: "SET",
    lat: "32.99242420",
    lng: "-7.62226650"
    },
    {
    state: "Sidi Bennour",
    stateShort: "SIB",
    lat: "32.64926020",
    lng: "-8.44714530"
    },
    {
    state: "Sidi Ifni",
    stateShort: "SIF",
    lat: "29.36657970",
    lng: "-10.21084850"
    },
    {
    state: "Sidi Kacem",
    stateShort: "SIK",
    lat: "34.22601720",
    lng: "-5.71291640"
    },
    {
    state: "Sidi Slimane",
    stateShort: "SIL",
    lat: "34.27378280",
    lng: "-5.98059720"
    },
    {
    state: "Skhirate-Témara",
    stateShort: "SKH",
    lat: "33.76224250",
    lng: "-7.04190520"
    },
    {
    state: "Souss-Massa",
    stateShort: "09",
    lat: "30.27506110",
    lng: "-8.13385580"
    },
    {
    state: "Tan-Tan (EH-partial)",
    stateShort: "TNT",
    lat: "28.03012000",
    lng: "-11.16173560"
    },
    {
    state: "Tanger-Assilah",
    stateShort: "TNG",
    lat: "35.76325390",
    lng: "-5.90450980"
    },
    {
    state: "Tanger-Tétouan-Al Hoceïma",
    stateShort: "01",
    lat: "35.26295580",
    lng: "-5.56172790"
    },
    {
    state: "Taounate",
    stateShort: "TAO",
    lat: "34.53691700",
    lng: "-4.63986930"
    },
    {
    state: "Taourirt",
    stateShort: "TAI",
    lat: "34.21259800",
    lng: "-2.69838680"
    },
    {
    state: "Tarfaya (EH-partial)",
    stateShort: "TAF",
    lat: "27.93777010",
    lng: "-12.92940630"
    },
    {
    state: "Taroudannt",
    stateShort: "TAR",
    lat: "30.47271260",
    lng: "-8.87487650"
    },
    {
    state: "Tata",
    stateShort: "TAT",
    lat: "29.75087700",
    lng: "-7.97563430"
    },
    {
    state: "Taza",
    stateShort: "TAZ",
    lat: "34.27889530",
    lng: "-3.58126920"
    },
    {
    state: "Tétouan",
    stateShort: "TET",
    lat: "35.58889950",
    lng: "-5.36255160"
    },
    {
    state: "Tinghir",
    stateShort: "TIN",
    lat: "31.48507940",
    lng: "-6.20192980"
    },
    {
    state: "Tiznit",
    stateShort: "TIZ",
    lat: "29.69339200",
    lng: "-9.73215700"
    },
    {
    state: "Youssoufia",
    stateShort: "YUS",
    lat: "32.02006790",
    lng: "-8.86926480"
    },
    {
    state: "Zagora",
    stateShort: "ZAG",
    lat: "30.57860930",
    lng: "-5.89871390"
    }
    ]
    },
    {
    country: "Mozambique",
    countryShort: "MZ",
    currency_symbol: "MT",
    states: [
    {
    state: "Cabo Delgado Province",
    stateShort: "P",
    lat: "-12.33354740",
    lng: "39.32062410"
    },
    {
    state: "Gaza Province",
    stateShort: "G",
    lat: "-23.02219280",
    lng: "32.71813750"
    },
    {
    state: "Inhambane Province",
    stateShort: "I",
    lat: "-22.85279970",
    lng: "34.55087580"
    },
    {
    state: "Manica Province",
    stateShort: "B",
    lat: "-19.50597870",
    lng: "33.43835300"
    },
    {
    state: "Maputo",
    stateShort: "MPM",
    lat: "-25.96924800",
    lng: "32.57317460"
    },
    {
    state: "Maputo Province",
    stateShort: "L",
    lat: "-25.25698760",
    lng: "32.53727410"
    },
    {
    state: "Nampula Province",
    stateShort: "N",
    lat: "-14.76049310",
    lng: "39.32062410"
    },
    {
    state: "Niassa Province",
    stateShort: "A",
    lat: "-12.78262020",
    lng: "36.60939260"
    },
    {
    state: "Sofala Province",
    stateShort: "S",
    lat: "-19.20390730",
    lng: "34.86241660"
    },
    {
    state: "Tete Province",
    stateShort: "T",
    lat: "-15.65960560",
    lng: "32.71813750"
    },
    {
    state: "Zambezia Province",
    stateShort: "Q",
    lat: "-16.56389870",
    lng: "36.60939260"
    }
    ]
    },
    {
    country: "Myanmar",
    countryShort: "MM",
    currency_symbol: "K",
    states: [
    {
    state: "Ayeyarwady Region",
    stateShort: "07",
    lat: "17.03421250",
    lng: "95.22666750"
    },
    {
    state: "Bago",
    stateShort: "02",
    lat: "17.32207110",
    lng: "96.46632860"
    },
    {
    state: "Chin State",
    stateShort: "14",
    lat: "22.00869780",
    lng: "93.58126920"
    },
    {
    state: "Kachin State",
    stateShort: "11",
    lat: "25.85090400",
    lng: "97.43813550"
    },
    {
    state: "Kayah State",
    stateShort: "12",
    lat: "19.23420610",
    lng: "97.26528580"
    },
    {
    state: "Kayin State",
    stateShort: "13",
    lat: "16.94593460",
    lng: "97.95928630"
    },
    {
    state: "Magway Region",
    stateShort: "03",
    lat: "19.88713860",
    lng: "94.72775280"
    },
    {
    state: "Mandalay Region",
    stateShort: "04",
    lat: "21.56190580",
    lng: "95.89871390"
    },
    {
    state: "Mon State",
    stateShort: "15",
    lat: "16.30031330",
    lng: "97.69822720"
    },
    {
    state: "Naypyidaw Union Territory",
    stateShort: "18",
    lat: "19.93862450",
    lng: "96.15269850"
    },
    {
    state: "Rakhine State",
    stateShort: "16",
    lat: "20.10408180",
    lng: "93.58126920"
    },
    {
    state: "Sagaing Region",
    stateShort: "01",
    lat: "24.42838100",
    lng: "95.39395510"
    },
    {
    state: "Shan State",
    stateShort: "17",
    lat: "22.03619850",
    lng: "98.13385580"
    },
    {
    state: "Tanintharyi Region",
    stateShort: "05",
    lat: "12.47068760",
    lng: "99.01289260"
    },
    {
    state: "Yangon Region",
    stateShort: "06",
    lat: "16.91434880",
    lng: "96.15269850"
    }
    ]
    },
    {
    country: "Namibia",
    countryShort: "NA",
    currency_symbol: "$",
    states: [
    {
    state: "Erongo Region",
    stateShort: "ER",
    lat: "-22.25656820",
    lng: "15.40680790"
    },
    {
    state: "Hardap Region",
    stateShort: "HA",
    lat: "-24.23101340",
    lng: "17.66888700"
    },
    {
    state: "Karas Region",
    stateShort: "KA",
    lat: "-26.84296450",
    lng: "17.29028390"
    },
    {
    state: "Kavango East Region",
    stateShort: "KE",
    lat: "-18.27104800",
    lng: "18.42760470"
    },
    {
    state: "Kavango West Region",
    stateShort: "KW",
    lat: "-18.27104800",
    lng: "18.42760470"
    },
    {
    state: "Khomas Region",
    stateShort: "KH",
    lat: "-22.63778540",
    lng: "17.10119310"
    },
    {
    state: "Kunene Region",
    stateShort: "KU",
    lat: "-19.40863170",
    lng: "13.91439900"
    },
    {
    state: "Ohangwena Region",
    stateShort: "OW",
    lat: "-17.59792910",
    lng: "16.81783770"
    },
    {
    state: "Omaheke Region",
    stateShort: "OH",
    lat: "-21.84666510",
    lng: "19.18800470"
    },
    {
    state: "Omusati Region",
    stateShort: "OS",
    lat: "-18.40702940",
    lng: "14.84546190"
    },
    {
    state: "Oshana Region",
    stateShort: "ON",
    lat: "-18.43050640",
    lng: "15.68817880"
    },
    {
    state: "Oshikoto Region",
    stateShort: "OT",
    lat: "-18.41525750",
    lng: "16.91225100"
    },
    {
    state: "Otjozondjupa Region",
    stateShort: "OD",
    lat: "-20.54869160",
    lng: "17.66888700"
    },
    {
    state: "Zambezi Region",
    stateShort: "CA",
    lat: "-17.81934190",
    lng: "23.95364660"
    }
    ]
    },
    {
    country: "Nauru",
    countryShort: "NR",
    currency_symbol: "$",
    states: [
    {
    state: "Aiwo District",
    stateShort: "01",
    lat: "-0.53400120",
    lng: "166.91388730"
    },
    {
    state: "Anabar District",
    stateShort: "02",
    lat: "-0.51335170",
    lng: "166.94846240"
    },
    {
    state: "Anetan District",
    stateShort: "03",
    lat: "-0.50643430",
    lng: "166.94270060"
    },
    {
    state: "Anibare District",
    stateShort: "04",
    lat: "-0.52947580",
    lng: "166.95134320"
    },
    {
    state: "Baiti District",
    stateShort: "05",
    lat: "-0.51043100",
    lng: "166.92757440"
    },
    {
    state: "Boe District",
    stateShort: "06",
    lat: "39.07327760",
    lng: "-94.57104980"
    },
    {
    state: "Buada District",
    stateShort: "07",
    lat: "-0.53287770",
    lng: "166.92685410"
    },
    {
    state: "Denigomodu District",
    stateShort: "08",
    lat: "-0.52479640",
    lng: "166.91676890"
    },
    {
    state: "Ewa District",
    stateShort: "09",
    lat: "-0.50872410",
    lng: "166.93693840"
    },
    {
    state: "Ijuw District",
    stateShort: "10",
    lat: "-0.52027670",
    lng: "166.95710460"
    },
    {
    state: "Meneng District",
    stateShort: "11",
    lat: "-0.54672400",
    lng: "166.93837900"
    },
    {
    state: "Nibok District",
    stateShort: "12",
    lat: "-0.51962080",
    lng: "166.91893010"
    },
    {
    state: "Uaboe District",
    stateShort: "13",
    lat: "-0.52022220",
    lng: "166.93117610"
    },
    {
    state: "Yaren District",
    stateShort: "14",
    lat: "-0.54668570",
    lng: "166.92109130"
    }
    ]
    },
    {
    country: "Nepal",
    countryShort: "NP",
    currency_symbol: "₨",
    states: [
    {
    state: "Bagmati Zone",
    stateShort: "BA",
    lat: "28.03675770",
    lng: "85.43755740"
    },
    {
    state: "Bheri Zone",
    stateShort: "BH",
    lat: "28.51745600",
    lng: "81.77870210"
    },
    {
    state: "Central Region",
    stateShort: "1",
    lat: null,
    lng: null
    },
    {
    state: "Dhaulagiri Zone",
    stateShort: "DH",
    lat: "28.61117600",
    lng: "83.50702030"
    },
    {
    state: "Eastern Development Region",
    stateShort: "4",
    lat: "27.33090720",
    lng: "87.06242610"
    },
    {
    state: "Far-Western Development Region",
    stateShort: "5",
    lat: "29.29878710",
    lng: "80.98710740"
    },
    {
    state: "Gandaki Zone",
    stateShort: "GA",
    lat: "28.37320370",
    lng: "84.43827210"
    },
    {
    state: "Janakpur Zone",
    stateShort: "JA",
    lat: "27.21108990",
    lng: "86.01215730"
    },
    {
    state: "Karnali Zone",
    stateShort: "KA",
    lat: "29.38625550",
    lng: "82.38857830"
    },
    {
    state: "Kosi Zone",
    stateShort: "KO",
    lat: "27.05365240",
    lng: "87.30161320"
    },
    {
    state: "Lumbini Zone",
    stateShort: "LU",
    lat: "27.45000000",
    lng: "83.25000000"
    },
    {
    state: "Mahakali Zone",
    stateShort: "MA",
    lat: "29.36010790",
    lng: "80.54384500"
    },
    {
    state: "Mechi Zone",
    stateShort: "ME",
    lat: "26.87600070",
    lng: "87.93348030"
    },
    {
    state: "Mid-Western Region",
    stateShort: "2",
    lat: "38.41118410",
    lng: "-90.38320980"
    },
    {
    state: "Narayani Zone",
    stateShort: "NA",
    lat: "27.36117660",
    lng: "84.85679320"
    },
    {
    state: "Rapti Zone",
    stateShort: "RA",
    lat: "28.27434700",
    lng: "82.38857830"
    },
    {
    state: "Sagarmatha Zone",
    stateShort: "SA",
    lat: "27.32382630",
    lng: "86.74163740"
    },
    {
    state: "Seti Zone",
    stateShort: "SE",
    lat: "29.69054270",
    lng: "81.33994140"
    },
    {
    state: "Western Region",
    stateShort: "3",
    lat: null,
    lng: null
    }
    ]
    },
    {
    country: "Netherlands",
    countryShort: "NL",
    currency_symbol: "€",
    states: [
    {
    state: "Bonaire",
    stateShort: "BQ1",
    lat: "12.20189020",
    lng: "-68.26238220"
    },
    {
    state: "Drenthe",
    stateShort: "DR",
    lat: "52.94760120",
    lng: "6.62305860"
    },
    {
    state: "Flevoland",
    stateShort: "FL",
    lat: "52.52797810",
    lng: "5.59535080"
    },
    {
    state: "Friesland",
    stateShort: "FR",
    lat: "53.16416420",
    lng: "5.78175420"
    },
    {
    state: "Gelderland",
    stateShort: "GE",
    lat: "52.04515500",
    lng: "5.87182350"
    },
    {
    state: "Groningen",
    stateShort: "GR",
    lat: "53.21938350",
    lng: "6.56650170"
    },
    {
    state: "Limburg",
    stateShort: "LI",
    lat: "51.44272380",
    lng: "6.06087260"
    },
    {
    state: "Noord-Brabant",
    stateShort: "NB",
    lat: "51.48265370",
    lng: "5.23216870"
    },
    {
    state: "Noord-Holland",
    stateShort: "NH",
    lat: "52.52058690",
    lng: "4.78847400"
    },
    {
    state: "Overijssel",
    stateShort: "OV",
    lat: "52.43878140",
    lng: "6.50164110"
    },
    {
    state: "Saba",
    stateShort: "BQ2",
    lat: "17.63546420",
    lng: "-63.23267630"
    },
    {
    state: "Sint Eustatius",
    stateShort: "BQ3",
    lat: "17.48903060",
    lng: "-62.97355500"
    },
    {
    state: "Zuid-Holland",
    stateShort: "ZH",
    lat: "41.60086810",
    lng: "-87.60698940"
    },
    {
    state: "Utrecht",
    stateShort: "UT",
    lat: "52.09073740",
    lng: "5.12142010"
    },
    {
    state: "Zeeland",
    stateShort: "ZE",
    lat: "51.49403090",
    lng: "3.84968150"
    }
    ]
    },

    {
    country: "New Zealand",
    countryShort: "NZ",
    currency_symbol: "$",
    states: [
    {
    state: "Auckland",
    stateShort: "AUK",
    lat: "-36.66753280",
    lng: "174.77333250"
    },
    {
    state: "Bay of Plenty",
    stateShort: "BOP",
    lat: "-37.42339170",
    lng: "176.74163740"
    },
    {
    state: "Canterbury",
    stateShort: "CAN",
    lat: "-43.75422750",
    lng: "171.16372450"
    },
    {
    state: "Chatham Islands",
    stateShort: "CIT",
    lat: "-44.00575230",
    lng: "-176.54006740"
    },
    {
    state: "Gisborne",
    stateShort: "GIS",
    lat: "-38.13581740",
    lng: "178.32393090"
    },
    {
    state: "Hawke's Bay",
    stateShort: "HKB",
    lat: "-39.60165970",
    lng: "176.58044730"
    },
    {
    state: "Manawatu-Wanganui",
    stateShort: "MWT",
    lat: "-39.72733560",
    lng: "175.43755740"
    },
    {
    state: "Marlborough",
    stateShort: "MBH",
    lat: "-41.59168830",
    lng: "173.76240530"
    },
    {
    state: "Nelson",
    stateShort: "NSN",
    lat: "-41.29853970",
    lng: "173.24414910"
    },
    {
    state: "Northland",
    stateShort: "NTL",
    lat: "-35.41361720",
    lng: "173.93208060"
    },
    {
    state: "Otago",
    stateShort: "OTA",
    lat: "-45.47906710",
    lng: "170.15475670"
    },
    {
    state: "Southland",
    stateShort: "STL",
    lat: "-45.84891590",
    lng: "167.67553870"
    },
    {
    state: "Taranaki",
    stateShort: "TKI",
    lat: "-39.35381490",
    lng: "174.43827210"
    },
    {
    state: "Tasman",
    stateShort: "TAS",
    lat: "-41.45711840",
    lng: "172.82097400"
    },
    {
    state: "Waikato",
    stateShort: "WKO",
    lat: "-37.61908620",
    lng: "175.02334600"
    },
    {
    state: "Wellington",
    stateShort: "WGN",
    lat: "-41.02993230",
    lng: "175.43755740"
    },
    {
    state: "West Coast",
    stateShort: "WTC",
    lat: "62.41136340",
    lng: "-149.07297140"
    }
    ]
    },
    {
    country: "Nicaragua",
    countryShort: "NI",
    currency_symbol: "C$",
    states: [
    {
    state: "Boaco",
    stateShort: "BO",
    lat: "12.46928400",
    lng: "-85.66146820"
    },
    {
    state: "Carazo",
    stateShort: "CA",
    lat: "11.72747290",
    lng: "-86.21584970"
    },
    {
    state: "Chinandega",
    stateShort: "CI",
    lat: "12.88200620",
    lng: "-87.14228950"
    },
    {
    state: "Chontales",
    stateShort: "CO",
    lat: "11.93947170",
    lng: "-85.18940450"
    },
    {
    state: "Estelí",
    stateShort: "ES",
    lat: "13.08511390",
    lng: "-86.36301970"
    },
    {
    state: "Granada",
    stateShort: "GR",
    lat: "11.93440730",
    lng: "-85.95600050"
    },
    {
    state: "Jinotega",
    stateShort: "JI",
    lat: "13.08839070",
    lng: "-85.99939970"
    },
    {
    state: "León",
    stateShort: "LE",
    lat: "12.50920370",
    lng: "-86.66110830"
    },
    {
    state: "Madriz",
    stateShort: "MD",
    lat: "13.47260050",
    lng: "-86.45920910"
    },
    {
    state: "Managua",
    stateShort: "MN",
    lat: "12.13916990",
    lng: "-86.33767610"
    },
    {
    state: "Masaya",
    stateShort: "MS",
    lat: "11.97593280",
    lng: "-86.07334980"
    },
    {
    state: "Matagalpa",
    stateShort: "MT",
    lat: "12.94984360",
    lng: "-85.43755740"
    },
    {
    state: "North Caribbean Coast",
    stateShort: "AN",
    lat: "13.83944560",
    lng: "-83.93208060"
    },
    {
    state: "Nueva Segovia",
    stateShort: "NS",
    lat: "13.76570610",
    lng: "-86.53700390"
    },
    {
    state: "Río San Juan",
    stateShort: "SJ",
    lat: "11.47816100",
    lng: "-84.77333250"
    },
    {
    state: "Rivas",
    stateShort: "RI",
    lat: "11.40234900",
    lng: "-85.68457800"
    },
    {
    state: "South Caribbean Coast",
    stateShort: "AS",
    lat: "12.19185020",
    lng: "-84.10128610"
    }
    ]
    },
    {
    country: "Niger",
    countryShort: "NE",
    currency_symbol: "CFA",
    states: [
    {
    state: "Agadez Region",
    stateShort: "1",
    lat: "20.66707520",
    lng: "12.07182810"
    },
    {
    state: "Diffa Region",
    stateShort: "2",
    lat: "13.67686470",
    lng: "12.71351210"
    },
    {
    state: "Dosso Region",
    stateShort: "3",
    lat: "13.15139470",
    lng: "3.41955270"
    },
    {
    state: "Maradi Region",
    stateShort: "4",
    lat: "13.80180740",
    lng: "7.43813550"
    },
    {
    state: "Tahoua Region",
    stateShort: "5",
    lat: "16.09025430",
    lng: "5.39395510"
    },
    {
    state: "Tillabéri Region",
    stateShort: "6",
    lat: "14.64895250",
    lng: "2.14502450"
    },
    {
    state: "Zinder Region",
    stateShort: "7",
    lat: "15.17188810",
    lng: "10.26001250"
    }
    ]
    },
    {
    country: "Nigeria",
    countryShort: "NG",
    currency_symbol: "₦",
    states: [
    {
    state: "Abia",
    stateShort: "AB",
    lat: "5.45273540",
    lng: "7.52484140"
    },
    {
    state: "Abuja Federal Capital Territory",
    stateShort: "FC",
    lat: "8.89406910",
    lng: "7.18604020"
    },
    {
    state: "Adamawa",
    stateShort: "AD",
    lat: "9.32647510",
    lng: "12.39838530"
    },
    {
    state: "Akwa Ibom",
    stateShort: "AK",
    lat: "4.90573710",
    lng: "7.85366750"
    },
    {
    state: "Anambra",
    stateShort: "AN",
    lat: "6.22089970",
    lng: "6.93695590"
    },
    {
    state: "Bauchi",
    stateShort: "BA",
    lat: "10.77606240",
    lng: "9.99919430"
    },
    {
    state: "Bayelsa",
    stateShort: "BY",
    lat: "4.77190710",
    lng: "6.06985260"
    },
    {
    state: "Benue",
    stateShort: "BE",
    lat: "7.33690240",
    lng: "8.74036870"
    },
    {
    state: "Borno",
    stateShort: "BO",
    lat: "11.88463560",
    lng: "13.15196650"
    },
    {
    state: "Cross River",
    stateShort: "CR",
    lat: "5.87017240",
    lng: "8.59880140"
    },
    {
    state: "Delta",
    stateShort: "DE",
    lat: "33.74537840",
    lng: "-90.73545080"
    },
    {
    state: "Ebonyi",
    stateShort: "EB",
    lat: "6.26492320",
    lng: "8.01373020"
    },
    {
    state: "Edo",
    stateShort: "ED",
    lat: "6.63418310",
    lng: "5.93040560"
    },
    {
    state: "Ekiti",
    stateShort: "EK",
    lat: "7.71898620",
    lng: "5.31095050"
    },
    {
    state: "Enugu",
    stateShort: "EN",
    lat: "6.53635300",
    lng: "7.43561940"
    },
    {
    state: "Gombe",
    stateShort: "GO",
    lat: "10.36377950",
    lng: "11.19275870"
    },
    {
    state: "Imo",
    stateShort: "IM",
    lat: "5.57201220",
    lng: "7.05882190"
    },
    {
    state: "Jigawa",
    stateShort: "JI",
    lat: "12.22801200",
    lng: "9.56158670"
    },
    {
    state: "Kaduna",
    stateShort: "KD",
    lat: "10.37640060",
    lng: "7.70945370"
    },
    {
    state: "Kano",
    stateShort: "KN",
    lat: "11.74706980",
    lng: "8.52471070"
    },
    {
    state: "Katsina",
    stateShort: "KT",
    lat: "12.37967070",
    lng: "7.63057480"
    },
    {
    state: "Kebbi",
    stateShort: "KE",
    lat: "11.49420030",
    lng: "4.23333550"
    },
    {
    state: "Kogi",
    stateShort: "KO",
    lat: "7.73373250",
    lng: "6.69058360"
    },
    {
    state: "Kwara",
    stateShort: "KW",
    lat: "8.96689610",
    lng: "4.38740510"
    },
    {
    state: "Lagos",
    stateShort: "LA",
    lat: "6.52437930",
    lng: "3.37920570"
    },
    {
    state: "Nasarawa",
    stateShort: "NA",
    lat: "8.49979080",
    lng: "8.19969370"
    },
    {
    state: "Niger",
    stateShort: "NI",
    lat: "9.93092240",
    lng: "5.59832100"
    },
    {
    state: "Ogun",
    stateShort: "OG",
    lat: "6.99797470",
    lng: "3.47373780"
    },
    {
    state: "Ondo",
    stateShort: "ON",
    lat: "6.91486820",
    lng: "5.14781440"
    },
    {
    state: "Osun",
    stateShort: "OS",
    lat: "7.56289640",
    lng: "4.51995930"
    },
    {
    state: "Oyo",
    stateShort: "OY",
    lat: "8.15738090",
    lng: "3.61465340"
    },
    {
    state: "Plateau",
    stateShort: "PL",
    lat: "9.21820930",
    lng: "9.51794880"
    },
    {
    state: "Rivers",
    stateShort: "RI",
    lat: "5.02134200",
    lng: "6.43760220"
    },
    {
    state: "Sokoto",
    stateShort: "SO",
    lat: "13.05331430",
    lng: "5.32227220"
    },
    {
    state: "Taraba",
    stateShort: "TA",
    lat: "7.99936160",
    lng: "10.77398630"
    },
    {
    state: "Yobe",
    stateShort: "YO",
    lat: "12.29387600",
    lng: "11.43904110"
    },
    {
    state: "Zamfara",
    stateShort: "ZA",
    lat: "12.12218050",
    lng: "6.22358190"
    }
    ]
    },
 

    {
    country: "North Korea",
    countryShort: "KP",
    currency_symbol: "₩",
    states: [
    {
    state: "Chagang Province",
    stateShort: "04",
    lat: "40.72028090",
    lng: "126.56211370"
    },
    {
    state: "Kangwon Province",
    stateShort: "07",
    lat: "38.84323930",
    lng: "127.55970670"
    },
    {
    state: "North Hamgyong Province",
    stateShort: "09",
    lat: "41.81487580",
    lng: "129.45819550"
    },
    {
    state: "North Hwanghae Province",
    stateShort: "06",
    lat: "38.37860850",
    lng: "126.43643630"
    },
    {
    state: "North Pyongan Province",
    stateShort: "03",
    lat: "39.92556180",
    lng: "125.39280250"
    },
    {
    state: "Pyongyang",
    stateShort: "01",
    lat: "39.03921930",
    lng: "125.76252410"
    },
    {
    state: "Rason",
    stateShort: "13",
    lat: "42.25690630",
    lng: "130.29771860"
    },
    {
    state: "Ryanggang Province",
    stateShort: "10",
    lat: "41.23189210",
    lng: "128.50763590"
    },
    {
    state: "South Hamgyong Province",
    stateShort: "08",
    lat: "40.37253390",
    lng: "128.29888400"
    },
    {
    state: "South Hwanghae Province",
    stateShort: "05",
    lat: "38.20072150",
    lng: "125.47819260"
    },
    {
    state: "South Pyongan Province",
    stateShort: "02",
    lat: "39.35391780",
    lng: "126.16827100"
    }
    ]
    },
 
    {
    country: "Norway",
    countryShort: "NO",
    currency_symbol: "kr",
    states: [
    {
    state: "Agder",
    stateShort: "42",
    lat: "58.74069340",
    lng: "6.75315210"
    },
    {
    state: "Innlandet",
    stateShort: "34",
    lat: "61.19357870",
    lng: "5.50832660"
    },
    {
    state: "Jan Mayen",
    stateShort: "22",
    lat: "71.03181800",
    lng: "-8.29203460"
    },
    {
    state: "Møre og Romsdal",
    stateShort: "15",
    lat: "62.84068330",
    lng: "7.00714300"
    },
    {
    state: "Nordland",
    stateShort: "18",
    lat: "67.69305800",
    lng: "12.70739360"
    },
    {
    state: "Oslo",
    stateShort: "03",
    lat: "59.91386880",
    lng: "10.75224540"
    },
    {
    state: "Rogaland",
    stateShort: "11",
    lat: "59.14895440",
    lng: "6.01434320"
    },
    {
    state: "Svalbard",
    stateShort: "21",
    lat: "77.87497250",
    lng: "20.97518210"
    },
    {
    state: "Troms og Finnmark",
    stateShort: "54",
    lat: "69.77890670",
    lng: "18.99401840"
    },
    {
    state: "Trøndelag",
    stateShort: "50",
    lat: "63.54201250",
    lng: "10.93692670"
    },
    {
    state: "Vestfold og Telemark",
    stateShort: "38",
    lat: "59.41174820",
    lng: "7.76471750"
    },
    {
    state: "Vestland",
    stateShort: "46",
    lat: "60.90694420",
    lng: "3.96270810"
    },
    {
    state: "Viken",
    stateShort: "30",
    lat: "59.96530050",
    lng: "7.45051440"
    }
    ]
    },
    {
    country: "Oman",
    countryShort: "OM",
    currency_symbol: ".ع.ر",
    states: [
    {
    state: "Ad Dakhiliyah",
    stateShort: "DA",
    lat: "22.85887580",
    lng: "57.53943560"
    },
    {
    state: "Ad Dhahirah",
    stateShort: "ZA",
    lat: "23.21616740",
    lng: "56.49074440"
    },
    {
    state: "Al Batinah North",
    stateShort: "BS",
    lat: "24.34198460",
    lng: "56.72989040"
    },
    {
    state: "Al Batinah Region",
    stateShort: "BA",
    lat: "24.34198460",
    lng: "56.72989040"
    },
    {
    state: "Al Batinah South",
    stateShort: "BJ",
    lat: "23.43149030",
    lng: "57.42397960"
    },
    {
    state: "Al Buraimi",
    stateShort: "BU",
    lat: "24.16714130",
    lng: "56.11422530"
    },
    {
    state: "Al Wusta",
    stateShort: "WU",
    lat: "19.95710780",
    lng: "56.27568460"
    },
    {
    state: "Ash Sharqiyah North",
    stateShort: "SS",
    lat: "22.71411960",
    lng: "58.53080640"
    },
    {
    state: "Ash Sharqiyah Region",
    stateShort: "SH",
    lat: "22.71411960",
    lng: "58.53080640"
    },
    {
    state: "Ash Sharqiyah South",
    stateShort: "SJ",
    lat: "22.01582490",
    lng: "59.32519220"
    },
    {
    state: "Dhofar",
    stateShort: "ZU",
    lat: "17.03221210",
    lng: "54.14252140"
    },
    {
    state: "Musandam",
    stateShort: "MU",
    lat: "26.19861440",
    lng: "56.24609490"
    },
    {
    state: "Muscat",
    stateShort: "MA",
    lat: "23.58803070",
    lng: "58.38287170"
    }
    ]
    },
    {
    country: "Pakistan",
    countryShort: "PK",
    currency_symbol: "₨",
    states: [
    {
    state: "Azad Kashmir",
    stateShort: "JK",
    lat: "33.92590550",
    lng: "73.78103340"
    },
    {
    state: "Balochistan",
    stateShort: "BA",
    lat: "28.49073320",
    lng: "65.09577920"
    },
    {
    state: "Federally Administered Tribal Areas",
    stateShort: "TA",
    lat: "32.66747600",
    lng: "69.85974060"
    },
    {
    state: "Gilgit-Baltistan",
    stateShort: "GB",
    lat: "35.80256670",
    lng: "74.98318080"
    },
    {
    state: "Islamabad Capital Territory",
    stateShort: "IS",
    lat: "33.72049970",
    lng: "73.04052770"
    },
    {
    state: "Khyber Pakhtunkhwa",
    stateShort: "KP",
    lat: "34.95262050",
    lng: "72.33111300"
    },
    {
    state: "Punjab",
    stateShort: "PB",
    lat: "31.14713050",
    lng: "75.34121790"
    },
    {
    state: "Sindh",
    stateShort: "SD",
    lat: "25.89430180",
    lng: "68.52471490"
    }
    ]
    },
    {
    country: "Palau",
    countryShort: "PW",
    currency_symbol: "$",
    states: [
    {
    state: "Aimeliik",
    stateShort: "002",
    lat: "7.44558590",
    lng: "134.50308780"
    },
    {
    state: "Airai",
    stateShort: "004",
    lat: "7.39661180",
    lng: "134.56902250"
    },
    {
    state: "Angaur",
    stateShort: "010",
    lat: "6.90922300",
    lng: "134.13879340"
    },
    {
    state: "Hatohobei",
    stateShort: "050",
    lat: "3.00706580",
    lng: "131.12377810"
    },
    {
    state: "Kayangel",
    stateShort: "100",
    lat: "8.07000000",
    lng: "134.70277800"
    },
    {
    state: "Koror",
    stateShort: "150",
    lat: "7.33756460",
    lng: "134.48894690"
    },
    {
    state: "Melekeok",
    stateShort: "212",
    lat: "7.51502860",
    lng: "134.59725180"
    },
    {
    state: "Ngaraard",
    stateShort: "214",
    lat: "7.60794000",
    lng: "134.63486450"
    },
    {
    state: "Ngarchelong",
    stateShort: "218",
    lat: "7.71054690",
    lng: "134.63016460"
    },
    {
    state: "Ngardmau",
    stateShort: "222",
    lat: "7.58504860",
    lng: "134.55960890"
    },
    {
    state: "Ngatpang",
    stateShort: "224",
    lat: "7.47109940",
    lng: "134.52664660"
    },
    {
    state: "Ngchesar",
    stateShort: "226",
    lat: "7.45232800",
    lng: "134.57843420"
    },
    {
    state: "Ngeremlengui",
    stateShort: "227",
    lat: "7.51983970",
    lng: "134.55960890"
    },
    {
    state: "Ngiwal",
    stateShort: "228",
    lat: "7.56147640",
    lng: "134.61606190"
    },
    {
    state: "Peleliu",
    stateShort: "350",
    lat: "7.00229060",
    lng: "134.24316280"
    },
    {
    state: "Sonsorol",
    stateShort: "370",
    lat: "5.32681190",
    lng: "132.22391170"
    }
    ]
    },
    {
    country: "Palestinian Territory Occupied",
    countryShort: "PS",
    currency_symbol: "₪",
    states: [
    {
    state: "Bethlehem",
    stateShort: "BTH",
    lat: "31.70539960",
    lng: "35.19368770"
    },
    {
    state: "Deir El Balah",
    stateShort: "DEB",
    lat: null,
    lng: null
    },
    {
    state: "Gaza",
    stateShort: "GZA",
    lat: null,
    lng: null
    },
    {
    state: "Hebron",
    stateShort: "HBN",
    lat: "31.53260010",
    lng: "35.06394750"
    },
    {
    state: "Jenin",
    stateShort: "JEN",
    lat: null,
    lng: null
    },
    {
    state: "Jericho and Al Aghwar",
    stateShort: "JRH",
    lat: null,
    lng: null
    },
    {
    state: "Jerusalem",
    stateShort: "JEM",
    lat: null,
    lng: null
    },
    {
    state: "Khan Yunis",
    stateShort: "KYS",
    lat: null,
    lng: null
    },
    {
    state: "Nablus",
    stateShort: "NBS",
    lat: null,
    lng: null
    },
    {
    state: "North Gaza",
    stateShort: "NGZ",
    lat: null,
    lng: null
    },
    {
    state: "Qalqilya",
    stateShort: "QQA",
    lat: null,
    lng: null
    },
    {
    state: "Rafah",
    stateShort: "RFH",
    lat: null,
    lng: null
    },
    {
    state: "Ramallah",
    stateShort: "RBH",
    lat: null,
    lng: null
    },
    {
    state: "Salfit",
    stateShort: "SLT",
    lat: null,
    lng: null
    },
    {
    state: "Tubas",
    stateShort: "TBS",
    lat: null,
    lng: null
    },
    {
    state: "Tulkarm",
    stateShort: "TKM",
    lat: null,
    lng: null
    }
    ]
    },
    {
    country: "Panama",
    countryShort: "PA",
    currency_symbol: "B/.",
    states: [
    {
    state: "Bocas del Toro Province",
    stateShort: "1",
    lat: "9.41655210",
    lng: "-82.52077870"
    },
    {
    state: "Chiriquí Province",
    stateShort: "4",
    lat: "8.58489800",
    lng: "-82.38857830"
    },
    {
    state: "Coclé Province",
    stateShort: "2",
    lat: "8.62660680",
    lng: "-80.36586500"
    },
    {
    state: "Colón Province",
    stateShort: "3",
    lat: "9.18519890",
    lng: "-80.05349230"
    },
    {
    state: "Darién Province",
    stateShort: "5",
    lat: "7.86817130",
    lng: "-77.83672820"
    },
    {
    state: "Emberá-Wounaan Comarca",
    stateShort: "EM",
    lat: "8.37669830",
    lng: "-77.65361250"
    },
    {
    state: "Guna Yala",
    stateShort: "KY",
    lat: "9.23443950",
    lng: "-78.19262500"
    },
    {
    state: "Herrera Province",
    stateShort: "6",
    lat: "7.77042820",
    lng: "-80.72144170"
    },
    {
    state: "Los Santos Province",
    stateShort: "7",
    lat: "7.59093020",
    lng: "-80.36586500"
    },
    {
    state: "Ngöbe-Buglé Comarca",
    stateShort: "NB",
    lat: "8.65958330",
    lng: "-81.77870210"
    },
    {
    state: "Panamá Oeste Province",
    stateShort: "10",
    lat: "9.11967510",
    lng: "-79.29021330"
    },
    {
    state: "Panamá Province",
    stateShort: "8",
    lat: "9.11967510",
    lng: "-79.29021330"
    },
    {
    state: "Veraguas Province",
    stateShort: "9",
    lat: "8.12310330",
    lng: "-81.07546570"
    }
    ]
    },
    {
    country: "Papua new Guinea",
    countryShort: "PG",
    currency_symbol: "K",
    states: [
    {
    state: "Bougainville",
    stateShort: "NSB",
    lat: "-6.37539190",
    lng: "155.38071010"
    },
    {
    state: "Central Province",
    stateShort: "CPM",
    lat: null,
    lng: null
    },
    {
    state: "Chimbu Province",
    stateShort: "CPK",
    lat: "-6.30876820",
    lng: "144.87312190"
    },
    {
    state: "East New Britain",
    stateShort: "EBR",
    lat: "-4.61289430",
    lng: "151.88773210"
    },
    {
    state: "Eastern Highlands Province",
    stateShort: "EHG",
    lat: "-6.58616740",
    lng: "145.66896360"
    },
    {
    state: "Enga Province",
    stateShort: "EPW",
    lat: "-5.30058490",
    lng: "143.56356370"
    },
    {
    state: "Gulf",
    stateShort: "GPK",
    lat: "37.05483150",
    lng: "-94.43704190"
    },
    {
    state: "Hela",
    stateShort: "HLA",
    lat: "42.33295160",
    lng: "-83.04826180"
    },
    {
    state: "Jiwaka Province",
    stateShort: "JWK",
    lat: "-5.86911540",
    lng: "144.69727740"
    },
    {
    state: "Madang Province",
    stateShort: "MPM",
    lat: "-4.98497330",
    lng: "145.13758340"
    },
    {
    state: "Manus Province",
    stateShort: "MRL",
    lat: "-2.09411690",
    lng: "146.87609510"
    },
    {
    state: "Milne Bay Province",
    stateShort: "MBA",
    lat: "-9.52214510",
    lng: "150.67496530"
    },
    {
    state: "Morobe Province",
    stateShort: "MPL",
    lat: "-6.80137370",
    lng: "146.56164700"
    },
    {
    state: "New Ireland Province",
    stateShort: "NIK",
    lat: "-4.28532560",
    lng: "152.92059180"
    },
    {
    state: "Oro Province",
    stateShort: "NPP",
    lat: "-8.89880630",
    lng: "148.18929210"
    },
    {
    state: "Port Moresby",
    stateShort: "NCD",
    lat: "-9.44380040",
    lng: "147.18026710"
    },
    {
    state: "Sandaun Province",
    stateShort: "SAN",
    lat: "-3.71261790",
    lng: "141.68342750"
    },
    {
    state: "Southern Highlands Province",
    stateShort: "SHM",
    lat: "-6.41790830",
    lng: "143.56356370"
    },
    {
    state: "West New Britain Province",
    stateShort: "WBK",
    lat: "-5.70474320",
    lng: "150.02594660"
    },
    {
    state: "Western Highlands Province",
    stateShort: "WHM",
    lat: "-5.62681280",
    lng: "144.25931180"
    },
    {
    state: "Western Province",
    stateShort: "WPD",
    lat: null,
    lng: null
    }
    ]
    },
    {
    country: "Paraguay",
    countryShort: "PY",
    currency_symbol: "₲",
    states: [
    {
    state: "Alto Paraguay Department",
    stateShort: "16",
    lat: "-20.08525080",
    lng: "-59.47209040"
    },
    {
    state: "Alto Paraná Department",
    stateShort: "10",
    lat: "-25.60755460",
    lng: "-54.96118360"
    },
    {
    state: "Amambay Department",
    stateShort: "13",
    lat: "-22.55902720",
    lng: "-56.02499820"
    },
    {
    state: "Boquerón Department",
    stateShort: "19",
    lat: "-21.74492540",
    lng: "-60.95400730"
    },
    {
    state: "Caaguazú",
    stateShort: "5",
    lat: "-25.46458180",
    lng: "-56.01385100"
    },
    {
    state: "Caazapá",
    stateShort: "6",
    lat: "-26.18277130",
    lng: "-56.37123270"
    },
    {
    state: "Canindeyú",
    stateShort: "14",
    lat: "-24.13787350",
    lng: "-55.66896360"
    },
    {
    state: "Central Department",
    stateShort: "11",
    lat: "36.15592290",
    lng: "-95.96620750"
    },
    {
    state: "Concepción Department",
    stateShort: "1",
    lat: "-23.42142640",
    lng: "-57.43444510"
    },
    {
    state: "Cordillera Department",
    stateShort: "3",
    lat: "-25.22894910",
    lng: "-57.01116810"
    },
    {
    state: "Guairá Department",
    stateShort: "4",
    lat: "-25.88109320",
    lng: "-56.29293810"
    },
    {
    state: "Itapúa",
    stateShort: "7",
    lat: "-26.79236230",
    lng: "-55.66896360"
    },
    {
    state: "Misiones Department",
    stateShort: "8",
    lat: "-26.84335120",
    lng: "-57.10131880"
    },
    {
    state: "Ñeembucú Department",
    stateShort: "12",
    lat: "-27.02991140",
    lng: "-57.82539500"
    },
    {
    state: "Paraguarí Department",
    stateShort: "9",
    lat: "-25.62621740",
    lng: "-57.15206420"
    },
    {
    state: "Presidente Hayes Department",
    stateShort: "15",
    lat: "-23.35126050",
    lng: "-58.73736340"
    },
    {
    state: "San Pedro Department",
    stateShort: "2",
    lat: "-24.19486680",
    lng: "-56.56164700"
    }
    ]
    },
    {
    country: "Peru",
    countryShort: "PE",
    currency_symbol: "S/.",
    states: [
    {
    state: "Amazonas",
    stateShort: "AMA",
    lat: null,
    lng: null
    },
    {
    state: "Áncash",
    stateShort: "ANC",
    lat: "-9.32504970",
    lng: "-77.56194190"
    },
    {
    state: "Apurímac",
    stateShort: "APU",
    lat: "-14.05045330",
    lng: "-73.08774900"
    },
    {
    state: "Arequipa",
    stateShort: "ARE",
    lat: "-16.40904740",
    lng: "-71.53745100"
    },
    {
    state: "Ayacucho",
    stateShort: "AYA",
    lat: "-13.16387370",
    lng: "-74.22356410"
    },
    {
    state: "Cajamarca",
    stateShort: "CAJ",
    lat: "-7.16174650",
    lng: "-78.51278550"
    },
    {
    state: "Callao",
    stateShort: "CAL",
    lat: "-12.05084910",
    lng: "-77.12598430"
    },
    {
    state: "Cusco",
    stateShort: "CUS",
    lat: "-13.53195000",
    lng: "-71.96746260"
    },
    {
    state: "Huancavelica",
    stateShort: "HUV",
    lat: "-12.78619780",
    lng: "-74.97640240"
    },
    {
    state: "Huanuco",
    stateShort: "HUC",
    lat: "-9.92076480",
    lng: "-76.24108430"
    },
    {
    state: "Ica",
    stateShort: "ICA",
    lat: "42.35288320",
    lng: "-71.04300970"
    },
    {
    state: "Junín",
    stateShort: "JUN",
    lat: "-11.15819250",
    lng: "-75.99263060"
    },
    {
    state: "La Libertad",
    stateShort: "LAL",
    lat: "13.49069700",
    lng: "-89.30846070"
    },
    {
    state: "Lambayeque",
    stateShort: "LAM",
    lat: "-6.71976660",
    lng: "-79.90807570"
    },
    {
    state: "Lima",
    stateShort: "LIM",
    lat: "-12.04637310",
    lng: "-77.04275400"
    },
    {
    state: "Loreto",
    stateShort: "LOR",
    lat: "-4.37416430",
    lng: "-76.13042640"
    },
    {
    state: "Madre de Dios",
    stateShort: "MDD",
    lat: "-11.76687050",
    lng: "-70.81199530"
    },
    {
    state: "Moquegua",
    stateShort: "MOQ",
    lat: "-17.19273610",
    lng: "-70.93281380"
    },
    {
    state: "Pasco",
    stateShort: "PAS",
    lat: "46.23050490",
    lng: "-119.09223160"
    },
    {
    state: "Piura",
    stateShort: "PIU",
    lat: "-5.17828840",
    lng: "-80.65488820"
    },
    {
    state: "Puno",
    stateShort: "PUN",
    lat: "-15.84022180",
    lng: "-70.02188050"
    },
    {
    state: "San Martín",
    stateShort: "SAM",
    lat: "37.08494640",
    lng: "-121.61022160"
    },
    {
    state: "Tacna",
    stateShort: "TAC",
    lat: "-18.00656790",
    lng: "-70.24627410"
    },
    {
    state: "Tumbes",
    stateShort: "TUM",
    lat: "-3.55649210",
    lng: "-80.42708850"
    },
    {
    state: "Ucayali",
    stateShort: "UCA",
    lat: "-9.82511830",
    lng: "-73.08774900"
    }
    ]
    },
    {
    country: "Philippines",
    countryShort: "PH",
    currency_symbol: "₱",
    states: [
    {
    state: "Abra",
    stateShort: "ABR",
    lat: "42.49708300",
    lng: "-96.38441000"
    },
    {
    state: "Agusan del Norte",
    stateShort: "AGN",
    lat: "8.94562590",
    lng: "125.53192340"
    },
    {
    state: "Agusan del Sur",
    stateShort: "AGS",
    lat: "8.04638880",
    lng: "126.06153840"
    },
    {
    state: "Aklan",
    stateShort: "AKL",
    lat: "11.81661090",
    lng: "122.09415410"
    },
    {
    state: "Albay",
    stateShort: "ALB",
    lat: "13.17748270",
    lng: "123.52800720"
    },
    {
    state: "Antique",
    stateShort: "ANT",
    lat: "37.03586950",
    lng: "-95.63616940"
    },
    {
    state: "Apayao",
    stateShort: "APA",
    lat: "18.01203040",
    lng: "121.17103890"
    },
    {
    state: "Aurora",
    stateShort: "AUR",
    lat: "36.97089100",
    lng: "-93.71797900"
    },
    {
    state: "Autonomous Region in Muslim Mindanao",
    stateShort: "14",
    lat: "6.95683130",
    lng: "124.24215970"
    },
    {
    state: "Basilan",
    stateShort: "BAS",
    lat: "6.42963490",
    lng: "121.98701650"
    },
    {
    state: "Bataan",
    stateShort: "BAN",
    lat: "14.64168420",
    lng: "120.48184460"
    },
    {
    state: "Batanes",
    stateShort: "BTN",
    lat: "20.44850740",
    lng: "121.97081290"
    },
    {
    state: "Batangas",
    stateShort: "BTG",
    lat: "13.75646510",
    lng: "121.05830760"
    },
    {
    state: "Benguet",
    stateShort: "BEN",
    lat: "16.55772570",
    lng: "120.80394740"
    },
    {
    state: "Bicol",
    stateShort: "05",
    lat: "13.42098850",
    lng: "123.41367360"
    },
    {
    state: "Biliran",
    stateShort: "BIL",
    lat: "11.58331520",
    lng: "124.46418480"
    },
    {
    state: "Bohol",
    stateShort: "BOH",
    lat: "9.84999110",
    lng: "124.14354270"
    },
    {
    state: "Bukidnon",
    stateShort: "BUK",
    lat: "8.05150540",
    lng: "124.92299460"
    },
    {
    state: "Bulacan",
    stateShort: "BUL",
    lat: "14.79427350",
    lng: "120.87990080"
    },
    {
    state: "Cagayan",
    stateShort: "CAG",
    lat: "18.24896290",
    lng: "121.87878330"
    },
    {
    state: "Cagayan Valley",
    stateShort: "02",
    lat: "16.97537580",
    lng: "121.81070790"
    },
    {
    state: "Calabarzon",
    stateShort: "40",
    lat: "14.10078030",
    lng: "121.07937050"
    },
    {
    state: "Camarines Norte",
    stateShort: "CAN",
    lat: "14.13902650",
    lng: "122.76330360"
    },
    {
    state: "Camarines Sur",
    stateShort: "CAS",
    lat: "13.52501970",
    lng: "123.34861470"
    },
    {
    state: "Camiguin",
    stateShort: "CAM",
    lat: "9.17321640",
    lng: "124.72987650"
    },
    {
    state: "Capiz",
    stateShort: "CAP",
    lat: "11.55288160",
    lng: "122.74072300"
    },
    {
    state: "Caraga",
    stateShort: "13",
    lat: "8.80145620",
    lng: "125.74068820"
    },
    {
    state: "Catanduanes",
    stateShort: "CAT",
    lat: "13.70886840",
    lng: "124.24215970"
    },
    {
    state: "Cavite",
    stateShort: "CAV",
    lat: "14.47912970",
    lng: "120.89696340"
    },
    {
    state: "Cebu",
    stateShort: "CEB",
    lat: "10.31569920",
    lng: "123.88543660"
    },
    {
    state: "Central Luzon",
    stateShort: "03",
    lat: "15.48277220",
    lng: "120.71200230"
    },
    {
    state: "Central Visayas",
    stateShort: "07",
    lat: "9.81687500",
    lng: "124.06414190"
    },
    {
    state: "Compostela Valley",
    stateShort: "COM",
    lat: "7.51251500",
    lng: "126.17626150"
    },
    {
    state: "Cordillera Administrative",
    stateShort: "15",
    lat: "17.35125420",
    lng: "121.17188510"
    },
    {
    state: "Cotabato",
    stateShort: "NCO",
    lat: "7.20466680",
    lng: "124.23104390"
    },
    {
    state: "Davao",
    stateShort: "11",
    lat: "7.30416220",
    lng: "126.08934060"
    },
    {
    state: "Davao del Norte",
    stateShort: "DAV",
    lat: "7.56176990",
    lng: "125.65328480"
    },
    {
    state: "Davao del Sur",
    stateShort: "DAS",
    lat: "6.76626870",
    lng: "125.32842690"
    },
    {
    state: "Davao Occidental",
    stateShort: "DVO",
    lat: "6.09413960",
    lng: "125.60954740"
    },
    {
    state: "Davao Oriental",
    stateShort: "DAO",
    lat: "7.31715850",
    lng: "126.54198870"
    },
    {
    state: "Dinagat Islands",
    stateShort: "DIN",
    lat: "10.12818160",
    lng: "125.60954740"
    },
    {
    state: "Eastern Samar",
    stateShort: "EAS",
    lat: "11.50007310",
    lng: "125.49999080"
    },
    {
    state: "Eastern Visayas",
    stateShort: "08",
    lat: "12.24455330",
    lng: "125.03881640"
    },
    {
    state: "Guimaras",
    stateShort: "GUI",
    lat: "10.59286610",
    lng: "122.63250810"
    },
    {
    state: "Ifugao",
    stateShort: "IFU",
    lat: "16.83307920",
    lng: "121.17103890"
    },
    {
    state: "Ilocos",
    stateShort: "01",
    lat: "16.08321440",
    lng: "120.61998950"
    },
    {
    state: "Ilocos Norte",
    stateShort: "ILN",
    lat: "18.16472810",
    lng: "120.71155920"
    },
    {
    state: "Ilocos Sur",
    stateShort: "ILS",
    lat: "17.22786640",
    lng: "120.57395790"
    },
    {
    state: "Iloilo",
    stateShort: "ILI",
    lat: "10.72015010",
    lng: "122.56210630"
    },
    {
    state: "Isabela",
    stateShort: "ISA",
    lat: "18.50077590",
    lng: "-67.02434620"
    },
    {
    state: "Kalinga",
    stateShort: "KAL",
    lat: "17.47404220",
    lng: "121.35416310"
    },
    {
    state: "La Union",
    stateShort: "LUN",
    lat: "38.87668780",
    lng: "-77.12809120"
    },
    {
    state: "Laguna",
    stateShort: "LAG",
    lat: "33.54271890",
    lng: "-117.78535680"
    },
    {
    state: "Lanao del Norte",
    stateShort: "LAN",
    lat: "7.87218110",
    lng: "123.88577470"
    },
    {
    state: "Lanao del Sur",
    stateShort: "LAS",
    lat: "7.82317600",
    lng: "124.41982430"
    },
    {
    state: "Leyte",
    stateShort: "LEY",
    lat: "10.86245360",
    lng: "124.88111950"
    },
    {
    state: "Maguindanao",
    stateShort: "MAG",
    lat: "6.94225810",
    lng: "124.41982430"
    },
    {
    state: "Marinduque",
    stateShort: "MAD",
    lat: "13.47671710",
    lng: "121.90321920"
    },
    {
    state: "Masbate",
    stateShort: "MAS",
    lat: "12.35743460",
    lng: "123.55040760"
    },
    {
    state: "Metro Manila",
    stateShort: "NCR",
    lat: "14.60905370",
    lng: "121.02225650"
    },
    {
    state: "Mimaropa",
    stateShort: "41",
    lat: "9.84320650",
    lng: "118.73647830"
    },
    {
    state: "Misamis Occidental",
    stateShort: "MSC",
    lat: "8.33749030",
    lng: "123.70706190"
    },
    {
    state: "Misamis Oriental",
    stateShort: "MSR",
    lat: "8.50455580",
    lng: "124.62195920"
    },
    {
    state: "Mountain Province",
    stateShort: "MOU",
    lat: "40.70754370",
    lng: "-73.95010330"
    },
    {
    state: "Negros Occidental",
    stateShort: "NEC",
    lat: "10.29256090",
    lng: "123.02465180"
    },
    {
    state: "Negros Oriental",
    stateShort: "NER",
    lat: "9.62820830",
    lng: "122.98883190"
    },
    {
    state: "Northern Mindanao",
    stateShort: "10",
    lat: "8.02016350",
    lng: "124.68565090"
    },
    {
    state: "Northern Samar",
    stateShort: "NSA",
    lat: "12.36131990",
    lng: "124.77407930"
    },
    {
    state: "Nueva Ecija",
    stateShort: "NUE",
    lat: "15.57837500",
    lng: "121.11126150"
    },
    {
    state: "Nueva Vizcaya",
    stateShort: "NUV",
    lat: "16.33011070",
    lng: "121.17103890"
    },
    {
    state: "Occidental Mindoro",
    stateShort: "MDC",
    lat: "13.10241110",
    lng: "120.76512840"
    },
    {
    state: "Oriental Mindoro",
    stateShort: "MDR",
    lat: "13.05645980",
    lng: "121.40694170"
    },
    {
    state: "Palawan",
    stateShort: "PLW",
    lat: "9.83494930",
    lng: "118.73836150"
    },
    {
    state: "Pampanga",
    stateShort: "PAM",
    lat: "15.07940900",
    lng: "120.61998950"
    },
    {
    state: "Pangasinan",
    stateShort: "PAN",
    lat: "15.89490550",
    lng: "120.28631830"
    },
    {
    state: "Quezon",
    stateShort: "QUE",
    lat: "14.03139060",
    lng: "122.11309090"
    },
    {
    state: "Quirino",
    stateShort: "QUI",
    lat: "16.27004240",
    lng: "121.53700030"
    },
    {
    state: "Rizal",
    stateShort: "RIZ",
    lat: "14.60374460",
    lng: "121.30840880"
    },
    {
    state: "Romblon",
    stateShort: "ROM",
    lat: "12.57780160",
    lng: "122.26914600"
    },
    {
    state: "Sarangani",
    stateShort: "SAR",
    lat: "5.92671750",
    lng: "124.99475100"
    },
    {
    state: "Siquijor",
    stateShort: "SIG",
    lat: "9.19987790",
    lng: "123.59519250"
    },
    {
    state: "Soccsksargen",
    stateShort: "12",
    lat: "6.27069180",
    lng: "124.68565090"
    },
    {
    state: "Sorsogon",
    stateShort: "SOR",
    lat: "12.99270950",
    lng: "124.01474640"
    },
    {
    state: "South Cotabato",
    stateShort: "SCO",
    lat: "6.33575650",
    lng: "124.77407930"
    },
    {
    state: "Southern Leyte",
    stateShort: "SLE",
    lat: "10.33462060",
    lng: "125.17087410"
    },
    {
    state: "Sultan Kudarat",
    stateShort: "SUK",
    lat: "6.50694010",
    lng: "124.41982430"
    },
    {
    state: "Sulu",
    stateShort: "SLU",
    lat: "5.97490110",
    lng: "121.03351000"
    },
    {
    state: "Surigao del Norte",
    stateShort: "SUN",
    lat: "9.51482800",
    lng: "125.69699840"
    },
    {
    state: "Surigao del Sur",
    stateShort: "SUR",
    lat: "8.54049060",
    lng: "126.11447580"
    },
    {
    state: "Tarlac",
    stateShort: "TAR",
    lat: "15.47547860",
    lng: "120.59634920"
    },
    {
    state: "Tawi-Tawi",
    stateShort: "TAW",
    lat: "5.13381100",
    lng: "119.95092600"
    },
    {
    state: "Western Samar",
    stateShort: "WSA",
    lat: "12.00002060",
    lng: "124.99124520"
    },
    {
    state: "Western Visayas",
    stateShort: "06",
    lat: "11.00498360",
    lng: "122.53727410"
    },
    {
    state: "Zambales",
    stateShort: "ZMB",
    lat: "15.50817660",
    lng: "119.96978080"
    },
    {
    state: "Zamboanga del Norte",
    stateShort: "ZAN",
    lat: "8.38862820",
    lng: "123.16888830"
    },
    {
    state: "Zamboanga del Sur",
    stateShort: "ZAS",
    lat: "7.83830540",
    lng: "123.29666570"
    },
    {
    state: "Zamboanga Peninsula",
    stateShort: "09",
    lat: "8.15407700",
    lng: "123.25879300"
    },
    {
    state: "Zamboanga Sibugay",
    stateShort: "ZSI",
    lat: "7.52252470",
    lng: "122.31075170"
    }
    ]
    },
 
    {
    country: "Poland",
    countryShort: "PL",
    currency_symbol: "zł",
    states: [
    {
    state: "Greater Poland Voivodeship",
    stateShort: "WP",
    lat: "52.27998600",
    lng: "17.35229390"
    },
    {
    state: "Kuyavian-Pomeranian Voivodeship",
    stateShort: "KP",
    lat: "53.16483630",
    lng: "18.48342240"
    },
    {
    state: "Lesser Poland Voivodeship",
    stateShort: "MA",
    lat: "49.72253060",
    lng: "20.25033580"
    },
    {
    state: "Lower Silesian Voivodeship",
    stateShort: "DS",
    lat: "51.13398610",
    lng: "16.88419610"
    },
    {
    state: "Lublin Voivodeship",
    stateShort: "LU",
    lat: "51.24935190",
    lng: "23.10113920"
    },
    {
    state: "Lubusz Voivodeship",
    stateShort: "LB",
    lat: "52.22746120",
    lng: "15.25591030"
    },
    {
    state: "Łódź Voivodeship",
    stateShort: "LD",
    lat: "51.46347710",
    lng: "19.17269740"
    },
    {
    state: "masovian voivodeship",
    stateShort: "MZ",
    lat: "51.89271820",
    lng: "21.00216790"
    },
    {
    state: "Opole Voivodeship",
    stateShort: "OP",
    lat: "50.80037610",
    lng: "17.93798900"
    },
    {
    state: "podkarpackie voivodeship",
    stateShort: "PK",
    lat: "50.05747490",
    lng: "22.08956910"
    },
    {
    state: "podlaskie voivodeship",
    stateShort: "PD",
    lat: "53.06971590",
    lng: "22.96746390"
    },
    {
    state: "pomeranian voivodeship",
    stateShort: "PM",
    lat: "54.29442520",
    lng: "18.15311640"
    },
    {
    state: "silesian voivodeship",
    stateShort: "SL",
    lat: "50.57165950",
    lng: "19.32197680"
    },
    {
    state: "świętokrzyskie voivodeship",
    stateShort: "SK",
    lat: "50.62610410",
    lng: "20.94062790"
    },
    {
    state: "warmian-masurian voivodeship",
    stateShort: "WN",
    lat: "53.86711170",
    lng: "20.70278610"
    },
    {
    state: "west pomeranian voivodeship",
    stateShort: "ZP",
    lat: "53.46578910",
    lng: "15.18225810"
    }
    ]
    },
    {
    country: "Portugal",
    countryShort: "PT",
    currency_symbol: "€",
    states: [
    {
    state: "Açores",
    stateShort: "20",
    lat: "37.74124880",
    lng: "-25.67559440"
    },
    {
    state: "Aveiro district",
    stateShort: "01",
    lat: "40.72090230",
    lng: "-8.57210160"
    },
    {
    state: "Beja district",
    stateShort: "02",
    lat: "37.96877860",
    lng: "-7.87216000"
    },
    {
    state: "Braga",
    stateShort: "03",
    lat: "41.55038800",
    lng: "-8.42613010"
    },
    {
    state: "Bragança district",
    stateShort: "04",
    lat: "41.80616520",
    lng: "-6.75674270"
    },
    {
    state: "Castelo Branco",
    stateShort: "05",
    lat: "39.86313230",
    lng: "-7.48141630"
    },
    {
    state: "Coimbra district",
    stateShort: "06",
    lat: "40.20579940",
    lng: "-8.41369000"
    },
    {
    state: "Évora district",
    stateShort: "07",
    lat: "38.57444680",
    lng: "-7.90765530"
    },
    {
    state: "Faro",
    stateShort: "08",
    lat: "37.01935480",
    lng: "-7.93043970"
    },
    {
    state: "Guarda district",
    stateShort: "09",
    lat: "40.53859720",
    lng: "-7.26757720"
    },
    {
    state: "Leiria",
    stateShort: "10",
    lat: "39.77095320",
    lng: "-8.79218360"
    },
    {
    state: "lisboa",
    stateShort: "11",
    lat: "38.72232630",
    lng: "-9.13927140"
    },
    {
    state: "Madeira",
    stateShort: "30",
    lat: "32.76070740",
    lng: "-16.95947230"
    },
    {
    state: "portalegre district",
    stateShort: "12",
    lat: "39.29670860",
    lng: "-7.42847550"
    },
    {
    state: "Porto district",
    stateShort: "13",
    lat: "41.14766290",
    lng: "-8.60789730"
    },
    {
    state: "Santarém district",
    stateShort: "14",
    lat: "39.23666870",
    lng: "-8.68599440"
    },
    {
    state: "Setúbal",
    stateShort: "15",
    lat: "38.52409330",
    lng: "-8.89258760"
    },
    {
    state: "Viana do Castelo",
    stateShort: "16",
    lat: "41.69180460",
    lng: "-8.83445100"
    },
    {
    state: "Vila Real district",
    stateShort: "17",
    lat: "41.30035270",
    lng: "-7.74572740"
    },
    {
    state: "Viseu",
    stateShort: "18",
    lat: "40.65884240",
    lng: "-7.91475600"
    }
    ]
    },
    {
    country: "Puerto Rico",
    countryShort: "PR",
    currency_symbol: "$",
    states: [
    {
    state: "Arecibo",
    stateShort: "AR",
    lat: "18.47055556",
    lng: "-66.72083333"
    },
    {
    state: "Bayamon",
    stateShort: "BY",
    lat: "18.17777778",
    lng: "-66.11333333"
    },
    {
    state: "Caguas",
    stateShort: "CG",
    lat: "18.23333333",
    lng: "-66.03333333"
    },
    {
    state: "Carolina",
    stateShort: "CL",
    lat: "18.38888889",
    lng: "-65.96666667"
    },
    {
    state: "Guaynabo",
    stateShort: "GN",
    lat: "18.36666667",
    lng: "-66.10000000"
    },
    {
    state: "Mayagüez",
    stateShort: "MG",
    lat: "18.20111111",
    lng: "-67.13972222"
    },
    {
    state: "Ponce",
    stateShort: "PO",
    lat: "18.00000000",
    lng: "-66.61666667"
    },
    {
    state: "San Juan",
    stateShort: "SJ",
    lat: "18.45000000",
    lng: "-66.06666667"
    },
    {
    state: "Toa Baja",
    stateShort: "TB",
    lat: "18.44388900",
    lng: "-66.25972200"
    },
    {
    state: "Trujillo Alto",
    stateShort: "TA",
    lat: "18.36277800",
    lng: "-66.01750000"
    }
    ]
    },
    {
    country: "Qatar",
    countryShort: "QA",
    currency_symbol: "ق.ر",
    states: [
    {
    state: "Al Daayen",
    stateShort: "ZA",
    lat: "25.57845590",
    lng: "51.48213870"
    },
    {
    state: "Al Khor",
    stateShort: "KH",
    lat: "25.68040780",
    lng: "51.49685020"
    },
    {
    state: "Al Rayyan Municipality",
    stateShort: "RA",
    lat: "25.25225510",
    lng: "51.43887130"
    },
    {
    state: "Al Wakrah",
    stateShort: "WA",
    lat: "25.16593140",
    lng: "51.59755240"
    },
    {
    state: "Al-Shahaniya",
    stateShort: "SH",
    lat: "25.41063860",
    lng: "51.18460250"
    },
    {
    state: "Doha",
    stateShort: "DA",
    lat: "25.28544730",
    lng: "51.53103980"
    },
    {
    state: "Madinat ash Shamal",
    stateShort: "MS",
    lat: "26.11827430",
    lng: "51.21572650"
    },
    {
    state: "Umm Salal Municipality",
    stateShort: "US",
    lat: "25.48752420",
    lng: "51.39656800"
    }
    ]
    },
 
    {
    country: "Romania",
    countryShort: "RO",
    currency_symbol: "lei",
    states: [
    {
    state: "Alba",
    stateShort: "AB",
    lat: "44.70091530",
    lng: "8.03569110"
    },
    {
    state: "Arad County",
    stateShort: "AR",
    lat: "46.22836510",
    lng: "21.65978190"
    },
    {
    state: "Arges",
    stateShort: "AG",
    lat: "45.07225270",
    lng: "24.81427260"
    },
    {
    state: "Bacău County",
    stateShort: "BC",
    lat: "46.32581840",
    lng: "26.66237800"
    },
    {
    state: "Bihor County",
    stateShort: "BH",
    lat: "47.01575160",
    lng: "22.17226600"
    },
    {
    state: "Bistrița-Năsăud County",
    stateShort: "BN",
    lat: "47.24861070",
    lng: "24.53228140"
    },
    {
    state: "Botoșani County",
    stateShort: "BT",
    lat: "47.89240420",
    lng: "26.75917810"
    },
    {
    state: "Braila",
    stateShort: "BR",
    lat: "45.26524630",
    lng: "27.95947140"
    },
    {
    state: "Brașov County",
    stateShort: "BV",
    lat: "45.77818440",
    lng: "25.22258000"
    },
    {
    state: "Bucharest",
    stateShort: "B",
    lat: "44.42676740",
    lng: "26.10253840"
    },
    {
    state: "Buzău County",
    stateShort: "BZ",
    lat: "45.33509120",
    lng: "26.71075780"
    },
    {
    state: "Călărași County",
    stateShort: "CL",
    lat: "44.36587150",
    lng: "26.75486070"
    },
    {
    state: "Caraș-Severin County",
    stateShort: "CS",
    lat: "45.11396460",
    lng: "22.07409930"
    },
    {
    state: "Cluj County",
    stateShort: "CJ",
    lat: "46.79417970",
    lng: "23.61214920"
    },
    {
    state: "Constanța County",
    stateShort: "CT",
    lat: "44.21298700",
    lng: "28.25500550"
    },
    {
    state: "Covasna County",
    stateShort: "CV",
    lat: "45.94263470",
    lng: "25.89189840"
    },
    {
    state: "Dâmbovița County",
    stateShort: "DB",
    lat: "44.92898930",
    lng: "25.42538500"
    },
    {
    state: "Dolj County",
    stateShort: "DJ",
    lat: "44.16230220",
    lng: "23.63250540"
    },
    {
    state: "Galați County",
    stateShort: "GL",
    lat: "45.78005690",
    lng: "27.82515760"
    },
    {
    state: "Giurgiu County",
    stateShort: "GR",
    lat: "43.90370760",
    lng: "25.96992650"
    },
    {
    state: "Gorj County",
    stateShort: "GJ",
    lat: "44.94855950",
    lng: "23.24270790"
    },
    {
    state: "Harghita County",
    stateShort: "HR",
    lat: "46.49285070",
    lng: "25.64566960"
    },
    {
    state: "Hunedoara County",
    stateShort: "HD",
    lat: "45.79363800",
    lng: "22.99759930"
    },
    {
    state: "Ialomița County",
    stateShort: "IL",
    lat: "44.60313300",
    lng: "27.37899140"
    },
    {
    state: "Iași County",
    stateShort: "IS",
    lat: "47.26796530",
    lng: "27.21856620"
    },
    {
    state: "Ilfov County",
    stateShort: "IF",
    lat: "44.53554800",
    lng: "26.23248860"
    },
    {
    state: "Maramureș County",
    stateShort: "MM",
    lat: "46.55699040",
    lng: "24.67232150"
    },
    {
    state: "Mehedinți County",
    stateShort: "MH",
    lat: "44.55150530",
    lng: "22.90441570"
    },
    {
    state: "Mureș County",
    stateShort: "MS",
    lat: "46.55699040",
    lng: "24.67232150"
    },
    {
    state: "Neamț County",
    stateShort: "NT",
    lat: "46.97586850",
    lng: "26.38187640"
    },
    {
    state: "Olt County",
    stateShort: "OT",
    lat: "44.20079700",
    lng: "24.50229810"
    },
    {
    state: "Prahova County",
    stateShort: "PH",
    lat: "45.08919060",
    lng: "26.08293130"
    },
    {
    state: "Sălaj County",
    stateShort: "SJ",
    lat: "47.20908130",
    lng: "23.21219010"
    },
    {
    state: "Satu Mare County",
    stateShort: "SM",
    lat: "47.76689050",
    lng: "22.92413770"
    },
    {
    state: "Sibiu County",
    stateShort: "SB",
    lat: "45.92691060",
    lng: "24.22548070"
    },
    {
    state: "Suceava County",
    stateShort: "SV",
    lat: "47.55055480",
    lng: "25.74106200"
    },
    {
    state: "Teleorman County",
    stateShort: "TR",
    lat: "44.01604910",
    lng: "25.29866280"
    },
    {
    state: "Timiș County",
    stateShort: "TM",
    lat: "45.81389020",
    lng: "21.33310550"
    },
    {
    state: "Tulcea County",
    stateShort: "TL",
    lat: "45.04505650",
    lng: "29.03249120"
    },
    {
    state: "Vâlcea County",
    stateShort: "VL",
    lat: "45.07980510",
    lng: "24.08352830"
    },
    {
    state: "Vaslui County",
    stateShort: "VS",
    lat: "46.46310590",
    lng: "27.73980310"
    },
    {
    state: "Vrancea County",
    stateShort: "VN",
    lat: "45.81348760",
    lng: "27.06575310"
    }
    ]
    },
    {
    country: "Russia",
    countryShort: "RU",
    currency_symbol: "₽",
    states: [
    {
    state: "Altai Krai",
    stateShort: "ALT",
    lat: "51.79362980",
    lng: "82.67585960"
    },
    {
    state: "Altai Republic",
    stateShort: "AL",
    lat: "50.61819240",
    lng: "86.21993080"
    },
    {
    state: "Amur Oblast",
    stateShort: "AMU",
    lat: "54.60350650",
    lng: "127.48017210"
    },
    {
    state: "Arkhangelsk",
    stateShort: "ARK",
    lat: "64.54585490",
    lng: "40.55057690"
    },
    {
    state: "Astrakhan Oblast",
    stateShort: "AST",
    lat: "46.13211660",
    lng: "48.06101150"
    },
    {
    state: "Belgorod Oblast",
    stateShort: "BEL",
    lat: "50.71069260",
    lng: "37.75333770"
    },
    {
    state: "Bryansk Oblast",
    stateShort: "BRY",
    lat: "53.04085990",
    lng: "33.26909000"
    },
    {
    state: "Chechen Republic",
    stateShort: "CE",
    lat: "43.40233010",
    lng: "45.71874680"
    },
    {
    state: "Chelyabinsk Oblast",
    stateShort: "CHE",
    lat: "54.43194220",
    lng: "60.87889630"
    },
    {
    state: "Chukotka Autonomous Okrug",
    stateShort: "CHU",
    lat: "65.62983550",
    lng: "171.69521590"
    },
    {
    state: "Chuvash Republic",
    stateShort: "CU",
    lat: "55.55959920",
    lng: "46.92835350"
    },
    {
    state: "Irkutsk",
    stateShort: "IRK",
    lat: "52.28548340",
    lng: "104.28902220"
    },
    {
    state: "Ivanovo Oblast",
    stateShort: "IVA",
    lat: "57.10568540",
    lng: "41.48300840"
    },
    {
    state: "Jewish Autonomous Oblast",
    stateShort: "YEV",
    lat: "48.48081470",
    lng: "131.76573670"
    },
    {
    state: "Kabardino-Balkar Republic",
    stateShort: "KB",
    lat: "43.39324690",
    lng: "43.56284980"
    },
    {
    state: "Kaliningrad",
    stateShort: "KGD",
    lat: "54.71042640",
    lng: "20.45221440"
    },
    {
    state: "Kaluga Oblast",
    stateShort: "KLU",
    lat: "54.38726660",
    lng: "35.18890940"
    },
    {
    state: "Kamchatka Krai",
    stateShort: "KAM",
    lat: "61.43439810",
    lng: "166.78841310"
    },
    {
    state: "Karachay-Cherkess Republic",
    stateShort: "KC",
    lat: "43.88451430",
    lng: "41.73039390"
    },
    {
    state: "Kemerovo Oblast",
    stateShort: "KEM",
    lat: "54.75746480",
    lng: "87.40552880"
    },
    {
    state: "Khabarovsk Krai",
    stateShort: "KHA",
    lat: "50.58884310",
    lng: "135.00000000"
    },
    {
    state: "Khanty-Mansi Autonomous Okrug",
    stateShort: "KHM",
    lat: "62.22870620",
    lng: "70.64100570"
    },
    {
    state: "Kirov Oblast",
    stateShort: "KIR",
    lat: "58.41985290",
    lng: "50.20972480"
    },
    {
    state: "Komi Republic",
    stateShort: "KO",
    lat: "63.86305390",
    lng: "54.83126900"
    },
    {
    state: "Kostroma Oblast",
    stateShort: "KOS",
    lat: "58.55010690",
    lng: "43.95411020"
    },
    {
    state: "Krasnodar Krai",
    stateShort: "KDA",
    lat: "45.64152890",
    lng: "39.70559770"
    },
    {
    state: "Krasnoyarsk Krai",
    stateShort: "KYA",
    lat: "64.24797580",
    lng: "95.11041760"
    },
    {
    state: "Kurgan Oblast",
    stateShort: "KGN",
    lat: "55.44815480",
    lng: "65.11809750"
    },
    {
    state: "Kursk Oblast",
    stateShort: "KRS",
    lat: "51.76340260",
    lng: "35.38118120"
    },
    {
    state: "Leningrad Oblast",
    stateShort: "LEN",
    lat: "60.07932080",
    lng: "31.89266450"
    },
    {
    state: "Lipetsk Oblast",
    stateShort: "LIP",
    lat: "52.52647020",
    lng: "39.20322690"
    },
    {
    state: "Magadan Oblast",
    stateShort: "MAG",
    lat: "62.66434170",
    lng: "153.91499100"
    },
    {
    state: "Mari El Republic",
    stateShort: "ME",
    lat: "56.43845700",
    lng: "47.96077580"
    },
    {
    state: "Moscow",
    stateShort: "MOW",
    lat: "55.75582600",
    lng: "37.61729990"
    },
    {
    state: "Moscow Oblast",
    stateShort: "MOS",
    lat: "55.34039600",
    lng: "38.29176510"
    },
    {
    state: "Murmansk Oblast",
    stateShort: "MUR",
    lat: "67.84426740",
    lng: "35.08841020"
    },
    {
    state: "Nenets Autonomous Okrug",
    stateShort: "NEN",
    lat: "67.60783370",
    lng: "57.63383310"
    },
    {
    state: "Nizhny Novgorod Oblast",
    stateShort: "NIZ",
    lat: "55.79951590",
    lng: "44.02967690"
    },
    {
    state: "Novgorod Oblast",
    stateShort: "NGR",
    lat: "58.24275520",
    lng: "32.56651900"
    },
    {
    state: "Novosibirsk",
    stateShort: "NVS",
    lat: "54.98326930",
    lng: "82.89638310"
    },
    {
    state: "Omsk Oblast",
    stateShort: "OMS",
    lat: "55.05546690",
    lng: "73.31673420"
    },
    {
    state: "Orenburg Oblast",
    stateShort: "ORE",
    lat: "51.76340260",
    lng: "54.61881880"
    },
    {
    state: "Oryol Oblast",
    stateShort: "ORL",
    lat: "52.78564140",
    lng: "36.92423440"
    },
    {
    state: "Penza Oblast",
    stateShort: "PNZ",
    lat: "53.14121050",
    lng: "44.09400480"
    },
    {
    state: "Perm Krai",
    stateShort: "PER",
    lat: "58.82319290",
    lng: "56.58724810"
    },
    {
    state: "Primorsky Krai",
    stateShort: "PRI",
    lat: "45.05256410",
    lng: "135.00000000"
    },
    {
    state: "Pskov Oblast",
    stateShort: "PSK",
    lat: "56.77085990",
    lng: "29.09400900"
    },
    {
    state: "Republic of Adygea",
    stateShort: "AD",
    lat: "44.82291550",
    lng: "40.17544630"
    },
    {
    state: "Republic of Bashkortostan",
    stateShort: "BA",
    lat: "54.23121720",
    lng: "56.16452570"
    },
    {
    state: "Republic of Buryatia",
    stateShort: "BU",
    lat: "54.83311460",
    lng: "112.40605300"
    },
    {
    state: "Republic of Dagestan",
    stateShort: "DA",
    lat: "42.14318860",
    lng: "47.09497990"
    },
    {
    state: "Republic of Ingushetia",
    stateShort: "IN",
    lat: "43.40516980",
    lng: "44.82029990"
    },
    {
    state: "Republic of Kalmykia",
    stateShort: "KL",
    lat: "46.18671760",
    lng: "45.00000000"
    },
    {
    state: "Republic of Karelia",
    stateShort: "KR",
    lat: "63.15587020",
    lng: "32.99055520"
    },
    {
    state: "Republic of Khakassia",
    stateShort: "KK",
    lat: "53.04522810",
    lng: "90.39821450"
    },
    {
    state: "Republic of Mordovia",
    stateShort: "MO",
    lat: "54.23694410",
    lng: "44.06839700"
    },
    {
    state: "Republic of North Ossetia-Alania",
    stateShort: "SE",
    lat: "43.04513020",
    lng: "44.28709720"
    },
    {
    state: "Republic of Tatarstan",
    stateShort: "TA",
    lat: "55.18023640",
    lng: "50.72639450"
    },
    {
    state: "Rostov Oblast",
    stateShort: "ROS",
    lat: "47.68532470",
    lng: "41.82589520"
    },
    {
    state: "Ryazan Oblast",
    stateShort: "RYA",
    lat: "54.38759640",
    lng: "41.25956610"
    },
    {
    state: "Saint Petersburg",
    stateShort: "SPE",
    lat: "59.93105840",
    lng: "30.36090960"
    },
    {
    state: "Sakha Republic",
    stateShort: "SA",
    lat: "66.76134510",
    lng: "124.12375300"
    },
    {
    state: "Sakhalin",
    stateShort: "SAK",
    lat: "50.69098480",
    lng: "142.95056890"
    },
    {
    state: "Samara Oblast",
    stateShort: "SAM",
    lat: "53.41838390",
    lng: "50.47255280"
    },
    {
    state: "Saratov Oblast",
    stateShort: "SAR",
    lat: "51.83692630",
    lng: "46.75393970"
    },
    {
    state: "Sevastopol",
    stateShort: "UA-40",
    lat: "44.61665000",
    lng: "33.52536710"
    },
    {
    state: "Smolensk Oblast",
    stateShort: "SMO",
    lat: "54.98829940",
    lng: "32.66773780"
    },
    {
    state: "Stavropol Krai",
    stateShort: "STA",
    lat: "44.66809930",
    lng: "43.52021400"
    },
    {
    state: "Sverdlovsk",
    stateShort: "SVE",
    lat: "56.84309930",
    lng: "60.64540860"
    },
    {
    state: "Tambov Oblast",
    stateShort: "TAM",
    lat: "52.64165890",
    lng: "41.42164510"
    },
    {
    state: "Tomsk Oblast",
    stateShort: "TOM",
    lat: "58.89698820",
    lng: "82.67655000"
    },
    {
    state: "Tula Oblast",
    stateShort: "TUL",
    lat: "54.16376800",
    lng: "37.56495070"
    },
    {
    state: "Tuva Republic",
    stateShort: "TY",
    lat: "51.88726690",
    lng: "95.62601720"
    },
    {
    state: "Tver Oblast",
    stateShort: "TVE",
    lat: "57.00216540",
    lng: "33.98531420"
    },
    {
    state: "Tyumen Oblast",
    stateShort: "TYU",
    lat: "56.96343870",
    lng: "66.94827800"
    },
    {
    state: "Udmurt Republic",
    stateShort: "UD",
    lat: "57.06702180",
    lng: "53.02779480"
    },
    {
    state: "Ulyanovsk Oblast",
    stateShort: "ULY",
    lat: "53.97933570",
    lng: "47.77624250"
    },
    {
    state: "Vladimir Oblast",
    stateShort: "VLA",
    lat: "56.15534650",
    lng: "40.59266850"
    },
    {
    state: "Volgograd Oblast",
    stateShort: "VGG",
    lat: "49.25873930",
    lng: "39.81544630"
    },
    {
    state: "Vologda Oblast",
    stateShort: "VLG",
    lat: "59.87067110",
    lng: "40.65554110"
    },
    {
    state: "Voronezh Oblast",
    stateShort: "VOR",
    lat: "50.85897130",
    lng: "39.86443740"
    },
    {
    state: "Yamalo-Nenets Autonomous Okrug",
    stateShort: "YAN",
    lat: "66.06530570",
    lng: "76.93451930"
    },
    {
    state: "Yaroslavl Oblast",
    stateShort: "YAR",
    lat: "57.89915230",
    lng: "38.83886330"
    },
    {
    state: "Zabaykalsky Krai",
    stateShort: "ZAB",
    lat: "53.09287710",
    lng: "116.96765610"
    }
    ]
    },
    {
    country: "Rwanda",
    countryShort: "RW",
    currency_symbol: "FRw",
    states: [
    {
    state: "Eastern Province",
    stateShort: "02",
    lat: null,
    lng: null
    },
    {
    state: "Kigali district",
    stateShort: "01",
    lat: "-1.94407270",
    lng: "30.06188510"
    },
    {
    state: "Northern Province",
    stateShort: "03",
    lat: null,
    lng: null
    },
    {
    state: "Southern Province",
    stateShort: "05",
    lat: null,
    lng: null
    },
    {
    state: "Western Province",
    stateShort: "04",
    lat: null,
    lng: null
    }
    ]
    },
   
    {
    country: "Saint Kitts And Nevis",
    countryShort: "KN",
    currency_symbol: "$",
    states: [
    {
    state: "Christ Church Nichola Town Parish",
    stateShort: "01",
    lat: "17.36048120",
    lng: "-62.76178370"
    },
    {
    state: "Nevis",
    stateShort: "N",
    lat: "17.15535580",
    lng: "-62.57960260"
    },
    {
    state: "Saint Anne Sandy Point Parish",
    stateShort: "02",
    lat: "17.37253330",
    lng: "-62.84411330"
    },
    {
    state: "Saint George Gingerland Parish",
    stateShort: "04",
    lat: "17.12577590",
    lng: "-62.56198110"
    },
    {
    state: "Saint James Windward Parish",
    stateShort: "05",
    lat: "17.17696330",
    lng: "-62.57960260"
    },
    {
    state: "Saint John Capisterre Parish",
    stateShort: "06",
    lat: "17.38103410",
    lng: "-62.79118330"
    },
    {
    state: "Saint John Figtree Parish",
    stateShort: "07",
    lat: "17.11557480",
    lng: "-62.60310040"
    },
    {
    state: "Saint Kitts",
    stateShort: "K",
    lat: "17.34337960",
    lng: "-62.75590430"
    },
    {
    state: "Saint Mary Cayon Parish",
    stateShort: "08",
    lat: "17.34620710",
    lng: "-62.73826710"
    },
    {
    state: "Saint Paul Capisterre Parish",
    stateShort: "09",
    lat: "17.40166830",
    lng: "-62.82573320"
    },
    {
    state: "Saint Paul Charlestown Parish",
    stateShort: "10",
    lat: "17.13462970",
    lng: "-62.61338160"
    },
    {
    state: "Saint Peter Basseterre Parish",
    stateShort: "11",
    lat: "17.31029110",
    lng: "-62.71475330"
    },
    {
    state: "Saint Thomas Lowland Parish",
    stateShort: "12",
    lat: "17.16505130",
    lng: "-62.60897530"
    },
    {
    state: "Saint Thomas Middle Island Parish",
    stateShort: "13",
    lat: "17.33488130",
    lng: "-62.80882510"
    },
    {
    state: "Trinity Palmetto Point Parish",
    stateShort: "15",
    lat: "17.30635190",
    lng: "-62.76178370"
    }
    ]
    },
    {
    country: "Saint Lucia",
    countryShort: "LC",
    currency_symbol: "$",
    states: [
    {
    state: "Anse la Raye Quarter",
    stateShort: "01",
    lat: "13.94594240",
    lng: "-61.03694680"
    },
    {
    state: "Canaries",
    stateShort: "12",
    lat: "28.29156370",
    lng: "-16.62913040"
    },
    {
    state: "Castries Quarter",
    stateShort: "02",
    lat: "14.01010940",
    lng: "-60.98746870"
    },
    {
    state: "Choiseul Quarter",
    stateShort: "03",
    lat: "13.77501540",
    lng: "-61.04859100"
    },
    {
    state: "Dauphin Quarter",
    stateShort: "04",
    lat: "14.01033960",
    lng: "-60.91909880"
    },
    {
    state: "Dennery Quarter",
    stateShort: "05",
    lat: "13.92673930",
    lng: "-60.91909880"
    },
    {
    state: "Gros Islet Quarter",
    stateShort: "06",
    lat: "14.08435780",
    lng: "-60.94527940"
    },
    {
    state: "Laborie Quarter",
    stateShort: "07",
    lat: "13.75227830",
    lng: "-60.99328890"
    },
    {
    state: "Micoud Quarter",
    stateShort: "08",
    lat: "13.82118710",
    lng: "-60.90019340"
    },
    {
    state: "Praslin Quarter",
    stateShort: "09",
    lat: "13.87523920",
    lng: "-60.89946630"
    },
    {
    state: "Soufrière Quarter",
    stateShort: "10",
    lat: "13.85709860",
    lng: "-61.05732480"
    },
    {
    state: "Vieux Fort Quarter",
    stateShort: "11",
    lat: "13.72060800",
    lng: "-60.94964330"
    }
    ]
    },
 
    {
    country: "Saint Vincent And The Grenadines",
    countryShort: "VC",
    currency_symbol: "$",
    states: [
    {
    state: "Charlotte Parish",
    stateShort: "01",
    lat: "13.21754510",
    lng: "-61.16362440"
    },
    {
    state: "Grenadines Parish",
    stateShort: "06",
    lat: "13.01229650",
    lng: "-61.22773010"
    },
    {
    state: "Saint Andrew Parish",
    stateShort: "02",
    lat: "43.02429990",
    lng: "-81.20250000"
    },
    {
    state: "Saint David Parish",
    stateShort: "03",
    lat: "43.85230950",
    lng: "-79.52366540"
    },
    {
    state: "Saint George Parish",
    stateShort: "04",
    lat: "42.95760900",
    lng: "-81.32670500"
    },
    {
    state: "Saint Patrick Parish",
    stateShort: "05",
    lat: "39.75091860",
    lng: "-94.84505560"
    }
    ]
    },
  
 
    {
    country: "Samoa",
    countryShort: "WS",
    currency_symbol: "SAT",
    states: [
    {
    state: "A'ana",
    stateShort: "AA",
    lat: "-13.89841800",
    lng: "-171.97529950"
    },
    {
    state: "Aiga-i-le-Tai",
    stateShort: "AL",
    lat: "-13.85137910",
    lng: "-172.03254010"
    },
    {
    state: "Atua",
    stateShort: "AT",
    lat: "-13.97870530",
    lng: "-171.62542830"
    },
    {
    state: "Fa'asaleleaga",
    stateShort: "FA",
    lat: "-13.63076380",
    lng: "-172.23659810"
    },
    {
    state: "Gaga'emauga",
    stateShort: "GE",
    lat: "-13.54286660",
    lng: "-172.36688700"
    },
    {
    state: "Gaga'ifomauga",
    stateShort: "GI",
    lat: "-13.54680070",
    lng: "-172.49693310"
    },
    {
    state: "Palauli",
    stateShort: "PA",
    lat: "-13.72945790",
    lng: "-172.45361150"
    },
    {
    state: "Satupa'itea",
    stateShort: "SA",
    lat: "-13.65382140",
    lng: "-172.61592710"
    },
    {
    state: "Tuamasaga",
    stateShort: "TU",
    lat: "-13.91635920",
    lng: "-171.82243620"
    },
    {
    state: "Va'a-o-Fonoti",
    stateShort: "VF",
    lat: "-13.94709030",
    lng: "-171.54318720"
    },
    {
    state: "Vaisigano",
    stateShort: "VS",
    lat: "-13.54138270",
    lng: "-172.70233830"
    }
    ]
    },
    {
    country: "San Marino",
    countryShort: "SM",
    currency_symbol: "€",
    states: [
    {
    state: "Acquaviva",
    stateShort: "01",
    lat: "41.86715970",
    lng: "14.74694790"
    },
    {
    state: "Borgo Maggiore",
    stateShort: "06",
    lat: "43.95748820",
    lng: "12.45525460"
    },
    {
    state: "Chiesanuova",
    stateShort: "02",
    lat: "45.42261720",
    lng: "7.65038540"
    },
    {
    state: "Domagnano",
    stateShort: "03",
    lat: "43.95019290",
    lng: "12.46815370"
    },
    {
    state: "Faetano",
    stateShort: "04",
    lat: "43.93489670",
    lng: "12.48965540"
    },
    {
    state: "Fiorentino",
    stateShort: "05",
    lat: "43.90783370",
    lng: "12.45812090"
    },
    {
    state: "Montegiardino",
    stateShort: "08",
    lat: "43.90529990",
    lng: "12.48105420"
    },
    {
    state: "San Marino",
    stateShort: "07",
    lat: "43.94236000",
    lng: "12.45777700"
    },
    {
    state: "Serravalle",
    stateShort: "09",
    lat: "44.72320840",
    lng: "8.85740050"
    }
    ]
    },
    {
    country: "Sao Tome and Principe",
    countryShort: "ST",
    currency_symbol: "Db",
    states: [
    {
    state: "Príncipe Province",
    stateShort: "P",
    lat: "1.61393810",
    lng: "7.40569280"
    },
    {
    state: "São Tomé Province",
    stateShort: "S",
    lat: "0.33019240",
    lng: "6.73334300"
    }
    ]
    },
    {
    country: "Saudi Arabia",
    countryShort: "SA",
    currency_symbol: "﷼",
    states: [
    {
    state: "'Asir",
    stateShort: "14",
    lat: "19.09690620",
    lng: "42.86378750"
    },
    {
    state: "Al Bahah",
    stateShort: "11",
    lat: "20.27227390",
    lng: "41.44125100"
    },
    {
    state: "Al Jawf",
    stateShort: "12",
    lat: "29.88735600",
    lng: "39.32062410"
    },
    {
    state: "Al Madinah",
    stateShort: "03",
    lat: "24.84039770",
    lng: "39.32062410"
    },
    {
    state: "Al-Qassim",
    stateShort: "05",
    lat: "26.20782600",
    lng: "43.48373800"
    },
    {
    state: "Eastern Province",
    stateShort: "04",
    lat: "24.04399320",
    lng: "45.65892250"
    },
    {
    state: "Ha'il",
    stateShort: "06",
    lat: "27.70761430",
    lng: "41.91964710"
    },
    {
    state: "Jizan",
    stateShort: "09",
    lat: "17.17381760",
    lng: "42.70761070"
    },
    {
    state: "Makkah",
    stateShort: "02",
    lat: "21.52355840",
    lng: "41.91964710"
    },
    {
    state: "Najran",
    stateShort: "10",
    lat: "18.35146640",
    lng: "45.60071080"
    },
    {
    state: "Northern Borders",
    stateShort: "08",
    lat: "30.07991620",
    lng: "42.86378750"
    },
    {
    state: "Riyadh",
    stateShort: "01",
    lat: "22.75543850",
    lng: "46.20915470"
    },
    {
    state: "Tabuk",
    stateShort: "07",
    lat: "28.24533350",
    lng: "37.63866220"
    }
    ]
    },
    {
    country: "Senegal",
    countryShort: "SN",
    currency_symbol: "CFA",
    states: [
    {
    state: "Dakar",
    stateShort: "DK",
    lat: "14.71667700",
    lng: "-17.46768610"
    },
    {
    state: "Diourbel Region",
    stateShort: "DB",
    lat: "14.72830850",
    lng: "-16.25221430"
    },
    {
    state: "Fatick",
    stateShort: "FK",
    lat: "14.33901670",
    lng: "-16.41114250"
    },
    {
    state: "Kaffrine",
    stateShort: "KA",
    lat: "14.10520200",
    lng: "-15.54157550"
    },
    {
    state: "Kaolack",
    stateShort: "KL",
    lat: "14.16520830",
    lng: "-16.07577490"
    },
    {
    state: "Kédougou",
    stateShort: "KE",
    lat: "12.56046070",
    lng: "-12.17470770"
    },
    {
    state: "Kolda",
    stateShort: "KD",
    lat: "12.91074950",
    lng: "-14.95056710"
    },
    {
    state: "Louga",
    stateShort: "LG",
    lat: "15.61417680",
    lng: "-16.22868000"
    },
    {
    state: "Matam",
    stateShort: "MT",
    lat: "15.66002250",
    lng: "-13.25769060"
    },
    {
    state: "Saint-Louis",
    stateShort: "SL",
    lat: "38.62700250",
    lng: "-90.19940420"
    },
    {
    state: "Sédhiou",
    stateShort: "SE",
    lat: "12.70460400",
    lng: "-15.55623040"
    },
    {
    state: "Tambacounda Region",
    stateShort: "TC",
    lat: "13.56190110",
    lng: "-13.17403480"
    },
    {
    state: "Thiès Region",
    stateShort: "TH",
    lat: "14.79100520",
    lng: "-16.93586040"
    },
    {
    state: "Ziguinchor",
    stateShort: "ZG",
    lat: "12.56414790",
    lng: "-16.26398250"
    }
    ]
    },
    {
    country: "Serbia",
    countryShort: "RS",
    currency_symbol: "din",
    states: [
    {
    state: "Belgrade",
    stateShort: "00",
    lat: "44.78656800",
    lng: "20.44892160"
    },
    {
    state: "Bor District",
    stateShort: "14",
    lat: "44.06989180",
    lng: "22.09850860"
    },
    {
    state: "Braničevo District",
    stateShort: "11",
    lat: "44.69822460",
    lng: "21.54467750"
    },
    {
    state: "Central Banat District",
    stateShort: "02",
    lat: "45.47884850",
    lng: "20.60825220"
    },
    {
    state: "Jablanica District",
    stateShort: "23",
    lat: "42.94815600",
    lng: "21.81293210"
    },
    {
    state: "Kolubara District",
    stateShort: "09",
    lat: "44.35098110",
    lng: "20.00043050"
    },
    {
    state: "Mačva District",
    stateShort: "08",
    lat: "44.59253140",
    lng: "19.50822460"
    },
    {
    state: "Moravica District",
    stateShort: "17",
    lat: "43.84147000",
    lng: "20.29049870"
    },
    {
    state: "Nišava District",
    stateShort: "20",
    lat: "43.37389020",
    lng: "21.93223310"
    },
    {
    state: "North Bačka District",
    stateShort: "01",
    lat: "45.98033940",
    lng: "19.59070010"
    },
    {
    state: "North Banat District",
    stateShort: "03",
    lat: "45.90683900",
    lng: "19.99934170"
    },
    {
    state: "Pčinja District",
    stateShort: "24",
    lat: "42.58363620",
    lng: "22.14302150"
    },
    {
    state: "Pirot District",
    stateShort: "22",
    lat: "43.08740360",
    lng: "22.59830440"
    },
    {
    state: "Podunavlje District",
    stateShort: "10",
    lat: "44.47291560",
    lng: "20.99014260"
    },
    {
    state: "Pomoravlje District",
    stateShort: "13",
    lat: "43.95913790",
    lng: "21.27135300"
    },
    {
    state: "Rasina District",
    stateShort: "19",
    lat: "43.52635250",
    lng: "21.15881780"
    },
    {
    state: "Raška District",
    stateShort: "18",
    lat: "43.33734610",
    lng: "20.57340050"
    },
    {
    state: "South Bačka District",
    stateShort: "06",
    lat: "45.48903440",
    lng: "19.69761870"
    },
    {
    state: "South Banat District",
    stateShort: "04",
    lat: "45.00274570",
    lng: "21.05425090"
    },
    {
    state: "Srem District",
    stateShort: "07",
    lat: "45.00291710",
    lng: "19.80137730"
    },
    {
    state: "Šumadija District",
    stateShort: "12",
    lat: "44.20506780",
    lng: "20.78565650"
    },
    {
    state: "Toplica District",
    stateShort: "21",
    lat: "43.19065920",
    lng: "21.34077620"
    },
    {
    state: "Vojvodina",
    stateShort: "VO",
    lat: "45.26086510",
    lng: "19.83193380"
    },
    {
    state: "West Bačka District",
    stateShort: "05",
    lat: "45.73553850",
    lng: "19.18973640"
    },
    {
    state: "Zaječar District",
    stateShort: "15",
    lat: "43.90150480",
    lng: "22.27380110"
    },
    {
    state: "Zlatibor District",
    stateShort: "16",
    lat: "43.64541700",
    lng: "19.71014550"
    }
    ]
    },
    {
    country: "Seychelles",
    countryShort: "SC",
    currency_symbol: "SRe",
    states: [
    {
    state: "Anse Boileau",
    stateShort: "02",
    lat: "-4.70472680",
    lng: "55.48593630"
    },
    {
    state: "Anse Royale",
    stateShort: "05",
    lat: "-4.74079880",
    lng: "55.50810120"
    },
    {
    state: "Anse-aux-Pins",
    stateShort: "01",
    lat: "-4.69004430",
    lng: "55.51502890"
    },
    {
    state: "Au Cap",
    stateShort: "04",
    lat: "-4.70597230",
    lng: "55.50810120"
    },
    {
    state: "Baie Lazare",
    stateShort: "06",
    lat: "-4.74825250",
    lng: "55.48593630"
    },
    {
    state: "Baie Sainte Anne",
    stateShort: "07",
    lat: "47.05259000",
    lng: "-64.95245790"
    },
    {
    state: "Beau Vallon",
    stateShort: "08",
    lat: "-4.62109670",
    lng: "55.42778020"
    },
    {
    state: "Bel Air",
    stateShort: "09",
    lat: "34.10024550",
    lng: "-118.45946300"
    },
    {
    state: "Bel Ombre",
    stateShort: "10",
    lat: "-20.50100950",
    lng: "57.42596240"
    },
    {
    state: "Cascade",
    stateShort: "11",
    lat: "44.51628210",
    lng: "-116.04179830"
    },
    {
    state: "Glacis",
    stateShort: "12",
    lat: "47.11573030",
    lng: "-70.30281830"
    },
    {
    state: "Grand'Anse Mahé",
    stateShort: "13",
    lat: "-4.67739200",
    lng: "55.46377700"
    },
    {
    state: "Grand'Anse Praslin",
    stateShort: "14",
    lat: "-4.31762190",
    lng: "55.70783630"
    },
    {
    state: "La Digue",
    stateShort: "15",
    lat: "49.76669220",
    lng: "-97.15466290"
    },
    {
    state: "La Rivière Anglaise",
    stateShort: "16",
    lat: "-4.61061500",
    lng: "55.45408410"
    },
    {
    state: "Les Mamelles",
    stateShort: "24",
    lat: "38.82505050",
    lng: "-90.48345170"
    },
    {
    state: "Mont Buxton",
    stateShort: "17",
    lat: "-4.61666670",
    lng: "55.44577680"
    },
    {
    state: "Mont Fleuri",
    stateShort: "18",
    lat: "-4.63565430",
    lng: "55.45546880"
    },
    {
    state: "Plaisance",
    stateShort: "19",
    lat: "45.60709500",
    lng: "-75.11427450"
    },
    {
    state: "Pointe La Rue",
    stateShort: "20",
    lat: "-4.68048900",
    lng: "55.51918570"
    },
    {
    state: "Port Glaud",
    stateShort: "21",
    lat: "-4.64885230",
    lng: "55.41947530"
    },
    {
    state: "Roche Caiman",
    stateShort: "25",
    lat: "-4.63960280",
    lng: "55.46793150"
    },
    {
    state: "Saint Louis",
    stateShort: "22",
    lat: "38.62700250",
    lng: "-90.19940420"
    },
    {
    state: "Takamaka",
    stateShort: "23",
    lat: "37.96459170",
    lng: "-1.22177270"
    }
    ]
    },
    {
    country: "Sierra Leone",
    countryShort: "SL",
    currency_symbol: "Le",
    states: [
    {
    state: "Eastern Province",
    stateShort: "E",
    lat: null,
    lng: null
    },
    {
    state: "Northern Province",
    stateShort: "N",
    lat: null,
    lng: null
    },
    {
    state: "Southern Province",
    stateShort: "S",
    lat: null,
    lng: null
    },
    {
    state: "Western Area",
    stateShort: "W",
    lat: "40.25459690",
    lng: "-80.24554440"
    }
    ]
    },
    {
    country: "Singapore",
    countryShort: "SG",
    currency_symbol: "$",
    states: [
    {
    state: "Central Singapore Community Development Council",
    stateShort: "01",
    lat: "1.28951400",
    lng: "103.81438790"
    },
    {
    state: "North East Community Development Council",
    stateShort: "02",
    lat: "45.01181130",
    lng: "-93.24681070"
    },
    {
    state: "North West Community Development Council",
    stateShort: "03",
    lat: "39.10709300",
    lng: "-94.45733600"
    },
    {
    state: "South East Community Development Council",
    stateShort: "04",
    lat: "39.28630700",
    lng: "-76.56912370"
    },
    {
    state: "South West Community Development Council",
    stateShort: "05",
    lat: "39.92569100",
    lng: "-75.23105800"
    }
    ]
    },
  
    {
    country: "Slovakia",
    countryShort: "SK",
    currency_symbol: "€",
    states: [
    {
    state: "Banská Bystrica Region",
    stateShort: "BC",
    lat: "48.53124990",
    lng: "19.38287400"
    },
    {
    state: "Bratislava Region",
    stateShort: "BL",
    lat: "48.31183040",
    lng: "17.19732990"
    },
    {
    state: "Košice Region",
    stateShort: "KI",
    lat: "48.63757370",
    lng: "21.08342250"
    },
    {
    state: "Nitra Region",
    stateShort: "NI",
    lat: "48.01437650",
    lng: "18.54165040"
    },
    {
    state: "Prešov Region",
    stateShort: "PV",
    lat: "49.17167730",
    lng: "21.37420010"
    },
    {
    state: "Trenčín Region",
    stateShort: "TC",
    lat: "48.80867580",
    lng: "18.23240260"
    },
    {
    state: "Trnava Region",
    stateShort: "TA",
    lat: "48.39438980",
    lng: "17.72162050"
    },
    {
    state: "Žilina Region",
    stateShort: "ZI",
    lat: "49.20314350",
    lng: "19.36457330"
    }
    ]
    },
    {
    country: "Slovenia",
    countryShort: "SI",
    currency_symbol: "€",
    states: [
    {
    state: "Ajdovščina Municipality",
    stateShort: "001",
    lat: "45.88707760",
    lng: "13.90428180"
    },
    {
    state: "Ankaran Municipality",
    stateShort: "213",
    lat: "45.57845100",
    lng: "13.73691740"
    },
    {
    state: "Beltinci Municipality",
    stateShort: "002",
    lat: "46.60791530",
    lng: "16.23651270"
    },
    {
    state: "Benedikt Municipality",
    stateShort: "148",
    lat: "46.61558410",
    lng: "15.89572810"
    },
    {
    state: "Bistrica ob Sotli Municipality",
    stateShort: "149",
    lat: "46.05655790",
    lng: "15.66259470"
    },
    {
    state: "Bled Municipality",
    stateShort: "003",
    lat: "46.36832660",
    lng: "14.11457980"
    },
    {
    state: "Bloke Municipality",
    stateShort: "150",
    lat: "45.77281410",
    lng: "14.50634590"
    },
    {
    state: "Bohinj Municipality",
    stateShort: "004",
    lat: "46.30056520",
    lng: "13.94271950"
    },
    {
    state: "Borovnica Municipality",
    stateShort: "005",
    lat: "45.90445250",
    lng: "14.38241890"
    },
    {
    state: "Bovec Municipality",
    stateShort: "006",
    lat: "46.33804950",
    lng: "13.55241740"
    },
    {
    state: "Braslovče Municipality",
    stateShort: "151",
    lat: "46.28361920",
    lng: "15.04183200"
    },
    {
    state: "Brda Municipality",
    stateShort: "007",
    lat: "45.99756520",
    lng: "13.52704740"
    },
    {
    state: "Brežice Municipality",
    stateShort: "009",
    lat: "45.90410960",
    lng: "15.59436390"
    },
    {
    state: "Brezovica Municipality",
    stateShort: "008",
    lat: "45.95593510",
    lng: "14.43499520"
    },
    {
    state: "Cankova Municipality",
    stateShort: "152",
    lat: "46.71823700",
    lng: "16.01972220"
    },
    {
    state: "Cerklje na Gorenjskem Municipality",
    stateShort: "012",
    lat: "46.25170540",
    lng: "14.48579790"
    },
    {
    state: "Cerknica Municipality",
    stateShort: "013",
    lat: "45.79662550",
    lng: "14.39217700"
    },
    {
    state: "Cerkno Municipality",
    stateShort: "014",
    lat: "46.12884140",
    lng: "13.98940270"
    },
    {
    state: "Cerkvenjak Municipality",
    stateShort: "153",
    lat: "46.56707110",
    lng: "15.94297530"
    },
    {
    state: "City Municipality of Celje",
    stateShort: "011",
    lat: "46.23974950",
    lng: "15.26770630"
    },
    {
    state: "City Municipality of Novo Mesto",
    stateShort: "085",
    lat: "45.80108240",
    lng: "15.17100890"
    },
    {
    state: "Črenšovci Municipality",
    stateShort: "015",
    lat: "46.57200290",
    lng: "16.28773460"
    },
    {
    state: "Črna na Koroškem Municipality",
    stateShort: "016",
    lat: "46.47045290",
    lng: "14.84999980"
    },
    {
    state: "Črnomelj Municipality",
    stateShort: "017",
    lat: "45.53612250",
    lng: "15.19441430"
    },
    {
    state: "Destrnik Municipality",
    stateShort: "018",
    lat: "46.49223680",
    lng: "15.87779560"
    },
    {
    state: "Divača Municipality",
    stateShort: "019",
    lat: "45.68060690",
    lng: "13.97203120"
    },
    {
    state: "Dobje Municipality",
    stateShort: "154",
    lat: "46.13700370",
    lng: "15.39412900"
    },
    {
    state: "Dobrepolje Municipality",
    stateShort: "020",
    lat: "45.85249510",
    lng: "14.70831090"
    },
    {
    state: "Dobrna Municipality",
    stateShort: "155",
    lat: "46.33561410",
    lng: "15.22597320"
    },
    {
    state: "Dobrova–Polhov Gradec Municipality",
    stateShort: "021",
    lat: "46.06488960",
    lng: "14.31681950"
    },
    {
    state: "Dobrovnik Municipality",
    stateShort: "156",
    lat: "46.65386620",
    lng: "16.35065940"
    },
    {
    state: "Dol pri Ljubljani Municipality",
    stateShort: "022",
    lat: "46.08843860",
    lng: "14.64247920"
    },
    {
    state: "Dolenjske Toplice Municipality",
    stateShort: "157",
    lat: "45.73457110",
    lng: "15.01294930"
    },
    {
    state: "Domžale Municipality",
    stateShort: "023",
    lat: "46.14382690",
    lng: "14.63752790"
    },
    {
    state: "Dornava Municipality",
    stateShort: "024",
    lat: "46.44435130",
    lng: "15.98891590"
    },
    {
    state: "Dravograd Municipality",
    stateShort: "025",
    lat: "46.58921900",
    lng: "15.02460210"
    },
    {
    state: "Duplek Municipality",
    stateShort: "026",
    lat: "46.50100160",
    lng: "15.75463070"
    },
    {
    state: "Gorenja Vas–Poljane Municipality",
    stateShort: "027",
    lat: "46.11165820",
    lng: "14.11493480"
    },
    {
    state: "Gorišnica Municipality",
    stateShort: "028",
    lat: "46.41202710",
    lng: "16.01330890"
    },
    {
    state: "Gorje Municipality",
    stateShort: "207",
    lat: "46.38024580",
    lng: "14.06853390"
    },
    {
    state: "Gornja Radgona Municipality",
    stateShort: "029",
    lat: "46.67670990",
    lng: "15.99108470"
    },
    {
    state: "Gornji Grad Municipality",
    stateShort: "030",
    lat: "46.29617120",
    lng: "14.80623470"
    },
    {
    state: "Gornji Petrovci Municipality",
    stateShort: "031",
    lat: "46.80371280",
    lng: "16.21913790"
    },
    {
    state: "Grad Municipality",
    stateShort: "158",
    lat: "46.80873200",
    lng: "16.10920600"
    },
    {
    state: "Grosuplje Municipality",
    stateShort: "032",
    lat: "45.95576450",
    lng: "14.65889900"
    },
    {
    state: "Hajdina Municipality",
    stateShort: "159",
    lat: "46.41850140",
    lng: "15.82447220"
    },
    {
    state: "Hoče–Slivnica Municipality",
    stateShort: "160",
    lat: "46.47785800",
    lng: "15.64760050"
    },
    {
    state: "Hodoš Municipality",
    stateShort: "161",
    lat: "46.83141340",
    lng: "16.32106800"
    },
    {
    state: "Horjul Municipality",
    stateShort: "162",
    lat: "46.02253780",
    lng: "14.29862690"
    },
    {
    state: "Hrastnik Municipality",
    stateShort: "034",
    lat: "46.14172880",
    lng: "15.08448940"
    },
    {
    state: "Hrpelje–Kozina Municipality",
    stateShort: "035",
    lat: "45.60911920",
    lng: "13.93791480"
    },
    {
    state: "Idrija Municipality",
    stateShort: "036",
    lat: "46.00409390",
    lng: "13.97754930"
    },
    {
    state: "Ig Municipality",
    stateShort: "037",
    lat: "45.95888680",
    lng: "14.52705280"
    },
    {
    state: "Ivančna Gorica Municipality",
    stateShort: "039",
    lat: "45.93958410",
    lng: "14.80476260"
    },
    {
    state: "Izola Municipality",
    stateShort: "040",
    lat: "45.53135570",
    lng: "13.66646490"
    },
    {
    state: "Jesenice Municipality",
    stateShort: "041",
    lat: "46.43670470",
    lng: "14.05260570"
    },
    {
    state: "Jezersko Municipality",
    stateShort: "163",
    lat: "46.39427940",
    lng: "14.49855590"
    },
    {
    state: "Juršinci Municipality",
    stateShort: "042",
    lat: "46.48986510",
    lng: "15.98092300"
    },
    {
    state: "Kamnik Municipality",
    stateShort: "043",
    lat: "46.22216660",
    lng: "14.60707270"
    },
    {
    state: "Kanal ob Soči Municipality",
    stateShort: "044",
    lat: "46.06735300",
    lng: "13.62033500"
    },
    {
    state: "Kidričevo Municipality",
    stateShort: "045",
    lat: "46.39575720",
    lng: "15.79259060"
    },
    {
    state: "Kobarid Municipality",
    stateShort: "046",
    lat: "46.24569710",
    lng: "13.57869490"
    },
    {
    state: "Kobilje Municipality",
    stateShort: "047",
    lat: "46.68518000",
    lng: "16.39367190"
    },
    {
    state: "Kočevje Municipality",
    stateShort: "048",
    lat: "45.64280000",
    lng: "14.86158380"
    },
    {
    state: "Komen Municipality",
    stateShort: "049",
    lat: "45.81752350",
    lng: "13.74827110"
    },
    {
    state: "Komenda Municipality",
    stateShort: "164",
    lat: "46.20648800",
    lng: "14.53824990"
    },
    {
    state: "Koper City Municipality",
    stateShort: "050",
    lat: "45.54805900",
    lng: "13.73018770"
    },
    {
    state: "Kostanjevica na Krki Municipality",
    stateShort: "197",
    lat: "45.83166380",
    lng: "15.44119060"
    },
    {
    state: "Kostel Municipality",
    stateShort: "165",
    lat: "45.49282550",
    lng: "14.87082350"
    },
    {
    state: "Kozje Municipality",
    stateShort: "051",
    lat: "46.07332110",
    lng: "15.55967190"
    },
    {
    state: "Kranj City Municipality",
    stateShort: "052",
    lat: "46.25850210",
    lng: "14.35435690"
    },
    {
    state: "Kranjska Gora Municipality",
    stateShort: "053",
    lat: "46.48452930",
    lng: "13.78571450"
    },
    {
    state: "Križevci Municipality",
    stateShort: "166",
    lat: "46.57018210",
    lng: "16.10926530"
    },
    {
    state: "Kungota",
    stateShort: "055",
    lat: "46.64187930",
    lng: "15.60362880"
    },
    {
    state: "Kuzma Municipality",
    stateShort: "056",
    lat: "46.83510380",
    lng: "16.08071000"
    },
    {
    state: "Laško Municipality",
    stateShort: "057",
    lat: "46.15422360",
    lng: "15.23614910"
    },
    {
    state: "Lenart Municipality",
    stateShort: "058",
    lat: "46.58344240",
    lng: "15.82621250"
    },
    {
    state: "Lendava Municipality",
    stateShort: "059",
    lat: "46.55134830",
    lng: "16.44198390"
    },
    {
    state: "Litija Municipality",
    stateShort: "060",
    lat: "46.05732260",
    lng: "14.83096360"
    },
    {
    state: "Ljubljana City Municipality",
    stateShort: "061",
    lat: "46.05694650",
    lng: "14.50575150"
    },
    {
    state: "Ljubno Municipality",
    stateShort: "062",
    lat: "46.34431250",
    lng: "14.83354920"
    },
    {
    state: "Ljutomer Municipality",
    stateShort: "063",
    lat: "46.51908480",
    lng: "16.18932160"
    },
    {
    state: "Log–Dragomer Municipality",
    stateShort: "208",
    lat: "46.01787470",
    lng: "14.36877670"
    },
    {
    state: "Logatec Municipality",
    stateShort: "064",
    lat: "45.91761100",
    lng: "14.23514510"
    },
    {
    state: "Loška Dolina Municipality",
    stateShort: "065",
    lat: "45.64779080",
    lng: "14.49731470"
    },
    {
    state: "Loški Potok Municipality",
    stateShort: "066",
    lat: "45.69096370",
    lng: "14.59859700"
    },
    {
    state: "Lovrenc na Pohorju Municipality",
    stateShort: "167",
    lat: "46.54196380",
    lng: "15.40004430"
    },
    {
    state: "Luče Municipality",
    stateShort: "067",
    lat: "46.35449250",
    lng: "14.74715040"
    },
    {
    state: "Lukovica Municipality",
    stateShort: "068",
    lat: "46.16962930",
    lng: "14.69072590"
    },
    {
    state: "Majšperk Municipality",
    stateShort: "069",
    lat: "46.35030190",
    lng: "15.73405950"
    },
    {
    state: "Makole Municipality",
    stateShort: "198",
    lat: "46.31686970",
    lng: "15.66641260"
    },
    {
    state: "Maribor City Municipality",
    stateShort: "070",
    lat: "46.55064960",
    lng: "15.62054390"
    },
    {
    state: "Markovci Municipality",
    stateShort: "168",
    lat: "46.38793090",
    lng: "15.95860140"
    },
    {
    state: "Medvode Municipality",
    stateShort: "071",
    lat: "46.14190800",
    lng: "14.40325960"
    },
    {
    state: "Mengeš Municipality",
    stateShort: "072",
    lat: "46.16591220",
    lng: "14.57196940"
    },
    {
    state: "Metlika Municipality",
    stateShort: "073",
    lat: "45.64807150",
    lng: "15.31778380"
    },
    {
    state: "Mežica Municipality",
    stateShort: "074",
    lat: "46.52150270",
    lng: "14.85213400"
    },
    {
    state: "Miklavž na Dravskem Polju Municipality",
    stateShort: "169",
    lat: "46.50826280",
    lng: "15.69520650"
    },
    {
    state: "Miren–Kostanjevica Municipality",
    stateShort: "075",
    lat: "45.84360290",
    lng: "13.62766470"
    },
    {
    state: "Mirna Municipality",
    stateShort: "212",
    lat: "45.95156470",
    lng: "15.06209770"
    },
    {
    state: "Mirna Peč Municipality",
    stateShort: "170",
    lat: "45.84815740",
    lng: "15.08794500"
    },
    {
    state: "Mislinja Municipality",
    stateShort: "076",
    lat: "46.44294030",
    lng: "15.19876780"
    },
    {
    state: "Mokronog–Trebelno Municipality",
    stateShort: "199",
    lat: "45.90885290",
    lng: "15.15967360"
    },
    {
    state: "Moravče Municipality",
    stateShort: "077",
    lat: "46.13627810",
    lng: "14.74600100"
    },
    {
    state: "Moravske Toplice Municipality",
    stateShort: "078",
    lat: "46.68569320",
    lng: "16.22245820"
    },
    {
    state: "Mozirje Municipality",
    stateShort: "079",
    lat: "46.33943500",
    lng: "14.96024130"
    },
    {
    state: "Municipality of Apače",
    stateShort: "195",
    lat: "46.69746790",
    lng: "15.91025340"
    },
    {
    state: "Municipality of Cirkulane",
    stateShort: "196",
    lat: "46.32983220",
    lng: "15.99806660"
    },
    {
    state: "Municipality of Ilirska Bistrica",
    stateShort: "038",
    lat: "45.57913230",
    lng: "14.28097290"
    },
    {
    state: "Municipality of Krško",
    stateShort: "054",
    lat: "45.95896090",
    lng: "15.49235550"
    },
    {
    state: "Municipality of Škofljica",
    stateShort: "123",
    lat: "45.98409620",
    lng: "14.57466260"
    },
    {
    state: "Murska Sobota City Municipality",
    stateShort: "080",
    lat: "46.64321470",
    lng: "16.15157540"
    },
    {
    state: "Muta Municipality",
    stateShort: "081",
    lat: "46.60973660",
    lng: "15.16299950"
    },
    {
    state: "Naklo Municipality",
    stateShort: "082",
    lat: "46.27186630",
    lng: "14.31569320"
    },
    {
    state: "Nazarje Municipality",
    stateShort: "083",
    lat: "46.28217410",
    lng: "14.92256290"
    },
    {
    state: "Nova Gorica City Municipality",
    stateShort: "084",
    lat: "45.97627600",
    lng: "13.73088810"
    },
    {
    state: "Odranci Municipality",
    stateShort: "086",
    lat: "46.59010170",
    lng: "16.27881650"
    },
    {
    state: "Oplotnica",
    stateShort: "171",
    lat: "46.38716300",
    lng: "15.44581310"
    },
    {
    state: "Ormož Municipality",
    stateShort: "087",
    lat: "46.43533330",
    lng: "16.15437400"
    },
    {
    state: "Osilnica Municipality",
    stateShort: "088",
    lat: "45.54184670",
    lng: "14.71563030"
    },
    {
    state: "Pesnica Municipality",
    stateShort: "089",
    lat: "46.60887550",
    lng: "15.67570510"
    },
    {
    state: "Piran Municipality",
    stateShort: "090",
    lat: "45.52888560",
    lng: "13.56807350"
    },
    {
    state: "Pivka Municipality",
    stateShort: "091",
    lat: "45.67892960",
    lng: "14.25426890"
    },
    {
    state: "Podčetrtek Municipality",
    stateShort: "092",
    lat: "46.17395420",
    lng: "15.60138160"
    },
    {
    state: "Podlehnik Municipality",
    stateShort: "172",
    lat: "46.33107820",
    lng: "15.87858360"
    },
    {
    state: "Podvelka Municipality",
    stateShort: "093",
    lat: "46.62219520",
    lng: "15.38899220"
    },
    {
    state: "Poljčane Municipality",
    stateShort: "200",
    lat: "46.31398530",
    lng: "15.57847910"
    },
    {
    state: "Polzela Municipality",
    stateShort: "173",
    lat: "46.28089700",
    lng: "15.07373210"
    },
    {
    state: "Postojna Municipality",
    stateShort: "094",
    lat: "45.77493900",
    lng: "14.21342630"
    },
    {
    state: "Prebold Municipality",
    stateShort: "174",
    lat: "46.23591360",
    lng: "15.09369120"
    },
    {
    state: "Preddvor Municipality",
    stateShort: "095",
    lat: "46.30171390",
    lng: "14.42181650"
    },
    {
    state: "Prevalje Municipality",
    stateShort: "175",
    lat: "46.56211460",
    lng: "14.88478610"
    },
    {
    state: "Ptuj City Municipality",
    stateShort: "096",
    lat: "46.41995350",
    lng: "15.86968840"
    },
    {
    state: "Puconci Municipality",
    stateShort: "097",
    lat: "46.72004180",
    lng: "16.09977920"
    },
    {
    state: "Rače–Fram Municipality",
    stateShort: "098",
    lat: "46.45420830",
    lng: "15.63294670"
    },
    {
    state: "Radeče Municipality",
    stateShort: "099",
    lat: "46.06669540",
    lng: "15.18204380"
    },
    {
    state: "Radenci Municipality",
    stateShort: "100",
    lat: "46.62311210",
    lng: "16.05069030"
    },
    {
    state: "Radlje ob Dravi Municipality",
    stateShort: "101",
    lat: "46.61357320",
    lng: "15.23544380"
    },
    {
    state: "Radovljica Municipality",
    stateShort: "102",
    lat: "46.33558270",
    lng: "14.20945340"
    },
    {
    state: "Ravne na Koroškem Municipality",
    stateShort: "103",
    lat: "46.55211940",
    lng: "14.95990840"
    },
    {
    state: "Razkrižje Municipality",
    stateShort: "176",
    lat: "46.52263390",
    lng: "16.26686380"
    },
    {
    state: "Rečica ob Savinji Municipality",
    stateShort: "209",
    lat: "46.32337900",
    lng: "14.92236700"
    },
    {
    state: "Renče–Vogrsko Municipality",
    stateShort: "201",
    lat: "45.89546170",
    lng: "13.67856730"
    },
    {
    state: "Ribnica Municipality",
    stateShort: "104",
    lat: "45.74003030",
    lng: "14.72657820"
    },
    {
    state: "Ribnica na Pohorju Municipality",
    stateShort: "177",
    lat: "46.53561450",
    lng: "15.26745380"
    },
    {
    state: "Rogaška Slatina Municipality",
    stateShort: "106",
    lat: "46.24539730",
    lng: "15.62650140"
    },
    {
    state: "Rogašovci Municipality",
    stateShort: "105",
    lat: "46.80557850",
    lng: "16.03452370"
    },
    {
    state: "Rogatec Municipality",
    stateShort: "107",
    lat: "46.22866260",
    lng: "15.69913380"
    },
    {
    state: "Ruše Municipality",
    stateShort: "108",
    lat: "46.52062650",
    lng: "15.48178690"
    },
    {
    state: "Šalovci Municipality",
    stateShort: "033",
    lat: "46.85335680",
    lng: "16.25917910"
    },
    {
    state: "Selnica ob Dravi Municipality",
    stateShort: "178",
    lat: "46.55139180",
    lng: "15.49294100"
    },
    {
    state: "Semič Municipality",
    stateShort: "109",
    lat: "45.65205340",
    lng: "15.18207010"
    },
    {
    state: "Šempeter–Vrtojba Municipality",
    stateShort: "183",
    lat: "45.92900950",
    lng: "13.64155940"
    },
    {
    state: "Šenčur Municipality",
    stateShort: "117",
    lat: "46.24336990",
    lng: "14.41922230"
    },
    {
    state: "Šentilj Municipality",
    stateShort: "118",
    lat: "46.68628390",
    lng: "15.71035670"
    },
    {
    state: "Šentjernej Municipality",
    stateShort: "119",
    lat: "45.84341300",
    lng: "15.33783120"
    },
    {
    state: "Šentjur Municipality",
    stateShort: "120",
    lat: "46.26543390",
    lng: "15.40800000"
    },
    {
    state: "Šentrupert Municipality",
    stateShort: "211",
    lat: "45.98731420",
    lng: "15.08297830"
    },
    {
    state: "Sevnica Municipality",
    stateShort: "110",
    lat: "46.00703170",
    lng: "15.30456790"
    },
    {
    state: "Sežana Municipality",
    stateShort: "111",
    lat: "45.72751090",
    lng: "13.86619310"
    },
    {
    state: "Škocjan Municipality",
    stateShort: "121",
    lat: "45.91754540",
    lng: "15.31017360"
    },
    {
    state: "Škofja Loka Municipality",
    stateShort: "122",
    lat: "46.14098440",
    lng: "14.28118730"
    },
    {
    state: "Slovenj Gradec City Municipality",
    stateShort: "112",
    lat: "46.48777180",
    lng: "15.07294780"
    },
    {
    state: "Slovenska Bistrica Municipality",
    stateShort: "113",
    lat: "46.39198130",
    lng: "15.57278690"
    },
    {
    state: "Slovenske Konjice Municipality",
    stateShort: "114",
    lat: "46.33691910",
    lng: "15.42147080"
    },
    {
    state: "Šmarje pri Jelšah Municipality",
    stateShort: "124",
    lat: "46.22870250",
    lng: "15.51903530"
    },
    {
    state: "Šmarješke Toplice Municipality",
    stateShort: "206",
    lat: "45.86803770",
    lng: "15.23474220"
    },
    {
    state: "Šmartno ob Paki Municipality",
    stateShort: "125",
    lat: "46.32903720",
    lng: "15.03339370"
    },
    {
    state: "Šmartno pri Litiji Municipality",
    stateShort: "194",
    lat: "46.04549710",
    lng: "14.84101330"
    },
    {
    state: "Sodražica Municipality",
    stateShort: "179",
    lat: "45.76165650",
    lng: "14.63528530"
    },
    {
    state: "Solčava Municipality",
    stateShort: "180",
    lat: "46.40235260",
    lng: "14.68023040"
    },
    {
    state: "Šoštanj Municipality",
    stateShort: "126",
    lat: "46.37828360",
    lng: "15.04613780"
    },
    {
    state: "Središče ob Dravi",
    stateShort: "202",
    lat: "46.39592820",
    lng: "16.27049150"
    },
    {
    state: "Starše Municipality",
    stateShort: "115",
    lat: "46.46743310",
    lng: "15.76405460"
    },
    {
    state: "Štore Municipality",
    stateShort: "127",
    lat: "46.22225140",
    lng: "15.31261160"
    },
    {
    state: "Straža Municipality",
    stateShort: "203",
    lat: "45.77684280",
    lng: "15.09486940"
    },
    {
    state: "Sveta Ana Municipality",
    stateShort: "181",
    lat: "46.65000000",
    lng: "15.84527800"
    },
    {
    state: "Sveta Trojica v Slovenskih Goricah Municipality",
    stateShort: "204",
    lat: "46.56808090",
    lng: "15.88230640"
    },
    {
    state: "Sveti Andraž v Slovenskih Goricah Municipality",
    stateShort: "182",
    lat: "46.51897470",
    lng: "15.94982620"
    },
    {
    state: "Sveti Jurij ob Ščavnici Municipality",
    stateShort: "116",
    lat: "46.56874520",
    lng: "16.02225280"
    },
    {
    state: "Sveti Jurij v Slovenskih Goricah Municipality",
    stateShort: "210",
    lat: "46.61707910",
    lng: "15.78046770"
    },
    {
    state: "Sveti Tomaž Municipality",
    stateShort: "205",
    lat: "46.48352830",
    lng: "16.07944200"
    },
    {
    state: "Tabor Municipality",
    stateShort: "184",
    lat: "46.21079210",
    lng: "15.01742490"
    },
    {
    state: "Tišina Municipality",
    stateShort: "010",
    lat: "46.65418840",
    lng: "16.07547810"
    },
    {
    state: "Tolmin Municipality",
    stateShort: "128",
    lat: "46.18571880",
    lng: "13.73198380"
    },
    {
    state: "Trbovlje Municipality",
    stateShort: "129",
    lat: "46.15035630",
    lng: "15.04531370"
    },
    {
    state: "Trebnje Municipality",
    stateShort: "130",
    lat: "45.90801630",
    lng: "15.01319050"
    },
    {
    state: "Trnovska Vas Municipality",
    stateShort: "185",
    lat: "46.52940350",
    lng: "15.88531180"
    },
    {
    state: "Tržič Municipality",
    stateShort: "131",
    lat: "46.35935140",
    lng: "14.30066230"
    },
    {
    state: "Trzin Municipality",
    stateShort: "186",
    lat: "46.12982410",
    lng: "14.55776370"
    },
    {
    state: "Turnišče Municipality",
    stateShort: "132",
    lat: "46.61375040",
    lng: "16.32021000"
    },
    {
    state: "Velika Polana Municipality",
    stateShort: "187",
    lat: "46.57317150",
    lng: "16.34441260"
    },
    {
    state: "Velike Lašče Municipality",
    stateShort: "134",
    lat: "45.83365910",
    lng: "14.63623630"
    },
    {
    state: "Veržej Municipality",
    stateShort: "188",
    lat: "46.58411350",
    lng: "16.16208000"
    },
    {
    state: "Videm Municipality",
    stateShort: "135",
    lat: "46.36383300",
    lng: "15.87812120"
    },
    {
    state: "Vipava Municipality",
    stateShort: "136",
    lat: "45.84126740",
    lng: "13.96096130"
    },
    {
    state: "Vitanje Municipality",
    stateShort: "137",
    lat: "46.38153230",
    lng: "15.29506870"
    },
    {
    state: "Vodice Municipality",
    stateShort: "138",
    lat: "46.18966430",
    lng: "14.49385390"
    },
    {
    state: "Vojnik Municipality",
    stateShort: "139",
    lat: "46.29205810",
    lng: "15.30205800"
    },
    {
    state: "Vransko Municipality",
    stateShort: "189",
    lat: "46.23900600",
    lng: "14.95272490"
    },
    {
    state: "Vrhnika Municipality",
    stateShort: "140",
    lat: "45.95027190",
    lng: "14.32764220"
    },
    {
    state: "Vuzenica Municipality",
    stateShort: "141",
    lat: "46.59808360",
    lng: "15.16572370"
    },
    {
    state: "Zagorje ob Savi Municipality",
    stateShort: "142",
    lat: "46.13452020",
    lng: "14.99643840"
    },
    {
    state: "Žalec Municipality",
    stateShort: "190",
    lat: "46.25197120",
    lng: "15.16500720"
    },
    {
    state: "Zavrč Municipality",
    stateShort: "143",
    lat: "46.35713000",
    lng: "16.04777470"
    },
    {
    state: "Železniki Municipality",
    stateShort: "146",
    lat: "46.22563770",
    lng: "14.16936170"
    },
    {
    state: "Žetale Municipality",
    stateShort: "191",
    lat: "46.27428330",
    lng: "15.79133590"
    },
    {
    state: "Žiri Municipality",
    stateShort: "147",
    lat: "46.04724990",
    lng: "14.10984510"
    },
    {
    state: "Žirovnica Municipality",
    stateShort: "192",
    lat: "46.39544030",
    lng: "14.15396320"
    },
    {
    state: "Zreče Municipality",
    stateShort: "144",
    lat: "46.41777860",
    lng: "15.37094310"
    },
    {
    state: "Žužemberk Municipality",
    stateShort: "193",
    lat: "45.82003500",
    lng: "14.95359190"
    }
    ]
    },
    {
    country: "Solomon Islands",
    countryShort: "SB",
    currency_symbol: "Si$",
    states: [
    {
    state: "Central Province",
    stateShort: "CE",
    lat: null,
    lng: null
    },
    {
    state: "Choiseul Province",
    stateShort: "CH",
    lat: "-7.05014940",
    lng: "156.95114590"
    },
    {
    state: "Guadalcanal Province",
    stateShort: "GU",
    lat: "-9.57732840",
    lng: "160.14558050"
    },
    {
    state: "Honiara",
    stateShort: "CT",
    lat: "-9.44563810",
    lng: "159.97289990"
    },
    {
    state: "Isabel Province",
    stateShort: "IS",
    lat: "-8.05923530",
    lng: "159.14470810"
    },
    {
    state: "Makira-Ulawa Province",
    stateShort: "MK",
    lat: "-10.57374470",
    lng: "161.80969410"
    },
    {
    state: "Malaita Province",
    stateShort: "ML",
    lat: "-8.94461680",
    lng: "160.90712360"
    },
    {
    state: "Rennell and Bellona Province",
    stateShort: "RB",
    lat: "-11.61314350",
    lng: "160.16939490"
    },
    {
    state: "Temotu Province",
    stateShort: "TE",
    lat: "-10.68692900",
    lng: "166.06239790"
    },
    {
    state: "Western Province",
    stateShort: "WE",
    lat: null,
    lng: null
    }
    ]
    },
    {
    country: "Somalia",
    countryShort: "SO",
    currency_symbol: "Sh.so.",
    states: [
    {
    state: "Awdal Region",
    stateShort: "AW",
    lat: "10.63342850",
    lng: "43.32946600"
    },
    {
    state: "Bakool",
    stateShort: "BK",
    lat: "4.36572210",
    lng: "44.09603110"
    },
    {
    state: "Banaadir",
    stateShort: "BN",
    lat: "2.11873750",
    lng: "45.33694590"
    },
    {
    state: "Bari",
    stateShort: "BR",
    lat: "41.11714320",
    lng: "16.87187150"
    },
    {
    state: "Bay",
    stateShort: "BY",
    lat: "37.03655340",
    lng: "-95.61747670"
    },
    {
    state: "Galguduud",
    stateShort: "GA",
    lat: "5.18501280",
    lng: "46.82528380"
    },
    {
    state: "Gedo",
    stateShort: "GE",
    lat: "3.50392270",
    lng: "42.23624350"
    },
    {
    state: "Hiran",
    stateShort: "HI",
    lat: "4.32101500",
    lng: "45.29938620"
    },
    {
    state: "Lower Juba",
    stateShort: "JH",
    lat: "0.22402100",
    lng: "41.60118140"
    },
    {
    state: "Lower Shebelle",
    stateShort: "SH",
    lat: "1.87664580",
    lng: "44.24790150"
    },
    {
    state: "Middle Juba",
    stateShort: "JD",
    lat: "2.07804880",
    lng: "41.60118140"
    },
    {
    state: "Middle Shebelle",
    stateShort: "SD",
    lat: "2.92502470",
    lng: "45.90396890"
    },
    {
    state: "Mudug",
    stateShort: "MU",
    lat: "6.56567260",
    lng: "47.76375650"
    },
    {
    state: "Nugal",
    stateShort: "NU",
    lat: "43.27938610",
    lng: "17.03392050"
    },
    {
    state: "Sanaag Region",
    stateShort: "SA",
    lat: "10.39382180",
    lng: "47.76375650"
    },
    {
    state: "Togdheer Region",
    stateShort: "TO",
    lat: "9.44605870",
    lng: "45.29938620"
    }
    ]
    },
    {
    country: "South Africa",
    countryShort: "ZA",
    currency_symbol: "R",
    states: [
    {
    state: "Eastern Cape",
    stateShort: "EC",
    lat: "-32.29684020",
    lng: "26.41938900"
    },
    {
    state: "Free State",
    stateShort: "FS",
    lat: "37.68585250",
    lng: "-97.28112560"
    },
    {
    state: "Gauteng",
    stateShort: "GP",
    lat: "-26.27075930",
    lng: "28.11226790"
    },
    {
    state: "KwaZulu-Natal",
    stateShort: "KZN",
    lat: "-28.53055390",
    lng: "30.89582420"
    },
    {
    state: "Limpopo",
    stateShort: "LP",
    lat: "-23.40129460",
    lng: "29.41793240"
    },
    {
    state: "Mpumalanga",
    stateShort: "MP",
    lat: "-25.56533600",
    lng: "30.52790960"
    },
    {
    state: "North West",
    stateShort: "NW",
    lat: "32.75885200",
    lng: "-97.32880600"
    },
    {
    state: "Northern Cape",
    stateShort: "NC",
    lat: "-29.04668080",
    lng: "21.85685860"
    },
    {
    state: "Western Cape",
    stateShort: "WC",
    lat: "-33.22779180",
    lng: "21.85685860"
    }
    ]
    },
  
    {
    country: "South Korea",
    countryShort: "KR",
    currency_symbol: "₩",
    states: [
    {
    state: "Busan",
    stateShort: "26",
    lat: "35.17955430",
    lng: "129.07564160"
    },
    {
    state: "Daegu",
    stateShort: "27",
    lat: "35.87143540",
    lng: "128.60144500"
    },
    {
    state: "Daejeon",
    stateShort: "30",
    lat: "36.35041190",
    lng: "127.38454750"
    },
    {
    state: "gangwon-do",
    stateShort: "42",
    lat: "37.82280000",
    lng: "128.15550000"
    },
    {
    state: "jeollanam-do",
    stateShort: "29",
    lat: "35.15954540",
    lng: "126.85260120"
    },
    {
    state: "gyeonggi-do",
    stateShort: "41",
    lat: "37.41380000",
    lng: "127.51830000"
    },
    {
    state: "Incheon",
    stateShort: "28",
    lat: "37.45625570",
    lng: "126.70520620"
    },
    {
    state: "Jeju",
    stateShort: "49",
    lat: "33.95682780",
    lng: "-84.13135000"
    },
    {
    state: "chungcheongbuk-do",
    stateShort: "43",
    lat: "36.80000000",
    lng: "127.70000000"
    },
    {
    state: "gyeongsangbuk-do",
    stateShort: "47",
    lat: "36.49190000",
    lng: "128.88890000"
    },
    {
    state: "jeollabuk-do",
    stateShort: "45",
    lat: "35.71750000",
    lng: "127.15300000"
    },
    {
    state: "sejong-si",
    stateShort: "50",
    lat: "34.05233230",
    lng: "-118.30848970"
    },
    {
    state: "Seoul",
    stateShort: "11",
    lat: "37.56653500",
    lng: "126.97796920"
    },
    {
    state: "Chungcheongnam-do",
    stateShort: "44",
    lat: "36.51840000",
    lng: "126.80000000"
    },
    {
    state: "gyeongsangnam-do",
    stateShort: "48",
    lat: "35.46060000",
    lng: "128.21320000"
    },
    {
    state: "jeollanam-do",
    stateShort: "46",
    lat: "34.86790000",
    lng: "126.99100000"
    },
    {
    state: "Ulsan",
    stateShort: "31",
    lat: "35.53837730",
    lng: "129.31135960"
    }
    ]
    },
    {
    country: "South Sudan",
    countryShort: "SS",
    currency_symbol: "£",
    states: [
    {
    state: "Central Equatoria",
    stateShort: "EC",
    lat: "4.61440630",
    lng: "31.26263660"
    },
    {
    state: "Eastern Equatoria",
    stateShort: "EE",
    lat: "5.06929950",
    lng: "33.43835300"
    },
    {
    state: "Jonglei State",
    stateShort: "JG",
    lat: "7.18196190",
    lng: "32.35609520"
    },
    {
    state: "Lakes",
    stateShort: "LK",
    lat: "37.16282550",
    lng: "-95.69116230"
    },
    {
    state: "Northern Bahr el Ghazal",
    stateShort: "BN",
    lat: "8.53604490",
    lng: "26.79678490"
    },
    {
    state: "Unity",
    stateShort: "UY",
    lat: "37.78712760",
    lng: "-122.40340790"
    },
    {
    state: "Upper Nile",
    stateShort: "NU",
    lat: "9.88942020",
    lng: "32.71813750"
    },
    {
    state: "Warrap",
    stateShort: "WR",
    lat: "8.08862380",
    lng: "28.64106410"
    },
    {
    state: "Western Bahr el Ghazal",
    stateShort: "BW",
    lat: "8.64523990",
    lng: "25.28375850"
    },
    {
    state: "Western Equatoria",
    stateShort: "EW",
    lat: "5.34717990",
    lng: "28.29943500"
    }
    ]
    },
    {
    country: "Spain",
    countryShort: "ES",
    currency_symbol: "€",
    states: [
      {
         state: "Catalunya",
         stateShort: "CT",
         lat: "41.39266790",
         lng: "2.14018910"
         },
      {
         state: "andalucía",
         stateShort: "AN",
         lat: "37.3833",
         lng: "-5.9833"
         },
    {
    state: "A Coruña",
    stateShort: "C",
    lat: "43.36190400",
    lng: "-8.43019320"
    },
    {
    state: "Albacete",
    stateShort: "AB",
    lat: "38.99223120",
    lng: "-1.87809890"
    },
    {
    state: "Alicante",
    stateShort: "A",
    lat: "38.35795460",
    lng: "-0.54256340"
    },
    {
    state: "Almeria",
    stateShort: "AL",
    lat: "36.84152680",
    lng: "-2.47462610"
    },
    {
    state: "Araba",
    stateShort: "VI",
    lat: "42.83951190",
    lng: "-3.84237740"
    },
    {
    state: "Asturias",
    stateShort: "O",
    lat: "43.36139530",
    lng: "-5.85932670"
    },
    {
    state: "Ávila",
    stateShort: "AV",
    lat: "40.69345110",
    lng: "-4.89356270"
    },
    {
    state: "Badajoz",
    stateShort: "BA",
    lat: "38.87937480",
    lng: "-7.02269830"
    },
    {
    state: "Barcelona",
    stateShort: "B",
    lat: "41.39266790",
    lng: "2.14018910"
    },
    {
    state: "Bizkaia",
    stateShort: "BI",
    lat: "43.21921990",
    lng: "-3.21110870"
    },
    {
    state: "Burgos",
    stateShort: "BU",
    lat: "42.33807580",
    lng: "-3.58126920"
    },
    {
    state: "Caceres",
    stateShort: "CC",
    lat: "39.47163130",
    lng: "-6.42573840"
    },
    {
    state: "Cádiz",
    stateShort: "CA",
    lat: "36.51638510",
    lng: "-6.29997670"
    },
    {
    state: "Cantabria",
    stateShort: "S",
    lat: "43.18283960",
    lng: "-3.98784270"
    },
    {
    state: "Castellón",
    stateShort: "CS",
    lat: "39.98114350",
    lng: "0.00884070"
    },
    {
    state: "Ciudad Real",
    stateShort: "CR",
    lat: "38.98607580",
    lng: "-3.94449750"
    },
    {
    state: "Córdoba",
    stateShort: "CO",
    lat: "36.51638510",
    lng: "-6.29997670"
    },
    {
    state: "Cuenca",
    stateShort: "CU",
    lat: "40.06200360",
    lng: "-2.16553440"
    },
    {
    state: "Gipuzkoa",
    stateShort: "SS",
    lat: "43.14523600",
    lng: "-2.44618250"
    },
    {
    state: "Girona",
    stateShort: "GI",
    lat: "41.98034450",
    lng: "2.80115770"
    },
    {
    state: "Granada",
    stateShort: "GR",
    lat: "37.18094110",
    lng: "-3.62629100"
    },
    {
    state: "Guadalajara",
    stateShort: "GU",
    lat: "40.63222140",
    lng: "-3.19068200"
    },
    {
    state: "Huelva",
    stateShort: "H",
    lat: "37.27086660",
    lng: "-6.95719990"
    },
    {
    state: "Huesca",
    stateShort: "HU",
    lat: "41.59762750",
    lng: "-0.90566230"
    },
    {
    state: "Islas Baleares",
    stateShort: "PM",
    lat: "39.35877590",
    lng: "2.73563280"
    },
    {
    state: "Jaén",
    stateShort: "J",
    lat: "37.78009310",
    lng: "-3.81437450"
    },
    {
    state: "La Rioja",
    stateShort: "LO",
    lat: "42.28707330",
    lng: "-2.53960300"
    },
    {
    state: "Las Palmas",
    stateShort: "GC",
    lat: "28.29156370",
    lng: "-16.62913040"
    },
    {
    state: "Léon",
    stateShort: "LE",
    lat: "42.59870410",
    lng: "-5.56708390"
    },
    {
    state: "Lleida",
    stateShort: "L",
    lat: "41.61837310",
    lng: "0.60242530"
    },
    {
    state: "Lugo",
    stateShort: "LU",
    lat: "43.01231370",
    lng: "-7.57400960"
    },
    {
    state: "Madrid",
    stateShort: "M",
    lat: "40.41675150",
    lng: "-3.70383220"
    },
    {
    state: "Málaga",
    stateShort: "MA",
    lat: "36.71820150",
    lng: "-4.51930600"
    },
    {
    state: "Murcia",
    stateShort: "MU",
    lat: "38.13981410",
    lng: "-1.36621600"
    },
    {
    state: "Navarra",
    stateShort: "NA",
    lat: "42.69539090",
    lng: "-1.67606910"
    },
    {
    state: "Ourense",
    stateShort: "OR",
    lat: "42.33836130",
    lng: "-7.88119510"
    },
    {
    state: "Palencia",
    stateShort: "P",
    lat: "42.00968320",
    lng: "-4.52879490"
    },
    {
    state: "Pontevedra",
    stateShort: "PO",
    lat: "42.43385950",
    lng: "-8.65685520"
    },
    {
    state: "Salamanca",
    stateShort: "SA",
    lat: "40.95152630",
    lng: "-6.23759470"
    },
    {
    state: "Santa Cruz de Tenerife",
    stateShort: "TF",
    lat: "28.45789140",
    lng: "-16.32135390"
    },
    {
    state: "Segovia",
    stateShort: "SG",
    lat: "40.94292960",
    lng: "-4.10889420"
    },
    {
    state: "Sevilla",
    stateShort: "SE",
    lat: "37.37535010",
    lng: "-6.02509730"
    },
    {
    state: "Soria",
    stateShort: "SO",
    lat: "41.76654640",
    lng: "-2.47903060"
    },
    {
    state: "Tarragona",
    stateShort: "T",
    lat: "41.12586420",
    lng: "1.20356420"
    },
    {
    state: "Teruel",
    stateShort: "TE",
    lat: "40.34504100",
    lng: "-1.11847440"
    },
    {
    state: "Toledo",
    stateShort: "TO",
    lat: "39.86232000",
    lng: "-4.06946920"
    },
    {
    state: "Valencia",
    stateShort: "V",
    lat: "39.48401080",
    lng: "-0.75328090"
    },
    {
    state: "Valladolid",
    stateShort: "VA",
    lat: "41.65173750",
    lng: "-4.72449500"
    },
    {
    state: "Zamora",
    stateShort: "ZA",
    lat: "41.60957440",
    lng: "-5.89871390"
    },
    {
    state: "Zaragoza",
    stateShort: "Z",
    lat: "41.65175010",
    lng: "-0.93000020"
    },
  
    ]
    },
    {
    country: "Sri Lanka",
    countryShort: "LK",
    currency_symbol: "Rs",
    states: [
    {
    state: "Ampara District",
    stateShort: "52",
    lat: "7.29116850",
    lng: "81.67237610"
    },
    {
    state: "Anuradhapura District",
    stateShort: "71",
    lat: "8.33183050",
    lng: "80.40290170"
    },
    {
    state: "Badulla District",
    stateShort: "81",
    lat: "6.99340090",
    lng: "81.05498150"
    },
    {
    state: "Batticaloa District",
    stateShort: "51",
    lat: "7.82927810",
    lng: "81.47183870"
    },
    {
    state: "Central Province",
    stateShort: "2",
    lat: null,
    lng: null
    },
    {
    state: "Colombo District",
    stateShort: "11",
    lat: "6.92695570",
    lng: "79.86173060"
    },
    {
    state: "Eastern Province",
    stateShort: "5",
    lat: null,
    lng: null
    },
    {
    state: "Galle District",
    stateShort: "31",
    lat: "6.05774900",
    lng: "80.21755720"
    },
    {
    state: "Gampaha District",
    stateShort: "12",
    lat: "7.07126190",
    lng: "80.00877460"
    },
    {
    state: "Hambantota District",
    stateShort: "33",
    lat: "6.15358160",
    lng: "81.12714900"
    },
    {
    state: "Jaffna District",
    stateShort: "41",
    lat: "9.69304680",
    lng: "80.16518540"
    },
    {
    state: "Kalutara District",
    stateShort: "13",
    lat: "6.60846860",
    lng: "80.14285840"
    },
    {
    state: "Kandy District",
    stateShort: "21",
    lat: "7.29315880",
    lng: "80.63501070"
    },
    {
    state: "Kegalle District",
    stateShort: "92",
    lat: "7.12040530",
    lng: "80.32131060"
    },
    {
    state: "Kilinochchi District",
    stateShort: "42",
    lat: "9.36779710",
    lng: "80.32131060"
    },
    {
    state: "Mannar District",
    stateShort: "43",
    lat: "8.98095310",
    lng: "79.90439750"
    },
    {
    state: "Matale District",
    stateShort: "22",
    lat: "7.46596460",
    lng: "80.62342590"
    },
    {
    state: "Matara District",
    stateShort: "32",
    lat: "5.94493480",
    lng: "80.54879970"
    },
    {
    state: "Monaragala District",
    stateShort: "82",
    lat: "6.87277810",
    lng: "81.35068320"
    },
    {
    state: "Mullaitivu District",
    stateShort: "45",
    lat: "9.26753880",
    lng: "80.81282540"
    },
    {
    state: "North Central Province",
    stateShort: "7",
    lat: "8.19956380",
    lng: "80.63269160"
    },
    {
    state: "North Western Province",
    stateShort: "6",
    lat: "7.75840910",
    lng: "80.18750650"
    },
    {
    state: "Northern Province",
    stateShort: "4",
    lat: null,
    lng: null
    },
    {
    state: "Nuwara Eliya District",
    stateShort: "23",
    lat: "6.96065320",
    lng: "80.76927580"
    },
    {
    state: "Polonnaruwa District",
    stateShort: "72",
    lat: "7.93955670",
    lng: "81.00034030"
    },
    {
    state: "Puttalam District",
    stateShort: "62",
    lat: "8.02599150",
    lng: "79.84712720"
    },
    {
    state: "Ratnapura district",
    stateShort: "91",
    lat: "6.70551680",
    lng: "80.38483890"
    },
    {
    state: "Sabaragamuwa Province",
    stateShort: "9",
    lat: "6.73959410",
    lng: "80.36586500"
    },
    {
    state: "Southern Province",
    stateShort: "3",
    lat: null,
    lng: null
    },
    {
    state: "Trincomalee District",
    stateShort: "53",
    lat: "8.60130690",
    lng: "81.11960750"
    },
    {
    state: "Uva Province",
    stateShort: "8",
    lat: "6.84276120",
    lng: "81.33994140"
    },
    {
    state: "Vavuniya District",
    stateShort: "44",
    lat: "8.75947390",
    lng: "80.50003340"
    },
    {
    state: "Western Province",
    stateShort: "1",
    lat: null,
    lng: null
    }
    ]
    },
    {
    country: "Sudan",
    countryShort: "SD",
    currency_symbol: ".س.ج",
    states: [
    {
    state: "Al Jazirah",
    stateShort: "GZ",
    lat: "14.88596110",
    lng: "33.43835300"
    },
    {
    state: "Al Qadarif",
    stateShort: "GD",
    lat: "14.02430700",
    lng: "35.36856790"
    },
    {
    state: "Blue Nile",
    stateShort: "NB",
    lat: "47.59867300",
    lng: "-122.33441900"
    },
    {
    state: "Central Darfur",
    stateShort: "DC",
    lat: "14.37827470",
    lng: "24.90422080"
    },
    {
    state: "East Darfur",
    stateShort: "DE",
    lat: "14.37827470",
    lng: "24.90422080"
    },
    {
    state: "Kassala",
    stateShort: "KA",
    lat: "15.45813320",
    lng: "36.40396290"
    },
    {
    state: "Khartoum",
    stateShort: "KH",
    lat: "15.50065440",
    lng: "32.55989940"
    },
    {
    state: "North Darfur",
    stateShort: "DN",
    lat: "15.76619690",
    lng: "24.90422080"
    },
    {
    state: "North Kordofan",
    stateShort: "KN",
    lat: "13.83064410",
    lng: "29.41793240"
    },
    {
    state: "Northern",
    stateShort: "NO",
    lat: "38.06381700",
    lng: "-84.46286480"
    },
    {
    state: "Red Sea",
    stateShort: "RS",
    lat: "20.28023200",
    lng: "38.51257300"
    },
    {
    state: "River Nile",
    stateShort: "NR",
    lat: "23.97275950",
    lng: "32.87492060"
    },
    {
    state: "Sennar",
    stateShort: "SI",
    lat: "13.56746900",
    lng: "33.56720450"
    },
    {
    state: "South Darfur",
    stateShort: "DS",
    lat: "11.64886390",
    lng: "24.90422080"
    },
    {
    state: "South Kordofan",
    stateShort: "KS",
    lat: "11.19901920",
    lng: "29.41793240"
    },
    {
    state: "West Darfur",
    stateShort: "DW",
    lat: "12.84635610",
    lng: "23.00119890"
    },
    {
    state: "West Kordofan",
    stateShort: "GK",
    lat: "11.19901920",
    lng: "29.41793240"
    },
    {
    state: "White Nile",
    stateShort: "NW",
    lat: "9.33215160",
    lng: "31.46153000"
    }
    ]
    },
    {
    country: "Suriname",
    countryShort: "SR",
    currency_symbol: "$",
    states: [
    {
    state: "Brokopondo District",
    stateShort: "BR",
    lat: "4.77102470",
    lng: "-55.04933750"
    },
    {
    state: "Commewijne District",
    stateShort: "CM",
    lat: "5.74021100",
    lng: "-54.87312190"
    },
    {
    state: "Coronie District",
    stateShort: "CR",
    lat: "5.69432710",
    lng: "-56.29293810"
    },
    {
    state: "Marowijne District",
    stateShort: "MA",
    lat: "5.62681280",
    lng: "-54.25931180"
    },
    {
    state: "Nickerie District",
    stateShort: "NI",
    lat: "5.58554690",
    lng: "-56.83111170"
    },
    {
    state: "Para District",
    stateShort: "PR",
    lat: "5.48173180",
    lng: "-55.22592070"
    },
    {
    state: "Paramaribo District",
    stateShort: "PM",
    lat: "5.85203550",
    lng: "-55.20382780"
    },
    {
    state: "Saramacca District",
    stateShort: "SA",
    lat: "5.72408130",
    lng: "-55.66896360"
    },
    {
    state: "Sipaliwini District",
    stateShort: "SI",
    lat: "3.65673820",
    lng: "-56.20353870"
    },
    {
    state: "Wanica District",
    stateShort: "WA",
    lat: "5.73237620",
    lng: "-55.27012350"
    }
    ]
    },
    
    {
    country: "Swaziland",
    countryShort: "SZ",
    currency_symbol: "E",
    states: [
    {
    state: "Hhohho District",
    stateShort: "HH",
    lat: "-26.13656620",
    lng: "31.35416310"
    },
    {
    state: "Lubombo District",
    stateShort: "LU",
    lat: "-26.78517730",
    lng: "31.81070790"
    },
    {
    state: "Manzini District",
    stateShort: "MA",
    lat: "-26.50819990",
    lng: "31.37131640"
    },
    {
    state: "Shiselweni District",
    stateShort: "SH",
    lat: "-26.98275770",
    lng: "31.35416310"
    }
    ]
    },
    {
    country: "Sweden",
    countryShort: "SE",
    currency_symbol: "kr",
    states: [
    {
    state: "Blekinge län",
    stateShort: "K",
    lat: "56.27838370",
    lng: "15.01800580"
    },
    {
    state: "Dalarnas län",
    stateShort: "W",
    lat: "61.09170120",
    lng: "14.66636530"
    },
    {
    state: "Gävleborgs län",
    stateShort: "X",
    lat: "61.30119930",
    lng: "16.15342140"
    },
    {
    state: "Gotlands län",
    stateShort: "I",
    lat: "57.46841210",
    lng: "18.48674470"
    },
    {
    state: "Hallands län",
    stateShort: "N",
    lat: "56.89668050",
    lng: "12.80339930"
    },
    {
    state: "Jönköpings län",
    stateShort: "F",
    lat: "57.37084340",
    lng: "14.34391740"
    },
    {
    state: "Kalmar län",
    stateShort: "H",
    lat: "57.23501560",
    lng: "16.18493490"
    },
    {
    state: "Kronobergs län",
    stateShort: "G",
    lat: "56.71834030",
    lng: "14.41146730"
    },
    {
    state: "Norrbottens län",
    stateShort: "BD",
    lat: "66.83092160",
    lng: "20.39919660"
    },
    {
    state: "Örebro län",
    stateShort: "T",
    lat: "59.53503600",
    lng: "15.00657310"
    },
    {
    state: "Östergötlands län",
    stateShort: "E",
    lat: "58.34536350",
    lng: "15.51978440"
    },
    {
    state: "Skåne län",
    stateShort: "M",
    lat: "55.99025720",
    lng: "13.59576920"
    },
    {
    state: "Södermanlands län",
    stateShort: "D",
    lat: "59.03363490",
    lng: "16.75188990"
    },
    {
    state: "Stockholms län",
    stateShort: "AB",
    lat: "59.60249580",
    lng: "18.13843830"
    },
    {
    state: "Uppsala län",
    stateShort: "C",
    lat: "60.00922620",
    lng: "17.27145880"
    },
    {
    state: "Värmlands län",
    stateShort: "S",
    lat: "59.72940650",
    lng: "13.23540240"
    },
    {
    state: "Västerbottens län",
    stateShort: "AC",
    lat: "65.33373110",
    lng: "16.51616940"
    },
    {
    state: "Västernorrlands län",
    stateShort: "Y",
    lat: "63.42764730",
    lng: "17.72924440"
    },
    {
    state: "Västmanlands län",
    stateShort: "U",
    lat: "59.67138790",
    lng: "16.21589530"
    },
    {
    state: "Västra Götalands län",
    stateShort: "O",
    lat: "58.25279260",
    lng: "13.05964250"
    },
    {
    state: "Jämtlands län",
    stateShort: "Z",
    lat: "63.15",
    lng: "14.75"
    }
    ]
    },
    {
    country: "Switzerland",
    countryShort: "CH",
    currency_symbol: "CHf",
    states: [
    {
    state: "Aargau",
    stateShort: "AG",
    lat: "47.38766640",
    lng: "8.25542950"
    },
    {
    state: "Appenzell Ausserrhoden",
    stateShort: "AR",
    lat: "47.36648100",
    lng: "9.30009160"
    },
    {
    state: "Appenzell Innerrhoden",
    stateShort: "AI",
    lat: "47.31619250",
    lng: "9.43165730"
    },
    {
    state: "Basel-Land",
    stateShort: "BL",
    lat: "47.44181220",
    lng: "7.76440020"
    },
    {
    state: "Basel-Stadt",
    stateShort: "BS",
    lat: "47.56666700",
    lng: "7.60000000"
    },
    {
    state: "Bern",
    stateShort: "BE",
    lat: "46.79886210",
    lng: "7.70807010"
    },
    {
    state: "Fribourg",
    stateShort: "FR",
    lat: "46.68167480",
    lng: "7.11726350"
    },
    {
    state: "genève",
    stateShort: "GE",
    lat: "46.21800730",
    lng: "6.12169250"
    },
    {
    state: "Glarus",
    stateShort: "GL",
    lat: "47.04112320",
    lng: "9.06790000"
    },
    {
    state: "Graubünden",
    stateShort: "GR",
    lat: "46.65698710",
    lng: "9.57802570"
    },
    {
    state: "Jura",
    stateShort: "JU",
    lat: "47.34444740",
    lng: "7.14306080"
    },
    {
    state: "Lucerne",
    stateShort: "LU",
    lat: "47.07956710",
    lng: "8.16624450"
    },
    {
    state: "Neuchâtel",
    stateShort: "NE",
    lat: "46.98998740",
    lng: "6.92927320"
    },
    {
    state: "Nidwalden",
    stateShort: "NW",
    lat: "46.92670160",
    lng: "8.38499820"
    },
    {
    state: "Obwalden",
    stateShort: "OW",
    lat: "46.87785800",
    lng: "8.25124900"
    },
    {
    state: "Schaffhausen",
    stateShort: "SH",
    lat: "47.70093640",
    lng: "8.56800400"
    },
    {
    state: "Schwyz",
    stateShort: "SZ",
    lat: "47.02071380",
    lng: "8.65298840"
    },
    {
    state: "Solothurn",
    stateShort: "SO",
    lat: "47.33207170",
    lng: "7.63883850"
    },
    {
    state: "St. Gallen",
    stateShort: "SG",
    lat: "47.14562540",
    lng: "9.35043320"
    },
    {
    state: "Thurgau",
    stateShort: "TG",
    lat: "47.60378560",
    lng: "9.05573710"
    },
    {
    state: "Ticino",
    stateShort: "TI",
    lat: "46.33173400",
    lng: "8.80045290"
    },
    {
    state: "Uri",
    stateShort: "UR",
    lat: "41.48606470",
    lng: "-71.53085370"
    },
    {
    state: "Valais",
    stateShort: "VS",
    lat: "46.19046140",
    lng: "7.54492260"
    },
    {
    state: "Vaud",
    stateShort: "VD",
    lat: "46.56131350",
    lng: "6.53676500"
    },
    {
    state: "Zug",
    stateShort: "ZG",
    lat: "47.16615050",
    lng: "8.51547490"
    },
    {
    state: "Zürich",
    stateShort: "ZH",
    lat: "47.35953600",
    lng: "8.63564520"
    }
    ]
    },
    {
    country: "Syria",
    countryShort: "SY",
    currency_symbol: "LS",
    states: [
    {
    state: "Al-Hasakah",
    stateShort: "HA",
    lat: "36.40551500",
    lng: "40.79691490"
    },
    {
    state: "Al-Raqqah",
    stateShort: "RA",
    lat: "35.95941060",
    lng: "38.99810520"
    },
    {
    state: "Aleppo",
    stateShort: "HL",
    lat: "36.22623930",
    lng: "37.46813960"
    },
    {
    state: "As-Suwayda",
    stateShort: "SU",
    lat: "32.79891560",
    lng: "36.78195050"
    },
    {
    state: "Damascus",
    stateShort: "DI",
    lat: "33.51514440",
    lng: "36.39313540"
    },
    {
    state: "Daraa",
    stateShort: "DR",
    lat: "32.92488130",
    lng: "36.17626150"
    },
    {
    state: "Deir ez-Zor",
    stateShort: "DY",
    lat: "35.28797980",
    lng: "40.30886260"
    },
    {
    state: "Hama",
    stateShort: "HM",
    lat: "35.18878650",
    lng: "37.21158290"
    },
    {
    state: "Homs",
    stateShort: "HI",
    lat: "34.25671230",
    lng: "38.31657250"
    },
    {
    state: "Idlib",
    stateShort: "ID",
    lat: "35.82687980",
    lng: "36.69572160"
    },
    {
    state: "Latakia",
    stateShort: "LA",
    lat: "35.61297910",
    lng: "36.00232250"
    },
    {
    state: "Quneitra",
    stateShort: "QU",
    lat: "33.07763180",
    lng: "35.89341360"
    },
    {
    state: "Rif Dimashq",
    stateShort: "RD",
    lat: "33.51672890",
    lng: "36.95410700"
    },
    {
    state: "Tartus",
    stateShort: "TA",
    lat: "35.00066520",
    lng: "36.00232250"
    }
    ]
    },
    {
    country: "Taiwan",
    countryShort: "TW",
    currency_symbol: "$",
    states: [
    {
    state: "Changhua",
    stateShort: "CHA",
    lat: "24.05179630",
    lng: "120.51613520"
    },
    {
    state: "Chiayi",
    stateShort: "CYI",
    lat: "23.45184280",
    lng: "120.25546150"
    },
    {
    state: "Chiayi",
    stateShort: "CYQ",
    lat: "23.48007510",
    lng: "120.44911130"
    },
    {
    state: "Hsinchu",
    stateShort: "HSQ",
    lat: "24.83872260",
    lng: "121.01772460"
    },
    {
    state: "Hsinchu",
    stateShort: "HSZ",
    lat: "24.81382870",
    lng: "120.96747980"
    },
    {
    state: "Hualien",
    stateShort: "HUA",
    lat: "23.98715890",
    lng: "121.60157140"
    },
    {
    state: "Kaohsiung",
    stateShort: "KHH",
    lat: "22.62727840",
    lng: "120.30143530"
    },
    {
    state: "Keelung",
    stateShort: "KEE",
    lat: "25.12418620",
    lng: "121.64758340"
    },
    {
    state: "Kinmen",
    stateShort: "KIN",
    lat: "24.34877920",
    lng: "118.32856440"
    },
    {
    state: "Lienchiang",
    stateShort: "LIE",
    lat: "26.15055560",
    lng: "119.92888890"
    },
    {
    state: "Miaoli",
    stateShort: "MIA",
    lat: "24.56015900",
    lng: "120.82142650"
    },
    {
    state: "Nantou",
    stateShort: "NAN",
    lat: "23.96099810",
    lng: "120.97186380"
    },
    {
    state: "New Taipei",
    stateShort: "NWT",
    lat: "24.98752780",
    lng: "121.36459470"
    },
    {
    state: "Penghu",
    stateShort: "PEN",
    lat: "23.57118990",
    lng: "119.57931570"
    },
    {
    state: "Pingtung",
    stateShort: "PIF",
    lat: "22.55197590",
    lng: "120.54875970"
    },
    {
    state: "Taichung",
    stateShort: "TXG",
    lat: "24.14773580",
    lng: "120.67364820"
    },
    {
    state: "Tainan",
    stateShort: "TNN",
    lat: "22.99972810",
    lng: "120.22702770"
    },
    {
    state: "Taipei",
    stateShort: "TPE",
    lat: "25.03296940",
    lng: "121.56541770"
    },
    {
    state: "Taitung",
    stateShort: "TTT",
    lat: "22.79724470",
    lng: "121.07137020"
    },
    {
    state: "Taoyuan",
    stateShort: "TAO",
    lat: "24.99362810",
    lng: "121.30097980"
    },
    {
    state: "Yilan",
    stateShort: "ILA",
    lat: "24.70210730",
    lng: "121.73775020"
    },
    {
    state: "Yunlin",
    stateShort: "YUN",
    lat: "23.70920330",
    lng: "120.43133730"
    }
    ]
    },
    {
    country: "Tajikistan",
    countryShort: "TJ",
    currency_symbol: "SM",
    states: [
    {
    state: "districts of Republican Subordination",
    stateShort: "RA",
    lat: "39.08579020",
    lng: "70.24083250"
    },
    {
    state: "Gorno-Badakhshan Autonomous Province",
    stateShort: "GB",
    lat: "38.41273200",
    lng: "73.08774900"
    },
    {
    state: "Khatlon Province",
    stateShort: "KT",
    lat: "37.91135620",
    lng: "69.09702300"
    },
    {
    state: "Sughd Province",
    stateShort: "SU",
    lat: "39.51553260",
    lng: "69.09702300"
    }
    ]
    },
    {
    country: "Tanzania",
    countryShort: "TZ",
    currency_symbol: "TSh",
    states: [
    {
    state: "Arusha",
    stateShort: "01",
    lat: "-3.38692540",
    lng: "36.68299270"
    },
    {
    state: "Dar es Salaam",
    stateShort: "02",
    lat: "-6.79235400",
    lng: "39.20832840"
    },
    {
    state: "Dodoma",
    stateShort: "03",
    lat: "-6.57382280",
    lng: "36.26308460"
    },
    {
    state: "Geita",
    stateShort: "27",
    lat: "-2.82422570",
    lng: "32.26538870"
    },
    {
    state: "Iringa",
    stateShort: "04",
    lat: "-7.78874420",
    lng: "35.56578620"
    },
    {
    state: "Kagera",
    stateShort: "05",
    lat: "-1.30011150",
    lng: "31.26263660"
    },
    {
    state: "Katavi",
    stateShort: "28",
    lat: "-6.36771250",
    lng: "31.26263660"
    },
    {
    state: "Kigoma",
    stateShort: "08",
    lat: "-4.88240920",
    lng: "29.66150550"
    },
    {
    state: "Kilimanjaro",
    stateShort: "09",
    lat: "-4.13369270",
    lng: "37.80876930"
    },
    {
    state: "Lindi",
    stateShort: "12",
    lat: "-9.23433940",
    lng: "38.31657250"
    },
    {
    state: "Manyara",
    stateShort: "26",
    lat: "-4.31500580",
    lng: "36.95410700"
    },
    {
    state: "Mara",
    stateShort: "13",
    lat: "-1.77535380",
    lng: "34.15319470"
    },
    {
    state: "Mbeya",
    stateShort: "14",
    lat: "-8.28661120",
    lng: "32.81325370"
    },
    {
    state: "Morogoro",
    stateShort: "16",
    lat: "-8.81371730",
    lng: "36.95410700"
    },
    {
    state: "Mtwara",
    stateShort: "17",
    lat: "-10.33984550",
    lng: "40.16574660"
    },
    {
    state: "Mwanza",
    stateShort: "18",
    lat: "-2.46711970",
    lng: "32.89868120"
    },
    {
    state: "Njombe",
    stateShort: "29",
    lat: "-9.24226320",
    lng: "35.12687810"
    },
    {
    state: "Pemba North",
    stateShort: "06",
    lat: "-5.03193520",
    lng: "39.77555710"
    },
    {
    state: "Pemba South",
    stateShort: "10",
    lat: "-5.31469610",
    lng: "39.75495110"
    },
    {
    state: "Pwani",
    stateShort: "19",
    lat: "-7.32377140",
    lng: "38.82054540"
    },
    {
    state: "Rukwa",
    stateShort: "20",
    lat: "-8.01094440",
    lng: "31.44561790"
    },
    {
    state: "Ruvuma",
    stateShort: "21",
    lat: "-10.68787170",
    lng: "36.26308460"
    },
    {
    state: "Shinyanga",
    stateShort: "22",
    lat: "-3.68099610",
    lng: "33.42714030"
    },
    {
    state: "Simiyu",
    stateShort: "30",
    lat: "-2.83087380",
    lng: "34.15319470"
    },
    {
    state: "Singida",
    stateShort: "23",
    lat: "-6.74533520",
    lng: "34.15319470"
    },
    {
    state: "Songwe",
    stateShort: "31",
    lat: "-8.27261200",
    lng: "31.71131740"
    },
    {
    state: "Tabora",
    stateShort: "24",
    lat: "-5.03421380",
    lng: "32.80844960"
    },
    {
    state: "Tanga",
    stateShort: "25",
    lat: "-5.30497890",
    lng: "38.31657250"
    },
    {
    state: "Zanzibar North",
    stateShort: "07",
    lat: "-5.93950930",
    lng: "39.27910110"
    },
    {
    state: "Zanzibar South",
    stateShort: "11",
    lat: "-6.26428510",
    lng: "39.44502810"
    },
    {
    state: "Zanzibar West",
    stateShort: "15",
    lat: "-6.22981360",
    lng: "39.25832930"
    }
    ]
    },
    {
    country: "Thailand",
    countryShort: "TH",
    currency_symbol: "฿",
    states: [
    {
    state: "Amnat Charoen",
    stateShort: "37",
    lat: "15.86567830",
    lng: "104.62577740"
    },
    {
    state: "Ang Thong",
    stateShort: "15",
    lat: "14.58960540",
    lng: "100.45505200"
    },
    {
    state: "Bangkok",
    stateShort: "10",
    lat: "13.75633090",
    lng: "100.50176510"
    },
    {
    state: "Bueng Kan",
    stateShort: "38",
    lat: "18.36091040",
    lng: "103.64644630"
    },
    {
    state: "Buri Ram",
    stateShort: "31",
    lat: "14.99510030",
    lng: "103.11159150"
    },
    {
    state: "Chachoengsao",
    stateShort: "24",
    lat: "13.69041940",
    lng: "101.07795960"
    },
    {
    state: "Chai Nat",
    stateShort: "18",
    lat: "15.18519710",
    lng: "100.12512500"
    },
    {
    state: "Chaiyaphum",
    stateShort: "36",
    lat: "16.00749740",
    lng: "101.61291720"
    },
    {
    state: "Chanthaburi",
    stateShort: "22",
    lat: "12.61124850",
    lng: "102.10378060"
    },
    {
    state: "Chiang Mai",
    stateShort: "50",
    lat: "18.78834390",
    lng: "98.98530080"
    },
    {
    state: "Chiang Rai",
    stateShort: "57",
    lat: "19.91047980",
    lng: "99.84057600"
    },
    {
    state: "Chon Buri",
    stateShort: "20",
    lat: "13.36114310",
    lng: "100.98467170"
    },
    {
    state: "Chumphon",
    stateShort: "86",
    lat: "10.49304960",
    lng: "99.18001990"
    },
    {
    state: "Kalasin",
    stateShort: "46",
    lat: "16.43850800",
    lng: "103.50609940"
    },
    {
    state: "Kamphaeng Phet",
    stateShort: "62",
    lat: "16.48277980",
    lng: "99.52266180"
    },
    {
    state: "Kanchanaburi",
    stateShort: "71",
    lat: "14.10113930",
    lng: "99.41794310"
    },
    {
    state: "Khon Kaen",
    stateShort: "40",
    lat: "16.43219380",
    lng: "102.82362140"
    },
    {
    state: "Krabi",
    stateShort: "81",
    lat: "8.08629970",
    lng: "98.90628350"
    },
    {
    state: "Lampang",
    stateShort: "52",
    lat: "18.28553950",
    lng: "99.51278950"
    },
    {
    state: "Lamphun",
    stateShort: "51",
    lat: "18.57446060",
    lng: "99.00872210"
    },
    {
    state: "Loei",
    stateShort: "42",
    lat: "17.48602320",
    lng: "101.72230020"
    },
    {
    state: "Lop Buri",
    stateShort: "16",
    lat: "14.79950810",
    lng: "100.65337060"
    },
    {
    state: "Mae Hong Son",
    stateShort: "58",
    lat: "19.30202960",
    lng: "97.96543680"
    },
    {
    state: "Maha Sarakham",
    stateShort: "44",
    lat: "16.01320150",
    lng: "103.16151690"
    },
    {
    state: "Mukdahan",
    stateShort: "49",
    lat: "16.54359140",
    lng: "104.70241210"
    },
    {
    state: "Nakhon Nayok",
    stateShort: "26",
    lat: "14.20694660",
    lng: "101.21305110"
    },
    {
    state: "Nakhon Pathom",
    stateShort: "73",
    lat: "13.81402930",
    lng: "100.03729290"
    },
    {
    state: "Nakhon Phanom",
    stateShort: "48",
    lat: "17.39203900",
    lng: "104.76955080"
    },
    {
    state: "Nakhon Ratchasima",
    stateShort: "30",
    lat: "14.97384930",
    lng: "102.08365200"
    },
    {
    state: "Nakhon Sawan",
    stateShort: "60",
    lat: "15.69873820",
    lng: "100.11996000"
    },
    {
    state: "Nakhon Si Thammarat",
    stateShort: "80",
    lat: "8.43248310",
    lng: "99.95990330"
    },
    {
    state: "Nan",
    stateShort: "55",
    lat: "45.52220800",
    lng: "-122.98632810"
    },
    {
    state: "Narathiwat",
    stateShort: "96",
    lat: "6.42546070",
    lng: "101.82531430"
    },
    {
    state: "Nong Bua Lam Phu",
    stateShort: "39",
    lat: "17.22182470",
    lng: "102.42603680"
    },
    {
    state: "Nong Khai",
    stateShort: "43",
    lat: "17.87828030",
    lng: "102.74126380"
    },
    {
    state: "Nonthaburi",
    stateShort: "12",
    lat: "13.85910840",
    lng: "100.52165080"
    },
    {
    state: "Pathum Thani",
    stateShort: "13",
    lat: "14.02083910",
    lng: "100.52502760"
    },
    {
    state: "Pattani",
    stateShort: "94",
    lat: "6.76183080",
    lng: "101.32325490"
    },
    {
    state: "Pattaya",
    stateShort: "S",
    lat: "12.92355570",
    lng: "100.88245510"
    },
    {
    state: "Phangnga",
    stateShort: "82",
    lat: "8.45014140",
    lng: "98.52553170"
    },
    {
    state: "Phatthalung",
    stateShort: "93",
    lat: "7.61668230",
    lng: "100.07402310"
    },
    {
    state: "Phayao",
    stateShort: "56",
    lat: "19.21543670",
    lng: "100.20236920"
    },
    {
    state: "Phetchabun",
    stateShort: "67",
    lat: "16.30166900",
    lng: "101.11928040"
    },
    {
    state: "Phetchaburi",
    stateShort: "76",
    lat: "12.96492150",
    lng: "99.64258830"
    },
    {
    state: "Phichit",
    stateShort: "66",
    lat: "16.27408760",
    lng: "100.33469910"
    },
    {
    state: "Phitsanulok",
    stateShort: "65",
    lat: "16.82112380",
    lng: "100.26585160"
    },
    {
    state: "Phra Nakhon Si Ayutthaya",
    stateShort: "14",
    lat: "14.36923250",
    lng: "100.58766340"
    },
    {
    state: "Phrae",
    stateShort: "54",
    lat: "18.14457740",
    lng: "100.14028310"
    },
    {
    state: "Phuket",
    stateShort: "83",
    lat: "7.88044790",
    lng: "98.39225040"
    },
    {
    state: "Prachin Buri",
    stateShort: "25",
    lat: "14.04206990",
    lng: "101.66008740"
    },
    {
    state: "Prachuap Khiri Khan",
    stateShort: "77",
    lat: "11.79383890",
    lng: "99.79575640"
    },
    {
    state: "Ranong",
    stateShort: "85",
    lat: "9.95287020",
    lng: "98.60846410"
    },
    {
    state: "Ratchaburi",
    stateShort: "70",
    lat: "13.52828930",
    lng: "99.81342110"
    },
    {
    state: "Rayong",
    stateShort: "21",
    lat: "12.68139570",
    lng: "101.28162610"
    },
    {
    state: "Roi Et",
    stateShort: "45",
    lat: "16.05381960",
    lng: "103.65200360"
    },
    {
    state: "Sa Kaeo",
    stateShort: "27",
    lat: "13.82403800",
    lng: "102.06458390"
    },
    {
    state: "Sakon Nakhon",
    stateShort: "47",
    lat: "17.16642110",
    lng: "104.14860550"
    },
    {
    state: "Samut Prakan",
    stateShort: "11",
    lat: "13.59909610",
    lng: "100.59983190"
    },
    {
    state: "Samut Sakhon",
    stateShort: "74",
    lat: "13.54752160",
    lng: "100.27439560"
    },
    {
    state: "Samut Songkhram",
    stateShort: "75",
    lat: "13.40982170",
    lng: "100.00226450"
    },
    {
    state: "Saraburi",
    stateShort: "19",
    lat: "14.52891540",
    lng: "100.91014210"
    },
    {
    state: "Satun",
    stateShort: "91",
    lat: "6.62381580",
    lng: "100.06737440"
    },
    {
    state: "Si Sa Ket",
    stateShort: "33",
    lat: "15.11860090",
    lng: "104.32200950"
    },
    {
    state: "Sing Buri",
    stateShort: "17",
    lat: "14.89362530",
    lng: "100.39673140"
    },
    {
    state: "Songkhla",
    stateShort: "90",
    lat: "7.18976590",
    lng: "100.59538130"
    },
    {
    state: "Sukhothai",
    stateShort: "64",
    lat: "43.64855560",
    lng: "-79.37466390"
    },
    {
    state: "Suphan Buri",
    stateShort: "72",
    lat: "14.47448920",
    lng: "100.11771280"
    },
    {
    state: "Surat Thani",
    stateShort: "84",
    lat: "9.13419490",
    lng: "99.33341980"
    },
    {
    state: "Surin",
    stateShort: "32",
    lat: "37.03582710",
    lng: "-95.62763670"
    },
    {
    state: "Tak",
    stateShort: "63",
    lat: "45.02996460",
    lng: "-93.10498150"
    },
    {
    state: "Trang",
    stateShort: "92",
    lat: "7.56448330",
    lng: "99.62393340"
    },
    {
    state: "Trat",
    stateShort: "23",
    lat: "12.24275630",
    lng: "102.51747340"
    },
    {
    state: "Ubon Ratchathani",
    stateShort: "34",
    lat: "15.24484530",
    lng: "104.84729950"
    },
    {
    state: "Udon Thani",
    stateShort: "41",
    lat: "17.36469690",
    lng: "102.81589240"
    },
    {
    state: "Uthai Thani",
    stateShort: "61",
    lat: "15.38350010",
    lng: "100.02455270"
    },
    {
    state: "Uttaradit",
    stateShort: "53",
    lat: "17.62008860",
    lng: "100.09929420"
    },
    {
    state: "Yala",
    stateShort: "95",
    lat: "44.05791170",
    lng: "-123.16538480"
    },
    {
    state: "Yasothon",
    stateShort: "35",
    lat: "15.79264100",
    lng: "104.14528270"
    }
    ]
    },
    {
    country: "The Bahamas",
    countryShort: "BS",
    currency_symbol: "B$",
    states: [
    {
    state: "Acklins",
    stateShort: "AK",
    lat: "22.36577080",
    lng: "-74.05351260"
    },
    {
    state: "Acklins and Crooked Islands",
    stateShort: "AC",
    lat: "22.36577080",
    lng: "-74.05351260"
    },
    {
    state: "Berry Islands",
    stateShort: "BY",
    lat: "25.62500420",
    lng: "-77.82522030"
    },
    {
    state: "Bimini",
    stateShort: "BI",
    lat: "24.64153250",
    lng: "-79.85062260"
    },
    {
    state: "Black Point",
    stateShort: "BP",
    lat: "41.39510240",
    lng: "-71.46505560"
    },
    {
    state: "Cat Island",
    stateShort: "CI",
    lat: "30.22801360",
    lng: "-89.10149330"
    },
    {
    state: "Central Abaco",
    stateShort: "CO",
    lat: "26.35550290",
    lng: "-77.14851630"
    },
    {
    state: "Central Andros",
    stateShort: "CS",
    lat: "24.46884820",
    lng: "-77.97386500"
    },
    {
    state: "Central Eleuthera",
    stateShort: "CE",
    lat: "25.13620370",
    lng: "-76.14359150"
    },
    {
    state: "Crooked Island",
    stateShort: "CK",
    lat: "22.63909820",
    lng: "-74.00650900"
    },
    {
    state: "East Grand Bahama",
    stateShort: "EG",
    lat: "26.65828230",
    lng: "-78.22482910"
    },
    {
    state: "Exuma",
    stateShort: "EX",
    lat: "23.61925980",
    lng: "-75.96954650"
    },
    {
    state: "Freeport",
    stateShort: "FP",
    lat: "42.29668610",
    lng: "-89.62122710"
    },
    {
    state: "Fresh Creek",
    stateShort: "FC",
    lat: "40.65437560",
    lng: "-73.89479390"
    },
    {
    state: "Governor's Harbour",
    stateShort: "GH",
    lat: "25.19480960",
    lng: "-76.24396220"
    },
    {
    state: "Grand Cay",
    stateShort: "GC",
    lat: "27.21626150",
    lng: "-78.32305590"
    },
    {
    state: "Green Turtle Cay",
    stateShort: "GT",
    lat: "26.77471070",
    lng: "-77.32957080"
    },
    {
    state: "Harbour Island",
    stateShort: "HI",
    lat: "25.50011000",
    lng: "-76.63405110"
    },
    {
    state: "High Rock",
    stateShort: "HR",
    lat: "46.68434150",
    lng: "-121.90174610"
    },
    {
    state: "Hope Town",
    stateShort: "HT",
    lat: "26.50095040",
    lng: "-76.99598720"
    },
    {
    state: "Inagua",
    stateShort: "IN",
    lat: "21.06560660",
    lng: "-73.32370800"
    },
    {
    state: "Kemps Bay",
    stateShort: "KB",
    lat: "24.02364000",
    lng: "-77.54534900"
    },
    {
    state: "Long Island",
    stateShort: "LI",
    lat: "40.78914200",
    lng: "-73.13496100"
    },
    {
    state: "Mangrove Cay",
    stateShort: "MC",
    lat: "24.14814250",
    lng: "-77.76809520"
    },
    {
    state: "Marsh Harbour",
    stateShort: "MH",
    lat: "26.52416530",
    lng: "-77.09098090"
    },
    {
    state: "Mayaguana District",
    stateShort: "MG",
    lat: "22.40177140",
    lng: "-73.06413960"
    },
    {
    state: "New Providence",
    stateShort: "NP",
    lat: "40.69843480",
    lng: "-74.40154050"
    },
    {
    state: "Nichollstown and Berry Islands",
    stateShort: "NB",
    lat: "25.72362340",
    lng: "-77.83101040"
    },
    {
    state: "North Abaco",
    stateShort: "NO",
    lat: "26.78716970",
    lng: "-77.43577390"
    },
    {
    state: "North Andros",
    stateShort: "NS",
    lat: "24.70638050",
    lng: "-78.01953870"
    },
    {
    state: "North Eleuthera",
    stateShort: "NE",
    lat: "25.46475170",
    lng: "-76.67592200"
    },
    {
    state: "Ragged Island",
    stateShort: "RI",
    lat: "41.59743100",
    lng: "-71.26020200"
    },
    {
    state: "Rock Sound",
    stateShort: "RS",
    lat: "39.01424430",
    lng: "-95.67089890"
    },
    {
    state: "Rum Cay District",
    stateShort: "RC",
    lat: "23.68546760",
    lng: "-74.83901620"
    },
    {
    state: "San Salvador and Rum Cay",
    stateShort: "SR",
    lat: "23.68546760",
    lng: "-74.83901620"
    },
    {
    state: "San Salvador Island",
    stateShort: "SS",
    lat: "24.07755460",
    lng: "-74.47600880"
    },
    {
    state: "Sandy Point",
    stateShort: "SP",
    lat: "39.01454640",
    lng: "-76.39989250"
    },
    {
    state: "South Abaco",
    stateShort: "SO",
    lat: "26.06405910",
    lng: "-77.26350380"
    },
    {
    state: "South Andros",
    stateShort: "SA",
    lat: "23.97135560",
    lng: "-77.60778650"
    },
    {
    state: "South Eleuthera",
    stateShort: "SE",
    lat: "24.77085620",
    lng: "-76.21314740"
    },
    {
    state: "Spanish Wells",
    stateShort: "SW",
    lat: "26.32505990",
    lng: "-81.79803280"
    },
    {
    state: "West Grand Bahama",
    stateShort: "WG",
    lat: "26.65944700",
    lng: "-78.52065000"
    }
    ]
    },
    {
    country: "Togo",
    countryShort: "TG",
    currency_symbol: "CFA",
    states: [
    {
    state: "Centrale Region",
    stateShort: "C",
    lat: "8.65860290",
    lng: "1.05861350"
    },
    {
    state: "Kara Region",
    stateShort: "K",
    lat: "9.72163930",
    lng: "1.05861350"
    },
    {
    state: "Maritime",
    stateShort: "M",
    lat: "41.65514930",
    lng: "-83.52784670"
    },
    {
    state: "Plateaux Region",
    stateShort: "P",
    lat: "7.61013780",
    lng: "1.05861350"
    },
    {
    state: "Savanes Region",
    stateShort: "S",
    lat: "10.52917810",
    lng: "0.52578230"
    }
    ]
    },
  
    {
    country: "Tonga",
    countryShort: "TO",
    currency_symbol: "$",
    states: [
    {
    state: "Haʻapai",
    stateShort: "02",
    lat: "-19.75000000",
    lng: "-174.36666700"
    },
    {
    state: "ʻEua",
    stateShort: "01",
    lat: "37.09024000",
    lng: "-95.71289100"
    },
    {
    state: "Niuas",
    stateShort: "03",
    lat: "-15.95940000",
    lng: "-173.78300000"
    },
    {
    state: "Tongatapu",
    stateShort: "04",
    lat: "-21.14659680",
    lng: "-175.25154820"
    },
    {
    state: "Vavaʻu",
    stateShort: "05",
    lat: "-18.62275600",
    lng: "-173.99029820"
    }
    ]
    },
    {
    country: "Trinidad And Tobago",
    countryShort: "TT",
    currency_symbol: "$",
    states: [
    {
    state: "Arima",
    stateShort: "ARI",
    lat: "46.79316040",
    lng: "-71.25843110"
    },
    {
    state: "Chaguanas",
    stateShort: "CHA",
    lat: "10.51683870",
    lng: "-61.41144820"
    },
    {
    state: "Couva-Tabaquite-Talparo Regional Corporation",
    stateShort: "CTT",
    lat: "10.42971450",
    lng: "-61.37352100"
    },
    {
    state: "Diego Martin Regional Corporation",
    stateShort: "DMN",
    lat: "10.73622860",
    lng: "-61.55448360"
    },
    {
    state: "Eastern Tobago",
    stateShort: "ETO",
    lat: "11.29793480",
    lng: "-60.55885240"
    },
    {
    state: "Penal-Debe Regional Corporation",
    stateShort: "PED",
    lat: "10.13374020",
    lng: "-61.44354740"
    },
    {
    state: "Point Fortin",
    stateShort: "PTF",
    lat: "10.17027370",
    lng: "-61.67133860"
    },
    {
    state: "Port of Spain",
    stateShort: "POS",
    lat: "10.66031960",
    lng: "-61.50856250"
    },
    {
    state: "Princes Town Regional Corporation",
    stateShort: "PRT",
    lat: "10.17867460",
    lng: "-61.28019960"
    },
    {
    state: "Rio Claro-Mayaro Regional Corporation",
    stateShort: "MRC",
    lat: "10.24128320",
    lng: "-61.09372060"
    },
    {
    state: "San Fernando",
    stateShort: "SFO",
    lat: "34.28194610",
    lng: "-118.43897190"
    },
    {
    state: "San Juan-Laventille Regional Corporation",
    stateShort: "SJL",
    lat: "10.69085780",
    lng: "-61.45522130"
    },
    {
    state: "Sangre Grande Regional Corporation",
    stateShort: "SGE",
    lat: "10.58529390",
    lng: "-61.13158130"
    },
    {
    state: "Siparia Regional Corporation",
    stateShort: "SIP",
    lat: "10.12456260",
    lng: "-61.56032440"
    },
    {
    state: "Tunapuna-Piarco Regional Corporation",
    stateShort: "TUP",
    lat: "10.68590960",
    lng: "-61.30352480"
    },
    {
    state: "Western Tobago",
    stateShort: "WTO",
    lat: "11.18970720",
    lng: "-60.77954520"
    }
    ]
    },
    {
    country: "Tunisia",
    countryShort: "TN",
    currency_symbol: "ت.د",
    states: [
    {
    state: "Ariana",
    stateShort: "12",
    lat: "36.99227510",
    lng: "10.12551640"
    },
    {
    state: "Ben Arous",
    stateShort: "13",
    lat: "36.64356060",
    lng: "10.21515780"
    },
    {
    state: "Bizerte",
    stateShort: "23",
    lat: "37.16093970",
    lng: "9.63413500"
    },
    {
    state: "Gabès",
    stateShort: "81",
    lat: "33.94596480",
    lng: "9.72326730"
    },
    {
    state: "Gafsa",
    stateShort: "71",
    lat: "34.37885050",
    lng: "8.66005860"
    },
    {
    state: "Jendouba",
    stateShort: "32",
    lat: "36.71818620",
    lng: "8.74811670"
    },
    {
    state: "Kairouan",
    stateShort: "41",
    lat: "35.67116630",
    lng: "10.10054690"
    },
    {
    state: "Kasserine",
    stateShort: "42",
    lat: "35.08091480",
    lng: "8.66005860"
    },
    {
    state: "Kassrine",
    stateShort: "31",
    lat: "35.17227160",
    lng: "8.83076260"
    },
    {
    state: "Kebili",
    stateShort: "73",
    lat: "33.70715510",
    lng: "8.97146230"
    },
    {
    state: "Kef",
    stateShort: "33",
    lat: "36.12305120",
    lng: "8.66005860"
    },
    {
    state: "Mahdia",
    stateShort: "53",
    lat: "35.33525580",
    lng: "10.89030990"
    },
    {
    state: "Manouba",
    stateShort: "14",
    lat: "36.84465040",
    lng: "9.85714160"
    },
    {
    state: "Medenine",
    stateShort: "82",
    lat: "33.22805650",
    lng: "10.89030990"
    },
    {
    state: "Monastir",
    stateShort: "52",
    lat: "35.76425150",
    lng: "10.81128850"
    },
    {
    state: "Nabeul",
    stateShort: "21",
    lat: "36.45245910",
    lng: "10.68032220"
    },
    {
    state: "Sfax",
    stateShort: "61",
    lat: "34.86065810",
    lng: "10.34978950"
    },
    {
    state: "Sidi Bouzid",
    stateShort: "43",
    lat: "35.03543860",
    lng: "9.48393920"
    },
    {
    state: "Siliana",
    stateShort: "34",
    lat: "36.08872080",
    lng: "9.36453350"
    },
    {
    state: "Sousse",
    stateShort: "51",
    lat: "35.90222670",
    lng: "10.34978950"
    },
    {
    state: "Tataouine",
    stateShort: "83",
    lat: "32.13441220",
    lng: "10.08072980"
    },
    {
    state: "Tozeur",
    stateShort: "72",
    lat: "33.97894910",
    lng: "8.04651850"
    },
    {
    state: "Tunis",
    stateShort: "11",
    lat: "36.83749460",
    lng: "10.19273890"
    },
    {
    state: "Zaghouan",
    stateShort: "22",
    lat: "36.40911880",
    lng: "10.14231720"
    }
    ]
    },
    {
    country: "Turkey",
    countryShort: "TR",
    currency_symbol: "₺",
    states: [
    {
    state: "Adana",
    stateShort: "01",
    lat: "37.26123150",
    lng: "35.39050460"
    },
    {
    state: "Adıyaman",
    stateShort: "02",
    lat: "37.90782910",
    lng: "38.48499230"
    },
    {
    state: "Afyonkarahisar",
    stateShort: "03",
    lat: "38.73910990",
    lng: "30.71200230"
    },
    {
    state: "Ağrı",
    stateShort: "04",
    lat: "39.62692180",
    lng: "43.02159650"
    },
    {
    state: "Aksaray",
    stateShort: "68",
    lat: "38.33520430",
    lng: "33.97500180"
    },
    {
    state: "Amasya",
    stateShort: "05",
    lat: "40.65166080",
    lng: "35.90379660"
    },
    {
    state: "Ankara",
    stateShort: "06",
    lat: "39.78052450",
    lng: "32.71813750"
    },
    {
    state: "Antalya",
    stateShort: "07",
    lat: "37.09516720",
    lng: "31.07937050"
    },
    {
    state: "Ardahan",
    stateShort: "75",
    lat: "41.11129640",
    lng: "42.78316740"
    },
    {
    state: "Artvin",
    stateShort: "08",
    lat: "41.07866400",
    lng: "41.76282230"
    },
    {
    state: "Aydın",
    stateShort: "09",
    lat: "37.81170330",
    lng: "28.48639630"
    },
    {
    state: "Balıkesir",
    stateShort: "10",
    lat: "39.76167820",
    lng: "28.11226790"
    },
    {
    state: "Bartın",
    stateShort: "74",
    lat: "41.58105090",
    lng: "32.46097940"
    },
    {
    state: "Batman",
    stateShort: "72",
    lat: "37.83624960",
    lng: "41.36057390"
    },
    {
    state: "Bayburt",
    stateShort: "69",
    lat: "40.26032000",
    lng: "40.22804800"
    },
    {
    state: "Bilecik",
    stateShort: "11",
    lat: "40.05665550",
    lng: "30.06652360"
    },
    {
    state: "Bingöl",
    stateShort: "12",
    lat: "39.06263540",
    lng: "40.76960950"
    },
    {
    state: "Bitlis",
    stateShort: "13",
    lat: "38.65231330",
    lng: "42.42020280"
    },
    {
    state: "Bolu",
    stateShort: "14",
    lat: "40.57597660",
    lng: "31.57880860"
    },
    {
    state: "Burdur",
    stateShort: "15",
    lat: "37.46126690",
    lng: "30.06652360"
    },
    {
    state: "Bursa",
    stateShort: "16",
    lat: "40.06554590",
    lng: "29.23207840"
    },
    {
    state: "Çanakkale",
    stateShort: "17",
    lat: "40.05101040",
    lng: "26.98524220"
    },
    {
    state: "Çankırı",
    stateShort: "18",
    lat: "40.53690730",
    lng: "33.58838930"
    },
    {
    state: "Çorum",
    stateShort: "19",
    lat: "40.49982110",
    lng: "34.59862630"
    },
    {
    state: "Denizli",
    stateShort: "20",
    lat: "37.61283950",
    lng: "29.23207840"
    },
    {
    state: "Diyarbakır",
    stateShort: "21",
    lat: "38.10663720",
    lng: "40.54268960"
    },
    {
    state: "Düzce",
    stateShort: "81",
    lat: "40.87705310",
    lng: "31.31927130"
    },
    {
    state: "Edirne",
    stateShort: "22",
    lat: "41.15172220",
    lng: "26.51379640"
    },
    {
    state: "Elazığ",
    stateShort: "23",
    lat: "38.49648040",
    lng: "39.21990290"
    },
    {
    state: "Erzincan",
    stateShort: "24",
    lat: "39.76819140",
    lng: "39.05013060"
    },
    {
    state: "Erzurum",
    stateShort: "25",
    lat: "40.07467990",
    lng: "41.66945620"
    },
    {
    state: "Eskişehir",
    stateShort: "26",
    lat: "39.63296570",
    lng: "31.26263660"
    },
    {
    state: "Gaziantep",
    stateShort: "27",
    lat: "37.07638820",
    lng: "37.38272340"
    },
    {
    state: "Giresun",
    stateShort: "28",
    lat: "40.64616720",
    lng: "38.59355110"
    },
    {
    state: "Gümüşhane",
    stateShort: "29",
    lat: "40.28036730",
    lng: "39.31432530"
    },
    {
    state: "Hakkâri",
    stateShort: "30",
    lat: "37.44593190",
    lng: "43.74498410"
    },
    {
    state: "Hatay",
    stateShort: "31",
    lat: "36.40184880",
    lng: "36.34980970"
    },
    {
    state: "Iğdır",
    stateShort: "76",
    lat: "39.88798410",
    lng: "44.00483650"
    },
    {
    state: "Isparta",
    stateShort: "32",
    lat: "38.02114640",
    lng: "31.07937050"
    },
    {
    state: "İstanbul",
    stateShort: "34",
    lat: "41.16343020",
    lng: "28.76644080"
    },
    {
    state: "İzmir",
    stateShort: "35",
    lat: "38.35916930",
    lng: "27.26761160"
    },
    {
    state: "Kahramanmaraş",
    stateShort: "46",
    lat: "37.75030360",
    lng: "36.95410700"
    },
    {
    state: "Karabük",
    stateShort: "78",
    lat: "41.18748900",
    lng: "32.74174190"
    },
    {
    state: "Karaman",
    stateShort: "70",
    lat: "37.24363360",
    lng: "33.61757700"
    },
    {
    state: "Kars",
    stateShort: "36",
    lat: "40.28076360",
    lng: "42.99195270"
    },
    {
    state: "Kastamonu",
    stateShort: "37",
    lat: "41.41038630",
    lng: "33.69983340"
    },
    {
    state: "Kayseri",
    stateShort: "38",
    lat: "38.62568540",
    lng: "35.74068820"
    },
    {
    state: "Kilis",
    stateShort: "79",
    lat: "36.82047750",
    lng: "37.16873390"
    },
    {
    state: "Kırıkkale",
    stateShort: "71",
    lat: "39.88768780",
    lng: "33.75552480"
    },
    {
    state: "Kırklareli",
    stateShort: "39",
    lat: "41.72597950",
    lng: "27.48383900"
    },
    {
    state: "Kırşehir",
    stateShort: "40",
    lat: "39.22689050",
    lng: "33.97500180"
    },
    {
    state: "Kocaeli",
    stateShort: "41",
    lat: "40.85327040",
    lng: "29.88152030"
    },
    {
    state: "Konya",
    stateShort: "42",
    lat: "37.98381340",
    lng: "32.71813750"
    },
    {
    state: "Kütahya",
    stateShort: "43",
    lat: "39.35813700",
    lng: "29.60354950"
    },
    {
    state: "Malatya",
    stateShort: "44",
    lat: "38.40150570",
    lng: "37.95362980"
    },
    {
    state: "Manisa",
    stateShort: "45",
    lat: "38.84193730",
    lng: "28.11226790"
    },
    {
    state: "Mardin",
    stateShort: "47",
    lat: "37.34429290",
    lng: "40.61964870"
    },
    {
    state: "Mersin",
    stateShort: "33",
    lat: "36.81208580",
    lng: "34.64147500"
    },
    {
    state: "Muğla",
    stateShort: "48",
    lat: "37.18358190",
    lng: "28.48639630"
    },
    {
    state: "Muş",
    stateShort: "49",
    lat: "38.94618880",
    lng: "41.75389310"
    },
    {
    state: "Nevşehir",
    stateShort: "50",
    lat: "38.69393990",
    lng: "34.68565090"
    },
    {
    state: "Niğde",
    stateShort: "51",
    lat: "38.09930860",
    lng: "34.68565090"
    },
    {
    state: "Ordu",
    stateShort: "52",
    lat: "40.79905800",
    lng: "37.38990050"
    },
    {
    state: "Osmaniye",
    stateShort: "80",
    lat: "37.21302580",
    lng: "36.17626150"
    },
    {
    state: "Rize",
    stateShort: "53",
    lat: "40.95814970",
    lng: "40.92269850"
    },
    {
    state: "Sakarya",
    stateShort: "54",
    lat: "40.78885500",
    lng: "30.40595400"
    },
    {
    state: "Samsun",
    stateShort: "55",
    lat: "41.18648590",
    lng: "36.13226780"
    },
    {
    state: "Şanlıurfa",
    stateShort: "63",
    lat: "37.35691020",
    lng: "39.15436770"
    },
    {
    state: "Siirt",
    stateShort: "56",
    lat: "37.86588620",
    lng: "42.14945230"
    },
    {
    state: "Sinop",
    stateShort: "57",
    lat: "41.55947490",
    lng: "34.85805320"
    },
    {
    state: "Sivas",
    stateShort: "58",
    lat: "39.44880390",
    lng: "37.12944970"
    },
    {
    state: "Şırnak",
    stateShort: "73",
    lat: "37.41874810",
    lng: "42.49183380"
    },
    {
    state: "Tekirdağ",
    stateShort: "59",
    lat: "41.11212270",
    lng: "27.26761160"
    },
    {
    state: "Tokat",
    stateShort: "60",
    lat: "40.39027130",
    lng: "36.62518630"
    },
    {
    state: "Trabzon",
    stateShort: "61",
    lat: "40.79924100",
    lng: "39.58479440"
    },
    {
    state: "Tunceli",
    stateShort: "62",
    lat: "39.30735540",
    lng: "39.43877780"
    },
    {
    state: "Uşak",
    stateShort: "64",
    lat: "38.54313190",
    lng: "29.23207840"
    },
    {
    state: "Van",
    stateShort: "65",
    lat: "38.36794170",
    lng: "43.71827870"
    },
    {
    state: "Yalova",
    stateShort: "77",
    lat: "40.57759860",
    lng: "29.20883030"
    },
    {
    state: "Yozgat",
    stateShort: "66",
    lat: "39.72719790",
    lng: "35.10778580"
    },
    {
    state: "Zonguldak",
    stateShort: "67",
    lat: "41.31249170",
    lng: "31.85982510"
    }
    ]
    },
    {
    country: "Turkmenistan",
    countryShort: "TM",
    currency_symbol: "T",
    states: [
    {
    state: "Ahal Region",
    stateShort: "A",
    lat: "38.63993980",
    lng: "59.47209040"
    },
    {
    state: "Ashgabat",
    stateShort: "S",
    lat: "37.96007660",
    lng: "58.32606290"
    },
    {
    state: "Balkan Region",
    stateShort: "B",
    lat: "41.81014720",
    lng: "21.09373110"
    },
    {
    state: "Daşoguz Region",
    stateShort: "D",
    lat: "41.83687370",
    lng: "59.96519040"
    },
    {
    state: "Lebap Region",
    stateShort: "L",
    lat: "38.12724620",
    lng: "64.71624150"
    },
    {
    state: "Mary Region",
    stateShort: "M",
    lat: "36.94816230",
    lng: "62.45041540"
    }
    ]
    },
  
    {
    country: "Tuvalu",
    countryShort: "TV",
    currency_symbol: "$",
    states: [
    {
    state: "Funafuti",
    stateShort: "FUN",
    lat: "-8.52114710",
    lng: "179.19619260"
    },
    {
    state: "Nanumanga",
    stateShort: "NMG",
    lat: "-6.28580190",
    lng: "176.31992800"
    },
    {
    state: "Nanumea",
    stateShort: "NMA",
    lat: "-5.68816170",
    lng: "176.13701480"
    },
    {
    state: "Niutao Island Council",
    stateShort: "NIT",
    lat: "-6.10642580",
    lng: "177.34384290"
    },
    {
    state: "Nui",
    stateShort: "NUI",
    lat: "-7.23887680",
    lng: "177.14852320"
    },
    {
    state: "Nukufetau",
    stateShort: "NKF",
    lat: "-8.00000000",
    lng: "178.50000000"
    },
    {
    state: "Nukulaelae",
    stateShort: "NKL",
    lat: "-9.38111100",
    lng: "179.85222200"
    },
    {
    state: "Vaitupu",
    stateShort: "VAI",
    lat: "-7.47673270",
    lng: "178.67476750"
    }
    ]
    },
    {
    country: "Uganda",
    countryShort: "UG",
    currency_symbol: "USh",
    states: [
    {
    state: "Abim District",
    stateShort: "314",
    lat: "2.70669800",
    lng: "33.65953370"
    },
    {
    state: "Adjumani District",
    stateShort: "301",
    lat: "3.25485270",
    lng: "31.71954590"
    },
    {
    state: "Agago District",
    stateShort: "322",
    lat: "2.92508200",
    lng: "33.34861470"
    },
    {
    state: "Alebtong District",
    stateShort: "323",
    lat: "2.25457730",
    lng: "33.34861470"
    },
    {
    state: "Amolatar District",
    stateShort: "315",
    lat: "1.60544020",
    lng: "32.80844960"
    },
    {
    state: "Amudat District",
    stateShort: "324",
    lat: "1.79162240",
    lng: "34.90655100"
    },
    {
    state: "Amuria District",
    stateShort: "216",
    lat: "2.03017000",
    lng: "33.64275330"
    },
    {
    state: "Amuru District",
    stateShort: "316",
    lat: "2.96678780",
    lng: "32.08374450"
    },
    {
    state: "Apac District",
    stateShort: "302",
    lat: "1.87302630",
    lng: "32.62774550"
    },
    {
    state: "Arua District",
    stateShort: "303",
    lat: "2.99598460",
    lng: "31.17103890"
    },
    {
    state: "Budaka District",
    stateShort: "217",
    lat: "1.10162770",
    lng: "33.93039910"
    },
    {
    state: "Bududa District",
    stateShort: "218",
    lat: "1.00296930",
    lng: "34.33381230"
    },
    {
    state: "Bugiri District",
    stateShort: "201",
    lat: "0.53161270",
    lng: "33.75177230"
    },
    {
    state: "Buhweju District",
    stateShort: "420",
    lat: "-0.29113590",
    lng: "30.29741990"
    },
    {
    state: "Buikwe District",
    stateShort: "117",
    lat: "0.31440460",
    lng: "32.98883190"
    },
    {
    state: "Bukedea District",
    stateShort: "219",
    lat: "1.35568980",
    lng: "34.10867930"
    },
    {
    state: "Bukomansimbi District",
    stateShort: "118",
    lat: "-0.14327520",
    lng: "31.60548930"
    },
    {
    state: "Bukwo District",
    stateShort: "220",
    lat: "1.28186510",
    lng: "34.72987650"
    },
    {
    state: "Bulambuli District",
    stateShort: "225",
    lat: "1.47988460",
    lng: "34.37544140"
    },
    {
    state: "Buliisa District",
    stateShort: "416",
    lat: "2.02996070",
    lng: "31.53700030"
    },
    {
    state: "Bundibugyo District",
    stateShort: "401",
    lat: "0.68517630",
    lng: "30.02029640"
    },
    {
    state: "Bunyangabu District",
    stateShort: "430",
    lat: "0.48709180",
    lng: "30.20510960"
    },
    {
    state: "Bushenyi District",
    stateShort: "402",
    lat: "-0.48709180",
    lng: "30.20510960"
    },
    {
    state: "Busia District",
    stateShort: "202",
    lat: "0.40447310",
    lng: "34.01958270"
    },
    {
    state: "Butaleja District",
    stateShort: "221",
    lat: "0.84749220",
    lng: "33.84112880"
    },
    {
    state: "Butambala District",
    stateShort: "119",
    lat: "0.17425000",
    lng: "32.10646680"
    },
    {
    state: "Butebo District",
    stateShort: "233",
    lat: "1.21411240",
    lng: "33.90808960"
    },
    {
    state: "Buvuma District",
    stateShort: "120",
    lat: "-0.37649120",
    lng: "33.25879300"
    },
    {
    state: "Buyende District",
    stateShort: "226",
    lat: "1.24136820",
    lng: "33.12390490"
    },
    {
    state: "Central Region",
    stateShort: "C",
    lat: "44.29687500",
    lng: "-94.74017330"
    },
    {
    state: "Dokolo District",
    stateShort: "317",
    lat: "1.96364210",
    lng: "33.03387670"
    },
    {
    state: "Eastern Region",
    stateShort: "E",
    lat: "6.23740360",
    lng: "-0.45023680"
    },
    {
    state: "Gomba District",
    stateShort: "121",
    lat: "0.22297910",
    lng: "31.67393710"
    },
    {
    state: "Gulu District",
    stateShort: "304",
    lat: "2.81857760",
    lng: "32.44672380"
    },
    {
    state: "Ibanda District",
    stateShort: "417",
    lat: "-0.09648900",
    lng: "30.57395790"
    },
    {
    state: "Iganga District",
    stateShort: "203",
    lat: "0.66001370",
    lng: "33.48319060"
    },
    {
    state: "Isingiro District",
    stateShort: "418",
    lat: "-0.84354300",
    lng: "30.80394740"
    },
    {
    state: "Jinja District",
    stateShort: "204",
    lat: "0.53437430",
    lng: "33.30371430"
    },
    {
    state: "Kaabong District",
    stateShort: "318",
    lat: "3.51262150",
    lng: "33.97500180"
    },
    {
    state: "Kabale District",
    stateShort: "404",
    lat: "-1.24930840",
    lng: "30.06652360"
    },
    {
    state: "Kabarole District",
    stateShort: "405",
    lat: "0.58507910",
    lng: "30.25127280"
    },
    {
    state: "Kaberamaido District",
    stateShort: "213",
    lat: "1.69633220",
    lng: "33.21385100"
    },
    {
    state: "Kagadi District",
    stateShort: "427",
    lat: "0.94007610",
    lng: "30.81256380"
    },
    {
    state: "Kakumiro District",
    stateShort: "428",
    lat: "0.78080350",
    lng: "31.32413890"
    },
    {
    state: "Kalangala District",
    stateShort: "101",
    lat: "-0.63505780",
    lng: "32.53727410"
    },
    {
    state: "Kaliro District",
    stateShort: "222",
    lat: "1.04311070",
    lng: "33.48319060"
    },
    {
    state: "Kalungu District",
    stateShort: "122",
    lat: "-0.09528310",
    lng: "31.76513620"
    },
    {
    state: "Kampala District",
    stateShort: "102",
    lat: "0.34759640",
    lng: "32.58251970"
    },
    {
    state: "Kamuli District",
    stateShort: "205",
    lat: "0.91871070",
    lng: "33.12390490"
    },
    {
    state: "Kamwenge District",
    stateShort: "413",
    lat: "0.22579300",
    lng: "30.48184460"
    },
    {
    state: "Kanungu District",
    stateShort: "414",
    lat: "-0.81952530",
    lng: "29.74260400"
    },
    {
    state: "Kapchorwa District",
    stateShort: "206",
    lat: "1.33502050",
    lng: "34.39763560"
    },
    {
    state: "Kasese District",
    stateShort: "406",
    lat: "0.06462850",
    lng: "30.06652360"
    },
    {
    state: "Katakwi District",
    stateShort: "207",
    lat: "1.97310300",
    lng: "34.06414190"
    },
    {
    state: "Kayunga District",
    stateShort: "112",
    lat: "0.98601820",
    lng: "32.85357550"
    },
    {
    state: "Kibaale District",
    stateShort: "407",
    lat: "0.90668020",
    lng: "31.07937050"
    },
    {
    state: "Kiboga District",
    stateShort: "103",
    lat: "0.96575900",
    lng: "31.71954590"
    },
    {
    state: "Kibuku District",
    stateShort: "227",
    lat: "1.04528740",
    lng: "33.79925360"
    },
    {
    state: "Kiruhura District",
    stateShort: "419",
    lat: "-0.19279980",
    lng: "30.80394740"
    },
    {
    state: "Kiryandongo District",
    stateShort: "421",
    lat: "2.01799070",
    lng: "32.08374450"
    },
    {
    state: "Kisoro District",
    stateShort: "408",
    lat: "-1.22094300",
    lng: "29.64991620"
    },
    {
    state: "Kitgum District",
    stateShort: "305",
    lat: "3.33968290",
    lng: "33.16888830"
    },
    {
    state: "Koboko District",
    stateShort: "319",
    lat: "3.52370580",
    lng: "31.03351000"
    },
    {
    state: "Kole District",
    stateShort: "325",
    lat: "2.37010970",
    lng: "32.76330360"
    },
    {
    state: "Kotido District",
    stateShort: "306",
    lat: "3.04156790",
    lng: "33.88577470"
    },
    {
    state: "Kumi District",
    stateShort: "208",
    lat: "1.48769990",
    lng: "33.93039910"
    },
    {
    state: "Kween District",
    stateShort: "228",
    lat: "1.44387900",
    lng: "34.59713200"
    },
    {
    state: "Kyankwanzi District",
    stateShort: "123",
    lat: "1.09660370",
    lng: "31.71954590"
    },
    {
    state: "Kyegegwa District",
    stateShort: "422",
    lat: "0.48181930",
    lng: "31.05500930"
    },
    {
    state: "Kyenjojo District",
    stateShort: "415",
    lat: "0.60929230",
    lng: "30.64012310"
    },
    {
    state: "Kyotera District",
    stateShort: "125",
    lat: "-0.63589880",
    lng: "31.54556370"
    },
    {
    state: "Lamwo District",
    stateShort: "326",
    lat: "3.57075680",
    lng: "32.53727410"
    },
    {
    state: "Lira District",
    stateShort: "307",
    lat: "2.23161690",
    lng: "32.94376670"
    },
    {
    state: "Luuka District",
    stateShort: "229",
    lat: "0.72505990",
    lng: "33.30371430"
    },
    {
    state: "Luwero District",
    stateShort: "104",
    lat: "0.82711180",
    lng: "32.62774550"
    },
    {
    state: "Lwengo District",
    stateShort: "124",
    lat: "-0.41652880",
    lng: "31.39989950"
    },
    {
    state: "Lyantonde District",
    stateShort: "114",
    lat: "-0.22406960",
    lng: "31.21684660"
    },
    {
    state: "Manafwa District",
    stateShort: "223",
    lat: "0.90635990",
    lng: "34.28660910"
    },
    {
    state: "Maracha District",
    stateShort: "320",
    lat: "3.28731270",
    lng: "30.94030230"
    },
    {
    state: "Masaka District",
    stateShort: "105",
    lat: "-0.44636910",
    lng: "31.90179540"
    },
    {
    state: "Masindi District",
    stateShort: "409",
    lat: "1.49203630",
    lng: "31.71954590"
    },
    {
    state: "Mayuge District",
    stateShort: "214",
    lat: "-0.21829820",
    lng: "33.57280270"
    },
    {
    state: "Mbale District",
    stateShort: "209",
    lat: "1.03442740",
    lng: "34.19768820"
    },
    {
    state: "Mbarara District",
    stateShort: "410",
    lat: "-0.60715960",
    lng: "30.65450220"
    },
    {
    state: "Mitooma District",
    stateShort: "423",
    lat: "-0.61932760",
    lng: "30.02029640"
    },
    {
    state: "Mityana District",
    stateShort: "115",
    lat: "0.44548450",
    lng: "32.08374450"
    },
    {
    state: "Moroto District",
    stateShort: "308",
    lat: "2.61685450",
    lng: "34.59713200"
    },
    {
    state: "Moyo District",
    stateShort: "309",
    lat: "3.56964640",
    lng: "31.67393710"
    },
    {
    state: "Mpigi District",
    stateShort: "106",
    lat: "0.22735280",
    lng: "32.32492360"
    },
    {
    state: "Mubende District",
    stateShort: "107",
    lat: "0.57727580",
    lng: "31.53700030"
    },
    {
    state: "Mukono District",
    stateShort: "108",
    lat: "0.28354760",
    lng: "32.76330360"
    },
    {
    state: "Nakapiripirit District",
    stateShort: "311",
    lat: "1.96061730",
    lng: "34.59713200"
    },
    {
    state: "Nakaseke District",
    stateShort: "116",
    lat: "1.22308480",
    lng: "32.08374450"
    },
    {
    state: "Nakasongola District",
    stateShort: "109",
    lat: "1.34897210",
    lng: "32.44672380"
    },
    {
    state: "Namayingo District",
    stateShort: "230",
    lat: "-0.28035750",
    lng: "33.75177230"
    },
    {
    state: "Namisindwa District",
    stateShort: "234",
    lat: "0.90710100",
    lng: "34.35740370"
    },
    {
    state: "Namutumba District",
    stateShort: "224",
    lat: "0.84926100",
    lng: "33.66233010"
    },
    {
    state: "Napak District",
    stateShort: "327",
    lat: "2.36299450",
    lng: "34.24215970"
    },
    {
    state: "Nebbi District",
    stateShort: "310",
    lat: "2.44093920",
    lng: "31.35416310"
    },
    {
    state: "Ngora District",
    stateShort: "231",
    lat: "1.49081150",
    lng: "33.75177230"
    },
    {
    state: "Northern Region",
    stateShort: "N",
    lat: "9.54392690",
    lng: "-0.90566230"
    },
    {
    state: "Ntoroko District",
    stateShort: "424",
    lat: "1.07881780",
    lng: "30.38966510"
    },
    {
    state: "Ntungamo District",
    stateShort: "411",
    lat: "-0.98073410",
    lng: "30.25127280"
    },
    {
    state: "Nwoya District",
    stateShort: "328",
    lat: "2.56244400",
    lng: "31.90179540"
    },
    {
    state: "Omoro District",
    stateShort: "331",
    lat: "2.71522300",
    lng: "32.49200880"
    },
    {
    state: "Otuke District",
    stateShort: "329",
    lat: "2.52140590",
    lng: "33.34861470"
    },
    {
    state: "Oyam District",
    stateShort: "321",
    lat: "2.27762810",
    lng: "32.44672380"
    },
    {
    state: "Pader District",
    stateShort: "312",
    lat: "2.94306820",
    lng: "32.80844960"
    },
    {
    state: "Pakwach District",
    stateShort: "332",
    lat: "2.46071410",
    lng: "31.49417380"
    },
    {
    state: "Pallisa District",
    stateShort: "210",
    lat: "1.23242060",
    lng: "33.75177230"
    },
    {
    state: "Rakai District",
    stateShort: "110",
    lat: "-0.70691350",
    lng: "31.53700030"
    },
    {
    state: "Rubanda District",
    stateShort: "429",
    lat: "-1.18611900",
    lng: "29.84535760"
    },
    {
    state: "Rubirizi District",
    stateShort: "425",
    lat: "-0.26424100",
    lng: "30.10840330"
    },
    {
    state: "Rukiga District",
    stateShort: "431",
    lat: "-1.13263370",
    lng: "30.04341200"
    },
    {
    state: "Rukungiri District",
    stateShort: "412",
    lat: "-0.75184900",
    lng: "29.92779470"
    },
    {
    state: "Sembabule District",
    stateShort: "111",
    lat: "0.06377150",
    lng: "31.35416310"
    },
    {
    state: "Serere District",
    stateShort: "232",
    lat: "1.49940330",
    lng: "33.54900780"
    },
    {
    state: "Sheema District",
    stateShort: "426",
    lat: "-0.55152980",
    lng: "30.38966510"
    },
    {
    state: "Sironko District",
    stateShort: "215",
    lat: "1.23022740",
    lng: "34.24910640"
    },
    {
    state: "Soroti District",
    stateShort: "211",
    lat: "1.72291170",
    lng: "33.52800720"
    },
    {
    state: "Tororo District",
    stateShort: "212",
    lat: "0.68709940",
    lng: "34.06414190"
    },
    {
    state: "Wakiso District",
    stateShort: "113",
    lat: "0.06301900",
    lng: "32.44672380"
    },
    {
    state: "Western Region",
    stateShort: "W",
    lat: "40.76672150",
    lng: "-111.88772030"
    },
    {
    state: "Yumbe District",
    stateShort: "313",
    lat: "3.46980230",
    lng: "31.24832910"
    },
    {
    state: "Zombo District",
    stateShort: "330",
    lat: "2.55442930",
    lng: "30.94173680"
    }
    ]
    },
    {
    country: "Ukraine",
    countryShort: "UA",
    currency_symbol: "₴",
    states: [
    {
    state: "Autonomous Republic of Crimea",
    stateShort: "43",
    lat: "44.95211700",
    lng: "34.10241700"
    },
    {
    state: "Cherkaska oblast",
    stateShort: "71",
    lat: "49.44443300",
    lng: "32.05976700"
    },
    {
    state: "Chernihivska oblast",
    stateShort: "74",
    lat: "51.49820000",
    lng: "31.28934990"
    },
    {
    state: "Chernivetska oblast",
    stateShort: "77",
    lat: "48.29168300",
    lng: "25.93521700"
    },
    {
    state: "Dnipropetrovska oblast",
    stateShort: "12",
    lat: "48.46471700",
    lng: "35.04618300"
    },
    {
    state: "Donetska oblast",
    stateShort: "14",
    lat: "48.01588300",
    lng: "37.80285000"
    },
    {
    state: "Ivano-Frankivska oblast",
    stateShort: "26",
    lat: "48.92263300",
    lng: "24.71111700"
    },
    {
    state: "Kharkivska oblast",
    stateShort: "63",
    lat: "49.99350000",
    lng: "36.23038300"
    },
    {
    state: "Khersonska oblast",
    stateShort: "65",
    lat: "46.63541700",
    lng: "32.61686700"
    },
    {
    state: "Khmelnytska oblast",
    stateShort: "68",
    lat: "49.42298300",
    lng: "26.98713310"
    },
    {
    state: "Kirovohradska oblast",
    stateShort: "35",
    lat: "48.50793300",
    lng: "32.26231700"
    },
    {
    state: "Kyiv",
    stateShort: "30",
    lat: "50.45010000",
    lng: "30.52340000"
    },
    {
    state: "Kyivska oblast",
    stateShort: "32",
    lat: "50.05295060",
    lng: "30.76671340"
    },
    {
    state: "Luhanska oblast",
    stateShort: "09",
    lat: "48.57404100",
    lng: "39.30781500"
    },
    {
    state: "Lvivska oblast",
    stateShort: "46",
    lat: "49.83968300",
    lng: "24.02971700"
    },
    {
    state: "Mykolaivska oblast",
    stateShort: "48",
    lat: "46.97503300",
    lng: "31.99458290"
    },
    {
    state: "Odeska oblast",
    stateShort: "51",
    lat: "46.48458300",
    lng: "30.73260000"
    },
    {
    state: "Poltavska oblast",
    stateShort: "53",
    lat: "49.64291960",
    lng: "32.66753390"
    },
    {
    state: "Rivnenska oblast",
    stateShort: "56",
    lat: "50.61990000",
    lng: "26.25161700"
    },
    {
    state: "Sumska oblast",
    stateShort: "59",
    lat: "50.90770000",
    lng: "34.79810000"
    },
    {
    state: "Ternopilska oblast",
    stateShort: "61",
    lat: "49.55351700",
    lng: "25.59476700"
    },
    {
    state: "Vinnytska oblast",
    stateShort: "05",
    lat: "49.23308300",
    lng: "28.46821690"
    },
    {
    state: "Volynska oblast",
    stateShort: "07",
    lat: "50.74723300",
    lng: "25.32538300"
    },
    {
    state: "Zakarpatska Oblast",
    stateShort: "21",
    lat: "48.62080000",
    lng: "22.28788300"
    },
    {
    state: "Zaporizka oblast",
    stateShort: "23",
    lat: "47.83880000",
    lng: "35.13956700"
    },
    {
    state: "Zhytomyrska oblast",
    stateShort: "18",
    lat: "50.25465000",
    lng: "28.65866690"
    }
    ]
    },
    {
    country: "United Arab Emirates",
    countryShort: "AE",
    currency_symbol: "إ.د",
    states: [
    {
    state: "Abu Dhabi Emirate",
    stateShort: "AZ",
    lat: "24.45388400",
    lng: "54.37734380"
    },
    {
    state: "Ajman Emirate",
    stateShort: "AJ",
    lat: "25.40521650",
    lng: "55.51364330"
    },
    {
    state: "Dubai",
    stateShort: "DU",
    lat: "25.20484930",
    lng: "55.27078280"
    },
    {
    state: "Fujairah",
    stateShort: "FU",
    lat: "25.12880990",
    lng: "56.32648490"
    },
    {
    state: "Ras al-Khaimah",
    stateShort: "RK",
    lat: "25.67413430",
    lng: "55.98041730"
    },
    {
    state: "Sharjah Emirate",
    stateShort: "SH",
    lat: "25.07539740",
    lng: "55.75784030"
    },
    {
    state: "Umm al-Quwain",
    stateShort: "UQ",
    lat: "25.54263240",
    lng: "55.54753480"
    }
    ]
    },
    {
    country: "United Kingdom",
    countryShort: "GB",
    currency_symbol: "£",
    states: [
    {
    state: "Aberdeen",
    stateShort: "ABE",
    lat: "57.14971700",
    lng: "-2.09427800"
    },
    {
    state: "Aberdeenshire",
    stateShort: "ABD",
    lat: "57.28687230",
    lng: "-2.38156840"
    },
    {
    state: "Angus",
    stateShort: "ANS",
    lat: "37.27578860",
    lng: "-95.65010330"
    },
    {
    state: "Antrim",
    stateShort: "ANT",
    lat: "54.71953380",
    lng: "-6.20724980"
    },
    {
    state: "Antrim and Newtownabbey",
    stateShort: "ANN",
    lat: "54.69568870",
    lng: "-5.94810690"
    },
    {
    state: "Ards",
    stateShort: "ARD",
    lat: "42.13918510",
    lng: "-87.86149720"
    },
    {
    state: "Ards and North Down",
    stateShort: "AND",
    lat: "54.58996450",
    lng: "-5.59849720"
    },
    {
    state: "Argyll and Bute",
    stateShort: "AGB",
    lat: "56.40062140",
    lng: "-5.48074800"
    },
    {
    state: "Armagh City and District Council",
    stateShort: "ARM",
    lat: "54.39325920",
    lng: "-6.45634010"
    },
    {
    state: "Armagh, Banbridge and Craigavon",
    stateShort: "ABC",
    lat: "54.39325920",
    lng: "-6.45634010"
    },
    {
    state: "Ascension Island",
    stateShort: "SH-AC",
    lat: "-7.94671660",
    lng: "-14.35591580"
    },
    {
    state: "Ballymena Borough",
    stateShort: "BLA",
    lat: "54.86426000",
    lng: "-6.27910740"
    },
    {
    state: "Ballymoney",
    stateShort: "BLY",
    lat: "55.07048880",
    lng: "-6.51737080"
    },
    {
    state: "Banbridge",
    stateShort: "BNB",
    lat: "54.34872900",
    lng: "-6.27048030"
    },
    {
    state: "Barnsley",
    stateShort: "BNS",
    lat: "34.29949560",
    lng: "-84.98458090"
    },
    {
    state: "Bath and North East Somerset",
    stateShort: "BAS",
    lat: "51.32501020",
    lng: "-2.47662410"
    },
    {
    state: "Bedford",
    stateShort: "BDF",
    lat: "32.84401700",
    lng: "-97.14306710"
    },
    {
    state: "Belfast district",
    stateShort: "BFS",
    lat: "54.61703660",
    lng: "-5.95318610"
    },
    {
    state: "Birmingham",
    stateShort: "BIR",
    lat: "33.51858920",
    lng: "-86.81035670"
    },
    {
    state: "Blackburn with Darwen",
    stateShort: "BBD",
    lat: "53.69575220",
    lng: "-2.46829850"
    },
    {
    state: "Blackpool",
    stateShort: "BPL",
    lat: "53.81750530",
    lng: "-3.03567480"
    },
    {
    state: "Blaenau Gwent County Borough",
    stateShort: "BGW",
    lat: "51.78757790",
    lng: "-3.20439310"
    },
    {
    state: "Bolton",
    stateShort: "BOL",
    lat: "44.37264760",
    lng: "-72.87876250"
    },
    {
    state: "Bournemouth",
    stateShort: "BMH",
    lat: "50.71916400",
    lng: "-1.88076900"
    },
    {
    state: "Bracknell Forest",
    stateShort: "BRC",
    lat: "51.41538280",
    lng: "-0.75364950"
    },
    {
    state: "Bradford",
    stateShort: "BRD",
    lat: "53.79598400",
    lng: "-1.75939800"
    },
    {
    state: "Bridgend County Borough",
    stateShort: "BGE",
    lat: "51.50831990",
    lng: "-3.58120750"
    },
    {
    state: "Brighton and Hove",
    stateShort: "BNH",
    lat: "50.82262880",
    lng: "-0.13704700"
    },
    {
    state: "Buckinghamshire",
    stateShort: "BKM",
    lat: "51.80722040",
    lng: "-0.81276640"
    },
    {
    state: "Bury",
    stateShort: "BUR",
    lat: "53.59334980",
    lng: "-2.29660540"
    },
    {
    state: "Caerphilly County Borough",
    stateShort: "CAY",
    lat: "51.66044650",
    lng: "-3.21787240"
    },
    {
    state: "Calderdale",
    stateShort: "CLD",
    lat: "53.72478450",
    lng: "-1.86583570"
    },
    {
    state: "Cambridgeshire",
    stateShort: "CAM",
    lat: "52.20529730",
    lng: "0.12181950"
    },
    {
    state: "Carmarthenshire",
    stateShort: "CMN",
    lat: "51.85723090",
    lng: "-4.31159590"
    },
    {
    state: "Carrickfergus Borough Council",
    stateShort: "CKF",
    lat: "54.72568430",
    lng: "-5.80937190"
    },
    {
    state: "Castlereagh",
    stateShort: "CSR",
    lat: "54.57567900",
    lng: "-5.88840280"
    },
    {
    state: "Causeway Coast and Glens",
    stateShort: "CCG",
    lat: "55.04318300",
    lng: "-6.67412880"
    },
    {
    state: "Central Bedfordshire",
    stateShort: "CBF",
    lat: "52.00297440",
    lng: "-0.46513890"
    },
    {
    state: "Ceredigion",
    stateShort: "CGN",
    lat: "52.21914290",
    lng: "-3.93212560"
    },
    {
    state: "Cheshire East",
    stateShort: "CHE",
    lat: "53.16104460",
    lng: "-2.21859320"
    },
    {
    state: "Cheshire West and Chester",
    stateShort: "CHW",
    lat: "53.23029740",
    lng: "-2.71511170"
    },
    {
    state: "City and County of Cardiff",
    stateShort: "CRF",
    lat: "51.48158100",
    lng: "-3.17909000"
    },
    {
    state: "City and County of Swansea",
    stateShort: "SWA",
    lat: "51.62144000",
    lng: "-3.94364600"
    },
    {
    state: "City of Bristol",
    stateShort: "BST",
    lat: "41.67352200",
    lng: "-72.94653750"
    },
    {
    state: "City of Derby",
    stateShort: "DER",
    lat: "37.54837550",
    lng: "-97.24851910"
    },
    {
    state: "City of Kingston upon Hull",
    stateShort: "KHL",
    lat: "53.76762360",
    lng: "-0.32741980"
    },
    {
    state: "City of Leicester",
    stateShort: "LCE",
    lat: "52.63687780",
    lng: "-1.13975920"
    },
    {
    state: "City of London",
    stateShort: "LND",
    lat: "51.51234430",
    lng: "-0.09098520"
    },
    {
    state: "City of Nottingham",
    stateShort: "NGM",
    lat: "52.95478320",
    lng: "-1.15810860"
    },
    {
    state: "City of Peterborough",
    stateShort: "PTE",
    lat: "44.30936360",
    lng: "-78.32015300"
    },
    {
    state: "City of Plymouth",
    stateShort: "PLY",
    lat: "42.37089410",
    lng: "-83.46971410"
    },
    {
    state: "City of Portsmouth",
    stateShort: "POR",
    lat: "36.83291500",
    lng: "-76.29755490"
    },
    {
    state: "City of Southampton",
    stateShort: "STH",
    lat: "50.90970040",
    lng: "-1.40435090"
    },
    {
    state: "City of Stoke-on-Trent",
    stateShort: "STE",
    lat: "53.00266800",
    lng: "-2.17940400"
    },
    {
    state: "City of Sunderland",
    stateShort: "SND",
    lat: "54.88614890",
    lng: "-1.47857970"
    },
    {
    state: "City of Westminster",
    stateShort: "WSM",
    lat: "39.57659770",
    lng: "-76.99721260"
    },
    {
    state: "City of Wolverhampton",
    stateShort: "WLV",
    lat: "52.58891200",
    lng: "-2.15646300"
    },
    {
    state: "City of York",
    stateShort: "YOR",
    lat: "53.95996510",
    lng: "-1.08729790"
    },
    {
    state: "Clackmannanshire",
    stateShort: "CLK",
    lat: "56.10753510",
    lng: "-3.75294090"
    },
    {
    state: "Coleraine Borough Council",
    stateShort: "CLR",
    lat: "55.14515700",
    lng: "-6.67598140"
    },
    {
    state: "Conwy County Borough",
    stateShort: "CWY",
    lat: "53.29350130",
    lng: "-3.72651610"
    },
    {
    state: "Cookstown District Council",
    stateShort: "CKT",
    lat: "54.64181580",
    lng: "-6.74438950"
    },
    {
    state: "Cornwall",
    stateShort: "CON",
    lat: "50.26604710",
    lng: "-5.05271250"
    },
    {
    state: "County Durham",
    stateShort: "DUR",
    lat: "54.72940990",
    lng: "-1.88115980"
    },
    {
    state: "Coventry",
    stateShort: "COV",
    lat: "52.40682200",
    lng: "-1.51969300"
    },
    {
    state: "Craigavon Borough Council",
    stateShort: "CGV",
    lat: "54.39325920",
    lng: "-6.45634010"
    },
    {
    state: "Cumbria",
    stateShort: "CMA",
    lat: "54.57723230",
    lng: "-2.79748350"
    },
    {
    state: "Darlington",
    stateShort: "DAL",
    lat: "34.29987620",
    lng: "-79.87617410"
    },
    {
    state: "Denbighshire",
    stateShort: "DEN",
    lat: "53.18422880",
    lng: "-3.42249850"
    },
    {
    state: "Derbyshire",
    stateShort: "DBY",
    lat: "53.10467820",
    lng: "-1.56238850"
    },
    {
    state: "Derry City and Strabane",
    stateShort: "DRS",
    lat: "55.00474430",
    lng: "-7.32092220"
    },
    {
    state: "Derry City Council",
    stateShort: "DRY",
    lat: "54.96907780",
    lng: "-7.19583510"
    },
    {
    state: "Devon",
    stateShort: "DEV",
    lat: "50.71555910",
    lng: "-3.53087500"
    },
    {
    state: "Doncaster",
    stateShort: "DNC",
    lat: "53.52282000",
    lng: "-1.12846200"
    },
    {
    state: "Dorset",
    stateShort: "DOR",
    lat: "50.74876350",
    lng: "-2.34447860"
    },
    {
    state: "Down District Council",
    stateShort: "DOW",
    lat: "54.24342870",
    lng: "-5.95779590"
    },
    {
    state: "Dudley",
    stateShort: "DUD",
    lat: "42.04336610",
    lng: "-71.92760330"
    },
    {
    state: "Dumfries and Galloway",
    stateShort: "DGY",
    lat: "55.07010730",
    lng: "-3.60525810"
    },
    {
    state: "Dundee",
    stateShort: "DND",
    lat: "56.46201800",
    lng: "-2.97072100"
    },
    {
    state: "Dungannon and South Tyrone Borough Council",
    stateShort: "DGN",
    lat: "54.50826840",
    lng: "-6.76658910"
    },
    {
    state: "East Ayrshire",
    stateShort: "EAY",
    lat: "55.45184960",
    lng: "-4.26444780"
    },
    {
    state: "East Dunbartonshire",
    stateShort: "EDU",
    lat: "55.97431620",
    lng: "-4.20229800"
    },
    {
    state: "East Lothian",
    stateShort: "ELN",
    lat: "55.94933830",
    lng: "-2.77044640"
    },
    {
    state: "East Renfrewshire",
    stateShort: "ERW",
    lat: "55.77047350",
    lng: "-4.33598210"
    },
    {
    state: "East Riding of Yorkshire",
    stateShort: "ERY",
    lat: "53.84161680",
    lng: "-0.43441060"
    },
    {
    state: "East Sussex",
    stateShort: "ESX",
    lat: "50.90859550",
    lng: "0.24941660"
    },
    {
    state: "Edinburgh",
    stateShort: "EDH",
    lat: "55.95325200",
    lng: "-3.18826700"
    },
    {
    state: "England",
    stateShort: "ENG",
    lat: "52.35551770",
    lng: "-1.17431970"
    },
    {
    state: "Essex",
    stateShort: "ESS",
    lat: "51.57424470",
    lng: "0.48567810"
    },
    {
    state: "Falkirk",
    stateShort: "FAL",
    lat: "56.00187750",
    lng: "-3.78391310"
    },
    {
    state: "Fermanagh and Omagh",
    stateShort: "FMO",
    lat: "54.45135240",
    lng: "-7.71250180"
    },
    {
    state: "Fermanagh District Council",
    stateShort: "FER",
    lat: "54.34479780",
    lng: "-7.63842180"
    },
    {
    state: "Fife",
    stateShort: "FIF",
    lat: "56.20820780",
    lng: "-3.14951750"
    },
    {
    state: "Flintshire",
    stateShort: "FLN",
    lat: "53.16686580",
    lng: "-3.14189080"
    },
    {
    state: "Gateshead",
    stateShort: "GAT",
    lat: "54.95268000",
    lng: "-1.60341100"
    },
    {
    state: "Glasgow",
    stateShort: "GLG",
    lat: "55.86423700",
    lng: "-4.25180600"
    },
    {
    state: "Gloucestershire",
    stateShort: "GLS",
    lat: "51.86421120",
    lng: "-2.23803350"
    },
    {
    state: "Gwynedd",
    stateShort: "GWN",
    lat: "52.92772660",
    lng: "-4.13348360"
    },
    {
    state: "Halton",
    stateShort: "HAL",
    lat: "43.53253720",
    lng: "-79.87448360"
    },
    {
    state: "Hampshire",
    stateShort: "HAM",
    lat: "51.05769480",
    lng: "-1.30806290"
    },
    {
    state: "Hartlepool",
    stateShort: "HPL",
    lat: "54.69174500",
    lng: "-1.21292600"
    },
    {
    state: "Herefordshire",
    stateShort: "HEF",
    lat: "52.07651640",
    lng: "-2.65441820"
    },
    {
    state: "Hertfordshire",
    stateShort: "HRT",
    lat: "51.80978230",
    lng: "-0.23767440"
    },
    {
    state: "Highland",
    stateShort: "HLD",
    lat: "36.29675080",
    lng: "-95.83803660"
    },
    {
    state: "Inverclyde",
    stateShort: "IVC",
    lat: "55.93165690",
    lng: "-4.68001580"
    },
    {
    state: "Isle of Wight",
    stateShort: "IOW",
    lat: "50.69384790",
    lng: "-1.30473400"
    },
    {
    state: "Isles of Scilly",
    stateShort: "IOS",
    lat: "49.92772610",
    lng: "-6.32749660"
    },
    {
    state: "Kent",
    stateShort: "KEN",
    lat: "41.15366740",
    lng: "-81.35788590"
    },
    {
    state: "Kirklees",
    stateShort: "KIR",
    lat: "53.59334320",
    lng: "-1.80095090"
    },
    {
    state: "Knowsley",
    stateShort: "KWL",
    lat: "53.45459400",
    lng: "-2.85290700"
    },
    {
    state: "Lancashire",
    stateShort: "LAN",
    lat: "53.76322540",
    lng: "-2.70440520"
    },
    {
    state: "Larne Borough Council",
    stateShort: "LRN",
    lat: "54.85780030",
    lng: "-5.82362240"
    },
    {
    state: "Leeds",
    stateShort: "LDS",
    lat: "53.80075540",
    lng: "-1.54907740"
    },
    {
    state: "Leicestershire",
    stateShort: "LEC",
    lat: "52.77257100",
    lng: "-1.20521260"
    },
    {
    state: "Limavady Borough Council",
    stateShort: "LMV",
    lat: "55.05168200",
    lng: "-6.94919440"
    },
    {
    state: "Lincolnshire",
    stateShort: "LIN",
    lat: "52.94518890",
    lng: "-0.16012460"
    },
    {
    state: "Lisburn and Castlereagh",
    stateShort: "LBC",
    lat: "54.49815840",
    lng: "-6.13067910"
    },
    {
    state: "Lisburn City Council",
    stateShort: "LSB",
    lat: "54.49815840",
    lng: "-6.13067910"
    },
    {
    state: "Liverpool",
    stateShort: "LIV",
    lat: "32.65649810",
    lng: "-115.47632410"
    },
    {
    state: "London Borough of Barking and Dagenham",
    stateShort: "BDG",
    lat: "51.55406660",
    lng: "0.13401700"
    },
    {
    state: "London Borough of Barnet",
    stateShort: "BNE",
    lat: "51.60496730",
    lng: "-0.20762950"
    },
    {
    state: "London Borough of Bexley",
    stateShort: "BEX",
    lat: "51.45190210",
    lng: "0.11717860"
    },
    {
    state: "London Borough of Brent",
    stateShort: "BEN",
    lat: "51.56728080",
    lng: "-0.27105680"
    },
    {
    state: "London Borough of Bromley",
    stateShort: "BRY",
    lat: "51.36797050",
    lng: "0.07006200"
    },
    {
    state: "London Borough of Camden",
    stateShort: "CMD",
    lat: "51.54547360",
    lng: "-0.16279020"
    },
    {
    state: "London Borough of Croydon",
    stateShort: "CRY",
    lat: "51.38274460",
    lng: "-0.09851630"
    },
    {
    state: "London Borough of Ealing",
    stateShort: "EAL",
    lat: "51.52503660",
    lng: "-0.34139650"
    },
    {
    state: "London Borough of Enfield",
    stateShort: "ENF",
    lat: "51.66229090",
    lng: "-0.11806510"
    },
    {
    state: "London Borough of Hackney",
    stateShort: "HCK",
    lat: "51.57344500",
    lng: "-0.07243760"
    },
    {
    state: "London Borough of Hammersmith and Fulham",
    stateShort: "HMF",
    lat: "51.49901560",
    lng: "-0.22915000"
    },
    {
    state: "London Borough of Haringey",
    stateShort: "HRY",
    lat: "51.59061130",
    lng: "-0.11097090"
    },
    {
    state: "London Borough of Harrow",
    stateShort: "HRW",
    lat: "51.58816270",
    lng: "-0.34228510"
    },
    {
    state: "London Borough of Havering",
    stateShort: "HAV",
    lat: "51.57792400",
    lng: "0.21208290"
    },
    {
    state: "London Borough of Hillingdon",
    stateShort: "HIL",
    lat: "51.53518320",
    lng: "-0.44813780"
    },
    {
    state: "London Borough of Hounslow",
    stateShort: "HNS",
    lat: "51.48283580",
    lng: "-0.38820620"
    },
    {
    state: "London Borough of Islington",
    stateShort: "ISL",
    lat: "51.54650630",
    lng: "-0.10580580"
    },
    {
    state: "London Borough of Lambeth",
    stateShort: "LBH",
    lat: "51.45714770",
    lng: "-0.12306810"
    },
    {
    state: "London Borough of Lewisham",
    stateShort: "LEW",
    lat: "51.44145790",
    lng: "-0.01170060"
    },
    {
    state: "London Borough of Merton",
    stateShort: "MRT",
    lat: "51.40977420",
    lng: "-0.21080840"
    },
    {
    state: "London Borough of Newham",
    stateShort: "NWM",
    lat: "51.52551620",
    lng: "0.03521630"
    },
    {
    state: "London Borough of Redbridge",
    stateShort: "RDB",
    lat: "51.58861210",
    lng: "0.08239820"
    },
    {
    state: "London Borough of Richmond upon Thames",
    stateShort: "RIC",
    lat: "51.46130540",
    lng: "-0.30377090"
    },
    {
    state: "London Borough of Southwark",
    stateShort: "SWK",
    lat: "51.48805720",
    lng: "-0.07628380"
    },
    {
    state: "London Borough of Sutton",
    stateShort: "STN",
    lat: "51.35737620",
    lng: "-0.17527960"
    },
    {
    state: "London Borough of Tower Hamlets",
    stateShort: "TWH",
    lat: "51.52026070",
    lng: "-0.02933960"
    },
    {
    state: "London Borough of Waltham Forest",
    stateShort: "WFT",
    lat: "51.58863830",
    lng: "-0.01176250"
    },
    {
    state: "London Borough of Wandsworth",
    stateShort: "WND",
    lat: "51.45682740",
    lng: "-0.18966380"
    },
    {
    state: "Magherafelt District Council",
    stateShort: "MFT",
    lat: "54.75532790",
    lng: "-6.60774870"
    },
    {
    state: "Manchester",
    stateShort: "MAN",
    lat: "53.48075930",
    lng: "-2.24263050"
    },
    {
    state: "Medway",
    stateShort: "MDW",
    lat: "42.14176410",
    lng: "-71.39672560"
    },
    {
    state: "Merthyr Tydfil County Borough",
    stateShort: "MTY",
    lat: "51.74674740",
    lng: "-3.38132750"
    },
    {
    state: "Metropolitan Borough of Wigan",
    stateShort: "WGN",
    lat: "53.51348120",
    lng: "-2.61069990"
    },
    {
    state: "Mid and East Antrim",
    stateShort: "MEA",
    lat: "54.93993410",
    lng: "-6.11374230"
    },
    {
    state: "Mid Ulster",
    stateShort: "MUL",
    lat: "54.64113010",
    lng: "-6.75225490"
    },
    {
    state: "Middlesbrough",
    stateShort: "MDB",
    lat: "54.57422700",
    lng: "-1.23495600"
    },
    {
    state: "Midlothian",
    stateShort: "MLN",
    lat: "32.47533500",
    lng: "-97.01031810"
    },
    {
    state: "Milton Keynes",
    stateShort: "MIK",
    lat: "52.08520380",
    lng: "-0.73331330"
    },
    {
    state: "Monmouthshire",
    stateShort: "MON",
    lat: "51.81161000",
    lng: "-2.71634170"
    },
    {
    state: "Moray",
    stateShort: "MRY",
    lat: "57.64984760",
    lng: "-3.31680390"
    },
    {
    state: "Moyle District Council",
    stateShort: "MYL",
    lat: "55.20473270",
    lng: "-6.25317400"
    },
    {
    state: "Neath Port Talbot County Borough",
    stateShort: "NTL",
    lat: "51.59785190",
    lng: "-3.78396680"
    },
    {
    state: "Newcastle upon Tyne",
    stateShort: "NET",
    lat: "54.97825200",
    lng: "-1.61778000"
    },
    {
    state: "Newport",
    stateShort: "NWP",
    lat: "37.52782340",
    lng: "-94.10438760"
    },
    {
    state: "Newry and Mourne District Council",
    stateShort: "NYM",
    lat: "54.17425050",
    lng: "-6.33919920"
    },
    {
    state: "Newry, Mourne and Down",
    stateShort: "NMD",
    lat: "54.24342870",
    lng: "-5.95779590"
    },
    {
    state: "Newtownabbey Borough Council",
    stateShort: "NTA",
    lat: "54.67924220",
    lng: "-5.95911020"
    },
    {
    state: "Norfolk",
    stateShort: "NFK",
    lat: "36.85076890",
    lng: "-76.28587260"
    },
    {
    state: "North Ayrshire",
    stateShort: "NAY",
    lat: "55.64167310",
    lng: "-4.75946000"
    },
    {
    state: "North Down Borough Council",
    stateShort: "NDN",
    lat: "54.65362970",
    lng: "-5.67249250"
    },
    {
    state: "North East Lincolnshire",
    stateShort: "NEL",
    lat: "53.56682010",
    lng: "-0.08150660"
    },
    {
    state: "North Lanarkshire",
    stateShort: "NLK",
    lat: "55.86624320",
    lng: "-3.96131440"
    },
    {
    state: "North Lincolnshire",
    stateShort: "NLN",
    lat: "53.60555920",
    lng: "-0.55965820"
    },
    {
    state: "North Somerset",
    stateShort: "NSM",
    lat: "51.38790280",
    lng: "-2.77810910"
    },
    {
    state: "North Tyneside",
    stateShort: "NTY",
    lat: "55.01823990",
    lng: "-1.48584360"
    },
    {
    state: "North Yorkshire",
    stateShort: "NYK",
    lat: "53.99150280",
    lng: "-1.54120150"
    },
    {
    state: "Northamptonshire",
    stateShort: "NTH",
    lat: "52.27299440",
    lng: "-0.87555150"
    },
    {
    state: "Northern Ireland",
    stateShort: "NIR",
    lat: "54.78771490",
    lng: "-6.49231450"
    },
    {
    state: "Northumberland",
    stateShort: "NBL",
    lat: "55.20825420",
    lng: "-2.07841380"
    },
    {
    state: "Nottinghamshire",
    stateShort: "NTT",
    lat: "53.10031900",
    lng: "-0.99363060"
    },
    {
    state: "Oldham",
    stateShort: "OLD",
    lat: "42.20405980",
    lng: "-71.20481190"
    },
    {
    state: "Omagh District Council",
    stateShort: "OMH",
    lat: "54.45135240",
    lng: "-7.71250180"
    },
    {
    state: "Orkney Islands",
    stateShort: "ORK",
    lat: "58.98094010",
    lng: "-2.96052060"
    },
    {
    state: "Outer Hebrides",
    stateShort: "ELS",
    lat: "57.75989180",
    lng: "-7.01940340"
    },
    {
    state: "Oxfordshire",
    stateShort: "OXF",
    lat: "51.76120560",
    lng: "-1.24646740"
    },
    {
    state: "Pembrokeshire",
    stateShort: "PEM",
    lat: "51.67407800",
    lng: "-4.90887850"
    },
    {
    state: "Perth and Kinross",
    stateShort: "PKN",
    lat: "56.39538170",
    lng: "-3.42835470"
    },
    {
    state: "Poole",
    stateShort: "POL",
    lat: "50.71505000",
    lng: "-1.98724800"
    },
    {
    state: "Powys",
    stateShort: "POW",
    lat: "52.64642490",
    lng: "-3.32609040"
    },
    {
    state: "Reading",
    stateShort: "RDG",
    lat: "36.14866590",
    lng: "-95.98400120"
    },
    {
    state: "Redcar and Cleveland",
    stateShort: "RCC",
    lat: "54.59713440",
    lng: "-1.07759970"
    },
    {
    state: "Renfrewshire",
    stateShort: "RFW",
    lat: "55.84665400",
    lng: "-4.53312590"
    },
    {
    state: "Rhondda Cynon Taf",
    stateShort: "RCT",
    lat: "51.64902070",
    lng: "-3.42886920"
    },
    {
    state: "Rochdale",
    stateShort: "RCH",
    lat: "53.60971360",
    lng: "-2.15610000"
    },
    {
    state: "Rotherham",
    stateShort: "ROT",
    lat: "53.43260350",
    lng: "-1.36350090"
    },
    {
    state: "Royal Borough of Greenwich",
    stateShort: "GRE",
    lat: "51.48346270",
    lng: "0.05862020"
    },
    {
    state: "Royal Borough of Kensington and Chelsea",
    stateShort: "KEC",
    lat: "51.49908050",
    lng: "-0.19382530"
    },
    {
    state: "Royal Borough of Kingston upon Thames",
    stateShort: "KTT",
    lat: "51.37811700",
    lng: "-0.29270900"
    },
    {
    state: "Rutland",
    stateShort: "RUT",
    lat: "43.61062370",
    lng: "-72.97260650"
    },
    {
    state: "Saint Helena",
    stateShort: "SH-HL",
    lat: "-15.96501040",
    lng: "-5.70892410"
    },
    {
    state: "Salford",
    stateShort: "SLF",
    lat: "53.48752350",
    lng: "-2.29012640"
    },
    {
    state: "Sandwell",
    stateShort: "SAW",
    lat: "52.53616740",
    lng: "-2.01079300"
    },
    {
    state: "Scotland",
    stateShort: "SCT",
    lat: "56.49067120",
    lng: "-4.20264580"
    },
    {
    state: "Scottish Borders",
    stateShort: "SCB",
    lat: "55.54856970",
    lng: "-2.78613880"
    },
    {
    state: "Sefton",
    stateShort: "SFT",
    lat: "53.50344490",
    lng: "-2.97035900"
    },
    {
    state: "Sheffield",
    stateShort: "SHF",
    lat: "36.09507430",
    lng: "-80.27884660"
    },
    {
    state: "Shetland Islands",
    stateShort: "ZET",
    lat: "60.52965070",
    lng: "-1.26594090"
    },
    {
    state: "Shropshire",
    stateShort: "SHR",
    lat: "52.70636570",
    lng: "-2.74178490"
    },
    {
    state: "Slough",
    stateShort: "SLG",
    lat: "51.51053840",
    lng: "-0.59504060"
    },
    {
    state: "Solihull",
    stateShort: "SOL",
    lat: "52.41181100",
    lng: "-1.77761000"
    },
    {
    state: "Somerset",
    stateShort: "SOM",
    lat: "51.10509700",
    lng: "-2.92623070"
    },
    {
    state: "South Ayrshire",
    stateShort: "SAY",
    lat: "55.45889880",
    lng: "-4.62919940"
    },
    {
    state: "South Gloucestershire",
    stateShort: "SGC",
    lat: "51.52643610",
    lng: "-2.47284870"
    },
    {
    state: "South Lanarkshire",
    stateShort: "SLK",
    lat: "55.67359090",
    lng: "-3.78196610"
    },
    {
    state: "South Tyneside",
    stateShort: "STY",
    lat: "54.96366930",
    lng: "-1.44186340"
    },
    {
    state: "Southend-on-Sea",
    stateShort: "SOS",
    lat: "51.54592690",
    lng: "0.70771230"
    },
    {
    state: "St Helens",
    stateShort: "SHN",
    lat: "45.85896100",
    lng: "-122.82123560"
    },
    {
    state: "Staffordshire",
    stateShort: "STS",
    lat: "52.87927450",
    lng: "-2.05718680"
    },
    {
    state: "Stirling",
    stateShort: "STG",
    lat: "56.11652270",
    lng: "-3.93690290"
    },
    {
    state: "Stockport",
    stateShort: "SKP",
    lat: "53.41063160",
    lng: "-2.15753320"
    },
    {
    state: "Stockton-on-Tees",
    stateShort: "STT",
    lat: "54.57045510",
    lng: "-1.32898210"
    },
    {
    state: "Strabane District Council",
    stateShort: "STB",
    lat: "54.82738650",
    lng: "-7.46331030"
    },
    {
    state: "Suffolk",
    stateShort: "SFK",
    lat: "52.18724720",
    lng: "0.97078010"
    },
    {
    state: "Surrey",
    stateShort: "SRY",
    lat: "51.31475930",
    lng: "-0.55995010"
    },
    {
    state: "Swindon",
    stateShort: "SWD",
    lat: "51.55577390",
    lng: "-1.77971760"
    },
    {
    state: "Tameside",
    stateShort: "TAM",
    lat: "53.48058280",
    lng: "-2.08098910"
    },
    {
    state: "Telford and Wrekin",
    stateShort: "TFW",
    lat: "52.74099160",
    lng: "-2.48685860"
    },
    {
    state: "Thurrock",
    stateShort: "THR",
    lat: "51.49345570",
    lng: "0.35291970"
    },
    {
    state: "Torbay",
    stateShort: "TOB",
    lat: "50.43923290",
    lng: "-3.53698990"
    },
    {
    state: "Torfaen",
    stateShort: "TOF",
    lat: "51.70022530",
    lng: "-3.04460150"
    },
    {
    state: "Trafford",
    stateShort: "TRF",
    lat: "40.38562460",
    lng: "-79.75893470"
    },
    {
    state: "United Kingdom",
    stateShort: "UKM",
    lat: "55.37805100",
    lng: "-3.43597300"
    },
    {
    state: "Vale of Glamorgan",
    stateShort: "VGL",
    lat: "51.40959580",
    lng: "-3.48481670"
    },
    {
    state: "Wakefield",
    stateShort: "WKF",
    lat: "42.50393950",
    lng: "-71.07233910"
    },
    {
    state: "Wales",
    stateShort: "WLS",
    lat: "52.13066070",
    lng: "-3.78371170"
    },
    {
    state: "Walsall",
    stateShort: "WLL",
    lat: "52.58621400",
    lng: "-1.98291900"
    },
    {
    state: "Warrington",
    stateShort: "WRT",
    lat: "40.24927410",
    lng: "-75.13406040"
    },
    {
    state: "Warwickshire",
    stateShort: "WAR",
    lat: "52.26713530",
    lng: "-1.46752160"
    },
    {
    state: "West Berkshire",
    stateShort: "WBK",
    lat: "51.43082550",
    lng: "-1.14449270"
    },
    {
    state: "West Dunbartonshire",
    stateShort: "WDU",
    lat: "55.94509250",
    lng: "-4.56462590"
    },
    {
    state: "West Lothian",
    stateShort: "WLN",
    lat: "55.90701980",
    lng: "-3.55171670"
    },
    {
    state: "West Sussex",
    stateShort: "WSX",
    lat: "50.92801430",
    lng: "-0.46170750"
    },
    {
    state: "Wiltshire",
    stateShort: "WIL",
    lat: "51.34919960",
    lng: "-1.99271050"
    },
    {
    state: "Windsor and Maidenhead",
    stateShort: "WNM",
    lat: "51.47997120",
    lng: "-0.62425650"
    },
    {
    state: "Wirral",
    stateShort: "WRL",
    lat: "53.37271810",
    lng: "-3.07375400"
    },
    {
    state: "Wokingham",
    stateShort: "WOK",
    lat: "51.41045700",
    lng: "-0.83386100"
    },
    {
    state: "Worcestershire",
    stateShort: "WOR",
    lat: "52.25452250",
    lng: "-2.26683820"
    },
    {
    state: "Wrexham County Borough",
    stateShort: "WRX",
    lat: "53.03013780",
    lng: "-3.02614870"
    }
    ]
    },
    {
    country: "United States",
    countryShort: "US",
    currency_symbol: "$",
    states: [
    {
    state: "Alabama",
    stateShort: "AL",
    lat: "32.31823140",
    lng: "-86.90229800"
    },
    {
    state: "Alaska",
    stateShort: "AK",
    lat: "64.20084130",
    lng: "-149.49367330"
    },
    {
    state: "American Samoa",
    stateShort: "AS",
    lat: "-14.27097200",
    lng: "-170.13221700"
    },
    {
    state: "Arizona",
    stateShort: "AZ",
    lat: "34.04892810",
    lng: "-111.09373110"
    },
    {
    state: "Arkansas",
    stateShort: "AR",
    lat: "35.20105000",
    lng: "-91.83183340"
    },
    {
    state: "Baker Island",
    stateShort: "UM-81",
    lat: "0.19362660",
    lng: "-176.47690800"
    },
    {
    state: "California",
    stateShort: "CA",
    lat: "36.77826100",
    lng: "-119.41793240"
    },
    {
    state: "Colorado",
    stateShort: "CO",
    lat: "39.55005070",
    lng: "-105.78206740"
    },
    {
    state: "Connecticut",
    stateShort: "CT",
    lat: "41.60322070",
    lng: "-73.08774900"
    },
    {
    state: "Delaware",
    stateShort: "DE",
    lat: "38.91083250",
    lng: "-75.52766990"
    },
    {
    state: "District of Columbia",
    stateShort: "DC",
    lat: "38.90719230",
    lng: "-77.03687070"
    },
    {
    state: "Florida",
    stateShort: "FL",
    lat: "27.66482740",
    lng: "-81.51575350"
    },
    {
    state: "Georgia",
    stateShort: "GA",
    lat: "32.16562210",
    lng: "-82.90007510"
    },
    {
    state: "Guam",
    stateShort: "GU",
    lat: "13.44430400",
    lng: "144.79373100"
    },
    {
    state: "Hawaii",
    stateShort: "HI",
    lat: "19.89676620",
    lng: "-155.58278180"
    },
    {
    state: "Howland Island",
    stateShort: "UM-84",
    lat: "0.81132190",
    lng: "-176.61827360"
    },
    {
    state: "Idaho",
    stateShort: "ID",
    lat: "44.06820190",
    lng: "-114.74204080"
    },
    {
    state: "Illinois",
    stateShort: "IL",
    lat: "40.63312490",
    lng: "-89.39852830"
    },
    {
    state: "Indiana",
    stateShort: "IN",
    lat: "40.26719410",
    lng: "-86.13490190"
    },
    {
    state: "Iowa",
    stateShort: "IA",
    lat: "41.87800250",
    lng: "-93.09770200"
    },
    {
    state: "Jarvis Island",
    stateShort: "UM-86",
    lat: "-0.37435030",
    lng: "-159.99672060"
    },
    {
    state: "Johnston Atoll",
    stateShort: "UM-67",
    lat: "16.72950350",
    lng: "-169.53364770"
    },
    {
    state: "Kansas",
    stateShort: "KS",
    lat: "39.01190200",
    lng: "-98.48424650"
    },
    {
    state: "Kentucky",
    stateShort: "KY",
    lat: "37.83933320",
    lng: "-84.27001790"
    },
    {
    state: "Kingman Reef",
    stateShort: "UM-89",
    lat: "6.38333300",
    lng: "-162.41666700"
    },
    {
    state: "Louisiana",
    stateShort: "LA",
    lat: "30.98429770",
    lng: "-91.96233270"
    },
    {
    state: "Maine",
    stateShort: "ME",
    lat: "45.25378300",
    lng: "-69.44546890"
    },
    {
    state: "Maryland",
    stateShort: "MD",
    lat: "39.04575490",
    lng: "-76.64127120"
    },
    {
    state: "Massachusetts",
    stateShort: "MA",
    lat: "42.40721070",
    lng: "-71.38243740"
    },
    {
    state: "Michigan",
    stateShort: "MI",
    lat: "44.31484430",
    lng: "-85.60236430"
    },
    {
    state: "Midway Atoll",
    stateShort: "UM-71",
    lat: "28.20721680",
    lng: "-177.37349260"
    },
    {
    state: "Minnesota",
    stateShort: "MN",
    lat: "46.72955300",
    lng: "-94.68589980"
    },
    {
    state: "Mississippi",
    stateShort: "MS",
    lat: "32.35466790",
    lng: "-89.39852830"
    },
    {
    state: "Missouri",
    stateShort: "MO",
    lat: "37.96425290",
    lng: "-91.83183340"
    },
    {
    state: "Montana",
    stateShort: "MT",
    lat: "46.87968220",
    lng: "-110.36256580"
    },
    {
    state: "Navassa Island",
    stateShort: "UM-76",
    lat: "18.41006890",
    lng: "-75.01146120"
    },
    {
    state: "Nebraska",
    stateShort: "NE",
    lat: "41.49253740",
    lng: "-99.90181310"
    },
    {
    state: "Nevada",
    stateShort: "NV",
    lat: "38.80260970",
    lng: "-116.41938900"
    },
    {
    state: "New Hampshire",
    stateShort: "NH",
    lat: "43.19385160",
    lng: "-71.57239530"
    },
    {
    state: "New Jersey",
    stateShort: "NJ",
    lat: "40.05832380",
    lng: "-74.40566120"
    },
    {
    state: "New Mexico",
    stateShort: "NM",
    lat: "34.51994020",
    lng: "-105.87009010"
    },
    {
    state: "New York",
    stateShort: "NY",
    lat: "40.71277530",
    lng: "-74.00597280"
    },
    {
    state: "North Carolina",
    stateShort: "NC",
    lat: "35.75957310",
    lng: "-79.01929970"
    },
    {
    state: "North Dakota",
    stateShort: "ND",
    lat: "47.55149260",
    lng: "-101.00201190"
    },
    {
    state: "Northern Mariana Islands",
    stateShort: "MP",
    lat: "15.09790000",
    lng: "145.67390000"
    },
    {
    state: "Ohio",
    stateShort: "OH",
    lat: "40.41728710",
    lng: "-82.90712300"
    },
    {
    state: "Oklahoma",
    stateShort: "OK",
    lat: "35.46756020",
    lng: "-97.51642760"
    },
    {
    state: "Oregon",
    stateShort: "OR",
    lat: "43.80413340",
    lng: "-120.55420120"
    },
    {
    state: "Palmyra Atoll",
    stateShort: "UM-95",
    lat: "5.88850260",
    lng: "-162.07866560"
    },
    {
    state: "Pennsylvania",
    stateShort: "PA",
    lat: "41.20332160",
    lng: "-77.19452470"
    },
    {
    state: "Puerto Rico",
    stateShort: "PR",
    lat: "18.22083300",
    lng: "-66.59014900"
    },
    {
    state: "Rhode Island",
    stateShort: "RI",
    lat: "41.58009450",
    lng: "-71.47742910"
    },
    {
    state: "South Carolina",
    stateShort: "SC",
    lat: "33.83608100",
    lng: "-81.16372450"
    },
    {
    state: "South Dakota",
    stateShort: "SD",
    lat: "43.96951480",
    lng: "-99.90181310"
    },
    {
    state: "Tennessee",
    stateShort: "TN",
    lat: "35.51749130",
    lng: "-86.58044730"
    },
    {
    state: "Texas",
    stateShort: "TX",
    lat: "31.96859880",
    lng: "-99.90181310"
    },
    {
    state: "United States Minor Outlying Islands",
    stateShort: "UM",
    lat: "19.28231920",
    lng: "166.64704700"
    },
    {
    state: "United States Virgin Islands",
    stateShort: "VI",
    lat: "18.33576500",
    lng: "-64.89633500"
    },
    {
    state: "Utah",
    stateShort: "UT",
    lat: "39.32098010",
    lng: "-111.09373110"
    },
    {
    state: "Vermont",
    stateShort: "VT",
    lat: "44.55880280",
    lng: "-72.57784150"
    },
    {
    state: "Virginia",
    stateShort: "VA",
    lat: "37.43157340",
    lng: "-78.65689420"
    },
    {
    state: "Wake Island",
    stateShort: "UM-79",
    lat: "19.27961900",
    lng: "166.64993480"
    },
    {
    state: "Washington",
    stateShort: "WA",
    lat: "47.75107410",
    lng: "-120.74013850"
    },
    {
    state: "West Virginia",
    stateShort: "WV",
    lat: "38.59762620",
    lng: "-80.45490260"
    },
    {
    state: "Wisconsin",
    stateShort: "WI",
    lat: "43.78443970",
    lng: "-88.78786780"
    },
    {
    state: "Wyoming",
    stateShort: "WY",
    lat: "43.07596780",
    lng: "-107.29028390"
    }
    ]
    },
  
    {
    country: "Uruguay",
    countryShort: "UY",
    currency_symbol: "$",
    states: [
    {
    state: "Artigas Department",
    stateShort: "AR",
    lat: "-30.61751120",
    lng: "-56.95945590"
    },
    {
    state: "Canelones Department",
    stateShort: "CA",
    lat: "-34.54087170",
    lng: "-55.93076000"
    },
    {
    state: "Cerro Largo Department",
    stateShort: "CL",
    lat: "-32.44110320",
    lng: "-54.35217530"
    },
    {
    state: "Colonia Department",
    stateShort: "CO",
    lat: "-34.12946780",
    lng: "-57.66051840"
    },
    {
    state: "Durazno Department",
    stateShort: "DU",
    lat: "-33.02324540",
    lng: "-56.02846440"
    },
    {
    state: "Flores Department",
    stateShort: "FS",
    lat: "-33.57337530",
    lng: "-56.89450280"
    },
    {
    state: "Florida Department",
    stateShort: "FD",
    lat: "28.03594950",
    lng: "-82.45792890"
    },
    {
    state: "Lavalleja Department",
    stateShort: "LA",
    lat: "-33.92261750",
    lng: "-54.97657940"
    },
    {
    state: "Maldonado Department",
    stateShort: "MA",
    lat: "-34.55979320",
    lng: "-54.86285520"
    },
    {
    state: "Montevideo Department",
    stateShort: "MO",
    lat: "-34.81815870",
    lng: "-56.21382560"
    },
    {
    state: "Paysandú Department",
    stateShort: "PA",
    lat: "-32.06673660",
    lng: "-57.33647890"
    },
    {
    state: "Río Negro Department",
    stateShort: "RN",
    lat: "-32.76763560",
    lng: "-57.42952070"
    },
    {
    state: "Rivera Department",
    stateShort: "RV",
    lat: "-31.48174210",
    lng: "-55.24357590"
    },
    {
    state: "Rocha Department",
    stateShort: "RO",
    lat: "-33.96900810",
    lng: "-54.02148500"
    },
    {
    state: "Salto Department",
    stateShort: "SA",
    lat: "-31.38802800",
    lng: "-57.96124550"
    },
    {
    state: "San José Department",
    stateShort: "SJ",
    lat: "37.34929680",
    lng: "-121.90560490"
    },
    {
    state: "Soriano Department",
    stateShort: "SO",
    lat: "-33.51027920",
    lng: "-57.74981030"
    },
    {
    state: "Tacuarembó Department",
    stateShort: "TA",
    lat: "-31.72068370",
    lng: "-55.98598870"
    },
    {
    state: "Treinta y Tres Department",
    stateShort: "TT",
    lat: "-33.06850860",
    lng: "-54.28586270"
    }
    ]
    },
    {
    country: "Uzbekistan",
    countryShort: "UZ",
    currency_symbol: "лв",
    states: [
    {
    state: "Andijan Region",
    stateShort: "AN",
    lat: "40.76859410",
    lng: "72.23637900"
    },
    {
    state: "Bukhara Region",
    stateShort: "BU",
    lat: "40.25041620",
    lng: "63.20321510"
    },
    {
    state: "Fergana Region",
    stateShort: "FA",
    lat: "40.45680810",
    lng: "71.28742090"
    },
    {
    state: "Jizzakh Region",
    stateShort: "JI",
    lat: "40.47064150",
    lng: "67.57085360"
    },
    {
    state: "Karakalpakstan",
    stateShort: "QR",
    lat: "43.80413340",
    lng: "59.44579880"
    },
    {
    state: "Namangan Region",
    stateShort: "NG",
    lat: "41.05100370",
    lng: "71.09731700"
    },
    {
    state: "Navoiy Region",
    stateShort: "NW",
    lat: "42.69885750",
    lng: "64.63376850"
    },
    {
    state: "Qashqadaryo Region",
    stateShort: "QA",
    lat: "38.89862310",
    lng: "66.04635340"
    },
    {
    state: "Samarqand Region",
    stateShort: "SA",
    lat: "39.62701200",
    lng: "66.97497310"
    },
    {
    state: "Sirdaryo Region",
    stateShort: "SI",
    lat: "40.38638080",
    lng: "68.71549750"
    },
    {
    state: "Surxondaryo Region",
    stateShort: "SU",
    lat: "37.94090050",
    lng: "67.57085360"
    },
    {
    state: "Tashkent",
    stateShort: "TK",
    lat: "41.29949580",
    lng: "69.24007340"
    },
    {
    state: "Tashkent Region",
    stateShort: "TO",
    lat: "41.22132340",
    lng: "69.85974060"
    },
    {
    state: "Xorazm Region",
    stateShort: "XO",
    lat: "41.35653360",
    lng: "60.85666860"
    }
    ]
    },
    {
    country: "Vanuatu",
    countryShort: "VU",
    currency_symbol: "VT",
    states: [
    {
    state: "Malampa",
    stateShort: "MAP",
    lat: "-16.40114050",
    lng: "167.60778650"
    },
    {
    state: "Penama",
    stateShort: "PAM",
    lat: "-15.37957580",
    lng: "167.90531820"
    },
    {
    state: "Sanma",
    stateShort: "SAM",
    lat: "-15.48400170",
    lng: "166.91820970"
    },
    {
    state: "Shefa",
    stateShort: "SEE",
    lat: "32.80576500",
    lng: "35.16997100"
    },
    {
    state: "Tafea",
    stateShort: "TAE",
    lat: "-18.72378270",
    lng: "169.06450560"
    },
    {
    state: "Torba",
    stateShort: "TOB",
    lat: "37.07653000",
    lng: "27.45657300"
    }
    ]
    },
  
    {
    country: "Venezuela",
    countryShort: "VE",
    currency_symbol: "Bs",
    states: [
    {
    state: "Amazonas",
    stateShort: "Z",
    lat: "-3.41684270",
    lng: "-65.85606460"
    },
    {
    state: "Anzoátegui",
    stateShort: "B",
    lat: "8.59130730",
    lng: "-63.95861110"
    },
    {
    state: "Apure",
    stateShort: "C",
    lat: "6.92694830",
    lng: "-68.52471490"
    },
    {
    state: "Aragua",
    stateShort: "D",
    lat: "10.06357580",
    lng: "-67.28478750"
    },
    {
    state: "Barinas",
    stateShort: "E",
    lat: "8.62314980",
    lng: "-70.23710450"
    },
    {
    state: "Bolívar",
    stateShort: "F",
    lat: "37.61448380",
    lng: "-93.41047490"
    },
    {
    state: "Carabobo",
    stateShort: "G",
    lat: "10.11764330",
    lng: "-68.04775090"
    },
    {
    state: "Cojedes",
    stateShort: "H",
    lat: "9.38166820",
    lng: "-68.33392750"
    },
    {
    state: "Delta Amacuro",
    stateShort: "Y",
    lat: "8.84993070",
    lng: "-61.14031960"
    },
    {
    state: "Distrito Capital",
    stateShort: "A",
    lat: "41.26148460",
    lng: "-95.93108070"
    },
    {
    state: "Falcón",
    stateShort: "I",
    lat: "11.18106740",
    lng: "-69.85974060"
    },
    {
    state: "Federal Dependencies of Venezuela",
    stateShort: "W",
    lat: "10.93770530",
    lng: "-65.35695730"
    },
    {
    state: "Guárico",
    stateShort: "J",
    lat: "8.74893090",
    lng: "-66.23671720"
    },
    {
    state: "La Guaira",
    stateShort: "X",
    lat: "29.30522680",
    lng: "-94.79138540"
    },
    {
    state: "Lara",
    stateShort: "K",
    lat: "33.98221650",
    lng: "-118.13227470"
    },
    {
    state: "Mérida",
    stateShort: "L",
    lat: "20.96737020",
    lng: "-89.59258570"
    },
    {
    state: "Miranda",
    stateShort: "M",
    lat: "42.35193830",
    lng: "-71.52907660"
    },
    {
    state: "Monagas",
    stateShort: "N",
    lat: "9.32416520",
    lng: "-63.01475780"
    },
    {
    state: "Nueva Esparta",
    stateShort: "O",
    lat: "10.99707230",
    lng: "-63.91132960"
    },
    {
    state: "Portuguesa",
    stateShort: "P",
    lat: "9.09439990",
    lng: "-69.09702300"
    },
    {
    state: "Sucre",
    stateShort: "R",
    lat: "-19.03534500",
    lng: "-65.25921280"
    },
    {
    state: "Táchira",
    stateShort: "S",
    lat: "7.91370010",
    lng: "-72.14161320"
    },
    {
    state: "Trujillo",
    stateShort: "T",
    lat: "36.67343430",
    lng: "-121.62875880"
    },
    {
    state: "Yaracuy",
    stateShort: "U",
    lat: "10.33938900",
    lng: "-68.81088490"
    },
    {
    state: "Zulia",
    stateShort: "V",
    lat: "10.29102370",
    lng: "-72.14161320"
    }
    ]
    },
    {
    country: "Vietnam",
    countryShort: "VN",
    currency_symbol: "₫",
    states: [
    {
    state: "An Giang",
    stateShort: "44",
    lat: "10.52158360",
    lng: "105.12589550"
    },
    {
    state: "Bà Rịa-Vũng Tàu",
    stateShort: "43",
    lat: "10.54173970",
    lng: "107.24299760"
    },
    {
    state: "Bắc Giang",
    stateShort: "54",
    lat: "21.28199210",
    lng: "106.19747690"
    },
    {
    state: "Bắc Kạn",
    stateShort: "53",
    lat: "22.30329230",
    lng: "105.87600400"
    },
    {
    state: "Bạc Liêu",
    stateShort: "55",
    lat: "9.29400270",
    lng: "105.72156630"
    },
    {
    state: "Bắc Ninh",
    stateShort: "56",
    lat: "21.12144400",
    lng: "106.11105010"
    },
    {
    state: "Bến Tre",
    stateShort: "50",
    lat: "10.24335560",
    lng: "106.37555100"
    },
    {
    state: "Bình Dương",
    stateShort: "57",
    lat: "11.32540240",
    lng: "106.47701700"
    },
    {
    state: "Bình Định",
    stateShort: "31",
    lat: "14.16653240",
    lng: "108.90268300"
    },
    {
    state: "Bình Phước",
    stateShort: "58",
    lat: "11.75118940",
    lng: "106.72346390"
    },
    {
    state: "Bình Thuận",
    stateShort: "40",
    lat: "11.09037030",
    lng: "108.07207810"
    },
    {
    state: "Cà Mau",
    stateShort: "59",
    lat: "9.15267280",
    lng: "105.19607950"
    },
    {
    state: "Cần Thơ",
    stateShort: "CT",
    lat: "10.03418510",
    lng: "105.72255070"
    },
    {
    state: "Cao Bằng",
    stateShort: "04",
    lat: "22.63568900",
    lng: "106.25221430"
    },
    {
    state: "Đà Nẵng",
    stateShort: "DN",
    lat: "16.05440680",
    lng: "108.20216670"
    },
    {
    state: "Đắk Lắk",
    stateShort: "33",
    lat: "12.71001160",
    lng: "108.23775190"
    },
    {
    state: "Đắk Nông",
    stateShort: "72",
    lat: "12.26464760",
    lng: "107.60980600"
    },
    {
    state: "Điện Biên",
    stateShort: "71",
    lat: "21.80423090",
    lng: "103.10765250"
    },
    {
    state: "Đồng Nai",
    stateShort: "39",
    lat: "11.06863050",
    lng: "107.16759760"
    },
    {
    state: "Đồng Tháp",
    stateShort: "45",
    lat: "10.49379890",
    lng: "105.68817880"
    },
    {
    state: "Gia Lai",
    stateShort: "30",
    lat: "13.80789430",
    lng: "108.10937500"
    },
    {
    state: "Hà Giang",
    stateShort: "03",
    lat: "22.80255880",
    lng: "104.97844940"
    },
    {
    state: "Hà Nam",
    stateShort: "63",
    lat: "20.58351960",
    lng: "105.92299000"
    },
    {
    state: "Hà Nội",
    stateShort: "HN",
    lat: "21.02776440",
    lng: "105.83415980"
    },
    {
    state: "Hà Tĩnh",
    stateShort: "23",
    lat: "18.35595370",
    lng: "105.88774940"
    },
    {
    state: "Hải Dương",
    stateShort: "61",
    lat: "20.93734130",
    lng: "106.31455420"
    },
    {
    state: "Hải Phòng",
    stateShort: "HP",
    lat: "20.84491150",
    lng: "106.68808410"
    },
    {
    state: "Hậu Giang",
    stateShort: "73",
    lat: "9.75789800",
    lng: "105.64125270"
    },
    {
    state: "Hồ Chí Minh",
    stateShort: "SG",
    lat: "10.82309890",
    lng: "106.62966380"
    },
    {
    state: "Hòa Bình",
    stateShort: "14",
    lat: "20.68612650",
    lng: "105.31311850"
    },
    {
    state: "Hưng Yên",
    stateShort: "66",
    lat: "20.85257110",
    lng: "106.01699710"
    },
    {
    state: "Khánh Hòa",
    stateShort: "34",
    lat: "12.25850980",
    lng: "109.05260760"
    },
    {
    state: "Kiên Giang",
    stateShort: "47",
    lat: "9.82495870",
    lng: "105.12589550"
    },
    {
    state: "Kon Tum",
    stateShort: "28",
    lat: "14.34974030",
    lng: "108.00046060"
    },
    {
    state: "Lai Châu",
    stateShort: "01",
    lat: "22.38622270",
    lng: "103.47026310"
    },
    {
    state: "Lâm Đồng",
    stateShort: "35",
    lat: "11.57527910",
    lng: "108.14286690"
    },
    {
    state: "Lạng Sơn",
    stateShort: "09",
    lat: "21.85370800",
    lng: "106.76151900"
    },
    {
    state: "Lào Cai",
    stateShort: "02",
    lat: "22.48094310",
    lng: "103.97549590"
    },
    {
    state: "Long An",
    stateShort: "41",
    lat: "10.56071680",
    lng: "106.64976230"
    },
    {
    state: "Nam Định",
    stateShort: "67",
    lat: "20.43882250",
    lng: "106.16210530"
    },
    {
    state: "Nghệ An",
    stateShort: "22",
    lat: "19.23424890",
    lng: "104.92003650"
    },
    {
    state: "Ninh Bình",
    stateShort: "18",
    lat: "20.25061490",
    lng: "105.97445360"
    },
    {
    state: "Ninh Thuận",
    stateShort: "36",
    lat: "11.67387670",
    lng: "108.86295720"
    },
    {
    state: "Phú Thọ",
    stateShort: "68",
    lat: "21.26844300",
    lng: "105.20455730"
    },
    {
    state: "Phú Yên",
    stateShort: "32",
    lat: "13.08818610",
    lng: "109.09287640"
    },
    {
    state: "Quảng Bình",
    stateShort: "24",
    lat: "17.61027150",
    lng: "106.34874740"
    },
    {
    state: "Quảng Nam",
    stateShort: "27",
    lat: "15.53935380",
    lng: "108.01910200"
    },
    {
    state: "Quảng Ngãi",
    stateShort: "29",
    lat: "15.12138730",
    lng: "108.80441450"
    },
    {
    state: "Quảng Ninh",
    stateShort: "13",
    lat: "21.00638200",
    lng: "107.29251440"
    },
    {
    state: "Quảng Trị",
    stateShort: "25",
    lat: "16.74030740",
    lng: "107.18546790"
    },
    {
    state: "Sóc Trăng",
    stateShort: "52",
    lat: "9.60252100",
    lng: "105.97390490"
    },
    {
    state: "Sơn La",
    stateShort: "05",
    lat: "21.10222840",
    lng: "103.72891670"
    },
    {
    state: "Tây Ninh",
    stateShort: "37",
    lat: "11.33515540",
    lng: "106.10988540"
    },
    {
    state: "Thái Bình",
    stateShort: "20",
    lat: "20.44634710",
    lng: "106.33658280"
    },
    {
    state: "Thái Nguyên",
    stateShort: "69",
    lat: "21.56715590",
    lng: "105.82520380"
    },
    {
    state: "Thanh Hóa",
    stateShort: "21",
    lat: "19.80669200",
    lng: "105.78518160"
    },
    {
    state: "Thừa Thiên-Huế",
    stateShort: "26",
    lat: "16.46739700",
    lng: "107.59053260"
    },
    {
    state: "Tiền Giang",
    stateShort: "46",
    lat: "10.44933240",
    lng: "106.34205040"
    },
    {
    state: "Trà Vinh",
    stateShort: "51",
    lat: "9.81274100",
    lng: "106.29929120"
    },
    {
    state: "Tuyên Quang",
    stateShort: "07",
    lat: "21.77672460",
    lng: "105.22801960"
    },
    {
    state: "Vĩnh Long",
    stateShort: "49",
    lat: "10.23957400",
    lng: "105.95719280"
    },
    {
    state: "Vĩnh Phúc",
    stateShort: "70",
    lat: "21.36088050",
    lng: "105.54743730"
    },
    {
    state: "Yên Bái",
    stateShort: "06",
    lat: "21.71676890",
    lng: "104.89858780"
    }
    ]
    },
  
    {
    country: "Virgin Islands (US)",
    countryShort: "VI",
    currency_symbol: "$",
    states: [
    {
    state: "Saint Croix",
    stateShort: "SC",
    lat: "17.72935200",
    lng: "-64.73437050"
    },
    {
    state: "Saint John",
    stateShort: "SJ",
    lat: "18.33561690",
    lng: "-64.80028000"
    },
    {
    state: "Saint Thomas",
    stateShort: "ST",
    lat: "18.34284590",
    lng: "-65.07701800"
    }
    ]
    }, 
    {
    country: "Yemen",
    countryShort: "YE",
    currency_symbol: "﷼",
    states: [
    {
    state: "'Adan",
    stateShort: "AD",
    lat: "12.82574810",
    lng: "44.79438040"
    },
    {
    state: "'Amran",
    stateShort: "AM",
    lat: "16.25692140",
    lng: "43.94367880"
    },
    {
    state: "Abyan",
    stateShort: "AB",
    lat: "13.63434130",
    lng: "46.05632120"
    },
    {
    state: "Al Bayda'",
    stateShort: "BA",
    lat: "14.35886620",
    lng: "45.44980650"
    },
    {
    state: "Al Hudaydah",
    stateShort: "HU",
    lat: "15.30530720",
    lng: "43.01948970"
    },
    {
    state: "Al Jawf",
    stateShort: "JA",
    lat: "16.79018190",
    lng: "45.29938620"
    },
    {
    state: "Al Mahrah",
    stateShort: "MR",
    lat: "16.52384230",
    lng: "51.68342750"
    },
    {
    state: "Al Mahwit",
    stateShort: "MW",
    lat: "15.39632290",
    lng: "43.56069460"
    },
    {
    state: "Dhamar",
    stateShort: "DH",
    lat: "14.71953440",
    lng: "44.24790150"
    },
    {
    state: "Hadhramaut",
    stateShort: "HD",
    lat: "16.93041350",
    lng: "49.36531490"
    },
    {
    state: "Hajjah",
    stateShort: "HJ",
    lat: "16.11806310",
    lng: "43.32946600"
    },
    {
    state: "Ibb",
    stateShort: "IB",
    lat: "14.14157170",
    lng: "44.24790150"
    },
    {
    state: "Lahij",
    stateShort: "LA",
    lat: "13.14895880",
    lng: "44.85054950"
    },
    {
    state: "Ma'rib",
    stateShort: "MA",
    lat: "15.51588800",
    lng: "45.44980650"
    },
    {
    state: "Raymah",
    stateShort: "RA",
    lat: "14.62776820",
    lng: "43.71424840"
    },
    {
    state: "Saada",
    stateShort: "SD",
    lat: "16.84765280",
    lng: "43.94367880"
    },
    {
    state: "Sana'a",
    stateShort: "SN",
    lat: "15.31689130",
    lng: "44.47480180"
    },
    {
    state: "Sana'a",
    stateShort: "SA",
    lat: "15.36944510",
    lng: "44.19100660"
    },
    {
    state: "Shabwah",
    stateShort: "SH",
    lat: "14.75463030",
    lng: "46.51626200"
    },
    {
    state: "Socotra",
    stateShort: "SU",
    lat: "12.46342050",
    lng: "53.82373850"
    },
    {
    state: "Ta'izz",
    stateShort: "TA",
    lat: "13.57758860",
    lng: "44.01779890"
    }
    ]
    },
    {
    country: "Zambia",
    countryShort: "ZM",
    currency_symbol: "ZK",
    states: [
    {
    state: "Central Province",
    stateShort: "02",
    lat: "7.25649960",
    lng: "80.72144170"
    },
    {
    state: "Copperbelt Province",
    stateShort: "08",
    lat: "-13.05700730",
    lng: "27.54958460"
    },
    {
    state: "Eastern Province",
    stateShort: "03",
    lat: "23.16696880",
    lng: "49.36531490"
    },
    {
    state: "Luapula Province",
    stateShort: "04",
    lat: "-11.56483100",
    lng: "29.04599270"
    },
    {
    state: "Lusaka Province",
    stateShort: "09",
    lat: "-15.36571290",
    lng: "29.23207840"
    },
    {
    state: "Muchinga Province",
    stateShort: "10",
    lat: "-15.38219300",
    lng: "28.26158000"
    },
    {
    state: "Northern Province",
    stateShort: "05",
    lat: "8.88550270",
    lng: "80.27673270"
    },
    {
    state: "Northwestern Province",
    stateShort: "06",
    lat: "-13.00502580",
    lng: "24.90422080"
    },
    {
    state: "Southern Province",
    stateShort: "07",
    lat: "6.23737500",
    lng: "80.54384500"
    },
    {
    state: "Western Province",
    stateShort: "01",
    lat: "6.90160860",
    lng: "80.00877460"
    }
    ]
    },
    {
    country: "Zimbabwe",
    countryShort: "ZW",
    currency_symbol: "$",
    states: [
    {
    state: "Bulawayo Province",
    stateShort: "BU",
    lat: "-20.14895050",
    lng: "28.53310380"
    },
    {
    state: "Harare Province",
    stateShort: "HA",
    lat: "-17.82162880",
    lng: "31.04922590"
    },
    {
    state: "Manicaland",
    stateShort: "MA",
    lat: "-18.92163860",
    lng: "32.17460500"
    },
    {
    state: "Mashonaland Central Province",
    stateShort: "MC",
    lat: "-16.76442950",
    lng: "31.07937050"
    },
    {
    state: "Mashonaland East Province",
    stateShort: "ME",
    lat: "-18.58716420",
    lng: "31.26263660"
    },
    {
    state: "Mashonaland West Province",
    stateShort: "MW",
    lat: "-17.48510290",
    lng: "29.78892480"
    },
    {
    state: "Masvingo Province",
    stateShort: "MV",
    lat: "-20.62415090",
    lng: "31.26263660"
    },
    {
    state: "Matabeleland North Province",
    stateShort: "MN",
    lat: "-18.53315660",
    lng: "27.54958460"
    },
    {
    state: "Matabeleland South Province",
    stateShort: "MS",
    lat: "-21.05233700",
    lng: "29.04599270"
    },
    {
    state: "Midlands Province",
    stateShort: "MI",
    lat: "-19.05520090",
    lng: "29.60354950"
    }
    ]
    }
    ]
    export default countryStates