import "./reviewUser.css";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import AuthorInfo from "../../profile/user/authorInfo";
import UserProfile from "../../profile/user/userProfile";
import apiUsers from "../../../../../../utils/apiUsers";

export default function ReviewUser() {
  const signedUser = useContext(CartContext);
  const messageRef = useRef(null);
  const location = useLocation();
  const { data } = location.state;
  const [user, setUser] = useState(data); 

  const [accessCodes, setAccessCodes] = useState();
  useEffect(
    () => {(async (e) => {
      const response = await apiUsers.getRolesInfo(signedUser.user.token);
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      setAccessCodes(response.data[0]);
    })()},[]
  );

  function checkedHandle(e) {  
    const roleId = Number(e.target.id);
    const updatedRoles = [...user?.roles] || [];
    const roleIndex = updatedRoles.findIndex((role) => role === roleId);
    if (roleIndex !== -1) {    
      updatedRoles.splice(roleIndex, 1);
    } else {     
      updatedRoles.push(roleId);
    }
    console.log(updatedRoles)
    setUser(prev=>({...prev,
    roles:updatedRoles
    }));



  }
  
  function accessRender(codes) {
    const rows = [];
    for (let key in codes) {
      // if (key == '__v') return   console.log('_id');
      rows.push(
        <li className="accessRows">
          <label htmlFor={codes[key]}>{key}</label>
          <input
            className="form-check-input"
            id={codes[key]}
            type="checkbox"
            checked={user?.roles?.some((role) => role === codes[key])}
            onChange={checkedHandle}
          />
        </li>
      );
    }
    return rows;
  }
 
  function AccessDiv() {
    if (!accessCodes) return;
    const row = [];
    for (let key in accessCodes) {
        key !== "_id" &&
        key !== "__v" &&
        key !== "updatedAt" &&
        key !== "createdAt" &&
        row.push(
          <ul key={key} className="">
            <h5>{window.capitalize(key)}</h5>            
            {accessRender(accessCodes[key])}
          </ul>
        );
    }    
    return row;
  }
  async function rolesConfirmHandle() {
    // console.log(signedUser.user._id ,signedUser.user.token, user.roles)
    const response = await apiUsers.setUserRoles(
      user._id,
      signedUser.user.token,
      user.roles
    );
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    messageRef.current.showSuccess({
      message: "به روز رسانی دسترسی با موفقیت انجام شد.",
    });

    setUser(response.data);
  }

  return (
    // <div className="ReviewContainer justify-contetnt-start">
    <div className="d-flex justify-contetnt-start">
      <ToastNote messageRef={messageRef} />
      <UserProfile user={user} />
      <AuthorInfo user2={user} />      
      <div className="RightContainerAcc">    
        <div className="d-flex flex-column "><AccessDiv /></div>
        <button className="btn btn-success" onClick={rolesConfirmHandle}>
          تائید
        </button>
      </div>
    </div>
  );
}
