import { Outlet} from "react-router-dom"



export default function BusDahOut() {
 
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
  
    <Outlet/>
        
    </div>
  )
}
