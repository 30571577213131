import React, { useContext } from 'react'
import { CartContext } from '../../../contexts/cartContext'

export default function ActionBtnGenerator({session,currentStatus ,actionHandler,currentDate,access}){
   const {user}=useContext(CartContext)
    const buttons =[]
    if(access==='bus'||access==='tutor'||access==='admin'){
    (currentStatus === "confirmed" ||
    currentStatus === "booked" ||
    currentStatus === "test" )&&
    (new Date(session.sessionInfo.start).getTime()+( Number(session.sessionInfo.duration)*60*60*1000)) <= currentDate && function(){              
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-greenGray"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "completed",
            session,
          })
        }
      >
         برگزار شد 
      </div>)
    buttons.push(<div
      className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
      onClick={(e) =>
        actionHandler({
          e,
          currentStatus: currentStatus,
          newStatus: "noShowedS",
          session,
        })
      }
    >
      غیبت دانشجو
    </div>)
    }()
  
    if((currentStatus !== "canceled" &&
    currentStatus !== "refunded" &&
    currentStatus !== "notRefunded" &&
    currentStatus !== "selected" &&
    new Date(session.sessionInfo.start) >= currentDate)){
      currentStatus !== "confirmed" && buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-pinkOrange"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "confirmed",
            session,
          })
        }
      >
        تائید 
      </div>)
      currentStatus !== "canceled" && buttons.push(<div
        className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200"
        // className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "canceled",
            session,
          })
        }
      >
        کنسل 
      </div>)
    }
  
    if(
      (currentStatus === "canceled") &&
      session.history[0]?.user !== user?._id&&
      session.guest._id !== user?._id&&
      session.sessionInfo.rate > 0&&
      new Date(session.sessionInfo.start).getTime() -
    new Date(session.history[0].timestamp).getTime() <=
    24 * 60 * 60 * 1000 ||currentStatus === "noShowedS"){
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-redDark"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "refunded",
            session,
          })
        }
      >
        بازگشت وجه
      </div>)
  
      buttons.push(<div
        className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200"
        onClick={(e) =>
          actionHandler({
            e,
            currentStatus: currentStatus,
            newStatus: "notRefunded",
            session,
          })
        }
      >
        واریز به حساب من
      </div>)
  
    }
  
  
  
    }

    if(access==="user"){
        if((currentStatus === "confirmed" ||
           currentStatus === "booked" ||
           currentStatus === "test" )&&
           (new Date(session.sessionInfo.start).getTime()+( Number(session.sessionInfo.duration)*60*60*1000)) <= currentDate) {      
           buttons.push(<div
             className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
             onClick={(e) =>
               actionHandler({
                 e,
                 currentStatus: currentStatus,
                 newStatus: "noShowedT",
                 session,
               })
             }
           >
             گزارش غیبت استاد
           </div>)
           }
       
             if((currentStatus === "confirmed" ||
           currentStatus === "booked" ||
           currentStatus === "test" )&&
           currentStatus !== "canceled"&&
           new Date(session.sessionInfo.start) > currentDate) {              
            
           buttons.push(<div
             className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200 "
             onClick={(e) =>
               actionHandler({
                 e,
                 currentStatus: currentStatus,
                 newStatus: "canceled",
                 session,
               })
             }
           >
             کنسل
           </div>)
           }
           
           }

  
  
  
    return(buttons)
    
  }
