import { Outlet } from "react-router-dom"



export default function UserDashOut() {
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
   {/* <h1>Dashbord User Header</h1> */}
    <Outlet/>
        
    </div>
  )
}
