import { NavLink } from "react-router-dom";

export default function UserDashTransNav() {
  return (
    <div className="dashboardNavigator d-flex flex-wrap justify-content-md-start justify-content-around p-3">
      {/* <NavLink to={`/${window.lcs}/dashboard/user/trans/overview`} className={`sidebarListItem m-1 link  ${({isActive})=>{return isActive && "active" }}`}>دیدگاه کلی</NavLink> */}
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/points`}
        className={`sidebarListItem m-md-3  link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        متا پوینت ها
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/wallet`}
        className={`sidebarListItem m-md-3  link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        کیف پول
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/invlist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        صورتحساب ها
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/orderlist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        سفارش های ناتمام
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/refcodelist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        گزارش بازاریابی
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/trans/affilatelist`}
        className={`sidebarListItem m-md-3 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        گزارش لینک معرفی
      </NavLink>
    </div>
  );
}
