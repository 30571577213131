import React, { useState } from "react";
import renderData from "../../../../utils/renderData";
// import TimePicker from "../../../golbal/timePicker";



export default function WeekdaysSelector({ formik }) {  
  // const [active, setActive] = useState({ index: null, type: null });

  function addBtn() {
    formik.setValues((prev) => {
      const newF = { ...prev };
      const index = newF.workingDays.length;
      newF.workingDays.push({...newF.workingDays[index-1],weekDay:"" }     );
      return newF;
    });
  }

  function removeHandle(index) {
    formik.setValues((prev) => {
      const newFormik = { ...prev };
      newFormik.workingDays.splice(index, 1);
      return newFormik;
    });
  }

  function convertTime(time24h) {
    if (!time24h) return "";
    var timeParts = time24h.split(':');
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);
    var period = hours < 12 ? 'AM' : 'PM';
    if (hours > 12) {
      hours -= 12;
    }
    var hoursStr = hours.toString().padStart(2, '0');
    var minutesStr = minutes.toString().padStart(2, '0');
    return `${period} ${hoursStr}:${minutesStr}`;
  }

  return (
    <div>      
      <div id="workingHoursSetting" className="form-label mb-0" htmlFor="f6">
        ساعت کاری
      </div>
      {formik.values.workingDays?.map((day, i) => (
        <div key={"workingDays" + i}>
          <br />         
          <div className="p-2 maintable" style={{ textAlign: "right", backgroundColor: "white", borderRadius: ".5rem" }}>
            <br />
            <div className="container-fluid m-0 p-0">
              <div className="table timeRepeaterParent">
                <div key="0" id="0" className="data-contact-person timeRepeaterChild">
                  <select
                    className="pb-2 my-2 login-btn"
                    dir="rtl"
                    name={"days" + i}
                    id={"days" + i}
                    {...formik.getFieldProps(`workingDays[${i}].weekDay`)}
                  >
                    <option value="">
                      {renderData.weekDays?.find((day) => day.name === formik.values?.workingDays[i]?.weekDay)?.[window.lang] || "روز کاری را انتخاب کنید"}
                    </option>
                    {renderData.weekDays?.map((day, j) => (
                      <option key={j} value={day.name}>
                        {day?.[window.lang]}
                      </option>
                    ))}
                  </select>
                  <br />
                  <div className="d-flex">
                    <label className="small-gray-font">
                      از ساعت:
                      <input
                        type="time"
                        className="mx-1"
                        {...formik.getFieldProps(`workingDays[${i}].from`)}
                      />
                    </label>
                    <label className="small-gray-font">
                      تا ساعت:
                      <input
                        type="time"
                        className="mx-1"
                        {...formik.getFieldProps(`workingDays[${i}].to`)}
                      />
                    </label>
                    <button
                      type="button"
                      style={{ visibility: i > 0 ? "visible" : "hidden" }}
                      title="پاک کردن روز و ساعت"
                      className="deleteContact btn btn login-trigger3 btn-xs m-3"
                      onClick={() => removeHandle(i)}
                    >
                      <i className="bi bi-x-circle-fill" style={{ fontSize: "1.5em" }}></i>
                    </button>
                  </div>
                  {formik.touched.workingDays && formik.touched.workingDays[i]?.weekDay && formik.errors.workingDays && formik.errors.workingDays[i]?.weekDay && (
                    <div className="error-message">
                      <i className="bi bi-exclamation-triangle-fill"></i>
                      {formik.errors.workingDays[i]?.weekDay}
                    </div>
                  )}
                  {formik.touched.workingDays && formik.touched.workingDays[i]?.from && formik.errors.workingDays && formik.errors.workingDays[i]?.from && (
                    <div className="error-message">
                      <i className="bi bi-exclamation-triangle-fill"></i>
                      {formik.errors.workingDays[i]?.from}
                    </div>
                  )}
                  {formik.touched.workingDays && formik.touched.workingDays[i]?.to && formik.errors.workingDays && formik.errors.workingDays[i]?.to && (
                    <div className="error-message">
                      <i className="bi bi-exclamation-triangle-fill"></i>
                      {formik.errors.workingDays[i]?.to}
                    </div>
                  )}
                </div>
              </div> 
            </div>
          </div>
        </div>
      ))}

      <button
        type="button"
        title="افزودن روز و ساعت"
        id="btnAdd"
        className="btn btn-xs shadow-none login-trigger2 m-3 mx-4"
        onClick={addBtn}
      >
        <i className="bi bi-plus-circle-fill" style={{ fontSize: "1.5em" }}></i>
      </button>
    </div>
  );
}


//  function WeekdaysSelector({ formik }) {  
//   const[active,setActive]=useState({index:null , type:null})
//   return (
//     <div>      
//           <div
//             id="workingHoursSetting"
//             className="form-label mb-0"
//             htmlFor="f6"
//           >
//              ساعت کاری
//           </div>
//            {formik.values.workingDays?.map((day, i) => (
//         <div key={"workingDays"+i}>
//           <br />         
//           <div
//             className="p-2  maintable"
//             style={{
//               textAlign: "right",
//               backgroundColor: "white",
//               borderRadius: ".5rem",
//             }}
//           >
//             <br />
//             <div className="container-fluid m-0 p-0">
//               <div className="table timeRepeaterParent">
//                 <div
//                   key="0"
//                   id="0"
//                   className="data-contact-person timeRepeaterChild "
//                 >
//                   <select
//                     className="pb-2 my-2 login-btn "
//                     dir="rtl"
//                     name={"days"+i}
//                     id={"days"+i}
//                     {...formik.getFieldProps(`workingDays[${i}].weekDay`)}
//                   >
//                     <option value="">
//                       {renderData.weekDays?.find(
//                         (day) => day.name === formik.values?.workingDays[i]?.weekDay
//                       )?.[window.lang] || "روز کاری را انتخاب کنید"}
//                     </option>
//                     {renderData.weekDays?.map((day, i) => (
//                       <option key={i} value={day.name}>
//                         {day?.[window.lang]}
//                       </option>
//                     ))}
//                   </select>
//                   <br />
//                   <div className="d-flex ">                   
//                     <span className="small-gray-font" onClick={() =>
//                         setActive((prev) => ({
//                           ...prev,
//                           type: prev.type === "from"?null:"from" ,
//                           index: i,
//                         }))
//                       }> از ساعت:  
//                       <span className="btn primary-btn checkout-btn checkout-btn-narrow mx-1">{day.from&&convertTime(day.from) }</span> 
//                        </span>
                   
//                     <span className="small-gray-font"  onClick={() =>
//                         setActive((prev) => ({
//                           ...prev,
//                           type: prev.type === "to"?null:"to" ,
//                           index: i,
//                         }))
//                       }>تا ساعت : 
//                        <span className="btn primary-btn checkout-btn checkout-btn-narrow mx-1">{day.to&&convertTime(day.to)}</span> </span>
//                        <button
//                 type="button"
//                 style={{visibility:i > 0?"visible":"hidden"}}
//                 title="پاک کردن روز و ساعت"
//                 className="deleteContact btn btn login-trigger3 btn-xs m-3"
//                 onClick={() => removeHandle(i)}
//               >
//                 <i
//                   className="bi bi-x-circle-fill"
//                   style={{ fontSize: "1.5em" }}
//                 ></i>
//               </button>
                 
//                     </div>

//                     {formik.touched.workingDays&&formik.touched.workingDays[i]?.weekDay&&formik.errors.workingDays&&formik.errors.workingDays[i]?.weekDay && (
//                         <div className="error-message">
//                           <i className="bi bi-exclamation-triangle-fill"></i>
//                           {formik.errors.workingDays[i]?.weekDay}
//                         </div>
//                       )}
//                     {formik.touched.workingDays&&formik.touched.workingDays[i]?.from&&formik.errors.workingDays&&formik.errors.workingDays[i]?.from && (
//                         <div className="error-message">
//                           <i className="bi bi-exclamation-triangle-fill"></i>
//                           {formik.errors.workingDays[i]?.from}
//                         </div>
//                       )}
//                     {formik.touched.workingDays&&formik.touched.workingDays[i]?.to&&formik.errors.workingDays&&formik.errors.workingDays[i]?.to && (
//                         <div className="error-message">
//                           <i className="bi bi-exclamation-triangle-fill"></i>
//                           {formik.errors.workingDays[i]?.to}
//                         </div>
//                       )}
              
                 
//                 </div>
//               </div> 
//             </div>
//             {active.type &&<TimePicker formik={formik} i={i}
//             active={active}
//             />} 
           
              
         
//           </div>
            
//         </div>
//       ))}

//       <button
//         type="button"
//         title="افزودن روز و ساعت"
//         id="btnAdd"
//         className="btn btn-xs shadow-none login-trigger2 m-3 mx-4"
//         onClick={addBtn}
//       >
//         <i className="bi bi-plus-circle-fill" style={{ fontSize: "1.5em" }}></i>
//       </button>
   
//     </div>
//   );

//   function addBtn() {
//     console.log("addBtn");
//     formik.setValues((prev) => {
//       const newF = { ...prev };
//       const index = newF.workingDays.length;
//       console.log("addsocialHandle", newF, "index", index);
//       newF.workingDays.push(newF.workingDays[index-1]);
//       // newF.workingDays.push({
//       //   from: "",        
//       //   to: "",       
//       //   weekDay: "",
//       // });
//       return newF;
//     });
//   }
//   function removeHandle(index) {
//     console.log("removeHandle");
//     formik.setValues((prev) => {
//       const newFormik = { ...prev };
//       newFormik.workingDays.splice(index, 1);
//       return newFormik;
//     });
//   }

//   function convertTime(time24h) {
//     var timeParts = time24h.split(':');
//     var hours = parseInt(timeParts[0]);
//     var minutes = parseInt(timeParts[1]);
    
//     // Determine AM/PM
//     var period = hours < 12 ? 'AM' : 'PM';
    
//     // Convert to 12-hour format
//     if (hours > 12) {
//       hours -= 12;
//     }
    
//     // Ensure two-digit formatting
//     var hoursStr = hours.toString().padStart(2, '0');
//     var minutesStr = minutes.toString().padStart(2, '0');
    
//     // Concatenate the modified values
//     var time12h = period + ' ' + hoursStr + ':' + minutesStr;
    
//     return time12h;
//   }


// }
