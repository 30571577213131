import { CircularProgress } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../../contexts/cartContext";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import api from "../../../../utils/api";
import ToastNote from "../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import tools from "../../../../utils/tools";

function PostSummaryCard({ data, posts }) {
  // console.log(posts);
  const section = "blog";
  const navigate = useNavigate();

  const messageRef = useRef(null);
  const [loading, setLoading] = useState({ save: false });
  const { user, signedUser } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setLoading((pre) => ({ ...pre, save: true }));
    const response = await api.saveHandler({
      id: data._id,
      token: user.token,
      section,
    });
    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 2);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div>
              دوباره تلاش کنید و در صورت عدم موفقیت دوباره وارد حساب خود شوید.
            </div>
          </div>
        ),
      });

    signedUser(response.data);

    !user?.saved?.[section]?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "پست با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "پست از لیست ذخیره حذف شد.",
        });
  };
  const navigateHandle = (url, state) => {
    window.scroll(0, 0);

    setTimeout((e) => navigate(url, { state: { data, posts } }), 200);
  };

  return (
    <div key={data._id} className="card entry  p-0 w-100 ">
      <ToastNote messageRef={messageRef} />

      <div className="row  g-0 ">
        <div className="col-md-5 d-flex align-items-center ">
          <div className="image-container ">
            <img
              src={data.img}
              alt={data.title?.substring(0, 10)}
              className="img-fluid blog-img image-content"
              onClick={(e) =>
                navigateHandle(`/${window.lcs}/blog/post/${data._id}`)
              }
              loading="lazy"
            />
            {/* {data.status === "01-draft" && (
                  <Tooltip1 
                    icon={<MyIcons icon={data?.status} />}
                    message="  این مطلب توسط کاربران نوشته شده و متامارس مسیولیتی در مورداین نوشته ندارد. "
                  />
                )} */}
            {data.status === "01-draft" && (
              <div className="user-sug-tag mx-2" to={`?status=draft`}>
                نوشته‌های شما
                <Tooltip1
                  icon={<MyIcons icon={data?.status} />}
                  message={`این مطلب توسط ${data?.author?.authorName} عزیز گردآوری و همه حقوق و مسئولیت‌ها متعلق به ایشان می باشد. `}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-7 d-flex flex-column pt-2">
          <div className="card-body py-1">
            <h5 className="card-title  entry-title">
              <div
                href="blog-single.html"
                onClick={(e) =>
                  navigateHandle(`/${window.lcs}/blog/post/${data._id}`)
                }
              >
                {data.title}
              </div>
            </h5>
            <div className="entry-meta ">
              <ul>
                {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li> --> */}
                <li className="d-flex align-items-center me-1">
                  {/* <i className="bi bi-pencil"></i> */}
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      window.scroll(0, 0);
                      navigate(
                        `/${window.lcs}/blog/filters?authorId=${data?.author?._id}&title=${data?.author?.authorName}`
                      );
                    }}

                    // to={`/${window.lcs}/blog/filters?authorId=${data?.author?._id}&title=${data?.author?.authorName}`}
                  >
                    <img
                      className="me-1"
                      style={{
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                      }}
                      src={data?.author?.thumbnail}
                      alt={
                        data?.author?.authorName + "/" + data.title.slice(0, 15)
                      }
                      loading="lazy"
                    />
                    {data?.author?.authorName}
                  </div>
                </li>

                <li className="d-flex align-items-center">
                  <i className="bi bi-calendar4" title="تاریخ انتشار"></i>
                  <span href="blog-single.html">
                    <time
                      dateTime="2020-01-01"
                     
                    >
                    {tools.dynamicDate({date:data?.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true})}

                    </time>
                  </span>
                </li>

                <li className="d-flex align-items-center " title="زمان مطالعه">
                  <i className="bi bi-stopwatch "></i>
                  {/* {data.readTime} */}
                  {tools.dynamicNumber({number: data?.readTime ,lang:window.lang})}

                  دقیقه
                </li>
                {/* <!-- <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li> --> */}

                <li
                  className="d-flex align-items-center"
                  title="پسند"
                  onClick={(e) =>
                    navigateHandle(`/${window.lcs}/blog/post/${data._id}`)
                  }
                >
                  <i className="bi bi-heart"></i>                 
                  {tools.dynamicNumber({number: data?.likes ,lang:window.lang})}
                  
                </li>
                <li
                  className="d-flex align-items-center"
                  title="بازدید"
                  onClick={(e) =>
                    navigateHandle(`/${window.lcs}/blog/post/${data._id}`)
                  }
                >
                  <i className="bi bi-eye"></i>
                  {tools.dynamicNumber({number: data?.views ,lang:window.lang})}
                </li>
              </ul>
            </div>
            <div className="entry-content d-flex flex-column p-0">
              <p className="card-text m-0 ">{data?.coverSummary}</p>
              <div
                className="read-more2  d-flex justify-content-end mb-3"
                onClick={(e) =>
                  navigateHandle(`/${window.lcs}/blog/post/${data._id}`)
                }
              >
                بیشتر بخوانید
                <span>
                  <i className="bi bi-chevron-double-left ms-1"></i>
                </span>
              </div>

              {/* <!-- end of card bady --> */}

              {/* <!-- end of card footer --> */}
            </div>
          </div>
          <div className="card-footer pt-0 d-flex mt-auto ">
            {/* <!-- <a href="#" className="btn btn-primary btn-card stretched-link">  بیشتر</a> --> */}
            <div className="me-auto d-flex justify-content-start ">
              <div
                onClick={(e) =>
                  navigateHandle(
                    `/${window.lcs}/blog/filters?mainCat=${
                      data?.mainCat?.[window.lang]
                    }&mainCatId=${data?.mainCat?._id}`
                  )
                }
                title="دسته‌بندی اصلی"
                className="btn btn-primary button-filter button-filter-tag mx-2 "
              >
                {data?.mainCat?.[window.lang]}
              </div>
              <div
                title="دسته‌بندی کوچکتر"
                onClick={(e) =>
                  navigateHandle(
                    `/${window.lcs}/blog/filters?mainCat=${
                      data?.mainCat?.[window.lang]
                    }&mainCatId=${data?.mainCat?._id}&subCat=${
                      data?.subCat?.[window.lang]
                    }&subCatId=${data?.subCat?._id}`
                  )
                }
                className="btn btn-primary button-filter button-filter-tag  button-filter-tag2 mx-2 me-auto"
              >
                {data?.subCat?.[window.lang]}
              </div>
            </div>
            <div className="ms-auto d-flex align-items-center">
              {/* <span className="icon-stack" > */}
              <button className="icon-btn icon-stack" onClick={saveHandler}>
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.[section]?.includes(data._id)
                        ? "bi bi-bookmark-fill pt-1 icon-stack-1x"
                        : "bi bi-bookmark pt-1 icon-stack-1x"
                    }
                    title="ذخیره"
                  ></i>
                )}
              </button>
              {/* </span> */}
              <span>
                <button
                  className=" icon-stack mx-1 d-flex justify-content-center align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data._id}`}
                  id={`shareBtn${data._id}`}
                  data-bs-placement="top"
                  title="همرسانی!"
                >
                  <i className="bi bi-share pt-1" title="همرسانی"></i>
                </button>
                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lcs}/blog/post/${data._id}`}
                  title={data.title}
                  img={data?.img}
                  _id={data._id}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostSummaryCard;
