import { Check } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
// import Resizer from "react-image-file-resizer";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import tools from "../../../../utils/tools";
import api from "../../../../utils/api";
import Tooltip1 from "../../../golbal/tooltip/tooltip";

export default function BusinessPhotos(props) {
  // console.log(props.formik.values);
  const { user, signedUser } = useContext(CartContext);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState({
    loading1: false, //sendBusinessCardToFirebase
    loading11: false, //businessCardHandle
    loading2: false, //sendCoverToFirebase
    loading12: false, //CoverHandle
    loading3: false, //sendLogoToFirebase
    loading13: false, //logHandle
    loading4: false, //sendBodyPhotosFirebase
    loading14: false, //sendBodyPhotoshandle
  });
  const [photos, setPhotos] = useState({
    businessCard: {
      blob: "",
      resized: "",
      url: "",
    },
    coverPhoto: {
      blob: "",
      resized: "",
      url: "",
    },
    bodyPhotos: {
      blob: [],
      resized: [],
      urls: [],
    },
    logo: {
      blob: "",
      resized: "",
      url: "",
    },
  });
  // console.log(photos);
  const coverPhotoHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, loading12: true }));
    tools
      .resizeFile({ file: e.target.files[0], width: 1100, height: 1000 })
      .then((resized) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          coverPhoto: {
            ...prevPhotos.coverPhoto,
            resized: resized,
            url: "",
          },
        }))
      )
      .catch((err) => console.log(err));

    tools
      .resizeFile({
        file: e.target.files[0],
        width: 1000,
        height: 1000,
        blob: true,
      })
      .then((blob) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          coverPhoto: {
            ...prevPhotos.coverPhoto,
            blob: blob,
          },
        }))
      )
      .catch((err) => console.log(err));
    setLoading((pre) => ({ ...pre, loading12: false }));
  };
  const businessCardHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, loading11: true }));
    tools
      .resizeFile({ file: e.target.files[0] })
      .then((resized) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          businessCard: {
            ...prevPhotos.businessCard,
            resized: resized,
            url: "",
          },
        }))
      )
      .catch((err) => console.log(err));

    tools
      .resizeFile({ file: e.target.files[0], blob: true })
      .then((blob) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          businessCard: {
            ...prevPhotos.businessCard,
            blob: blob,
          },
        }))
      )
      .catch((err) => console.log(err));
    setLoading((pre) => ({ ...pre, loading11: false }));
  };
  const logoHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, loading13: true }));
    tools
      .resizeFile({ file: e.target.files[0] })
      .then((resized) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          logo: {
            ...prevPhotos.logo,
            resized: resized,
            url: "",
          },
        }))
      )
      .catch((err) => console.log(err));

    tools
      .resizeFile({ file: e.target.files[0], blob: true })
      .then((blob) =>
        setPhotos((prevPhotos) => ({
          ...prevPhotos,
          logo: {
            ...prevPhotos.logo,
            blob: blob,
          },
        }))
      )
      .catch((err) => console.log(err));

    setLoading((pre) => ({ ...pre, loading13: false }));
  };

  const bodyPhotosHandle = async (e) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: " فرمت فایل را از میان  jpeg , png , gif انتخاب کنید.",
      });

    setLoading((pre) => ({ ...pre, loading14: true }));
    const resizedPhotos = await Promise.all(
      [...Array.from(e.target.files)]
        .slice(0, 6)
        .map(
          async (x) =>
            await tools.resizeFile({ file: x, width: 1000, height: 1000 })
        )
    );
    const resizedPhotosBlob = await Promise.all(
      [...Array.from(e.target.files)].slice(0, 6).map(
        async (x) =>
          await tools.resizeFile({
            file: x,
            width: 1000,
            height: 1000,
            blob: true,
          })
      )
    );
    setLoading((pre) => ({ ...pre, loading14: false }));
    if (resizedPhotos.length < 6) {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
        bodyPhotos: {
          ...prevPhotos.bodyPhotos,
          resized: photos.bodyPhotos.resized
            .slice(resizedPhotos.length - 6)
            .concat(resizedPhotos),
          blob: photos.bodyPhotos.blob
            .slice(resizedPhotos.length - 6)
            .concat(resizedPhotosBlob),
          urls: [],
        },
      }));
    } else {
      setPhotos((prevPhotos) => ({
        ...prevPhotos,
        bodyPhotos: {
          ...prevPhotos.bodyPhotos,
          resized: resizedPhotos,
          blob: resizedPhotosBlob,
          urls: [],
        },
      }));
    }
  };
  // const resizeFile = ({ file, width, height, quality, blob }) =>
  //   new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       width || 1000,
  //       height || 1000,
  //       "JPEG",
  //       quality || 100,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       blob ? "blob" : "base64"
  //     );
  //   });

  const sendBodyPhotosFirebase = async () => {
    if (photos.bodyPhotos.blob == []) return;
    console.log('sendBusinessCardToFirebase04');
    const uplaodedURLS2 = await Promise.all(
      photos.bodyPhotos.blob?.map(async (img) => {
        const formData = new FormData();
        formData.append("file", img);
        const response = await api.uploadSingleFile({
          token: user.token,
          formData,
          path: "images/business",
        });
        if (response.error)
          return messageRef.current.showError({
            message: (
              <div>
                <div>مشکل در ذخیره فایل.</div>
                <div dir="ltr">{response.error}</div>
              </div>
            ),
          });
        return response.data;
      })
    );
    console.log('sendBusinessCardToFirebase04',uplaodedURLS2);
    return uplaodedURLS2;
  };

  const sendBusinessCardToFirebase = async () => {
    if (photos.businessCard.blob == "") return;
    console.log('sendBusinessCardToFirebase01');
    const formData = new FormData();
    formData.append("file", photos.businessCard.blob);
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/business",
    });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
      console.log('sendBusinessCardToFirebase01',response.data);
      return response.data;

  };

  const sendCoverToFirebase = async () => {
    if (photos.coverPhoto.blob == "") return;
    console.log('sendBusinessCardToFirebase03');
    const formData = new FormData();
    formData.append("file", photos.coverPhoto.blob);
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/business",
    });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
      console.log('sendBusinessCardToFirebase03',response.data);
    return response.data;
  };

  const sendLogoToFirebase = async () => {
    if (photos.logo.blob == "") return;
    console.log('sendBusinessCardToFirebase0');
    const formData = new FormData();
    formData.append("file", photos.logo.blob);
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/business",
    });
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
      console.log('sendBusinessCardToFirebase02',response.data);
    return response.data;
  };

  useEffect(() => {
    props.formik.setValues((prev) => {
      const newVal = { ...prev };
      prev.photos = photos;

      return newVal;
    });
  }, [photos]);

  function deleteHandle(e) {
    const newArray = photos.bodyPhotos.resized.filter(
      (x, i) => i != e.target.id
    );
    const newArrayBlob = photos.bodyPhotos.blob.filter(
      (x, i) => i != e.target.id
    );
    setPhotos((prevPhotos) => ({
      ...prevPhotos,
      bodyPhotos: {
        ...prevPhotos.bodyPhotos,
        resized: newArray,
        blob: newArrayBlob,
      },
    }));
  }

  props.photoRef.current = {
    sendBusinessCardToFirebase,
    sendLogoToFirebase,
    sendCoverToFirebase,
    sendBodyPhotosFirebase,
    photos,
  };

  return (
    <div
      // className="tab-pane fade  addbusinessform-content"
      // id="v-pills-upload"
      // role="tabpanel"
      // aria-labelledby="v-pills-upload-tab"
    >
      <ToastNote messageRef={messageRef} />
      <div className="row d-flex">
        <div className="col-md-6 pe-0">
          <div className="container p-0 m-0">
            <label htmlFor="formFile1" className="form-label form-titr mb-2 ">
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              عکس معرفی <span className="small-gray-font">(نمایش در لیست کسب و کارها)</span>
              <Tooltip1
                              message=" این عکس در صفحه فیلترها نمایش داده خواهد شد."
                              position="top-end"
                            />
            </label>
            <div className="mb-0 form-group d-flex files ">
              <input
                disabled={loading.loading11 || loading.loading1}
                onChange={businessCardHandle}
                className="form-control p-3"
                type="file"
                accept="image/jpeg, image/png, image/gif"
                id="formFile1"
                dir="ltr"
              />
              <div>
                {loading.loading11 && (
                  <CircularProgress color="inherit" size="20px" />
                )}
              </div>
              {photos?.businessCard?.resized?.length ? (
                <>
                  <img
                    src={photos?.businessCard?.resized}
                    alt={""}
                    className="userShowImg my-0 userShowImg-buscard "
                    loading="lazy"
                  />
                  <i
                    className="bi bi-trash"
                    style={{ color: "red" }}
                    onClick={(e) =>
                      setPhotos((pre) => {
                        const photos = { ...pre };
                        photos.businessCard.url = "";
                        photos.businessCard.blob = "";
                        photos.businessCard.resized = "";

                        const formikValues = { ...props.formik.values };
                        formikValues.photos.businessCard = "";
                        props.formik.setValues(formikValues);

                        return photos;
                      })
                    }
                  ></i>

                  {!props.formik?.values?._id ? (
                    ""
                  ) : photos?.businessCard?.url ? (
                    <Check />
                  ) : (
                    <div
                      className="btn btn-primary"
                      onClick={async (e) => {
                        console.log(e.target);
                        setLoading((pre) => ({
                          ...pre,
                          loading1: true,
                        }));
                        const url = await sendBusinessCardToFirebase();
                        setLoading((pre) => ({
                          ...pre,
                          loading1: false,
                        }));
                        setPhotos((pre) => {
                          const photos = { ...pre };
                          photos.businessCard.url = url;

                          const formikValues = { ...props.formik.values };
                          formikValues.photos.businessCard = url;
                          props.formik.setValues(formikValues);

                          return photos;
                        });
                      }}
                    >
                      بفرست
                      {loading.loading1 && (
                        <CircularProgress color="inherit" size="20px" />
                      )}
                    </div>
                  )}
                </>
              ) : props?.formik?.values?.photos?.businessCard?.length ? (
                <img
                  src={props?.formik?.values?.photos?.businessCard}
                  alt={""}
                  className="userShowImg my-0 userShowImg-buscard "
                  loading="lazy"
                />
              ) : (
                ""
              )}
            </div>

            <label htmlFor="formFile2" className="form-label  form-titr mb-2 ">
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              کاور صفحه کسب و کار
              <Tooltip1
                              message="این عکس به عنوان کاور صفحه اختصاصی کسب و کار شما نمایش داده خواهد شد."
                              position="top-end"
                            />
            </label>
            <div className="mb-0 form-group d-flex files ">
              <input
                disabled={loading.loading12 || loading.loading2}
                onChange={coverPhotoHandle}
                className="form-control p-3"
                type="file"
                accept="image/jpeg, image/png, image/gif"
                id="formFile2"
                dir="ltr"
              />
              {loading.loading12 && (
                <CircularProgress color="inherit" size="20px" />
              )}
              {photos?.coverPhoto?.resized?.length ? (
                <>
                  <img
                    src={photos?.coverPhoto?.resized}
                    alt={""}
                    className="userShowImg userShowImg-buscover my-0 mx-3"
                    loading="lazy"
                  />
                  <i
                    className="bi bi-trash"
                    style={{ color: "red" }}
                    onClick={(e) =>
                      setPhotos((pre) => {
                        const photos = { ...pre };
                        photos.coverPhoto.url = "";
                        photos.coverPhoto.blob = "";
                        photos.coverPhoto.resized = "";

                        const formikValues = { ...props.formik.values };
                        formikValues.photos.coverPhoto = "";
                        props.formik.setValues(formikValues);

                        return photos;
                      })
                    }
                  ></i>

                  {!props.formik?.values?._id ? (
                    ""
                  ) : photos?.coverPhoto?.url ? (
                    <Check />
                  ) : (
                    <div
                      className="btn btn-primary login-btn"
                      onClick={async (e) => {
                        setLoading((pre) => ({
                          ...pre,
                          loading2: true,
                        }));
                        const url = await sendCoverToFirebase();
                        setLoading((pre) => ({
                          ...pre,
                          loading2: true,
                        }));
                        setPhotos((pre) => {
                          const photos = { ...pre };
                          photos.coverPhoto.url = url;

                          const formikValues = { ...props.formik.values };
                          formikValues.photos.coverPhoto = url;
                          props.formik.setValues(formikValues);

                          return photos;
                        });
                      }}
                    >
                      بفرست
                      {loading.loading2 && (
                        <CircularProgress color="inherit" size="20px" />
                      )}
                    </div>
                  )}
                </>
              ) : props?.formik?.values?.photos?.coverPhoto?.length ? (
                <img
                  src={props?.formik?.values?.photos?.coverPhoto}
                  alt={""}
                  className="userShowImg my-0 userShowImg-buscard "
                  loading="lazy"
                />
              ) : (
                ""
              )}
            </div>
            <label htmlFor="formFile3" className="form-label  form-titr mb-2">
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              لوگو
            </label>
            <div className="mb-0 form-group d-flex files ">
              <input
                disabled={loading.loading13 || loading.loading3}
                type="file"
                accept="image/jpeg, image/png, image/gif"
                className="form-control p-3 text-center"
                id="formFile3"
                dir="ltr"
                onChange={logoHandle}
              />
              {loading.loading13 && (
                <CircularProgress color="inherit" size="20px" />
              )}
              {photos?.logo?.resized?.length ? (
                <>
                  <img
                    src={photos?.logo?.resized}
                    alt={""}
                    className="userShowImg userShowImg-buslogo my-0 mx-3"
                    loading="lazy"
                  />
                  <i
                    className="bi bi-trash"
                    style={{ color: "red" }}
                    onClick={(e) =>
                      setPhotos((pre) => {
                        const photos = { ...pre };
                        photos.logo.url = "";
                        photos.logo.blob = "";
                        photos.logo.resized = "";

                        const formikValues = { ...props.formik.values };
                        formikValues.photos.logo = "";
                        props.formik.setValues(formikValues);

                        return photos;
                      })
                    }
                  ></i>

                  {!props.formik?.values?._id ? (
                    ""
                  ) : photos?.logo?.url ? (
                    <Check />
                  ) : (
                    <div
                      className="btn btn-primary login-btn"
                      onClick={async (e) => {
                        console.log(e.target);
                        setLoading((pre) => ({
                          ...pre,
                          loading3: true,
                        }));
                        const url = await sendLogoToFirebase();
                        setLoading((pre) => ({
                          ...pre,
                          loading3: false,
                        }));
                        setPhotos((pre) => {
                          const photos = { ...pre };
                          photos.logo.url = url;

                          const formikValues = { ...props.formik.values };
                          formikValues.photos.logo = url;
                          props.formik.setValues(formikValues);

                          return photos;
                        });
                      }}
                    >
                      بفرست
                      {loading.loading3 && (
                        <CircularProgress color="inherit" size="20px" />
                      )}
                    </div>
                  )}
                </>
              ) : props?.formik?.values?.photos?.logo?.length ? (
                <img
                  src={props?.formik?.values?.photos?.logo}
                  alt={""}
                  className="userShowImg my-0 userShowImg-buscard "
                  loading="lazy"
                />
              ) : (
                ""
              )}
            </div>
            <label
              htmlFor="formFileMultiple"
              className="form-label  form-titr mb-2"
            >
              <span>
                <i className="bi bi-circle-fill me-1"></i>
              </span>
              آلبوم عکس‌های کسب و کار
              <Tooltip1
                              message="این عکس ها در گالری اختصاصی صفحه کسب و کار شما نمایش داده خواهد شد. (حداکثر 6 عکس)"
                              position="top-end"
                            />
            </label>
            <div className="mb-0 form-group files">
              <div className="d-flex ">
                <input
                  disabled={loading.loading14 || loading.loading4}
                  className="form-control  p-3"
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  data-mdb-max-file-quantity="6"
                  id="formFileMultiple"
                  dir="ltr"
                  multiple
                  max={5}
                  onChange={bodyPhotosHandle}
                />
                {loading.loading14 && (
                  <CircularProgress color="inherit" size="20px" />
                )}
                {!props.formik?.values?._id
                  ? ""
                  : photos?.bodyPhotos?.resized?.length > 0 &&
                    (photos?.bodyPhotos?.urls?.length > 0 ? (
                      <Check />
                    ) : (
                      <span
                        className="btn btn-primary login-btn m-3"
                        onClick={async (e) => {
                          setLoading((pre) => ({
                            ...pre,
                            loading4: true,
                          }));
                          const urls = await sendBodyPhotosFirebase();
                          setLoading((pre) => ({
                            ...pre,
                            loading4: false,
                          }));

                          setPhotos((pre) => {
                            const photos = { ...pre };
                            photos.bodyPhotos.urls = urls;

                            const formikValues = { ...props.formik.values };
                            formikValues.photos.bodyPhotos = urls;
                            props.formik.setValues(formikValues);

                            return photos;
                          });
                        }}
                      >
                        بفرست
                        {loading.loading4 && (
                          <CircularProgress color="inherit" size="20px" />
                        )}
                      </span>
                    ))}
              </div>

              {photos?.bodyPhotos?.resized.length > 0
                ? photos?.bodyPhotos?.resized.map((img, i) => (
                    <>
                      <img
                        key={i}
                        className="userShowImg userShowImg-busphotos"
                        src={img}
                        alt="userShowImg"
                        loading="lazy"
                      />
                      <i
                        id={i}
                        className="bi bi-trash"
                        style={{ color: "red" }}
                        onClick={deleteHandle}
                      ></i>
                    </>
                  ))
                : props?.formik?.values?.photos?.bodyPhotos?.length > 0
                ? props?.formik?.values?.photos?.bodyPhotos.map((img, i) => (
                    <>
                      <img
                        key={i}
                        className="userShowImg userShowImg-busphotos"
                        src={img}
                        alt={props?.formik?.values.busName?.[window.lang] + i}
                        loading="lazy"
                      />
                      {/* <i
                id={i}
                className="bi bi-trash"
                style={{ color: "red" }}
                onClick={deleteHandle}
              ></i> */}
                    </>
                  ))
                : ""}
            </div>
          </div>
        </div>

        <div className="col display-none-md ">
          <img
            src="/img/Images/Image-upload-rafiki.png"
            className="img-fluid  my-auto "
            alt="bodyPhotos"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}
