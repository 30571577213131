import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'


export default function MediaOut() {
  const [pubData] = useOutletContext();
  return (
    <div  >
   <Outlet context={[pubData]} />
    </div>
  )
}
