import { Outlet } from "react-router-dom"



export default function UsersDashOut() {
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
   
    <Outlet/>
        
    </div>
  )
}
