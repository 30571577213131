import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import apiJobs from "../../../utils/apiJobs";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
// import BusCardSlider from "../../golbal/sliders/busCardSlider";
import RatingManuel from "../../comment/rating";
import SocialMedias from "../business/components/socialMedias";
import MyIcons from "../../golbal/icons/myIcons";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
// import utils from "../../../../../backend/src/utils/utils";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";

export default function JobPage(props) {
  // console.log("props", props);
  const messageRef = useRef(null);
  // const navigate = useNavigate();
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);

  const section = "jobs";
  const data = useLocation().state;
  // console.log("useLocation data", data);
  const { id } = useParams();
  const [job, setJob] = useState(
    props?.data?.preview ? props?.data : data?.data
  );
  const [busSlider, setBusSlider] = useState([]);
  const [cv, setCv] = useState({
    name: "",
    email: "",
    description: "",
    cv: "",
  });
  console.log("cv", cv);

  const [loading, setLoading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    save: false,
  });

  const repliesCounter = () => {
    const comments = job?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    console.log("saveHandler", job);
    const response = await api.saveHandler({
      id: job?._id,
      section,
      token: user.token,
      title: job.moreInfo?.title,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    console.log("job", user?.saved?.job);
    signedUser(response.data);

    !user?.saved?.job?.includes(job?._id)
      ? messageRef.current.showSuccess({
          message: "فرصت شغلی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "فرصت شغلی از لیست ذخیره حذف شد.",
        });
  };

  useEffect(() => {
    if (!id || props?.data?.preview) return;
    (async () => {
      if (id?.length !== 24)
        return messageRef.current.showError({
          message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
        });
      const response = await apiJobs.jobDetails(id);
      if (response.error) return alert(response.error);
      setJob(response.data);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = ` متامارس  |  استخدام  ${job?.moreInfo?.title} در ${
    translations?.[window.lang] + " شهر " + job?.moreInfo?.address?.city
  } | ${job?.category?.subCat?.[window.lang]}`;
  const description =
    job?.moreInfo?.description?.substring(0, 100) +
    " | " +
    job?.moreInfo?.address?.municipality +
    " | " +
    job?.moreInfo?.address?.city;

  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "https://schema.org/",
      "@type": "JobPosting",
      title: job?.moreInfo?.title,
      description: job?.moreInfo?.description,

      identifier: {
        "@type": "PropertyValue",
        name: job?.moreInfo?.busName?.[window.lang],
        value: job?.business,
      },
      datePosted: job?.moreInfo?.dates?.start,
      validThrough: job?.moreInfo?.dates?.end,

      hiringOrganization: {
        "@type": "Organization",
        name: job?.moreInfo?.busName?.[window.lang],
        sameAs: `/${window.lcs}/business/page/${job?.business}`,
        logo: job?.moreInfo?.logo,
      },
      jobLocation: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          streetAddress: job?.moreInfo?.address?.streetNumber,
          addressLocality: job?.moreInfo?.address?.route,
          addressRegion: job?.moreInfo?.address?.state?.toUpperCase(),
          postalCode: job?.moreInfo?.address?.postalCode,
          addressCountry: job?.moreInfo?.address?.countryShort?.toUpperCase(),
        },
      },
      baseSalary: {
        "@type": "MonetaryAmount",
        currency: job?.moreInfo?.salary?.currency,
        value: {
          "@type": "QuantitativeValue",
          maxValue: job?.moreInfo?.salary?.max,
          minValue: job?.moreInfo?.salary?.min,
          // "value": 40.00,
          unitText: job?.moreInfo?.salary?.period,
        },
      },
      employmentType: job?.moreInfo?.emType.join(" - "),
      jobBenefits: job?.moreInfo?.benefits,
      educationRequirements: job?.moreInfo?.studyField,
      experienceRequirements: job?.moreInfo?.experience + " years",
      industry: job?.category?.mainCat?.[window.lang],
      occupationalCategory: job?.moreInfo?.title,
      qualifications: job?.moreInfo?.expertise,
      responsibilities: job?.moreInfo?.description,
      skills: job?.moreInfo?.expertise,
      // "specialCommitments": "VeteranCommit",
      title: job?.moreInfo?.title,
      workHours: job?.moreInfo?.workShifts?.join(" - "),
    },
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: job?.moreInfo?.title,
      description: job?.coverSummary,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs`,
              name: `کاریابی`,
              description:
                "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: job?.moreInfo?.title,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "آگهی استخدام",
    url: `https://www.metamarce.com/${window.lcs}/jobs/page/${job?._id}`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "JobPosting",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* ****** cover ******** */}

      <div className="listing-hero pt-0">
        <div className=" container2 p-0 ">
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
              <div className="listing-cover-page">
                <div className="container p-0 d-flex justify-content-lg-start justify-content-center">
                  <div className="picture-container">
                    <div className=" picture picture2">
                      <img
                        // src="/img/Images/icons8-add-image-64.png"
                        src={job?.moreInfo?.logo}
                        className="picture-src"
                        id="wizardPicturePreview"
                        title=""
                      />
                      {/* <input type="file" id="wizard-picture" className="" /> */}
                    </div>
                  </div>
                  <div>
                    <h1 className="business-name-cover">
                      {job?.moreInfo?.busName?.[window.lang]}
                    </h1>
                    {/* <!-- *******rating stars shold show up here -->*/}
                    <p className="text-start">
                      <Link
                        to={`/${window.lcs}/business/page/${job?.business}#comments2`}
                        title="امتیاز کارفرما در متامارس"
                      >
                        <RatingManuel value={job?.feedbackInfo?.rate} />
                        <span>
                          {Math.floor(job?.feedbackInfo?.rate * 100) / 100}
                        </span>
                        <span className="review-text"></span>
                      </Link>
                      <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {job?.feedbackInfo?.views} )
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6  px-0 d-flex align-items-end justify-content-lg-end justify-content-center ">
              {user?.roles?.includes(1000) && (
                <Link
                  className="btn btn-primary share-cover-btn mx-2 "
                  to={`/${window.lcs}/dashboard/user/jobs/edit/${job?._id}?action=edit`}
                >
                  ویرایش
                </Link>
              )}

              <button
                className="btn btn-primary share-cover-btn mx-2 "
                data-bs-toggle="modal"
                data-bs-target={`#myModel${job?._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                برای دوستانم بفرست!
                <i className="bi bi-share-fill mx-2"></i>
              </button>

              {/* <!-- Modal --> */}
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lcs}/jobs/page/${job?._id}`}
                title={job?.moreInfo?.title}
                img={job?.moreInfo?.businessCard}
                _id={job?._id}
              />

              <button
                className="btn btn-outline-secondary save-cover-btn mx-2"
                onClick={saveHandler}
              >
                برای خودم ذخیره کن
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.[section]?.includes(job?._id)
                        ? "bi bi-bookmark-fill ms-1"
                        : "bi bi-bookmark ms-1"
                    }
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-5">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/jobs/filters?mainCat=${
                job?.category?.mainCat?.[window.lang]
              }&mainCatId=${job?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {job?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/jobs/filters?subCat=${
                job?.category?.subCat?.[window.lang]
              }&subCatId=${job?.category?.subCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {job?.category?.subCat?.[window.lang]}
            </Link>
          </div>
          <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              ( {repliesCounter()} ) دیدگاه‌
            </a>
            {/* <a href="#comments" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-person-square me-1"></i>
              </span>
              همکاری
            </a> */}
            <Link
              to={`/${window.lcs}/business/page/${job?.business}`}
              className="btn btn-primary button-filter "
            >
              <span>
                <i className="bi bi-shop me-1"></i>
              </span>
              صفحه کارفرما در متامارس
            </Link>
            {/* <a href="#" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-calendar-check me-1"></i>
              </span>
              قرار ملاقات
            </a> */}
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className="col-lg-7  order-2 order-lg-1">
            {job?.moreInfo?.title.length > 0 && (
              <div className="listing-details-information p-5  py-3  mb-5">
                <p className="bold-dark-font font-18"> عنوان شغل</p>
                <div className="d-flex">
                  {job?.process === "urgent" && (
                    <Link
                      to={`/${window.lcs}/jobs/filters?process=urgent`}
                      className="btn btn-primary button-filter text-center button-filter-tag button-filter-tag5 me-2 my-0 "
                    >
                      <span>
                        <i className="bi bi-clock me-1 med-gray-font"></i>
                      </span>
                      فوری
                    </Link>
                  )}

                  {job?.moreInfo?.title}
                </div>
              </div>
            )}
            {job?.moreInfo?.description?.length > 0 && (
              <div className="listing-details-information p-5  py-3 mb-5">
                <div className=" divider-small border-0 mt-4">
                  <span></span>
                  <MyIcons icon="portfolio" />
                  <span className="p-0 mb-0 mx-2 ">وظایف شغلی </span>
                </div>

                <p className="text-justify mt-3">
                  {tools.linesSpliter(job?.moreInfo?.description)}
                </p>
              </div>
            )}
            {job?.moreInfo?.expertise?.length > 0 && (
              <div className="listing-details-information p-5  py-3 mb-5">
                <div className=" divider-small border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/skills.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>

                  <span className="p-0 mb-0 ">مهارت‌‌های مورد نیاز </span>
                </div>

                <p className="text-justify mt-3">
                  {tools.linesSpliter(job?.moreInfo?.expertise)}
                </p>
              </div>
            )}
            {job?.moreInfo?.benefits?.length > 0 && (
              <div className="listing-details-information p-5  py-3 mb-5">
                <div className=" divider-small border-0 mt-4">
                  <span>
                    <img
                      src="/img/icons/pie-chart.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>

                  <span className="p-0 mb-0 ">تسهیلات و مزایا </span>
                </div>
                <div className="text-justify mt-3">
                  {tools.linesSpliter(job?.moreInfo?.benefits)}
                </div>
              </div>
            )}
            {job?.moreInfo?.studyField?.length > 0 && (
              <div className="listing-details-information p-5  py-3 mb-5">
                <div className=" divider-small border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/information.png"
                      className="arrow2 pe-2"
                      alt="info"
                    />
                  </span>

                  <span className="p-0 mb-0 ">زمینه تحصیلی </span>
                </div>

                <p className=".text-justify mt-3">
                  {job?.moreInfo?.studyField}
                </p>
              </div>
            )}
            {job?.moreInfo?.tags?.length > 0 && (
              <div className="listing-details-information p-5  py-3 mb-5 ">
                <div className=" divider-small border-0 mt-4 ">
                  <span>
                    <img
                      src="/img/bussiness-page/tag.png"
                      className="arrow2 pe-2"
                      alt="tags"
                    />
                  </span>

                  <span className="p-0 mb-0 ">برچسب‌ها </span>
                </div>
                <ul className=" d-flex flex-wrap justify-content-between">
                  {job?.moreInfo?.tags?.map((tag, i) => (
                    <li>
                      <Link
                        onClick={(e) =>
                          window.scrollTo({ top: 0, smooth: true })
                        }
                        key={i}
                        to={`/${window.lcs}/jobs/filters?tag=${tag}`}
                      >
                        <span key={i}>
                          <i className="bi bi-tag me-1"></i>
                        </span>
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className=" col-lg-5 order-1 order-lg-2">
            <button
              type="button"
              className="btn primary-btn checkout-btn cart-footer mb-5 mt-3  border-0 shadow-none  w-100  "
              // data-bs-toggle="modal"
              // data-bs-target="#applyNow"

              onClick={(e) => {
                e.preventDefault();
                if (!window.logined(9999)) return;
                if (job?.moreInfo?.history?.includes(user._id))
                  return messageRef.current.showError({
                    message: "شما در گذشته برای این فرصت شغلی اقدام نموده اید.",
                  });
                var myModal = document.getElementById("applyNow"); // Get the modal element
                console.log("myModal", myModal);
                var modal = new window.bootstrap.Modal(myModal, {
                  // backdrop: "static",
                  keyboard: true,
                });
                modal.show();
              }}
            >
              <span>
                <i
                  className="bi bi-envelope-plus mx-2"
                  style={{ fontSize: 20 }}
                ></i>
              </span>
              درخواست کار (فرستادن رزومه )
              {job?.moreInfo?.history?.includes(user._id) && (
                <div>شما قبلا برای این فرصت شغلی اقدام نموده اید.</div>
              )}
              <span>
                <i
                  className="bi bi-check-circle-fill ms-5"
                  style={{ fontSize: 20, color: "beige" }}
                ></i>
              </span>
            </button>
            <div
              className="modal fade "
              id="applyNow"
              tabIndex="-1"
              aria-labelledby="applyNowLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered mt-0">
                <div className="modal-content">
                  <div className="modal-body p-5">
                    <div className="text-center mb-4">
                      <h5
                        className="modal-title titr5"
                        id="staticBackdropLabel"
                      >
                        فرم درخواست همکاری
                      </h5>
                    </div>
                    <div className="position-absolute end-0 top-0 p-3">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="mb-3">
                      <label for="nameControlInput" className="form-label">
                        نام و نام خانوادگی
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        id="nameControlInput"
                        placeholder="Enter your name"
                        onKeyUp={(e) =>
                          setCv((pre) => ({ ...pre, name: e.target.value }))
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label for="emailControlInput2" className="form-label">
                        ایمیل
                      </label>
                      <input
                        type="email"
                        autoComplete="off"
                        className="form-control"
                        id="emailControlInput2"
                        placeholder="Enter your email"
                        onKeyUp={(e) =>
                          setCv((pre) => ({ ...pre, email: e.target.value }))
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        for="messageControlTextarea"
                        className="form-label"
                      >
                        توضیحات | Cover letter
                      </label>
                      <textarea
                        className="form-control"
                        id="messageControlTextarea"
                        rows="4"
                        placeholder="Enter your message"
                        onKeyUp={(e) =>
                          setCv((pre) => ({
                            ...pre,
                            description: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                    <div className="mb-4">
                      <label className="form-label" for="inputGroupFile01">
                        بارگذاری رزومه
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile01"
                        onChange={(e) =>
                          setCv((pre) => ({ ...pre, cv: e.target.files[0] }))
                        }
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-100  btn primary-btn view-cart  cart-foot shadow-none border-0 my-0"
                      onClick={sendCvHandle}
                    >
                      فرستادن درخواست
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small border-0">
                <span>
                  <img
                    src="/img/bussiness-page/information-2.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 ">جزییات کار</span>
              </div>

              <div className="me-auto d-flex flex-wrap justify-content-center ">
                <div className="m-3 mt-0">
                  <div className="med-gray-font text-center "> سابقه کار</div>
                  <a
                    title="سابقه کار"
                    className="btn btn-primary button-filter button-filter-tag button-filter-tag4 mx-0 "
                    href="/fa/CA/QC/jobs/filters"
                  >
                    {
                      renderData.jobExperience.find(
                        (exper) => exper.name === job?.moreInfo?.experience
                      )?.[window.lang]
                    }
                  </a>
                </div>
                <div className="m-3 mt-0">
                  <div className="med-gray-font text-center "> حقوق </div>
                  <a
                    title="حقوق"
                    className="btn btn-primary button-filter button-filter-tag button-filter-tag4 mx-0 "
                    href="/fa/CA/QC/jobs/filters"
                  >
                    <span className=" small-gray-font px-2"> از </span>
                    {tools.showPrice(
                      job?.moreInfo?.salary?.min,
                      job?.moreInfo?.salary?.currency
                    )}
                    <span className=" small-gray-font px-2"> تا </span>
                    {tools.showPrice(
                      job?.moreInfo?.salary?.max,
                      job?.moreInfo?.salary?.currency
                    )}
                    <span className=" small-gray-font px-2">
                      {`(${
                        renderData.jobPeriod.find(
                          (per) => per.name === job?.moreInfo?.salary?.period
                        )?.[window.lang]
                      })`}
                    </span>
                  </a>
                </div>
              </div>
              <div className="m-3 w-100 ">
                <div className="med-gray-font text-center "> نوع همکاری </div>
                <div className="d-flex justify-content-center flex-wrap">
                  {job?.moreInfo?.emType.map((em) => (
                    <Link
                      title="نوع همکاری"
                      className="btn btn-primary button-filter button-filter-tag button-filter-tag4  mx-2"
                      to={`/${window.lcs}/jobs/filters?emType=${em}`}
                    >
                      {
                        renderData.emType.find((emp) => emp.name === em)?.[
                          window.lang
                        ]
                      }
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small border-0">
                <span>
                  <img
                    src="/img/bussiness-page/alarm-clock.png"
                    className="arrow2 pe-2 py-1"
                    alt="tags"
                  />
                </span>
                <span className="p-0 mb-0 ">روز و ساعت کاری</span>
                <div className="d-flex justify-content-center flex-wrap">
                  {job?.moreInfo?.workShifts?.map((ws) => (
                    <Link
                      title="نوع همکاری"
                      className="btn btn-primary button-filter button-filter-tag button-filter-tag4  mx-2"
                      to={`/${window.lcs}/jobs/filters?workShifts=${ws}`}
                    >
                      {
                        renderData.workShifts.find((emp) => emp.name === ws)?.[
                          window.lang
                        ]
                      }
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small border-0">
                <span>
                  <img
                    src="/img/bussiness-page/home-address.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 ">آدرس محل کار</span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span>
                    {job?.moreInfo?.address?.displayAddress && (
                      <span className="addressBusPage">
                        {`
                    ${
                      job?.moreInfo?.address?.number.length
                        ? "#" +
                          window.capitalize(job?.moreInfo?.address?.number) +
                          ","
                        : ""
                    }
                    ${
                      job?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            job?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      job?.moreInfo?.address?.route?.length
                        ? window.capitalize(job?.moreInfo?.address?.route) + ","
                        : ""
                    }
                    
                    ${
                      job?.moreInfo?.address?.postalCode
                        ? job?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                      </span>
                    )}
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      job?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            job?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      job?.moreInfo?.address?.city?.length
                        ? window.capitalize(job?.moreInfo?.address?.city) + ","
                        : ""
                    }
                    ${
                      job?.moreInfo?.address?.state?.length
                        ? window.capitalize(job?.moreInfo?.address?.state) + ","
                        : ""
                    }
                    ${
                      job?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            job?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>
              </ul>

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={job?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small border-0">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 ">راه‌های تماس</span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {job?.moreInfo?.phone ?? ""}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <a href={"job?.moreInfo?.website[0]"}>
                    {job?.moreInfo?.moreContact ?? ""}
                  </a>
                </li>
                <li className="group-list-item">
                  <i className="bi bi-envelope mx-3"></i>
                  {job?.email}
                </li>
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={job?.moreInfo?.socialMedia} />
              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={job?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <p className=" d-flex justify-content-around">
                <div className="mx-3 text-center">
                  <div
                    className="med-gray-font m-1"
                  >
                    تاریخ آغاز آگهی
                  </div>
                  <span className="m-0 med-gray-font">
                    <i className="bi bi-calendar-plus med-gray-font px-2 "></i>
                
            {tools.dynamicDate({date:job?.moreInfo?.dates?.start,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                  </span>
                </div>
                <div className="mx-3 text-center">
                  <div
                    className="med-gray-font m-1"
                    
                  >
                    تاریخ پایان آگهی
                  </div>
                  <span className="m-0 med-gray-font">
                    <i className="bi bi-calendar2-check med-gray-font px-2 "></i>
                    {tools.dynamicDate({date:job?.moreInfo?.dates?.end,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                   
                  </span>
                </div>
              </p>
            </div>
          </div>
          {/* <!-- </div> -->*/}
        </div>

        {/* <!-- ممکنه براتون جالب باشه... -->*/}
        {busSlider?.length >= 4 && (
          <div className=" mx-5 ">
            <div className="divider2 d-flex ">
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  -->*/}
              </svg>

              <span className="p-0 mb-0 ">ممکنه براتون جالب باشه...</span>
              <div className="p-0 mb-0 ms-auto med-gray-font">
                دریافت آگهی‌های مشابه
                <span>
                  <i className="bi bi-bell" style={{ fontSize: 20 }}></i>
                </span>
              </div>
            </div>

            {/* <BusCardSlider2 
            businessess={data?.businessess}
            subCat={job?.category?.subCategory}
            /> */}
          </div>
        )}
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={id}
            value={job}
            setData={setJob}
            collection="Job"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  async function sendCvHandle(e) {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (cv.email.length && !emailRegex.test(cv.email))
      return messageRef.current.showError({
        message: "لطفا ایمیل را درست وارد نمائید.",
      });
    if (job?.moreInfo?.history?.includes(user._id))
      return messageRef.current.showError({
        message: "شما در گذشته برای این فرصت شغلی اقدام نموده اید.",
      });
    if (!cv.cv?.size)
      return messageRef.current.showError({
        message: "لطفا رزومه خود را انتخاب نمائید.",
      });
    if (!cv.cv?.size > 2000000)
      return messageRef.current.showError({
        message: "لطفا از فایل با حجم حداکثر 2 مگابایت استفاده نمائید.",
      });
    if (!cv.cv?.type?.startsWith("application/pdf"))
      return messageRef.current.showError({
        message: "لطفا برای فایل از فرمت پی دی اف استفاده نمائید.",
      });

    const formData = new FormData();
    formData.append("file", cv.cv);
    formData.append("name", cv.name);
    formData.append("email", cv.email);
    formData.append("description", cv.description);

    // console.log("cv.cv:",cv.cv,'file:',file);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiJobs.jobApply({
      token: user.token,
      data: cv,
      _id: job._id,
      formData,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در فرستادن رزومه.</div>{" "}
            <div dir="ltr">{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({
      message: "رزومه شما با موفقیت برای این فرصت شغلی فرستاده شد.",
    });
    setJob(response.data);
  }
}
