import { Route } from "react-router-dom";
import BusTerms from "../components/screens/business/busTerms";
import EventRegister from "../components/screens/event/eventRegister";
import EventHome from "../components/screens/event/eventHome";
import EventPage from "../components/screens/event/page/eventPage";
import EventFilters from "../components/screens/event/filters/eventFilters";
import SeatingMap from "../components/screens/event/seatingMap/seatingMap";
export default function eventRoutes() { 
  return (
    <>
      <Route path="" element={<EventHome />} />      
      {/* <Route path="terms" element={<BusTerms />} />  */}
      {/* <Route path="register" element={<EventRegister action="register"/>} /> */}
      <Route path="page/:id/:title?" element={<EventPage />} />
      <Route path="booking/:id/:title?" element={<SeatingMap />} />
       <Route path="filters" element={<EventFilters />}>
      </Route>
    </>
  );
}
