import { Route } from "react-router-dom";

// import BlogAuthor from "../components/screens/blog/blogAuthor";
import BlogFilters from "../components/screens/blog/blogCarts/blogFilters";
// import BlogCategory from "../components/screens/blog/blogCategory";
import PostScreen from "../components/screens/blog/post/postScreen";

export default function blogRoutes() {
  return (
    <>
      <Route path="filters" element={<BlogFilters />} />    
      <Route path="post/:id/:title?" element={<PostScreen />} />
    </>
  );
}
