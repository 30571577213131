import React from "react";
import { Link } from "react-router-dom";

export default function ComingSoon() {
  return (
    <div>
      {/* Page Not Found-M */}
      <div className="container-404 text-center d-flex flex-column align-items-center ">
        <img
          className=" img-404 p-0 m-0"
          src="https://marketplace.canva.com/EAFijA-Es8I/1/0/1600w/canva-beige-minimalist-stay-tuned-coming-soon-instagram-post-iv_vQnhdRkY.jpg"
          alt="ComingSoon"
        />
      
          <span>
            
            صفحه ای که به دنبالش هستی به زودی تکمیل میشه و در دسترس قرار خواهد گرفت.
          </span>
          <span>
            
            <i className="bi bi-emoji-happy"></i>
          </span>
          <div
            className="btn primary-btn btn-404 checkout-btn cart-footer mb-5 mt-2"
           onClick={e=>window.history.go(-1)}
           
          >
            بازگشت
          </div>
        
      </div>
    </div>
  );
}
