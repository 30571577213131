import countryStates from "./countryStates";
import Resizer from "react-image-file-resizer";
import moment  from 'moment-timezone';
import renderData from "./renderData";


export default new (class{
    showPrice(amount,currency,lang){
      const la = currency==="IRR"?"fa":"en"
        const cur = currency||'USD'
        // const final = (amount||0).toLocaleString(lang||window.lang,{
        const final = (amount||0).toLocaleString(la,{
          style: 'currency',
          currency:cur,
          minimumFractionDigits: cur==="IRR"?0:2,
          maximumFractionDigits: cur==="IRR"?0:2,
        })
        return final
      }

 compareCheck(arr1, arr2) {      
    let set = new Set(arr1);
    for (let i = 0; i < arr2.length; i++) {
      if (set.has(arr2[i])) {
        return true;
      }
    }
    return false;
  }

  toSolar(date){ 
    if(!date?.length)return
    const fDate= new Date(date)
    const todayFa = {
      "day" : getDateFormat(fDate , {"day" : "2-digit"}),
      "month" : getDateFormat(fDate , {"month" : "numeric"}),
      "monthTitle" : getDateFormat(fDate , {"month" : "long"}),
      "year" : getDateFormat(fDate , {"year" : "numeric"}),
      "dayWeek" : getDateFormat(fDate , {"weekday" : "long"}),
  }
  function getDateFormat(uDate,option){
    let date = new Intl.DateTimeFormat('fa-IR', option).format(uDate);
    return date;
} 
const solarDate = `${todayFa.dayWeek} ${todayFa.day}/${todayFa.monthTitle}/${todayFa.year} `
    return solarDate
  }
  dynamicDate({date,countryShort,lang,singleDate,showYear,short,showYearMonth}){ 
    if(!date || (typeof date !== 'string' && typeof date !== 'object'))return
    // const {countryShort,lang}=this.lcsn(window.location.pathname)
    const finalLan = (lang==="fa"&&countryShort==="ir")?lang:'en'
    const fDate= typeof date === 'object' ?date :new Date(date)    
    const todayFa = {
      "minute" : getDateFormat(fDate , {"minute" : "numeric"}),          
      "hour" : getDateFormat(fDate , {"hour" : "numeric"}),
      "day" : getDateFormat(fDate , {"day" : "2-digit"}),
      "month" : getDateFormat(fDate , {"month" : "numeric"}),
      "monthTitle" : getDateFormat(fDate , {"month" : short?"short":"long"}),
      "year" : getDateFormat(fDate , {"year" : "numeric"}),
      "dayWeek" : getDateFormat(fDate , {"weekday" : "long"}),}
      
  function getDateFormat(uDate,option,la){
     uDate.setHours(uDate.getHours());    
    //  const locale = `${(lang==="fa"&&countryShort==="ir")?lang:'en'}-${countryShort?.toUpperCase()}`;
     const locale = `${finalLan}-${countryShort?.toUpperCase()}`;
    let date = new Intl.DateTimeFormat(locale, option).format(uDate);
    return date;
} 

if(singleDate) return todayFa.day
const solarDate = `${todayFa.day} ${todayFa.monthTitle} ${showYear?todayFa.year:""}`
const gregDate = `${todayFa.monthTitle} ${todayFa.day}, ${showYear?todayFa.year:""}`
const shortDate = `${todayFa.day} ${todayFa.monthTitle} ${showYear?todayFa.year:""}`
const shortDateGreg = `${todayFa.monthTitle} ${todayFa.day}, ${showYear?todayFa.year:""}`
const yearMonth = `${todayFa.monthTitle} ${todayFa.year}`
    return short?(finalLan==="fa"? shortDate:shortDateGreg):
    showYearMonth?yearMonth : finalLan==="fa"?solarDate:gregDate
  }
  dynamicTime({date,countryShort,lang,hour12}){ 
    if(!date || (typeof date !== 'string' && typeof date !== 'object'))return
    // const {countryShort,lang}=this.lcsn(window.location.pathname)
    const fDate= typeof date === 'object' ?date :new Date(date)
    const la = lang.toLowerCase()
    const cs = countryShort.toUpperCase()
    const locale = `${(la==="fa"&&cs==="IR")?la:'en'}-${countryShort.toUpperCase()}`;
    return fDate
    .toLocaleTimeString(
      locale,
      {
        hour12: hour12===true?true:false,
        // timeZone: "America/Toronto",
        hour: "2-digit",
        minute: "2-digit",
      }
    )   
  }

  dynamicNumber({number ,lang}) {
    // console.log('number',number);
    
    if(lang!=="fa"||!number) return number
    const persianDigits = {
        0: '۰',
        1: '۱',
        2: '۲',
        3: '۳',
        4: '۴',
        5: '۵',
        6: '۶',
        7: '۷',
        8: '۸',
        9: '۹'
    };

    const numberString = number.toString();
    let result = '';

    for (let i = 0; i < numberString.length; i++) {
        const digit = parseInt(numberString[i]);
        if (isNaN(digit)) {
            result += numberString[i];
        } else {
            result += persianDigits[digit];
        }
    }

    return result;
}
  

  toGreg(date){ 
    if(!date?.length)return
   
    const fDate= new Date(date)
    const todayFa = {
      "day" : getDateFormat(fDate , {"day" : "2-digit"}),
      "month" : getDateFormat(fDate , {"month" : "numeric"}),
      "monthTitle" : getDateFormat(fDate , {"month" : "short"}),
      "year" : getDateFormat(fDate , {"year" : "numeric"}),
      "dayWeek" : getDateFormat(fDate , {"weekday" : "short"}),      
  }

  function getDateFormat(uDate,option){
    let date = new Intl.DateTimeFormat('en-CA', option).format(uDate);
    
    return date;
} 
const solarDate = `${todayFa.monthTitle} ${todayFa.day}, ${todayFa.year} `
    return solarDate
  }
  

  photoCheck(e){
    const files= e.target.files
    return Array.from(files).every(file=> file.type.startsWith("image/"))
  }
  
  lcsn (pathname) {   
    const convert  = (string)=>string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const [lang, countryShort, stateShort] =pathname.toLowerCase().split("/").slice(1, 4); 
    // const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
    // const country = regionNames.of(countryShort.toUpperCase()).toLowerCase();
    const countryInfo = countryStates.find((count) => count.countryShort.toLowerCase() === countryShort);
   const country = countryInfo?.country?.toLowerCase()
    const stateInfo = countryInfo?.states.find((state) => state?.stateShort.toLowerCase() === stateShort);
    // console.log('country000',country);
    return {lang,country,countryShort, state: convert(stateInfo?.state?.toLowerCase()||''),stateShort,stateInfo,countryInfo };
  }

  linesSpliter(text){  
    const lines = text?.split('\n');
    const paragraphs = lines?.map((line, index) => <div key={index}>{line}</div>)
    return paragraphs
  }
  linesSpliterWithAnchor(text){  
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const lines = text?.split('\n');
    const paragraphs = lines?.map((line, index) => {
      const lineWithLinks = line.split(urlRegex).map((part, index) => {
        if (part.match(urlRegex)) return <a title={part} href={part} key={index} target="_blank" rel="noopener noreferrer">{part}</a>;
        return<div key={index}>{part}</div>
      })
    return lineWithLinks
  })
    return paragraphs
  }
   resizeFile = async ({ file, width, height, blob,format }) =>{
   let output = await new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width || 1200,
      height || 1000,
      format||"WEBP",
      100,
      0,      
      (uri) => {
        resolve(uri);
      },
      blob ? "blob" : "base64"
    );
  });
  console.log("file :",file.size);
  console.log("output 00" ,output.size );
  console.log("file 01" ,file.size <= output.size );
    if((file.size <= output.size) && blob){
      console.log("blob activated01");
      output=file}
    return output  
  }
  prepareForSMS(inputString) {
    // Remove spaces, parentheses, dashes, and any non-alphanumeric characters
const cleanedString = inputString.replace(/[^a-zA-Z0-9]/g, '');

// Remove alphabetical characters
const numericOnlyString = cleanedString.replace(/[a-zA-Z]/g, '');

// Add a plus sign to the beginning
let formattedString = ''
if(numericOnlyString.length>6) formattedString = '+' + numericOnlyString
   
     return formattedString;
   }
   formatPhoneNumber(phoneNumber){
    if(!phoneNumber?.length)return;
    const cleanedNumber = phoneNumber?.replace(/\D/g, ''); // Remove non-numeric characters
    // Determine the length of the country code
    let countryCodeLength = 2; // Assume one digit by default
    if (cleanedNumber?.length >= 2 && cleanedNumber[0] === '1') {
      countryCodeLength = 1; // If the first digit is '1', assume two digits for the country code
    }
      const formattedNumber = `+${cleanedNumber?.slice(0, countryCodeLength)} (${cleanedNumber?.slice(countryCodeLength, countryCodeLength + 3)}) ${cleanedNumber?.slice(countryCodeLength + 3, countryCodeLength + 6)} ${cleanedNumber?.slice(countryCodeLength + 6)}`;
      return formattedNumber;   
  }  

  scrollToAnchor(elId){
    const id = elId||window.location.hash.substring(1)
    if(!id)return
    // console.log('id',id);
    const element = document.getElementById(id)
    // console.log('element',element);
    if(element){element.scrollIntoView({ behavior: 'smooth' })}
  }

convertToUserTimezone({date, time, timeZone}) {
 
  if(typeof time !== 'string'||typeof date !== 'string')return
  const [hours, minutes] = time?.split(':').map(Number);
  const currentDate = new Date(date);
  // console.log('date',date , time)
  const utcOffset = currentDate.getTimezoneOffset() / 60; // Get the UTC offset in hours
  // console.log('utcOffset',utcOffset , )

  currentDate.setHours(currentDate.getHours() +utcOffset);
  // console.log('currentDate',currentDate);
  currentDate.setHours(hours, minutes);

    const options = {
      timeZone: timeZone,
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    };
    return currentDate.toLocaleString('en-US', options);
  }

  generateUtcDate({ date, time, timeZone }){    
    if(typeof time !== 'string')return
    // const targetTimeZone = "Asia/Tehran"
    const currentTimeZone = "America/Toronto" 
    
    const inputDateTimeStr = `${date} ${time}`;
    const inputDateTime = moment.tz(inputDateTimeStr, "YYYY-MM-DD HH:mm", timeZone);

    // Convert to target time zone
    // const targetDateTime = inputDateTime.clone().tz(currentTimeZone);   
    // console.log("generateUtcDate",date,new Date(inputDateTime));
    return new Date(inputDateTime)
  }

  convertToEnglish(Number) {    
    let englishNumber = "";
    const persianToEnglishMap = {
        "۰": "0",
        "۱": "1",
        "۲": "2",
        "۳": "3",
        "۴": "4",
        "۵": "5",
        "۶": "6",
        "۷": "7",
        "۸": "8",
        "۹": "9"
    };

    for (var i = 0; i < Number.length; i++) {
        var char = Number.charAt(i);       
        englishNumber += persianToEnglishMap[char] || char;
    } 
    return englishNumber
}
renderTrans({section, key}){
  return renderData[section]?.find(da=>da.name===key)?.[window.lang]
}
generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink?.match(
    /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
  );
  if (videoIdMatch) {
    const videoId = videoIdMatch[1];
    const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="560"
        id='youtubeIframe'
        height="315"
        src={iframeSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen="allowFullScreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>
    );
  }

  return null; // Return null if video ID couldn't be extracted
}


showYMHistory(dateString) {
  function getElapsedTime(dateString) {
    const startDate = new Date(dateString);
    const currentDate = new Date();
    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();

    // Adjust the years and months if the current month is earlier in the year than the start month
    if (months < 0) {
        years--;
        months += 12;
    }

    return { years, months };
}
  
const elapsedTime = getElapsedTime(dateString);
const { years, months } = elapsedTime;

if (years > 0) {
    return `${years} yr${years>1?"s":""} on Metamarce`;
} else {
    return `${months} Mo on Metamarce`;
}
}

timeAgo(dateString) {
 
  const startDate = new Date(dateString);
  const currentDate = new Date();

  const diffInMs = currentDate - startDate;
  const diffInMinutes = Math.floor(diffInMs / 60000);
  const diffInHours = Math.floor(diffInMs / 3600000);
  const diffInDays = Math.floor(diffInMs / 86400000);
  const diffInMonths = Math.floor(diffInMs / 2592000000); // Roughly 30.44 days per month
  const diffInYears = Math.floor(diffInMs / (2592000000*12)); // Roughly 30.44 days per month
  
  if(window.lang==='fa'){   
  if (diffInMinutes <= 5) {
    return ` همین الان`;
} else if (diffInMinutes < 60) {
      return `${this.dynamicNumber({number:diffInMinutes , lang:window.lang})} دقیقه پیش`;
  } else if (diffInHours < 24) {
      return `${diffInHours} hr پیش`;
  } else if (diffInDays < 30) {
      return `${this.dynamicNumber({number:diffInDays , lang:window.lang})}  روز پیش `;
  } else if(diffInMonths<12) {
      return `${this.dynamicNumber({number:diffInMonths , lang:window.lang})} ماه پیش`;
  }else{
    return `${this.dynamicNumber({number:diffInYears , lang:window.lang})} سال پیش`
  }
 }else{
  if (diffInMinutes <= 5) {
    return `Just now`;
} else if (diffInMinutes < 60) {
      return `${diffInMinutes} min${diffInMinutes>1?"s":""} ago`;
  } else if (diffInHours < 24) {
      return `${diffInHours} hr${diffInHours>1?"s":""} ago`;
  } else if (diffInDays < 30) {
      return `${diffInDays} day${diffInDays>1?"s":""} ago`;
  } else if(diffInMonths<12) {
      return `${diffInMonths} month${diffInMonths>1?"s":""} ago`;
  }else{
    return `${diffInYears} year${diffInYears>1?"s":""} ago`
  }
 }

}




})() 
