import { Route } from "react-router-dom";
import DemyHome from "../components/screens/demy/demyHome";
import DemyPage from "../components/screens/demy/CenterPage";
import ProgramFilters from "../components/screens/demy/filters/programFilters";
import CenterFilters from "../components/screens/demy/filters/centerFilters";
import CenterPage from "../components/screens/demy/CenterPage";
import TutorFilters from "../components/screens/demy/filters/tutorFilters";
import TutorPage from "../components/screens/demy/tutorPage";
import DemyTutorHome from "../components/screens/demy/demyTutorHome";
export default function demyRoutes() {
  return (
    <>
      <Route path="" element={<DemyHome />} />
      <Route path="program/page/:id/:title?" element={<DemyPage />} />
      <Route path="program/filters" element={<ProgramFilters />} />
      <Route path="center/page/:id/:title?" element={<CenterPage />} />      
      <Route path="center/filters" element={<CenterFilters />} />
      <Route path="tutor/page/:id/:title?" element={<TutorPage />} />      
      <Route path="tutor/filters" element={<TutorFilters />} />
      <Route path="tutor/home" element={<DemyTutorHome />} />
    </>
  );
}
