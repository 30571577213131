import { useContext, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { CartContext } from "../contexts/cartContext";
import { Rating } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import Tooltip1 from "../golbal/tooltip/tooltip";
import ToastNote from "../golbal/notifications/toastNote";
import ColoredSpiner from "../alret/spiners/coloredSpiner/coloredSpinner";
import api from "../../utils/api";
import FormikErrRender from "../persian/common/alerts/formikErrRender";
import tools from "../../utils/tools";

export default function CommentsRating({
  id,
  value,
  setData,
  section,
  collection,
}) {
  const messageRef = useRef(null);
  // console.log(props);
  // const { id, value, setData,section } = props;
  const [showMore, setShowMore] = useState(3);
  // console.log('showMore',showMore);
  const [commentDoc, setCommentDoc] = useState(
    value?.feedbackInfo?.comment || {}
  );
  useEffect(() => {
    (() => {
      setCommentDoc(value?.feedbackInfo?.comment);
    })();
  }, [value?.feedbackInfo?.comment]);
  // console.log(value);
  const { user } = useContext(CartContext);
  const [loading, setLoading] = useState({ comment: false });
  const [replyDisplay, setReplyDisplay] = useState(false);
  const [commentId, setCommentId] = useState(false);
  const [repToRepDisplay, setRepToRepDisplay] = useState(false);
  const [repToRep2Display, setRepToRep2Display] = useState(false);
  const [reportDisplay, setReportDisplay] = useState(false);
  const [repReportDisplay, setRepReportDisplay] = useState(false);
  const [repReport2Display, setRepReport2Display] = useState(false);
  const [replyId, setReplyId] = useState("");
  const [reply2Id, setReply2Id] = useState("");
  // const [repliesDisplay, setRepliesDisplay] = useState(false);

  const formik = useFormik({
    initialValues: {
      rate: "0",
      comment: "",
      commentId: "",
      replyText: "",
      replyId: "",
      replyToReplyText: "",
      replyToReply2Text: "",
      replyReportText: "",
      reply2ReportText: "",
      reportText: "",
      reply: "",
      replyToReply: "",
    },
    // onSubmit: async (values,actions) => {
    //   console.log(values);

    // },
    validationSchema: Yup.object({
      rate: Yup.number()
        .min(1, " امتیاز خود را انتخاب کنید.")
        .max(5, "حداکثر 5 را انتخاب کنید"),
      comment: Yup.string().trim().min(5, "توضیحات حداقل باید 3 حرف باشد."),
      replyText: Yup.string()
        .trim()
        .min(3, "توضیحات حداقل باید 3 حرف باشد.")
        .required("در این قسمت میتوانید پاسخ خود را وارد کنید."),
    }),
  });

  function showReplistBtn(e) {
    const reply = document.querySelectorAll(`.${e.currentTarget.id}`);
    Array.from(reply).map((node) => {
      e.currentTarget.checked
        ? (node.style.display = "block")
        : (node.style.display = "none");
    });
  }

  const commentHandler = async (e) => {
    e.preventDefault();
    console.log(e.target);
    if (!id)
      return messageRef.current.showError({
        message: "ثبت دیدگاه امکانپذیر نمی باشد.",
      });
    if (!window.logined(9999)) return;
    
    const repeatedComment = commentDoc?.comments?.find(
      (comment) => comment.userId == user._id
    );
    if ((collection === "Business"||collection === "Tutor"||collection === "Hide") && formik.values.comment.length < 2)
      return messageRef.current.showWarning({
        message: " دیدگاه خود را وارد کنید.",
      });
    if (
      collection === "Business" &&
      repeatedComment &&
      formik.values.comment.length
    )
      return messageRef.current.showError({
        // message: `درخواست به روز رسانی از ${repeatedComment.rate}به ${formik.values.rate} `,
        message: `رای ${formik.values.rate} ستاره شما در گذشته دریافت شده است.`,
      });
    if ((collection === "Business"||collection === "Tutor") && repeatedComment && formik.values.rate)
      return messageRef.current.showError({
        message: `با سپاس، نظر شما در گذشته ثبت شده است.`,
      });
    if (collection === "Business" && formik.values.rate < 1)
      return messageRef.current.showWarning({
        message: " لطفا امتیاز خود را انتخاب کنید.",
      });
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.createComment({
      id,
      rate: formik.values.rate,
      comment: formik.values.comment,
      token: user.token,
      thumbnail: user.thumbnail,
      collection,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    setData(response.data);
    formik.resetForm();
    formik.values.reply = "";
    messageRef.current.showSuccess({ message: " نظر با موفقیت ثبت شد ." });
  };
  const commentLikeHandle = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.commentLikeCounter({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      likeId: e.target.id,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    setCommentDoc(response.data);
    // console.log(response);
  };

  const commentReplyBtn = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setCommentId(e.target.id);
    replyDisplay == false
      ? (function () {
          setReplyDisplay(true);
          setReportDisplay(false);
        })()
      : setReplyDisplay(false);
  };
  const commentReportBtn = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    console.log("commentReportBtn");
    setCommentId(e.target.id);
    console.log(e.target);
    reportDisplay == false
      ? (function () {
          setReportDisplay(true);
          setReplyDisplay(false);
        })()
      : setReportDisplay(false);
  };
  const submitReply = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    // console.log(formik.values.replyText);
    // console.log(e.currentTarget);
    // console.log(commentDoc._id);
    setLoading((pre) => ({ ...pre, comment: true }));

    const response = await api.createReply({
      id: commentDoc._id,
      token: user.token,
      commentId: e.currentTarget.id,
      replyText: formik.values.replyText,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    messageRef.current.showSuccess({ message: " پاسخ با موفقیت ثبت شد ." });
    setReplyDisplay(false);
    setCommentDoc(response.data);
    formik.values.replyText = "";
  };

  const submitReport = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    if (!formik.values.reportText.length)
      return messageRef.current.showError({
        message: "قسمت گزارش نمیتواند خالی باشد.",
      });
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.createCommentReport({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      commentId: e.currentTarget.id,
      reportText: formik.values.reportText,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: "گزارش با موفقیت ثبت شد ." });
    setCommentDoc(response.data);
    setReportDisplay(false);

    formik.values.reportText = "";
  };

  //reply section
  const replyLikeBtn = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const commentId = e.target.id.split(",")[0];
    const replyId = e.target.id.split(",")[1];
    console.log(e.target);
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.replyLikeCounter({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      commentId,
      replyId,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    setCommentDoc(response.data);
  };

  const reply2LikeBtn = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const commentId = e.target.id.split(",")[0];
    const replyId = e.target.id.split(",")[1];
    const reply2Id = e.target.id.split(",")[2];
    console.log(e.target);
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.reply2LikeCounter({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      commentId,
      replyId,
      reply2Id,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    setCommentDoc(response.data);
  };
  const replyReportBtn = (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    console.log(e.target.id);
    setReplyId(e.target.id);
    repReportDisplay == false
      ? (function () {
          setRepReportDisplay(true);
          setRepReport2Display(false);
          setRepToRepDisplay(false);
        })()
      : setRepReportDisplay(false);
  };
  const replyToReplyBtn = (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    console.log(e.target.id);
    setReplyId(e.target.id.split(",")[1]);
    console.log(repToRepDisplay);
    repToRepDisplay == false
      ? (function () {
          setRepReportDisplay(false);
          setRepToRepDisplay(true);
        })()
      : setRepToRepDisplay(false);
  };
  const reply2ReportBtn = (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    // console.log(e.target.id);
    setReply2Id(e.target.id.split(",")[2]);
    repReport2Display == false
      ? (function () {
          setRepReport2Display(true);
          setRepReportDisplay(false);
          setRepToRep2Display(false);
        })()
      : setRepReport2Display(false);
  };
  const replyToReply2Btn = (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setReply2Id(e.target.id.split(",")[2]);
    repToRep2Display == false
      ? (function () {
          setRepToRep2Display(true);
          setRepReport2Display(false);
        })()
      : setRepToRep2Display(false);
  };

  const submitReplyToReply = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const commentId = e.target.id.split(",")[0];
    const replyId = e.target.id.split(",")[1];

    console.log(commentDoc._id, commentId, replyId);
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.createReplyToReply({
      id,
      token: user.token,
      commentDocId: commentDoc._id,
      commentId,
      replyId,
      replyToReplyText: formik.values.replyToReplyText,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    messageRef.current.showSuccess({ message: "پاسخ با موفقیت ثبت شد ." });
    setRepToRepDisplay(false);
    setRepToRep2Display(false);
    console.log(response);
    setCommentDoc(response.data);
  };

  const submitReply2Report = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    console.log("submitReply2Report");

    if (!formik.values.reply2ReportText)
      return messageRef.current.showSuccess({
        message: "گزارش نمی تواند خالی باشد.",
      });

    const commentId = e.currentTarget.id.split(",")[0];
    const replyId = e.currentTarget.id.split(",")[1];
    const reply2Id = e.currentTarget.id.split(",")[2];
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.createReply2Report({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      commentId,
      replyId,
      reply2Id,
      reply2ReportText: formik.values.reply2ReportText,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    setRepReport2Display(false);
    console.log(response);
    formik.values.replyReportText = "";
    setCommentDoc(response.data);
    messageRef.current.showSuccess({ message: " پاسخ با موفقیت ثبت شد ." });
  };
  const submitReplyReport = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    if (!formik.values.replyReportText)
      return messageRef.current.showWarning({
        message: "گزارش نمی تواند خالی باشد.",
      });

    const commentId = e.currentTarget.id.split(",")[0];
    const replyId = e.currentTarget.id.split(",")[1];
    setLoading((pre) => ({ ...pre, comment: true }));
    const response = await api.createReplyReport({
      id,
      commentDocId: commentDoc._id,
      token: user.token,
      commentId,
      replyId,
      replyReportText: formik.values.replyReportText,
      thumbnail: user.thumbnail,
    });
    setLoading((pre) => ({ ...pre, comment: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: " گزارش با موفقیت ثبت شد ." });
    setRepReport2Display(false);
    console.log(response);
    formik.values.replyReportText = "";
    setCommentDoc(response.data);
  };

  function commentReplyIputRender({ comment, reply }) {
    return (
      <div
        className="bg-reply p-2"
        style={{
          display:
            repToRepDisplay === true && reply._id == replyId ? "block" : "none",
        }}
      >
        <div className="d-flex flex-row align-items-start">
          <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy"/>
          <textarea
            className="form-control ms-1 shadow-none textarea"
            id={comment._id}
            // onChange={(e) =>
            //   setReplyToReplyText(e.target.data)
            // }
            {...formik.getFieldProps("replyToReplyText")}
          ></textarea>
        </div>
        <div className="mt-2 d-flex justify-content-end pe-5">
          <button
            className="btn primary-btn checkout-btn btn-sm shadow-none button-comment py-1 px-2 text-end"
            type=""
            onClick={submitReplyToReply}
            disabled={formik.values.replyToReplyText?.length<2}
          >
            <i className="bi bi-send" id={[comment._id, reply._id]}>
              ارسال پاسخ
            </i>
          </button>
        </div>
      </div>
    );
  }
  function commentReply2IputRender({ comment, reply, reply2 }) {
    return (
      <div
        className="bg-reply p-2"
        style={{
          display:
            repToRep2Display == true && reply2._id == reply2Id
              ? "block"
              : "none",
        }}
      >
        <div className="d-flex flex-row align-items-start">
          <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy"/>
          <textarea
            className="form-control ms-1 shadow-none textarea"
            // id={[comment._id,reply._id]}
            // onChange={(e) =>
            //   setReplyToReplyText(e.target.data)
            // }
            {...formik.getFieldProps("replyToReplyText")}
          ></textarea>
        </div>
        <div className="mt-2 d-flex justify-content-end pe-5">
          <button
            className="btn primary-btn checkout-btn btn-sm shadow-none button-comment py-1 px-2 text-end"
            type=""
            onClick={submitReplyToReply}
            disabled={formik.values.replyToReplyText?.length<2}
          >
            <i className="bi bi-send" id={[comment._id, reply._id]}>
             ارسال پاسخ
            </i>
          </button>
        </div>
      </div>
    );
  }
  function ReplyReportInputRender({ comment, reply }) {
    return (
      <div
        className="bg-reply p-2"
        style={{
          display: `${
            repReportDisplay == true && reply._id == replyId ? "block" : "none"
          }`,
        }}
      >
        <div className="d-flex flex-row align-items-start">
          <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy"/>
          <textarea
            className="form-control ms-1 shadow-none textarea"
            id={comment._id}
            {...formik.getFieldProps("replyReportText")}
            placeholder={
              reply.report.find((r) => r.userId == user._id)
                ? reply.report.filter((r) => r.userId == user._id)[
                    reply.report.filter((r) => r.userId == user._id).length - 1
                  ].replyreportText
                : "ٌWrite here ..."
            }
          ></textarea>
        </div>
        <div className="mt-2 d-flex justify-content-end pe-5">
          <button
            className="btn primary-btn view-cart btn-sm shadow-none button-comment py-1 px-2 text-end  "
            type=""
            onClick={submitReplyReport}
            id={[comment._id, reply._id]}
            disabled={formik.values.replyReportText?.length<3}
          >
            <i className="bi bi-send me-1"></i>
            ارسال گزارش
          </button>
        </div>
      </div>
    );
  }
  function ReplyReport2InputRender({ comment, reply, reply2 }) {
    return (
      <div
        className="bg-reply p-2"
        style={{
          display: `${
            repReport2Display == true && reply2._id == reply2Id
              ? "block"
              : "none"
          }`,
        }}
      >
        <div className="d-flex flex-row align-items-start">
          <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy" />
          <textarea
            className="form-control ms-1 shadow-none textarea"
            id={comment._id}
            // onChange={(e) =>
            //   setReply2ReportText(e.target.data)
            // }
            {...formik.getFieldProps("reply2ReportText")}
            placeholder={
              reply2.report?.find((r) => r.userId == user._id)
                ? reply2.report.filter((r) => r.userId == user._id)[
                    reply2.report.filter((r) => r.userId == user._id).length - 1
                  ].reply2reportText
                : "Write here ..."
            }
          ></textarea>
        </div>
        <div className="mt-2 d-flex justify-content-end pe-5">
          <button
            className="btn primary-btn view-cart btn-sm shadow-none button-comment py-1 px-2 text-end  "
            type=""
            onClick={submitReply2Report}
            id={[comment._id, reply._id, reply2._id]}
            disabled={formik.values.reply2ReportText?.length<3}
          >
            <i className="bi bi-send me-1"></i>
            فرستادن گزارش
          </button>
        </div>
      </div>
    );
  }
  function commentRender(comment) {
    const repliesCounter =
      comment?.reply?.length + comment?.reply[0]?.replyToReply?.length || "";

    return (
      <>
        <div className="bg-comment">
          <div className="d-flex flex-row user-info">
            <img
              className="rounded-circle"
              src={comment.userThumbnail}
              width="50"
              height="50"
              alt={comment.userName}
              loading="lazy"
            />
            <div className="d-flex flex-column justify-content-start mt-2 ms-2">
              <span className="  d-block font-weight-bold name">
                {comment.userName}
              </span>
              {(collection === "Business"||collection === "Tutor") && (
                <Rating name="read-only" value={comment.rate} readOnly />
              )}

              <span className="date text-black-50">
                <i className="bi bi-calendar-check"></i>
                <time
                  dateTime="2020-01-01"                  
                >          
                  {tools.dynamicDate({date:comment?.date,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                </time>
              </span>
            </div>
          </div>
          <div className="mt-2">
            <p className="comment-text">{comment.comment}</p>
          </div>
        </div>
        <div className="bg-comment">
          <div className="d-flex flex-row fs-12 ">
            <div className="like p-2 cursor">
              <i
                className={
                  comment.like.some((x) => x.userId == user._id)
                    ? "bi bi-hand-thumbs-up-fill mx-1"
                    : "bi bi-hand-thumbs-up mx-1"
                }
                data={"like"}
                id={comment._id}
                onClick={commentLikeHandle}
              ></i>
              {comment.like.length}

              <span className="ms-1"> </span>
            </div>
            <div className="like p-2 cursor">
              <i
                className={
                  comment.report.some((x) => x.userId == user._id)
                    ? "bi bi-flag-fill"
                    : "bi bi-flag"
                }
                id={comment._id}
                onClick={commentReportBtn}
              ></i>
              <span className="ms-1"></span>
            </div>
            <div className="like p-2 cursor">
              <i
                className={repliesCounter ? "bi bi-reply-fill" : "bi bi-reply"}
                id={comment._id}
                onClick={commentReplyBtn}
              ></i>

              {repliesCounter && (
                <>
                  <label
                    htmlFor={`reply${comment._id}`}
                    className="reply-btn-comments d-flex  align-items-center justify-content-center"
                    id={comment._id}
                  >
                    {repliesCounter}
                    <i className="bi bi-caret-down-fill "></i>
                  </label>
                  <input
                    type="checkbox"
                    id={`reply${comment._id}`}
                    onClick={showReplistBtn}
                    style={{ display: "none" }}
                  />
                  <span className="ms-1"></span>
                </>
              )}
            </div>
          </div>

          <div
            className="bg-reply p-2"
            style={{
              display: `${
                replyDisplay == true && comment._id == commentId
                  ? "block"
                  : "none"
              }`,
            }}
          >
            <div className="d-flex flex-row align-items-start">
              <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy"/>
              <textarea
                className="form-control ms-1 shadow-none textarea"
                id={comment._id}
                // onChange={(e) => setReplyText(e.target.data)}
                {...formik.getFieldProps("replyText")}
              ></textarea>
            </div>
            <FormikErrRender formik={formik} field="replyText" />

            <div className="mt-2 d-flex justify-content-end pe-5">
              <button
                className="btn primary-btn checkout-btn btn-sm shadow-none button-comment py-1 px-2 text-end"
                type=""
                onClick={submitReply}
                id={comment._id}
                disabled={formik.values.replyText?.length<2}
              >
                <i className="bi bi-send me-2" id={comment._id}></i>
                فرستادن پاسخ
              </button>
            </div>
          </div>

          <div
            className="bg-reply p-2"
            style={{
              display:
                reportDisplay == true && comment._id == commentId
                  ? "block"
                  : "none",
            }}
          >
            <div className="d-flex flex-row align-items-start">
              <img className="rounded-circle" src={user.thumbnail} width="40" loading="lazy"/>
              <textarea
                className="form-control ms-1 shadow-none textarea"
                {...formik.getFieldProps("reportText")}
                placeholder={
                  comment.report.some((r) => r.userId === user._id)
                    ? comment.report.filter((r) => r.userId === user._id)[
                        comment.report.filter((r) => r.userId === user._id)
                          .length - 1
                      ].reportText
                    : "Write here ..."
                }
              ></textarea>
            </div>
            <div className="mt-2 d-flex justify-content-end pe-5">
              <button
                className="btn primary-btn view-cart btn-sm shadow-none button-comment py-1 px-2 text-end "
                type=""
                onClick={submitReport}
                id={comment._id}
                disabled={formik.values.reportText?.length<3}
              >
                <i className="bi bi-send me-2"></i>
                گزارش
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  function replyRender({ reply, comment }) {
    return (
      <div className={`reply-comment ms-0`}>
        <div className="bg-comment">
          <div className="d-flex flex-row user-info">
            <img
              className="rounded-circle"
              src={reply.userThumbnail}
              width="50"
              height="50"
              loading="lazy"
            />
            <div className="d-flex flex-column justify-content-start mt-2 ms-2">
              <span className="  d-block font-weight-bold name">
                {reply.userName}
              </span>
              <span className="date text-black-50">
                <i className="bi bi-calendar-check"></i>
                <time
                  dateTime="2020-01-01"
                 
                >
                  
                  {tools.dynamicDate({date:reply.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                </time>
              </span>
            </div>
          </div>
          <div className="mt-2">
            <p className="comment-text">{reply.replyText}</p>
          </div>
        </div>
        <div className="bg-comment">
          <div className="d-flex flex-row fs-12 ">
            <div className="like p-2 cursor">
              <i
                className={
                  reply.like.some((x) => x.userId == user._id)
                    ? "bi bi-hand-thumbs-up-fill mx-1"
                    : "bi bi-hand-thumbs-up mx-1"
                }
                id={[comment._id, reply._id]}
                onClick={replyLikeBtn}
              ></i>
              {reply.like.length}

              <span className="ms-1"> </span>
            </div>
            <div className="like p-2 cursor">
              <i
                className={
                  reply.report.some((x) => x.userId == user._id)
                    ? "bi bi-flag-fill"
                    : "bi bi-flag"
                }
                id={reply._id}
                onClick={replyReportBtn}
              ></i>
              <span className="ms-1"></span>
            </div>
            <div className="like p-2 cursor">
              <i
                className="bi bi-reply"
                id={[comment._id, reply._id]}
                onClick={replyToReplyBtn}
              ></i>
              <span className="ms-1"></span>
            </div>
          </div>
        </div>
        {commentReplyIputRender({ comment, reply })}
        {ReplyReportInputRender({ comment, reply })}
      </div>
    );
  }

  function replyToReplyRender({ reply, reply2, comment }) {
    return (
      <div className="reply-comment ms-5">
        <div className="bg-comment">
          <div className="d-flex flex-row user-info">
            <img
              className="rounded-circle"
              src={reply2.userThumbnail}
              width="50"
              height="50"
              loading="lazy"
            />
            <div className="d-flex flex-column justify-content-start mt-2 ms-2">
              <span className=" d-block font-weight-bold name">
                {reply2.userName}
              </span>
              <span className="date text-black-50">
                <i className="bi bi-calendar-check"></i>
                <time
                  dateTime="2020-01-01"
         
                >
                  
                  {tools.dynamicDate({date:reply2.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                </time>
              </span>
            </div>
          </div>
          <div className="mt-2">
            <p className="comment-text">{reply2.replyToReplyText}</p>
          </div>
        </div>
        <div className="bg-comment">
          <div className="d-flex flex-row fs-12 ">
            <div className="like p-2 cursor">
              <i
                className={
                  reply2.like?.some((x) => x.userId == user._id)
                    ? "bi bi-hand-thumbs-up-fill mx-5"
                    : "bi bi-hand-thumbs-up mx-1"
                }
                id={[comment._id, reply._id, reply2._id]}
                onClick={reply2LikeBtn}
              ></i>
              {reply2?.like?.length}

              <span className="ms-1"> </span>
            </div>
            <div className="like p-2 cursor">
              <i
                className={
                  reply2.report?.find((x) => x.userId == user._id)
                    ? "bi bi-flag-fill"
                    : "bi bi-flag"
                }
                id={[comment._id, reply._id, reply2._id]}
                onClick={reply2ReportBtn}
              ></i>
              <span className="ms-1"></span>
            </div>
            <div className="like p-2 cursor">
              <i
                className="bi bi-reply"
                id={[comment._id, reply._id, reply2._id]}
                onClick={replyToReply2Btn}
              ></i>
              <span className="ms-1"></span>
            </div>
          </div>
        </div>
        {commentReply2IputRender({ comment, reply, reply2 })}
        {ReplyReport2InputRender({ comment, reply, reply2 })}
      </div>
    );
  }
  // console.log("formik.errors",formik.errors)
  return (
    <div id="comments2" className="blog-comments my-5">
      <ToastNote messageRef={messageRef} />
      {loading.comment && <ColoredSpiner />}
      <div className="reply-form">
      {(collection !== "Tutor"||(collection === "Tutor"&&value?.moreInfo?.stList?.includes(user?._id)))&&<div>
        <div className="  mb-3  mt-0 comments-title">
           <i className="bi bi-pencil-fill"></i>
          <span className="p-0 mb-0 mx-2 h5">
          دیدگاه شما
            {/* {collection === "Business" && (
              <Tooltip1
                message="ارائه دیدگاه به همراه امتیاز می تواند به این کسب و کار در جهت بهبود کیفیت خود کمک کند."
                position="left"
              />
            )} */}
          </span>
          {/* <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"/> */}
        </div>
          

        
        {(collection === "Business"||collection === "Tutor") && (
          <Rating
            dir="ltr"
            name="simple-controlled"
            data={formik.values.rate}
            {...formik.getFieldProps("rate")}
          />
        )}
        <FormikErrRender formik={formik} field="rate" />

        <div className="row">
          <div className="col form-group main-comment">
            <textarea
              name="comment"
              className="form-control"
              placeholder="Write your review*"
              {...formik.getFieldProps("comment")}
            ></textarea>
            <div className="mt-2  d-flex justify-content-end pe-5">
              <button
                className="btn primary-btn checkout-btn btn-sm shadow-none button-comment py-1 px-2 text-end fw-bold"
                type=""
                onClick={commentHandler}
                id={id}
                disabled={formik.values.comment?.length<2}
              >
                <i className="bi bi-send me-2 "></i>
                بفرست
              </button>
            </div>
          </div>
        </div>
        </div>}
        <div className="container pt-0 commentContainer">
          <div className="d-flex justify-content-center row">
            <div className="col">
              <div className="d-flex flex-column comment-section">
                {/* Comment Section */}

                {commentDoc?.comments?.length <= 0 ? (
                  <div>بدون دیدگاه</div>
                ) : (
                  commentDoc?.comments
                    ?.slice(0, showMore)
                    // ?.filter((comment) => comment.comment.length)
                    ?.map((comment, i) => {
                      return (
                        <div key={i}>
                          {commentRender(comment)}
                          {comment.reply.map((reply, i) => (
                            <div
                              key={i}
                              className={`reply${comment._id}`}
                              style={{ display: "none" }}
                            >
                              {replyRender({ reply, comment })}
                              {reply.replyToReply.map((reply2) =>
                                replyToReplyRender({ comment, reply, reply2 })
                              )}
                            </div>
                          ))}
                        </div>
                      );
                    })
                )}
                {commentDoc?.comments?.length > 3 && (
                  <div
                    className="read-more btn box5 mx-0 text-center ms-auto my-2 small-gray-font"
                    onClick={showHandle}
                  >
                    {showMore >= commentDoc?.comments?.length
                      ? "End"
                      : ` ادامه (${
                          commentDoc?.comments?.length - showMore
                        }دیدگاه‌ها)`}
                  </div>
                )}
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function showHandle(e) {
    const step = 5;
    const target = e.target;
    if (commentDoc?.comments.length <= showMore) setShowMore(3);

    if (commentDoc?.comments.length > showMore)
      return setShowMore((pre) => (pre += step));
  }
}
