// import "./saved.css"

import { Outlet } from "react-router-dom"

import UserDashAdsNav from "../../../components/dashNavbar/userDashAdsNav"

export default function UserAdsOut() {
    
  return (
    <div className="savedItems">
        
        <UserDashAdsNav/> 
      
        <Outlet/>
    </div>
  )
}
