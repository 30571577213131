// import "./saved.css"

import { Outlet } from "react-router-dom"

// import { useContext } from "react"
// import { CartContext } from "../../../../../contexts/cartContext"

// import UserDashTransNav from '../../../components/dashNavbar/userDashTransNav'
// import UserDashNavbar from "../../../components/dashNavbar/userDashNavbar"
import StoreDashTransNav from "../../components/dashNavbar/storeDashTransNav"

export default function StoreTransOut() {
  
    
  return (
    <div className="savedItems">
        <StoreDashTransNav/>          
        <Outlet/>
    </div>
  )
}
