import { useNavigate } from "react-router-dom";
import tools from "../../../../utils/tools";

export default function NewPosts2x2({ newPosts }) {
    // console.log(newPosts);
    const navigate = useNavigate()
    function redirectHandle  (data) {
      window.scroll(0,0);      
      navigate(`/${window.lcs}/blog/post/${data._id}`, {
        state: { data, newPosts },
      });
    };
    return (
      <>
        {newPosts?.slice(0, 4)?.map((data, i) => (
          <div  key={i} className="col card2x2 ">
<div className="card mb-3 mx-auto h-100" style={{maxWidth:" 540px", cursor:"pointer"}}  onClick={(e) => redirectHandle(data)}>
  
  <div className="row g-0 ">
    <div className="col-4">
      <div className="image-container">
         <img
                    src={data.img}
                    className="img-fluid rounded-start w-100 h-100  img-card-h "
                    alt="publish date"
                    loading="lazy"
                  />
                  </div>
   
      {/* <img src="..." className="img-fluid rounded-start" alt="..."/> */}
    </div>
    <div  className={` col-8  small-card${i + 1}`}>
     
      <div className="card-body d-flex flex-column h-100 ">
                    <h5 className="card-title font-18">
                      {data.title.substring(0, 200)}
                    </h5>
                    <p className="card-text">
                      {/* {data.title.substring(30,200)} */}
                    </p>
                    <p className="card-text mt-auto">
                      <small
                        className="text-muted"
                       
                      >
                        تاریخ انتشار : 
                      
                    {tools.dynamicDate({date:data?.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                      </small>
                    </p>
                   </div>
    </div>
  </div>
</div>


          
          </div>
        ))}
      </>
    );
  }