import React, { useContext } from "react";
import { CartContext } from "../../../../../../contexts/cartContext";
import FeaturedInfo from "../../../../components/featuredInfo/featuredInfo";
import Chart from "../../../../components/chart/chart";
import WidgetSm from "../../../../components/widgetLg/widgetSm";
import WidgetLg from "../../../../components/widgetSm/widgetLg";
import { userData } from "../../../../dummyData";

export default function MyTransMain() {
  const { user } = useContext(CartContext);
  console.log(user);

  return (
    <div>
      <FeaturedInfo user={user} />
      <Chart
        data={userData}
        title="On User Analytics"
        grid="data"
        dataKey="Active User"
      />
      <div className="homeWidgets">
        <WidgetSm />
        <WidgetLg />
      </div>
    </div>
  );
}
