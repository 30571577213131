import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import { CheckCircle, Clear, Error, WarningAmber } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpiner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/apiDemy";
import MetaDecorator from "../../../../utils/metaDecorator";
import BlogSearch from "../../../persian/common/pagination/searchPagination";
import demyData from "../../../../utils/demyData";
import AddLinks from "../../../golbal/addLinks";
import apiDemy from "../../../../utils/apiDemy";

export default function ProgramRegister({ action }) {
  const [lang, countryShort, stateShort] = window.location.pathname
    .toLowerCase()
    .split("/")
    .slice(1, 4);
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const [data, setData] = useState([]);
  // const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      action,
      lcs: window.lcs,
      lang,
      centerId: "",
      center: "",
      programLevel: "",
      studyArea: "",
      img: "",
      _id: "",
      links: [{ section: "", urls: [{ name: "", url: "" }] }],
      email: "",
      distance: false,
      coop: false,
      languages: ["en"],
    },
    onSubmit: async (values) => {
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiDemy.registerProgram({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "Congraulattions",
      });

      window.scrollTo({ top: "0", behavior: "smooth" });

      navigate(`/${window.lcs}/demy/page/${response.data._id}`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      terms: Yup.boolean().oneOf(
        [true],
        " قوانین را مطالعه و دکمه قبول را فشار دهید."
      ),
      center: Yup.string()
        .required("لطفاً نام مرکز را وارد کنید.")
        .max(200, "نام مرکز حداکثر باید 200 حرف باشد .")
        .min(5, "نام مرکز حداقل باید 5 حرف باشد."),
      programLevel: Yup.string()
        .required("لطفاً نام مرکز را وارد کنید.")
        .max(200, "نام مرکز حداکثر باید 200 حرف باشد .")
        .min(5, "نام مرکز حداقل باید 5 حرف باشد."),
      studyArea: Yup.string()
        .required("لطفاً نام مرکز را وارد کنید.")
        .max(200, "نام مرکز حداکثر باید 200 حرف باشد .")
        .min(5, "نام مرکز حداقل باید 5 حرف باشد."),
      description: Yup.string()
        .notRequired()
        .max(2000, "وظایف شغلی حداکثر باید 2000 حرف باشد ."),
      email: Yup.string()
        .trim()
        .lowercase()
        .required("ایمیل را وارد کنید.")
        .email("ایمیل را به درستی وارد کنید."),
      languages: Yup.array()
        .required(" دست کم یک زبان را انتخاب کنید.")
        .test(
          "array-length",
          " دست کم یک زبان را انتخاب کنید.",
          (value) => value.length > 0
        ),
    }),
  });

  useEffect(() => {
    if (!id) {
      fetchListData();
    } else {
      fetchDemyDetails();
    }
  }, []);

  async function fetchListData(key) {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.fetchCenterList({
      token: user.token,
      id: user._id,
      key,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
    if (!id) {
      formik.setValues((pre) => {
        const data = response.data[0];
        const pre2 = { ...pre };
        pre2.center = data?.center;
        pre2.img = data?.moreInfo?.img;
        pre2.centerId = data?._id;
        return pre2;
      });
    }
  }
  async function fetchDemyDetails() {
    console.log("id:", id);
    if (!id) return;
    const response = await apiDemy.fetchProgramDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  console.log("formik", formik.values);
  // console.log("formik", formik.errors);
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "ثبت رایگان آگهی استخدام",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs`,
              name: `کاریابی`,
              description:
                "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs/register`,
              name: "ثبت رایگان آگهی استخدام",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "Study Program Registration",
    description: " Register ",
    section: "Study program register",
    url: `https://www.metamarce.com/${window.lcs}/jobs/register`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/jobs/register`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "program",
  };
  async function searchHandle(e) {
    e.preventDefault();
    const value = e.target.parentElement.querySelector("input").value;
    console.log("value", value);
    if (e.key === "Enter" || e.type === "click") {
      fetchListData(value);
    }
  }

  return (
    <div className="user  mt-3  px-0">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <div>
        <p className="divider2 border-0 mt-3 ">
           <MyIcons icon="dotsTitr" />
          <span className="p-0 m-z ">Center selection</span>
        </p>
        <div className="userShow py-5 px-md-5">
          <div className="  mx-md-5">
            <div id="existingBus">
              <BlogSearch searchHandle={searchHandle} />

              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => existBusinesses({ e, user })}
              >
                <div className="d-flex">
                  <p className="text-center m-0 p-0">
                    {formik?.values?.center.length
                      ? `Selected Center (more ${data?.length})`
                      : "Choose a center"}
                  </p>
                  <div className="mx-md-5 titr5">
                    {formik.values.center.length > 0 &&
                      `"${formik.values.center}"`}
                  </div>
                  <div className="ms-auto">
                    <img
                      className="jobCrad-img"
                      src={formik.values.img}
                      alt={formik.values.center}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            {data.length > 0 && (
              <div
                id="existingBusShowHide"
                className="BusRegList p-1 mx-auto w-100 ads-all"
                style={{ display: "none" }}
              >
                <span
                  title="بستن"
                  onClick={(e) => {
                    document.querySelector(
                      "#existingBusShowHide"
                    ).style.display = "none";
                  }}
                >
                  <Clear sx={{ color: "red" }} />
                </span>
                <BlogSearch searchHandle={searchHandle} />
                {data.map((da) => (
                  <ExistCenterRender data={da} formik={formik} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="row ads-all mx-auto my-5 px-mx-5"
        name="jobRegForm00"
        autoComplete="true"
      >
        <div>
          <p className="divider2 border-0 mt-3 ">
               <MyIcons icon="dotsTitr" />
            <span className="p-0 m-z ">Program details</span>
          </p>

          <div className="userShow px-5">
            <div className="row">
              <div className="col px-3">
                <div className="mb-5 ">
                  <div className="form-label mt-3 titr6 mb-0  required">
                    {" "}
                    PROGRAM LEVEL{" "}
                  </div>
                  <select
                    className="login-btn my-2 cursor"
                    name="salaryCurrency"
                    id=""
                    {...formik.getFieldProps("programLevel")}
                  >
                    <option>Please Select</option>
                    {demyData.programLevel.map((level) => (
                      <option value={level}>{level}</option>
                    ))}
                  </select>
                  <FormikErrRender formik={formik} field="programLevel" />
                </div>
                <div className="mb-5 ">
                  <div className="form-label mt-3 titr6 mb-0  required">
                    {" "}
                    PROGRAM Field{" "}
                  </div>
                  <select
                    className="login-btn my-2 cursor"
                    name="salaryCurrency"
                    id=""
                    {...formik.getFieldProps("studyArea")}
                  >
                    <option>Please Select</option>
                    {demyData.studyArea.map((fi) => (
                      <option value={fi}>{fi}</option>
                    ))}
                  </select>
                  <FormikErrRender formik={formik} field="studyArea" />
                </div>
                <div className="mb-5 ">
                  <label
                    className="form-label mt-3 titr6 mb-0  required"
                    htmlFor="distance"
                  >
                    {" "}
                    DISTANCE ED{" "}
                  </label>
                  <input
                    id="distance"
                    type="checkbox"
                    className="form-check-input px-1"
                    checked={formik.values.distance}
                    {...formik.getFieldProps("distance")}
                  />
                  <FormikErrRender formik={formik} field="distance" />
                </div>

                <div className="mb-5 ">
                  <label
                    className="form-label mt-3 titr6 mb-0  required"
                    htmlFor="coop"
                  >
                    {" "}
                    COOP{" "}
                  </label>
                  <input
                    id="coop"
                    type="checkbox"
                    className="form-check-input px-1"
                    checked={formik.values.coop}
                    {...formik.getFieldProps("coop")}
                  />
                  <FormikErrRender formik={formik} field="coop" />
                </div>
                <div>
                  <label
                    className="form-label mt-5 titr6 mb-0 required"
                    htmlFor="emailApply"
                  >
                    Email
                  </label>
                  <input
                    dir="ltr"
                    id="emailApply"
                    {...formik.getFieldProps("email")}
                    className={`userUpdateInput  form-control box2 ${addErrClass(
                      {
                        formik,
                        field: "email",
                      }
                    )}`}
                    type="text"
                    autoComplete="off"
                    placeholder={formik.values.email}
                  />
                  <FormikErrRender formik={formik} field="email" />
                </div>

                <div className="mb-5">
                  <label className="form-label titr6">Description</label>
                  <textarea
                    className={`form-control ${addErrClass({
                      formik,
                      field: "description",
                    })}`}
                    rows="3"
                    name="description"
                    defaultValue={formik.values.description}
                    onBlur={(e) =>
                      formik.setValues((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  ></textarea>
                  <FormikErrRender formik={formik} field="description" />
                </div>

                <div className="mb-5">
                  <div className="form-label titr6">Important Links</div>
                  <AddLinks formik={formik} />
                </div>

                <div className=" p-2 mt-5">
                  <p className="form-label titr6"> Course Language (s)</p>
                  <div className="form-check ">
                    <div
                      className={` form-font row row-cols-3 row-cols-md-4 row-cols-lg-5 g-2 ${addErrClass(
                        { formik, field: "languages" }
                      )} `}
                    >
                      {renderData.languages.map((lang, i) =>
                        !lang[window.lang].length ? (
                          ""
                        ) : (
                          <div key={i} className="morelang-line my-0 ">
                            <input
                              className="form-check-input  ms-5 me-1 red moreLang"
                              type="checkbox"
                              value={lang.name}
                              checked={formik.values.languages?.includes(
                                lang.name
                              )}
                              id={`flexLanguages${i}`}
                              onChange={(e) => {
                                const languages =
                                  [...formik.values.languages] || [];
                                const index = languages.indexOf(lang.name);
                                if (index !== -1) {
                                  languages.splice(index, 1);
                                } else {
                                  languages.push(e.target.value);
                                }

                                formik.setValues((pre) => ({
                                  ...pre,
                                  languages,
                                }));
                              }}
                            />
                            <label
                              className="form-check-label ps-2 red moreLang"
                              htmlFor={`flexLanguages${i}`}
                            >
                              {lang[window.lang]}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                    <button
                      className="btn btn-click ms-5 mt-2 mb-5"
                      type="button"
                    >
                      <label htmlFor="moreLangCheckbox"> بیشتر... </label>
                      {/* <span>
                              <i className="bi bi-three-dots ps-2 py-0 my-0"></i>
                            </span> */}
                      <input
                        id="moreLangCheckbox"
                        type="checkbox"
                        onClick={(e) => {
                          const elemet = document.querySelectorAll(".moreLang");
                          if (e.target.checked) {
                            document.querySelector(
                              "label[for=moreLangCheckbox]"
                            ).innerHTML = "کمتر...";
                            Array.from(elemet).map(
                              (el) => (el.style.display = "inline-block")
                            );
                          } else {
                            document.querySelector(
                              "label[for=moreLangCheckbox]"
                            ).innerHTML = "بیشتر...";
                            Array.from(elemet).map(
                              (el) => (el.style.display = "none")
                            );
                          }
                        }}
                      />
                    </button>
                    <FormikErrRender formik={formik} field="languages" />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
        <SubmitBtn formik={formik} text="" />
      </form>

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }
}
async function existBusinesses({ e, user }) {
  e.preventDefault();
  if (!user.roles?.includes(9999))
    return document.querySelector("#loginTrigger").click();
  const showHide = document.querySelector("#existingBusShowHide");

  if (showHide !== null) {
    showHide.style.display =
      showHide.style.display === "none" ? "block" : "none";
  }
  document.querySelector("#existItemNotAvail")?.remove();
}

function ExistCenterRender({ data, formik }) {
  return (
    <div
      key={data?._id}
      className="m-1 p-3 bs-1 d-flex justify-content-between w-100"
    >
      <div className="d-flex ad-select">
        <input
          type="radio"
          id={`ad-select-bus${data?._id}`}
          name="busRadio"
          style={{ display: "none" }}
          defaultChecked={data._id === formik.values.centerId}
          onChange={(e) => {
            const showHide = (document.querySelector(
              "#existingBusShowHide"
            ).style.display = "none");

            formik.setValues((pre) => {
              const pre2 = { ...pre };
              pre2.center = data?.center || "";
              pre2.img = data?.moreInfo?.img;
              pre2.centerId = data?._id;
              return pre2;
            });
          }}
        />
        <label
          htmlFor={`ad-select-bus${data?._id}`}
          className="d-flex justify-content-between"
        >
          <img
            height="40px"
            width="60px"
            src={data?.moreInfo?.img}
            alt={data?.center}
            loading="lazy"
          />
          <span className="m-1 p-2">{data?.center}</span>
          <span className="m-1 p-2">ایمیل : {data?.email}</span>
          <div className="m-1 p-2" dir="ltr">
            <i className="bi bi bi-geo-alt mx-2"></i>
            {`${window.capitalize(data?.moreInfo?.address?.city)},
              ${window.capitalize(data?.moreInfo?.address?.state)},
              ${window.capitalize(data?.moreInfo?.address?.countryShort)}
              `}
          </div>
        </label>
      </div>
      <Link
        className="d-flex justify-content-around"
        to={`/${window.lcs}/demy/page/${data?._id}`}
      >
        <button className="btn primary-btn delete-btn"> دیدن صفحه </button>
      </Link>
    </div>
  );
}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    centerId: data.moreInfo?.centerId,
    img: data.moreInfo.img,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    email: data.email,

    description: data.moreInfo.description,
    centerId: data.moreInfo?.centerId,
    center: data.moreInfo?.center,
    img: data.moreInfo?.img,
    programLevel: data.moreInfo?.programLevel,
    studyArea: data.moreInfo?.studyArea,
    distance: data.moreInfo?.distance,
    coop: data.moreInfo?.coop,
    links: data.moreInfo?.links,
    status: data.moreInfo?.status,
    languages: data.moreInfo?.languages,
  };
  formik.setValues(initialValues);
  if (document.querySelector("#termsContent"))
    document.querySelector("#termsContent").style.display = data.moreInfo?.terms
      ? "none"
      : "block";
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await api.editProgram({ token: user.token, data: values });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });
  messageRef.current.showSuccess({
    message: "دوره آموزشی با موفقیت ویرایش شد.",
  });
  // navigate(`/${window.lcs}/demy/page/${response.data._id}`, {
  //   state: response.data,
  // });
}
