import { Route } from "react-router-dom";
import JobsHome from "../components/screens/jobs/jobsHome";
import JobsRegister from "../components/screens/jobs/register/jobsRegister";
import JobPage from "../components/screens/jobs/jobPage";
import JobsFilters from "../components/screens/jobs/filters/jobsFilters";
import ReadyPage from "../components/screens/jobs/readyPage";
import ReadyFilters from "../components/screens/jobs/filters/readyFilters";
// import HideFilters from "../components/screens/jobs/filters/readyFilters";
import HidePage from "../components/screens/jobs/hidePage";
import HideFilters from "../components/screens/jobs/filters/hideFilters";
// import HideFilters from "../components/screens/jobs/filters/hideFilters1";
export default function jobsRoutes() {
  return (
    <>
      <Route path="" element={<JobsHome />} />
      <Route path="register" element={<JobsRegister />} />
      <Route path="register/:id" element={<JobsRegister />} />
      <Route path="page/:id/:title?" element={<JobPage />} />
      <Route path="filters" element={<JobsFilters />}/>
      <Route path="job/filters" element={<JobsFilters />}/>
      <Route path="ready/filters" element={<ReadyFilters/>}/>
      <Route path="ready/page/:id" element={<ReadyPage />} />
      <Route path="hide/filters" element={<HideFilters/>}/>
      <Route path="hide/page/:id" element={<HidePage />} />
    </>
  );
}
