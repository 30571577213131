
import {Route } from "react-router-dom";
import Cart from "../components/chechout/cart";
import DisplayContent from "../components/screens/displayContent/displayContent";
import ProductScreen from "../components/screens/productScreen/productScreen";
import AdsLocator from "../components/screens/dashboard/pages/profile/ads/pages/adsLocator";
import Thanks from "../components/chechout/thanks";


export default function StoreRoutes() { 

  return (
    <>
      <Route path="" element={<DisplayContent />} /> 
      {/* <Route path="product/:id" element={<ProductScreen />} />  */}
      {/* <Route path="placeorder"element={productItems.lenght !== 0 ? <PlaceOrder /> : <Navigate to="/" />}/> */}
      {/* <Route path="shipping" element={user?.roles?.includes(9999) ? <ShippingAddressScreen /> : <Navigate to="/login" />}/> */}
      <Route path="cart" element={<Cart/>} /> 
      <Route path="order/thanks" element={<Thanks />} />
      <Route path="order/:id" element={<Cart />} />
      {/* <Route path='payment' element={ shipTo.address ?  <Payment/> : <Navigate to="/shipping"/> }/> */}
      <Route path="plans" element={<AdsLocator />} />
      

    </>
  );
}
