import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

export default function StoreOut() {
  const [pubData] = useOutletContext();
  return (
    <div>     
    <Outlet context={[pubData]}/>
      {/* <!-- ********** back to top************************* --> */}
  <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  )
}
