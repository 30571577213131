import { useContext, useRef, useState } from "react";
import { CartContext } from "../../../contexts/cartContext";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
import api from "../../../../utils/api";
import ToastNote from "../../../golbal/notifications/toastNote";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function ContactUs() {
  const messageRef = useRef(null);
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(CartContext);
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "تماس باما",
      description: "منتظر شنیدن دیدگاه‌های شما هستیم!",
      image:
        "https://www.metamarce.com/img/illustration/contact-us-animate.svg",
      url: "https://www.metamarce.com/fa/ca/qc/prompt/contact",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/prompt/contact`,
              name: `تماس با ما`,
              description: "منتظر شنیدن دیدگاه‌های شما هستیم!",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "تماس با متامارس",
    description: "راه های ارتباطی با متامارس | گزارش مشکل",
    section: "تماس با ما",
    url: `https://www.metamarce.com/${window.lcs}/prompt/contact`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/prompt/contact`,
    img: "https://www.metamarce.com/img/illustration/contact-us-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      {loading && <ColoredSpinner />}
      <ToastNote messageRef={messageRef} />

      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>

        <div className=" p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
            تماس با ما
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start"
            style={{ color: "#60412b" }}
          >
            {/* راهنمای سوشیال مدیای متامارس */}
            منتظر شنیدن دیدگاه‌های شما هستیم!
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
        />
      </div>

      <div className="px-5">
        <div className="row">
          <div className="col-md-6">
            <img
              src="/img/illustration/contact-us-animate.svg"
              className=" mb-3 px-3 w-100 "
              alt="homePage"
            />
          </div>
          <div className="col-md-6 text-center">
            <form
              action=""
              className="w-100 "
              name="homeForm"
              autoComplete="true"
            >
              <div>
                <h2 className="mt-5 titr7 text-center">
                  آیا چیزی برای گزارش دارید؟
                </h2>
                <p>برای مکاتبه یا پرسش از متامارس، این‌جا برای ما بنویسید</p>
                <textarea
                  id="f25"
                  className="form-control shadow-none my-2"
                  rows="3"
                  placeholder=""
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
              </div>

              <button
                type="button"
                className="btn primary-btn checkout-btn cart-footer mx-auto w-50 mt-3"
                onClick={sendRequestHandle}
              >
                <i className="bi bi-pencil-square p-1"></i>
                بفرست!
              </button>
            </form>

            {/* <div className="titr7 mt-5 mb-2"> دیگر راه‌های تماس با ما</div> */}

            <div className="mt-5 mb-2">
              <span>
                <img
                  src="/img/bussiness-page/email.png"
                  className="arrow2 pe-2 py-1"
                  alt="more info"
                />
              </span>
              <span className="p-0 mb-0 titr7 titr7-5 ">ایمیل متامارس</span>
            </div>
            <a href="mailto:metamarce.com@gmail.com">metamarce.com@gmail.com</a>
            {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
            <div className="mt-4 mb-2">
              <span>
                <img
                  src="/img/icons/mobile-message.png"
                  className="arrow2 pe-2 py-1"
                  alt="more info"
                />
              </span>
              <span className="p-0 mb-0 titr7 titr7-5">
                {" "}
               تلفن متامارس
              </span>
            </div>
            <div dir="ltr">+1 (514) 600 4252  <span className="small-gray-font">(SMS)</span></div>
            <div className="mt-4 mb-2">
              <span>
                <img
                  src="/img/bussiness-page/video-chat.png"
                  className="arrow2 pe-2 py-1"
                  alt="more info"
                />
              </span>
              <span className="p-0 mb-0 titr7 titr7-5">
                {" "}
                شبکه‌های اجتماعی متامارس
              </span>
            </div>
            <div
              className="d-flex justify-content-around align-items-center mt-0 mb-5  "
              id="mr"
            >
              <ul className="d-flex justify-content-center align-items-center m-0 p-2 flex-wrap w-100">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61555192350817&mibextid=LQQJ4d"
                    target="_blank"
                    aria-label="Explore more about metamarce on facebook"
                  >
                    <i className="bi bi-facebook icon"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2FMetamarceCom"
                    target="_blank"
                    aria-label="Explore more about metamarce on twitter"
                  >
                    <i className="bi bi-twitter icon"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/joinchat/BfW__kawWflm6_tY_fwH8w"
                    target="_blank"
                    aria-label="Explore more about metamarce on telegram"
                  >
                    <i className="bi bi-telegram icon"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.metamarce.com"
                    target="_blank"
                    aria-label="Explore more about metamarce on google"
                  >
                    <i className="bi bi-google icon"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCpTidDaYOLb8IggNIQiUZUA"
                    target="_blank"
                    aria-label="Explore more about metamarce on youtube"
                  >
                    <i className="bi bi-youtube icon"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr"
                    target="_blank"
                    aria-label="Explore more about metamarce on instagram"
                  >
                    <i className="bi bi-instagram icon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    if (question.length < 20)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (question.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    e.target.setAttribute("disabled", true);
    setLoading(true);
    const response = await api.askq({
      token: user.token,
      data: question,
      lang: window.lang,
    });
    setLoading(false);
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: "پرسش شما با موفقیت فرستاده شد. ",
    });
    document.querySelector("textarea").value = "";
    setQuestion("");
  }
}
