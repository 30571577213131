import { Component } from "react";
import { Link } from "react-router-dom";
import MyIcons from "../../golbal/icons/myIcons";
class ItemCard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    qty: this.props.product.orderCount,
    totalPrice:
      Math.floor(
        this.props.product.orderCount * this.props.product.price * 100
      ) / 100,
  };
  addToCart = (Quantity) => {
    const addedProduct = { ...this.props.product };
    addedProduct.orderCount = parseInt(Quantity);
    this.props.increment(addedProduct);
    this.setState({
      totalPrice:
        Math.floor(parseInt(Quantity) * this.props.product.price * 100) / 100,
    });
  };
  deleteBtn = () => {
    this.props.decrementItem(this.props.product._id);
    console.log("delete");
  };

  render() {
    return (
      <div className="select-items">
        <table>
          <tbody>
            <tr>
              <td className="si-pic">
                <Link to={`/product/${this.props.product._id}`}>
                  <img
                    src={this.props.product.img}
                    alt={this.props.product.name}
                    loading="lazy"
                  />
                </Link>
              </td>
              <td className="si-text w-100">
                <div className="product-selected">
                  <h6 className="text-center">{this.props.product.name}</h6>
                  <p className="text-end pe-3" dir="ltr">
                    <select
                      className="store-select-btn"
                      value={this.state.qty}
                      onChange={(e) => {
                        this.setState({ qty: e.target.value });
                        this.addToCart(e.target.value);
                      }}
                      name="stateSelect"
                      id=""
                    >
                      {[...Array(this.props.product.qty).keys()].map((x, i) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </select>{" "}
                    x {this.props.product.price} = ${this.state.totalPrice}
                  </p>
                </div>
              </td>
              <td className="si-close" onClick={this.deleteBtn}>
                <MyIcons icon="delete2" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ItemCard;
