import { Link, useNavigate } from 'react-router-dom'
import SocialMedias from '../../business/components/socialMedias'
import tools from '../../../../utils/tools'
export default function AuthorBio({post}) {
  const navigate = useNavigate();
  function navigateHandle(url,state){
    window.scroll(0,0)
    setTimeout(e=>navigate(url),200)
  }
   
  return (
    <div className="blog-author d-flex align-items-center">
              <div
              onClick={e=>navigateHandle(`/${window.lcs}/blog/filters?authorId=${post?.author?._id}&title=${post?.author?.authorName}`)}
              title={`همه نوشته های${post?.author?.authorName} در متامارس`}>
              <img
                src={post?.author?.thumbnail}
                className="rounded-circle float-left mx-3 round cursor"
                alt={post?.author?.authorName}
                loading="lazy"
              />
              </div>
              <div>
              <div
              className='cursor'
              title={`همه نوشته های${post?.author?.authorName} در متامارس`}
              onClick={e=>navigateHandle(`/${window.lcs}/blog/filters?authorId=${post?.author?._id}&title=${post?.author?.authorName}`)}>           
                <h5>{post?.author?.authorName}</h5>
                </div>
                <SocialMedias extraClass={"social-links"} socialMedias={post?.author?.socialMedia} />            
                <div>{tools.linesSpliter(post?.author?.biography)}</div>
                
                {post?.author?.metamarcePage?.lenght>10&&
                <div 
              onClick={e=>navigateHandle(`${post?.author?.metamarcePage}`)}               
              title={`برو به صفحه ${post?.author?.authorName} در متامارس`}>
                <div
                className="read-more2  d-flex justify-content-end mb-3"
                onClick={e => window.scroll(0,0)}
              >
              بیشتر بخوانید
                <span>
                  <i className="bi bi-chevron-double-left ms-1"></i>
                </span>
              </div>
                </div>}
              </div>
            </div>
  )
}

