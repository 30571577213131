import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CheckCircle,
  Clear,
  Error,
  Publish,
  WarningAmber,
} from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { CartContext } from "../../../contexts/cartContext";
import ToastNote from "../../../golbal/notifications/toastNote";
import ColoredSpinner from "../../../alret/spiners/coloredSpiner/coloredSpinner";
// import AddEducation from "../../../persian/addSocialMedia";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import RegisterAddressPlaces from "../../business/register/registerAddressPlaces";
import MyIcons from "../../../golbal/icons/myIcons";
import { Link, useNavigate, useParams } from "react-router-dom";
import renderData from "../../../../utils/renderData";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import addErrClass from "../../../persian/common/alerts/addErrClass";
import api from "../../../../utils/api";
import MetaDecorator from "../../../../utils/metaDecorator";
import demyData from "../../../../utils/demyData";
import tools from "../../../../utils/tools";
import CatsSelector from "../../../golbal/catsSelector";
import countryStates from "../../../../utils/countryStates";
import apiMP from "../../../../utils/apiMP";
import CountryStateCitySelector from "../../../golbal/dropdown/countryStateCitySelector";
import LangsLevel from "../../../persian/common/LangsLevel";
import AddEducation from "../../../persian/addEducation";
import FormikListErr from "../../../persian/common/alerts/formikListErr";

export default function MatchRegister({ action }) {
  const { lang, state, countryShort, stateShort } = tools.lcsn(
    window.location.pathname
  );
  const [view, setView] = useState({
    register: true,
    preview: false,
  });
  const [states, setStates] = useState([]);
  const [resisedPhotoAuth, setResisedPhotoAuth] = useState(null);
  const [originalPhoto, setOriginalPhoto] = useState(null);
  const [resisedLogoAuth, setResisedLogoAuth] = useState(null);
  const [originalLogo, setOriginalLogo] = useState(null);
  const photoHandle = async (e, setResisedState, setOriginalState) => {
    if (!tools.photoCheck(e))
      return messageRef.current.showError({
        message: "از فایل با فرمت عکس استفاده کنید.",
      });
    // console.log("photoHandle auth", originalPhoto);
    setResisedState(
      await tools.resizeFile({
        file: e.target.files[0],
      })
    );
    setOriginalState(e.target.files[0]);
  };
  const uploadFileDatabase = async (e, file, field, fieldState) => {
    e.preventDefault();
    if (!file) return;
    const resizedPhotoBlob = await tools.resizeFile({
      file,
      blob: true,
      width: 500,
      height: 500,
    });
    const formData = new FormData();
    formData.append("file", resizedPhotoBlob);
    console.log("formData", formData);
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.uploadSingleFile({
      token: user.token,
      formData,
      path: "images/mp/match",
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>مشکل در ذخیره فایل.</div> <div dir="ltr">{response.error}</div>
          </div>
        ),
      });

    console.log(response.data);
    formik.setValues((pre) => ({ ...pre, [field]: response.data }));
    fieldState(null);
    messageRef.current.showSuccess({
      message: "عکس با موفقیت بارگذاری شد، جهت ثبت کلید ویرایش را فشار دهید. ",
    });
  };


  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false, addSub: false });
  const countryDetails = JSON.parse(localStorage.getItem("countryDetails"));
  const { id } = useParams();

  const urlRegex =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const formik = useFormik({
    initialValues: {
      action,
      _id: "",
      lang,
      lcs: window.lcs,
      name: "",
      
      partner:{
        location:{country:"",state:"",city:""},
        traits :[],
        age:{min:18,max:70},
        height:{min:150,max:180},
        figures :["slim"],
        education:"",
        smoking:"",        
        drinking:"",
      },
      person:{
        birthday: "",
        gender:"",
        feelings:"",
        advice:"",
        trusting :"",
        traits  :["confident"],
        hair:"",
        eyes:"",
        height:"",
        figure:"",
        smoking:"",        
        drinking:"",
        children:"",
        education:"",
        income:100000,
        jobTitle:"",
        easyToChoosePartner:false,
        mostKnowSomeone:"", //What interests you the most when getting to know someone new?

      },
      
      // email: user.email,
      // socialMedia:[],
      // category: {
      //   mainCat: {},
      //   subCat: {},
      // },
      // img: "", 
      // title: "",
      // experience: '',
      // skills: "",
      // hobbies: '',
      // educationDesc: '',
      // education: [{level:"",field:"",institute:"",description:"",}],
      // emType: ["fullTime"],
      // certificates:"",
      // commute:"",
      // phone: '',
      // linkedin: '',
      // showContact:false,   
      // languages: [{ name: "", level: "" }],  
      salary: {
        period: "hourly",
        rate: 0,
        currency: "CAD",
        min: "20",
        max: "30",
        desc: "",
      },
      // tags: "",
      location: {
        displayAddress: false,
        googleAddress: "",
        preciseAddress:"",
        country: "",
        countryShort: "",
        state: "",
        stateShort: "",
        city: "",
        // route: "",
        // municipality: "",
        // streetNumber: "",
        // number: "",
        // postalCode: "",
        // lat: "",
        // lng: "",
      },
      // website: "",
    },
    onSubmit: async (values) => {
      console.log("onSubmit");
      if (values?.action === "edit")
        return editHandle({ messageRef, setLoading, user, values, navigate });

      console.log("passed Formik", values);
      setLoading((pre) => ({ ...pre, spinner: true }));
      const response = await apiMP.registerReady({
        token: user.token,
        data: values,
      });
      setLoading((pre) => ({ ...pre, spinner: false }));
      if (response.error)
        return messageRef.current.showError({
          message: (
            <div>
              <div> مشکل در انجام عملیات !</div>
              <div>{response.error}</div>
            </div>
          ),
        });
      messageRef.current.showSuccess({
        message: "تبریک، اطلاعات مدرس با موفقیت ثبت شد.",
      });

      const updateduser = await api.getUser();
      if (updateduser.error) return;
      signedUser(updateduser.data);
      window.scrollTo({ top: "0", behavior: "smooth" });

      navigate(`/${window.lcs}/mp/match/ready/filters`, {
        state: response.data,
      });
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("لطفاً نام را وارد کنید.")
        .max(120, "نام حداکثر باید 120 حرف باشد .")
        .min(3, "نام حداقل باید 3 حرف باشد."),
      title: Yup.string()
        .required("لطفاً عنوان شغلی را وارد کنید.")
        .max(120, "عنوان شغلی حداکثر باید 120 حرف باشد .")
        .min(3, "عنوان شغلی حداقل باید 3 حرف باشد."),
        experience: Yup.string()
        .required("لطفاً تجربه کاری خود را وارد کنید.")
        .max(2000, "تجربه کاری خود حداکثر باید 2000 حرف باشد .")
        .min(3, "تجربه کاری خود حداقل باید 3 حرف باشد."),
        commute: Yup.string()
        .required("لطفاً شهر یا محل کار را وارد کنید.")
        .max(250, "شهر یا محل کار حداکثر باید 250 حرف باشد .")
        .min(3, "شهر یا محل کار حداقل باید 3 حرف باشد."),
        certificates: Yup.string()        
        .max(2000, "گواهینامه ها حداکثر باید 2000 حرف باشد ."),
        

      // education: Yup.string()
      //   .required("لطفاً اطلاعات تکمیلی را وارد کنید.")
      //   .max(4000, "اطلاعات تکمیلی حداکثر باید 4000 حرف باشد .")
      //   .min(10, "اطلاعات تکمیلی حداقل باید 10 حرف باشد."),
      category: Yup.object().shape({
        mainCat: Yup.object().shape({
          _id: Yup.string().required(" دسته‌بندی را انتخاب کنید."),
        }),
        subCat: Yup.object().shape({
          _id: Yup.string().required(" دسته بندی کوچکتر را انتخاب کنید."),
        }),
      }),
      phone: Yup.string()
        .trim()
        .when("suggestMode", (suggestMode, schema, value) => {
          return (
            value.parent.phone?.length &&
            schema.test(
              "is-valid-phone",
              "شماره تلفن نامعتبر میباشد.",
              (value) => {
                return isValidPhoneNumber(value || "");
              }
            )
          );
        }),

      email: Yup.string()
        .required("لطفا ایمیل خود را وارد نمائید.")
        .trim()
        .lowercase()
        .email("ایمیل را به درستی وارد کنید."),
      // emType: Yup.array()
      // .required(" دست کم یک نوع از همکاری را انتخاب کنید.")
      // .test(
      //   "array-length",
      //   " دست کم یک نوع از همکاری را انتخاب کنید.",
      //   (value) => value?.length > 0
      // ),
      // languages: Yup.array()
      //   .required(" دست کم یک زبان را انتخاب کنید.")
      //   .test(
      //     "array-length",
      //     " دست کم یک زبان را انتخاب کنید.",
      //     (value) => value.length > 0
      //   ),
        languages: Yup.array().of(
          Yup.object().shape({
            level: Yup.string().required("لطفا سطح زبانی خود را انتخاب نمائید."),
            name: Yup.string().required("لطفا زبان را انتخاب نمائید."),
          })),

      location: Yup.object().shape({
        country: Yup.string().required("کشور نمیتواند خالی باشد."),
        state: Yup.string().required("استان نمیتواند خالی باشد."),
        city: Yup.string().required("شهر نمیتواند خالی باشد."),
        municipality: Yup.string(),
      }),
      linkedin: Yup.string().matches(
        /^https?:\/\/(?:www\.)?linkedin\.com(?:\/.*)?$/,
        " آدرس را در دامنه www.linkedin.com انتخاب کنید."
      ),
      // website: Yup.string().matches(
      //   urlRegex,
      //   " آدرس وبسایت را به درستی وارد کنید."
      // ),
      // socialMedia: Yup.array().of(
      //   Yup.object().shape({
      //     platform: Yup.string(),

      //     url: Yup.string()
      //       .when("platform", (platform, schema) => {
      //         return platform
      //           ? schema.required("لطفاً آدرس URL را وارد نمایید.")
      //           : schema;
      //       })
      //       .test("validUrl", " آدرس را به شکل درست وارد کنید.", (value) => {
      //         if (!value) return true; // Allow empty value
      //         // Regular expression to validate URL
      //         return urlRegex.test(value);
      //       }),
      //   })
      // ),
      // availability: Yup.array().of(
      //   Yup.object().shape({
      //     availableDay: Yup.string().required("لطفا روزهای کاری را انتخاب نمائید."),
      //     availableTime: Yup.string().required("لطفا ساعات کاری را انتخاب نمائید."),
      //   })
      // ),
      // distances: Yup.array().of(
      //   Yup.object().shape({
      //     distance: Yup.string().required("Please enter the distance."),
      //     cost: Yup.string().required("Please enter the cost."),
      //   })
      // ),
      salary: Yup.object().shape({
        // min: Yup.number().required(" حداقل حقوق را وارد کنید.").min(1),
        desc: Yup.string().required("لطفا حقوق درخواستی خود را وارد نمائید.").min(5,"لطفا اطلاعات بیشتری در مورد حقوق بنویسید"),
      }),
      education: Yup.array().of(
        Yup.object().shape({
          field: Yup.string().required("لطفا رشته تحصیلی را انتخاب نمائید.").min(3, "رشته تحصیلی حداقل باید 3 حرف باشد."),
          institute: Yup.string().required("لطفا نام مرکز آموزشی را انتخاب نمائید.").min(3, "نام مرکز آموزشی حداقل باید 3 حرف باشد."),
          level: Yup.string().required("لطفا مقطع آموزشی را انتخاب نمائید.").min(3, "مقطع آموزشی حداقل باید 3 حرف باشد."),
         
        })
      )
    }),
  });

  useEffect(() => {
    fetchDetails();
    // fetchSubjects()
  }, [id]);

  async function fetchDetails() {
    console.log(id);
    if (!id) return;
    const response = await apiMP.readyDetails(id);
    if (response.error) return alert(response.error);
    // console.log(response.data);
    editInitialData(formik, response.data, action);
  }
  console.log("formik", formik.values);
  // console.log("formik.errors", formik.errors);

  // const act = checkAction();
  // function checkAction() {
  //   // console.log("action", action);
  //   let actToDo = { textBtn: "کنترل پارامتر ها", getAction: "register" };
  //   const verifiedPhone =
  //     formik.values?.verifiedPhone[0]?.phoneNumber ===
  //     formik.values.contactPhone;
  //   // console.log('verifiedPhone',verifiedPhone);
  //   if (action === "register") {
  //     actToDo.textBtn = "ثبت اولیه";
  //     // actToDo.getAction = register;
  //   }
  //   if (
  //     action === "edit" &&
  //     verifiedPhone &&
  //     formik.values.status === "01-draft"
  //   ) {
  //     actToDo.textBtn = "فعال سازی";
  //     // actToDo.getAction = editAndActivate;
  //   }
  //   if (
  //     action === "edit" &&
  //     !verifiedPhone &&
  //     tools.compareCheck([formik.values.status], ["01-draft"])
  //   ) {
  //     actToDo.textBtn = "تائید هویت و فعال سازی";
  //     // actToDo.getAction = actModal;
  //   }
  //   if (
  //     action === "edit" &&
  //     !verifiedPhone &&
  //     tools.compareCheck([formik.values.status], ["01-draft"]) &&
  //     formik.values.history.createdBy._id === user._id
  //   ) {
  //     actToDo.textBtn = "ویرایش پیشنهاد";
  //     // actToDo.getAction = editBusiness;
  //   }
  //   if (
  //     action === "edit" &&
  //     tools.compareCheck(
  //       [formik.values.status],
  //       ["02-claimed", "03-verified", "04-trusted"]
  //     ) &&
  //     verifiedPhone
  //   ) {
  //     actToDo.textBtn = "ویرایش";
  //     // actToDo.getAction = editBusiness;
  //   }
  //   if (
  //     action === "edit" &&
  //     !verifiedPhone &&
  //     tools.compareCheck(
  //       [formik.values.status],
  //       ["02-claimed", "03-verified", "04-trusted"]
  //     )
  //   ) {
  //     actToDo.textBtn = "تائید هویت و ویرایش";
  //     // actToDo.getAction = actModal;
  //   }
  //   return actToDo;
  // }
  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "University Register",
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/mp/match`,
              name: `دوستیابی`,
              description:
                "پل ارتباطی بین جویندگان کار و کارفرماها در شهرها و محله‌های گوناگون ... ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/dashboard/mp/registermatch`,
              name: "ثبت رایگان آگهی استخدام",
              description: "ثبت آسان، انتشار سریع و خودکار آگهی استخدام",
            },
          },
        ],
      },
    },
  ];

  const metaTagsData = {
    title: "ثبت نام دوستابی",
    description: "ثبت اطلاعات شخصی",
    section: "Tutor register",
    url: `https://www.metamarce.com/${window.lang}/ca/qc/dashboard/mp/registermatch`,
    img: "https://www.metamarce.com/img/demy/Hiring-rafiki.svg",
    type: "",
  };

  return (
    <>
    <MetaDecorator data={{ ...metaTagsData, structuredData }} />
    <ToastNote messageRef={messageRef} />
    {loading.spinner && <ColoredSpinner />}
    <div className=" mx-0 align-items-center w-100">
      <div
        className="display-none-md"
        style={{
          backgroundColor: "var(--brownGray)",
        }}
      >
        <div className="text-center pt-5 titr3 font-40">
          افزودن کسب و کار جدید
        </div>
      </div>
      <div className="text-center m-4 titr7  display-tablet">
        افزودن کسب و کار جدید
      </div>
    </div>

    {/* <!-- This is htmlFor curve! --> */}

    <div className="business-top-curve display-none-md">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style={{ height: "100%", width: "100%" }}
      >
        <path
          d="M-2.82,59.70 C211.62,278.78 237.58,-55.75 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style={{ stroke: "none", fill: "var(--brownGray)" }}
        ></path>
      </svg>
    </div>

    {/* <!-- *************************************************************************************************************************************************** --> */}
    {user?.roles?.includes(9999) ? (
      <div>
        <div>
          <form
            name="busRegForm"
            id="busRegForm"
            className="form-style mt-0"
            onSubmit={formik.handleSubmit}
            autoComplete="false"
          >
            {/* <!-- Righ side --> */}
            <div className="px-md-5 px-2">
              <div className="my-3">
                <div className="accordion w-100" id="accordionExample">
                  <div className="accordion-item2 m-md-3 my-3">
                    <div
                      className="accordion-button accordion-button4 collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      data-bs-target={`#open-col-location`}
                      aria-controls={`open-col-location`}
                      aria-labelledby="open-col-location-tab"
                    >
                      <div>
                        موقعیت جغرافیایی
                        <span> {checkFields(formik, ["location"])} </span>
                      </div>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="open-col-location"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="row ">
                        <div className="col-md-6 ">
                          {/* <RegisterAddress formik={formik} /> */}
                          <p className="form-titr mb-3  large bold">
                            <span>
                              <i className="bi bi-circle-fill mx-1"></i>
                            </span>
                            نشانی کسب و کار :
                            <Tooltip1
                              message={
                                <div>
                                  اگر آدرس دقیق پیشنهاد نشد، نزدیک ترین منطقه
                                  به خود را پیدا و در ادامه اطلاعات کامل را
                                  وارد کنید.
                                </div>
                              }
                            />
                          </p>
                          <RegisterAddressPlaces
                            formik={formik}
                            section="business"
                            setLoading={setLoading}
                          />
                          {/* <TargetAddress formik={formik} /> */}

                          {(formik.values.status === "02-claimed" ||
                            formik.values.status === "03-verified") && (
                            <div
                              className="d-flex justify-content-between "
                              onClick={(e) =>
                                redirectGoPro({ formik, navigate })
                              }
                            >
                              <p
                                className="form-titr mt-0 py-0 mb-3 "
                                data-bs-toggle="modal"
                                data-bs-target="#premiumModal"
                              >
                                <span>
                                  <img
                                    src="/img/premium/global.png"
                                    className="arrow4 px-1"
                                    alt="targetAddress"
                                    loading="lazy"
                                  />
                                </span>
                                نمایش آگهی در متامارس دیگر کشور/ استانها :
                              </p>
                              <span className="mt-0 py-0 mb-3 ">
                                <img
                                  // src="/img/premium/premium.png"
                                  src="/img/premium/premium-quality.png"
                                  className="arrow2"
                                  alt="premium"
                                />
                              </span>
                            </div>
                          )}
                          {/* {formik.values.isPaid.targetAddress && (
                            <div>
                              <TargetAddress01 formik={formik} />
                            </div>
                          )} */}
                        </div>

                        <div className="col-md-6 ">
                          {/* <MapBusRegister data={formik.values?.location} /> */}
                          {formik.values.location.city?.length > 0 && (
                            <div className="lat-lng m-3  ">
                              <p className="form-titr mb-3 mt-4 large bold required">
                                {/* <span >
                        <i className="bi bi-circle-fill mx-1"></i>
                      </span> */}
                                مختصات جغرافیایی کسب و کار:
                              </p>
                              <div className="row">
                                <div className="col mb-2">
                                  <label
                                    className="form-label mt-0 mb-2"
                                    htmlFor="f201"
                                  >
                                    عرض
                                  </label>
                                  <input
                                    id="f201"
                                    type="number"
                                    value={formik.values?.location.lat}
                                    {...formik.getFieldProps("location.lat")}
                                    // onChange={(event) => setLat(parseFloat(event.target.value))}
                                    // step={0.01}
                                  />
                                  <FormikErrRender
                                    formik={formik}
                                    field="location.lat"
                                  />
                                </div>

                                <div className="col mb-2">
                                  <label
                                    className="form-label mt-0 mb-2"
                                    htmlFor="f200"
                                  >
                                    طول
                                  </label>

                                  <input
                                    id="f200"
                                    type="number"
                                    value={formik.values?.location.lng}
                                    {...formik.getFieldProps("location.lng")}
                                    // onChange={(event) => setLng(parseFloat(event.target.value))}
                                    // step={0.01}
                                  />
                                  <FormikErrRender
                                    formik={formik}
                                    field="location.lng"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <img
                            src="/img/Images/Location-review-rafiki.svg"
                            className="img-fluid display-none-md "
                            alt="map"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item2 m-md-3 my-3">
                    <div
                      className="accordion-button accordion-button4 collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      data-bs-target={`#open-col-personInfo`}
                      aria-controls={`open-col-personInfo`}
                      aria-labelledby="open-col-personInfo-tab"
                    >
                      <div>
                       اطلاعات شخصی شما
                        {checkFields(formik, ["busName", "category", "desc"])}
                      </div>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="open-col-personInfo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="row d-flex">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              className="form-label mt-3 mb-0 required"
                              htmlFor="f1"
                            >
                              نام
                       
                            </label>
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control py-1 box2 "
                              id={"f1"}
                              placeholder=" (مثال: دانا)"
                              {...formik.getFieldProps(`name`)}
                            />
                            <FormikErrRender
                            formik={formik}
                            field="name"
                          />  
           
                          </div>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="birthday">
                              تاریخ تولد شما
                              
                            </label>

                            <input
                            id="birthday"
                            type="date"
                              className="form-control"
                              rows="7"
                              {...formik.getFieldProps("person.birthday")}
                            ></input>
                            <FormikErrRender
                              formik={formik}
                              field="person.birthday"
                            />
                          </div>

                         
              <div className="mb-3">                 
                <div className=""> کدوم کشور یا شهر؟</div>
              <CountryStateCitySelector formik={formik} />             
              </div>

              <div className="mb-3">
                            <label className="form-label" htmlFor="easyToChoosePartner">
                            برای شما انتخاب پارتنر راحته?
                              
                            </label>
                            <select name="" id="easyToChoosePartner" onChange={e=>formik.setFieldValue("person.easyToChoosePartner", e.target.value)}>
                              <option value="">انتخاب کنید :</option>
                              <option value={true}>بله من حس ام رو دنبال میکنم</option>
                              <option value={false}>ابتدا بایستی سبک و سنگین کنم</option>
                            </select>
                            {formik.values.person.easyToChoosePartner=="false"&&<div>اینجوری خیلی مطمیئن تره!</div>}
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
             
                        <div className="mb-3">
                            <label className="form-label" htmlFor="personMostKnowSomeone">
                            بیایید تصور کنیم که عاشق شده اید.
                            <div>آیا با احساسات خود باز هستید؟
                            </div>
                            </label>
                            <select name="" id="personMostKnowSomeone" onChange={e=>formik.setFieldValue("person.mostKnowSomeone", e.target.value)}>
                              <option value="">انتخاب کنید :</option>
                              <option value='yes'>شغل و سوابق گذشته </option>
                              <option value='maybe'> من بیشتر دقت میکنم </option>
                              <option value='no'> ترجیح میدهم تا دیت اول حرکتی نکنم </option>
                            </select>
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="personAdvice">
                            آیا هنگام انتخاب شریک زندگی از دوستان و خانواده خود مشاوره می گیرید؟
                            <div>
                            </div>
                            </label>
                            <ul className="">
                              {matchData.advice.map((adv,i)=><li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.person.advice===adv.name?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pre=>{
                                if(formik.values.person.advice===adv.name){
                                  pre.person.advice=""
                                }else{
                                   pre.person.advice=adv.name
                                }
                                // const traits = [...pre.person.traits]
                                // const index =traits.indexOf(adv.name)
                                // console.log('index',index);                                  
                                // if(index!==-1){
                                //   traits.splice(index,1)
                                // }else{
                                //   traits.push(adv.name)
                                // }
                                // pre.person.traits=traits
                                return pre                                 
                              })}
                              >
                                <span>{adv.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li>)}
                            </ul>
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="personAdvice">
                            به نظر می رسد وقتی صحبت از اعتماد به شخصی جدید می شود محتاط هستید.
                            <div>
                            </div>
                            </label>
                            <ul className="">
                              {matchData.advice.map((adv,i)=><li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.person.advice===adv.name?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pre=>{
                                if(formik.values.person.advice===adv.name){
                                  pre.person.advice=""
                                }else{
                                   pre.person.advice=adv.name
                                }
                                // const traits = [...pre.person.traits]
                                // const index =traits.indexOf(adv.name)
                                // console.log('index',index);                                  
                                // if(index!==-1){
                                //   traits.splice(index,1)
                                // }else{
                                //   traits.push(adv.name)
                                // }
                                // pre.person.traits=traits
                                return pre                                 
                              })}
                              >
                                <span>{adv.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li>)}
                            </ul>
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>

                          <div className="mb-3">
                            <label className="form-label" htmlFor="traits">
                            به نظر شما کدام ویژگی را دارید؟ سعی کنید صادق باشید
                            </label>
                            <ul className="">
                              {matchData.traits.map((trait,i)=><li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.person.traits.indexOf(trait.name)!==-1?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pre=>{
                                const traits = [...pre.person.traits]
                                const index =traits.indexOf(trait.name)
                                console.log('index',index);    
                                                         
                                if(index!==-1){
                                  traits.splice(index,1)
                                }else{
                                  traits.push(trait.name)
                                }
                                pre.person.traits=traits
                                return pre                                 
                              })}
                              >
                                <span>{trait.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li>)}
                            </ul>
                            
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
             
           

                        </div>

                        <div className="col-md-6 display-none-md">
                          <img
                            src="/img/Images/Profile-Interface-bro.svg"
                            className="img-fluid   "
                            alt="Profile"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item2 m-md-3 my-3">
                    <div
                      className="accordion-button accordion-button4 collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      data-bs-target={`#open-col-partnerInfo`}
                      aria-controls={`open-col-partnerInfo`}
                      aria-labelledby="open-col-partnerInfo-tab"
                    >
                      <div>
                        اطلاعات پارتنر
                        {checkFields(formik, [
                          "email",
                          "contactPhone",
                          "businessPhone",
                          "workingDays",
                        ])}
                      </div>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="open-col-partnerInfo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="col-md-7 col-lg-6">
                      <div className="mb-3">
                            <label className="form-label" htmlFor="partnerMostKnowSomeone">
                            هنگام آشنایی با شخصی جدید، چه چیزی بیشتر شما رو جذب میکنه؟

                              
                            </label>
                            <select name="" id="partnerMostKnowSomeone" onChange={e=>formik.setFieldValue("partner.mostKnowSomeone", e.target.value)}>
                              <option value="">انتخاب کنید :</option>
                              <option value='job'>شغل و سوابق گذشته </option>
                              <option value='hopes'>امید و آرزوهاش</option>
                            </select>
                      
                            <FormikErrRender
                              formik={formik}
                              field="partner.easyToChoosePartner"
                            />
                          </div>
                      <div className="mb-3">
                            <label className="form-label" htmlFor="traits">
                            کدام ویژگی شریک زندگی برای شما مهمتر است؟                              
                            </label>
                            <ul className="">
                              {matchData.traits.map((trait,i)=><li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.partner.traits.indexOf(trait.name)!==-1?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pre=>{
                                const traits = [...pre.partner.traits]
                                const index =traits.indexOf(trait.name)
                                console.log('index',index);    
                                                         
                                if(index!==-1){
                                  traits.splice(index,1)
                                }else{
                                  traits.push(trait.name)
                                }
                                pre.partner.traits=traits
                                return pre                                 
                              })}
                              >
                                <span>{trait.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li>)}
                            </ul>
                            
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
                        <div className="mb-3">
                          <div
                             className="form-label required mb-0"

                            // className={`form-label mt-3 mb-0 `}
                            htmlFor="f3"
                          >
                            پاتنر شما باید چند ساله باشند؟
                       
                          </div>
                          {/* age */}
                        <div className="mb-3 d-flex space-between">
                          <div>
                          <label htmlFor="partnerAgeFrom">From</label>
                          <select name="" id="partnerAgeFrom" onChange={e=> formik.setFieldValue('partner.age.min',e.target.value)}>
                            <option value="">انتخاب کنید</option>    
                            {[...Array(63).keys()].map((index) => {
                              const age = index + 18;
                              return (
                                <option key={age} value={age}>
                                  {age}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                          <div>
                          <label htmlFor="partnerAgeTo">To</label>
                          <select name="" id="partnerAgeTo" onChange={e=> formik.setFieldValue('partner.age.max',e.target.value)}>
                            <option value="">انتخاب کنید</option>    
                            {[...Array(63).keys()].map((index) => {
                              const age = index + 18;
                              return (
                                <option key={age} value={age}>
                                  {age}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        </div>

                        <div className="mb-3">
                                                  <div
                                                    className="form-label required mb-0"

                                                    // className={`form-label mt-3 mb-0 `}
                                                    htmlFor="f3"
                                                  >
                        قد پاتنر شما چقدر باید باشد؟                       
                                                  </div>

                                                  {/* height */}
                                                  <div className="d-flex space-between">
                                                  <div>
                          <label htmlFor="partnerAgeFrom">From</label>
                          <select name="" id="partnerAgeFrom" onChange={e=> formik.setFieldValue('partner.height.min',e.target.value)}>
                            <option value="">انتخاب کنید</option>    
                            <option value="144">  کمتر از 144</option>    
                            {[...Array(66).keys()].map((index) => {
                              const height = index + 145;
                              return (
                                <option key={height} value={height}>
                                  {height}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                                                  <div>
                          <label htmlFor="partnerAgeTo">To</label>
                          <select name="" id="partnerAgeTo" onChange={e=> formik.setFieldValue('partner.height.max',e.target.value)}>
                            <option value="">انتخاب کنید</option>    
                            {[...Array(66).keys()].map((index) => {
                              const height = index + 145;
                              return (
                                <option key={height} value={height}>
                                  {height}
                                </option>
                              );
                              <option value="211">  بیشتر از 210</option> 
                            })}
                          </select>
                        </div>
                        </div>
                        </div>


                        <div className="mb-3">
                            <label className="form-label" htmlFor="figures">
                            کدام استایل را برا پارتنر خود می پسندید؟                              
                            </label>
                            <ul className="">
                              {matchData.figures.map((figure,i)=>
                              <div>
                                <input checked={formik.values.partner.figures.some(fi=>fi===figure.name)} type="checkbox" id={"figureInp"+i} onChange={e=>formik.setValues(pre=>{
                                let figures = [...pre.partner.figures]
                                const index =figures.indexOf(figure.name)
                                console.log('index',index ,figure );
                                // if(formik.values.partner.figures.length>=3){
                                //   figures=['all']
                                // }else                                  
                                // if(figure.name==='all'){
                                //   figures=['all']
                                // }else                                  
                                if(index!==-1){
                                  // figure.name==='all'?figures=['all']:
                                  figures.splice(index,1)
                                }else{
                                  // figure.name==='all'?figures=['all']:
                                  figures.push(figure.name)
                                }
                                pre.partner.figures=figures
                                return pre                                 
                              })}/>
                                <label htmlFor={"figureInp"+i}>{figure.fa}</label>
                              <li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.partner.figures.indexOf(figure.name)!==-1?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pre=>{
                                const figures = [...pre.partner.figures]
                                const index =figures.indexOf(figure.name)
                                console.log('index',index);                                  
                                if(index!==-1){
                                  figures.splice(index,1)
                                }else{
                                  figures.push(figure.name)
                                }
                                pre.partner.figures=figures
                                return pre                                 
                              })}
                              >
                                <span>{figure.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li></div>)}
                            </ul>
                            
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>
                        <div className="mb-3">
       


                            <label className="form-label" htmlFor="figures">
                            کدام لایف استایل را برا پارتنر خود می پسندید؟                              
                            </label>
                            <div class="accordion" id="accordionLifeStyle">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEducation" aria-expanded="true" aria-controls="collapseEducation">
        تحصیلات
      </button>
    </h2>
    <div id="collapseEducation" class="accordion-collapse collapse show" data-bs-parent="#accordionLifeStyle">
      <div class="accordion-body">
      <ul className="">
                              {renderData.education.map((edu,i)=>
                              <div>                          
                              <li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.partner.education===edu.name?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pr=>{
                               const pre = {...pr}
                                if(pre.partner.education===edu.name){
                                  pre.partner.education=""
                                }  else{
                                  pre.partner.education=edu.name
                                }
                                return pre                                 
                              })}
                              >
                                <span>{edu.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li></div>)}
                            </ul>  </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSmoking" aria-expanded="false" aria-controls="collapseSmoking">
        مصرف سیگار
      </button>
    </h2>
    <div id="collapseSmoking" class="accordion-collapse collapse" data-bs-parent="#accordionLifeStyle">
      <div class="accordion-body">
      <ul className="">
                              {matchData.smoking.map((smo,i)=>
                              <div>                          
                              <li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.partner.smoking===smo.name?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pr=>{
                               const pre = {...pr}
                                if(pre.partner.smoking===smo.name){
                                  pre.partner.smoking=""
                                }  else{
                                  pre.partner.smoking=smo.name
                                }
                                return pre                                 
                              })}
                              >
                                <span>{smo.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li></div>)}
                            </ul>  
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDrinking" aria-expanded="false" aria-controls="collapseDrinking">
        مصرف الکل
      </button>
    </h2>
    <div id="collapseDrinking" class="accordion-collapse collapse" data-bs-parent="#accordionLifeStyle">
      <div class="accordion-body">
      <ul className="">
                              {matchData.drinking.map((drink,i)=>
                              <div>                          
                              <li className={`btn-search shadow-none m-2 d-flex justify-content-between align-items-center cursor ${formik.values.partner.drinking===drink.name?'bg-pinkOrangeDark':""}`}
                              onClick={e=>formik.setValues(pr=>{
                               const pre = {...pr}
                                if(pre.partner.drinking===drink.name){
                                  pre.partner.drinking=""
                                }  else{
                                  pre.partner.drinking=drink.name
                                }
                                return pre                                 
                              })}
                              >
                                <span>{drink.fa}</span>
                                {/* <span className="ms-3 me-2" id={i} >
              <i className="bi bi-x-lg"></i>
            </span> */}
                              </li></div>)}
                            </ul>  
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionLifeStyle">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>


                        
                            
                      
                            <FormikErrRender
                              formik={formik}
                              field="person.easyToChoosePartner"
                            />
                          </div>

                 

                

                   
                        </div>
                   

                    
                    
                       
                      </div>
                  
                    </div>
                  </div>
                  <div className="accordion-item2 m-md-3 my-3">
                    <div
                      className="accordion-button accordion-button4 collapsed "
                      type="button"
                      data-bs-toggle="collapse"
                      aria-expanded="true"
                      data-bs-target={`#open-col-socialMedias`}
                      aria-controls={`open-col-socialMedias`}
                      aria-labelledby="open-col-socialMedias-tab"
                    >
                      <div>
                        شبکه های اجتماعی
                        {checkFields(formik, ["socialMedia", "website"])}
                      </div>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="open-col-socialMedias"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="row d-flex">
                        <div className="col-md-6 ps-3">
                          <div className="mb-3 mt-3">
                            <label
                              className="form-label mt-3  ps-2"
                              htmlFor="f5"
                            >
                              وبسایت
                              <Tooltip1
                                message="برای مثال: https://www.metamarce.com،در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                                position="top-end"
                              />
                            </label>
                            <a
                              tabIndex="0"
                              className="btn info-btn shadow-none "
                              role="button"
                              data-bs-toggle="popover"
                              data-bs-trigger="focus"
                              data-bs-content="در صورت تمایل لینک وبسایت خود را به صورت کامل وارد کنید."
                            >
                              {/* <i className="bi bi-question-circle-fill"></i> */}
                            </a>

                            <input
                              type="text"
                              autoComplete="off"
                              className=" form-control mb-5 py-1 box2"
                              dir="ltr"
                              id="f5"
                              placeholder=" https://www.instagram.com/metamarce?igsh=azJoejNtdjdydXY4&utm_source=qr "
                              {...formik.getFieldProps("website")}
                            />
                            <FormikErrRender
                              formik={formik}
                              field="website"
                            />
                          </div>
                          لینک شبکه‌های اجتماعی خودتون را وارد کنید.
                          {/* <AddSocialMedia formik={formik} /> */}
                        </div>

                        <div className="col-md-6 display-none-md ">
                          <img
                            src="/img/Images/Mobile-Marketing-pana-(2).png"
                            className="img-fluid   "
                            alt="social Media"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                 
              
                
                
                </div>
   
                <label
                  htmlFor="termsBtn"
                  className={`required my-3 mb-5 med-gray-font  `}
                >
                  <a
                    className=" py-0 pe-1 fw-bold fst-italic"
                    href={`/${window.lcs}/business/terms`}
                    target="_blank"
                  >
                   ( قوانین افزودن کسب و کار )
                  </a>
                  <span>را مطالعه کردم و می‌پذیرم</span>

                  <input
                    id="termsBtn"
                    type="checkbox"
                    className="form-check-input mx-2"
                    onChange={(e) => {
                      if (formik.values.terms) return;
                      formik.setValues((pre) => ({ ...pre, terms: true }));
                    }}
                    checked={formik.values.terms}
                  />
                  <FormikErrRender formik={formik} field="terms" />
                </label>
                <FormikListErr formik={formik}/>
                <SubmitBtn formik={formik} loading={loading} />
              </div>
            </div>
          </form>
        </div>
        {/* )} */}
   
        {/* <!-- ********** back to top************************* --> */}
        <div className="row backtotop">
          <div className="col-12">
            <a
              href="#top"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="بازگشت به آغاز صفحه"
              className="tooltip-color"
            >
              <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
            </a>
          </div>
        </div>
      </div>
    ) : (
      <div className="container-404 text-center d-flex flex-column align-items-center px-4 ">
        <div className="my-3 ">
          <div> در گام نخست شما نیاز دارید تا وارد حساب کاربری شوید.</div>

          <Link
            id="loginTrigger"
            className="btn primary-btn btn-404 checkout-btn my-5 mt-3"
            data-bs-toggle="modal"
            to="#exampleModalToggle"
            role="button"
          >
            ورود به حساب کاربری
          </Link>
          <img
            className=" img-404 w-75 "
            src="/img/illustration/access-denied.svg"
            alt="404"
          />
        </div>
      </div>
    )}
  </>
  );

  function SubmitBtn({ text, formik }) {
    let textBtn = "بفرست";
    if (formik.values.action === "edit") textBtn = "ویرایش";
    return (
      <button
        disabled={formik.isSubmitting}
        className="my-1 login-btn login-btn2"
        style={{
          backgroundColor: Object.keys(formik.errors)?.length > 0 && "grey",
        }}
        type="submit"
        id="createBusBtn"
      >
        {textBtn}
        {loading.spinner ? (
          <CircularProgress color="inherit" size="20px" />
        ) : (
          Object.keys(formik.errors)?.length === 0 && <CheckCircle />
        )}
        <span>
          {Object.keys(formik.errors)?.length > 0 && (
            <Tooltip1
              message=" موارد ستاره دار را کامل کنید."
              icon={<Error sx={{ fontSize: 18, color: "red" }} />}
              position="left"
            />
          )}
        </span>
      </button>
    );
  }






}

function editInitialData(formik, data, action) {
  const initialValues = {
    action,
    _id: action === "edit" ? data._id : "",
    lang: data?.lang ?? window.lang,
    lcs: data?.lcs ?? window.lcs,
    email: data.email,
    name: data.name,
    category: data.category,
    img: data.moreInfo.img,
    title: data.moreInfo.title,
    experience: data.moreInfo.experience,
    skills: data.moreInfo.skills,
    hobbies: data.moreInfo.hobbies,
    educationDesc: data.moreInfo.educationDesc,
    education: data.moreInfo.education,
    emType: data.moreInfo.emType,
    certificates: data.moreInfo.certificates,
    commute: data.moreInfo.commute,
    phone: data.moreInfo.phone,
    linkedin: data.moreInfo.linkedin,
    showContact: data.moreInfo.showContact,
    languages: data.moreInfo.languages,
    salary: data.moreInfo.salary,
    tags: data.moreInfo.tags.join("+"),


    status: data.moreInfo.status,
    // title: data.moreInfo.title,
    website: data.moreInfo.website,
   
 
    location: {
      ...data.moreInfo.address,
      lat: data.moreInfo.address?.coordinates?.lat,
      lng: data.moreInfo.address?.coordinates?.lng,
    },
  };
  formik.setValues(initialValues);
}

async function editHandle({ messageRef, setLoading, user, values, navigate }) {
  console.log("edit handle triggred");
  setLoading((pre) => ({ ...pre, spinner: true }));
  const response = await apiMP.editReady({
    token: user.token,
    data: values,
  });
  setLoading((pre) => ({ ...pre, spinner: false }));
  if (response.error)
    return messageRef.current.showError({
      message: (
        <div>
          <div> مشکل در انجام عملیات !</div>
          <div dir="ltr">{response.error}</div>
        </div>
      ),
    });

  messageRef.current.showSuccess({
    message: " اطلاعات با موفقیت ویرایش شد.",
  });

  navigate(`/${window.lcs}/mp/match/page/${response.data._id}`, {
    state: response.data,
  });
}
function redirectGoPro({ formik, navigate }) {
  navigate(
    `/${window.lcs}/dashboard/user/ads/plans?_id=${formik.values._id}&targetIdentifier=Business`,
    {
      state: {
        title: formik.values.name,
        img: formik.values.photos.businessCard,
        targetIdentifier: "Business",
        _id: formik.values._id,
      },
    },
    {
      // replace: true,
    }
  );
}




function checkFields(formik, fields) {
  const hasError = fields.some(
    (field) => field in formik.touched && field in formik.errors
  );

  if (hasError) {
    return (
      <span>
        <Error sx={{ color: "red" }} />
      </span>
    );
  }
  //  else {
  //   return <CheckCircle />;
  // }
}


const matchData={
  advice: [
    { name: "advice", en: "Absolutely, I enjoy talking about my relationship with those closest to me", fa: "مطمئناً از صحبت کردن در مورد رابطه ام با نزدیک ترین افراد لذت می برم", img: "" },
    { name: "noAdv", en: "I prefer to keep my relationship private", fa: "ترجیح می دهم رابطه ام را خصوصی نگه دارم", img: "" },
  ],
  trusting: [
    { name: "trust", en: "Yes, I trust little by little", fa: "بله کم کم اعتماد می کنم", img: "" },
    { name: "noTrust", en: "No, I trust 100% from the start", fa: "نه، من از ابتدا 100% اعتماد دارم", img: "" },
  ],
  drinking: [
    { name: "noP", en: "No preference", fa: "فرقی نداره", img: "" },
    { name: "nonDrinker", en: "Don't drink Alcohole", fa: "غیر الکلی", img: "" },
    { name: "socialDrinker", en: "Socila Drinker", fa: "گاهی تفریحی", img: "" },
    { name: "Drinker", en: "Drink daily", fa: "الکلی", img: "" },
  ],
  smoking: [
    { name: "noP", en: "No preference", fa: "فرقی نداره", img: "" },
    { name: "nonSmoker", en: "Non Smoker", fa: "غیرسیگاری", img: "" },
    { name: "socialSmoker", en: "Socila Smoker", fa: "گاهی تفریحی", img: "" },
    { name: "smoker", en: "Smoker", fa: "سیگاری", img: "" },
  ],
  figures : [
    { name: "all", en: "No preference", fa: "همه جوره", img: "" },
    { name: "slim", en: "Slim", fa: "لاغر", img: "" },
    { name: "average", en: "Average", fa: "میانگین", img: "" },
    { name: "fewPoundsMore", en: "A few pounds more", fa: "چند کیلو اضافه داشته باشد", img: "" },
  ],
  traits : [
    { name: "headstrong", en: "headstrong", fa: "سرسخت", img: "" },
    { name: "outgoing", en: "outgoing", fa: "برونگرا", img: "" },
    { name: "likesToRelax", en: "likes to relax", fa: "ریلکس", img: "" },
    { name: "attentive", en: "attentive", fa: "مراقب", img: "" },
    { name: "assertive", en: "assertive", fa: "مدعی", img: "" },
    { name: "likesBeingBusy", en: "likes being busy", fa: "مشغول", img: "" },
    { name: "impulsive", en: "impulsive", fa: "تکانشی", img: "" },
    { name: "talkative", en: "talkative", fa: "پرحرف", img: "" },
    { name: "spontaneous", en: "spontaneous", fa: "خود انگیز", img: "" },
    { name: "careerDriven", en: "career driven", fa: "شغل محور", img: "" },
    { name: "laidBack", en: "laid back", fa: "عقب گذاشته", img: "" },
    { name: "familyOriented", en: "family Oriented", fa: "اهل خانواده", img: "" },
    { name: "listener", en: "listener", fa: "شنونده", img: "" },
    { name: "creative", en: "creative", fa: "خلاق", img: "" },
    
  ],

}