import { useState, useEffect, useRef } from "react";
import {
  GoogleMapsProvider,
  useGoogleMap,
} from "@ubilabs/google-maps-react-hooks";

let  mapOptions = {};


export default function GoogleMap(props) {
 
  const location = props?.data
   mapOptions = {
    zoom: 12,
    center: {
      lat:location?.lat ||45.5019,
      lng: location?.lng||-73.5674,
    },
  };
  // console.log(mapOptions);

  const [mapContainer, setMapContainer] = useState(null);
  const [selected, setSelected] = useState(null);
//   const [value, setValue] = useState(null);

  return (
    <GoogleMapsProvider
    googleMapsAPIKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
    mapOptions={mapOptions}
    mapContainer={mapContainer}       
      >
      <div ref={(node) => setMapContainer(node)} style={{ height: "50vh" }} />
      <Location />        
    </GoogleMapsProvider>
  );
} 

function Location() {

  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  useEffect(()=>{
    setLat(mapOptions.center.lat)
    setLng(mapOptions.center.lng)
    // console.log('useEffect');
  },[mapOptions])
  
  
  const  map  = useGoogleMap();
  const markerRef = useRef();
// console.log(lat,lng);
  useEffect(() => {
    if (!map || markerRef.current) return;
    markerRef.current = new window.google.maps.Marker({ map });
  }, [map]);

  useEffect(() => {
    if (!markerRef.current) return;
    if (isNaN(lat) || isNaN(lng)) return;
    markerRef.current.setPosition({ lat, lng });
    map.panTo({ lat, lng });
  }, [lat, lng, map]);

  // return (
  //   <div className="lat-lng">
  //     <input type="number" value={lat}
  //      onChange={(event) => setLat(parseFloat(event.target.value))}
  //       step={0.01}
  //     />
  //     <input
  //       type="number"
  //       value={lng}
  //       onChange={(event) => setLng(parseFloat(event.target.value))}
  //       step={0.01}
  //     />
  //   </div>
  // );
}


  