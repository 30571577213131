export  const userData = [
    {
      name: 'Jan',
      "Active User": 4000,
      
    },
    {
      name: 'Feb',
      "Active User": 3000,
    
    },
    {
      name: 'Mar',
      "Active User": 2000,
      
    },
    {
      name: 'Aprl',
      "Active User": 2780,
     
    },
    {
      name: 'May',
      "Active User": 1890,
   
    },
    {
      name: 'Jun',
      "Active User": 2390,
  
    },
    {
      name: 'Jul',
      "Active User": 3490,
 
    },
    {
      name: 'Aug',
      "Active User": 4490,
 
    },
    {
      name: 'Sep',
      "Active User": 3590,
 
    },
    {
      name: 'Oct',
      "Active User": 3990,
 
    },
    {
      name: 'Nov',
      "Active User": 2490,
 
    },
    {
      name: 'Dec',
      "Active User": 5790,
 
    },
    
  ];

  export  const productData = [
    {
      name: 'Jan',
      "sales": 4000,
      
    },
    {
      name: 'Feb',
      "sales": 3000,
    
    },
    {
      name: 'Mar',
      "sales": 2000,
      
    },
        
  ];


  export const userRows = [
    { id: 1, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 2, userName: 'Brad Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 3, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 4, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 5, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 6, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 7, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 8, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
    { id: 9, userName: 'John Snow',
      avatar : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      email : 'jhon@gmail.com',
      status : 'Active',
      transaction : '$125.56'
  },
   
  ];

  export const productsRows = [
    { id: 1, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 2, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 3, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 4, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 5, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 6, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 7, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 8, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
    { id: 9, name: 'Apple',
      img : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSexdGpZwW02ZZPNv_FJEQTC0CybXOBWP5TEg&usqp=CAU",
      stock : 123,
      status : 'Active',
      price : '$125.56'
  },
   
  ];