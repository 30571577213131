import React from 'react'
import Tooltip1 from '../../../golbal/tooltip/tooltip';

export default function ProfTutor({data}) {
    const isPro = (() => {
        if (
          data?.moreInfo?.transactions?.commitmentRate > 0.9 &&
          data?.moreInfo?.transactions?.sessionCounter >= 5 &&
          data?.feedbackInfo?.rate >= 4.5 &&
          data?.feedbackInfo?.rates >= 5 &&
          // data?.moreInfo?.stList.length >5&&
          data?.moreInfo?.video?.[window.lang]?.youtube?.length > 10
        )
          return true;
        return false;
      })();
  return (
    <>{isPro && (
          <Tooltip1 icon={ <div className="prof-tag my-3">
          <i className="bi bi-stars font-14"></i> حرفه‌ای
        </div>} message={<div>
          <strong>نشان حرفه‌ای متامارس </strong>
          به اساتید با تجربه‌ای واگذار می‌شود که با امتیازات دریافتی و تعهد خود به دانشجویان،  ما را شگفت زده نموده‌اند.
        {/* <span className='cursor' onClick={e=> window.navigateHandle(`/${window.lcs}/prompt/tutor#open-col-commitmentScore`)}>(اطلاعات بیشتر)</span> */}
        </div>}/>
       
      )}</>
  )
}
