import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import apiStore from '../../../../../utils/apiStore';
import { CartContext } from '../../../../contexts/cartContext';
import api from '../../../../../utils/api';
import ColoredSpinner from '../../../../alret/spiners/coloredSpiner/coloredSpinner';


export default function TransOverview() {
  const {user}=useContext(CartContext)
  const[loading,setLoading]=useState(false)
  const effectRan=useRef(false)
  useEffect(()=> {
  (async()=>{
    if(effectRan.current) return
     setLoading(true);
    const response = await Promise.all([
      await fetchCurrencies(),
      await fetchData(),         
    ]);   
    setLoading(false);
    effectRan.current=true 
  })()},[])
  const [data1, setData]=useState([])
  const [cur, setCur]=useState([])

 


async function fetchCurrencies(){
  const response = await api.fetchCurrencies({
    token: user.token,
    acceptedView: true,
  });
 
  // console.log("response 00", response);
  if (response.error) {
    alert(response.error);
    setCur([]);
  } else setCur(response.data);
}

  async function fetchData(){
    const response =  await apiStore.getCountriesSales({token:user.token, type:'stackedBarChart'})
    console.log(response.data);
    if(response.error)return;
    setData(response.data)

  }

  
  


  

  const outputArray = [];
  data1.forEach((item) => {
    for (const country in item) {
      const currencies = item[country];
      // console.log("currencies",country, currencies);
      for (const currency in currencies) {
        const exRate=cur.find((cur2)=> cur2.currency===currency).exRate
        // console.log('exRate',country,currency,exRate);
        const sum = Object.values(currencies[currency]).reduce((acc, value) => acc + (value/exRate), 0);
       const index = outputArray.findIndex(out=>out.country ===country )
       if(index!==-1){    
        outputArray[index].CAD += sum
       }else{
        outputArray.push({
          country,        
          CAD: Math.round(sum),
        });
}
      }
    }
  });  
  console.log("outputArray",outputArray);
  return (
    <div>
      {loading&&<ColoredSpinner/>}      
      {outputArray.length>0&&<ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={outputArray.sort((a, b) => b.CAD - a.CAD)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={"country"} />
        <YAxis />
        <Tooltip />
        <Legend />       
          <Bar dataKey="CAD" fill="#8884d8" /> 
      </BarChart>
    </ResponsiveContainer>}  
    

<div className="container mt-4">
  {data1.map(da => (
    <div key={Object.keys(da)[0]} className="my-4">
      <h3>{Object.keys(da)[0]}</h3>
      <div className="d-flex">
        <div className="mr-3"><strong>Currency</strong></div>
        <div className="mr-3"><strong>Amount</strong></div>
        <RenderCurrencies da={da} country={Object.keys(da)[0]} />
      </div>
    </div>)
  )}
</div>

    
    </div> 
  );
}

function RenderCurrencies({ da, country }) {
  const currencyRows = [];

  for (let key in da) {
    const currencies = da[key];

    for (let currencyKey in currencies) {
      const currency = currencies[currencyKey];
      currencyRows.push(
        <div className="currency card mb-3" key={currencyKey}>
          <div className="card-header currency-name">{currencyKey}</div>
          {renderCurrencyDetails(currency)}
        </div>
      );
    }
  }

  function renderCurrencyDetails(currency) {
    const details = [];
    for (let stateKey in currency) {
      const state = stateKey;
      const amount = currency[stateKey];

      details.push(
        <div className="card-body currency-detail" key={stateKey}>
          <div className="state">{state}</div>
          <div className="amount">{amount}</div>
        </div>
      );
    }
    return details;
  }

  return (
    <div className="currencies">
      {currencyRows}
    </div>
  );
}







