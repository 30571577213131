import React from 'react'

export default function FormikErrRender({formik , field}) {
    const nested = field.split('.');
  
    if (nested.length === 1)  return (
        formik.touched[field] &&
        formik.errors[field] && (
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field]}
          </div>
        )
      );
    
    if (nested.length === 2) {
      const [field1, field2] = nested;
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] && (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2]}
          </div>
        )
      );
    }
    if (nested.length === 3) {     
      const [field1, field2,field3] = nested;      
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
         (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3]}
          </div>
        )
      );
    }
    if (nested.length === 4) {     
      // console.log('nested.length === 4',nested);
      const [field1, field2,field3,field4] = nested;      
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4] &&
         (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4]}
          </div>
        )
      );
    }
    if (nested.length === 5) {     
      // console.log('nested.length === 5',nested);
      const [field1, field2,field3,field4,field5] = nested;      
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4] &&
        formik.errors[field1][field2][field3][field4][field5] &&
         (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4][field5]}
          </div>
        )
      );
    }
    if (nested.length === 6) {     
      // console.log('nested.length === 6',nested);
      const [field1, field2,field3,field4,field5,field6] = nested; 
      // console.log("field1, field2,field3,field4,field5,field6",field1, field2,field3,field4,field5,field6);     
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4] &&
        formik.errors[field1][field2][field3][field4][field5] &&
        formik.errors[field1][field2][field3][field4][field5][field6] &&
         (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4][field5][field6]}
          </div>
        )
      );
    }
    if (nested.length === 7) {     
      // console.log('nested.length === 7',nested);
      const [field1, field2,field3,field4,field5,field6,field7] = nested; 
      // console.log("field1, field2,field3,field4,field5,field6,field7",field1, field2,field3,field4,field5,field6,field7);     
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4]&&
        formik.errors[field1][field2][field3][field4][field5] &&
        formik.errors[field1][field2][field3][field4][field5][field6] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7] &&
         (
            
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4][field5][field6][field7]}
          </div>
        )
      );
    }
    if (nested.length === 8) {     
      // console.log('nested.length === 8',nested);
      const [field1, field2,field3,field4,field5,field6,field7,field8] = nested; 
      // console.log("field1, field2,field3,field4,field5,field6,field7",field1, field2,field3,field4,field5,field6,field7);     
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4]&&
        formik.errors[field1][field2][field3][field4][field5] &&
        formik.errors[field1][field2][field3][field4][field5][field6] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7][field8] &&
         (
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4][field5][field6][field7][field8]}
          </div>
        )
      );
    }
    if (nested.length === 9) {
      const [field1, field2,field3,field4,field5,field6,field7,field8,field9] = nested; 
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        // formik.touched[field1][field2][field3] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        formik.errors[field1][field2][field3][field4]&&
        formik.errors[field1][field2][field3][field4][field5] &&
        formik.errors[field1][field2][field3][field4][field5][field6] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7][field8] &&
        formik.errors[field1][field2][field3][field4][field5][field6][field7][field8][field9] &&
         (
          <div className="error-message">
            <i className="bi bi-exclamation-triangle-fill mx-2 error-icon"></i>
            {formik.errors[field1][field2][field3][field4][field5][field6][field7][field8][field9]}
          </div>
        )
      );
    }
  
    return <div>yes</div>;
}
