import { Link } from "react-router-dom";
import "./featuredInfo.css";
import { ArrowBack, ArrowDownwardTwoTone, ArrowUpwardOutlined } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import apiUsers from "../../../../../utils/apiUsers";
import ToastNote from "../../../../golbal/notifications/toastNote";

import renderData from "../../../../../utils/renderData";
import { CircularProgress } from "@mui/material";

import PieChartCom from "../chart/pieChart/pieChart";

export default function FeaturedInfo({ user }) {
  const [loading, setLoading] = useState({ points: false });
  const [data, setData] = useState({ groupedPointData: [],changedPoints:0 });
  const messageRef = useRef(null);
  console.log(data);

  useEffect(()=> {
  (async()=>{
    setLoading((pre) => ({ ...pre, changedPoints: true }));
    const response = await apiUsers.changedPoints({
      id: user._id,
      token: user.token,
    });
    setLoading((pre) => ({ ...pre, changedPoints: false }));

    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    setData((pre) => ({ ...pre, changedPoints: response.data.changedPoints }));

  })()
  },[])

  const GroupedPointsHandle = async () => {
  document.getElementById("pointsModalTrigger").click(); // Get the modal element

  if(data.groupedPointData.length>0) return;

  setLoading((pre) => ({ ...pre, points: true }));
  const response = await apiUsers.GroupedPoints({
    id: user._id,
    token: user.token,
  });
  setLoading((pre) => ({ ...pre, points: false }));

    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });

    setData((pre) => ({ ...pre, groupedPointData: response.data }));
  };
  function ShowChange(props){   
    return props.data >  0 ? <ArrowUpwardOutlined  className="featuredIcon" /> : props.data<0 ? <ArrowDownwardTwoTone className="featuredIcon negative" />:<ArrowBack className="featuredIcon zero" />
  }
  return (
    <div className="featured">
      <ToastNote messageRef={messageRef} />
      <div className="featuredItem">
        <span className="featuredTitle">امتیاز ها</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney" onClick={GroupedPointsHandle}>
            {user?.points}pts
          </span>
          <span className="featuredMoneyRate">            
            {loading.changedPoints? <CircularProgress color="inherit" size="20px" /> : <span>{data.changedPoints}
            <ShowChange data={data.changedPoints}/>
            </span> } 
          
                 
          </span>
        </div>
        <span className="featuredSub">Compared to Last Month</span>
        <div className="mb-3 pt-1 text-end ">
          <Link
            className=""
            data-bs-toggle="modal"
            to="#pointsModal"
            onClick={GroupedPointsHandle}
            id="pointsModalTrigger"
          >
            جزئیات بیشتر
          </Link>
        </div>
        <div
          className="modal fade"
          id="pointsModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel3"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div>
                  <h4>امتیاز به تفکیک</h4>
                  {loading.points ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    data.groupedPointData.map((gPoint, i) => {
                      const group = renderData.pointsGroups.find(
                        (group) => group.name === gPoint.name
                      );
                      return (
                        <div key={i}>
                          {group?.[window.lang]} || {gPoint.value} امتیاز
                        </div>
                      );
                    })
                  )}

                  <PieChartCom data={data.groupedPointData}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Sales</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">$22,415</span>
          <span className="featuredMoneyRate">
            -1.5 <ArrowDownwardTwoTone className="featuredIcon negative" />
          </span>
        </div>
        <span className="featuredSub">Compared to Last Month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Cost</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">$12,415</span>
          <span className="featuredMoneyRate">
            12.5 <ArrowUpwardOutlined className="featuredIcon" />
          </span>
        </div>
        <span className="featuredSub">Compared to Last Month</span>
      </div>
    </div>
  );
}
