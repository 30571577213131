import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import JobsNav from '../navabar/jobsNav';

export default function JobsOut() {
  const [pubData] = useOutletContext();
  return (
    <div>
      {/* BusinessOutlet */}
      <JobsNav/>
    <Outlet context={[pubData]}/>
    </div>
  )
}
