import { NavLink } from "react-router-dom";

export default function UserDashMediaNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  return (
    <div className="dashboardNavigator d-flex">
      <NavLink
        to={`/${window.lcs}/dashboard/user/media/register`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        افزودن شبکه اجتماعی{" "}
      </NavLink>
      <NavLink
        to={`/${window.lcs}/dashboard/user/media/list`}
        className={`sidebarListItem m-1 link  ${({ isActive }) => {
          return isActive && "active";
        }}`}
      >
        صفحه های من
      </NavLink>
      {showEdit === "edit" && (
        <NavLink
          to={`/${window.lcs}/dashboard/user/media/edit`}
          className={`sidebarListItem m-1 link  ${({ isActive }) => {
            return isActive && "active";
          }}`}
        >
          ویرایش شبکه اجتماعی{" "}
        </NavLink>
      )}
    </div>
  );
}
