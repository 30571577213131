export default function ShowAddress({ address }) {
  const addressComp = ["state", "city"];
  const add = [];
  addressComp.forEach((component, i) => {
    address?.[component].length > 0 &&
      add.push(
        <li key={component}>
          {i !== 0 && ", "}
          {window.capitalize(address[component])}
        </li>
      );
  }); 
  return (
    <div>
  
      <ul className="group-list-item2  small-gray-font mb-3 mt-1 d-flex justify-content-end align-items-center  px-0 font-10">
        {add.length > 0 && (
          <li >
                <img
                      // width="25px"
                      style={{width:"25px"}}
                      className="ms-2"
                      src={`/img/flags/${address?.countryShort?.toLowerCase()}.svg`}
                      alt={address?.countryShort}
                      title={window.capitalize(address?.country)}
                    />
            {/* <i className="bi bi bi-geo-alt mx-2"></i> */}
          </li>
        )}
        {add}
      </ul>
    </div>
  );
}
