import { Outlet, useOutletContext } from "react-router-dom";
import PlansX4 from "../../golbal/sliders/plansX4.jsx";
import BusSmallCardSlider from "../../golbal/sliders/busCardSliderS1.jsx";
import MainSidebar from "../media/components/mainSidebar.jsx";


export default function BlogOut() {
  const [pubData] = useOutletContext();


  return (
    <div className="container pt-0">      
      <section className="mx-auto my-0 special-carousel ">
        {/* <!-- ======= Blog Section ======= --> */}
        <section className="blog  pt-3">
          <div className="row g-5 px-3">
            <Outlet context={[pubData]} />
            <div className="col-lg-4  sidebar-all align-items-center text-center  p-md-4 p-1">
                <MainSidebar section='blog' />
            </div>
          
          
          </div>
        </section>
        <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">معرفی ویژه</span>
            </div>
        {/* <!-- معرفی ویژه --> */}
       
          <PlansX4 plansX4={pubData.ads?.plansX4} page="blog" />
       

        <div className="divider2 ">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">برگزیدگان</span>
        </div>
        
          <BusSmallCardSlider plansS1={pubData?.ads?.plansS1} page="blog" />
        

        {/* <!-- ======= Blog Section ======= --> */}
      </section>
      <div>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossOrigin="anonymous"
        ></script>

        {/* <!-- Option 2: Separate Popper and Bootstrap JS -->
      <!-- */}
        <script
          src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js"
          integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js"
          integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF"
          crossOrigin="anonymous"
        ></script>
        {/* -->  */}
      </div>
      {/* <!-- end of***JavaScript*** --> */}

      {/* <!-- ********* FOOTER **********************************  -->
  
  
  {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
