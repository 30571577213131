import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/apiDemy";
import ToastNote from "../../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
import MyIcons from "../../../../golbal/icons/myIcons";

export default function TutorList({ access }) {
  const { user } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [user2, setUser2] = useState({ _id: user._id });
  const [data, setData] = useState({});
  const messageRef = useRef(null);

  // const access = window.location.pathname.split('/').slice(5,6).join('')!=='user'?'admin':'user'
  const deleteHandle = async (id) => {
    if (window.confirm("I am agree to remove this profile permanently.") !== true || access!=="admin")
      return;
    // console.log('access',access)
    const response = await api.deleteTutor({
      token: user.token,
      id,
      action: "delete",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Delete action is completed successfully.",
    });     
      const newData = data.filter((da) => da._id !== id);
      setData(newData);

  };
  const inactiveHandle = async (id) => {
    if (window.confirm("I am agree to inactive this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "inactive",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };
  const activationHandle = async (id) => {
    if (window.confirm("I am agree to activate this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "active",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "Inactive action is completed successfully.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };
  const completeHandle = async (id) => {
    if (window.confirm("I am agree to complete this job posting") !== true)
      return;
    const response = await api.changeStatus({
      token: user.token,
      id,
      action: "complete",
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "complete action is successfull.",
    });

    const newData = data.map((da) => (da._id === id ? response.data : da));
    setData(newData);
  };

  useEffect(() => {
    fetchListData();
  }, [window.location.pathname]);

  async function fetchListData() {
    setLoading(true);
    const response = await api.fetchTutorList({
      token: user.token,
      access: access,
      id: user2._id,
    });
    setLoading(false);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  const columns = [
    {
      field: "action",
      headerName: "عملیات",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <Link to={`/${window.lcs}/demy/tutor/page/${params.row._id}`}>
              <MyIcons icon="display" />
            </Link>
            <Link
              to={`/${window.lcs}/dashboard/demy/edittutor/${params.row._id}`}
              state={{ post: params.row }}
            >
              <MyIcons icon="edit" />
            </Link>
            <div title="پاک کردن" onClick={() => deleteHandle(params.row._id)}>
              <MyIcons icon="delete2" />
            </div>
            {/* <DeleteOutline className='userListDelete' onClick={()=>deleteHandle(params.row._id)}/>          */}
          </div>
        );
      },
    },
  
    {
      field: "email",
      headerName: "Email",
      width: 100,
      // renderCell: (params) => <div>{params.row.moreInfo?.status}</div>,
    },
    {
      field: "img",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            // to={`/${window.lcs}/demy/tutor/page/${params.row._id}`}
            // state={params.row}
          >
            <img
              src={params.row.moreInfo.img}
              alt={params.row.name?.[window.lang]}
              className="userListImg"
              loading="lazy"
            />
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "نام استاد",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            // to={`/${window.lcs}/demy/tutor/page/${params.row._id}`}
            // state={params.row}
          >
            <div title={params.row?.name?.[window.lang]}>
              {params.row?.name?.[window.lang]}
            </div>           
          </div>
        );
      },
    },
    {
      field: "moreInfo.address",
      headerName: "آدرس",
      width: 200,
      renderCell: (params) => (
        <div dir="ltr">
          <span title={params.row.moreInfo?.address?.city}>
            {params.row.moreInfo?.address?.city},
          </span>
          <span title={params.row.moreInfo?.address?.state}>
            {params.row.moreInfo?.address?.state},
          </span>
          <span title={params.row.moreInfo?.address?.countryShort}>
            {params.row.moreInfo?.address?.countryShort}
          </span>
        </div>
      ),
    },

    {
      field: "moreInfo.title",
      headerName: "وضعیت",
      width: 60,
      renderCell: (params) => <div>{params.row.moreInfo?.isActive?"فعال":"غیر فعال"}</div>,
    },
  ];

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading && <ColoredSpinner />}
      {!data.length ? (
        <div>
          داده ای پیدا نشد. پس از اطمینان از وجود داده، دوباره تلاش کنید.
        </div>
      ) : (
        <DataGrid
          getRowId={(row) => row._id}
          rows={data}
          components={{
            Toolbar: GridToolbar,
          }}
          disableSelectionOnClick
          columns={columns}
          pageSize={[10]}
          rowsPerPageOptions={[1]}
          checkboxSelection
        />
      )}
      
    </div>
  );
}
