import { Route } from "react-router-dom";
import BusinessFilters from "../components/screens/business/filters/businessFilters";
import BusinessHome from "../components/screens/business/home/businessHome";
import BusinessRegister from "../components/screens/business/register/businessRegister";
import BusinessPage from "../components/screens/business/page/businessPage";
import BusTerms from "../components/screens/business/busTerms";
export default function businessRoutes() { 
  return (
    <>
      <Route path="" element={<BusinessHome />} />
      <Route path="terms" element={<BusTerms />} /> 
      <Route path="register" element={<BusinessRegister action="register"/>} />
      <Route path="page/:id/:title?" element={<BusinessPage />} />
       <Route path="filters" element={<BusinessFilters />}/>
       </>
  );
}
