export default new (class {
  programLevel = [
    "Bachelor's/First professional degree",
    "Master's degree",
    "Undergraduate level certificate/diploma",
    "Doctorate",
    "Graduate level certificate/diploma",
    "License undergraduate",
    "Master's qualifying year",
  ];

  studyLevel = ["Under Graduate", "Graduate"];
  enrolTypes = ["Full-time", "Part-time"];
  centerTypes = [
    "College",
    "University",
    "Secondary-school",
    "Elementory-school",
  ];
  studyArea = [
    "Accounting",
    "Adult and continuing education and teaching",
    "Anthropology, general",
    "Applied mathematics, general",
    "Art history, criticism and conservation",
    "Biochemistry",
    "Biology/biological sciences, general",
    "Biomedical sciences, general",
    "Biomedical/medical engineering",
    "Business administration and management, general",
    "Business/commerce, general",
    "Chemical engineering",
    "Chemistry, general",
    "City/urban, community and regional planning",
    "Civil engineering, general",
    "Classical, ancient Mediterranean and Near Eastern studies and archaeology",
    "Computer engineering, general",
    "Computer science",
    "Computer software engineering",
    "Counselling psychology",
    "Criminology",
    "Curriculum and instruction",
    "Development economics and international development",
    "Digital communication and media/multimedia",
    "Drama and dramatics/theatre arts, general",
    "Economics, general",
    "Education, general",
    "Educational leadership and administration, general",
    "Electrical and electronics engineering",
    "Elementary education and teaching",
    "Engineering, general",
    "Engineering, other",
    "English language and literature, general",
    "Entrepreneurship/entrepreneurial studies",
    "Environmental science",
    "Environmental studies",
    "Environmental/environmental health engineering",
    "Environmental/natural resources management and policy, general",
    "Ethnic, cultural minority, gender, and group studies, other",
    "Exercise science and kinesiology",
    "Film/cinema/video studies",
    "Finance, general",
    "Fine/studio arts, general",
    "French language and literature, general",
    "General studies",
    "Geographic information science and cartography",
    "Geography",
    "Geology/Earth science, general",
    "German language and literature",
    "Health professions and related programs, other",
    "Health Services/Allied Health/Health Sciences, General",
    "Health/health care administration/management",
    "History, general",
    "Human resources management/personnel administration, general",
    "Indigenous education",
    "Indigenous peoples of the Americas studies",
    "Information science/studies",
    "International business/trade/commerce",
    "International/globalization studies",
    "Labour and industrial relations",
    "Language interpretation and translation",
    "Law (LLB, JD, BCL)",
    "Legal studies",
    "Liberal arts and sciences/liberal studies",
    "Linguistics",
    "Management information systems, general",
    "Marketing/marketing management, general",
    "Mass communication/media studies",
    "Mathematics, general",
    "Mechanical engineering",
    "Medical science/scientist (MS, MSc, PhD)",
    "Multidisciplinary/interdisciplinary studies, other",
    "Music performance, general",
    "Music theory and composition",
    "Music, general",
    "Neuroscience",
    "Nursing science (MS, MSc, PhD)",
    "Organizational leadership",
    "Philosophy",
    "Physics, general",
    "Political science and government, general",
    "Project management",
    "Psychology, general",
    "Public administration, general",
    "Public health, general (BPH, MPH, DPH)",
    "Public policy analysis, general",
    "Registered nursing/registered nurse (RN, ASN, BSN, BScN, MSN, MScN)",
    "Religion/religious studies, general",
    "Secondary education and teaching",
    "Social sciences, other",
    "Social work, general",
    "Sociology, general",
    "Spanish language and literature",
    "Special education and teaching, general",
    "Speech communication and rhetoric",
    "Sports, kinesiology, and physical education/physical fitness, general",
    "Statistics, general",
    "Sustainability studies",
    "Theology/theological studies",
    "Women's studies",
  ];

  shool = [
    "Accounting",
    "Adult and continuing education and teaching",
    "Anthropology, general",
    "Applied mathematics, general",
    "Art history, criticism and conservation",
    "Biochemistry",
    "Biology/biological sciences, general",
  ];
  university = [
    "Biomedical sciences, general",
    "Biomedical/medical engineering",
    "Business administration and management, general",
    "Business/commerce, general",
    "Chemical engineering",
    "Chemistry, general",
    "City/urban, community and regional planning",
    "Civil engineering, general",
    "Classical, ancient Mediterranean and Near Eastern studies and archaeology",
    "Computer engineering, general",
    "Computer science",
  ];
  resideStatus = ["Citizen", "International student", "Permanent resident"];

  availableDay = [
    { name: "anyDay", en: "Any day", fa: "هر روز", fr: "" } ,
    { name: "weekdays", en: "Weekdays", fa: "روزهای هفته", fr: "" },
    { name: "weekends", en: "Weekends", fa: "آخر هفته ", fr: "" },
    {
      name: "monWedFri",
      en: "Mon Wed Fri",
      fa: "دوشنبه چهارشنبه جمعه",
      fr: "",
    },
    { name: "tuesThurs", en: "Tues/Thurs", fa: "سه‌شنبه  پنج‌شنبه", fr: "" },
    { name: "overtime", en: "Overtime", fa: "اضافه‌کاری", fr: "" },
    {
      name: "saturdayToThursday",
      en: "Saturday to Thursday",
      fa: "شنبه تا پنجشنبه",
      fr: "",
    },
    {
      name: "saturdayToWednesday",
      en: "Saturday to Wednesday",
      fa: "شنبه تا چهارشنبه",
      fr: "",
    },
  ];
  availableTime = [
    { name: "anyTime", en: "Any Time", fa: "هر زمان", fr: "" },
    { name: "morning", en: "in the morning", fa: "صبح", fr: "" },
    { name: "afternoon", en: "in the afternoon", fa: "ظهر", fr: "" },
    { name: "evening", en: "in the evening", fa: "عصر", fr: "" },
  ];
  tutorLevel = [
    { name: "school", en: "School", fa: "دبستان | دبیرستان", fr: "" },
    { name: "university", en: "University", fa: "کالج | دانشگاه", fr: "" },
    { name: "all", en: "All Levels", fa: "زمینه تدریس", fr: "" },
  ];
  distances = [
    { name: "5Min", en: "Up to 5 Minutes away ", fa: "۵ دقیقه فاصله", fr: "" },
    {
      name: "10Min",
      en: "Up to 10 Minutes away ",
      fa: "۱۰ دقیقه فاصله",
      fr: "",
    },
    {
      name: "15Min",
      en: "Up to 15 Minutes away ",
      fa: "۱۵ دقیقه فاصله",
      fr: "",
    },
    {
      name: "20Min",
      en: "Up to 20 Minutes away ",
      fa: "۲۰ دقیقه فاصله",
      fr: "",
    },
    {
      name: "25Min",
      en: "Up to 25 Minutes away ",
      fa: "۲۵ دقیقه فاصله",
      fr: "",
    },
    {
      name: "30Min",
      en: "Up to 30 Minutes away ",
      fa: "۳۰ دقیقه فاصله",
      fr: "",
    },
    {
      name: "45Min",
      en: "Up to 45 Minutes away ",
      fa: "۴۵ دقیقه فاصله",
      fr: "",
    },
    { name: "1hour", en: "Up to 1 hour away ", fa: "۱ ساعت فاصله", fr: "" },
    {
      name: "1hour",
      en: "More than 1 hour away ",
      fa: " بیشتر از ۱ ساعت فاصله",
      fr: "",
    },
  ];
  charges = [
    { name: "no", en: "for no additional charge", fa: "بدون هزینه", fr: "" },
    { name: "flat", en: "for a flat fee", fa: "با مبلغ مشخص", fr: "" },
    {
      name: "regularRate",
      en: "at my regular hourly rate",
      fa: "براساس نرخ تدریس",
      fr: "",
    },
  ];
})();
