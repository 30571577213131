import { useContext, useEffect } from "react";
import { CartContext } from "../../../contexts/cartContext";
import tools from "../../../../utils/tools";
import renderData from "../../../../utils/renderData";
import MetaDecorator from "../../../../utils/metaDecorator";
import { Link } from "react-router-dom";

export default function Guides() {
  const { user } = useContext(CartContext);
  useEffect(() => {
    tools.scrollToAnchor();
  }, []);

  const structuredData = [
    ...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: "راهنما",
      description: "راهنمای ثبت نام بعنوان کاربر",
      url: "https://www.metamarce.com/fa/ca/qc/prompt/guides",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/prompt/guides`,
              name: `راهنما`,
              description:
                "راهنمای ثبت نام بعنوان کاربر و افزودن کسب و کار رایگان",
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title: "راهنمای متامارس",
    description: "راهنمای متامارس",
    section: " متامارس",
    url: `https://www.metamarce.com/${window.lcs}/prompt/guides`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/prompt/guides`,
    img: "https://www.metamarce.com/img/illustration/setup-wizard-animate.svg",
    type: "webpage",
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <div className="my-header m-0">
        <div className="bg-image fixed-bg-media bg-contact-us"></div>

        <div className=" p-3 pb-0 px-5">
          <h1 className="mx-auto header-firstpage titr1 font-40 text-start">
            راهنمای متامارس
          </h1>
          <h3
            className="mx-auto header-firstpage titr3 text-start"
            style={{ color: "#60412b" }}
          >
            {/* راهنمای سوشیال مدیای متامارس */}
            {/* منتظر شنیدن دیدگاه‌های شما هستیم! */}
            پلی بین پارسی‌زبانان سراسر دنیا
          </h3>
        </div>

        <img
          src="/img/homePage/wave-haikei-3.svg"
          className=" p-0 pt-2 w-100 overlay-b"
          alt="homePage01"
          loading="lazy"
        />
      </div>

      <div className="px-md-5 px-3">
        <div className="row px-md-5">
          <div className="col-md-8 font-18 px-md-4 d-flex flex-column align-items-start justify-content-center">
            <p className="titr7"> راهنمای متامارس</p>
            <a className="my-2 " href="#account-com">
              <i className="bi bi-arrow-left-circle font-20 me-2"></i>ساخت حساب
              کاربری در متامارس (کامپیوتر)
            </a>

            {user?.roles && (
              <a className="my-2 " href="#bus-com">
                <i className="bi bi-arrow-left-circle font-20 me-2"></i>افزودن
                کسب و کار در متامارس (کامپیوتر)
              </a>
            )}

            <a className="my-2 " href="#account-mob">
              <i className="bi bi-arrow-left-circle font-20 me-2"></i>ساخت حساب
              کاربری در متامارس (موبایل)
            </a>
            {user?.roles && (
              <a className="my-2 " href="#bus-mob">
                <i className="bi bi-arrow-left-circle font-20 me-2"></i>افزودن
                کسب و کار در متامارس (موبایل)
              </a>
            )}
            <Link className="my-2" to={`/${window.lcs}/prompt/tutor`}>
              <i className="bi bi-arrow-left-circle font-20 me-2"></i>راهنما و
              قوانین آکادمی متامارس (متادمی)
            </Link>
            {user?.roles?.includes(1000) && (
              <Link className="my-2" to={`/${window.lcs}/prompt/affiliation`}>
                <i className="bi bi-arrow-left-circle font-20 me-2"></i>راهنما و
                قوانین لینک معرفی و دریافت پورسانت(Affiliation)
              </Link>
            )}
            {/* <Link className="my-2" to={`/${window.lcs}/prompt/busSchedule`}>
              <i className="bi bi-arrow-left-circle font-20 me-2"></i>راهنمای راه اندازی و رزرو قرار ملاقات ها در کسب و کارها 
              </Link> */}
          </div>
          <div className="col-md-4 text-center px-2 ">
            <div style={{ position: "relative" }}>
              {/* <div className="titr9 text-center dir="ltr"">Welcome to Metamarce!</div> */}
              <img
                src="/img/illustration/setup-wizard-animate.svg"
                className=" mb-3 px-0 w-100"
                alt="homePage"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="divider2 " id="account-com">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">
            ساخت حساب کاربری در متامارس (کامپیوتر)
          </span>
        </div>

        <div
          className="row row-cols-md-2 row-cols-1  "
          style={{ backgroundColor: "white" }}
        >
          <div className="col p-5 ">
            <div className="card h-100">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g1.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body " style={{ position: "relative" }}>
                <div className="number-guide"> ۱</div>

                <p className="card-text mt-4">
                  به صفحه نخست متامارس خوش اومدید. از این جا می‌تونید زبان و
                  کشور مورد نظرتون رو انتخاب کنید. (در حال حاضر وبسایت تنها به
                  زبان فارسی در دسترسه. اما در آینده نزدیک به زبان‌های دیگر هم
                  قابل دسترس خواهد بود.)
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card h-100">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g2.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۲</div>
                <p className="card-text mt-4">
                  برای استفاده بهتر از وبسایت و امکانات اختصاصی مربوط به محل
                  زندگیتون، از اینجا استان مورد نظرتون رو انتخاب کنید. شما به
                  این گزینه همیشه (گوشه بالا و سمت چپ)دسترسی خواهید داشت و هر
                  زمان نیاز داشته باشید می‌تونید کشور یا استانتون رو تغییر بدید.
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g3.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۳</div>
                <p className="card-text mt-4">
                  برای ساخت حساب کاربری در متامارس گزینه
                  <strong> ورود | ثبت نام </strong>
                  (گوشه بالا و سمت راست) را فشار بدید.
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g4.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۴</div>
                <p className="card-text mt-4">
                  اگه در متامارس حساب کاربری دارید از این جا می‌تونید وارد شید.
                  اگر کاربر جدید هستید گزینه
                  <strong> هنوز عضو نشدم | ثبت نام </strong>
                  رو انتخاب کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g5.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۵</div>
                <p className="card-text mt-4">
                  شما می‌تونید به صورت مستقیم با استفاده از درگاه گوگل، فیسبوک
                  یا توییتر (x) یک حساب در متامارس داشته باشید. یا حساب کاربری
                  خودتون رو در متامارس بسازید. برای این کار شما نیاز به آدرس
                  ایمیل یا شماره تلفن همراه دارید تا کد تایید رو دریافت کنید. در
                  این حالت گزینه مورد نظرتون (ایمیل یا تلفن همراه) رو انتخاب
                  کنید و اطلاعات مورد نظر رو وارد کنید.
                  <strong>
                    کلمه عبور حداقل باید ۸ حرف و ترکیبی از اعداد و حروف لاتین
                    باشه.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g6.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۶</div>
                <p className="card-text mt-4">
                  ایمیل یا تلفن همراه خود را چک کنید و کد فعالسازی را این جا
                  وارد و تایید کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col p-5">
            <div className="card">
              <div className="card image-container image-container-gd">
                <img
                  src="/img/guide/g7.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۷</div>
                <p className="card-text mt-4">
                  شما یک حساب کاربری در متامارس دارید که با استفاده از مرحله ۴
                  می‌تونید وارد بشید.
                </p>
              </div>
            </div>
          </div>
        </div>
        {user?.roles && (
          <div>
            <div className="divider2 " id="bus-com">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">
                افزودن کسب و کار در متامارس (کامپیوتر)
              </span>
            </div>
            <div
              className="row row-cols-md-2 row-cols-1 "
              style={{ backgroundColor: "white" }}
            >
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b1.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۱</div>

                    <p className="card-text mt-4">
                      برای افزودن کسب و کارتون در متامارس ابتدا وارد حساب
                      کاربریتون بشید. سپس
                      <strong> افزودن کسب و کار </strong>
                      رو انتخاب کنید. این گزینه هم از منو و هم از پنل کاربری
                      قابل دسترسه.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b2.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۲</div>

                    <p className="card-text mt-4">
                      بخش‌های مختلف فرم رو پر کنید. برای پر کردن نشانی مطمئن
                      بشید که کشور و استان کسب و کارتون با آنچه که برای وبسایت
                      انتخاب کردید یکسانه. شما می‌تونید انتخاب کنید که آدرستون
                      روی وبسایت نمایش داده بشه یا خیر.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b3.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۳</div>

                    <p className="card-text mt-4">
                      استفاده از برچسب (تگ) ابزار بسیار مهمیه که به شما کمک
                      می‌کنه تا هم در فیلترهای متامارس، و هم در جستجوی گوگل بهتر
                      و در اولویت بالاتری دیده بشید. تا جایی که می‌تونید از
                      کلمه‌های کلیدی مربوط به کسب و کار و خدماتتون استفاده کنید.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b4.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۴</div>

                    <p className="card-text mt-4">
                      پس از افزودن کسب و کار، می‌رسیم به بخش مهم تایید هویت یا
                      ادعای مالکیت. اهمیت این بخش به این دلیله که نشون میده شما
                      به عنوان مالک کسب و کار این صفحه رو کنترل می‌کنید. برای
                      این کار یک کد به تلفن همراه شما (شماره تلفن مسئول کسب و
                      کار که در فرم وارد کردید) فرستاده میشه.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b5.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۵</div>

                    <p className="card-text mt-4">
                      گزینه دریافت پیامک رو بزنید و از درستی شماره تلفن مطمئن
                      بشید.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col p-5">
                <div className="card">
                  <div className="card image-container image-container-gd">
                    <img
                      src="/img/guide/b6.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۶</div>

                    <p className="card-text mt-4">
                      کد رو وارد و ثبت‌‌نام رو کامل کنید. بهتون تبریک می‌گم. شما
                      صفحه کسب و کارتون رو به نام خودتون در متامارس ثبت کردید.
                      <span>
                        <i className="bi bi-emoji-happy"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <br />
        <br />
        <div className="divider2 " id="account-mob">
           
          <svg
            className="text-dark-550 dark:text-white ml-2"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
            <circle
              cx="35"
              cy="16"
              r="8"
              fill="currentColor"
              fillOpacity="0.4"
            ></circle>
            <circle
              cx="20"
              cy="8"
              r="5"
              fill="currentColor"
              fillOpacity="0.7"
            ></circle>
            {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
          </svg>
          <span className="p-0 mb-0 ">
            ساخت حساب کاربری در متامارس (موبایل)
          </span>
        </div>

        <div className="row row-cols-md-4 row-cols-1 g-5">
          <div className="col ">
            <div className="card h-100">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma1.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body " style={{ position: "relative" }}>
                <div className="number-guide"> ۱</div>

                <p className="card-text mt-4">
                  به صفحه نخست متامارس خوش اومدید. از این جا می‌تونید زبان و
                  کشور مورد نظرتون رو انتخاب کنید. (در حال حاضر وبسایت تنها به
                  زبان فارسی در دسترسه. اما در آینده نزدیک به زبان‌های دیگر هم
                  قابل دسترس خواهد بود.)
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card h-100">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma2.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۲</div>
                <p className="card-text mt-4">
                  برای استفاده بهتر از وبسایت و امکانات اختصاصی مربوط به محل
                  زندگیتون، از اینجا استان مورد نظرتون رو انتخاب کنید. شما به
                  این گزینه همیشه (گوشه بالا و سمت چپ)دسترسی خواهید داشت و هر
                  زمان نیاز داشته باشید می‌تونید کشور یا استانتون رو تغییر بدید.
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card h-100">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma3.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۳</div>
                <p className="card-text mt-4">
                  برای ساخت حساب کاربری در متامارس گزینه
                  <strong> ورود | ثبت نام </strong>
                  (گوشه بالا و سمت راست) را فشار بدید.
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma4.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۴</div>
                <p className="card-text mt-4">
                  اگه در متامارس حساب کاربری دارید از این جا می‌تونید وارد شید.
                  اگر کاربر جدید هستید گزینه
                  <strong> هنوز عضو نشدم | ثبت نام </strong>
                  رو انتخاب کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma5.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۵</div>
                <p className="card-text mt-4">
                  شما می‌تونید به صورت مستقیم با استفاده از درگاه گوگل، فیسبوک
                  یا توییتر (x) یک حساب در متامارس داشته باشید. یا حساب کاربری
                  خودتون رو در متامارس بسازید. برای این کار شما نیاز به آدرس
                  ایمیل یا شماره تلفن همراه دارید تا کد تایید رو دریافت کنید. در
                  این حالت گزینه مورد نظرتون (ایمیل یا تلفن همراه) رو انتخاب
                  کنید و اطلاعات مورد نظر رو وارد کنید.
                  <strong>
                    کلمه عبور حداقل باید ۸ حرف و ترکیبی از اعداد و حروف لاتین
                    باشه.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma6.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۶</div>
                <p className="card-text mt-4">
                  ایمیل یا تلفن همراه خود را چک کنید و کد فعالسازی را این جا
                  وارد و تایید کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="col ">
            <div className="card">
              <div className="card image-container image-container-gdm">
                <img
                  src="/img/guide/ma7.jpg"
                  className="card-img-top"
                  loading="lazy"
                  alt="..."
                />
              </div>
              <div cclassName="card-body" style={{ position: "relative" }}>
                <div className="number-guide">۷</div>
                <p className="card-text mt-4">
                  شما یک حساب کاربری در متامارس دارید که با استفاده از مرحله ۴
                  می‌تونید وارد بشید.
                </p>
              </div>
            </div>
          </div>
        </div>
        {user?.roles && (
          <div>
            <div className="divider2 " id="bus-mob">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  --> */}
              </svg>
              <span className="p-0 mb-0 ">
                افزودن کسب و کار در متامارس (موبایل)
              </span>
            </div>
            <div className="row row-cols-md-4 row-cols-1 g-5">
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb1.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۱</div>

                    <p className="card-text mt-4">
                      برای افزودن کسب و کارتون در متامارس ابتدا وارد حساب
                      کاربریتون بشید. سپس
                      <strong> افزودن کسب و کار </strong>
                      رو انتخاب کنید. این گزینه هم از منو و هم از پنل کاربری
                      قابل دسترسه.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb2.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۲</div>

                    <p className="card-text mt-4">
                      بخش‌های مختلف فرم رو پر کنید. برای انتخاب موارد مختلف منوی
                      فرم را به راست یا چپ حرکت بدید.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb3.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۳</div>

                    <p className="card-text mt-4">
                      برای پر کردن نشانی مطمئن بشید که کشور و استان کسب و کارتون
                      با آنچه که برای وبسایت انتخاب کردید یکسانه. شما می‌تونید
                      انتخاب کنید که آدرستون روی وبسایت نمایش داده بشه یا خیر.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb4.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۴</div>

                    <p className="card-text mt-4">
                      استفاده از برچسب (تگ) ابزار بسیار مهمیه که به شما کمک
                      می‌کنه تا هم در فیلترهای متامارس، و هم در جستجوی گوگل بهتر
                      و در اولویت بالاتری دیده بشید. تا جایی که می‌تونید از
                      کلمه‌های کلیدی مربوط به کسب و کار و خدماتتون استفاده کنید.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb5.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۵</div>

                    <p className="card-text mt-4">
                      پس از افزودن کسب و کار، می‌رسیم به بخش مهم تایید هویت یا
                      ادعای مالکیت. اهمیت این بخش به این دلیله که نشون میده شما
                      به عنوان مالک کسب و کار این صفحه رو کنترل می‌کنید. برای
                      این کار یک کد به تلفن همراه شما (شماره تلفن مسئول کسب و
                      کار که در فرم وارد کردید) فرستاده میشه.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb6.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۶</div>

                    <p className="card-text mt-4">
                      گزینه دریافت پیامک رو بزنید و از درستی شماره تلفن مطمئن
                      بشید. دکمه «بفرست» رو بزنید. یک کد ۶ رقمی برای شما فرستاده
                      میشه.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card">
                  <div className="card image-container image-container-gdm">
                    <img
                      src="/img/guide/mb7.jpg"
                      className="card-img-top"
                      loading="lazy"
                      alt="..."
                    />
                  </div>
                  <div cclassName="card-body " style={{ position: "relative" }}>
                    <div className="number-guide"> ۷</div>

                    <p className="card-text mt-4">
                      کد رو وارد و ثبت‌‌نام رو کامل کنید. بهتون تبریک می‌گم. شما
                      صفحه کسب و کارتون رو به نام خودتون در متامارس ثبت کردید.
                      <span>
                        <i className="bi bi-emoji-happy"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
