import axios from "axios";

// import jwt from"jsonwebtoken"

export default new ( class { 
  getPointsDetails =async({token , id})=>{  
  try {
    const response = await axios.get(`/api/users/getpoints?id=${id}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
        }
  
    })
      
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
    }
  getWalletDetails =async({token , id})=>{  
  try {
    const response = await axios.get(`/api/users/getwallet?id=${id}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
        }
  
    })
      
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
    }
  GroupedPoints =async({token , id})=>{  
  try {
    const response = await axios.get(`/api/users/getpoints/grouped/type?id=${id}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
        }
  
    })
      
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
    }

    changedPoints =async({token , id})=>{ 

  try {
    const response = await axios.get(`/api/users/getpoints/grouped/changedPoints?id=${id}` ,
    {
      headers :{
        Authorization : `Bearer ${token}`,
        }                
    })
      
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
    }

    getAllUsers =async(token)=>{    try {
      const response = await axios.get(`/api/users/`, {headers:{
        Authorization:`Bearer ${token}`,
      }})    
    if(response.status!==200) throw new Error(response.data.message)
   
    return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.message} 
    } 
   }

   getAuthorList =async({token})=>{    try {
    const response = await axios.get(`/api/users/authorList`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error(response.data.message)
 
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 setUserInfo =  async({token , userInfo})=>{
  try {
    const response = await axios.put(`/api/users`,{userInfo}, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 
 setAuthorInfo =  async({id , token , authorInfo})=>{
  try {
    const response = await axios.post(`/api/users/author/${id}`,{authorInfo}, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }
 setMarketerInfo =  async({id , token , marketerInfo})=>{
  try {
    const response = await axios.post(`/api/users/marketer/${id}`,{marketerInfo}, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }


 setUserPhoto =  async(id , token , thumbnail)=>{
  try {
    const response = await axios.post(`/api/users`,{id,thumbnail}, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error(response.data.message)
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 getRolesInfo =  async(token)=>{
  try {
    const response = await axios.get(`/api/users/roles`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error(response.data.message)
  // console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 setUserRoles =  async(id , token , roles)=>{
  console.log(id , token , roles);
  try {
    const response = await axios.put(`/api/users/roles`,{id , roles}, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error(response.data.message)
  // console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 getAuthorInfo =  async({id , token})=>{
  try {
    const response = await axios.get(`/api/users/author/${id}`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  // console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }
 getMarketerInfo =  async({id , token})=>{
  try {
    const response = await axios.get(`/api/users/marketer/${id}`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  if(response.status!==200) throw new Error('خطا در بروز رسانی اطلاعات')
  // console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }

 getUserInfo =  async(id , token)=>{
  try {
    const response = await axios.get(`/api/users/${id}`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  // if(response.status!==200) throw new Error(response.data.message)
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }
 deleteUser =  async({id , token})=>{
  try {
    const response = await axios.delete(`/api/users/${id}`, {headers:{
      Authorization:`Bearer ${token}`,
    }})    
  // if(response.status!==200) throw new Error(response.data.message)
  console.log(response.data);
  return response.data
  } catch (error) {
    return  {error: error?.response?.data?.message || error.message} 
  } 
 }
  

 getChartData= async({token , type})=>{
  console.log('getChartData');
    try {
      const response = await axios.get(`/api/users/reports?type=${type}` , {
        headers:{
          Authorization : `Bearer ${token}`,
        }
      })
      console.log(response);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
        } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
    }
    }


                
            

})()