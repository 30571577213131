import { useState, useEffect, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import apiDemy from "../../../utils/apiDemy";
import { CircularProgress } from "@mui/material";
import CommentsRating from "../../comment/commentsRating";
import { CartContext } from "../../contexts/cartContext";
import renderData from "../../../utils/renderData";
import ToastNote from "../../golbal/notifications/toastNote";
import SocialMedias from "../business/components/socialMedias";
import ShareSocialMedias from "../business/components/shareSocialMedias";
import api from "../../../utils/api";
import tools from "../../../utils/tools";
import MetaDecorator from "../../../utils/metaDecorator";
import GoogleMap from "../../googleMap/googleMap";
import demyData from "../../../utils/demyData";
import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import apiJobs from "../../../utils/apiJobs";

export default function HidePage(props) {
  // console.log("props", props);
  const messageRef = useRef(null);
  // const navigate = useNavigate();
  const effectRan = useRef(false);
  const { user, signedUser } = useContext(CartContext);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({ spinner: false, save: false });
  const [request, setRequest] = useState({ email: "", message: "" });
  const repliesCounter = () => {
    const comments = data?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    console.log("saveHandler", data);
    const response = await api.saveHandler({
      id: data?._id,
      section: "ready",
      token: user.token,
      title: data.moreInfo?.title,
    });

    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 5);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    console.log("data", user?.saved?.data);
    signedUser(response.data);

    !user?.saved?.ready?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "مرکز آموزشی با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "مرکز آموزشی از لیست ذخیره حذف شد.",
        });
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      if (id?.length !== 24)
        return messageRef.current.showError({
          message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
        });
      const response = await apiJobs.readyDetails(id);
      if (response.error) return alert(response.error);
      setData(response.data);
      if (effectRan.current) return;
      effectRan.current = true;
    })();
  }, [id]);
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `آکادمی متامارس | ${data?.moreInfo?.title} در ${
    translations?.[window.lang] + " شهر " + data?.moreInfo?.address?.city
  } | ${data?.category?.subCat?.[window.lang]}`;
  const description =
    data?.moreInfo?.description?.substring(0, 100) +
    " | " +
    data?.moreInfo?.address?.municipality +
    " | " +
    data?.moreInfo?.address?.city;

  const structuredData = [
    ...renderData.structuredData,

    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: data?.moreInfo?.title,
      description: data?.coverSummary,
      url: window.location.href,
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "شروع",
              description: "متامارس، پلی بین پارسی‌زبانان سراسر دنیا",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `خانه`,
              description:
                "پاسخی برای صاحبان و جستجوگران کسب و کارها، فرصت های شغلی، اینفلوئنسر ها، تولید کنندگان محتوا و... !",
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/jobs/ready`,
              name: `آکادمی متامارس`,
              description:
                "دسترسی به منابع آموزشی به روز و دست اول در آکادمی متامارس ",
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": window.location.href,
              name: data?.moreInfo?.title,
            },
          },
        ],
      },
    },
  ];
  const metaTagsData = {
    title,
    description,
    section: "صفحه مدرس",
    url: `https://www.metamarce.com/${window.lcs}/jobs/ready/page/${data?._id}`,
    img: "https://www.metamarce.com/img/jobs/Hiring-rafiki.svg",
    type: "webpage",
  };
  function openGoogleMaps(destination) {
    if (!destination)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  }

  return (
    <div className="page-en">
      {loading.spinner && <ColoredSpinner />}
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />
      <ToastNote messageRef={messageRef} />
      {/* ****** cover ******** */}

      <div className="listing-hero-uni py-0" dir="ltr">
        <div className=" container2 p-0 ">
          <div className="row py-3 px-lg-5 g-3">
            <div className="col-lg-2 d-flex justify-content-lg-start justify-content-center">
              <div className="picture-container">
                <div className=" picture picture2">
                  <img
                    src={data?.moreInfo?.logo}
                    className="picture-src"
                    id="wizardPicturePreview"
                    title="mentor Logo"
                  />
                  {/* <input type="file" id="wizard-picture" className="" /> */}
                </div>
              </div>
            </div>

            <div className="col-lg-10 text-center px-0 d-flex flex-column align-items-center justify-content-start  ">
              <h1 className="uni-name-cover m-2 my-3">
                {/* className="p-0 mb-0   "> */}
                {data?.name}
              </h1>

              <div className=" d-flex ">
                {user?.roles?.includes(1000) && (
                  <Link
                    className="btn btn-primary share-cover-btn mx-2 font-12 py-1 "
                    to={`/${window.lcs}/dashboard/jobs/editready/${data?._id}`}
                  >
                    edit
                  </Link>
                )}
                {user?._id === data?.moreInfo?.history.createdBy._id && (
                  <Link
                    className="btn btn-primary share-cover-btn mx-2 font-12 py-1 "
                    to={`/${window.lcs}/dashboard/jobs/listrequests`}
                  >
                    پیامهای من
                    <span
                      style={{ background: "black", borderRadius: "50%" }}
                      title=" تمام پیام های من"
                    >
                      {data?.moreInfo?.requests.reduce((a, c) => 1 + a, 0)}
                    </span>
                  </Link>
                )}

                <button
                  className="btn btn-primary share-cover-btn mx-2 font-12 py-1"
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id="shareBtn"
                  data-bs-placement="top"
                  title="Share!"
                >
                  share
                  <i className="bi bi-share-fill mx-2"></i>
                </button>

                {/* <!-- Modal --> */}
                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lcs}/jobs/ready/page/${data?._id}`}
                  title={data?.name}
                  img={data?.moreInfo?.img}
                  _id={data?._id}
                />

                <button
                  className="btn btn-outline-secondary save-cover-btn mx-2 font-12 py-1"
                  onClick={saveHandler}
                >
                  save
                  {loading.save ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    <i
                      className={
                        user?.saved?.ready?.includes(data?._id)
                          ? "bi bi-bookmark-fill mx-1"
                          : "bi bi-bookmark mx-1"
                      }
                    ></i>
                  )}
                </button>

                <div>
                  <button
                    type="button"
                    className="btn btn-primary share-cover-btn m-2"
                    data-bs-toggle="modal"
                    data-bs-target={
                      user.roles?.includes(9999)
                        ? "#messageModal"
                        : "#exampleModalToggle"
                    }
                  >
                    {`تماس با  ${data?.name}`}

                    <span
                      style={{ background: "black", borderRadius: "50%" }}
                      title="تعداد پیام های فرستاده شده شما برای این مدرس"
                    >
                      {data?.moreInfo?.requests.reduce(
                        (a, c) => c._id === user._id && 1 + a,
                        0
                      )}
                    </span>
                  </button>

                  {/* <!-- Modal --> */}
                  <div
                    className="modal fade"
                    id="messageModal"
                    tabIndex="-1"
                    aria-labelledby="sendMessageModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="sendMessageModalLabel"
                          >
                            تماس با معلم
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-md-7">
                              <div>
                                <label htmlFor="emailCheck">
                                  مکاتبه شمااز ایمیل زیر انجام خواهد شد.
                                </label>
                                <input
                                  type="email"
                                  id="emailCheck"
                                  defaultValue={user.email}
                                  onChange={(e) =>
                                    setRequest((pre) => ({
                                      ...pre,
                                      email: e.target.value,
                                    }))
                                  }
                                />
                              </div>

                              <div>
                                <label htmlFor="sessionRequest">
                                  برای هماهنگی می توانید از باکس زیر استفاده
                                  نمائید.
                                </label>
                                <textarea
                                  name=""
                                  id="sessionRequest"
                                  cols="30"
                                  rows="10"
                                  onChange={(e) =>
                                    setRequest((pre) => ({
                                      ...pre,
                                      message: e.target.value,
                                    }))
                                  }
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-md-5 align-items-center my-5 display-none-md">
                              <img
                                src="/img/illustration/task-animate.svg"
                                alt="claim"
                                loading="lazy"
                                // style={{ verticalAlign: "center" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                            // data-bs-dismiss="modal"
                            onClick={sendRequestHandle}
                          >
                            بفرست
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary save-cover-btn ms-3"
                            data-bs-dismiss="modal"
                          >
                            بستن
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-3">
        <div className="row p-0 m-0">
          <div className=" col-lg-6  d-flex flex-wrap  justify-content-lg-start justify-content-center mt-3 px-lg-5">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/jobs/ready/filters?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {data?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/jobs/ready/filters?subCat=${
                data?.category?.subCat?.[window.lang]
              }&subCatId=${data?.category?.subCat?._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {data?.category?.subCat?.[window.lang]}
            </Link>
          </div>
          <div className=" col-lg-6   d-flex flex-wrap  justify-content-lg-end justify-content-center  mt-3 px-lg-5">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              ( {repliesCounter()} ) Comments
            </a>
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}
        <div className="row m-md-2 p-2 gx-5" dir="ltr">
          <div className="col-lg-8  order-2 order-lg-1">
            <div className="w-100 image-container image-container-gd rounded-top">
              <img src={data?.moreInfo?.img} alt={data?.name} />
            </div>

            <div className="listing-details-information px-md-5  p-3 mb-5">
              <div className=" divider-small divider-small-en border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/graduated.png"
                    className="arrow4 pe-2"
                    alt="info"
                  />
                </span>
                <span className="p-0 mb-0 ">مهارت ها</span>
              </div>

              <div className="text-justify mt-3">
                مهارت های زبانی
                <ul>
                  {data?.moreInfo?.languages?.map((lang, i) => (
                    <li key={i} className="">
                      {
                        renderData?.langsLevel?.find(
                          (lng) => lng.name === lang.level
                        )?.[window.lang]
                      }

                      <label
                        htmlFor={lang + i}
                        className="form-check-label px-1 "
                      >
                        {
                          renderData?.languages?.find(
                            (lng) => lng.name === lang.name
                          )?.[window.lang]
                        }
                      </label>

                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked
                        id={lang + i}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="text-justify mt-3">
                  توانایی آموزش در زمینه های:
                  <div>
                    {data?.moreInfo?.levelSub?.map((sub, i) => (
                      <div key={i}>
                        <strong>{sub.level}</strong>
                        <ul>
                          {sub.subjects.map((sub, i) => (
                            <li key={i} className="ms-2 blog-tag">
                              {sub}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div> */}
              <div className="text-justify mt-3">
                برچسب ها
                <ul className="mt-auto d-flex flex-wrap">
                  {data?.moreInfo?.tags.map((tag, i) => (
                    <li>
                      <Link
                        key={i}
                        id={tag}
                        onClick={(e) =>
                          window.scrollTo({ top: 0, smooth: true })
                        }
                        to={`?tag=${tag}`}
                        className="ms-2 blog-tag"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {data?.moreInfo?.education?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/graduated.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">Education</span>
                </div>

                <p className="text-justify mt-3">
                  {tools.linesSpliter(data?.moreInfo?.education)}
                </p>
              </div>
            )}

            {data?.moreInfo?.experience?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/graduated.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">Experience</span>
                </div>

                <p className="text-justify mt-3">
                  {tools.linesSpliter(data?.moreInfo?.experience)}
                </p>
              </div>
            )}

            {data?.moreInfo?.hobbies?.length > 0 && (
              <div className="listing-details-information px-md-5  p-3 mb-5">
                <div className=" divider-small divider-small-en border-0 mt-4">
                  <span>
                    <img
                      src="/img/bussiness-page/graduated.png"
                      className="arrow4 pe-2"
                      alt="info"
                    />
                  </span>
                  <span className="p-0 mb-0 ">Hobbies</span>
                </div>

                <p className="text-justify mt-3">
                  {tools.linesSpliter(data?.moreInfo?.hobbies)}
                </p>
              </div>
            )}
          </div>
          <div className=" col-lg-4 order-1 order-lg-2">
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/home-address.png"
                    className="arrow2 ms-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="mb-0 ">LOCATION</span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span>

                    <span className="addressBusPage">
                      {`
                    ${
                      data?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(data?.moreInfo?.address?.number) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.route?.length
                        ? window.capitalize(data?.moreInfo?.address?.route) +
                          ","
                        : ""
                    }
                    
                    ${
                      data?.moreInfo?.address?.postalCode
                        ? data?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                    </span>
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      data?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.city?.length
                        ? window.capitalize(data?.moreInfo?.address?.city) + ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.state?.length
                        ? window.capitalize(data?.moreInfo?.address?.state) +
                          ","
                        : ""
                    }
                    ${
                      data?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            data?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>
                {data?.moreInfo?.address?.displayAddress && (
                  <li
                    className="group-list-item pe-auto cursor"
                    onClick={() =>
                      openGoogleMaps(data?.moreInfo?.address?.googleAddress)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="32"
                      fill="currentColor"
                      className="bi bi-sign-turn-right mx-2 my-1"
                      viewBox="0 0 16 32"
                    >
                      <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                      <path
                        fillRule="evenodd"
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                      />
                    </svg>
                    <div className="map-dir">Get direction</div>
                  </li>
                )}
              </ul>

              {data?.moreInfo?.address?.displayAddress && (
                <div className="listing-details-map mt-3">
                  <GoogleMap data={data?.moreInfo?.address.coordinates} />
                </div>
              )}
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en border-0">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span className="p-0 mb-0 ">درباره {data?.name} </span>
              </div>
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {tools.formatPhoneNumber(data?.moreInfo?.phone ?? "")}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>
                  <a href={data?.moreInfo?.website}>
                    {data?.moreInfo?.website}
                  </a>
                </li>
                <li className="group-list-item">
                  <i className="bi bi-envelope mx-3"></i>
                  {data?.email}
                </li>
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={data?.moreInfo?.socialMedia} />

              {data?.moreInfo?.links?.map((link, i) => (
                <div className="mt-3" key={i}>
                  <strong>{link.section}</strong>
                  <ul className="d-flex flex-column">
                    {link.urls.map((url, i) => (
                      <li>
                        <a href={url.url} target="_blank">
                          {url.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="listing-details-map mt-3">
                {/* <GoogleMap data={data?.moreInfo?.address.coordinates} /> */}
              </div>
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small divider-small-en  border-0">
                <span>
                  <img
                    src="/img/bussiness-page/maple-leaf.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                  />
                </span>
                <span>Financial Details </span>
              </div>
              <p>
                {" "}
                <strong>دستمزد</strong>
                <div>
                  <div>
                    {
                      renderData.jobPeriod.find(
                        (sa) => sa.name === data?.moreInfo?.salary.period
                      )?.[window.lang]
                    }
                  </div>
                  {tools.showPrice(
                    data?.moreInfo?.salary?.min,
                    data?.moreInfo?.salary?.currency
                  ) +
                    "  تا " +
                    tools.showPrice(
                      data?.moreInfo?.salary?.max,
                      data?.moreInfo?.salary?.currency
                    )}
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <SliderPosts data={busSlider}/> */}
      {/* <BusCardSlider data={busSlider} /> */}
      {/* *****************comments************** */}

      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );
  async function sendRequestHandle(e) {
    if (!window.logined(9999)) return;
    console.log(request);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (request.message.length < 10)
      return messageRef.current.showError({
        message: " لطفا توضیحات بیشتری بنویسید.",
      });
    if (request.message.length > 1000)
      return messageRef.current.showError({
        message: " لطفا توضیحات را به صورت مختصر بنویسید.",
      });
    if (request.email.length && !emailRegex.test(request.email))
      return messageRef.current.showError({
        message: "فرمت ایمیل درست نمی باشد.",
      });

    e.target.setAttribute("disabled", true);

    setLoading((pre) => ({ ...pre, spinner: true }));
    const email = request.email.length ? request.email : user.email;
    const response = await apiDemy.reqTutor({
      token: user.token,
      data: {
        message: request.message,
        email,
        _id: data._id,
        lang: window.lang,
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 10000);
    if (response.error)
      return messageRef.current.showError({
        message: "خطا در انجام عملیات : " + response.error,
      });
    messageRef.current.showSuccess({
      message: (
        <div>
          <div>درخواست شما با موفقیت ثبت شد</div>
          <div>پاسخ استاد توسط {email} به شما اطلاع رسانی خواهد شد.</div>
        </div>
      ),
    });
    setData(response.data);
    var myModal = document.getElementById("messageModal"); // Get the modal element
    var modal = window.bootstrap.Modal.getInstance(myModal);
    modal.hide();
    document.querySelector("textarea").value = "";
    setRequest({ message: "", email: "" });
  }
}
