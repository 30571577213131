
export default function AddLinks({ formik }) {
    const addSection = () => {
      formik.setValues(prevValues => ({
        ...prevValues,
        links: [
          ...prevValues.links,
          { section: '', urls: [{ name: '', url: '' }] }
        ]
      }));
    };
  
    const removeSection = index => {
      formik.setValues(prevValues => ({
        ...prevValues,
        links: prevValues.links.filter((_, i) => i !== index)
      }));
    };
  
    const handleNameUrlChange = (sectionIndex, nameIndex, field, value) => {
      formik.setValues(prevValues => ({
        ...prevValues,
        links: prevValues.links.map((link, i) =>
          i === sectionIndex
            ? {
                ...link,
                urls: link.urls.map((url, j) =>
                  j === nameIndex ? { ...url, [field]: value } : url
                )
              }
            : link
        )
      }));
    };
  
    const addName = sectionIndex => {
      formik.setValues(prevValues => ({
        ...prevValues,
        links: prevValues.links.map((link, i) =>
          i === sectionIndex
            ? { ...link, urls: [...link.urls, { name: '', url: '' }] }
            : link
        )
      }));
    };
  
    const removeName = (sectionIndex, nameIndex) => {
      formik.setValues(prevValues => ({
        ...prevValues,
        links: prevValues.links.map((link, i) =>
          i === sectionIndex
            ? {
                ...link,
                urls: link.urls.filter((_, j) => j !== nameIndex)
              }
            : link
        )
      }));
    };
  
    return (
      <div className="">
        {formik.values.links.map((link, sectionIndex) => (
          <div className="mb-2"  key={sectionIndex}>
            Section :
            <input
              type="text"
              className="form-control"
              value={link.section}
              // onChange={e => handleSectionChange(sectionIndex, e)}
              {...formik.getFieldProps(`links[${sectionIndex}].section`)}
            />
            {link.urls.map((url, nameIndex) => (
              <div className="d-flex flex-row" key={nameIndex}>
                name : 
                <input
                  type="text"
                  value={url.name}
                  onChange={e =>
                    handleNameUrlChange(sectionIndex, nameIndex, 'name', e.target.value)
                  }
                />
                Url:
                <input              
                  type="text"
                  value={url.url}
                  onChange={e =>
                    handleNameUrlChange(sectionIndex, nameIndex, 'url', e.target.value)
                  }
                />
                {nameIndex > 0 && 
                <button className="deleteContact btn btn login-trigger3 btn-xs mx-2" onClick={() => removeName(sectionIndex, nameIndex)}>
                  <i
                  className="bi bi-x-circle-fill p-0 "
                  style={{ fontSize: "1.5em" }}
                ></i>
                </button>}
              </div>
            ))}
            <div className="btn btn-primary share-cover-btn mx-2" onClick={() => addName(sectionIndex)}>Add Name/URL</div>
            
            {sectionIndex > 0 && <div className="btn btn-primary share-cover-btn mx-2" onClick={() => removeSection(sectionIndex)}>Remove Section</div>}
         
          </div>
        ))}
        <div className="btn btn-primary share-cover-btn mx-2" onClick={addSection}>Add Section</div>
      </div>
    );
  }