import {
  GoogleMapsProvider,
  useGoogleMap,
} from "@ubilabs/google-maps-react-hooks";
import {
  GridAlgorithm,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useEffect, useRef } from "react";
import { useState } from "react";
// import api from "../../utils/apiBusiness";
import ToastNot from "../golbal/notifications/toastNote";

// import { CartContext } from "../contexts/cartContext";

import ReactDOMServer from "react-dom/server";
import MapBusinessCard from "../screens/business/components/mapBusinessCard";
import apiJobs from "../../utils/apiJobs";
import apiBusiness from "../../utils/apiBusiness";
import apiDemy from "../../utils/apiDemy";
import MapTutorCard from "../screens/business/components/mapTutorCard";
import tools from "../../utils/tools";

export default function Cluster(props) {
  // console.log("props.section",props.section);
  const api = props.section ==="job"?apiJobs:
   props.section ==="business"?apiBusiness:
   props.section ==="tutor"?apiDemy:
   props.section ==="center"?apiDemy:
   apiBusiness
  const messageRef = useRef(null);
  const effectRan = useRef(false);
  const [locations, setLocations] = useState([]);
  const [mapContainer, setMapContainer] = useState(null);
  const { itemsToShow, sort, pageNumber, ...rest } = props.filters;

  useEffect(() => {
    if (!effectRan.current) {
      fetchData();
      // console.log("useEffect");
    }
    effectRan.current = true;
  }, [props.filters]);
  const mapOptions = {
    zoom: 6,
    center: {
      lat: Number(props.state.lat) || 52.9399,
      lng: Number(props.state.lng) || -73.5491,
    },
  };

  return (
    <div>
      <ToastNot messageRef={messageRef} />
      <div>مورد {tools.dynamicNumber({number: locations.length,lang:window.lang})}تعداد نتایج </div>
      <GoogleMapsProvider
        googleMapsAPIKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        mapOptions={mapOptions}
        mapContainer={mapContainer}
        // onLoad={(map) => addMarkers(map)}
        onLoadMap={(map) => addMarkers(map)}
      >
        {locations.length > 0 && (
          <div
            id="map"
            ref={(node) => setMapContainer(node)}
            style={{ height: "100vh" }}
          />
        )}
      </GoogleMapsProvider>
    </div>
  );
  async function fetchData() {
    let getLocations="getLocations"
    if(props.section ==="tutor"||props.section ==="center")getLocations=`${props.section}getLocations`
    const response = await api[getLocations]({ filters: props.filters });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // data2= response.data.locations
    setLocations(response.data);
    // console.log("fetchData 01",response.data);
  }
  function addMarkers(map) {
    const infoWindow = new window.google.maps.InfoWindow();
    // console.log('locations',locations); 
    const markers = locations?.map((bus, i) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: bus?.moreInfo?.address?.coordinates?.lat,
          lng:bus?.moreInfo?.address?.coordinates?.lng,
        },
        icon: {
            url: bus?.mpreInfo?.photos?.logo||bus?.moreInfo?.logo|| bus?.moreInfo?.photos?.businessCard,
            scaledSize: new window.google.maps.Size(40, 40),
            // origin: new window.google.maps.Point(0, 0),
            //  anchor: new window.google.maps.Point(17, 34),
            
          },
        // label: {
        //   text:  bus.busName?.[window.lang]||bus.center,
        //   color: "rgba(255,255,255,0.9)",
        //   fontSize: "14px",
        //   fontWeight:"bold",
        //   position: 'absolute',
        //   labelOrigin: new window.google.maps.Point(20, 0),
        // },
        title: bus.busName?.[window.lang]||bus.center, // Add your desired title
        optimized: false, // Disable optimization to access the label's DOM
      });

      marker.addListener("click", () => {
        infoWindow.setPosition({
          lat:bus?.moreInfo?.address?.coordinates?.lat,
          lng:bus?.moreInfo?.address?.coordinates?.lng,
        });
        const html = (props.section ==="tutor"||props.section ==="center")?<MapTutorCard section={props.section} key={i} data={bus} url={`/${window.lcs}/demy/${props.section}/page/${bus._id}`}/>:<MapBusinessCard key={i} data={bus} />
        const htmlString = ReactDOMServer.renderToString(
          html
        );
        //  console.log("htmlString",htmlString);

        infoWindow.setContent(htmlString);
        // infoWindow.setContent(`
        // <div key=${i} className="info-window">
        // <h6>${bus.busName?.[window.lang]}</h6>
        // <img src=${bus.businessCard} alt=${bus.busName?.[window.lang]} width="60px" height="60px" />
        // <i className="bi bi-star-fill overal-stars">${bus.rate}</i>
        // <a href="page/${bus._id}">Visit business page</a>
        // </div>`)
        infoWindow.open({ map });
      });
      // window.addEventListener('click',()=> infoWindow.close({map}))
      return marker;
    });

    const markerCluster = new MarkerClusterer({
      markers,
      map,
      //  algorithm: new GridAlgorithm({})
      algorithm: new SuperClusterAlgorithm({ radius: 20 }),
    });
  }
}
