import { useContext, useEffect, useRef, useState } from "react";
import "./searchBar.css";
import { CartContext } from "../../../../../contexts/cartContext";
import apiUsers from "../../../../../../utils/apiUsers";

export default function SearchBar(props) {
  // console.log(props);
  const { user } = useContext(CartContext);
  const ulRef = useRef();
  // const effectRan = useRef(false)
  const inputRef = useRef();
  const [authors, setAuthors] = useState([]);
  const [options, setOptions] = useState([]);

  const onInputChange = (e) => {
    const newOptions = authors.filter((user) =>
      user.authorName?.toLowerCase()?.includes(e?.target?.value.toLowerCase())
    );
    setOptions(newOptions);
  };

  useEffect(() => {
    inputRef.current.addEventListener("click", (e) => {
      e.stopPropagation();
      if (ulRef?.current?.style) ulRef.current.style.display = "flex";
      // console.log(e);
      onInputChange(e);
    });
    document.addEventListener("click", (e) => {
      if (ulRef?.current?.style) return (ulRef.current.style.display = "none");
    });
  }, []);

  useEffect(() => {
    fetchUserList();
  }, []);

  async function fetchUserList() {
    const response = await apiUsers.getAuthorList({ token: user.token });
    if (response.error) return alert(response.error);
    setAuthors(response.data);
  }

  const authorHandler = (e) => {
    const authorInfo = e.currentTarget.id;
    // console.log(authorInfo);
    const author = {
      _id: authorInfo.split(",")[0],
      email: authorInfo.split(",")[2],
      authorName: authorInfo.split(",")[1],
    };
    console.log("author", author);
    props.formik.setValues((pre) => ({ ...pre, author }));
  };

  return (
    <div className="search-bar-dropdown">
      <input
        ref={inputRef}
        id="search-bar"
        type="text"
        className="form-control btn-search "
        placeholder={
          props.formik.values?.author.authorName !== undefined
            ? props.formik.values?.author?.authorName
            : "جستجو..."
        }
        onChange={onInputChange}
        autoComplete="off"
      />

      <ul ref={ulRef} id="result" className="list-group">
        {options?.map((option, i) => (
          <button
            onClick={(e) => {
              inputRef.current.value = option.authorName;
              authorHandler(e);
            }}
            key={i}
            id={[option._id, option?.authorName, option.email]}
            type="button"
            className="list-group-item list-group-item-action groupItems"
          >
            <span className="listOptions">{option.authorName}</span>|
            <span className="listOptions">{option.email}</span>
          </button>
        ))}
      </ul>
    </div>
  );
}
