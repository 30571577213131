export default function addErrClass({formik,field}){
    const nested = field.split('.');
    
    if (nested.length === 1)  return (
        formik.touched[field] &&
        formik.errors[field] && "error-red-border"
      );
    
    if (nested.length === 2) {
      const [outerField, innerField] = nested;
      return (
        formik.touched[outerField] &&
        formik.touched[outerField]?.[innerField] &&
        formik.errors[outerField] &&
        formik.errors[outerField]?.[innerField] && ("error-red-border" ));
    }  
    if (nested.length === 3) {
      const [field1, field2,field3] = nested;
      return (
        formik.touched[field1] &&
        formik.touched[field1][field2] &&
        formik.errors[field1] &&
        formik.errors[field1][field2] &&
        formik.errors[field1][field2][field3] &&
        
        ("error-red-border" ));
    }  
    return null;
  
  }
  
