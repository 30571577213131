import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import RatingManuel from "../../../comment/rating";
import apiEvent from "../../../../utils/apiEvent";
import CommentsRating from "../../../comment/commentsRating";
import { CartContext } from "../../../contexts/cartContext";
import GoogleMap from "../../../googleMap/googleMap";
import ToastNote from "../../../golbal/notifications/toastNote";
import { CircularProgress } from "@mui/material";
// import BusCardSlider from "../../../golbal/sliders/busCardSlider";
import api from "../../../../utils/api";
import tools from "../../../../utils/tools";
import QRCode from "qrcode.react";
import SocialMedias from "../../business/components/socialMedias";
import ShareSocialMedias from "../../business/components/shareSocialMedias";
import MetaDecorator from "../../../../utils/metaDecorator";

export default function EventPage(props) {
  const messageRef = useRef(null);
  const navigate = useNavigate();
  const { user, signedUser } = useContext(CartContext);
  const data = useLocation().state;
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const { id } = useParams();
  const [mainData, setMainData] = useState(
    props?.data?.preview ? props?.data : data?.data
  );

  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url), 200);
  }

  const [eventList, setEventList] = useState([]);
  const [busSlider, setBusSlider] = useState([]);
  console.log("mainData", mainData);
  const [loading, setLoading] = useState({
    spinner: false,
    loading2: false,
    loading3: false,
    loading4: false,
    save: false,
  });

  const repliesCounter = () => {
    const comments = mainData?.feedbackInfo?.comment?.comments;
    let count = 0;
    comments?.forEach((com) => {
      count += 1;
      com.reply.forEach((rep) => {
        count += 1;
        rep.replyToReply.forEach((rep2) => (count += 1));
      });
    });
    return count;
  };

  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    if (!mainData._id)
      return messageRef.current.showError({
        message: "این رویداد نامعتبر می باشد.",
      });
    setLoading((pre) => ({ ...pre, save: true }));
    e.target.setAttribute("disabled", true);
    // console.log("saveHandler", mainData);
    const response = await api.saveHandler({
      id: mainData._id,
      token: user.token,
      section: "event",
    });
    setLoading((pre) => ({ ...pre, save: false }));
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 10);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // user.saved.mainData.find((x) => x._id == mainData?._id);
    !user?.saved?.event?.includes(mainData?._id)
      ? messageRef.current.showSuccess({
          message: "رویداد با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "رویداد از لیست ذخیره حذف شد.",
        });

    signedUser(response.data);
  };

  useEffect(() => {
    if (!id) return;
    fetchEventDetails();
  }, [id]);
  const { lang, country, state, stateInfo } = tools.lcsn(
    window.location.pathname
  );
  const [filters, setFilters] = useState({
    lang,
    country,
    state,
    cities: [],
    mainCat: "",
    mainCatId: "",
    subCat: [],
    subCatId: [],
    searchKey: "",
    tag: "",
    validation: [],
    pageNumber: 1,
    itemsToShow: 15,
    sort: "default",
  });
  async function fetchEventDetails() {
    if (id?.length !== 24)
      return messageRef.current.showError({
        message: "شناسه صحیح نمی باشد،  دوباره وارد این صفحه شوید.",
      });
    const response = await apiEvent.fetchEventDetails({ id });
    if (response.error) return alert(response.error);
    setMainData(response.data);
    filterEvents(response.data.artistInfo.artistId);
  }
  async function filterEvents(artistId) {
    setLoading((pre) => ({
      ...pre,
      spinner: true,
    }));
    filters.artistId = artistId;
    const response = await apiEvent.filters({ filters });
    setLoading((pre) => ({
      ...pre,
      spinner: false,
    }));
    if (response.error) return alert(response.message);
    setEventList(response.data.data);
  }

  // async function fetchBusSlider(data) {
  //   setTimeout(async () => {
  //     // console.log("fetchBusSlider", data);
  //     const filters = {
  //       lang: window.lang,
  //       country: data.moreInfo.address.country,
  //       state: data.moreInfo.address.state,
  //       stateShort: data.moreInfo.address.stateShort,
  //       mainCatId: data.category.mainCat._id,
  //       subCatId: data.category.subCat._id,
  //       pageNumber: 1,
  //       itemsToShow: 12,
  //       sort: "dateB",
  //     };
  //     console.log(filters);
  //     setLoading((pre) => ({ ...pre, busSlider: true }));
  //     const responseSlider = await apiEvent.BusCardsSlider({ filters });
  //     setLoading((pre) => ({ ...pre, busSlider: false }));
  //     // console.log(responseSlider);
  //     if (responseSlider.error) return;
  //     setBusSlider(responseSlider.data);
  //   }, 200);
  // }

  // console.log(mainData);

  const openGoogleMaps = (destination) => {
    if (!destination || !mainData.moreInfo.address.displayAddress)
      return messageRef.current.showError({
        message: "آدرس در دسترس نمی باشد",
      });
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  const title = `${mainData?.artistInfo?.name?.[window.lang]} | ${
    "  کسب و کارهای ایرانی " +
    translations?.[window.lang] +
    " | " +
    mainData?.category?.subCat?.[window.lang] +
    " در " +
    translations?.[window.lang] +
    mainData?.moreInfo?.address.city
  }`;
  const description = `بانک اطلاعاتی مشاغل ایرانیان متامارس |  ${
    " کسب و کارهای ایرانیان " +
    translations?.[window.lang] +
    " | " +
    mainData?.artistInfo?.name?.[window.lang]
  } | ${
    mainData?.moreInfo?.address.municipality +
    "-" +
    mainData?.moreInfo?.address.city +
    "-" +
    mainData?.moreInfo?.address.state +
    "-" +
    mainData?.moreInfo?.address.country +
    "|" +
    mainData?.moreInfo?.desc?.[window.lang]
  }`;
  const metaTagsData = {
    title,
    description,
    section: "event page",
    url: `${window.location.origin}/${window.lang}/ca/qc/event/page/${mainData?._id}`,
    img: "https://www.metamarce.com/img/illustration/buy-photography-gear-online.svg",
    type: "webpage",
    index: false,
  };

  return (
    <div>
      <MetaDecorator data={{ ...metaTagsData }} />

      <ToastNote messageRef={messageRef} />
      {/* ****** cover ******** */}
      <div
        className="listing-hero"
        data-setbg="/img/img/listing/details/listing-hero.jpg"
        style={{
          objectFit: "cover",
          backgroundImage: `url(${mainData?.img})`,
        }}
      >
        <div className=" container2 ">
          <div className="row px-1 px-lg-5 ">
            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
              <div className="listing-cover-page">
                <div className="container">
                  <div className="picture-container">
                    <div className="picture">
                      <img
                        // src="/img/Images/icons8-add-image-64.png"
                        src={mainData?.busInfo?.logo}
                        className="picture-src"
                        id="wizardPicturePreview"
                        title={mainData?.artistInfo.name?.[
                          window.lang
                        ]?.substring(0, 20)}
                        loading="lazy"
                      />
                      {/* <input type="file" id="wizard-picture" className="" /> */}
                    </div>
                  </div>
                  <div className="bus-bio-bg">
                    <h1 className="business-name-cover">
                      {tools.linesSpliter(
                        mainData?.moreInfo?.title?.[window.lang]
                      )}
                    </h1>
                    <span className="review-text">
                      مدیریت رویداد :{" "}
                      {mainData?.busInfo?.busName?.[window.lang]}
                    </span>
                    {/* <!-- *******rating stars shold show up here -->*/}
                    <p className="text-start">
                      <a href="#comments2">
                        <RatingManuel value={mainData?.feedbackInfo?.rate} />
                        <span>
                          {Math.floor(mainData?.feedbackInfo?.rate * 100) / 100}
                        </span>
                        <span className="review-text">
                          (
                          {mainData?.feedbackInfo?.rates
                            ? mainData?.feedbackInfo?.rates
                            : "بدون "}
                          رای)
                        </span>
                      </a>
                      <span className="review-text text-center">
                        (
                        <i
                          className="bi bi-eye Business-cover-icon px-1  "
                          title="بازدید"
                        ></i>
                        {mainData?.feedbackInfo?.views} )
                      </span>
                    </p>
                    <p className="mt-5 mb-1">
                      <span title=" تاریخ ثبت " className="m-0">
                        <i className="bi bi-calendar-plus Business-cover-icon px-2 "></i>
                        <span >
                        
            {tools.dynamicDate({date:mainData?.createdAt,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}
                          
                        </span>
                      </span>

                      <span title="آخرین به روز رسانی " className="m-0">
                        <i className="bi bi-calendar2-check Business-cover-icon px-2 "></i>
                        <span
                        >
            {tools.dynamicDate({date:mainData?.moreInfo?.lastUpdate,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}

                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-5 px-0 d-flex flex-wrap align-items-end justify-content-lg-end justify-content-center ">
              {/* <!-- Button trigger modal --> */}

              {((mainData?.moreInfo?.history?.createdBy?._id === user?._id &&
                (user?._id ?? false)) ||
                (!mainData?.moreInfo?.history?.createdBy?._id &&
                  mainData?.moreInfo?.history?.createdBy?._id ===
                    (user?._id ?? false)) ||
                user?.roles?.includes(1000)) && (
                <Link
                  className="btn btn-primary share-cover-btn m-2"
                  data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                  to={
                    user.roles?.includes(9999)
                      ? `/${window.lcs}/dashboard/event/edit/${mainData?._id}`
                      : "#exampleModalToggle"
                  }
                  state={mainData}
                  type="Link"
                >
                  ویرایش ‌<i className="bi bi-arrow-repeat"></i>
                </Link>
              )}

              {mainData?.moreInfo?.history?.createdBy?._id ===
                (user?._id ?? false) &&
                mainData.moreInfo.status !== "01-draft" && (
                  <Link
                    className="btn btn-primary share-cover-btn m-2"
                    data-bs-toggle={!user.roles?.includes(9999) && "modal"}
                    to={
                      user.roles?.includes(9999) &&
                      `/${window.lcs}/dashboard/user/ads/plans?_id=${mainData?._id}&targetIdentifier=Event`
                    }
                    state={mainData}
                    type="Link"
                  >
                    بیشتر دیده شوید‌<i className="bi bi-magic"></i>
                  </Link>
                )}

              <button
                className="btn btn-primary share-cover-btn m-2 "
                data-bs-toggle="modal"
                data-bs-target={`#myModel${mainData?._id}`}
                id="shareBtn"
                data-bs-placement="top"
                title="Share!"
              >
                همرسانی
                <i className="bi bi-share-fill mx-2"></i>
              </button>

              {/* <!-- Modal --> */}
              <ShareSocialMedias
                url={`${window.location.origin}/${window.lcs}/event/page/${mainData?._id}`}
                title={mainData?.artistInfo?.name?.[window.lang]}
                img={mainData?.artistInfo?.img}
                _id={mainData?._id}
              />

              <button
                className="btn btn-outline-secondary save-cover-btn m-2"
                onClick={saveHandler}
              >
                ذخیره
                {loading.save ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved?.event?.includes(mainData?._id)
                        ? "bi bi-bookmark-fill ms-1"
                        : "bi bi-bookmark ms-1"
                    }
                  ></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ****** end of cover ******** */}
      <div className="mx-md-5">
        <div className="row p-0 m-0">
          <div className=" col-md-6 d-flex flex-wrap  justify-content-center mt-3">
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/event/filters?mainCat=${
                mainData?.category?.mainCat?.[window.lang]
              }&mainCatId=${mainData?.category?.mainCat._id}`}
              // to={'/${lcs}/mainData/filters?mainCat=${mainData?.category.mainCat?.[window.lang]}&mainCatId=${mainData?.category?mainCat?._id}'}
              className="btn btn-primary button-filter button-filter-tag"
            >
              <span>
                <i className="bi bi-list-ul me-1"></i>
              </span>
              {mainData?.category?.mainCat?.[window.lang]}
            </Link>
            <Link
              onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
              to={`/${window.lcs}/event/filters?subCat=${
                mainData?.category?.subCat?.[window.lang]
              }&subCatId=${mainData?.category?.subCat._id}`}
              className="btn btn-primary button-filter button-filter-tag  "
            >
              <span>
                <i className="bi bi-list-nested me-1 "></i>
              </span>
              {mainData?.category?.subCat?.[window.lang]}
            </Link>
          </div>

          <div className=" col-md-6 d-flex flex-wrap  justify-content-center  mt-3">
            <a href="#comments2" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-chat-dots me-1"></i>
              </span>
              {repliesCounter()} دیدگاه‌
            </a>
            <Link
              className="btn btn-primary button-filter "
              to={`/${window.lcs}/event/filters?artistId=${
                mainData?._id
              }&artistName=${mainData?.artistInfo.name?.[window.lang]}`}
            >
              <span>
                <i className="bi bi-person-square me-1"></i>
              </span>
              همکاری
            </Link>
            <div
              className="btn btn-primary button-filter "
              onClick={(e) =>
                navigateHandle(`/${window.lcs}/event/booking/${mainData._id}`)
              }
            >
              <span>
                <i className="bi bi-person-square me-1"></i>
              </span>
              بوکینگ
            </div>

            {/* KEEEEEEEEEEP
            <a href="#" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-shop me-1"></i>
              </span>
              فروشگاه
            </a>
            <a href="#" className="btn btn-primary button-filter ">
              <span>
                <i className="bi bi-calendar-check me-1"></i>
              </span>
              قرار ملاقات
            </a> */}
          </div>
        </div>
        {/* <!-- Listing Details Section Begin -->*/}

        {/* <!-- <div className="container"> -->*/}
        <div className="row m-md-2 p-2 gx-5">
          <div className=" col-lg-5 ">
            {/* <!-- <div className=""> -->*/}
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/qr-code.png"
                    className="arrow2 pe-2 py-1"
                    alt="tags"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">
                  سایر رویدادها
                  {"  " + mainData?.artistInfo?.name?.[window.lang]}
                </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div className="text-center">
                <ul className="list-group">
                  {eventList.map((ev, i) => (
                    <li key={i} className="d-flex flex-row">
                      <img
                        className="img-fluid"
                        width="50px"
                        src={ev.img}
                        alt=""
                      />
                      <div className="col-md-6" dir="ltr">
                        <span>{ev.moreInfo?.address?.country},</span>
                        <span>
                          {ev.moreInfo?.address?.state +
                            "," +
                            ev.moreInfo?.address?.city}
                        </span>
                        <span>
            {tools.dynamicDate({date:ev.moreInfo?.dates?.eventDate,countryShort:window.lcs.split('/')[1],lang:window.lang,showYear:true,short:true})}
                      
                        </span>
                        <div>{ev.moreInfo?.complex[window.lang]}</div>
                      </div>
                      <div
                        className="col-md-6 cursor"
                        onClick={(e) =>
                          navigateHandle(`/${window.lcs}/event/page/${ev._id}`)
                        }
                      >
                        جزئیات بیشتر
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/email.png"
                    className="arrow2 pe-2 py-1"
                    alt="more info"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">اطلاعات تماس</span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>

              {/* <div style={{direction:"ltr"}}> */}
              <ul className="list-group px-1" dir="ltr">
                <li className="group-list-item flex-column" dir="ltr">
                  <div dir="ltr">
                    <span>
                      <i className="bi bi bi-geo-alt mx-3"></i>
                    </span>
                    <span className="addressBusPage">
                      {`
                    ${
                      mainData?.moreInfo?.address?.number?.length
                        ? "#" +
                          window.capitalize(
                            mainData?.moreInfo?.address?.number
                          ) +
                          ","
                        : ""
                    }
                    ${
                      mainData?.moreInfo?.address?.streetNumber?.length
                        ? window.capitalize(
                            mainData?.moreInfo?.address?.streetNumber
                          ) + ","
                        : ""
                    }
                    ${
                      mainData?.moreInfo?.address?.route?.length
                        ? window.capitalize(
                            mainData?.moreInfo?.address?.route
                          ) + ","
                        : ""
                    }
                    
                    ${
                      mainData?.moreInfo?.address?.postalCode
                        ? mainData?.moreInfo?.address?.postalCode
                        : ""
                    }
                    
                   
                   `}
                    </span>
                  </div>

                  <div className="me-5">
                    {`
                    ${
                      mainData?.moreInfo?.address?.municipality?.length
                        ? window.capitalize(
                            mainData?.moreInfo?.address?.municipality
                          ) + ","
                        : ""
                    }
                    ${
                      mainData?.moreInfo?.address?.city?.length
                        ? window.capitalize(mainData?.moreInfo?.address?.city) +
                          ","
                        : ""
                    }
                    ${
                      mainData?.moreInfo?.address?.state?.length
                        ? window.capitalize(
                            mainData?.moreInfo?.address?.state
                          ) + ","
                        : ""
                    }
                    ${
                      mainData?.moreInfo?.address?.countryShort?.length
                        ? window.capitalize(
                            mainData?.moreInfo?.address?.countryShort
                          )
                        : ""
                    }
                    `}
                  </div>
                </li>

                <li className="group-list-item">
                  <i className="bi bi-telephone mx-3"></i>
                  {tools.formatPhoneNumber(mainData?.moreInfo?.phone)}
                </li>
                {/* <!-- <li className="group-list-item"><i className="bi bi-clock"></i><span>Sat - Mon:</span> 09.00am
                                            - 10.00pm</li> -->*/}
                <li className="group-list-item">
                  <i className="bi bi-globe mx-3"></i>

                  {mainData?.moreInfo?.website?.length > 0 ? (
                    <a
                      href={
                        (!mainData?.moreInfo?.website.startsWith("http")
                          ? "http://"
                          : "") + mainData?.moreInfo?.website
                      }
                      target="_blank"
                    >
                      {mainData?.moreInfo?.website}
                    </a>
                  ) : (
                    <span title="وبسایت تاکنون ثبت نشده است">بدون وبسایت</span>
                  )}
                </li>
                <li className="group-list-item ">
                  <i className="bi bi-envelope mx-3"></i>
                  <div className="text-break">{mainData?.email}</div>
                </li>
                {mainData?.moreInfo?.address?.displayAddress && (
                  <li
                    className="group-list-item pe-auto cursor"
                    onClick={() =>
                      openGoogleMaps(mainData?.moreInfo?.address?.googleAddress)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="32"
                      fill="currentColor"
                      className="bi bi-sign-turn-right mx-3"
                      viewBox="0 0 16 32"
                    >
                      <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5V8.5Z" />
                      <path
                        fillRule="evenodd"
                        d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435Zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"
                      />
                    </svg>
                    <div className="map-dir">مسیریابی با نقشه گوگل</div>
                  </li>
                )}
                {/* <li className="w-75">
                <QRCode value={`https://www.metamarce.com/${window.lcs}/mainData/page/${mainData?._id}`} fgColor="#BAA78D"/>
                </li> */}
                {/* <li>
                <GoogleMapsIframe destination={mainData?.moreInfo?.address?.googleAddress}/>
                </li> */}
              </ul>
              {/* </div> */}
              <SocialMedias socialMedias={mainData?.moreInfo?.socialMedia} />

              {mainData?.moreInfo?.address?.displayAddress && (
                <div className="listing-details-map mt-3">
                  <GoogleMap data={mainData?.moreInfo?.address.coordinates} />
                </div>
              )}
            </div>
            <div className="listing-sidebar-section mt-0 mb-5">
              <div className="divider2 divider-small">
                <span>
                  <img
                    src="/img/bussiness-page/qr-code.png"
                    className="arrow2 pe-2 py-1"
                    alt="tags"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-0 ">
                  {" "}
                  QR-code کسب‌و‌کار در متامارس{" "}
                </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div className="text-center">
                <QRCode
                  value={`https://www.metamarce.com/${window.lcs}/event/page/${mainData?._id}`}
                  fgColor="#BAA78D"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="listing-details-information p-5  pt-3 my-5">
              <div className=" divider-small border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/information.png"
                    className="arrow2 pe-2"
                    alt="info"
                    loading="lazy"
                  />
                </span>

                <span className="p-0 mb-0 ">توضیحات </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <div>
                {tools.linesSpliter(mainData?.moreInfo?.desc?.[window.lang])}
              </div>
            </div>
            <div className="listing-details-information py-3  my-5 ">
              <div className=" divider-small border-0 mt-4 px-5">
                <span>
                  <img
                    src="/img/bussiness-page/media-player.png"
                    className="arrow2 pe-2"
                    alt="tags"
                    loading="lazy"
                  />
                </span>

                <span className="p-0 mb-0 ">ویدیو </span>
              </div>
              {mainData?.moreInfo?.isPaid?.video && (
                <div className="px-3">
                  <div className="ratio ratio-16x9">
                    {generateYoutubeIframe(
                      mainData?.moreInfo?.video[0]?.youtube ?? ""
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="listing-details-information p-5  pt-3 my-5 ">
              <div className=" divider-small border-0 mt-4 ">
                <span>
                  <img
                    src="/img/bussiness-page/tag.png"
                    className="arrow2 pe-2"
                    alt="tags"
                    loading="lazy"
                  />
                </span>

                <span className="p-0 mb-0 ">برچسب‌ها </span>

                {/* <!-- <img src="/img/Images/arrow-09-svgrepo-com.svg"  className="arrow" alt="arrow"> -->*/}
              </div>
              <ul className=" d-flex flex-wrap justify-content-between">
                {mainData?.moreInfo?.tags?.map((tag, i) => (
                  <li>
                    <Link
                      onClick={(e) => window.scrollTo({ top: 0, smooth: true })}
                      key={i}
                      to={`/${window.lcs}/event/filters?tag=${tag}`}
                    >
                      <span key={i}>
                        {/* <img
                    src="/img/bussiness-page/tag.png"
                    className="arrow2 pe-2"
                    alt="tags"
                  /> */}
                        {/* <i className="bi bi-hash"></i> */}
                        <i className="bi bi-tag me-1"></i>
                      </span>
                      {tag}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="listing-details-information p-3  my-5  ">
              <div className=" divider-small border-0 mt-4">
                <span>
                  <img
                    src="/img/bussiness-page/notes.png"
                    className="arrow2 pe-2"
                    alt="facilities"
                    loading="lazy"
                  />
                </span>
                <span className="p-0 mb-3 ">امکانات </span>
              </div>
            </div>
          </div>
          {/* <!-- </div> -->*/}
        </div>

        {/* <!-- ممکنه براتون جالب باشه... -->*/}
        {busSlider?.length >= 4 && (
          <div>
            <div className="divider2 ">
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                {/* <!-- <line x1="30" y1="20" x2="560" y2="20" stroke="currentColor" />  -->*/}
              </svg>

              <span className="p-0 mb-0 ">ممکنه براتون جالب باشه...</span>
              {/* <img
                src="/img/Images/arrow-09-svgrepo-com.svg"
                className="arrow"
                alt="arrow"
              /> */}
            </div>
            {/* <BusCardSlider data={busSlider} /> */}
            {/* <BusCardSlider2 
            businessess={data?.businessess}
            subCat={mainData?.category?.subCategory}
            /> */}
          </div>
        )}
      </div>

      {/* *****************comments************** */}
      <div className="row m-0 p-0 gx-5 justify-content-center ">
        <div className="col-md-9 col-lg-9 col-xl-6" id="comments2">
          <CommentsRating
            id={id}
            value={mainData}
            setData={setMainData}
            collection="Business"
          />
        </div>
      </div>
      {/* <!-- ********** back to top************************* --> */}
      <div className="row backtotop">
        <div className="col-12">
          <a
            href="#top"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="بازگشت به آغاز صفحه"
            className="tooltip-color"
          >
            <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
          </a>
        </div>
      </div>
    </div>
  );

  function convertTime(time24h) {
    var timeParts = time24h.split(":");
    var hours = parseInt(timeParts[0]);
    var minutes = parseInt(timeParts[1]);

    // Determine AM/PM
    var period = hours < 12 ? "AM" : "PM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    }

    // Ensure two-digit formatting
    var hoursStr = hours.toString().padStart(2, "0");
    var minutesStr = minutes.toString().padStart(2, "0");

    // Concatenate the modified values
    var time12h = period + " " + hoursStr + ":" + minutesStr;

    return time12h;
  }
}
function generateYoutubeIframe(videoLink) {
  const videoIdMatch = videoLink?.match(
    /(?:v=|v\/|embed\/|youtu\.be\/)(.{11})/
  );
  if (videoIdMatch) {
    const videoId = videoIdMatch[1];
    const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="560"
        height="315"
        src={iframeSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen="allowFullScreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      ></iframe>
    );
  }

  return null; // Return null if video ID couldn't be extracted
}
