import './widgetLg.css'

export default function WidgetLg() {
  const Button = ({status})=>{
    return <button className={'widgetLgButton ' + status}>{status}</button>
  }
  return (
    <div className='widgetLg'>
      <h3 className="widgetLgTitle">Latest Transactions</h3>
      <table className="wedgitLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Customer</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img loading="lazy" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScuzBdCj-6DYYKRuXR6wroURM_D0C-fb6u-w&usqp=CAU" alt="widgetLgImg" className="widgetLgImg" />
            <span className="widgetName">Alex Maxi</span>
          </td>
          
            <td className="widgetLgDate">2 June 2022</td>
            <td className="widgetLgAmount">$122.00</td>
            <td className="widgetLgStatus">
              <Button status="Approved"/>
            </td>          
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img  loading="lazy" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJrURzysGidjCA4aOFFAsZW6e5Weami4AUvg&usqp=CAU" alt="widgetLgImg" className="widgetLgImg" />
            <span className="widgetName">Alex Maxi</span>
          </td>
          
            <td className="widgetLgDate">2 June 2022</td>
            <td className="widgetLgAmount">$122.00</td>
            <td className="widgetLgStatus">
              <Button status="Pending"/>
            </td>          
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img loading="lazy"src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1TGbyli999eKrb6hXMmf1Jl3ekZ82ivazkQ&usqp=CAU" alt="widgetLgImg" className="widgetLgImg" />
            <span className="widgetName">Alex Maxi</span>
          </td>
          
            <td className="widgetLgDate">2 June 2022</td>
            <td className="widgetLgAmount">$122.00</td>
            <td className="widgetLgStatus">
              <Button status="Declined"/>
            </td>          
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img loading="lazy"src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4rR-KMSgNMEl6b9nBdhzTEvGs5BDI1rvdaQ&usqp=CAU" alt="widgetLgImg" className="widgetLgImg" />
            <span className="widgetName">Alex Maxi</span>
          </td>
          
            <td className="widgetLgDate">2 June 2022</td>
            <td className="widgetLgAmount">$122.00</td>
            <td className="widgetLgStatus">
              <Button status="Pending"/>
            </td>          
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img loading="lazy"src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFFJbUSrvysQrdtNpXCVTbeKqEOnDIDak7Zw&usqp=CAU" alt="widgetLgImg" className="widgetLgImg" />
            <span className="widgetName">Alex Maxi</span>
          </td>
          
            <td className="widgetLgDate">2 June 2022</td>
            <td className="widgetLgAmount">$122.00</td>
            <td className="widgetLgStatus">
              <Button status="Declined"/>
            </td>          
        </tr>
        
       
      </table>
    </div>
  )
}
