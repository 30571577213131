import { Route } from "react-router-dom";

import MediaFilters from "../components/screens/media/filters/mediaFilters";

import MediaRegister from "../components/screens/media/register/mediaRegister";
import MediaHome from "../components/screens/media/mediaHome";

export default function mediaRoutes() {
  return (
    <>
      <Route path="" element={<MediaHome />} />
      <Route path="register" element={<MediaRegister />} />
      <Route path="register/:id" element={<MediaRegister />} />
      {/* <Route path="page/:id" element={<JobPage />} />       */}
      <Route path="filters" element={<MediaFilters />}></Route>
    </>
  );
}
