import './userList.css'
import {DeleteOutline} from '@mui/icons-material';
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { CartContext } from '../../../../contexts/cartContext';
import apiUsers from '../../../../../utils/apiUsers';
import tools from '../../../../../utils/tools';


export default function UserList() {
  const effectRan = useRef(false)
  const {user} = useContext(CartContext)
  const [data , setData] = useState([])
  const deleteHandle =async (id)=>{
    if(window.confirm('are you sure to delete this user ? ')!== true) return;
    const response= await  apiUsers.deleteUser({token:user.token , id})
    if(response.error)return alert(response.error)
    window.alert(response.message)

    setData(data.filter(item => item._id !== id))
  }

  useEffect(()=>{
if(effectRan.current===false){
    (async()=>{ const response = await apiUsers.getAllUsers(user.token)
      if(response.error) return alert(response.error)
      setData(response.data)

    })()}
    effectRan.current=true
  },[])

const columns = [
  // { field: '_id', headerName: 'ID', width: 70 },
  { field: 'userName', headerName: 'User Name', width: 100 , renderCell:(params)=>{ return (
    <div className='userListUser'>
        <img className='userListImg' src={params.row.thumbnail} alt="thumbnail" loading="lazy"/>
        {params.row.name}
    </div>
   )} },
   { field: 'email',  headerName: 'User Info', width: 300, renderCell:(params)=>{
    return( 
        <div>
          <div >{params.row.userName}</div>
          <div >{params.row.email}</div>
        </div>
    )
  }}, 
  // { field: 'email', headerName: 'Email', width: 220 },  
  { field: 'roles', headerName: 'Roles', width: 100 },
  { field: 'lcs', headerName: 'Lcs', width: 100 },
  // { field: 'moreInfo.phoneNumber',  headerName: 'Phone', width: 150, renderCell:(params)=>{
  //   return <div dir='ltr' title={params.row.moreInfo.phoneNumber}>{tools.formatPhoneNumber(  params.row.moreInfo.phoneNumber)}</div>
  // }}, 
  { field: 'affiliateInfo.code',  headerName: 'Code', width: 150, renderCell:(params)=>{
    return <div dir='ltr' title={params.row.affiliateInfo.code}>{params.row.affiliateInfo.code}</div>
  }}, 
  { field: 'action',  headerName: 'Action', width: 150, renderCell:(params)=>{
    return( 
        <div>
          <Link to={`/${window.lcs}/dashboard/users/reviewuser`} state={{data: params.row}} ><button className='userListEdit'>Edit</button></Link>
         <DeleteOutline className='userListDelete' onClick={()=>deleteHandle(params.row._id)}/>
        </div>
    )
  }}, 
 
  // { field: 'transaction',  headerName: 'Transaction', width: 90,}, 
];


  return (
    <div className='userList' style={{ height: 800, width: '100%' }}>
      <DataGrid
      getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        checkboxSelection       
        
      />
      12321
    </div>
  );
}

