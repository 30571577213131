import "./saved.css";
import { DeleteOutline } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../../contexts/cartContext";
// import api from "../../../../../../utils/apiBusiness";
import ToastNote from "../../../../../golbal/notifications/toastNote";
import { CircularProgress } from "@mui/material";
import apiBlog from "../../../../../../utils/apiBlog";
import api from "../../../../../../utils/api";
import ColoredSpiner from "../../../../../alret/spiners/coloredSpiner/coloredSpinner";
import MyIcons from "../../../../../golbal/icons/myIcons";

export default function SavedPosts(props) {
  const messageRef = useRef(null);
  const [loading, setLoading] = useState({
    id: "",
    spinner: false,
    loading2: false,
    loading3: false,
  });
  // console.log("loading main",loading);
  const { user, signedUser } = useContext(CartContext);

  const navigate = useNavigate();
  const url = useParams();
  // console.log(user?.saved);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchSavedList();
  }, []);

  async function fetchSavedList() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBlog.userSavedList({
      token: user.token,
      savedItems: user?.saved?.post,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    console.log(response.data);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  const deleteHandler = async (e, post) => {
    e.preventDefault();
    const text = "شما در حال حذف این پست از ذخیره میباشید.";
    if (window.confirm(text) != true) return;
    setLoading((pre) => ({
      ...pre,
      id: post._id,
    }));
    const response = await api.saveHandler({
      id: post._id,
      token: user.token,
      section: "post",
    });
    setLoading((pre) => ({ ...pre, id: "" }));
    console.log("saveHandler", response.data);
    setTimeout(() => e.target.removeAttribute("disabled"), 1000 * 10);
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({
      message: "پست از لیست ذخیره حذف شد.",
    });
    signedUser(response.data);
    const newData = data.filter((da) => da._id !== post._id);
    setData(newData);
  };

  function showPageHandle(props) {
    console.log(props);
    let text = "";
    if (window.lang !== props.lang.toLowerCase())
      text += `شما در حال انتقال به زبان ${window.capitalize(
        props.lang
      )} میباشد . \n`;

    if (!text.length) return navigate(`/${window.lcs}/blog/post/${props._id}`);

    if (window.confirm(text) != true) return;
    console.log("Passed");
    navigate(
      `/${
        props.lang +
        "/" +
        window.location.pathname.split("/").slice(2, 4).join("/")
      }/blog/post/${props._id}`
    );
  }

  const columns = [
    {
      field: "action",
      headerName: "عملیات",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div title="نمایش" onClick={(e) => showPageHandle(params.row)}>
              <MyIcons icon="display" />{" "}
            </div>
            {loading.id === params.row._id ? (
              <CircularProgress color="inherit" size="20px" />
            ) : (
              <div
                title="پاک کردن"
                onClick={(e) => deleteHandler(e, params.row)}
              >
                <MyIcons icon="delete2" />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "img",
      headerName: "عکس",
      width: 100,
      renderCell: (params) => {
        return (
          <div onClick={(e) => showPageHandle(params)}>
            <img
              src={params.row.img}
              alt={params.row?.title}
              className="userListImg"
              loading="lazy"
            />
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "موضوع پست",
      width: 250,
      renderCell: (params) => {
        return (
          <button
            className="userListTitle"
            onClick={(e) => showPageHandle({ props: params.row })}
          >
            <div title={params.row?.title}>{params.row?.title}</div>
            {/* <div title={params.row?.email}> {params.row?.email}</div>        */}
          </button>
        );
      },
    },
    {
      field: "subCategory",
      headerName: "دسته بندی",
      width: 100,
    },
    {
      field: "city",
      headerName: "شهر",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.city}>
          {window.capitalize(params.row?.city)}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "استان",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.state}>
          {window.capitalize(params.row?.state)}
        </div>
      ),
    },
    {
      field: "country",
      headerName: "کشور",
      width: 100,
      renderCell: (params) => (
        <div dir="ltr" title={params.row?.country}>
          {window.capitalize(params.row?.country)}
        </div>
      ),
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 170,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {loading.id === params.row._id ? (
    //           <CircularProgress color="inherit" size="20px" />
    //         ) : (

    //           <DeleteOutline
    //             className="userListDelete"
    //             onClick={(e) => deleteHandler(e, params.row)}
    //           />
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      {loading.spinner && <ColoredSpiner />}
      <ToastNote messageRef={messageRef} />
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={[15]}
        rowsPerPageOptions={[1]}
        checkboxSelection
      />
    </div>
  );
}
