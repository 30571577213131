// import "./productList.css";
import {DeleteOutline} from '@mui/icons-material';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { productsRows } from '../../dummyData';
import { Link } from 'react-router-dom';
import { useState } from 'react';

export default function ProductList() {
    const [data , setData] = useState(productsRows)
    const deleteHandle = (id)=>{
        setData(data.filter(item => item.id !== id))
      }
      const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Product', width: 160 , renderCell:(params)=>{ return (
          <div className='productListUser'>
              <img className='productListImg' src={params.row.img} alt="Product" />
              {params.row.name} 
          </div>
         )} },
        { field: 'stock', headerName: 'Stock', width:100 },  
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'price',  headerName: 'Price', width: 90,}, 
        { field: 'action',  headerName: 'Action', width: 150, renderCell:(params)=>{
          return( 
              <div>
                <Link to={`/dashboard/products/${params.row.id}`}><button className='productListEdit'>Edit</button></Link>
               <DeleteOutline className='productListDelete' onClick={()=>deleteHandle(params.row.id)}/>
              </div>
          )
        }}, 
        { field: 'transaction',  headerName: 'Transaction', width: 90,}, 
      ];
      
  return (
    <div className="productList">
     <DataGrid
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
    );
}
