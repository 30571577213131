import axios from "axios"

export default new (class{
   getPostList = async({token,id, access})=>{  
    try {
      const response = await axios.get(`/api/blog/postslist/${id}?access=${access}`,{
        headers :{
          Authorization : `Bearer ${token}`,
         }
    
      })
        // console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)      
        return response.data
    
    } catch (error) {
      //  console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }
  getPost = async({filters})=>{  
    let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }
  
    try {
      const response = await axios.get(`/api/blog?${filters1}`)
      //  console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)      
        return response.data    
    } catch (error) {
      //  console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }

    addPost =async({postInfo , token})=>{  
      try {
        const response = await axios.post(`/api/blog/addpost` ,
        {postInfo} ,{
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==201)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       
     

       deletePost =async({token , id})=>{  
       
      try {
        const response = await axios.delete(`/api/blog/post/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       activatePost =async({token , id})=>{  
       
      try {
        const response = await axios.get(`/api/blog/post/activate/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       draftedPost =async({token , id})=>{  
       
      try {
        const response = await axios.get(`/api/blog/post/drafting/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       suspendingPost =async({token , id})=>{         
      try {
        const response = await axios.get(`/api/blog/post/suspending/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       featuredAuthor =async({token , id})=>{         
      try {
        const response = await axios.get(`/api/blog/post/featuredAuthor/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }
       hideAuthor =async({token , id})=>{         
      try {
        const response = await axios.delete(`/api/blog/post/featuredAuthor/${id}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
      
        })
          console.log(response.data);
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
       }

       likeHandler = async(id , token)=>{
    try {
const response = await axios.get(`/api/blog/${id}/like` ,
{
  headers :{
    Authorization : `Bearer ${token}`,
   }      
})
  // console.log(response.data);
  if(response.status!==201)  throw new Error(response.data.message)
  return response.data

} catch (error) {
 console.log(error);
  return  {error: error?.response?.data?.message || error.message}
}
}

getPostDetails = async(postId)=>{      
  try {
    const response = await axios.get(`/api/blog/postdetails/${postId}`)          
      if(response.status!==200)  throw new Error(response.data.message)      
      // console.log(response.data);
      return response.data      
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editPost = async ({postInfo, token , id})=>{  
    try {
      const response = await axios.put(`/api/blog/post/${id}` ,
      {postInfo} ,{
        headers :{
          Authorization : `Bearer ${token}`,
         }
    
      })
        console.log(response.data);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
    
    } catch (error) {
       console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
     }

  userSavedList = async({token,savedItems})=>{
    try {
    
    const response = await axios.post(`/api/blog/user/list/saved`,{savedItems} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }}
       )
    
    
    // console.log(response.data);
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }   


getTags = async({ filters})=>{    
  let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }

  try {
    const response = await axios.get(`/api/blog/tags?${filters1}`)
      
      if(response.status!==200)  throw new Error(response.data.message)      
      return response.data
  
  } catch (error) {             
      return  {error: error?.response?.data?.message || error.message}
  }
  }

})()