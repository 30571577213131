import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticTimePicker ,DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import addErrClass from '../persian/common/alerts/addErrClass';

export default function DatePickerRender({ formik ,field}) {  
    
    function changeHandle (newTime){
      console.log("changeHandle");
      const date = dayjs(newTime).toDate()
      const date2 = new Date(date)
      formik.setValues(pre=>({
        ...pre,
        dates:{...pre.dates,[field]:date2}
      }))
      
    };
   

  return (  
     
    <LocalizationProvider dateAdapter={AdapterDayjs}>

    <DatePicker 
        value={dayjs(formik.values.dates?.[field])}
    onChange={changeHandle}
    className={addErrClass({formik,field:`dates.${field}`})}
    />
  </LocalizationProvider>
    
  
  );
}

