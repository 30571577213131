import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CartContext } from '../../../../contexts/cartContext';
import ColoredSpinner from '../../../../alret/spiners/coloredSpiner/coloredSpinner';
import apiBusiness from '../../../../../utils/apiBusiness';


export default function BusinessesOverview() {
  const {user}=useContext(CartContext)
  const[loading,setLoading]=useState(false)
  const effectRan=useRef(false)
  useEffect(()=>{
    if(effectRan.current) return
     setLoading(true);
    // const response = await Promise.all([
    //   // await fetchCurrencies(),
    // ]);   
    fetchData()         
    setLoading(false);
    effectRan.current=true 
  },[])
  const [data, setData]=useState([])
  const [cur, setCur]=useState([])

  async function fetchData(){
    const response =  await apiBusiness.getChartData({token:user.token, type:'stackedBarChart'})
    console.log(response.data);
    if(response.error)return;
    setData(response.data)

  }

  
  


  

  const transformedData = {};

// Iterate through the initial data
data.forEach((item) => {
    const { country, ...statuses } = item;

    if (!transformedData[country]) {
        transformedData[country] = { country };
    }

    // Iterate through the statuses for each country
    for (const [status, countObj] of Object.entries(statuses)) {
        for (const [state, count] of Object.entries(countObj)) {
            if (!transformedData[country][status]) {
                transformedData[country][status] = 0;
            }
            transformedData[country][status] += count;
        }
    }
});

// Convert the transformed data into an array of objects
const resultArray = Object.values(transformedData);
resultArray.sort((a, b) => {
  const totalCountA = Object.values(a).reduce((sum, value) => (typeof value === 'number' ? sum + value : sum), 0);
  const totalCountB = Object.values(b).reduce((sum, value) => (typeof value === 'number' ? sum + value : sum), 0);
  
  return totalCountB - totalCountA;
});

console.log(resultArray);
  
  
  return (
    <div>
      {loading&&<ColoredSpinner/>}      
      {resultArray.length > 0 && (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart
      data={resultArray}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="country" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="00-deleted" stackId="a" fill="#8884d8" name="  Deleted" />
      <Bar dataKey="01-draft" stackId="a" fill="#82ca9d" name="  Draft" />
      <Bar dataKey="02-claimed" stackId="a" fill="#13ca9d" name="  Claimed" />
      <Bar dataKey="03-verified" stackId="a" fill="#44ca9d" name="  Verified" />
      <Bar dataKey="04-trusted" stackId="a" fill="#55ca9d" name="  Trusted" />
    </BarChart>
  </ResponsiveContainer>
)}
 <StatusTable data={data} />

    </div> 
  );
}





function StatusTable({ data }) {
  const [statusCounts, setStatusCounts] = useState(new Map());

  useEffect(() => {
    const calculateStatusCounts = () => {
      const countsMap = new Map();

      data.forEach((item) => {
        const { country, ...statuses } = item;

        Object.entries(statuses).forEach(([status, statesObj]) => {
          const keyPrefix = `${status},${country}`;

          Object.entries(statesObj).forEach(([state, count]) => {
            const key = `${keyPrefix},${state}`;

            if (!countsMap.has(key)) {
              countsMap.set(key, { count: 0 });
            }

            countsMap.get(key).count += count;
          });
        });
      });

      setStatusCounts(countsMap);
    };

    calculateStatusCounts();
  }, [data]);

  // Convert the Map to an array and sort by total count in descending order
  const sortedData = Array.from(statusCounts).sort((a, b) => b[1].count - a[1].count);

  // Calculate the total count for each status across all countries
  const totalAll = {};
  sortedData.forEach(([key, { count }]) => {
    const [status] = key.split(",");
    if (!totalAll.hasOwnProperty(status)) {
      totalAll[status] = 0;
    }
    totalAll[status] += count;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Status</th>
            <th>State</th>
            <th>Total Count</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map(([key, { count }]) => {
            const [status, country, state] = key.split(",");
            
            return (
              <tr key={key}>
                <td>{window.capitalize(country)}</td>
                <td>{window.capitalize(status?.substring(3))}</td>
                <td>{window.capitalize(state)}</td>
                <td>{count}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="2">Total All</td>
            <td></td>
            <td>{Object.values(totalAll).reduce((acc, curr) => acc + curr, 0)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
