import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Rating } from "@mui/material";
import api from "../../../../utils/api";
import { CartContext } from "../../../contexts/cartContext";
import ShareSocialMedias from "./shareSocialMedias";
import ToastNote from "../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import MyIcons from "../../../golbal/icons/myIcons";
import tools from "../../../../utils/tools";

export default function BusinessCard({data,location}) {
  const messageRef = useRef(null);
  const [spiner, setSpiner] = useState(false);
  const navigate = useNavigate();
  function navigateHandle(url) {
    window.scroll(0, 0);
    setTimeout(
      () => navigate(url, { state: { business: data } }),
      200
    );
  }
  const { signedUser, user } = useContext(CartContext);
  const saveHandler = async (e) => {
    e.preventDefault();
    if (!window.logined(9999)) return;
    const el = e.currentTarget;
    el.setAttribute("disabled", true);
    setSpiner(true);
    const response = await api.saveHandler({
      id: data?._id,
      token: user.token,
      // title: data?.busName,
      section: "business",
    });
    setSpiner(false);
    setTimeout(() => el.removeAttribute("disabled"), 1000 * 6);
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div>عملیات ذخیره ناموفق!</div>
            <div> دوباره وارد شوید.</div>
          </div>
        ),
      });
    // user?.saved.business.find((x) => x._id == data?._id);
    signedUser(response.data);
    !user?.saved?.business?.includes(data?._id)
      ? messageRef.current.showSuccess({
          message: "کسب و کار با موفقیت ذخیره شد.",
        })
      : messageRef.current.showWarning({
          message: "کسب و کار از لیست ذخیره حذف شد.",
        });
  };
  const businessCard = data?.moreInfo?.photos?.businessCard||data?.businessCard||'/img/landing/defalutBusCard.jpg'
  return (
    <div className="col pb-3 px-0 h-100 ">
      <ToastNote messageRef={messageRef} />
      <div className="card mx-2  border-0 shadow h-100">
        <div className="card-img-pack ">
          <div className="business-card-img-wrap">
            <div
              className="image-container image-container-bus"
              onClick={(e) =>
                navigateHandle(
                  data?._id
                    ? `/${window.lcs}/business/page/${data?._id}`
                    : data?.url
                )
              }
            >
              <img
                src={businessCard}
                className={`card-img-top  image-card business-card-img ${
                  data?.moreInfo?.status === "01-draft" && "unclaimed-img"
                }`}
                alt={(
                  data?.busName?.[window.lang] || data?.title
                )?.substring(0, 15)}
                loading="lazy"
              />
              {/* ********unclaimed****** */}
              {/* {data?.moreInfo?.status === "01-draft" && (
                <div
                  className={`card-img-overlay pb-4 ps-4 ${
                    data?.moreInfo?.status === "01-draft" &&
                    "card-img-overlay-unclaimed"
                  } `}
                >
                  <div>
                  

                    <p className="card-text overlay-text">
                      
                      این کسب و کار توسط کاربر ها اضافه و تاکنون توسط مالک آن
                      تائید و به روز رسانی نشده است.آیا شما مالک آن هستید؟
                    </p>

                    <p className="card-text " style={{ color: "white" }}>
                      
                      تائید نشده!
                    </p>
                  </div>
                </div>
              )} */}
              {/* ********end of unclaimed****** */}
            </div>
          </div>
          {data?.category?.mainCat?.mainLogo && (
            <div
              onClick={(e) =>
                navigateHandle(
                  `?mainCat=${
                    data?.category?.mainCat?.[window.lang]
                  }&mainCatId=${data?.category?.mainCat?._id}`
                )
              }
              to={`?mainCat=${
                data?.category?.mainCat?.[window.lang]
              }&mainCatId=${data?.category?.mainCat?._id}`}
              title={data?.category?.mainCat?.[window.lang]}
            >
              <img
                src={data?.category?.mainCat?.mainLogo}
                className="card-logo "
                alt={data?.category?.mainCat?.[window.lang]}
                loading="lazy"
              />
            </div>
          )}

          {/* <div className="listing-item-pic-tag"> تخفیف ویژه</div> */}
          {/* <!-- <span className="icon-stack2">
                            <i className="bi bi-check icon-stack-2x"></i>
                        </span> --> */}
          {/* <!-- <i className="bi bi-shield-fill-check icon-logo "></i> --> */}
        </div>
        <div className="card-body mt-4" dir="ltr">
          <h5
            dir="rtl"
            className="card-title card-titel2 m-1 cursor"
            onClick={(e) =>
              navigateHandle(
                data?._id
                  ? `/${window.lcs}/business/page/${data?._id}`
                  : data?.url
              )
            }
          >
            {data?.busName?.[window.lang] || data?.title}
            <span>
              {data?.moreInfo?.status === "01-draft" ? (
                <Tooltip1 message="این کسب و کار توسط کاربرها پیشنهاد و ثبت شده است." />
              ) : // <i className="bi bi-patch-question-fill"></i>
              data?.moreInfo?.status === "03-verified" ? (
                <Tooltip1
                  icon={<MyIcons icon={data?.moreInfo?.status} />}
                  message=" هویت مالک کسب و کار تائید شده است."
                />
              ) : //  : data?.moreInfo?.status === "02-claimed" ? (
              //   <Tooltip1
              //   icon={<MyIcons icon={data?.moreInfo?.status} />}
              //     message="کسب و کار توسط مالک ثبت شده"
              //   />
              // )
              data?.moreInfo?.status === "04-trusted" ? (
                <Tooltip1
                  icon={<MyIcons icon={data?.moreInfo?.status} />}
                  message=" هویت کسب و کار و مالک آن تائید شده است."
                />
              ) : (
                ""
              )}
            </span>
          </h5>

          {/* <!-- *******rating stars shold show up here --> */}

          <p dir="ltr" className="text-start">
            {/* <span className="review-text">
              ( رای
              {data?.feedbackInfo?.rates
                ? data?.feedbackInfo?.rates
                : "بدون "}
              )
            </span> */}

            <div
              // title={
              //   Number(data?.feedbackInfo?.rate) > 0 ?
              //   Number(data?.feedbackInfo?.rate.toFixed(2)):""
              // }
              className="d-flex  align-items-center justify-content-end"
            >
              <span className="review-text">
                (
                {data?.feedbackInfo?.rates
                  ? tools.dynamicNumber({
                      number: data?.feedbackInfo?.rates||data?.rates,
                      lang: window.lang,
                    })
                  : "بدون "}
                دیدگاه )
              </span>
              <Rating
                name="read-only"
                value={Number(data?.feedbackInfo?.rate||data?.rate)}
                readOnly
                precision={0.5}
              />
              {/* {tools.dynamicNumber({number:Math.floor(data?.feedbackInfo?.rate * 100) / 100,lang:window.lang})} */}
            </div>
          </p>
          <div className="card-text card-text1">
            <div className="list-group">
              {data?.moreInfo?.address?.city && (
                <span className="group-list-item2 my-1">
                  <i className="bi bi bi-geo-alt mx-2"></i>
                  {`${window.capitalize(data?.moreInfo?.address?.city)},
                    ${window.capitalize(data?.moreInfo?.address?.state)},
                    ${window.capitalize(data?.moreInfo?.address?.countryShort)}
                `}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* <!-- end of card bady --> */}
        {data?.category && (
          <div className="card-footer py-4  ">
            {/* <!-- <a href="#" className="btn btn-primary btn-card stretched-link">  بیشتر</a> --> */}

            <div
              onClick={(e) =>
                navigateHandle(
                  `/${window.lcs}/business/page/${data?._id}`
                )
              }
              className="btn btn-primary btn-card mx-1"
            >
              جزییات بیشتر
            </div>

            <span
              className="icon-stack"
              onClick={(e) =>
                window.open(`tel:${data?.moreInfo?.businessPhone}`)
              }
            >
              <i
                className="bi bi-telephone icon-stack-1x px-1"
                title=" تماس"
              ></i>
            </span>

            <span className="icon-stack ">
              <button className="icon-btn" onClick={saveHandler}>
                {spiner ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <i
                    className={
                      user?.saved !== undefined &&
                      user?.saved?.business?.includes(data?._id)
                        ? "bi bi-bookmark-fill icon-stack-1x"
                        : "bi bi-bookmark icon-stack-1x"
                    }
                    title="ذخیره"
                    style={{ color: "rgba(123, 99, 90, 0.894)" }}
                  ></i>
                )}
              </button>
            </span>
            {location !== "slider" && (
              <span>
                <button
                  className=" icon-stack mx-1 "
                  data-bs-toggle="modal"
                  data-bs-target={`#myModel${data?._id}`}
                  id={`shareBtn${data?._id}`}
                  data-bs-placement="top"
                  title="Share!"
                >
                  <i className="bi bi-share icon-stack-1x" title="همرسانی"></i>
                </button>

                <ShareSocialMedias
                  url={`${window.location.origin}/${window.lcs}/business/page/${data?._id}`}
                  title={data?.busName?.[window.lang]}
                  img={data?.moreInfo?.photos?.businessCard}
                  _id={data?._id}
                />
              </span>
            )}
          </div>
        )}
        {/* <!-- end of card footer --> */}
      </div>
    </div>
  );
}
