import { useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
// import BusCardSliderS1 from "../../golbal/sliders/busCardSliderS1";
// import PlansX4 from "../../golbal/sliders/plansX4";
// import api from "../../../utils/api";
// import HorizontalSlider from "../../golbal/sliders/horizontalSlider";
// import ColoredSpinner from "../../alret/spiners/coloredSpiner/coloredSpinner";
import tools from "../../../utils/tools";
import renderData from "../../../utils/renderData";
import MetaDecorator from "../../../utils/metaDecorator";
// import demyData from "../../../utils/demyData";

export default function DemyTutorHome() {
  // const [pubData] = useOutletContext();
  // const [data, setData] = useState({ center: "", program: "", level: "" });
  // console.log('demy home data' ,data);
  // const [popCat, setPopCat] = useState([]);
  // console.log("pubData", pubData);
  const navigate = useNavigate();
  const page = "demyHome";
  const { country, countryShort, stateShort, state, stateInfo } = tools.lcsn(
    window.location.pathname
  );

  function navigateHandle(url, section) {
    window.scroll(0, 0);
    setTimeout(() => navigate(url), 200);
    const storedFilters = JSON.parse(localStorage.getItem("storedFilters"));
    if (storedFilters && storedFilters[section]) {
      delete storedFilters[section];
      localStorage.setItem("storedFilters", JSON.stringify(storedFilters));
    }
  }
  const { translations } =
    JSON.parse(localStorage?.getItem("countryDetails")) ?? {};
  const title = `MetaDemy | How to become a tutor in    
   ${window.capitalize( country) + ", " + window.capitalize(state)}`;
  const description = ` در متادمی استاد شوید. هنر و مهارتتون رو به درآمدتون تبدیل  و  به راحتی و از خانه‌ درآمد ارزی بدست آورید.`;
  const structuredData = [...renderData.structuredData,
    {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name:
     "MetaDemy" ,
      url: window.location.href,
      "inLanguage": "fa-IR",
      "image": "https://www.metamarce.com/img/Metademy/Metademy-green-red-3.jpg",
      breadcrumb: {
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": "https://www.metamarce.com/",
              name: "landing",
              
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}`,
              name: `home`,
              
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://www.metamarce.com/${window.lcs}/demy/tutor/home`,
              name: `MetaDemy`,              
            },
          },
        ],
      },
      "about": {
        "@type": "Place",
        "name": state?.toUpperCase(),
        "address": {
          "@type": "PostalAddress",
          // "addressLocality": "City",
          "addressRegion": state?.toUpperCase(),
          // "postalCode": "Postal Code",
          "addressCountry": country?.toUpperCase()
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": stateInfo.lat,  // Replace with the actual latitude
          "longitude": stateInfo.lng  // Replace with the actual longitude
        }
      },     
    },
  ];

  const metaTagsData = {
    title,
    description,
    section: "MetaDemy",
    url: `https://www.metamarce.com/${window.lcs}/demy/tutor/home`,
    canonical: `https://www.metamarce.com/${window.lang}/ca/qc/demy/tutor/home`,
    img: "https://www.metamarce.com/img/Metademy/Metademy-green-red-3.jpg",
    type: "webpage",
  };

  return (
    <div className="metademy-page">
      <MetaDecorator data={{ ...metaTagsData, structuredData }} />

      {/* <div className="demy-fp text-center w-100 display-none-md">
        <img
          className="w-100 max-w-1000 mx-auto px-5 "
          src="/img/Metademy/Metademy-green-red.png"
          alt=""
        />
      </div> */}
      {/* <div className="demy-fp text-center w-100 display-tablet ">
        <img
          className="w-100 max-w-1000 mx-auto px-4 "
          src="/img/Metademy/Metademy-mobile-green-red.png"
          alt=""
        />
      </div> */}
      {/* sections */}

      {/* *****menu***** */}
      {/* <div className="text-center max-w-1000 mx-auto my-5">
        <div className="row px-3 py-md-5">
          <div className="col ">
            {" "}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/1.png"
              onClick={(e) => navigateHandle(`/${window.lcs}/demy/tutor/filters`, "tutor")}
              alt="tutor/filters"
            />
          </div>
          <div className="col ">
            {" "}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/2.png"
              onClick={(e) =>  {
                if(!window.logined(9999))return
                navigateHandle(`/${window.lcs}/dashboard/user/demy/registertutor`, "registertutor")
              }}
              alt="tutor/filters"
            />
          </div>
          <div className="col ">
            {" "}
            <img
              className="w-100 demy-cat "
              src="/img/Metademy/3.png"
              onClick={(e) => navigateHandle(`/${window.lcs}/demy/center/filters`, "center")}
              alt="center/filters"
            />
          </div>
        
        </div>

      </div>
     */}

      <div className="mb-5  mx-auto ">
        <div className="row  tutor-frame banner-listing-2 w-100  max-w-m  mx-auto display-none-md">
     
          <nav className="" aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item">
      <Link to={`/${window.lcs}`}>
        خانه
      </Link>
    </li>
    <li className="breadcrumb-item">
      <Link to={`/${window.lcs}/demy`}>
        متادمی
      </Link>
    </li>
    <li className="breadcrumb-item">  
        <strong> تدریس در متادمی</strong>        
    </li>
  </ol>
</nav>

          <img
            className="w-100 p-0 "
            src="/img/Metademy/Metademy-green-red-5.jpg"
            alt="متادمی"
          />
          {/* </div> */}
        </div>
        <div className="demy-fp text-center w-100 display-tablet ">
          <nav
            className="p-md-3  p-2 bg-deepGreen text-beigeYellow "
            style={{ bsBreadcrumbDivider: ">", ariaLabel: "breadcrumb" }}
          >
            <ol className="breadcrumb my-0">
              <Link to={`/${window.lcs}`} className="breadcrumb-item ">
                خانه
              </Link>
              <Link to={`/${window.lcs}/demy`} className="breadcrumb-item">
                متادمی
              </Link>
              <div
                to={`/${window.lcs}/demy/tutor/filters`}
                className="breadcrumb-item"
              >
                <strong> تدریس در متادمی</strong>
              </div>
            </ol>
          </nav>
          <img
            className="w-100 max-w-1000 mx-auto p-0 "
            src="/img/Metademy/Metademy-mobile-green-red-5.jpg"
            alt="تدریس در متادمی"
          />
        </div>
      </div>

      {/* why metademy for tutors */}
      <div className="text-center max-w-1000 mx-auto my-5  ">
        <div>
          <p className="titr5 font-40"> چرا در متادمی تدریس کنم؟</p>
        </div>
        <div className="row px-3 pt-5">
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/investment.png"
                className="card-img-top w-25 mx-auto"
                alt="درآمد ارزی و مطمئن"
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark">
                  {" "}
                  درآمد ارزی و مطمئن
                </h5>
                <p className="card-text">

                بدون نگرانی از دریافت شهریه کلاس و مشکلات تبدیل ارز و با شهریه‌ای که خودت تعیین می‌کنی، از کشورهای گوناگون دانشجو داشته باش.
                  </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/calendar.png"
                className="card-img-top w-25 mx-auto"
                alt="برنامه زمان‌بندی شخصی"
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark">
                  برنامه زمان‌بندی شخصی
                </h5>
                <p className="card-text">
                  می‌تونی کلاس‌هات رو برنامه‌ریزی و زمان‌بندی کنی.

              
              این‌که چه زمانی از روز، چه روزی و چند ساعت در هفته میخوای تدریس کنی، همه با انتخاب خودته.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/speak.png"
                className="card-img-top w-25 mx-auto cursor"
                alt="پیام رسان متامارس"
                onClick={e=> document.getElementById("chatBoxBtn")?.click()}
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark cursor" onClick={e=> document.getElementById("chatBoxBtn")?.click()}>
                پیام رسان متامارس
                </h5>
                <p className="card-text">
                 «متاچت»
                امکان مکالمه مستقیم با دانشجویان و فرستادن فایل رو براتون فراهم کرده.

                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/form-2.png"
                className="card-img-top w-25 mx-auto"
                alt="صفحه اختصاصی"
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark">
                صفحه اختصاصی
                </h5>
                <p className="card-text">
                
در صفحه اختصاصیت در متادمی می‌تونی مهارت‌‌ها
 و تجربیاتت رو به صورت متن یا ویدیو به اشتراک بگذاری 
                  و توانایی تدریست رو به دانشجوهات نشون بدی.
                 
                     </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/location-pin.png"
                className="card-img-top w-25 mx-auto"
                alt="دسترسی جهانی"
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark">
                دسترسی جهانی
                </h5>
                <p className="card-text">
                  فرقی نمی‌کنه در کدوم نقطه از کره زمین زندگی می‌کنی، 
                 می‌تونی از سراسر دنیا دانشجو داشته باشی. 
          
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <div className="card w-100">
              <img
                src="/img/Metademy/resume.png"
                className="card-img-top w-25 mx-auto"
                alt="تقویت رزومه"
              />
              <div className="card-body">
                <h5 className="card-title titr5 text-pink-orange-dark">
                  تقویت رزومه{" "}
                </h5>
                <p className="card-text">
                  ساعت‌های کاریتون در متادمی به عنوان تجربه و سابقه کار ثبت و گواهی تدریس برای آنها صادر خواهد شد.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className="btn primary-btn tutor-btn shadow-none" onClick={e=>{
          if(!window.logined(9999,"tutor"))return
          window.navigateHandle(`/${window.lcs}/dashboard/user/demy/registertutor`)}}>
        
          <i className="bi bi-pencil-square p-1"></i> همین الان ثبت نام کن
        </button>
      </div>

      <div className="px-md-5  mx-0 my-5 m-t-7 tutor-frame">
        <div className="row  banner-listing-2 w-100  max-w-m  mx-auto">
          <div className="col-md-5 img-banner-text-21 d-flex align-items-center justify-content-center p-lg-5 p-4 img-banner-2 ">
            <img className="w-100 rounded-6" src="/img/Metademy/77.jpg" alt="  چطور در متادمی تدریس کنم؟" />
          </div>
          <div className="col-md-7 bg-deepGreen d-flex flex-column align-items-md-start align-items-center">
            <p className="titr1 mx-md-5 mb-0 mt-5 text-pink-orange ">
              چطور در متادمی تدریس کنم؟
            </p>

            <ul className="mx-md-5 my-3 px-1 flex-column text-start text-pink-orange align-items-start">
              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                ساخت حساب کاربری در متامارس
              </li>

              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                پر کردن فرم درخواست تدریس
              </li>

              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                انتخاب روز و ساعت‌‌ مناسب
              </li>
              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                تماس دانشجویان با شما و شروع تدریس
              </li>
             
            </ul>
            <div
              className="btn primary-btn  tutor-btn2 w-75 mx-md-5 mx-0 mt-auto mb-5"
              onClick={e=>{
                if(!window.logined(9999,"tutor"))return
                window.navigateHandle(`/${window.lcs}/dashboard/user/demy/registertutor`)}}
            >
              <i className="bi bi-pencil-square p-1"></i>
              ساخت پروفایل استاد
            </div>
          </div>
        </div>
      </div>

      {/* <div className="px-md-5  mx-0 my-5 m-t-7">
        <div className="row  rounded-3  banner-listing-2 w-100  max-w-m  mx-auto">
          <div className="col-md-5 img-banner-text-21 d-flex align-items-center justify-content-center p-lg-5 img-banner-2 ">
            <img className="w-100 px-5" src="/img/Metademy/11.png" alt="" />
          </div>
          <div className="col-md-7 bg-deepGreen d-flex flex-column align-items-md-start align-items-center">
          
            <ul className="mx-md-5 my-3 px-1 flex-column text-start text-pink-orange align-items-start">
              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                ساخت حساب کاربری در متامارس
              </li>

              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                پر کردن فرم درخواست تدریس
              </li>

              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                انتخاب روز و ساعت‌‌ مناسب
              </li>
              <li className="my-4">
                <i className="bi bi-circle-fill fs-6 mx-2"></i>
                تماس دانشجویان با شما و شروع تدریس
              </li>
            </ul>
            <div
              className="btn primary-btn  tutor-btn2 w-75 mx-md-5 mx-0 mt-auto mb-5"
              onClick={(e) =>
                navigateHandle(`/${window.lcs}/demy/registertutor`)
              }
            >
              <i className="bi bi-pencil-square p-1"></i>
              ساخت پروفایل استاد
            </div>
           
          </div>
        </div>
      </div> */}
      <div className="my-5  mx-auto ">
        <div className="row  tutor-frame banner-listing-2 w-100  max-w-m  mx-auto display-none-md">
          <img
            className="w-100 p-0 "
            src="/img/Metademy/Metademy-green-red-3.jpg"
            alt="Metademy-green-red"
          />
          {/* </div> */}
        </div>
        <div className="demy-fp text-center w-100 display-tablet ">
          <img
            className="w-100  p-0 "
            src="/img/Metademy/Metademy-mobile-green-red-3.jpg"
            alt="Metademy-mobile-green-red"
          />
        </div>
      </div>

      <div className="px-md-5   mx-0 my-5 m-t-7">
        <div className="row   banner-listing-2 w-100  max-w-m  mx-auto">
          <div className="col-md-8 d-flex flex-column align-items-center">
            <div className="titr1  mb-0 mt-5 ">
              <div className="m-2 mb-5 m-md-5 ">
                
                از هر جایی و در هر زمانی می‌تونی کلاست رو برگزار کنی!
              </div>
            </div>

            <div
              className="btn primary-btn  tutor-btn w-75 mx-md-5 mx-0 mt-auto mb-5"
              onClick={e=>{
                if(!window.logined(9999,"tutor"))return
                window.navigateHandle(`/${window.lcs}/dashboard/user/demy/registertutor`)}}
            >
              <i className="bi bi-pencil-square p-1"></i>
              ساخت پروفایل استاد
            </div>
            {/* <!-- </button> --> */}
          </div>
          <div className="col-md-4  d-flex align-items-center justify-content-center p-lg-5 p-5 img-banner-2 ">
            <img
              className="w-100 rounded-6"
              src="/img/Metademy/131.jpg"
              alt="از هر جایی کلاست رو برگزار کن"
            />
          </div>
        </div>
      </div>
      <div className="px-md-5 py-5 max-w-m  mx-auto rounded bg-pinkOrange mx-0 my-10 ">
        <div className="text-center  w-100  ">
        {/* <p className="titr1  mb-0 mt-5 ">
              <div className="m-2 mb-5 m-md-5 text-pink-orange ">
                
                از هر جا و در هر زمان می‌تونی کلاست رو برگزار کنی!
              </div>
            </p> */}

            <div
              className="btn primary-btn  tutor-btn font-20"
              onClick={(e) =>
                navigateHandle(`/${window.lcs}/prompt/tutor`)
              }
            >
              <i className="bi bi-file-text p-1 font-20">   راهنما و قوانین متادمی</i>
          
            </div>

          
        </div>
      </div>
      {/* <div className="bg-pinkOrange  py-5 ">
        <div className="text-beigeYellow text-center titr1 font-18 py-5 ">
          همین امروز ثبت نام کن و دانش و هنرت رو با دیگران به اشتراک بگذار.{" "}
        </div>
      </div> */}

      {/* <div className="my-5  mx-auto ">
        <div className="row  tutor-frame banner-listing-2 w-100  max-w-m  mx-auto display-none-md">
          <img
            className="w-100 p-0 "
            src="/img/Metademy/Metademy-green-red-2.png"
            alt=""
          />
        </div>
        <div className="demy-fp text-center w-100 display-tablet ">
          <img
            className="w-100 max-w-1000 mx-auto p-0 "
            src="/img/Metademy/Metademy-mobile-green-red-2.png"
            alt=""
          />
        </div>
      </div> */}

      {/* {/* <!-- ******************************* --> */}
      <div className="mx-0 py-0">
        {/* <div className="row shift-down"></div> */}

        <div className=" pb-2 pt-3">
          {/* Why Metamarce for media */}

          {/* <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">برگزیدگان</span>
            </div>
            <BusCardSliderS1 plansS1={pubData?.ads?.plansS1} page={page} />
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
              </svg>
              <span className="p-0 mb-0 ">معرفی ویژه</span>
            </div>

            <PlansX4 plansX4={pubData.ads?.plansX4} page={page} />
          </div> */}
        </div>

        <div className="mx-md-5  ">
          {/* <!-- تازه‌های جابز --> */}
          {/* <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                
              </svg>
              <span className="p-0 mb-0 "> تازه‌های کاریابی</span>
            </div>
            <HorizontalSlider data={pubData?.newJobs} section="jobs" />
          </div> */}
          {/* <!-- تازه‌های جابز end of --> */}

          {/* <!-- تازه‌های بلاگ --> */}
          {/* <div className="mx-md-5">
            <div className="divider2 ">
               
              <svg
                className="text-dark-550 dark:text-white ml-2"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="27" r="11" fill="currentColor"></circle>
                <circle
                  cx="35"
                  cy="16"
                  r="8"
                  fill="currentColor"
                  fillOpacity="0.4"
                ></circle>
                <circle
                  cx="20"
                  cy="8"
                  r="5"
                  fill="currentColor"
                  fillOpacity="0.7"
                ></circle>
                
              </svg>
              <span className="p-0 mb-0 ">تازه‌های بلاگ</span>
            </div>

            <HorizontalSlider data={pubData?.newPosts} section="blog" />
          </div> */}
          {/* <!-- تازه‌های بلاگ end of --> */}
        </div>

        {/* <div className=" m-0 p-0 img-banner-text-11 ">
          <div className="row  banner-listing-2 w-100  ">
            <div className="col-md-6 d-flex align-items-center justify-content-end p-lg-5 img-banner-2 display-none-md">
              <img
                className="w-100 px-md-5 "
                src="/img/Metademy/11.png"
                alt=""
                loading="lazy"
                style={{ maxWidth: "400px" }}
              />
            </div>

            <div className="col-md-6 bg-deepGreen  d-flex flex-column align-items-md-start align-items-center">
              <div>
                <div style={{ position: "relative" }}>
                  <p className="titr1 mb-0 mt-3 ">چرا متادمی ؟</p>
                </div>
                <ul className="my-3 flex-column  align-items-start text-start">
                  <li className="my-2 titr5" style={{ color: "var(--beige)" }}>
                    برای کارفرماها
                  </li>

                  <li className="my-2">
                    <i
                      className="bi bi-circle-fill fs-6 mx-2"
                      style={{ color: "var(--brown)" }}
                    ></i>
                    جذب نیروی کار
                  </li>
                </ul>
                <div
                  className="btn primary-btn view-cart cart-footer w-75 mx-md-5 mx-0 mb-5"
                  onClick={(e) => {
                    if (!window.logined(9999)) return;
                    window.scroll({ top: 0, behavior: "smooth" });
                    navigateHandle(
                      `/${window.lcs}/dashboard/user/jobs/register`
                    );
                  }}
                >
                  <i className="bi bi-pencil-square p-1"></i>
                  افزودن فرصت شغلی
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- ********** back to top************************* --> */}
        <div className="row backtotop">
          <div className="col-12">
            <a
              href="#top"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="بازگشت به آغاز صفحه"
              className="tooltip-color"
            >
              <i className=" bi bi-arrow-up-circle-fill backtotop-btn"></i>
            </a>
          </div>
        </div>
      </div>
      {/* <!-- *********************************** end of main ******************************************************************** --> */}

      {/* <!-- ***JavaScript*** --> */}

      {/* <div>
       
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
          crossOrigin="anonymous"
        ></script>

      
      </div> */}
    </div>
  );
}
