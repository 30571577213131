
import { Outlet } from 'react-router-dom'

export default function PromptOut() {
  return (
    <div>     
    <Outlet />
    </div>
  )
}
