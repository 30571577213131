import { Helmet } from "react-helmet-async";

export default function MetaDecorator ({data}){
  // console.log('MetaDecorator',data);
  return (<Helmet prioritizeSeoTags>
<title>{data.title}</title>
<meta name="description" content={data.description} />
<link rel="canonical" href={data?.canonical||data?.url} hrefLang={window.lang} />
<meta  name="robots" content={`${data?.index===false?"no-index":"index"}, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1`}/>
 <meta property="og:title" content={data?.title} />
 <meta property="og:description"   content={data?.description} />
 <meta property="fb:app_id" content="975901429720656" />
 <meta property="og:section" content={data?.section} />
 <meta property="og:image-alt" content={data?.title} />
 <meta property="og:site_name" content="metamarce" />
 <meta property="og:url" content={data?.url} />
<meta property="og:type" content={data?.type||"website"} />
<meta property="og:image" content={data?.img} />
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content={data?.title}/>
<meta name="twitter:description" content={data?.description}/>
<meta name="twitter:image" content={data?.img}/>
<meta property="og:locale" content={`${window?.lang==="fa"?window?.lang+'-IR':"en-US"}`} />
<script type="application/ld+json">{JSON.stringify(data?.structuredData)}</script>
  </Helmet>)
}




