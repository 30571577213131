// import "../../../userList/userList.css";
import { DeleteOutline, Visibility } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import api from "../../../../../utils/apiBusiness";
import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
import renderData from "../../../../../utils/renderData";
import MyIcons from "../../../../golbal/icons/myIcons";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
import ToastNote from "../../../../golbal/notifications/toastNote";

export default function BusinessList({ access, action }) {
  const { user } = useContext(CartContext);
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [user2, setUser2] = useState({ _id: user._id });
  const [countryShort,stateShort] = window.location.pathname.toLowerCase().split("/").slice(2, 4)  
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    busStatus: ["02-claimed"],
    access,
    countryShort,
    stateShort,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [backFilters, setBackFilters] = useState({ ...filters });
  const messageRef = useRef(null);
  console.log('selectedRows',selectedRows);
  const deleteHandle = async (bus) => {
    console.log("deleteHandle", bus._id);
    if (
      window.confirm(`You are about to delete : "${bus.busName?.[window.lang]}"`) !== true
    )
      return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.deleteBus({ token: user.token, id: bus._id });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: response.message });
    const newData = data.filter((da) => da._id !== bus._id);
    setData(newData);
  };
  const changeStatus = async ({ bus, action }) => {
    console.log("changeStatus", bus._id);
    if (
      window.confirm(
        `You are about to ${action.substring(3)} : "${bus.busName?.[window.lang]}"`
      ) !== true
    )
      return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.changeStatus({
      token: user.token,
      id: bus._id,
      action,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    messageRef.current.showSuccess({ message: response.message });
    const newData = data.map((da) => (da._id === bus._id ? response.data : da));
    setData(newData);
  };

  useEffect(() => {
    fetchListData();
  }, [filters, user2._id]);

  async function fetchListData() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.listBusinesses({
      token: user.token,
      filters,
      id: user2._id,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }
  function showPageHandle(props) {
    let text = "";
    if (window.lang !== props.moreInfo.businessLng.toLowerCase())
      text += `شما در حال انتقال به زبان ${window.capitalize(
        props.moreInfo.businessLng
      )} میباشد . \n`;
    if (
      countryShort.toLowerCase() !==
      props.moreInfo.address.countryShort.toLowerCase()
    )
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.country
      )} میباشد . \n`;
    if (
      stateShort.toLowerCase() !==
      props.moreInfo.address.stateShort.toLowerCase()
    )
      text += `شما در حال انتقال به استان ${window.capitalize(
        props.state
      )} میباشد . \n`;

    if (!text.length)
      return navigate(
        `/${window.lcs}/business/page/${props._id}`
      );

    if (window.confirm(text) != true) return;

    navigate(
      `/${
        props.moreInfo.businessLng +
        "/" +
        props.moreInfo.address.countryShort +
        "/" +
        props.moreInfo.address.stateShort
      }/business/page/${props._id}`
    );
  }
  function editBusHandle({ props }) {
    let text = "";
    if (window.lang !== props.moreInfo.businessLng.toLowerCase())
      text += `شما در حال انتقال به متامارس با زبان ${window.capitalize(
        props.moreInfo.businessLng
      )} میباشد . \n`;
    if (
      countryShort.toLowerCase() !==
      props.moreInfo.address.countryShort.toLowerCase()
    )
      text += `شما در حال انتقال به کشور ${window.capitalize(
        props.country
      )} میباشد . \n`;
    if (
      stateShort.toLowerCase() !==
      props.moreInfo.address.stateShort.toLowerCase()
    )
      text += `شما در حال انتقال به استان ${window.capitalize(
        props.state
      )} میباشد . \n`;
    const url = `/${
      props.moreInfo.businessLng +
      "/" +
      props.moreInfo.address.countryShort +
      "/" +
      props.moreInfo.address.stateShort
    }/dashboard/user/business/edit/${props._id}`;
    console.log(url);

    if (!text.length) return navigate(url, { state: props });

    if (window.confirm(text) != true) return;
    console.log("PAssed");

    navigate(url, { state: props });
  }

  const columns = [
    //   { field: '_id', headerName: 'ID', width: 70 },
    {
      field: "action",
      headerName: "عملیات",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="d-flex">
            <div onClick={(e) => showPageHandle(params.row)}>
              <MyIcons icon="display" />
            </div>
            <div className="d-flex">
              <div
                className=""
                onClick={(e) => editBusHandle({ props: params.row })}
              >
                <MyIcons icon="edit" />
              </div>
              {params.row.moreInfo.status !== "00-deleted" ? (
                <div onClick={() => deleteHandle(params.row)}>
                  <MyIcons icon="delete2" />
                </div>
              ) : (
                <div
                  onClick={(e) =>{
                   
                    changeStatus({ bus: params.row, action: "01-draft" })}
                  }
                >
                  <MyIcons icon="activate" />
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "status",
      width: 70,
      renderCell: (params) => {
        const status = renderData.validation.find(
          (va) => va.name === params.row.moreInfo.status.substring(3)
        )?.[window.lang];

        return (
          <div title={status}>{params.row.moreInfo?.status.substring(3)}</div>
        );
      },
    },
    {
      field: "img",
      headerName: "cover",
      width: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/${window.lcs}/business/page/${params.row._id}`}
            state={params.row}
          >
            <img
              src={params.row.moreInfo.photos.businessCard}
              alt="businessCard"
              className="userListImg"
              loading="lazy"
            />
          </Link>
        );
      },
    },

    {
      field: "busName",
      headerName: "BusName", 
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            to={`/${window.lcs}/business/page/${params.row._id}`}
            state={params.row}
          >
            <div title={params.row?.busName?.[window.lang]}>
              
              {params.row?.busName?.[window.lang]}
            </div>
            <div title={params.row?.email}> {params.row?.email}</div>
          </Link>
        );
      },
    },
    {
      field: "createdByName",
      headerName: "Created by",
      width: 200,
      renderCell: (params) => (
        <Link
          to={`/${window.lcs}/dashboard/business/createdBy/${
            params.row.moreInfo?.history?.createdBy?._id
          }`}
          state={params.row}
        >
          <div title={params.row.moreInfo?.history?.createdBy?.name}>
            {params.row.moreInfo?.history?.createdBy?.name}
          </div>
          <div title={params.row.moreInfo?.history?.createdBy?.email}>
            {params.row.moreInfo?.history?.createdBy?.email}
          </div>
        </Link>
      ),
    },

    {
      field: "name",
      headerName: "Claimed by",
      width: 150,
      renderCell: (params) => {
        return (
          <div>           
            <div title={params.row.moreInfo.history?.claimedBy[0]?.name}>
              {params.row.moreInfo.history?.claimedBy[0]?.name}
            </div>
            <div title={params.row.moreInfo.history?.claimedBy[0]?.email}>
              {params.row.moreInfo.history?.claimedBy[0]?.email}
            </div>
          </div>
        );
      },
    },
    {
      field: "verifiedBy",
      headerName: "Verified by",
      width: 150,
      renderCell: (params) => {
        return (
          <div>           
            <div title={params.row.moreInfo.history?.verifiedBy[0]?.name}>
              {params.row.moreInfo.history?.verifiedBy[0]?.name}
            </div>
            <div title={params.row.moreInfo.history?.verifiedBy[0]?.email}>
              {params.row.moreInfo.history?.verifiedBy[0]?.email}
            </div>
          </div>
        );
      },
    },

    {
      field: "moreInfo.address.moreInfo",
      headerName: "Country",
      width: 100,
      renderCell: (params) => (
        <div title={params.row.moreInfo.address.country}>
          {params.row.moreInfo.address.country}
        </div>
      ),
    },
    {
      field: "moreInfo.address.state",
      headerName: "State",
      width: 100,
      renderCell: (params) => (
        <div title={params.row.moreInfo.address.state}>
          {params.row.moreInfo.address.state}
        </div>
      ),
    },

    //   { field: 'transaction',  headerName: 'Transaction', width: 90,},

    //   { field: 'transaction',  headerName: 'Transaction', width: 90,},
  ];

  return (
    <div className="userList" style={{ height: "90vh", width: "100%" }}>
      {loading.spinner && <ColoredSpinner />}
      <ToastNote messageRef={messageRef} />
      <Filters />
      <DataGrid
        getRowId={(row) => row._id}
        rows={data}
        components={{
          Toolbar: GridToolbar,
        }}
        disableSelectionOnClick
        columns={columns}
        pageSize={[12]}
        rowsPerPageOptions={[1]}
        checkboxSelection
        onSelectionModelChange={e=>setSelectedRows(e)}
      />
      {access === "admin" && (
        <SearchBarUser setUser2={setUser2} user2={user2} />
      )}
    </div>
  );

  function Filters(params) {
    return (
      <div className="d-flex flex-row">
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-primary button-filter dropdown-toggle"
            data-bs-toggle="dropdown"
            // id="#filter5"
            aria-expanded="false"
            // aria-controls="filter5"
          >
            <span>
              <i className="bi bi-chat-text me-1"></i>
            </span>
            Business Status
            <span className="ps-3">
              <i className="bi bi-chevron-down"></i>
            </span>
          </button>

          {/* <!-- Modal --> */}

          <div className="dropdown-menu px-0 checkbox-form">
            <div
              className="reset-btn w-100"
              onClick={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  busStatus: [],
                  pageNumber: 1,
                }));
                setBackFilters((prev) => ({
                  ...prev,
                  busStatus: [],
                  pageNumber: 1,
                }));
              }}
            >
              Delete filters
            </div>
            <div
              className="reset-btn w-100"
              onClick={(e) => {              
                setFilters(backFilters);
              }}
            >
              Apply filters
            </div>
            <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <input
                className="form-check-input "
                type="checkbox"
                name="flexRadioDefault"
                id="draftInput"
                value="01-draft"
                checked={backFilters?.busStatus?.includes("01-draft")}
                onChange={(e) => {
                  const busStatus = backFilters?.busStatus
                    ? [...backFilters.busStatus]
                    : [];
                  const value = e.target.value;
                  const index = busStatus.indexOf(value);
                  if (index !== -1) {
                    busStatus.splice(index, 1);
                  } else {
                    busStatus.push(value);
                  }
                  setBackFilters((pre) => ({ ...pre, busStatus }));
                }}
              />
              <label className="form-check-label " htmlFor="draftInput">
                draft
              </label>
            </div>
            <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <input
                className="form-check-input "
                type="checkbox"
                name="flexRadioDefault"
                id="claimedInput"
                value="02-claimed"
                checked={backFilters?.busStatus?.includes("02-claimed")}
                onChange={(e) => {              
                  const busStatus = backFilters?.busStatus
                    ? [...backFilters.busStatus]
                    : [];
                  const value = e.target.value;
                  const index = busStatus.indexOf(value);
                  if (index !== -1) {
                    busStatus.splice(index, 1);
                  } else {
                    busStatus.push(value);
                  }
                  setBackFilters((pre) => ({ ...pre, busStatus }));
                }}
              />
              <label className="form-check-label " htmlFor="claimedInput">
                Claimed
              </label>
            </div>
            <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <input
                className="form-check-input "
                type="checkbox"
                name="flexRadioDefault"
                id="verifiedInput"
                value="03-verified"
                checked={backFilters?.busStatus?.includes("03-verified")}
                onChange={(e) => {
                  const busStatus = backFilters?.busStatus
                    ? [...backFilters.busStatus]
                    : [];
                  const value = e.target.value;
                  const index = busStatus.indexOf(value);
                  if (index !== -1) {
                    busStatus.splice(index, 1);
                  } else {
                    busStatus.push(value);
                  }
                  setBackFilters((pre) => ({ ...pre, busStatus }));
                }}
              />
              <label className="form-check-label " htmlFor="verifiedInput">
                Verified
              </label>
            </div>
            <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <input
                className="form-check-input "
                type="checkbox"
                name="flexRadioDefault"
                id="trustedInput"
                value="04-trusted"
                checked={backFilters?.busStatus?.includes("04-trusted")}
                onChange={(e) => {
                  const busStatus = backFilters?.busStatus
                    ? [...backFilters.busStatus]
                    : [];
                  const value = e.target.value;
                  const index = busStatus.indexOf(value);
                  if (index !== -1) {
                    busStatus.splice(index, 1);
                  } else {
                    busStatus.push(value);
                  }
                  setBackFilters((pre) => ({ ...pre, busStatus }));
                }}
              />
              <label className="form-check-label " htmlFor="trustedInput">
                Trusted
              </label>
            </div>
            <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <input
                className="form-check-input "
                type="checkbox"
                name="flexRadioDefault"
                id="deletedInput"
                value="00-deleted"
                checked={backFilters?.busStatus?.includes("00-deleted")}
                onChange={(e) => {
                  const busStatus = backFilters?.busStatus
                    ? [...backFilters.busStatus]
                    : [];
                  const value = e.target.value;
                  const index = busStatus.indexOf(value);
                  if (index !== -1) {
                    busStatus.splice(index, 1);
                  } else {
                    busStatus.push(value);
                  }
                  setBackFilters((pre) => ({ ...pre, busStatus }));
                }}
              />
              <label className="form-check-label " htmlFor="deletedInput">
                Deleted
              </label>
            </div>
          </div>
        </div>
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-primary button-filter dropdown-toggle"
            data-bs-toggle="dropdown"
            // id="#filter5"
            aria-expanded="false"
            // aria-controls="filter5"
          >
            <span>
              <i className="bi bi-chat-text me-1"></i>
            </span>
            Action
            <span className="ps-3">
              <i className="bi bi-chevron-down"></i>
            </span>
          </button>

          {/* <!-- Modal --> */}

          <div className="dropdown-menu px-0 checkbox-form">
            <div
              className="reset-btn w-100"
              onClick={postTelegramHandle}
            >
              Post on Telegram
            </div>
          
            {/* <div className="form-check px-3 d-flex justify-content-between flex-row-reverse">
              <div className="form-check-label" onClick={postTelegramHandle}>Post To Telegram</div>
             </div> */}
         
          </div>
        </div>
      </div>
    );
  }
  async function postTelegramHandle (e) {
    e.preventDefault()
    if(!selectedRows.length)return messageRef.current.showError({message:"Please choose at least one business."})
    if(window.confirm(`You are about to post ${selectedRows.length} business on telegram`)!== true)return
    const response = await api.postTelegram({token:user.token , data:selectedRows})
  if(response.error)return messageRef.current.showError({message:response.error})
   messageRef.current.showSuccess({message:response.message})
  }
}
