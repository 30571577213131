import { Outlet } from "react-router-dom"



export default function BlogDahOut() {
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
   <h1>Dashbord Blog Out </h1>
    <Outlet/>
        
    </div>
  )
}
