import axios from "axios"

export default new (class{
filterMedias = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {
const response = await axios.get(`/api/media?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

likeHandler = async({id , token})=>{  
  try {
const response = await axios.get(`/api/media/${id}/like` ,
{
headers :{
  Authorization : `Bearer ${token}`,
  }      
})
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
return  {error: error?.response?.data?.message || error.message}
}
}
disLikeHandler = async({id , token})=>{  
  try {
const response = await axios.get(`/api/media/${id}/dislike` ,
{
headers :{
  Authorization : `Bearer ${token}`,
  }      
})
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}


register = async({token , values })=>{
  console.log("Media register",values );
  
  try {
    const response = await axios.post(`/api/media/register` ,
    {values} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  edit = async({token , values })=>{
    console.log("edit ",values );
    
    try {
      const response = await axios.put(`/api/media/register` ,
      {values} ,{
        headers :{
          Authorization : `Bearer ${token}`,
         }    
      })
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
    
    } catch (error) {
       console.log(error);
        return  {error: error?.response?.data?.message || error.message}
    }
    }
fetchDetails = async(id)=>{
  // console.log("revLikeCounter");
  try {
  const response = await axios.get(`/api/media/details/${id}` 
  )
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  getListData = async({token,access,id})=>{
    try {
    const response = await axios.get(`/api/media/list/${id}?access=${access}`,{ headers :{
      Authorization : `Bearer ${token}`,
     }
    
    })
    
    
    // console.log(response.data);
    if(response.status!==200) throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }



 userSavedList = async({token,savedItems})=>{
  try {  
  const response = await axios.post(`/api/media/user/list/saved`,{savedItems} ,{
    headers :{
      Authorization : `Bearer ${token}`,
     }}
     )
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }
  changeStatus = async({token,id,action})=>{
    try {
    const response = await axios.get(`/api/media/changeStatus/${id}?action=${action}`,{ headers :{
      Authorization : `Bearer ${token}`,
     }
    
    })
    
    
    // console.log(response.data);
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }

    getTags = async({ filters})=>{    
    let filters1=""  
    for (let key in filters) {
      // console.log(`${key}: ${filters[key]}`);
      filters1 += `${key}=${filters[key]}&`
    }

    try {
      const response = await axios.get(`/api/media/tags?${filters1}`)
        
        if(response.status!==200)  throw new Error(response.data.message)      
        return response.data
    
    } catch (error) {             
        return  {error: error?.response?.data?.message || error.message}
    }
    }

    getPopCat = async({filters})=>{
      // console.log(filters);  
    let filters1=""  
    for (let key in filters) {
      // console.log(`${key}: ${filters[key]}`);
      filters1 += `${key}=${filters[key]}&`
    }
    try {
    const response = await axios.get(`/api/media/popCat?${filters1}`)
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    
    return  {error: error?.response?.data?.message || error.message}
    }
    }

})()