import axios from "axios";

export default new( class  {  
 
   
  addPromo = async({token,promInfo})=>{  
        try {        
        const response = await axios.post(`/api/store/prom`,{promInfo} ,{
          headers :{
            Authorization : `Bearer ${token}`,
           }}
           )
        
        
        // console.log(response.data);
        if(response.status!==201)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
  promoList = async({token})=>{
     console.log("promoList");
        try {        
        const response = await axios.get(`/api/store/prom`,{
          headers :{
            Authorization : `Bearer ${token}`,
           }}
           )       
        
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
  getPromDetails = async({token,promCode})=>{  
    // return console.log(token,promCode);
    try {        
    const response = await axios.put(`/api/store/prom`,{promCode, date: new Date()},{
      headers :{
        Authorization : `Bearer ${token}`,
        }}
        )       
    
    // console.log(response.data);
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }
  fetchRefCodeDetails = async({token,refCode})=>{  
    // return console.log(token,refCode);
    try {        
    const response = await axios.put(`/api/store/refcode`,{refCode, date: new Date()},{
      headers :{
        Authorization : `Bearer ${token}`,
        }}
        )       
    
    // console.log(response.data);
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    
    } catch (error) {
    console.log(error);
    return  {error: error?.response?.data?.message || error.message}
    }
    }
  getPaypalClientId = async({token})=>{
    //  console.log("getPaypalClientId");
        try {        
        const response = await axios.get(`/api/store/paypal/clientId`,{
          headers :{
            Authorization : `Bearer ${token}`,
           }}
           )       
        
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }
  createOrder =async({order,token})=>{
    try {
      const response = await axios.post('/api/orders',order,{
        headers:{
          Authorization:`Bearer ${token}`,
        }
      });
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.data.message} 
    }
      }
    deleteRow =async({id,token,userId})=>{
    try {
      const response = await axios.delete(`/api/orders?userId=${userId}&orderId=${id}`,{
        headers:{
          Authorization:`Bearer ${token}`,
        }
      });
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.data.message} 
    }
      }
    
  getOrder= async({id,token})=>{

    try {
      const response = await axios.get(`/api/orders/${id}/pay` , {
        headers:{
          Authorization : `Bearer ${token}`,
        }
      })
      console.log(response);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
        } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
    }
    }
    getOrdersList= async({id,token,access,section})=>{
    console.log('getOrdersList');
      try {
        const response = await axios.get(`/api/orders/user/${id}?access=${access}&section=${section}` , {
          headers:{
            Authorization : `Bearer ${token}`,
          }
        })
        console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
          } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
      }
      }
    fetchRefcodeTrans= async({id,token,access,section})=>{
    // console.log('fetchRefcodeTrans');
      try {
        const response = await axios.get(`/api/orders/user/reports/refCode/trans/${id}?access=${access}&section=${section}` , {
          headers:{
            Authorization : `Bearer ${token}`,
          }
        })
        // console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
          } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
      }
      }
      fetchAffiliateTrans= async({id,token,access,section,affiliateCode})=>{
    // console.log('fetchAffiliateTrans');
      try {
        const response = await axios.get(`/api/orders/user/reports/affiliate/trans/${id}?access=${access}&section=${section}&affiliateCode=${affiliateCode}` , {
          headers:{
            Authorization : `Bearer ${token}`,
          }
        })
        // console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
          } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
      }
      }
      getCountriesSales= async({token , type})=>{
    console.log('getCountriesSales');
      try {
        const response = await axios.get(`/api/orders/reports?type=${type}` , {
          headers:{
            Authorization : `Bearer ${token}`,
          }
        })
        console.log(response);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
          } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
      }
      }
  

  payOrder = async({token, id , paymentResult})=>{
console.log("payOrder");
  try {        
  const response = await axios.put(`/api/orders/${id}/pay`,{paymentResult},{
    headers :{
      Authorization : `Bearer ${token}`,
      }}
      )       
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }

  createAds =async({data,token,lang,orderId})=>{
    console.log(data);
    try {
      const response = await axios.post('/api/store/ads',{data,lang,orderId},{
        headers:{
          Authorization:`Bearer ${token}`,
        }
      });
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.data.message} 
    }
      }
      fetchAds =async({filters,yearMonth})=>{
    // console.log(filters);
    try {
      const response = await axios.get(`/api/store/ads?yearMonth=${yearMonth}&lang=${filters.lang}`);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
    } catch (error) {
      return  {error: error?.response?.data?.message || error.data.message} 
    }
      }
        
    

})()