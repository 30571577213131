import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useContext, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import ToastNote from "../../notifications/toastNote";
import api from "../../../../utils/apiStore";
import { CartContext } from "../../../contexts/cartContext";
import FormikErrRender from "../../../persian/common/alerts/formikErrRender";
import DatePickerRender from "../../datePicker";

export default function AddPromo() {
  const { user } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const messageRef = useRef(null);
  const regexpromCode = /^[A-Z0-9#]+$/;

  const addPromoHandle = async (values) => {
    console.log("addPromoHandle", values);
    if (window.confirm("با ایجاد کد پروموشن موافقم.") !== true) return;
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await api.addPromo({
      token: user.token,
      promInfo: values,
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({
        message: (
          <div>
            <div> مشکل در انجام عملیات !</div>
            <div>{response.error}</div>
          </div>
        ),
      });
    messageRef.current.showSuccess({ message: response.message });
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      cycle: "",
      promCode: "",
      dates: {
        start: "",
        end: "",
      },
      discountType: "",
      discPercent: "",
      discAmount: "",
    },
    onSubmit: async (values) => {
      addPromoHandle(values);
    },
    validationSchema: Yup.object({
      cycle: Yup.string().required("Please select one of the options !"),
      promCode: Yup.string()
        .matches(
          regexpromCode,
          "Please choose between capital letters , numbers and #"
        )
        .required("The promtion code is required !")
        .max(12, "the code must be less than 12 charcters !")
        .min(6, "the code must be more than 6 charcters !")
        .trim(),

      dates: Yup.object().shape({
        start: Yup.date()
          .required("Please select the starting Date")
          .typeError("Invalid start date")
          .min(new Date(), "Start date cannot be in the past"),
        end: Yup.date()
          .required("Please select the ending Date !")
          .typeError("Invalid end date !")
          .min(Yup.ref("start"), "End date must be after start date !"),
      }),
      discountType: Yup.string().required("Discount type is required"),
      discPercent: Yup.number().when("discountType", {
        is: "percent",
        then: Yup.number()
          .required("Discount percentage is required")
          .test("is-percentage", "Invalid percentage", function (value) {
            if (value < 0 || value > 100) {
              return false;
            }
            return true;
          }),
      }),
      discAmount: Yup.number().when("discountType", {
        is: "fix",
        then: Yup.number()
          .required("Discount amount is required")
          .max(100, "Dicount cant be more than $100"),
      }),
    }),
  });

  console.log(formik.values);
  return (
    <div className="featured">
      <ToastNote messageRef={messageRef} />

      <div className="featuredItem">
        <form
          onSubmit={formik.handleSubmit}
          name="addPromoForm"
          autoComplete="true"
        >
          <h4>Create Promotion</h4>

          <div>
            <div>Choose promotion cycle</div>
            <select
              name="selectCycle"
              type="text"
              {...formik.getFieldProps("cycle")}
            >
              <option value={""}>Select</option>
              <option value={"oneTime"}>One time</option>
              <option value={"periodic"}>Periodic</option>
            </select>
            <FormikErrRender formik={formik} field="cycle" />
          </div>

          <div>
            <div>Choose discount type</div>
            <select
              name="discountTypeSelect"
              type="text"
              {...formik.getFieldProps("discountType")}
            >
              <option value={""}>Select</option>
              <option value={"percent"}>Percentage</option>
              <option value={"fix"}>Fix amount</option>
            </select>
            <FormikErrRender formik={formik} field="discountType" />
          </div>

          {formik.values.discountType === "percent" && (
            <div>
              <div>Enter discount by percentage</div>
              <input type="percent" {...formik.getFieldProps("discPercent")} />
              <FormikErrRender formik={formik} field="discPercent" />
            </div>
          )}

          {formik.values.discountType === "fix" && (
            <div>
              <div>Enter discount by fix Amount</div>
              <input
                type="text"
                {...formik.getFieldProps("discAmount")}
                autoComplete="off"
              />
              <FormikErrRender formik={formik} field="discAmount" />
            </div>
          )}

          <div className="m-3">
            <div>Plese choose the starting and ending date</div>
            <div>
              <span>From</span>
              <DatePickerRender formik={formik} field="start" />
              <FormikErrRender formik={formik} field="dates.start" />
            </div>
            <div>
              <span>to</span>
              <DatePickerRender formik={formik} field="end" />
              <FormikErrRender formik={formik} field="dates.end" />
            </div>
          </div>

          <div>
            <label htmlFor="promCodeIn">
              Plese enter your desired promotion code
            </label>
            <input
              type="text"
              autoComplete="off"
              id="promCodeIn"
              style={{ textTransform: "uppercase" }}
              onBlur={(e) =>
                formik.setValues((pre) => ({
                  ...pre,
                  promCode: e.target.value.toUpperCase(),
                }))
              }
            />
            <FormikErrRender formik={formik} field="promCode" />
          </div>

          <div>
            <button
              disabled={formik.isSubmitting || loading.spinner}
              type="submit"
              className="btn  btn-secondary mx-auto w-50 login-btn "
            >
              {loading.spinner ? (
                <CircularProgress color="inherit" size="inherit" />
              ) : (
                "Send"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  function startHandle(newTime) {
    console.log("startHandle");
    const date = dayjs(newTime).toDate();
    const date2 = new Date(date);
    formik.setValues((pre) => ({
      ...pre,
      startDate: date2,
    }));
  }
  function endHandle(newTime) {
    console.log("startHandle");
    const date = dayjs(newTime).toDate();
    const date2 = new Date(date);
    formik.setValues((pre) => ({
      ...pre,
      endDate: date2,
    }));
  }
}
