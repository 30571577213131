import axios from "axios"

export default new (class{
filterBusinesses = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/business?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
BusCardsSlider = async({filters})=>{
  console.log(filters);
  
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}


try {

const response = await axios.get(`/api/business/slider?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {

return  {error: error?.response?.data?.message || error.message}
}
}

getPopCat = async({filters})=>{
  // console.log(filters);  
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}
try {
const response = await axios.get(`/api/business/PopCat?${filters1}`)
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {

return  {error: error?.response?.data?.message || error.message}
}
}
getLocations = async({filters})=>{
  // console.log(filters);
let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}

try {

const response = await axios.get(`/api/business/locations?${filters1}`)


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

listBusinesses = async({token,id,filters})=>{
  let query = ''
  for(let key in filters){
    query+= `${key}=${filters[key]}&`
  }
  console.log(query);

try {
const response = await axios.get(`/api/business/list?id=${id}&${query}`,{ headers :{
  Authorization : `Bearer ${token}`,
 }

})


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
getChartData= async({token , type})=>{
  console.log('getCountriesSales');
    try {
      const response = await axios.get(`/api/business/reports?type=${type}` , {
        headers:{
          Authorization : `Bearer ${token}`,
        }
      })
      console.log(response);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
        } catch (error) {
      console.log(error);
      return  {error: error?.response?.data?.message || error.message}
    }
    }

deleteBus = async({token,id})=>{
  
try {
const response = await axios.delete(`/api/business/list?id=${id}`,{ headers :{
  Authorization : `Bearer ${token}`,
 }

})


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
changeStatus = async({token,id,action})=>{  
try {
const response = await axios.put(`/api/business/list`,{id,action},{ headers :{
  Authorization : `Bearer ${token}`,
 }
})


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
waitingLiBus = async(token)=>{
try {
const response = await axios.get('/api/business/waiting',{ headers :{
  Authorization : `Bearer ${token}`,
 }

})


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
userBusList = async({token,filters})=>{
  let filters1=""  
for (let key in filters) {
  // console.log(`${key}: ${filters[key]}`);
  filters1 += `${key}=${filters[key]}&`
}
try {
const response = await axios.get(`/api/business/user/list?${filters1}`,{ headers :{
  Authorization : `Bearer ${token}`,
 }

})


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

userSavedList = async({token,savedItems})=>{
try {

const response = await axios.post(`/api/business/user/list/saved`,{savedItems} ,{
  headers :{
    Authorization : `Bearer ${token}`,
   }}
   )


// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
postTelegram = async({token,data})=>{
try {

const response = await axios.post(`/api/business/post/telegram`,{data} ,{
  headers :{
    Authorization : `Bearer ${token}`,
   }}
   )
if(response.status!==200)  throw new Error(response.data.message)
return response.data
} catch (error) {
return  {error: error?.response?.data?.message || error.message}
}
}

verifyDoc = async({token,section,action,id})=>{
  try {
  const response = await axios.post(`/api/business/verifyDoc`,{section,action,id},{ headers :{
    Authorization : `Bearer ${token}`,
   }
  
  })
  
  
  // console.log(response.data);
  if(response.status!==200)  throw new Error(response.data.message)
  return response.data
  
  } catch (error) {
  console.log(error);
  return  {error: error?.response?.data?.message || error.message}
  }
  }


register = async(token , values )=>{
  console.log("register",values );
  
  try {
    const response = await axios.post(`/api/business/register` ,
    {values} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editAndActivate = async(token , values )=>{
  console.log("register",values );
  
  try {
    const response = await axios.put(`/api/business/register` ,
    {values} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==201)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  busActivation = async({value ,token,id})=>{
  console.log("busActivation",value );
  
  try {
    const response = await axios.post(`/api/business/activate` ,
    {value,id} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  editBusiness = async({values ,token,id})=>{
  console.log("editBusiness",values );
  
  try {
    const response = await axios.put(`/api/business/register/edit` ,
    {values,id} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  setSchedule = async({data ,token})=>{
  console.log("editBusiness",data );
  
  try {
    const response = await axios.post(`/api/business/register/edit` ,
    {data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  preActivation = async({value ,token,id})=>{
  console.log("busActivation",value );
  
  try {
    const response = await axios.post(`/api/business/activate/phone` ,
    {value,id} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }  

  sendEmailCode = async({token,id})=>{
  console.log("sendEmailCode" );   
  try {
    const response = await axios.get(`/api/business/activate/email/${id}` ,
   {
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  checkEmailCodeAndShowPhoneInput = async({value,token,id})=>{
  console.log("checkEmailCodeAndShowPhoneInput" ); 
  
  try {
    const response = await axios.post(`/api/business/activate/email/${id}` ,
    {id,value} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  sendCodeToContactPhone = async({token,id,contactPhone})=>{
  // console.log("sendCodeToContactPhone" ); 
  
  try {
    const response = await axios.put(`/api/business/activate/phone` ,
    {id,contactPhone} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status!==200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }
  
  checkExistBus = async({busName , values,email,businessPhone,token,userId ,lang} )=>{
  console.log("checkExistBus",busName,values );
  
  try {
    const response = await axios.post(`/api/business/list` ,
    {busName ,values,email,businessPhone,userId,lang} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }
  
    })
      // console.log(response.data);
      if(response.status !== 200)  throw new Error(response.data.message)
      return response.data
  
  } catch (error) {
     console.log(error);
      return  {error: error?.response?.data?.message || error.message}
  }
  }

businessDetails = async(id)=>{

try {
const response = await axios.get(`/api/business/details/${id}` 
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
emSchedule = async({id , token})=>{

try {
const response = await axios.get(`/api/business/schedules/employee/${id}`,{
  headers :{
    Authorization : `Bearer ${token}`,
   } 
  }
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}
setEmSchedule = async({id , token,data})=>{

try {
const response = await axios.put(`/api/business/schedules/employee/${id}`,{
  data
},{
  headers :{
    Authorization : `Bearer ${token}`,
   } 
  }
)
// console.log(response.data);
if(response.status!==200)  throw new Error(response.data.message)
return response.data

} catch (error) {
console.log(error);
return  {error: error?.response?.data?.message || error.message}
}
}

getTags = async({ filters})=>{    
  let filters1=""  
  for (let key in filters) {
    // console.log(`${key}: ${filters[key]}`);
    filters1 += `${key}=${filters[key]}&`
  }

  try {
    const response = await axios.get(`/api/business/tags?${filters1}`)
      
      if(response.status!==200)  throw new Error(response.data.message)      
      return response.data
  
  } catch (error) {             
      return  {error: error?.response?.data?.message || error.message}
  }
  }

  postTelegram = async({token,data})=>{
    try {
    
    const response = await axios.post(`/api/business/post/telegram`,{data} ,{
      headers :{
        Authorization : `Bearer ${token}`,
       }}
       )
    if(response.status!==200)  throw new Error(response.data.message)
    return response.data
    } catch (error) {
    return  {error: error?.response?.data?.message || error.message}
    }
    }

    bookSession = async({token , data})=>{  
      try {
        const response = await axios.post(`/api/business/person/book` ,
        {data} ,{
          headers :{
            Authorization : `Bearer ${token}`,
            }
      
        })
          // console.log(response.data);
          if(response.status!==201)  throw new Error(response.data.message)
          return response.data
      
      } catch (error) {
          console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }

      fetchSessionReqList = async({token , data })=>{
        // console.log('fetchSessionReqList',data);
        let keys=''
        for(let key in data){      
          keys += `${key}=${data[key]}&`
        }
      try {
        const response = await axios.get(`/api/business/person/book?${keys}` ,
        {
          headers :{
            Authorization : `Bearer ${token}`,
           }
        })
          if(response.status!==200)  throw new Error(response.data.message)
          return response.data
      } catch (error) {
         console.log(error);
          return  {error: error?.response?.data?.message || error.message}
      }
      }

      changeSessionStatus = async({token,data})=>{
        try {
        const response = await axios.put(`/api/business/person/book`,{data},{ headers :{
          Authorization : `Bearer ${token}`,
         }
        
        })
        
        
        // console.log(response.data);
        if(response.status!==200)  throw new Error(response.data.message)
        return response.data
        
        } catch (error) {
        console.log(error);
        return  {error: error?.response?.data?.message || error.message}
        }
        }


})()