import { NavLink } from "react-router-dom";

export default function JobsNav() {
  const showEdit = window.location.pathname.split("/").slice(7, 8).toString();
  const jobsUrl =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 5).join("/");
    // console.log("jobsUrl",jobsUrl);
  const matchUrlMain =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 6).join("/");
    // console.log("matchUrlMain",matchUrlMain);
  const matchUrlSub =
    window.location.origin +
    "/" +
    window.location.pathname.split("/").slice(1, 7).join("/");
    // console.log("matchUrlSub",matchUrlSub);
  return (
    <div className="dashboardNavigator d-flex">        
        <ul className="d-flex">        
          <li
                    onClick={(e) =>
                      window. navigateHandle(`/${window.lcs}/jobs/filters`)
                    }
                    className={`sidebarListItem  link ${
                      (jobsUrl + "/filters" === matchUrlMain||jobsUrl + "/page" === matchUrlMain) && "active"
                    }`}
                  >
                   آگهی استخدام
                  </li>
                  <li
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lcs}/jobs/ready/filters`
                      )
                    }
                    className={`sidebarListItem  link ${
                      jobsUrl + "/ready" === matchUrlMain && "active"
                    }`}
                  >
                    آماده به کار
                  </li>
                  <li
                    onClick={(e) =>
                      window.navigateHandle(
                        `/${window.lcs}/jobs/hide/filters`
                      )
                    }
                    className={`sidebarListItem  link ${
                      jobsUrl + "/hide" === matchUrlMain && "active"
                    }`}
                  >
                   گوشه و کنار
                  </li>
          </ul>
    
    </div>
  );
}
