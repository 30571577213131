import React, { useEffect, useRef, useState } from "react";
import tools from "../../../../utils/tools";
// import ActionBtnGenerator from "../../demy/components/actionBtnGenerator";
import Tooltip1 from "../../../golbal/tooltip/tooltip";
import apiBusiness from "../../../../utils/apiBusiness";
import { FiberNew } from "@mui/icons-material";

export default function BusPageSch({ business, user, messageRef }) {
  const [activeEm, setActiveEm] = useState(business?.scheduleInfo?.filter(em=>em.isActive));
  // console.log("activeEm", activeEm);
  const [availableSlots, setAvailableSlots] = useState([]);
  // console.log("availableSlots", availableSlots);
  const [weeks, setWeeks] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState([]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isOwner = user?._id === business?.moreInfo?.history?.claimedBy[0]?._id;
  const lastWeek = new Date().setDate(new Date().getDate() - 1 * 7);
  const { countryShort, lang } = tools.lcsn(window.location.pathname);
  const [bookingComment, setBookingComment] = useState("");

  // const messageRef = useRef(null);
  const [clickedSession, setClickedSession] = useState({});
  useEffect(() => {
if(activeEm)personCombiner();
  }, [activeEm, weeks, sessions]);
  useEffect(() => {
    fetchSessions();
    tools.scrollToAnchor();
  }, []);
  async function fetchSessions() {
    setLoading((pre) => ({ ...pre, sessions: true }));
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - 1);
    const response = await apiBusiness.fetchSessionReqList({
      token: user?.token,
      data: {
        hostId: business._id,
        currentDate: lastWeek,
      },
    });
    setLoading((pre) => ({ ...pre, sessions: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setSessions(response.data);
    console.log("sessions 01", response.data);
  }
  return (
    <div>
      {activeEm?.length > 0 && (
        <div className="listing-details-information p-3  my-5  ">
          <div className=" divider-small border-0 mt-4">
            <div
              className="  divider-small w-100 w-100  border-0 w-100 mx- mt-4"
              id="tutor-schedule"
            >
              <span>
                <img
                  src="/img/icons/reminder.png"
                  className="arrow2 pe-2"
                  alt="info"
                />
              </span>
              <span className="p-0 mb-0 ">قرار ملاقات  <FiberNew/></span>
              <div className="small-gray-font px-1">
                (شخص و ساعت مورد نظرتون رو انتخاب کنید. ساعت‌ها به وقت محلی شما
                هستند.)
              </div>
            </div>

            <select
              className="login-btn selectpicker shadow-none "
              name=""
              id=""
              onChange={(e) => {
                const user = business.scheduleInfo.find(
                  (p) => p._id === e.target.value
                );
                console.log("user", user, e.target.value);
                if (user) return setActiveEm([user]);
                setActiveEm(business?.scheduleInfo?.filter(em=>em.isActive));
              }}
            >
              <option value="">همه کارمندان</option>
              {business?.scheduleInfo.filter(em=>em.isActive).map((user, i) => 
                <option value={user._id}>{user.name}</option>
            )}
            </select>

            <div className="mx-md-3 mx-1">
              <div className="d-flex justify-content-between w-100 p-2 ">
                <span>
                  <button
                    disabled={
                      (isOwner
                        ? lastWeek
                        : new Date().setDate(new Date().getDate() + 1)) >=
                      new Date(availableSlots[0]?.date)
                    }
                    onClick={(e) =>
                      setWeeks((pre) => {
                        return (pre -= 1);
                      })
                    }
                    className="icon-btn"
                  >
                    <i className="bi bi-caret-right-fill direction-pagination mx-1 my-2 "></i>
                  </button>
                </span>

                <div className=" d-flex mid-gray-font align-items-center">
                  {/* March 5-11, 2024 */}
                  <GetWeekDatesString data={availableSlots} />
                </div>
                <span>
                  <button
                    onClick={(e) =>
                      setWeeks((pre) => {
                        return (pre += 1);
                      })
                    }
                    className="icon-btn"
                  >
                    <i className="bi bi-caret-left-fill direction-pagination "></i>
                  </button>
                </span>
              </div>
              <div id={"availableSlotsParent" + weeks} className="row">
                {availableSlots.map((daySchedule, mainIndex) => (
                  <div
                    className="col-1-7 text-center font-14 px-1"
                    key={daySchedule?.weekDay + weeks}
                    id={"avS" + daySchedule?.weekDay + weeks}
                  >
                    <div className="weekdays text-wrap p-1 pt-3 text-center">
                      {/* {daySchedule?.weekDay} */}
                      {tools.renderTrans({
                        section: "weekDays",
                        key: daySchedule?.weekDay,
                      })}
                    </div>
                    {/* <strong className="pb-4"> {tools.dynamicNumber( {number:daySchedule?.date.split("-")[2],lang})} </strong>
                <strong className="pb-4"> {tools.dynamicNumber( {number:daySchedule?.date.split("-")[2],lang})} </strong> */}
                    <strong className="pb-4">
                      {tools.dynamicDate({
                        lang,
                        countryShort,
                        singleDate: true,
                        date: new Date(
                          tools.generateUtcDate({
                            date: daySchedule?.date,
                            time: "09:00",
                            // timeZone2: "Asia/Tehran",
                            // timeZone: data.moreInfo.timeZone,
                            timeZone,
                          })
                        ),
                      })}
                    </strong>
                    <ul className="px-0 text-center" id={"mainUl" + mainIndex}>
                      {Object.entries(daySchedule.slots).map(
                        ([time, slots], i) => {
                          const isAvailable = slots.some(
                            (slot) => slot.status === "available"
                          );
                          if (!isAvailable && !isOwner) return;
                          return (
                            <li
                              key={`keyMainIndex${mainIndex}slotIndex${i}`}
                              id={`mainIndex${mainIndex}slotIndex${i}`}
                              className={`cursor times-tutor my-2 font-14 ${
                                !isAvailable ? "booked-session" : ""
                              }`}
                            >
                              <div className="dropdown">
                                <div
                                  type="button"
                                  className="dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"

                                  // className="btn btn-primary share-cover-btn mx-2"
                                >
                                  {tools.dynamicTime({
                                    date: time,
                                    lang: window.lang,
                                    countryShort,
                                  })}
                                  <div>
                                    <i className="bi bi-chevron-compact-down " />
                                  </div>
                                </div>

                                {/* <!-- Modal --> */}
                                <div className="dropdown-menu checkbox-form ">
                                  {slots.map((slot, i) => {
                                    // console.log(i,slot);
                                    const isAvailable =
                                      slot.status === "available";
                                    const isPerson = slot.personId === user._id;
                                    if (!isAvailable && !isOwner) return;
                                    return (
                                      <div key={i}>
                                        <div className="row mb-3">
                                          <div className="col-3 d-flex flex-column align-items-center justify-content-center px-0">
                                            <img
                                              className="img-fluid jobCrad-img3 "
                                              src={slot?.img}
                                              alt={slot?.name}
                                              onClick={(e) =>
                                                selectHandler(e, slot)
                                              }
                                            />
                                            {tools.renderTrans({
                                              section: "status",
                                              key: slot?.status,
                                            })}
                                          </div>
                                          <div className="col-9 text- px-1">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                              <strong
                                                onClick={(e) =>
                                                  selectHandler(e, slot)
                                                }
                                              >
                                                {slot?.name}
                                              </strong>
                                              <div className=" small-gray-font">
                                                <div>
                                                  {tools.dynamicDate({
                                                    date: slot.start,
                                                    countryShort,
                                                    lang: window.lang,
                                                  })}
                                                </div>
                                                <div>
                                                  {tools.dynamicTime({
                                                    date: slot?.end,
                                                    countryShort,
                                                    lang: window.lang,
                                                  })}
                                                  -
                                                  {tools.dynamicTime({
                                                    date: slot?.start,
                                                    countryShort,
                                                    lang: window.lang,
                                                  })}
                                                </div>
                                                <div className="text-success">
                                                  {slot?.guest?.userName}
                                                </div>
                                              </div>
                                             

                                              {/* <div onClick={e=>e.stopPropagation() }>
                                                <Tooltip1 icon={<div>خدمات</div>} message={slot?.desc[window.lang]} />
                                              </div> */}
                                              
                                            </div>
                                            
                                          </div>
                                         
                                        </div>
                                        {slot.start.getTime() > Date.now() &&
                                          ((isOwner || isPerson) &&
                                          slot?.sessionId?.length > 0 ? (
                                            <div
                                              // type="button"
                                              className="d-flex flex-column dropdown-toggle"
                                              // data-bs-toggle="dropdown"
                                              // aria-expanded="false"
                                              title="بیشتر"
                                              // className="btn btn-primary share-cover-btn mx-2"
                                            >
                                              {slot.status !== "canceled" && (
                                                <div
                                                  className="btn primary-btn checkout-btn w-100  p-1  tutor-color0 font-10 max-w-200"
                                                  // className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200"
                                                  onClick={(e) =>
                                                    actionHandler({
                                                      e,
                                                      currentStatus:
                                                        slot.status,
                                                      newStatus: "canceled",
                                                      session: slot,
                                                    })
                                                  }
                                                >
                                                  کنسل
                                                </div>
                                              )}
                                              {slot.status !== "canceled" &&
                                                slot.status !== "confirmed" &&
                                                slot.status !== "selected" && (
                                                  <div
                                                    className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200 bg-pinkOrange"
                                                    // className="btn primary-btn checkout-btn w-100 my-2 p-1 font-10 max-w-200"
                                                    onClick={(e) =>
                                                      actionHandler({
                                                        e,
                                                        currentStatus:
                                                          slot.status,
                                                        newStatus: "confirmed",
                                                        session: slot,
                                                      })
                                                    }
                                                  >
                                                    تائید
                                                  </div>
                                                )}
                                            </div>
                                          ) : (
                                            !(isOwner || isPerson) && (
                                              <div>
                                                 <div className=" small-gray-font">
                                              {slot?.desc[window.lang].length<80?slot?.desc[window.lang]:<span title={slot?.desc[window.lang]}>{slot?.desc[window.lang].substring(0,80)+" ..."}</span>}
                                              </div>
                                              <div
                                                className="btn primary-btn checkout-btn w-100 my-2 p-0 font-10 max-w-200 bg-beigeDark text-green-dark"
                                                onClick={(e) =>
                                                  selectHandler(e, slot)
                                                }
                                              >
                                                انتخاب
                                              </div>
                                              </div>
                                            )
                                          ))}
                                        <div></div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ))}
              </div>
              {/* <div className="small-gray-font font-10 mt-1">
            ( برای اطلاع از جزییات دقیق قرار ملاقات‌ها، به
            <span
              className="mx-1 fw-bold cursor"
              onClick={(e) =>
                window.navigateHandle(
                  `/${window.lcs}/dashboard/user/demy/listsessions`
                )
              }
            >
              {" "}
              «پنل کاربری»{" "}
            </span>
            سر بزنید. )
          </div> */}
              <div
                className="modal fade"
                id="bookingModal"
                tabIndex="-1"
                aria-labelledby="claimModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title titr5 " id="claimModalLabel">
                        درخواست رزرو با
                        {clickedSession?.name}
                      </h1>
                      <button
                        type="button"
                        id="modal-colse-btn"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <table className="w-100 text-center mb-3">
                        <thead className="font-14 ">
                          <tr>
                            <th>نام</th>
                            <th>تاریخ</th>
                            <th>آغاز</th>
                            <th>مدت</th>
                          </tr>
                        </thead>
                        <tbody className="font-12">
                          <tr key={clickedSession._id}>
                            <td>{clickedSession.name}</td>
                            <td>
                              {tools.dynamicDate({
                                date: clickedSession.start,
                                countryShort,
                                lang: window.lang,
                              })}
                            </td>
                            <td dir="ltr">
                              {tools.dynamicTime({
                                date: clickedSession.start,
                                countryShort,
                                lang: window.lang,
                                hour12: true,
                              })}
                            </td>
                            <td>{clickedSession.duration * 60} دقیقه</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-md-6 px-4">
                          <textarea
                            className="claim-box mx-auto w-100"
                            rows={3}
                            placeholder="اگه پیامی برای این رزرواسیون دارید اینجا بنویسید..."
                            onChange={(e) => setBookingComment(e.target.value)}
                          />
                          {user?.roles && !user?.email?.length && (
                            <div className="d-flex btn btn-primary save-cover-btn bg-pinkOrange border-0 w-100 mb-3 justify-content-center">
                              <div
                                type="button"
                                data-bs-dismiss="modal"
                                data-bs-target="#bookingModal"
                                onClick={(e) =>
                                  window.navigateHandle(
                                    `/${window.lcs}/dashboard/user/profile/me`
                                  )
                                }
                              >
                                تنظیم آدرس ایمیل
                              </div>
                              <Tooltip1 message="تائید رزرواسیون شما به ایمیل آدرستون فرستاده می‌شود و شما تاکنون آنرا ثبت نکرده‌اید." />
                            </div>
                          )}

                          <div
                            type="button"
                            className="btn btn-primary save-cover-btn bg-pinkOrange border-0 w-100"
                            // data-bs-dismiss="modal"
                            onClick={bookSessionHandle}
                          >
                            رزرو قرار ملاقات
                          </div>
                        </div>
                        <div className="col-md-6 align-items-center px-5 py-0 display-none-md">
                          <img
                            src={clickedSession.img}
                            width="250px"
                            alt="Book"
                            loading="lazy"
                          />

                          {/* <img
                      src="/img/Metademy/free-session.svg"
                      alt="claim"
                      loading="lazy"
                    /> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="modal-footer">
          
        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function personCombiner() {
    //  const converted =  activeEm.map(per=>weekDaysCalculator(per))
    // console.log('activeEm',activeEm)
    const combinedData = activeEm?.flatMap((per, i) =>
      weekDaysCalculator(per, i)
    );

    //  console.log('combinedData',combinedData);
    const combinedData2 = combineDataf(combinedData);
    setAvailableSlots(combinedData2);
    //  console.log('combined02',combinedData2);
    //  const sortedCombinedData = combinedData?.sort((a, b) => new Date(a.date) - new Date(b.date));
    //  console.log('sortedCombinedData',sortedCombinedData);
  }

  function combineDataf(data) {
    // console.log("combineDataf data", data);
    // Create a Map to group data by date and weekday
    const data0 = [...data];
    const combinedMap = new Map();

    // Iterate through each item in the data array
    data0.forEach((item) => {
      // Generate a unique key combining date and weekday
      const key = `${item.date}-${item.weekDay}`;

      // If the key doesn't exist in the map, initialize it with an empty object
      if (!combinedMap.has(key)) {
        combinedMap.set(key, {
          date: item.date,
          weekDay: item.weekDay,
          slots: {},
        });
      }

      // Iterate through slots and add person info to the corresponding time
      item.slots.forEach((slot) => {
        // const startTime = new Date(slot.start).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        // console.log('slot.start',slot.start);
        const startTime = new Date(
          `${item.date}T${new Date(slot.start).toISOString().substring(11)}`
        );
        // console.log('combinedMap.get(key).slots',combinedMap.get(key).slots);
        // const startTime = slot.start;

        if (!(startTime in combinedMap.get(key).slots)) {
          combinedMap.get(key).slots[startTime] = [];
        }

        combinedMap.get(key).slots[startTime].push({
          name: item.name,
          img: item.img,
          personId: item.personId,
          duration: slot.duration,
          email: item.email,
          desc: item.desc,
          ...slot,
          start: startTime,
        });
      });
    });

    // Convert the Map values back to an array
    const combinedArray = Array.from(combinedMap.values());

    return combinedArray;
  }

  function weekDaysCalculator(per, i) {
    // if (!activeEm?.schedule) return;
    
    const schedules = [...per?.schedule];
     console.log('schedules0',schedules);
    // Parse the current date starting from tomorrow
    const currentDate = new Date();
    // Function to get the next available weekday
    function getNextWeekday(weekday) {
      const today = currentDate.getDay();
      const days = { addDay: 6, addWeekDay: +1 };
      if (isOwner) {
        days.addDay = 7;
        days.addWeekDay = 0;
      } // If today is the same as the desired weekday, move to next week
      let daysToAdd = (days.addDay + weekday - today) % 7; // Calculate days to add to reach the next weekday
      const nextWeekday = new Date(currentDate);
      nextWeekday.setDate(
        nextWeekday.getDate() + 7 * weeks + daysToAdd + days.addWeekDay
      ); // Set the date to the next weekday
      //  console.log('today',today);
      //  console.log('nextWeekday',nextWeekday);
      return nextWeekday;
    }

    const schedules2 = schedules.map((schedule, i) => {
      const nextWeekday = getNextWeekday(getWeekdayNumber(schedule.weekDay));
      const updatedDate = getDateFormat(nextWeekday); // Extracting date in YYYY-MM-DD format
      return { ...schedule, date: updatedDate };
    });

    function getDateFormat(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
      const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    }
    // Generate missing weekdays and assign them
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (let i = 0; i < 7; i++) {
      const existingWeekday = schedules2.find(
        (schedule) => getWeekdayNumber(schedule.weekDay) === i
      );
      if (!existingWeekday) {
        const nextWeekday = getNextWeekday(i);
        const newSchedule = {
          weekDay: weekdays[i],
          slots: [],
          date: getDateFormat(nextWeekday),
        };
        schedules2.push(newSchedule);
      }
    }

    // Sort the schedules2 based on weekdays
    schedules2.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Function to get the numeric value of weekday
    function getWeekdayNumber(weekday) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return weekdays.indexOf(weekday);
    }
    const updatedSortedData = schedules2?.map((day, index) => {
      const matchedSessions = sessions.filter((se, i) => {
        // console.log("per",i,per,se)
        return (
          getDateFormat(new Date(se.sessionInfo.start)) === day.date &&
          per._id === se.host.personId
        );
      });

      // console.log('matchedSessions',matchedSessions);

      const convertedMatched = matchedSessions?.map((se) => ({
        guest: se.guest,
        history: se.history,
        sessionInfo: se.sessionInfo,
        duration: se.sessionInfo.duration,
        end: new Date(
          new Date(se.sessionInfo.end).toLocaleString("en-US", {
            timeZone: se.sessionInfo.timeZone,
          })
        ),
        start: new Date(
          new Date(se.sessionInfo.start).toLocaleString("en-US", {
            timeZone: se.sessionInfo.timeZone,
          })
        ),
        status: se.history[0].status,
        sessionId: se._id,
        // weekDay: weekdays[new Date (se.sessionInfo.start).getDay()]
      }));
      // console.log('matchedSessions',matchedSessions);
      const convertedSlots = [...day.slots]
        .filter((sl) => !sl?.sessionId)
        .map((slot) => {
          if (typeof slot.start === "string") {
            console.log("day.date , slot.start", day.date, slot.start);
            // slot.start = '2024-07-01'
            slot.start = new Date(
              tools.generateUtcDate({
                date: day.date,
                // date: '2024-07-01',
                time: slot.start,
                // timeZone: "Europe/Warsaw",
                // timeZone:'+03:30',
                timeZone: business?.moreInfo?.timeZone,
                // timeZone : "Asia/Tehran",
                // timeZone
              })
            );
          } else {
            slot.start = new Date(
              day.date + "T" + new Date(slot.start).toISOString().substring(11)
            );
          }
          slot.end = endCalculator(slot.start, slot.duration);
          slot.status = "available";
          return slot;
        });

      // console.log('convertedSlots',convertedSlots);

      const combinedMatch = [...convertedMatched, ...convertedSlots].sort(
        (a, b) => a.start - b.start
      );

      // console.log('combinedMatch',combinedMatch);

      day.name = per.name;
      day.img = per.img;
      day.personId = per._id;
      day.email = per.email;
      day.desc = per.desc;
      day.slots = removeDuplicates(combinedMatch);
      return day;
    });
    return updatedSortedData;
    // setAvailableSlots(updatedSortedData);
  }
  function removeDuplicates(array) {
    // console.log('array',array);
    const uniqueMap = new Map();
    for (const item of array) {
      // Check if an item with the same start date already exists in the map
      if (uniqueMap.has(item.start.toISOString())) {
        // If the existing item doesn't have a sessionId and the current item does, replace it
        if (
          !uniqueMap.get(item.start.toISOString()).sessionId &&
          item.sessionId
        ) {
          uniqueMap.set(item.start.toISOString(), item);
        }
      } else {
        // Otherwise, add the current item to the map
        uniqueMap.set(item.start.toISOString(), item);
      }
    }
    return Array.from(uniqueMap.values());
  }

  function endCalculator(start, duration) {
    const newDate = new Date(start);
    const currentHours = newDate.getHours();
    const currentMinutes = newDate.getMinutes();
    const totalMinutes = currentHours * 60 + currentMinutes + duration * 60; // Add duration
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;
    newDate.setHours(newHours);
    newDate.setMinutes(newMinutes);
    return newDate;
  }
  function selectHandler(e, slot) {
    // if (isOwner) return
if(!window.logined(9999))return;
    if (slot.status !== "available")
      return messageRef.current.showError({
        message: "این ساعت قبلا رزو شده است.",
      });

    setClickedSession(slot);

    var myModal = document.getElementById("bookingModal"); // Get the modal element
    var modal = new window.bootstrap.Modal(myModal, {
      // backdrop: "static",
      keyboard: true,
    });
    modal.show();
  }

  async function actionHandler({ e, session, currentStatus, newStatus }) {
    console.log(currentStatus, newStatus, business._id);
    // console.log("session", session);
    console.log("session", session);
    if (
      window.confirm(
        `آیا با تغییر وضعیت قرار ملاقات به ${tools.renderTrans({
          section: "status",
          key: newStatus,
        })} موافق هستید ؟`
      ) !== true
    )
      return;
    if (!session?.sessionId)
      return messageRef.current.showError({
        message: " تغییر این ساعت امکان پذیر نمی باشد.",
      });
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiBusiness.changeSessionStatus({
      token: user.token,
      data: {
        access: "owner",
        lang: window.lang,
        lcs: window.lcs,
        newStatus,
        currentStatus,
        hostId: business._id,
        start: session.start,
        sessionId: session.sessionId,
        slot: session,
        personEmail: session.email,
        busName: business.busName[window.lang],
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });

    messageRef.current.showSuccess({
      message: `عملیات ${
        newStatus === "confirmed"
          ? "تائید"
          : newStatus === "canceled"
          ? "کنسل"
          : newStatus === "completed"
          ? "برگزاری کلاس"
          : ""
      } موفق`,
    });

    const newSessions = sessions?.map((se) =>
      se._id === response.data._id ? response.data : se
    );
    setSessions(newSessions);
    // setData(newData);
  }
  function GetWeekDatesString({ data, language }) {
    const allDates = data?.map(
      (item) =>
        new Date(
          item?.slots?.[0]?.start ||
            new Date(
              tools.generateUtcDate({
                date: item.date,
                time: "09:00",
                timeZone,
              })
            )
        )
    );

    // Finding the earliest and latest dates
    const startDate = new Date(Math.min(...allDates));
    const endDate = new Date(Math.max(...allDates));

    // Get the locale based on the language
    // const locale = window.lang === "fa" ? "fa-IR" : "en-US";
    const la = lang.toLowerCase();
    const locale = `${
      la === "fa" && countryShort === "ir" ? la : "en"
    }-${countryShort.toUpperCase()}`;

    // Formatting the dates based on the locale
    const startMonth = startDate.toLocaleString(locale, { month: "long" });
    const endMonth = endDate.toLocaleString(locale, { month: "long" });
    const startYear = startDate.toLocaleString(locale, { year: "numeric" });
    const endYear = endDate.toLocaleString(locale, { year: "numeric" });
    const startDay = startDate.toLocaleString(locale, { day: "numeric" });
    const endDay = endDate.toLocaleString(locale, { day: "numeric" });

    // Constructing the string
    let dateString;
    if (window.lang === "fa" && countryShort === "ir") {
      if (startMonth === endMonth && startYear === endYear) {
        dateString = `${startDay} - ${endDay} ${startMonth}, ${startYear}`;
      } else if (startYear === endYear) {
        dateString = `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${startYear}`;
      } else {
        dateString = `${startDay} ${startMonth} ${startYear} -- ${endDay} ${endMonth} ${endYear}`;
      }
    } else {
      if (startMonth === endMonth && startYear === endYear) {
        dateString = `${startMonth} ${startDay}-${endDay}, ${startYear}`;
      } else if (startYear === endYear) {
        dateString = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
      } else {
        dateString = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
      }
    }

    return <div id={"WeekDatesString" + weeks}>{dateString}</div>;
  }
  async function bookSessionHandle() {
    console.log("bookingHandle clickedSession", clickedSession);
    if (!window.logined(9999)) return;
    const text =
      clickedSession?.status === "selected"
        ? "این جایگاه برای ده دقیقه رزرو شده است."
        : clickedSession?.status === "booked"
        ? "متاسفانه این جایگاه رزرو شده است."
        : "لطفا سایر جایگاه ها را انتخاب نمائید.";
    if (clickedSession?.status !== "available")
      return messageRef.current.showWarning({ message: text });
    if (
      window.confirm(
        `شما در حال رزرو قرار ملاقات با ${clickedSession.name} می‌باشید.`
      ) !== true
    )
      return;

    const sessionInfo = {
      timeZone: timeZone,
      duration: clickedSession.duration,
      // start:new Date(tools.convertToUserTimezone({date:clickedSession.schedule.date, time: clickedSession.start , timeZone2: 'Asia/Tehran',timeZone: data.moreInfo.timeZone})),
      start: clickedSession.start,
      end: clickedSession.end,
      desc: bookingComment,
      rate: clickedSession?.rate || 0,
      currency: clickedSession.currency || "CAD",
      exRate:
        1 || ("matchedCurr?.exRate" ?? business?.moreInfo?.salary?.exRate),
      hostId: business._id,
      hostEmail: business.email,
      hostImg: clickedSession.img,
      personName: clickedSession.name,
      personId: clickedSession.personId,
      personEmail: clickedSession.email,
      lcs: window.lcs,
      lang: window.lang,
      hostUser: business.moreInfo.history.claimedBy[0]._id,
    };
    const response = await apiBusiness.bookSession({
      token: user.token,
      data: sessionInfo,
    });
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    // console.log(response.data);
    setSessions((pre) => [...pre, response.data]);
    setClickedSession({});
    // setFreeSession(false);
    setBookingComment("");

    messageRef.current.showSuccess({
      message: "قرار ملاقات شما با موفقیت ثبت شد.",
    });
    document.querySelector("#modal-colse-btn").click();
  }
}
