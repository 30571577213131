import { useEffect, useRef, useState } from "react";
import countryStates from "../../../utils/countryStates";

// import iso2 from '../../../utils/iso2.json'

export default function DropdownSearch({ updateParent,country }) {
  const [options, setOptions] = useState(countryStates);
  const ulRef = useRef();
  const inputRef = useRef();
  const onInputChange = (e) => {
    const newOptions = options.filter((country) =>
      country.country.toLowerCase()?.includes(e?.target?.value.toLowerCase())
    );
    setOptions(newOptions);
  };  
  useEffect(() => {
    inputRef.current.addEventListener("keyup", (e) => {
      e.stopPropagation();
      ulRef.current.style.display = "flex";
      onInputChange(e);
    });
    document.addEventListener("click", (e) => {
      if (ulRef?.current?.style != null) ulRef.current.style.display = "none";
    });
  }, []);

  return (
    <div className="  btn-search d-flex justify-content-between align-items-center w-100 ">
      <span className="w-100 text-center ">
        <input
          ref={inputRef}
          onChange={onInputChange}
          className="form-input3 shadow-none pe-2 w-100"
          placeholder={        
           country?.toUpperCase()||
            "  Type at least three letters..."
          }
          name="countryFormInput"
          autoComplete="off"
        />
        <ul
          ref={ulRef}
          id="result"
          className="list-group mt-3"
          style={{ display: "none", zIndex: 2 }}
        >
          {options?.map((option, i) => {
            return (
              <button
                onClick={(e) => {
                  inputRef.current.value = option.country;
                  updateParent(e.target.id);
                }}
                key={i}
                type="button"
                className="list-group-item list-group-item-action groupItems"
              >
                <span id={option.countryShort} className="listOptions">
                  {option.country}
                </span>
              </button>
            );
          })}
        </ul>
      </span>
    </div>
  );
}
