// import "../../../userList/userList.css";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../../../contexts/cartContext";
import apiDemy from "../../../../../utils/apiDemy";
import ToastNote from "../../../../golbal/notifications/toastNote";
import Tooltip1 from "../../../../golbal/tooltip/tooltip";
import ColoredSpinner from "../../../../alret/spiners/coloredSpiner/coloredSpinner";
// import SearchBarUser from "../profile/transactions/myTrans/searchBarUser";
// import MyIcons from "../../../../golbal/icons/myIcons";
import tools from "../../../../../utils/tools";
import api from "../../../../../utils/api";
import renderData from "../../../../../utils/renderData";
import RatingManuel from "../../../../comment/rating";
import { Link } from "react-router-dom";
import MyIcons from "../../../../golbal/icons/myIcons";

export default function MyTutorList({ access }) {
  const { user, signedUser } = useContext(CartContext);
  const [loading, setLoading] = useState({ spinner: false });
  const [user2, setUser2] = useState({ _id: user._id });
  const [selected, setSelected] = useState([]);

  const [data, setData] = useState([]);
  const [history, setHistory] = useState(false);
  // console.log('user',user);
  // console.log('data',data);
  // console.log('selected',selected);
  const currentDate = new Date();
  currentDate.setDate(1); // Set the day of the month to 1 to get the first day of the current month
  currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to zero
  const messageRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [history, access]);

  async function fetchData() {
    setLoading((pre) => ({ ...pre, spinner: true }));
    const response = await apiDemy.fetchTutorProfileList({
      token: user.token,
      data: {
        access: access,
        userId: user?._id,
        // history,
        // currentDate,
        // trans:true
      },
    });
    setLoading((pre) => ({ ...pre, spinner: false }));
    if (response.error)
      return messageRef.current.showError({ message: response.error });
    setData(response.data);
  }

  return (
    <div className="userList" style={{ height: "100vh", width: "100%" }}>
      <ToastNote messageRef={messageRef} />
      {loading.spinner && <ColoredSpinner />}

      <div>
        <table className="table">
          <thead>
            <tr className="text-center">
              <th scope="col"> عملیات</th>
              <th scope="col">نام </th>
              <th scope="col"> توضیحات</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((tutor, i) => {
              return (
                <tr key={i}>
                  <td className="col-md-1">
                    <div className="d-flex flex-column  align-items-center">
                      <div
                        onClick={(e) =>
                          window.navigateHandle(
                            `/${window.lcs}/demy/tutor/page/${tutor?._id}`,
                            tutor
                          )
                        }
                      >
                        <i class="bi bi-eye btn-cart font-18  mb-2 fw-bold"></i>
                      </div>
                      <div
                        onClick={(e) =>
                          window.navigateHandle(
                            `/${window.lcs}/dashboard/demy/edittutor/${tutor?._id}`,
                            tutor
                          )
                        }
                      >
                        <i class="bi bi-pencil btn-cart font-18 fw-bold"></i>
                      </div>
                    </div>
                  </td>
                  <td className="col-md-3">
                    <div className="row text-center">
                      <div className="">
                        <div className="row">
                          <div className="col-3 px-0">
                            <div
                              className="px-0 d-flex justify-content-center align-items-center cursor"
                              onClick={(e) =>
                                window.navigateHandle(
                                  `/${window.lcs}/demy/tutor/page/${tutor?._id}`,
                                  tutor
                                )
                              }
                            >
                              <img
                                className="img-fluid jobCrad-img2 "
                                src={tutor?.moreInfo?.img}
                                alt={tutor?.name?.[window.lang]}
                              />
                            </div>
                          </div>
                          <div className="col-9">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <strong
                                className="cursor"
                                onClick={(e) =>
                                  window.navigateHandle(
                                    `/${window.lcs}/demy/tuor/page/${tutor?._id}`,
                                    tutor
                                  )
                                }
                              >
                                {tutor?.name?.[window.lang]}
                              </strong>
                              <div className="d-flex small-gray-font font-12">
                                <div>
                                  <RatingManuel
                                    value={tutor?.feedbackInfo?.rate}
                                  />
                                </div>
                                <div>
                                  {tutor?.moreInfo?.isActive
                                    ? "فعال"
                                    : "غیرفعال"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="col-md-3">
                    {/* <div>
      ضریب تعهد:
    {tools.dynamicNumber({number:Math.floor(tutor.moreInfo.transactions.commitmentRate*100),lang:window.lang})+"%"}
    </div> */}
                    <div>
                      امتیاز تعهد:{" "}
                      {tools.dynamicNumber({
                        number: Math.floor(
                          tutor.moreInfo.transactions.commitmentScore
                        ),
                        lang: window.lang,
                      })}
                    </div>

                    <div>
                      اعتبار هدیه:{" "}
                      {tools.dynamicNumber({
                        number: Math.floor(tutor.moreInfo.transactions.credit),
                        lang: window.lang,
                      })}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
