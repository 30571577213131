import { Navigate, Route } from "react-router-dom";
import BlogDahOut from "../components/screens/dashboard/pages/blog/blogDahOut";
import PostList from "../components/screens/dashboard/pages/blog/postList/postList";
import BlogSetting from "../components/screens/dashboard/pages/blog/setting/blogSetting";
import BusinessList from "../components/screens/dashboard/pages/businessDir/businessList";
import Categories from "../components/screens/dashboard/pages/businessDir/categories";
import NewProduct from "../components/screens/dashboard/pages/newProduct/newProduct";
import Product from "../components/screens/dashboard/pages/product/product";
import ProductList from "../components/screens/dashboard/pages/productList/productList";
import UserBusOut from "../components/screens/dashboard/pages/profile/businesses/userBusOut";
import UserSavedOut from "../components/screens/dashboard/pages/profile/saved/userSavedOut";
import SavedBusiness from "../components/screens/dashboard/pages/profile/saved/savedBusiness";
import SavedPosts from "../components/screens/dashboard/pages/profile/saved/savedPosts";
import SavedProducts from "../components/screens/dashboard/pages/profile/saved/savedProducts";
import BusinessRegister from "../components/screens/business/register/businessRegister";
import BusDahOut from "../components/screens/dashboard/pages/businessDir/busDahOut";
import UserTransOut from "../components/screens/dashboard/pages/profile/transactions/userTransOut";
import MyTransMain from "../components/screens/dashboard/pages/profile/transactions/myTrans/myTransMain";
import MyBusList from "../components/screens/dashboard/pages/profile/businesses/myBus/myBusList";
import PointsDetails from "../components/screens/dashboard/pages/profile/transactions/myTrans/pointsDetails";
import UserBlogOut from "../components/screens/dashboard/pages/profile/blog/userBlogOut";
import CreatePost from "../components/screens/dashboard/pages/blog/createPost/postRegister";
import UserProfOut from "../components/screens/dashboard/pages/profile/user/userProfOut";
import AuthorInfo from "../components/screens/dashboard/pages/profile/user/authorInfo";
import CreatePromotion from "../components/golbal/dashboard/promotion/addPromo";
import PromDahOut from "../components/screens/dashboard/pages/prom/promDahOut";
import PromoList from "../components/screens/dashboard/pages/prom/promoList";
import UserProfile from "../components/screens/dashboard/pages/profile/user/userProfile";
import UserAdsOut from "../components/screens/dashboard/pages/profile/ads/userAdsOut";
import AdsLocator from "../components/screens/dashboard/pages/profile/ads/pages/adsLocator";
import MyOrderList from "../components/screens/dashboard/pages/profile/transactions/myTrans/myOrderList";
import BusWaiting from "../components/screens/dashboard/pages/businessDir/busWaiting";
import JobsDahOut from "../components/screens/dashboard/pages/jobs/jobsDahOut";
import JobsRegister from "../components/screens/jobs/register/jobsRegister";
import UserJobsOut from "../components/screens/dashboard/pages/profile/jobs/userJobsOut";
import UserMediaOut from "../components/screens/dashboard/pages/profile/media/userMediaOut";
import StoreDashOut from "../components/screens/dashboard/pages/store/storeDashOut";
import CurrenciesList from "../components/screens/dashboard/pages/store/currenciesList";
import MediaRegister from "../components/screens/media/register/mediaRegister";
import JobsList from "../components/screens/dashboard/pages/jobs/jobsList";
import SavedJobs from "../components/screens/dashboard/pages/profile/saved/savedJobs";
import SavedMedia from "../components/screens/dashboard/pages/profile/saved/savedMedia";
import MediaList from "../components/screens/dashboard/pages/media/mediaList";
import UserDashOut from "../components/screens/dashboard/pages/user/userDashOut";
import { CartContext } from "../components/contexts/cartContext";
import { useContext } from "react";
import tools from "../utils/tools";
import UserList from "../components/screens/dashboard/pages/userList/userList";
import ReviewUser from "../components/screens/dashboard/pages/userList/reviewUser/reviewUser";
import UsersDashOut from "../components/screens/dashboard/pages/userList/usersDashOut";
import MediaDashOut from "../components/screens/dashboard/pages/media/mediaDashOut";
import TransOverview from "../components/screens/dashboard/components/chart/transOverview";
import StoreTransOut from "../components/screens/dashboard/pages/store/storeTransOut";
import BusListDashOut from "../components/screens/dashboard/pages/businessDir/busListDashOut";
import BusinessesOverview from "../components/screens/dashboard/components/chart/businessesOverview";
import UsersOverview from "../components/screens/dashboard/components/chart/usersOverview";
import MarketerInfo from "../components/screens/dashboard/pages/profile/user/marketerInfo";
import RefCodeList from "../components/screens/dashboard/pages/profile/transactions/myTrans/refCodeList";
import EventDashOut from "../components/screens/dashboard/pages/event/eventDashOut";
import EventRegister from "../components/screens/event/eventRegister";
import ArtistRegister from "../components/screens/event/artistRegister";
import ArtList from "../components/screens/dashboard/pages/event/artList";
import EventList from "../components/screens/dashboard/pages/event/eventList";
import CenterRegister from "../components/screens/demy/register/CenterRegister";
import DemyDahOut from "../components/screens/dashboard/pages/demy/demyDahOut";
import ProgramRegister from "../components/screens/demy/register/programRegister";
import CenterList from "../components/screens/dashboard/pages/demy/centerList";
import ProgramList from "../components/screens/dashboard/pages/demy/programList";
import TutorRegister from "../components/screens/demy/register/TutorRegister";
import TutorList from "../components/screens/dashboard/pages/demy/tutorList";
import RequestTutorList from "../components/screens/dashboard/pages/demy/RequestTutorList";
import ReadyRegister from "../components/screens/jobs/register/readyRegister";
import ReadyList from "../components/screens/dashboard/pages/jobs/readyList";
import HideRegister from "../components/screens/jobs/register/hideRegister";
import HideList from "../components/screens/dashboard/pages/jobs/hideList";
import SessionList from "../components/screens/dashboard/pages/demy/sessionList";
import UserDemyOut from "../components/screens/dashboard/pages/profile/demy/userDemyOut";
import TutorTransList from "../components/screens/dashboard/pages/demy/tutorTransList";
import WalletDetails from "../components/screens/dashboard/pages/profile/transactions/myTrans/walletDetails";
import MyTutorList from "../components/screens/dashboard/pages/demy/myTutorList";
import BusinessRegisterSch from "../components/screens/business/register/businessRegisterSch";
import AddEmployes from "../components/screens/business/register/addEmployes";
import BusSessionList from "../components/screens/dashboard/pages/businessDir/BusSessionList";
import EmployeeSch from "../components/screens/business/register/employeeSch";
// import AffiliateInfo from "../components/screens/dashboard/pages/profile/user/affiliateInfo";
import AffiliateList from "../components/screens/dashboard/pages/profile/transactions/myTrans/affiliateList";
import UsedRegister from "../components/screens/marketPlace/register/usedRegister";
import MatchRegister from "../components/screens/marketPlace/register/matchRegister";


export default function DashRoutes() {
  const lcs = window.location.pathname.split("/").slice(1, 4).join("/");
  const { user } = useContext(CartContext);

  return (
    <>
      {/* <Route path="" element={<DashHome /> }/> */}
      <Route
        path="users"
        element={
          tools.compareCheck(user?.roles, [1000]) ? (
            <UsersDashOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="list" element={<UserList />} />
        <Route path="reviewuser" element={<ReviewUser />} />
        <Route path="overView" element={<UsersOverview />} />
      </Route>
      <Route
        path="blog"
        element={
          tools.compareCheck(user?.roles, [1000, 1100]) ? (
            <BlogDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="edit/:id" element={<CreatePost />} />
        <Route path="categories" element={<Categories section="blog" />} />
        <Route path="setting" element={<BlogSetting />} />
        <Route path="postsList" element={<PostList access="admin" />} />
        <Route path="createpost" element={<CreatePost />} />
      </Route>

      <Route
        path="business"
        element={
          tools.compareCheck(user?.roles, [1000, 1200]) ? (
            <BusDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route
          path="register"
          element={<BusinessRegisterSch action="register" />}
        />
        <Route path="edit/:id" element={<BusinessRegisterSch action="edit" />} />
        <Route path="addEmployes/:id" element={<AddEmployes action="edit" />} />
        <Route path="list" element={<BusListDashOut />}>
          <Route
            path="all"
            element={<BusinessList access="admin" action="draft" />}
          />
          <Route
            path="overview"
            element={<BusinessesOverview access="admin" action="claimed" />}
          />
        </Route>
        <Route path="categories" element={<Categories section="business" />} />
        <Route path="waiting" element={<BusWaiting />} />
      </Route>

      <Route
        path="promo"
        element={
          tools.compareCheck(user?.roles, [1000, 1300]) ? (
            <PromDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="create" element={<CreatePromotion />} />
        <Route path="list" element={<PromoList />} />
        <Route path="reward" element={<BusinessList />} />
      </Route>

      <Route
        path="jobs"
        element={
          tools.compareCheck(user?.roles, [1000, 1400]) ? (
            <JobsDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="edit/:id" element={<JobsRegister action="edit" />} />
        <Route path="register" element={<JobsRegister action="register" />} />

        <Route path="editReady/:id" element={<ReadyRegister action="edit" />} />
        <Route path="registerReady" element={<ReadyRegister action="register" />} />
        <Route path="listReady" element={<ReadyList  access="admin"  />} />
        <Route path="edithide/:id" element={<HideRegister action="edit" />} />
        <Route path="registerhide" element={<HideRegister action="register" />} />
        <Route path="listHide" element={<HideList  access="admin"  />} />
        
        <Route path="list" element={<JobsList access="admin" />} />
        <Route path="categories" element={<Categories section="jobs" />} />
      </Route>
      <Route
        path="demy"
        element={
          tools.compareCheck(user?.roles, [1000, 1500,1505]) ? (
            <DemyDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="editProgram/:id" element={<ProgramRegister action="edit" />} />
        <Route path="registerProgram" element={<ProgramRegister action="register" />} />        
        <Route path="registercenter" element={<CenterRegister action="register" />} />
        <Route path="editcenter/:id" element={<CenterRegister action="edit" />} />
        <Route path="registertutor" element={<TutorRegister action="register" />} />
        <Route path="edittutor/:id" element={<TutorRegister action="edit" />} />
        <Route path="listCenters" element={<CenterList access="admin" />} />
        <Route path="listTutors" element={<TutorList access="admin" />} />
        {/* <Route path="listrequests" element={<RequestTutorList access="admin" />} /> */}
        <Route path="listsessions" element={<SessionList access="admin" />} />
        <Route path="listPrograms" element={<ProgramList access="admin" />} />
        <Route path="categoriesTutor" element={<Categories section="tutor" />} />
      </Route>
      <Route
        path="mp"
        element={
          tools.compareCheck(user?.roles, [1000]) ? (
            <DemyDahOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="registerused" element={<UsedRegister action="register" />} />        
        <Route path="editused/:id" element={<UsedRegister action="edit" />} />
        <Route path="categoriesUsed" element={<Categories section="used" />} />
        <Route path="listused" element={<ProgramList access="admin" />} />
        <Route path="registerMatch" element={<MatchRegister action="register" />} />
        <Route path="editMatch/:id" element={<MatchRegister action="edit" />} />
        <Route path="categoriesMatch" element={<Categories section="match" />} />
        <Route path="listMatch" element={<CenterList access="admin" />} />        
      </Route>

      <Route
        path="event"
        element={
          tools.compareCheck(user?.roles, [1000, 1600]) ? (
            <EventDashOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="edit/:id" element={<EventRegister action="edit" />} />
        <Route path="register" element={<EventRegister action="register" />} />
        <Route path="registerart" element={<ArtistRegister action="register" />} />
        <Route path="editart/:id" element={<ArtistRegister action="edit"/>} />
        <Route path="listart" element={<ArtList access="admin" />} />
        <Route path="listevent" element={<EventList access="admin" />} />
        <Route path="categories" element={<Categories section="event" />} />
      </Route>

      <Route
        path="media"
        element={
          tools.compareCheck(user?.roles, [1000, 1800]) ? (
            <MediaDashOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="edit/:id" element={<MediaRegister action="edit" />} />
        <Route path="register" element={<MediaRegister action="register" />} />
        <Route path="list" element={<MediaList access="admin" />} />
        <Route path="categories" element={<Categories section="media" />} />
      </Route>

      <Route
        path="store"
        element={
          tools.compareCheck(user?.roles, [1000, 1300]) ? (
            <StoreDashOut />
          ) : (
            <Navigate replace to={`/denied`} />
          )
        }
      >
        <Route path="trans" element={<StoreTransOut />}>
          <Route path="overview" element={<TransOverview access="admin" />} />
          <Route
            path="orderlist"
            element={<MyOrderList access="admin" section="order" />}
          />
          <Route
            path="invlist"
            element={<MyOrderList access="admin" section="inv" />}
          />
          <Route path="refcodelist"element={<RefCodeList access="admin"/>} />
        </Route>
        <Route path="currencies" element={<CurrenciesList access="admin" />} />
      </Route>
      <Route path="products" element={<ProductList />} />
      <Route path="newproduct" element={<NewProduct />} />
      <Route path="products/:id" element={<Product />} />

      <Route
        path="user"
        element={
          tools.compareCheck(user?.roles, [9999]) ? (
            <UserDashOut />
          ) : (
            <Navigate replace to={`/${lcs}`} />
          )
        }
      >
        <Route path="profile" element={<UserProfOut />}>
          <Route path="me" element={<UserProfile />} />
          <Route path="author" element={<AuthorInfo />} />
          <Route path="marketer" element={<MarketerInfo />} />
          {/* <Route path="affiliate" element={<AffiliateInfo />} /> */}
        </Route>
        <Route path="business" element={<UserBusOut />}>
          <Route
            path="register"
            element={<BusinessRegisterSch action="register" />}
          />
          <Route
            path="mySch"
            element={<EmployeeSch action="edit" />}
          />
          <Route path="edit/:id" element={<BusinessRegisterSch action="edit" />} />
          <Route path="addEmployes/:id" element={<AddEmployes action="edit" />} />
          <Route
            path="myBuslist"
            element={<MyBusList access="user" suggestMode={false} />}
          />
          <Route
            path="mySuglist"
            element={<MyBusList access="admin" suggestMode={true} />}
          />
           <Route path="listsessions" element={<BusSessionList access="bus" />} />
        </Route>
        <Route path="demy" element={<UserDemyOut />}>
        <Route path="registertutor" element={<TutorRegister action="register" />} />
        <Route path="edittutor/:id" element={<TutorRegister action="edit" />} />        
        {/* <Route path="listrequests" element={<RequestTutorList access="user" />} /> */}
        <Route path="listmysessions" element={<SessionList access="user" />} />
        {user?.roles?.includes(1505)&&
        <>
        <Route path="listsessions" element={<SessionList access="tutor" />} />
        <Route path="tutortranslist" element={<TutorTransList access="tutor" />} />
        <Route path="myTutorlist" element={<MyTutorList access="tutor" />}
          />
        </>
        }
        
          
        </Route>
        <Route path="blog" element={<UserBlogOut />}>
          <Route path="register" element={<CreatePost action="register" />} />
          <Route path="myPostsList" element={<PostList access="user" />} />
          <Route path="edit/:id" element={<CreatePost />} />
        </Route>
        <Route path="saved" element={<UserSavedOut />}>
          <Route path="posts" element={<SavedPosts />} />
          <Route path="business" element={<SavedBusiness />} />
          <Route path="jobs" element={<SavedJobs />} />
          <Route path="media" element={<SavedMedia />} />
          <Route path="products" element={<SavedProducts />} />
        </Route>
        <Route path="trans" element={<UserTransOut />}>
          <Route path="overview" element={<MyTransMain access="user" />} />
          <Route path="points" element={<PointsDetails />} />
          <Route path="wallet" element={<WalletDetails />} />
          <Route path="refcodelist" element={<RefCodeList access="user"/>} />
          <Route path="affilatelist" element={<AffiliateList access="user"/>} />
          <Route
            path="orderlist"
            element={<MyOrderList access="user" section="order" />}
          />
          <Route
            path="invlist"
            element={<MyOrderList access="user" section="inv" />}
          />
        </Route>
        <Route path="ads" element={<UserAdsOut />}>
          <Route path="plans" element={<AdsLocator />} />
          <Route
            path="myplans"
            element={<MyOrderList access="user" section="inv" />}
          />
          {/* <Route path="reports" element={<MyBusList />} />
        <Route path="billboard" element={<MyBusList />} /> */}
        </Route>
        <Route path="jobs" element={<UserJobsOut />}>
          <Route path="edit/:id" element={<JobsRegister action="edit" />} />
          <Route path="register" element={<JobsRegister action="register" />} />
          <Route path="list" element={<JobsList access="user" />} />
          <Route path="registerReady" element={<ReadyRegister action="register" />} />
          <Route path="editReady/:id" element={<ReadyRegister action="edit" />} />       
          <Route path="registerhide" element={<HideRegister action="register" />} />          
          <Route path="edithide/:id" element={<HideRegister action="edit" />} />          
          <Route path="listHide" element={<HideList  access="user"  />} />
        </Route>
        <Route path="media" element={<UserMediaOut />}>
          <Route path="edit/:id" element={<MediaRegister action="edit" />} />
          <Route
            path="register"
            element={<MediaRegister action="register" />}
          />
          <Route path="list" element={<MediaList access="user" />} />/
        </Route>
      </Route>
    </>
  );
}
