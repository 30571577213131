import { Outlet } from "react-router-dom"



export default function PromDahOut() {
  return (
    <div className="blogDashboard" style={{width:"100%"}}>
   <h1>Promotion Header</h1>
    <Outlet/>
        
    </div>
  )
}
